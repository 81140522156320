import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SVGIcon from './SVGIcon';

const SelectionListItemContent = ({ position, children }) => children && (
  <div className={ `showtime-selection-list__item-${position}-content` }>
    { children }
  </div>
);

SelectionListItemContent.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  children: PropTypes.node,
};

SelectionListItemContent.defaultProps = { children: null };

export const SelectionListItem = ({
  iconName,
  title,
  introduction,
  introductionRightContent,
  linkLabel,
  onLinkClick,
  linkDataTest,
  onClick,
  disabled,
  dataTest,
  className,
  rightContent,
  bottomContent,
  noMarginBottom,
  id,
}) => {
  const canClick = onClick && !disabled;
  const WrapperElement = canClick ? 'a' : 'div';
  const wrapperClassName = classnames(
    'showtime-selection-list__item',
    'hint__container',
    className,
    {
      'showtime-selection-list__item--clickable': canClick,
      'showtime-selection-list__item--disabled': disabled,
      'showtime-selection-list__item--no-margin-bottom': noMarginBottom,
    }
  );

  return (
    <WrapperElement
      className={ wrapperClassName }
      data-test={ dataTest }
      { ...canClick && { onClick } }
      id={ id }
    >
      <SelectionListItemContent position='top'>
        <SelectionListItemContent position='left'>
          <div className='showtime-selection-list__item-title'>
            <SVGIcon iconName={ iconName } />
            <span>{ title }</span>
            { onLinkClick && (
              <a
                className='showtime-selection-list__item-link showtime-link showtime-link--underline'
                data-test={ linkDataTest }
                onClick={ onLinkClick }
              >
                { linkLabel }
              </a>
            )}
          </div>
          <div className='showtime-selection-list__item-description'>
            { introduction }
            <SelectionListItemContent position='right'>
              {introductionRightContent}
            </SelectionListItemContent>
          </div>
        </SelectionListItemContent>
        <SelectionListItemContent position='right'>
          {rightContent}
        </SelectionListItemContent>
      </SelectionListItemContent>
      <SelectionListItemContent position='bottom'>
        {bottomContent}
      </SelectionListItemContent>
    </WrapperElement>
  );
};

SelectionListItem.propTypes = {
  onLinkClick: PropTypes.func,
  linkLabel: PropTypes.string,
  linkDataTest: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  iconName: PropTypes.string,
  introduction: PropTypes.node,
  introductionRightContent: PropTypes.node,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  rightContent: PropTypes.node,
  disabled: PropTypes.bool,
  bottomContent: PropTypes.node,
  noMarginBottom: PropTypes.bool,
};

SelectionListItem.defaultProps = {
  onLinkClick: null,
  linkLabel: null,
  linkDataTest: null,
  onClick: null,
  title: '',
  introduction: '',
  introductionRightContent: null,
  iconName: '',
  dataTest: null,
  className: '',
  rightContent: null,
  disabled: false,
  bottomContent: null,
  noMarginBottom: false,
  id: null,
};

export default SelectionListItem;
