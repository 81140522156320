import {
  actionConstants,
  FLOATIFICATION_PERSIST_DURATION,
  INFO,
} from '../../../floatifications/constants';
import floatificationsStore from '../../../store';
import {
  FLOATIFICATION_OFFLINE_ID,
  FLOATIFICATION_QUEUE_UPDATED_ID,
} from '../../../service-workers/constants';
import { OFFLINE_ICON } from '../../../offline/constants';

export default async (
  requestQueueLength,
  timeout = FLOATIFICATION_PERSIST_DURATION,
  client = window
) => {
  const offlineMessage = requestQueueLength
    ? {
      type: actionConstants.ADD_MESSAGE,
      message: {
        id: FLOATIFICATION_QUEUE_UPDATED_ID,
        text: `OFFLINE - ${requestQueueLength} unsaved changes`,
        type: INFO,
        icon: OFFLINE_ICON,
        expandedText: 'You can still enter most data in offline mode. Your changes will be synced when you reconnect.',
      },
    }
    : {
      type: actionConstants.ADD_MESSAGE,
      message: {
        id: FLOATIFICATION_OFFLINE_ID,
        text: 'OFFLINE',
        type: INFO,
        icon: OFFLINE_ICON,
        expandedText: 'You can still enter most data in offline mode. Your changes will be synced when you reconnect.',
      },
    };

  client.offlineMessageTimeout = setTimeout(() => {
    const floatificationsState = floatificationsStore.getState();

    const floatificationQueueUpdated = !floatificationsState.floatifications
      .some(({ id }) => id === FLOATIFICATION_QUEUE_UPDATED_ID);
    if (floatificationQueueUpdated) {
      floatificationsStore.dispatch(offlineMessage);
    }
  }, timeout);
};
