import canCurrentUserModifyCell from './canCurrentUserModifyCell';
import { TIMESTAMP_FIELDNAME } from '../constants';

const isEmpty = value => (value === '' || value === null || value === undefined);

export default (record, columns) => columns.reduce((acc, column) => {
  if (!column.cellSettings) return acc;

  const { columnRestricted, fieldName } = column.cellSettings;
  if (!fieldName) return acc;

  return isEmpty(record[fieldName])
    && fieldName !== TIMESTAMP_FIELDNAME
    && canCurrentUserModifyCell(columnRestricted)
    ? [...acc, fieldName]
    : acc;
}, []);
