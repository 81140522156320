import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { SubTaskConstants } from '../constants';
import { SubTaskActions } from '../actions';
import SubtaskCheckBox from '../SubtaskCheckBox';
import FlagOptionsMenu from './FlagOptionsMenu';
import SVGIcon from '../../common/SVGIcon';
import { ConfirmationMenu } from '../../common/menu';
import OfflineInteractionBlocker from '../../offline-interaction-blocker';
import ToolTip from '../../common/ToolTip';
import CommentButton from '../../taskContentComments/CommentButton';
import TaskContentCommentThread from '../../taskContentComments/TaskContentCommentThread';
import { commentableTypes } from '../../taskContentComments/constants';

export const RecurringTooltip = injectIntl(({ intl: { formatMessage } }) => (
  <ToolTip
    classes={ ['showtime-task__subtask-recurring-help', 'hint--small', 'hint--top-right'] }
    message={ formatMessage({ id: 'trail.subtasks.recurringToolTip' }) }
    iconName='/mini/arrows/arrows-16px-e_refresh-20'
  />
));

export class Subtask extends PureComponent {
  state = {
    showThread: this.props.readOnlyComments,
    isEditMenuOpen: false,
    isFlagMenuOpen: false,
  };

  toggleShowThread = () => this.setState(({ showThread }) => ({ showThread: !showThread }));

  formatCompletedAtLabel() {
    if (!this.props.completed_at || !this.props.completed_by) {
      return null;
    }
    const status = this.props.intl.formatMessage({ id: `trail.subtasks.value.${this.props.value}`, defaultMessage: ' ' }).trim();

    return this.props.intl.formatMessage({ id: 'trail.subtasks.completedTime' }, {
      status,
      completedAt: moment(this.props.completed_at).format('HH:mm [-] DD/MM/YYYY'),
      completedBy: this.props.completed_by,
    });
  }

  renderMenu() {
    if (!this.props.optionMenuCanShow) { return null; }

    const outsideClickIgnoreClass = `ignore-onclickoutside-menu-dots-${this.props.id}`;
    const { intl } = this.props;

    const menuProps = {
      showMenu: this.state.isEditMenuOpen,
      onClickOutside: this.hideSubtaskEditMenu,
      disableOnClickOutside: !this.state.isEditMenuOpen,
      align: 'left',
      outsideClickIgnoreClass,
    };

    const onDelete = () => SubTaskActions.deleteSubtask(this.props.id);

    return (
      <div
        data-test={ `subtask-show-menu-${this.props.id}` }
      >
        <OfflineInteractionBlocker
          tooltipMessage={ this.props.intl.formatMessage({ id: 'offline.notAvailable' }) }
        >
          <a
            className={ `showtime-link showtime-link--alternate ${outsideClickIgnoreClass}` }
            data-test={ `subtask-show-menu-button-${this.props.id}` }
            onClick={ this.toggleEditMenu }
          >
            <SVGIcon
              classes='showtime-link__icon showtime-link__icon--left'
              iconName='mini/ui/ui-16px-2_menu-dots'
            />
          </a>
          <ConfirmationMenu
            { ...{
              ...menuProps,
              onConfirm: onDelete,
              onAbort: this.hideSubtaskEditMenu,
              title: intl.formatMessage({ id: 'trail.subtasks.menu.title' }),
              description: intl.formatMessage({ id: 'trail.subtasks.menu.description' }),
              abort: intl.formatMessage({ id: 'trail.subtasks.menu.abort' }),
              confirm: intl.formatMessage({ id: 'trail.subtasks.menu.delete' }),
            } }
          />
        </OfflineInteractionBlocker>
      </div>
    );
  }

  isFlagged() {
    return this.props.value !== SubTaskConstants.VALUES.DONE
      && this.props.value !== SubTaskConstants.VALUES.UNCHECKED;
  }

  isUnchecked() {
    return this.props.value === SubTaskConstants.VALUES.UNCHECKED;
  }

  isRecurring() {
    return this.props.added_by_user_id != null &&
           this.props.subtask_template_id != null;
  }

  renderFlag() {
    if (!this.props.canShowFlag || !this.isUnchecked()) {
      return null;
    }
    const outsideClickIgnoreClass = `ignore-onclickoutside-menu-flag-${this.props.id}`;
    return (
      <div>
        <a
          data-test='subtask.flag'
          onClick={ this.toggleFlagMenu }
          className={ `showtime-link showtime-link--default ${outsideClickIgnoreClass}` }
        >
          <SVGIcon
            classes='showtime-link__icon showtime-link__icon--left'
            iconName='mini/maps-location/location-16px-e_flag-07'
          />
        </a>
        <FlagOptionsMenu
          { ...{
            onSelectOption: this.selectFlagOption,
            showMenu: this.state.isFlagMenuOpen,
            align: 'left',
            disableOnClickOutside: !this.state.isFlagMenuOpen,
            onClickOutside: this.hideSubtaskFlagMenu,
            outsideClickIgnoreClass,
            canSetRecurring: this.props.canSetRecurring,
            added_by_user_id: this.props.added_by_user_id,
          } }
        />
      </div>
    );
  }

  toggleCompleted = () => {
    if (this.isUnchecked()) {
      SubTaskActions.changeValue(
        this.props, false, { value: SubTaskConstants.VALUES.DONE, completed: true }
      );
    } else {
      SubTaskActions.changeValue(
        this.props, false, { value: SubTaskConstants.VALUES.UNCHECKED, completed: false }
      );
    }
  };

  toggleFlagMenu = () => this.state.isFlagMenuOpen
    ? this.hideSubtaskFlagMenu()
    : this.showSubtaskFlagMenu();

  toggleEditMenu = () => this.state.isEditMenuOpen
    ? this.hideSubtaskEditMenu()
    : this.showSubtaskEditMenu();

  selectFlagOption = (value) => {
    SubTaskActions.changeValue(this.props, false, { value, completed: true });
    this.hideSubtaskFlagMenu();
    SubTaskActions.expandSubtask();
  };

  showSubtaskFlagMenu = () => {
    this.setState({ isFlagMenuOpen: true });
    SubTaskActions.expandSubtask();
  };

  hideSubtaskFlagMenu = () => {
    this.setState({ isFlagMenuOpen: false });
    SubTaskActions.expandSubtask();
  };

  hideSubtaskEditMenu = () => {
    this.setState({ isEditMenuOpen: false });
    SubTaskActions.hideSubtaskEditMenu();
  };

  showSubtaskEditMenu = () => {
    this.setState({ isEditMenuOpen: true });
    SubTaskActions.showSubtaskEditMenu();
  };

  render() {
    const subTaskClasses = classNames({
      'showtime-task__subtasks-item': true,
      'is-expanded': this.isFlagged(),
    });

    const { readOnlyComments } = this.props;

    const commentThreadLinkingProps = {
      commentableId: this.props.id,
      commentableType: commentableTypes.subtask,
      timelineTaskId: this.props.task_id,
      readOnly: readOnlyComments,
    };

    const showCommentButton = window.config.features.inlineComments && !readOnlyComments;

    return (
      <div
        className={ subTaskClasses }
        data-test={ `subtask-${this.props.name}` }
        data-test-pending-add-confirmation={ this.props.pendingAddConfirmation }
      >
        <div className='showtime-task__subtasks-checkbox'>
          <SubtaskCheckBox
            checkboxClasses='showtime-checkbox--stacked'
            label={ this.props.name }
            secondaryLabel={ this.formatCompletedAtLabel() }
            checked={ !this.isUnchecked() }
            dataTest='task.subtask'
            onChange={ this.toggleCompleted }
            value={ this.props.value }
          />
          {this.isRecurring() ?
            <RecurringTooltip />
            : null}
          <div className='showtime-task__subtasks-actions'>
            { this.renderMenu() }
            { this.renderFlag() }
            { showCommentButton && (
              <CommentButton { ...commentThreadLinkingProps } onClick={ this.toggleShowThread } />
            ) }
          </div>
        </div>
        { window.config.features.inlineComments && this.state.showThread && (
          <TaskContentCommentThread { ...commentThreadLinkingProps } />
        ) }
      </div>
    );
  }
}

Subtask.propTypes = {
  name: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  completed_at: PropTypes.string,
  pendingAddConfirmation: PropTypes.bool,
  completed_by: PropTypes.string,
  value: PropTypes.string,
  optionMenuCanShow: PropTypes.bool,
  canShowFlag: PropTypes.bool,
  task_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  canSetRecurring: PropTypes.bool,
  added_by_user_id: PropTypes.number,
  subtask_template_id: PropTypes.number,
  taskHeaderHeight: PropTypes.number.isRequired,
  readOnlyComments: PropTypes.bool,
};

Subtask.defaultProps = {
  canShowFlag: false,
  completed: false,
  value: SubTaskConstants.VALUES.UNCHECKED,
  completed_at: null,
  completed_by: null,
  optionMenuCanShow: false,
  pendingAddConfirmation: false,
  canSetRecurring: false,
  added_by_user_id: undefined,
  subtask_template_id: null,
  readOnlyComments: false,
};

export default injectIntl(Subtask);
