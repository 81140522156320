import React, { useState, useEffect, createRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import FileTextArea from '../file/FileTextArea';
import Button from '../common/Button';
import { postTaskContentComment } from './actions';
import { AttachmentConstants } from '../attachments/constants';

const { SAVED } = AttachmentConstants.State;

const SendCommentButton = ({ onClick }) => {
  const { formatMessage } = useIntl();
  return (
    <div className='showtime-conversation__comment-box-inline-save'>
      <Button
        small
        highlightAlt
        label={ formatMessage({ id: 'conversation.commentBox.sendButtonLabel' }) }
        onClick={ onClick }
        dataTest='taskContentComment.send'
      />
    </div>
  );
};

SendCommentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const TaskContentCommentBox = ({
  timelineTaskId,
  commentableId,
  commentableType,
  widgetRowId,
  widgetFieldName,
}) => {
  const [value, setValue] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const textareaRef = createRef(null);

  useEffect(() => {
    textareaRef.current.focus();
  }, []);

  const handleClear = () => {
    setValue('');
    setAttachment(null);
  };
  const noPendingUploads = !attachment || _.get(attachment, 'status') === SAVED;
  const hasContent = !!value || !!attachment;
  const canSend = hasContent && noPendingUploads;

  const onSubmit = async () => {
    setIsLoading(true);
    const { success } = await dispatch(postTaskContentComment({
      attachments: attachment ? [attachment.metadata] : [],
      content: value,
      linkingMetadata: {
        widgetRowId, widgetFieldName,
      },
      commentableId,
      commentableType,
      timelineTaskId,
    }));
    if (success) {
      handleClear();
    }
    setIsLoading(false);
  };
  return (
    <FileTextArea
      textValue={ isLoading ? '' : value }
      textareaRef={ textareaRef }
      onTextChange={ setValue }
      onAttachmentChange={ setAttachment }
      attachment={ isLoading ? null : attachment }
      placeholder={ formatMessage({ id: 'conversation.commentBox.placeholder' }) }
    >
      { canSend && (
        <SendCommentButton onClick={ onSubmit } />
      ) }
    </FileTextArea>
  );
};

TaskContentCommentBox.propTypes = {
  timelineTaskId: PropTypes.number.isRequired,
  commentableId: PropTypes.number.isRequired,
  commentableType: PropTypes.string.isRequired,
  widgetRowId: PropTypes.number,
  widgetFieldName: PropTypes.string,
};

TaskContentCommentBox.defaultProps = {
  widgetRowId: null,
  widgetFieldName: null,
};

export default TaskContentCommentBox;
