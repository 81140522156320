import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import { EditWidgetActions } from '../../../actions';
import LogicThen from './LogicThen';
import LogicIf from './LogicIf';
import SettingsHeaderRow from '../SettingsHeaderRow';
import BlockTitle from './BlockTitle';
import SVGIcon from '../../../../common/SVGIcon';

const { columnLogicByIndexSelector } = TaskWidgetSelectors;
const { removeColumnLogicItem } = EditWidgetActions;

const LogicJump = ({ columnIndex, logicIndex }) => {
  const { formatMessage } = useIntl();
  const { then = {} } = useSelector(state => (
    columnLogicByIndexSelector(state, { columnIndex, logicIndex })
  ));
  const dispatch = useDispatch();
  const handleRemove = () => dispatch(removeColumnLogicItem(columnIndex, logicIndex));

  return (
    <div className='showtime-record-log-builder-field__config-row' data-test='logic.item'>
      <SettingsHeaderRow
        onRemove={ handleRemove }
      />
      <BlockTitle>
        { formatMessage({ id: 'editWidget.builder.optionsColumn.logic.condition.if' }) }
      </BlockTitle>
      <LogicIf { ...{ columnIndex, logicIndex } } />
      <BlockTitle>
        <SVGIcon iconName='mini/arrows/arrows-16px-1_tail-triangle-down' classes='showtime-icon' />
        { formatMessage({ id: 'editWidget.builder.optionsColumn.logic.then.then' }) }
      </BlockTitle>
      { _.range(then.length).map(thenIndex => (
        <LogicThen { ...{ columnIndex, thenIndex, logicIndex } } />
      ))}
    </div>
  );
};
LogicJump.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  logicIndex: PropTypes.number.isRequired,
};

export default LogicJump;
