import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      fill={ (props.style && props.style.fill) || '#222221' }
      d='M1.667,15h12.667c0.755,0,1.238-0.805,0.882-1.471L8.882,1.654c-0.376-0.706-1.388-0.706-1.765,0 L0.784,13.529C0.429,14.195,0.912,15,1.667,15z M7,6h2v4H7V6z M8,11c0.552,0,1,0.448,1,1c0,0.552-0.448,1-1,1s-1-0.448-1-1 C7,11.448,7.448,11,8,11z'
    />
  </Svg>

);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
