import { cookieJar } from '../utils/window';

const getTogglerCheckbox = () => document.querySelector('#show-development-features');
const togglerCookieName = 'onlyShowFeaturesThatCustomerSees';

export const onlyShowFeaturesThatCustomerSees = () => {
  const togglerCheckbox = getTogglerCheckbox();

  if (togglerCheckbox) {
    return !togglerCheckbox.checked;
  }

  return true;
};

const setupShadowingFeaturesToggler = (togglerCheckbox) => {
  const onShadowingFeatureTogglerChange = () => {
    togglerCheckbox.removeEventListener('change', onShadowingFeatureTogglerChange);

    togglerCheckbox.checked
      ? cookieJar.remove(togglerCookieName)
      : cookieJar.set(togglerCookieName, 'true');

    document.location.reload();
  };

  togglerCheckbox.addEventListener('change', onShadowingFeatureTogglerChange);
};

export const setup = () => {
  const togglerCheckbox = getTogglerCheckbox();
  if (togglerCheckbox) {
    togglerCheckbox.checked = !cookieJar.get(togglerCookieName);
    setupShadowingFeaturesToggler(togglerCheckbox);
  }
};
