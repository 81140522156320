import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path, Rect } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' enable-background='new 0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M6.3,11.7l-2-2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L7,9.6l3.3-3.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-4,4C7.3,12.1,6.8,12.2,6.3,11.7z'
    />
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M15,1h-3v2h2v11H2V3h2V1H1C0.4,1,0,1.4,0,2v13c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V2C16,1.4,15.6,1,15,1z'
    />
    <Rect is='rect' x='5' fill={ (props.style && props.style.fill) || '#444' } width='6' height='4' />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
