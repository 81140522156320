import React from 'react';
import { injectIntl } from 'react-intl';
import { HelpMessage, HelpMessageLink } from '../common/HelpMessage';

const TimeSlotHelpMessage = ({ intl }) => (
  <HelpMessage
    message={ intl.formatMessage({ id: 'sites.edit.businessHours.timeslotHelpMessage' }) }
    markdown={ false }
  >
    <HelpMessageLink href='https://answers.trailapp.com/en/articles/2290868-business-hours-time-slots-closed-dates' metricName='Time slot'>
      { intl.formatMessage({ id: 'sites.edit.businessHours.timeslotHelpMessageLink' }) }
    </HelpMessageLink>
  </HelpMessage>
);

export default injectIntl(TimeSlotHelpMessage);
