import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import DropDownMenu from '../common/menu/DropDownMenu';
import BulkArchiveButton from './BulkArchiveButton';
import BulkDeleteButton from './BulkDeleteButton';

const BulkAssign = ({
  className, dataTest, onClick, intl, labelName,
}) => (
  <a
    className={ className }
    data-test={ dataTest }
    onClick={ onClick }
  >
    <span className='showtime-menu__list-item-text showtime-link--default'>{intl.formatMessage({ id: labelName })}</span>
  </a>
);

const MoreActionMenu = ({
  intl,
  handleArchiveConfirmation,
  handleDeleteConfirmation,
  onRemoveTag,
  onUnassignSites,
  showUnassign,
  showBulkTagging,
  showArchive,
  showDelete,
}) => {
  let dropDownMenu;

  const onRemoveTagClick = () => {
    onRemoveTag();
    dropDownMenu.closeMenu();
  };

  const onUnassignClick = () => {
    onUnassignSites();
    dropDownMenu.closeMenu();
  };

  return (
    <DropDownMenu
      containerClassName='showtime-layout-admin-management__bulk-actions-more-menu'
      dataTestKey='taskplanner.more'
      iconName='mini/arrows/arrows-16px-3_small-down'
      iconPosition='right'
      label={ intl.formatMessage({ id: 'taskplanner.menu.label' }) }
      ref={ (ref) => { dropDownMenu = ref; } }
    >
      <ul className='showtime-menu__list'>
        <li className='showtime-menu__list-item'>
          {showArchive && (
          <BulkArchiveButton
            label={ <span className='showtime-menu__list-item-text showtime-link--default'>{intl.formatMessage({ id: 'taskplanner.archive.label' })}</span> }
            title={ intl.formatMessage({ id: 'taskplanner.archive.title' }) }
            description={
            intl.formatMessage({ id: 'taskplanner.archive.description' })
          }
            abort={ intl.formatMessage({ id: 'taskplanner.archive.abort' }) }
            confirm={ intl.formatMessage({ id: 'taskplanner.archive.confirm' }) }
            onConfirm={ () => {
              dropDownMenu.closeMenu();
              handleArchiveConfirmation();
            } }
            onAbort={ () => { dropDownMenu.closeMenu(); } }
            classes={ classNames(
              'showtime-layout-admin-management__bulk-actions-archive_bulk-tagging--with-all-buttons',
              {
                'showtime-layout-admin-management__bulk-actions-archive_bulk-tagging--no-unassign': !showUnassign,
                'showtime-layout-admin-management__bulk-actions-archive_bulk-tagging--no-remove-tag': !showBulkTagging,
                'showtime-layout-admin-management__bulk-actions-archive_bulk-tagging--no-unassign-no-remove-tag': !showUnassign && !showBulkTagging,
              }
            ) }
          />
          )}
          { showDelete && (
          <BulkDeleteButton
            label={ <span className='showtime-menu__list-item-text showtime-link--default'>{intl.formatMessage({ id: 'taskplanner.delete.label' })}</span> }
            title={ intl.formatMessage({ id: 'taskplanner.delete.title' }) }
            description={
                intl.formatMessage({ id: 'taskplanner.delete.description' })
              }
            abort={ intl.formatMessage({ id: 'taskplanner.delete.abort' }) }
            confirm={ intl.formatMessage({ id: 'taskplanner.delete.confirm' }) }
            onConfirm={ () => {
              dropDownMenu.closeMenu();
              handleDeleteConfirmation();
            } }
            onAbort={ () => { dropDownMenu.closeMenu(); } }
            classes={ classNames(
              'showtime-layout-admin-management__bulk-actions-delete_bulk-tagging--with-all-buttons',
              {
                'showtime-layout-admin-management__bulk-actions-delete_bulk-tagging--no-unassign': !showUnassign,
                'showtime-layout-admin-management__bulk-actions-delete_bulk-tagging--no-remove-tag': !showBulkTagging,
                'showtime-layout-admin-management__bulk-actions-delete_bulk-tagging--no-unassign-no-remove-tag': !showUnassign && !showBulkTagging,
              }
            ) }
          />
          )}
          {showUnassign && (
            <BulkAssign
              className='showtime-link--menu-item showtime-link--menu-item-auto-width'
              dataTest='more-menu_unassignDropdown'
              onClick={ onUnassignClick }
              intl={ intl }
              labelName='taskplanner.unassign_button'
            />
          )}
          {showBulkTagging && (
            <BulkAssign
              className='showtime-link--menu-item showtime-link--menu-item-auto-width'
              dataTest='more-menu_remove_tags_from_tasksDropdown'
              onClick={ onRemoveTagClick }
              intl={ intl }
              labelName='taskplanner.remove_tags_from_tasks_button'
            />
          )}
        </li>
      </ul>
    </DropDownMenu>
  );
};

MoreActionMenu.propTypes = {
  handleArchiveConfirmation: PropTypes.func.isRequired,
  handleDeleteConfirmation: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  onUnassignSites: PropTypes.func.isRequired,
  showUnassign: PropTypes.bool.isRequired,
  showBulkTagging: PropTypes.bool.isRequired,
  showArchive: PropTypes.string.isRequired,
  showDelete: PropTypes.string.isRequired,
};

BulkAssign.propTypes = {
  className: PropTypes.string.isRequired,
  dataTest: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  labelName: PropTypes.string.isRequired,
};

export default injectIntl(MoreActionMenu);
