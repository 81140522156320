import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import DropDownMenu from '../common/menu/DropDownMenu';
import CheckBox from '../common/forms/CheckBox';
import { TaskReportsSelectors } from './selectors';
import applyFiltersToQuery from './applyFiltersToQuery';
import { createIntlMessageFormatter } from '../common/intlMessageFormatter';
import { PageNameConstants } from '../metrics/constants';

export const TaskReportActions = ({
  viewComments,
  viewChecklists,
  viewRecordLogs,
  history,
  intl,
}) => {
  const taskActionsFormatMessage = createIntlMessageFormatter(intl, 'taskReports.taskActions');
  const allVisible = viewChecklists && viewComments && viewRecordLogs;

  let viewSecondaryLabel;
  if (allVisible) {
    viewSecondaryLabel = taskActionsFormatMessage('allTaskContent');
  } else {
    viewSecondaryLabel = _.compact([
      taskActionsFormatMessage('taskHeadings'),
      viewChecklists ? taskActionsFormatMessage('checklists') : '',
      viewRecordLogs ? taskActionsFormatMessage('recordLogs') : '',
      viewComments ? taskActionsFormatMessage('comments') : '',
    ]);

    if (viewSecondaryLabel.length > 1) {
      const allLabelsButLast = viewSecondaryLabel.slice(0, viewSecondaryLabel.length - 1);
      viewSecondaryLabel = [
        allLabelsButLast.join(taskActionsFormatMessage('delimeter')),
        viewSecondaryLabel[viewSecondaryLabel.length - 1],
      ].join(taskActionsFormatMessage('and'));
    }
  }

  return (
    <div className='showtime-task-reports__actions'>
      <DropDownMenu
        dataTestKey='taskReportActions.view'
        label={ taskActionsFormatMessage('view') }
        iconName='mini/ui/ui-16px-1_eye-19'
        iconPosition='left'
        secondaryLabel={ viewSecondaryLabel }
      >
        <CheckBox
          dataTest='taskReportActions.view.taskHeadings'
          disabled
          checked
          label={ taskActionsFormatMessage('taskHeadings') }
        />
        <CheckBox
          dataTest='taskReportActions.view.checklists'
          onChange={ () => {
            applyFiltersToQuery(
              PageNameConstants.TASK_REPORTS, history, {
                boolFilters: { viewChecklists: !viewChecklists },
              }
            );
          } }
          checked={ viewChecklists }
          label={ taskActionsFormatMessage('checklists') }
        />
        <CheckBox
          dataTest='taskReportActions.view.recordLogs'
          onChange={ () => {
            applyFiltersToQuery(
              PageNameConstants.TASK_REPORTS, history, {
                boolFilters: { viewRecordLogs: !viewRecordLogs },
              }
            );
          } }
          checked={ viewRecordLogs }
          label={ taskActionsFormatMessage('recordLogs') }
        />
        <CheckBox
          dataTest='taskReportActions.view.comments'
          onChange={ () => {
            applyFiltersToQuery(
              PageNameConstants.TASK_REPORTS, history, {
                boolFilters: { viewComments: !viewComments },
              }
            );
          } }
          checked={ viewComments }
          label={ taskActionsFormatMessage('comments') }
        />
      </DropDownMenu>
      <DropDownMenu
        disabled
        label={ taskActionsFormatMessage('sortBy') }
        iconName='mini/arrows/arrows-16px-4_swap-vertical'
        iconPosition='left'
        secondaryLabel={ taskActionsFormatMessage('sortByMostRecent') }
      />
    </div>
  );
};

TaskReportActions.propTypes = {
  viewComments: PropTypes.bool.isRequired,
  viewChecklists: PropTypes.bool.isRequired,
  viewRecordLogs: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  viewComments: TaskReportsSelectors.viewCommentsSelector(state, ownProps),
  viewChecklists: TaskReportsSelectors.viewChecklistsSelector(state, ownProps),
  viewRecordLogs: TaskReportsSelectors.viewRecordLogsSelector(state, ownProps),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(TaskReportActions);
