import PropTypes from 'prop-types';

export const Group = () => null;

Group.propTypes = {
  children: PropTypes.node,
  onSelectChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Group.defaultProps = {
  children: null,
};
