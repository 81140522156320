import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button, { RIGHT } from '../../../common/Button';

const HideButton = ({ onClick, visible }) => (
  <Button
    buttonClasses='showtime-button--lowlight-subtle showtime-button--small'
    dataTest='hide.setting'
    onClick={ onClick }
    iconPosition={ RIGHT }
    iconName={ `mini/arrows/arrows-16px-1_minimal-${visible ? 'up' : 'down'}` }
  >
    { useIntl().formatMessage({ id: `editWidget.builder.${visible ? 'hide' : 'show'}` }) }
  </Button>
);
HideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const RemoveButton = ({ onClick }) => (
  <Button
    buttonClasses='showtime-button--lowlight-subtle showtime-button--small hint--top-left hint--info'
    dataTest='remove.setting'
    dataHint={ useIntl().formatMessage({ id: 'editWidget.builder.remove' }) }
    onClick={ onClick }
    iconName='/mini/ui/ui-16px-1_simple-remove'
  />
);
RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const SettingsHeaderRow = ({
  title, onRemove, canHide, children, className,
}) => {
  const [visible, onVisibilityChange] = useState(true);
  const handleVisibilityChange = () => onVisibilityChange(!visible);

  return (
    <React.Fragment>
      <div
        className={
          classNames('showtime-record-log-builder-field__config-row-header', className)
        }
      >
        <h5
          className={
            classNames(
              'showtime-record-log-builder-field__config-row-title',
              { 'showtime-record-log-builder-field__config-row-title--lowlight-subtle': canHide }
            )
          }
        >
          { title }
        </h5>
        { canHide
          ? <HideButton onClick={ handleVisibilityChange } visible={ visible } />
          : <RemoveButton onClick={ onRemove } />}
      </div>
      { visible && children }
    </React.Fragment>
  );
};

SettingsHeaderRow.propTypes = {
  title: PropTypes.node,
  onRemove: PropTypes.func,
  canHide: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

SettingsHeaderRow.defaultProps = {
  title: null,
  onRemove() {},
  canHide: false,
  children: null,
  className: '',
};

export default SettingsHeaderRow;
