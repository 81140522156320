import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Tab, Tabs } from '../common/Tabs';

export const TaskTemplatePlannerOptions = ({
  intl, libraries, activeOrgId, onSelect,
}) => (
  <Tabs activeTab={ activeOrgId ? `${activeOrgId}` : '' } onSelect={ onSelect }>
    <Tab
      dataTest='task-template-library'
      name=''
      onSelect={ onSelect }
    >{ intl.formatMessage({ id: 'taskplanner.yourTaskTemplates' }) }
    </Tab>
    { libraries.map(l => (
      <Tab
        dataTest={ `task-template-library-${l.id}` }
        key={ l.id }
        name={ `${l.id}` }
      >{ l.name }
      </Tab>
    ))}

  </Tabs>
);
TaskTemplatePlannerOptions.propTypes = {
  activeOrgId: PropTypes.number,
  libraries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })).isRequired,
  onSelect: PropTypes.func.isRequired,
};
TaskTemplatePlannerOptions.defaultProps = {
  activeOrgId: null,
};

export default injectIntl(TaskTemplatePlannerOptions);
