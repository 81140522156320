export const SubTaskConstants = {
  MARK_COMPLETED: 'mark_completed',
  MARK_COMPLETED_END: 'mark_completed_end',
  MARK_COMPLETED_ERROR: 'mark_completed_error',
  MARK_UNCOMPLETED: 'mark_uncompleted',
  MARK_UNCOMPLETED_ERROR: 'mark_uncompleted_error',
  VALUE_CHANGED: 'subtask_value_changed',
  CREATION_STARTED: 'subtask_creation_started',
  CREATION_FAILED: 'subtask_creation_failed',
  CREATION_SUCCEEDED: 'subtask_creation_succeeded',
  DELETION_STARTED: 'subtask_deletion_started',
  DELETION_FAILED: 'subtask_deletion_failed',
  DELETION_SUCCEEDED: 'subtask_deletion_succeeded',
  FOCUS_SUBTASK_INPUT: 'subtask_focus_input',
  ACTION_SUBTASKS_ADDED: 'subtask_action_subtasks_added',
  SUBTASK_ADDED_FROM_PUSHER: 'subtask_added_from_pusher',
  SUBTASK_REMOVED_FROM_PUSHER: 'subtask_removed_from_pusher',
  VALUES: {
    DONE: 'done',
    NOT_DONE: 'not_done',
    UNCHECKED: 'unchecked',
    NOT_APPLICABLE: 'not_applicable',
    NEVER_APPLICABLE: 'never_applicable',
  },
  RECURRING_VALUES: ['never_applicable'],
  BASE_URL: '/api/v1/subtasks',
  markCompletedUrl(id) {
    return `/api/subtasks/mark_completed/${id}.json`;
  },
  futureMarkCompletedUrl(id) {
    return `/api/v1/subtasks/mark_completed/${id}`;
  },
  markUncompletedUrl(id) {
    return `/api/subtasks/mark_uncompleted/${id}.json`;
  },
  futureMarkUncompletedUrl(id) {
    return `/api/v1/subtasks/mark_uncompleted/${id}`;
  },
  markRecurringUrl(id) {
    return `/api/subtasks/mark_recurring/${id}.json`;
  },
  unmarkRecurringUrl(id) {
    return `/api/subtasks/unmark_recurring/${id}.json`;
  },
};
