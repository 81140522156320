import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../common/Button';
import { createAction, updateAction } from './actions';
import { canSaveTaskActionSelector } from './selectors';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';

const sendActionMetric = (created) => {
  const event = created
    ? TrailMetricsDirectory.page.Trail.ACTION_TASK_CREATED
    : TrailMetricsDirectory.page.Trail.ACTION_TASK_UPDATED;
  metricsPublisher.recordMetric(event, {
    userRole: window.current_user.role,
    locationId: window.navigation.locationId,
  });
};

const SubmitButton = ({
  linkedTimelineTaskId,
  rowId,
  fieldName,
  onSubmitSuccess,
  modalTaskTemplateId,
  modalTimelineTaskId,
  actionTemplateId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const canSaveTaskAction = useSelector(canSaveTaskActionSelector);

  const [isSavingTaskAction, setIsSaving] = useState(false);

  const creatingFromActionTemplate = actionTemplateId && !modalTaskTemplateId;
  const disableButton = !(canSaveTaskAction || creatingFromActionTemplate);

  const onClick = async () => {
    if (disableButton) return;

    setIsSaving(true);
    const { success } = await dispatch(modalTaskTemplateId
      ? updateAction({
        actionTemplateId, modalTimelineTaskId, modalTaskTemplateId, linkedTimelineTaskId,
      })
      : createAction({
        actionTemplateId, linkedTimelineTaskId, rowId, fieldName,
      })
    );
    setIsSaving(false);
    if (success) {
      sendActionMetric(!modalTaskTemplateId);
      onSubmitSuccess();
    }
  };

  const messageId = modalTaskTemplateId ? 'update' : 'submit';

  return (
    <Button
      highlight
      blockOffline
      block
      intl={ intl }
      disabledHighlight={ disableButton }
      className='showtime-task-action__submit'
      iconName='mini/ui/ui-16px-1_check'
      dataTest='action.submit'
      onClick={ onClick }
      loading={ isSavingTaskAction }
    >
      {intl.formatMessage({ id: `trail.task.action.${messageId}` })}
    </Button>
  );
};
SubmitButton.propTypes = {
  linkedTimelineTaskId: PropTypes.number.isRequired,
  rowId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  modalTaskTemplateId: PropTypes.number,
  modalTimelineTaskId: PropTypes.number,
  actionTemplateId: PropTypes.number,
};

SubmitButton.defaultProps = {
  modalTaskTemplateId: null,
  modalTimelineTaskId: null,
  actionTemplateId: null,
};

export default SubmitButton;
