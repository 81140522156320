import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { SubTaskConstants, TrailConstants } from './constants';
import { SubTaskStore } from './store';
import { SubTaskActions } from './actions';
import SubTask from './Subtask';
import SubtaskInput from './SubtaskInput';
import SVGIcon from '../common/SVGIcon';
import EditableSubtaskInput from './EditableSubtaskInput';
import { isValidSubtaskGroupName } from '../common/subtaskValidator';
import { UserRole, getCurrentUserRole } from '../application/UserRole';

export class SubTaskList extends Component {
  canSetRecurring() {
    return !this.props.currentUserIsTeamRole && _.get(window.config, 'features.recurringSubtasks', false);
  }

  getSubtaskProps = subtask => ({
    ..._.pick(subtask, 'name', 'completed_by', 'completed_at', 'completed', 'value', 'name', 'id', 'added_by_user_id', 'subtask_template_id'),
    key: subtask.id,
    task_id: this.props.task_id,
    readOnlyComments: this.props.readOnlyComments,
    taskHeaderHeight: this.props.taskHeaderHeight,
  });

  getUncompletedSubtaskProps = subtask => ({
    ...this.getSubtaskProps(subtask),
    canSetRecurring: this.canSetRecurring(),
    canShowFlag: this.props.canShowFlags,
    ref: `subtask${subtask.id}`,
    'data-test': `uncomplete.subtask-${subtask.id}`,
  });

  getCompletedSubtaskProps = subtask => ({
    ...this.getSubtaskProps(subtask),
    'data-test': `completed.subtask-${subtask.id}`,
  });

  renderSubtaskGroupHeader(subtaskGroupName) {
    return isValidSubtaskGroupName(subtaskGroupName) ? (
      <div
        className='showtime-task__subtasks-item showtime-task__subtasks-item--heading'
        data-test='task.subtaskHeading'
      >
        { subtaskGroupName }
      </div>
    ) : null;
  }

  renderSubtask(props) {
    return (<SubTask { ...props } />);
  }

  renderEditableSubtask(subtask, subtaskPropFilter) {
    const id = subtask.id || subtask.temporaryId;
    const subtaskInEdit = (
      SubTaskStore.getFocusedSubtaskInput() === id
      && !this.shouldHideSubtaskAddComponents()
      && _.get(window.config, 'features.recurringSubtasks', false)
    );

    if (subtaskInEdit) {
      return (
        <EditableSubtaskInput
          { ...{ subtask, taskId: this.props.task_id, key: id } }
        />
      );
    }

    return this.renderSubtask({
      ...subtaskPropFilter({ ...subtask, id }),
      optionMenuCanShow: !this.shouldHideSubtaskAddComponents(),
      pendingAddConfirmation: !!subtask.temporaryId,
    });
  }

  renderSubtaskList(subtasks, subtaskPropFilter, wrappingDivProps, subtasksEditable) {
    const subtaskGroups = _.groupBy(subtasks, 'group_name');

    return (
      <div { ...wrappingDivProps }>
        { Object.keys(subtaskGroups).map(subtaskGroupName => (
          <React.Fragment>
            { this.renderSubtaskGroupHeader(subtaskGroupName) }
            { subtaskGroups[subtaskGroupName].map(subtask => (
              subtasksEditable ?
                this.renderEditableSubtask(subtask, subtaskPropFilter) :
                this.renderSubtask({ ...subtaskPropFilter(subtask) })
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  }

  renderUncompletedSubTaskList() {
    const uncompletedSubTasks = this.props.subtasks.filter((subtask) => {
      if (subtask.value === SubTaskConstants.VALUES.DONE) return false;
      if (subtask.added_by_user_id || subtask.temporaryId) return false;
      if (SubTaskConstants.RECURRING_VALUES.includes(subtask.value) &&
          this.props.currentUserIsTeamRole) {
        return false;
      }

      return true;
    });
    const subtasksEditable = false;

    return this.renderSubtaskList(
      uncompletedSubTasks,
      this.getUncompletedSubtaskProps,
      { 'data-test': 'subtasks.uncomplete.addedByWizard' },
      subtasksEditable
    );
  }

  renderUncompletedSubTasksAddedByUser() {
    const uncompletedSubTasksAddedByUser = this.props.subtasks.filter((subtask) => {
      if (subtask.value === SubTaskConstants.VALUES.DONE || subtask.pendingDeletion) return false;
      if (subtask.added_by_user_id || subtask.temporaryId) return true;

      return false;
    });
    const subtasksEditable = true;

    return this.renderSubtaskList(
      uncompletedSubTasksAddedByUser,
      this.getUncompletedSubtaskProps,
      {
        className: 'showtime-task__subtasks-added',
        'data-test': 'subtasks.uncomplete.addedByUser',
      },
      subtasksEditable
    );
  }

  taskStatusIsDone() {
    return this.props.status === TrailConstants.taskStatus.DONE;
  }

  shouldHideCompletedItems() {
    return (!this.props.showCompletedItems && !this.taskStatusIsDone());
  }

  renderCompletedSubTaskList() {
    const completedSubTasks = this.props.subtasks.filter((subtask) => {
      if (subtask.value !== SubTaskConstants.VALUES.DONE) return false;
      if (subtask.added_by_user_id || subtask.temporaryId) return false;

      return true;
    });
    const subtasksEditable = false;

    if (completedSubTasks.length < 1 || this.shouldHideCompletedItems()) {
      return null;
    }

    return this.renderSubtaskList(
      completedSubTasks,
      this.getCompletedSubtaskProps,
      { 'data-test': 'subtasks.complete.addedByWizard' },
      subtasksEditable
    );
  }

  renderCompletedSubTasksAddedByUser() {
    const completedSubTasksAddedByUser = this.props.subtasks.filter((subtask) => {
      if (subtask.value !== SubTaskConstants.VALUES.DONE) return false;
      if (subtask.added_by_user_id || subtask.temporaryId) return true;

      return false;
    });
    const subtasksEditable = false;

    if (completedSubTasksAddedByUser.length < 1 || this.shouldHideCompletedItems()) {
      return null;
    }

    return this.renderSubtaskList(
      completedSubTasksAddedByUser,
      this.getCompletedSubtaskProps,
      {
        className: 'showtime-task__subtasks-added',
        'data-test': 'subtasks.complete.addedByUser',
      },
      subtasksEditable
    );
  }

  renderCompletedSubTaskListToggle() {
    const completedSubTasks = _.filter(
      this.props.subtasks, { value: SubTaskConstants.VALUES.DONE });

    if (completedSubTasks.length < 1) return null;

    const iconClasses = classNames({
      'showtime-link__icon': true,
      'showtime-has-transition': true,
      'showtime-transition-rotate-90': !this.props.showCompletedItems,
      'showtime-transition-rotate-180': this.props.showCompletedItems,
    });

    const toggleClasses = classNames({
      'showtime-task__subtasks-item-toggle': true,
    });

    const iconContainerClasses = classNames({
      'showtime-link__icon-container': true,
      'showtime-link__icon-container--expanded': this.props.showCompletedItems,
      'showtime-link__icon-container--collapsed': !this.props.showCompletedItems,
    });

    const completedSubtaskLabel = this.generateSubTaskReadable(completedSubTasks.length);
    return (
      <a
        className={ toggleClasses }
        data-test='toggle'
        onClick={ this.props.toggleCompletedItemsHandler }
      >
        <span
          className='showtime-link showtime-link--positive'
        >
          <span className={ iconContainerClasses }>
            <SVGIcon
              classes={ iconClasses }
              iconName='mini/arrows/arrows-16px-1_minimal-up'
            />
          </span>
          { completedSubtaskLabel }
        </span>
      </a>
    );
  }

  generateSubTaskReadable(count) {
    return this.props.intl.formatMessage({ id: 'trail.subtasks.completed' }, { count });
  }

  shouldHideSubtaskAddComponents() {
    return (
      !this.props.showAddSubtaskInput
      || this.taskStatusIsDone()
      || this.props.currentUserIsTeamRole
      || this.props.taskIsLocked
      || getCurrentUserRole()
        .isPermissionDisabled(UserRole.permissionTypes.MANAGE_LOCATION_SUBTASKS)
    );
  }

  renderSubtaskInput() {
    if (this.shouldHideSubtaskAddComponents()) { return null; }
    return (
      <SubtaskInput
        taskId={ this.props.task_id }
        onSave={ SubTaskActions.createSubtask }
        canSetRecurring={ this.canSetRecurring() }
      />
    );
  }

  render() {
    const subTaskListClasses = classNames({
      'showtime-task__subtasks': true,
    });

    return (
      <div className={ subTaskListClasses }>
        { this.renderUncompletedSubTaskList() }
        { this.renderUncompletedSubTasksAddedByUser() }
        { !SubTaskStore.getFocusedSubtaskInput() && this.renderSubtaskInput() }
        { this.renderCompletedSubTaskListToggle() }
        { this.renderCompletedSubTaskList() }
        { this.renderCompletedSubTasksAddedByUser() }
      </div>
    );
  }
}

SubTaskList.propTypes = {
  status: PropTypes.string,
  subtasks: PropTypes.array,
  task_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  canShowFlags: PropTypes.bool,
  showCompletedItems: PropTypes.bool,
  toggleCompletedItemsHandler: PropTypes.func,
  showAddSubtaskInput: PropTypes.bool,
  currentUserIsTeamRole: PropTypes.bool,
  readOnlyComments: PropTypes.bool,
  taskIsLocked: PropTypes.bool,
  taskHeaderHeight: PropTypes.number.isRequired,
};

SubTaskList.defaultProps = {
  readOnlyComments: false,
  subtasks: [],
  showAddSubtaskInput: false,
  canShowFlags: false,
  currentUserIsTeamRole: true,
  taskIsLocked: true,
  status: undefined,
  task_id: undefined,
  showCompletedItems: undefined,
  toggleCompletedItemsHandler: undefined,
};

export default injectIntl(SubTaskList);
