import { TagFilterConstants, TrailConstants } from '../../trail/constants';
import { FluxBridgeMiddlewareConstants } from '../../middleware/flux-bridge';
import { cookieJar } from '../../utils/window';

const TagFilterActions = {
  open: () => ({
    type: TagFilterConstants.OPEN,
  }),

  close: () => ({
    type: TagFilterConstants.CLOSE,
  }),

  loading: tag => ({
    type: TagFilterConstants.LOADING,
    tag,
  }),

  select: (tags, { includeTasksWithoutTags, isAssignedToMe }) => ({
    type: TagFilterConstants.SELECT,
    tags,
    includeTasksWithoutTags,
    isAssignedToMe,
  }),

  deselect: (tag, userId) => {
    // Warning - contains side effect of updating tag options in cookies
    const tagShell = {
      tags: [],
    };
    const tagCookieKey = TagFilterConstants.USER_COOKIE_KEY(userId);
    const tagCookie = cookieJar.getObject(tagCookieKey) || tagShell;

    tagCookie.tags = tagCookie.tags.filter(tagCookie => tag.id !== tagCookie);
    cookieJar.set(tagCookieKey, JSON.stringify(tagCookie));

    return {
      type: TagFilterConstants.DESELECT,
      tag,
    };
  },

  clearAll() {
    return {
      type: TagFilterConstants.CLEAR_ALL,
    };
  },

  filter(selectedTags, includeTasksWithoutTags, isAssignedToMe) {
    return {
      type: FluxBridgeMiddlewareConstants.DISPATCH,
      fluxActionType: TrailConstants.tags.FILTER,
      options: {
        selectedTags,
        includeTasksWithoutTags,
        isAssignedToMe,
      },
    };
  },

  filterTasksWithNoTags() {
    return {
      type: FluxBridgeMiddlewareConstants.DISPATCH,
      fluxActionType: TrailConstants.tags.FILTER_TASKS_WITH_NO_TAGS,
    };
  },

  filterIsAssignedToMeTask() {
    return {
      type: FluxBridgeMiddlewareConstants.DISPATCH,
      fluxActionType: TrailConstants.tags.IS_ASSIGNED_TO_ME_TASK,
    };
  },

  setTaskWithoutTagsToLoading() {
    return {
      type: TagFilterConstants.SET_TAGS_WITHOUT_TAGS_TO_LOADING,
    };
  },

  setIsAssignedToMeLoading() {
    return {
      type: TagFilterConstants.SET_ASSIGN_TO_ME_TASKS_TO_LOADING,
    };
  },
};

export { TagFilterActions };
