export const WidgetDataConstants = {
  SUBMITTED: 'widget_data.submitted',
  SUBMIT_KEY: '@WIDGET_DATA submit',
  SAVE_KEY: '@WIDGET_DATA save',
  FETCH_BATCH_KEY: '@WIDGET_DATA fetch_batch',
  FETCH_WIDGET_KEY: '@WIDGET_DATA fetch_widget',
  RESET_KEY: '@WIDGET_DATA reset',
  UNSUBMITTED: 'widget_data.unsubmitted',
  SAVE: 'widget_data.save',
  SET_STORE: 'widget_data.set_store',
  STORE_SET: 'widget_data.store_set',
  SAVE_SUCCESS: 'widget_data.save_success',
  RESET: 'widget_data.reset',
  QUEUE_AUTOSAVE: 'widget_data.queue_autosave',
  ROW_FIELDS_CLEARED: 'widget_data.row_fields_cleared',
  CUSTOM_FIELD_CHANGED: 'widget_data.custom_field_changed',
  TIMER_CHANGED: 'widget_data.timer_changed',
  ACTION_WIDGET_ADDED: 'widget_data.action_widget_added',
  CUSTOM_FIELD_ROW_CHANGED: 'widget_data.custom_field_row_changed',
  CUSTOM_FIELD_ROW_REMOVED: 'widget_data.custom_field_row_removed',
  CUSTOM_FIELD_ROW_ADDED: 'widget_data.custom_field_row_added',
  WIDGET_DATA_UPDATED: 'widget_data.updated',
  WIDGET_UPDATED_BY_PUSHER: 'widget_data.updated_by_pusher',
  WIDGET_AUTOSAVE_UPDATED: 'widget_data.autosave_updated',
  ON_CUSTOM_FIELD_ROW_SET: 'widget_data.on_custom_field_row_set',
  STORE_REBUILT: 'widget_data.store_rebuilt',
  REQUEST_CUSTOM_ROW_DELETE: 'widget_data.request_custom_row_delete',
  ON_CUSTOM_ROW_DELETED: 'widget_data.on_custom_row_deleted',
  WIDGET_UPDATED: 'widget_data.widget_updated',
  BATCH_ROUTE: '/api/widget_data/batch',
  FUTURE_BATCH_ROUTE: '/api/v1/widget_data/batch',
  POLLING_INTERVAL_PERIOD: 10000,
};
