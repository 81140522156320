import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import SelectField from '../../../../common/forms/SelectField';
import { NO_SELECTION } from '../../../../common/forms/Select';
import ValidationMessages from '../../../../common/forms/ValidationMessages';
import { analyzeErrors } from '../../../../common/constants/validation';
import { JUMP_TO_END_OF_RECORD_LOG } from '../../../constants';

import ColumnIcon from '../ColumnIcon';
import SVGIcon from '../../../../common/SVGIcon';

const FieldDropdown = ({
  disabled, dataTest, onChange, errors, columnOptions, selectedColumn, includeJumpToEnd, value,
}) => {
  const { formatMessage } = useIntl();

  const { cellType: selectedFieldType } = selectedColumn || {};

  if (selectedColumn && !_.find(columnOptions, { cellSettings: { fieldName: value } })) {
    columnOptions = [selectedColumn, ...columnOptions];
  }
  const jumpToEndSelected = value === JUMP_TO_END_OF_RECORD_LOG;
  const selectOptions = columnOptions.map(column => ({
    value: _.get(column, 'cellSettings.fieldName', null),
    label: column.columnTitle,
  }));
  if (includeJumpToEnd || jumpToEndSelected) {
    selectOptions.push({
      value: JUMP_TO_END_OF_RECORD_LOG,
      label: formatMessage({ id: 'editWidget.builder.optionsColumn.logic.then.jumpToEnd' }),
    });
  }

  const [focused, setIsFocused] = useState(false);

  const {
    isValid,
    anyValidationErrors,
    allValidationWarnings,
  } = analyzeErrors(errors);

  return (
    <div
      data-test={ dataTest }
      className={
        classNames({ 'is-invalid': anyValidationErrors, 'is-warning': allValidationWarnings })
}
    >
      <div
        className={
          classNames('showtime-record-log-builder-field--column-dropdown',
            {
              'showtime-record-log-builder-field--column-dropdown__disabled': disabled,
              'showtime-record-log-builder-field--column-dropdown__focused': focused,
            })
        }
      >
        { selectedFieldType && <ColumnIcon cellType={ selectedFieldType } hideTooltips /> }
        { jumpToEndSelected && (
          <SVGIcon
            classes='showtime-record-log-builder-field__header-icon'
            iconName='mini/ui/ui-16px-1_check-circle-08'
          />
        ) }
        <SelectField
          onChange={ onChange }
          containerClass='showtime-record-log-builder-field--select__wide'
          includeEmptyOption
          value={ (selectedColumn || jumpToEndSelected) ? value : NO_SELECTION }
          disabled={ disabled }
          onFocus={ () => setIsFocused(true) }
          onBlur={ () => setIsFocused(false) }
          options={ selectOptions }
          errors={ [] }
        />
      </div>
      {!isValid
        && (
        <ValidationMessages
          fieldId={ value }
          errors={ errors }
        />
        )}
    </div>
  );
};

FieldDropdown.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  columnOptions: PropTypes.array,
  selectedColumn: PropTypes.object,
  dataTest: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  includeJumpToEnd: PropTypes.bool,
};

FieldDropdown.defaultProps = {
  value: null,
  disabled: false,
  columnOptions: [],
  selectedColumn: null,
  dataTest: null,
  onChange() {},
  errors: [],
  includeJumpToEnd: false,
};

export default FieldDropdown;
