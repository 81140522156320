import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { createIntlMessageFormatter } from '../../intlMessageFormatter';
import Button from '../../Button';
import ConfirmableButton from '../../ConfirmableButton';

const MoreMenuItem = (
  { onTrigger, intl, isConfirmable, confirmAlign, action, iconName, intlPath, dataTest }
) => {
  const buttonBaseClass = 'showtime-link';
  const buttonClasses = classNames(
    'showtime-link--default',
    'showtime-link--block',
    'showtime-link--menu-item',
  );
  const iconClasses = classNames(
    'showtime-link__icon',
    'showtime-link__icon--left',
  );
  const itemMessage = createIntlMessageFormatter(intl, intlPath);

  return (
    <li className='showtime-menu__list-item'>
      { isConfirmable
        ? <ConfirmableButton
          { ...{
            buttonLabel: itemMessage('buttonLabel'),
            buttonIcon: iconName,
            buttonClasses: classNames(buttonBaseClass, buttonClasses),
            iconClasses,
            dataTest,
            title: itemMessage('title'),
            description: itemMessage('description'),
            abort: itemMessage('abort'),
            confirm: itemMessage('confirm'),
            onConfirm: onTrigger(action),
            isDestructive: true,
            align: confirmAlign,
          } }
        />
        : <Button
          { ...{
            label: itemMessage('buttonLabel'),
            iconName,
            baseClass: buttonBaseClass,
            buttonClasses,
            buttonIconClass: iconClasses,
            dataTest,
            onClick: onTrigger(action),
          } }
        />
      }
    </li>
  );
};

MoreMenuItem.propTypes = {
  onTrigger: PropTypes.func.isRequired,
  isConfirmable: PropTypes.bool,
  confirmAlign: PropTypes.string,
  action: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  intlPath: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
};

MoreMenuItem.defaultProps = {
  isConfirmable: false,
  confirmAlign: 'left',
  iconName: '',
  dataTest: 'menu.more.item',
};

export default injectIntl(MoreMenuItem);
