import _ from 'lodash';

export const tagsRequestStatusSelector = state => state.tags.status;

export const tagsSortedByNameSelector = state => _.chain(state)
  .get('tags.data', {}).values()
  .map(tag => ({ ...tag, label: tag.attributes.label }))
  .sortBy('label')
  .value();

export const tagsSelector = state => _.chain(state.tagFilter.tags)
  .values().sortBy('label').value();

export const selectedAndLoadingTagIdsSelector = state => _.chain(state.tagFilter.tags)
  .values()
  .filter(tag => tag.selected || tag.loading)
  .map('id')
  .value();

export const selectedTagIdsSelector = state => _.chain(state.tagFilter.tags)
  .values()
  .filter({ selected: true }).map('id')
  .value();

export const includeTasksWithoutTagsSelector = state => _.get(
  state.tagFilter, 'includeTasksWithoutTags', { selected: false });

export const isAssignedToMeSelector = state => _.get(
  state.tagFilter, 'isAssignedToMe', { selected: false });

export const selectedTagCountSelector = ({
  tagFilter: { tags, includeTasksWithoutTags, isAssignedToMe },
}) => {
  const tagCount = _.reduce(tags, (count, tag) => (tag.selected) ? count + 1 : count, 0);
  const countFor = filter => filter.selected ? 1 : 0;
  return tagCount + countFor(includeTasksWithoutTags) + countFor(isAssignedToMe);
};
