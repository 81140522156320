import { getColumnTitleErrors } from './columnTitle';
import { getOptionsColumnErrors } from './optionsColumn';
import { getNumberColumnAcceptableRangeErrors } from './numberColumnAcceptableRange';
import { getNumberColumnLookupRangeErrors } from './numberColumnLookupRange';

export const getAllColumnErrors = (column) => {
  if (!column) return [];

  const { columnTitle, cellSettings } = column;
  let errors = getColumnTitleErrors(columnTitle);

  if (column.cellType === 'OPTIONS') {
    const { labels, values } = cellSettings;
    errors = errors.concat(
      getOptionsColumnErrors(labels, values)
    );
  }

  if (column.cellType === 'FORMATTED_NUMBER') {
    const { minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText } = cellSettings;
    errors = errors.concat(
      getNumberColumnAcceptableRangeErrors(
        minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText)
    );

    const { validation: rules } = cellSettings;
    errors = errors.concat(getNumberColumnLookupRangeErrors(rules));
  }

  return errors;
};
