import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { getFilestackReadSignature } from '../actions';
import { getFileReadPolicyForHandle } from '../selectors';
import { isExpired } from '../securityValidation';
import { withOnline } from '../../online-context';

export const readPolicyHOC = Element => class ReadPolicy extends Component {
  static propTypes = {
    getSignature: PropTypes.func,
    handle: PropTypes.string,
    signature: PropTypes.string,
    expiry: PropTypes.number,
    policy: PropTypes.string,
    isLoading: PropTypes.bool,
    isOnline: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    getSignature() {},
    handle: undefined,
    signature: undefined,
    expiry: undefined,
    policy: undefined,
    isLoading: undefined,
  };

  constructor(props) {
    super(props);

    this.fetchReadPolicyIfNeeded(props);
  }

  componentDidUpdate = () => this.fetchReadPolicyIfNeeded(this.props);

  fetchReadPolicyIfNeeded({
    handle, expiry, isLoading, isOnline, getSignature,
  }) {
    const { config: { filestackApiKey } = {} } = window;
    const noValidOrFetchingReadPolicy = isExpired(expiry) && !isLoading;
    const fileCanBeViewed = filestackApiKey && handle;
    const shouldFetch = isOnline && fileCanBeViewed && noValidOrFetchingReadPolicy;

    if (shouldFetch) getSignature();
  }

  render() {
    const { expiry, policy, signature } = this.props;
    const omittedProps = ['expiry', 'policy', 'signature', 'getSignature', 'isLoading', 'isOnline'];
    return (
      <Element
        { ...{
          ..._.omit(this.props, ...omittedProps),
          ...!isExpired(expiry) && { policy, signature },
        } }
      />
    );
  }
};

const mapStateToProps = (store, ownProps) => getFileReadPolicyForHandle(store, ownProps);

const dispatchActionsToProps = (dispatch, ownProps) => {
  const handles = [ownProps.handle];
  const getSignature = () => dispatch(getFilestackReadSignature({ handles }));
  return { getSignature };
};

export default compose(
  withOnline,
  connect(
    mapStateToProps,
    dispatchActionsToProps,
  ),
  readPolicyHOC,
);
