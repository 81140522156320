import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TaskHelpTextActions } from '../task-wizard/actions';
import { descriptionSelector } from '../task-wizard/selectors';
import FileTextArea from '../file/FileTextArea';
import { attachmentsSelector } from './selectors';
import { setAttachments } from './actions';
import Label from '../common/forms/Label';

const TaskDescriptionAndFilesTextArea = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const description = useSelector(descriptionSelector);
  const onDescriptionChange = v => dispatch(TaskHelpTextActions.change(v));
  const setAttachment = attachment => dispatch(setAttachments(attachment ? [attachment] : []));
  const [attachment = null] = useSelector(attachmentsSelector) || [];

  const placeholder = formatMessage({ id: 'trail.task.action.descriptionPlaceholder' });
  const label = formatMessage({ id: 'taskWizard.helpText.title' });

  return (
    <div className='showtime-task-action__file-textarea'>
      <Label>{label}</Label>
      <FileTextArea
        disabled={ readOnly }
        placeholder={ placeholder }
        textValue={ description }
        onTextChange={ onDescriptionChange }
        attachment={ attachment }
        onAttachmentChange={ setAttachment }
        dataTest='action.descriptionAndFiles'
        stacked
      />
    </div>
  );
};

TaskDescriptionAndFilesTextArea.propTypes = {
  readOnly: PropTypes.bool,
};

TaskDescriptionAndFilesTextArea.defaultProps = {
  readOnly: false,
};

export default TaskDescriptionAndFilesTextArea;
