import React from 'react';
import PropTypes from 'prop-types';

import { columnPropTypes } from '../constants';
import Colgroups from './Colgroups';
import Head from './Head';
import Body from './Body';

const DataCaptureTable = ({
  widgetId,
  timelineTaskId,
  columns,
  errors,
  rows,
  setListMode,
  isTaskCompleted,
  hideTaskContentComments,
  router,
  routePrefix,
  timeZone,
}) => (
  <div className='showtime-widget-v2__overview'>
    <div className='showtime-widget-v2__overview-inner'>
      <table className='showtime-table showtime-table--full-width' data-test='data-capture.table'>
        <Colgroups columns={ columns } />
        <Head columns={ columns } />
        <Body
          { ...{
            widgetId,
            timelineTaskId,
            columns,
            errors,
            rows,
            setListMode,
            isTaskCompleted,
            hideTaskContentComments,
            router,
            routePrefix,
            timeZone,
          } }
        />
      </table>
    </div>
  </div>
);

DataCaptureTable.propTypes = {
  ...columnPropTypes,
  widgetId: PropTypes.number,
  timelineTaskId: PropTypes.number,
  errors: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  setListMode: PropTypes.func.isRequired,
  isTaskCompleted: PropTypes.bool.isRequired,
  hideTaskContentComments: PropTypes.bool,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
};

DataCaptureTable.defaultProps = {
  widgetId: null,
  timelineTaskId: null,
  hideTaskContentComments: false,
  routePrefix: '',
};

export default DataCaptureTable;
