import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SaveStatus from './SaveStatus';

class Header extends Component {
  _displayAvailableLogo() {
    if (this.props.logoUrl) {
      return (
        <img src={this.props.logoUrl} />
      );
    }

    return '';
  }

  render() {
    return (
      <div className='widget-header'>
        <div className='grid-block'> 
          <div className='grid-block vertical'>
            <div className="text-md grid-block">
              {this._displayAvailableLogo()}
            </div>
            <div className="text-md grid-block">
              {this.props.message}
            </div>
          </div>
          <div className="grid-block shrink">
            {this.props.additionalContent}
          </div>
          <SaveStatus saving={this.props.saving} />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  saving: PropTypes.bool,
  message: PropTypes.object,
  logoUrl: PropTypes.string,
  additionalContent: PropTypes.object,
};

export default Header;
