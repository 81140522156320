import _ from 'lodash';

import { RequestConstants } from '../../request';
import { USERS_FETCH_KEY } from '../constants';

export const initialStore = {
  loaded: false,
  entities: {},
};

export const usersReducer = (store, action) => {
  store = store || initialStore;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(USERS_FETCH_KEY):
      return {
        loaded: true,
        entities: _.keyBy(action.content, 'id'),
      };
    default:
      return store;
  }
};
