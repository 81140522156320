import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import DropdownFilter from '../../common/DropdownFilter';
import { ALL, filterTypeIcons, filterTypes } from '../constants';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

export const Filter = ({
  hasLoaded, items, groups,
  intl: { formatMessage },
  queryIds,
  setQueryIds,
  clearQueryIds,
  onFilterClear,
  initiallyExpanded,
  filterType,
  selectAll,
  showSearch,
  onClickOutside,
  onExpand,
  onItemsSelect,
  additionalCheckboxClasses,
  selectAllSecondaryLabel,
  exclusionWhenAllSelected,
  selectFromGroupId,
  filterMetric,
  multiSelect,
}) => {
  const handleClear = () => {
    clearQueryIds();
    onFilterClear();
  };

  const selectableItems = selectFromGroupId
    ? _.chain(groups).find({ id: selectFromGroupId }).get('items').value()
    : items || _.flatMap(groups, 'items');

  const handleSelectAll = ({ isSelected }) => {
    if (isSelected && !exclusionWhenAllSelected) {
      setQueryIds([ALL]);
      return;
    }
    if (isSelected && exclusionWhenAllSelected) {
      setQueryIds(_.map(selectableItems, 'id'));
      return;
    }
    clearQueryIds();
  };

  const selectAllProps = selectAll && {
    onSelectAll: handleSelectAll,
    allSelected: queryIds.includes(ALL),
  };

  const { allSelected, onSelectAll } = selectAllProps;

  const groupsWithCheckedParents = groups && groups.map((group) => {
    if (!selectFromGroupId) return group;
    if (selectFromGroupId === group.id) return group;
    return ({
      ...group,
      items: group.items.map(
        (item) => {
          const childItemIds = _.chain(selectableItems).filter({ parentId: item.id }).map('id').value();
          const allChildItemsSelected = !_.chain(childItemIds).difference(queryIds).size().value();
          const allValueSelected = queryIds.includes(ALL);
          const isSelected = item.isSelected || allChildItemsSelected || allValueSelected;
          return ({ ...item, isSelected });
        }),
    });
  });

  const lastParentGroup = useRef();
  useEffect(() => {
    if (!filterMetric || !groupsWithCheckedParents || !hasLoaded) return;

    const [parentGroup] = groupsWithCheckedParents;

    const changedParents = parentGroup.items.reduce((accu, item, index) => {
      const prev = _.get(lastParentGroup, ['current', index], parentGroup.items[index]);
      if (prev.isSelected === item.isSelected) return accu;
      return [...accu, item];
    }, []);

    changedParents.forEach(({ id, isSelected }) => {
      metricsPublisher.recordMetric(
        TrailMetricsDirectory.page[filterMetric].GROUP_FILTER_APPLIED,
        {
          id, groupId: parentGroup.id, isSelected, filterType,
        }
      );
    });

    lastParentGroup.current = parentGroup.items;
  }, [hasLoaded, filterMetric, filterType, groupsWithCheckedParents]);

  const handleItemSelect = (item) => {
    if (onItemsSelect) {
      onItemsSelect(item);
      return;
    }

    const { id, isSelected, groupId } = item;

    if (!multiSelect) {
      setQueryIds([id]);
      return;
    }

    const ids = selectFromGroupId && groupId !== selectFromGroupId
      ? _.chain(selectableItems).filter({ parentId: id }).map('id').value()
      : [id];

    const deselectingFromAll = queryIds.includes(ALL);
    if (deselectingFromAll) {
      setQueryIds(_.chain(selectableItems).map('id').without(...ids).value());
      return;
    }

    const updatedIds = isSelected
      ? _.uniq([...queryIds, ...ids])
      : _.without(queryIds, ...ids);

    const allSelectableItemsSelected = updatedIds.length === _.size(selectableItems);
    if (selectAll && allSelectableItemsSelected && !exclusionWhenAllSelected) {
      setQueryIds([ALL]);
      return;
    }

    setQueryIds(updatedIds);
  };

  return (
    <DropdownFilter
      selectAllSecondaryLabel={ selectAllSecondaryLabel }
      additionalCheckboxClasses={ additionalCheckboxClasses }
      dataTest={ `filterDropdown.${filterType.toLowerCase()}` }
      iconName={ filterTypeIcons[filterType] }
      label={ formatMessage({ id: `taskReports.filters.${filterType.toLowerCase()}` }) }
      isLoading={ !hasLoaded }
      onClearAll={ handleClear }
      allSelected={ allSelected }
      onSelectAll={ onSelectAll }
      selectedItemIds={ queryIds }
      items={ items }
      groups={ groupsWithCheckedParents }
      onItemsSelect={ handleItemSelect }
      initiallyExpanded={ initiallyExpanded }
      showSearch={ showSearch }
      onClickOutside={ onClickOutside }
      onExpand={ onExpand }
      selectFromGroupId={ selectFromGroupId }
      exclusionWhenAllSelected={ exclusionWhenAllSelected }
      multiSelect={ multiSelect }
    />
  );
};

Filter.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.array,
  groups: PropTypes.array,
  queryIds: PropTypes.array.isRequired,
  setQueryIds: PropTypes.func.isRequired,
  clearQueryIds: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func,
  initiallyExpanded: PropTypes.bool,
  filterType: PropTypes.oneOf(Object.values(filterTypes)).isRequired,
  selectAll: PropTypes.bool,
  showSearch: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onExpand: PropTypes.func,
  onItemsSelect: PropTypes.func,
  selectAllSecondaryLabel: PropTypes.string,
  additionalCheckboxClasses: PropTypes.string,
  exclusionWhenAllSelected: PropTypes.bool,
  selectFromGroupId: PropTypes.string,
  filterMetric: PropTypes.string,
  multiSelect: PropTypes.bool,
};

Filter.defaultProps = {
  selectAll: false,
  showSearch: true,
  onFilterClear() {},
  filterMetric: null,
  initiallyExpanded: false,
  onClickOutside() {},
  onExpand() {},
  onItemsSelect: undefined,
  groups: undefined,
  selectAllSecondaryLabel: undefined,
  additionalCheckboxClasses: undefined,
  items: undefined,
  exclusionWhenAllSelected: false,
  selectFromGroupId: undefined,
  multiSelect: true,
};

export default injectIntl(Filter);
