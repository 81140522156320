import moment from 'moment-timezone';
import { TimeslotConstants } from './constants';

export const timeslotHelper = (function () {
  const applyOffset = function (dayOffset, time) {
    return time.add(dayOffset, TimeslotConstants.DAYS);
  };

  const sortTimeslotByStartTime = function (firstTimeslot, secondTimeslot) {
    let firstTimeslotStartTime = moment(firstTimeslot.start.fmt, TimeslotConstants.TIME_FMT);
    let secondTimeslotStartTime = moment(secondTimeslot.start.fmt, TimeslotConstants.TIME_FMT);

    firstTimeslotStartTime = applyOffset(firstTimeslot.start.dayOffset, firstTimeslotStartTime);
    secondTimeslotStartTime = applyOffset(secondTimeslot.start.dayOffset, secondTimeslotStartTime);

    if (firstTimeslotStartTime.isBefore(secondTimeslotStartTime)) {
      return -1;
    }
    if (firstTimeslotStartTime.isAfter(secondTimeslotStartTime)) {
      return 1;
    }
    return 0;
  };

  return {
    sortTimeslotByStartTime,
    applyOffset,
  };
}());
