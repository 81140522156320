import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../common/Button';
import { TOP } from '../interaction-blocker/positions';
import { SMALL } from '../interaction-blocker/sizes';
import OfflineInteractionBlocker from '../offline-interaction-blocker';
import WindowHelper from '../utils/window';
import CaptureCamera from './CameraCapture';
import { filestackClient } from './filestackApi';
import { pickPolicyHOC } from './hoc';
import { createIntlMessageFormatter } from '../common/intlMessageFormatter';

export const FilePicker = ({
  intl,
  multiUpload,
  disabled,
  allowCamera,
  onUploadDone,
  onFileUploadFailed,
  onProgress,
  iconOnly,
  onUploadRequested,
  signature,
  policy,
  expiry,
}) => {
  const formatMessage = createIntlMessageFormatter(intl, 'file.filePicker');

  const hasSecurity = signature !== null;

  useEffect(() => {
    onUploadRequested();
  }, []);

  const client = () => filestackClient({
    security: {
      signature,
      policy,
      expiry,
    },
    onUploadDone,
    onFileUploadFailed,
    onProgress,
  });

  const { capture: captureSupported } = WindowHelper.browserSupport();

  const isDisabled = !hasSecurity || disabled;

  const buttonClasses = classNames(
    'showtime-button',
    {
      'is-disabled': isDisabled,
      'showtime-button--adjacent': captureSupported,
      'showtime-button--input-file': iconOnly,
      'showtime-button--default': !iconOnly,
    }
  );

  let label = '';
  if (!iconOnly) label = captureSupported ? formatMessage('captureSupported') : formatMessage('captureNotSupported');

  return (
    <div className='filepicker__buttons'>
      {
        allowCamera && (
        <CaptureCamera
          label={ iconOnly ? '' : formatMessage('captureCameraLabel') }
          disabled={ isDisabled }
          onCapture={ ({ target }) => {
            const { files: [file] } = target;
            client().capture(file);
          } }
        />
        )
      }
      <OfflineInteractionBlocker
        tooltipMessage={ formatMessage('notAvailable') }
        size={ SMALL }
        position={ TOP }
      >
        <Button
          dataTest='filePicker.upload'
          label={ label }
          iconName='glyph/24px/ui/ui-24px-glyph-1_attach-87'
          buttonClasses={ buttonClasses }
          onClick={ () => { client().open(multiUpload); } }
        />
      </OfflineInteractionBlocker>
    </div>
  );
};

FilePicker.propTypes = {
  multiUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  allowCamera: PropTypes.bool,
  onUploadDone: PropTypes.func.isRequired,
  onFileUploadFailed: PropTypes.func,
  onProgress: PropTypes.func,
  onUploadRequested: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool,
  signature: PropTypes.string,
  policy: PropTypes.string,
  expiry: PropTypes.number,
};

FilePicker.defaultProps = {
  multiUpload: true,
  disabled: false,
  allowCamera: true,
  iconOnly: false,
  signature: null,
  policy: null,
  expiry: null,
  onProgress: () => {},
  onFileUploadFailed: () => {},
};

const mapStateToProps = (state, props) => ({
  ...props,
  ...state.fileSecurities.pick,
});

export default compose(
  injectIntl,
  pickPolicyHOC,
  connect(mapStateToProps)
)(FilePicker);
