import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Input, {
  NUMBER,
  TEL,
  TEXT,
  EMAIL,
} from './Input';
import NumberInput from './NumberInput';
import Label from './Label';
import { analyzeErrors } from '../constants/validation';
import ValidationMessages from './ValidationMessages';
import Affix, {
  PREFIX as AFFIX_PREFIX,
  SUFFIX as AFFIX_SUFFIX,
} from './Affix';
import {
  CopyValueToNewLabel,
  LockedLabel,
} from './AnnotationLabels';

export const InputField = ({
  containerClass,
  disabled,
  readOnly,
  locked,
  errors,
  id,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onKeyDown,
  placeholder,
  prefix,
  refFn,
  repeating,
  required,
  suffix,
  type,
  value,
  dataTest,
  min,
  max,
  inputChildren,
  children: renderChild,
  labelEmphasis,
  subtext,
  whole,
}) => {
  const isEmpty = value === '';
  const {
    isValid,
    anyValidationErrors,
    allValidationWarnings,
  } = analyzeErrors(errors);

  const containerClasses = classNames(
    containerClass, {
      'is-empty': disabled && isEmpty,
      'is-invalid': anyValidationErrors,
      'is-warning': allValidationWarnings,
      'is-restricted': disabled && !isEmpty,
      'is-disabled': readOnly,
    }
  );
  const inputContainerClasses = classNames(
    'showtime-input',
    'showtime-input--block',
    {
      'showtime-input--number': type === NUMBER,
      'showtime-input--text': type === TEXT,
      'showtime-input--prefix': Boolean(prefix),
      'showtime-input--suffix': Boolean(suffix),
    }
  );

  const Field = type === NUMBER ? NumberInput : Input;

  return (
    <div className={ containerClasses }>
      {label && (
        <Label htmlFor={ id } required={ required } emphasis={ labelEmphasis }>{label}</Label>
      ) }
      <div className={ inputContainerClasses }>
        {prefix
          && <Affix position={ AFFIX_PREFIX }>{prefix}</Affix>}
        <Field
          disabled={ disabled || readOnly }
          id={ id }
          maxLength={ maxLength }
          name={ name }
          onBlur={ onBlur }
          onChange={ onChange }
          placeholder={ placeholder }
          onKeyDown={ onKeyDown }
          refFn={ refFn }
          required={ required }
          type={ type }
          value={ value }
          dataTest={ dataTest }
          min={ min }
          max={ max }
          fullWidth
          whole={ whole }
        />
        {suffix
          && <Affix position={ AFFIX_SUFFIX }>{suffix}</Affix>}
        { inputChildren }
      </div>
      {!isValid
        && (
        <ValidationMessages
          fieldId={ id }
          errors={ errors }
          someErrors={ anyValidationErrors }
        />
        )}
      {locked
        && <LockedLabel />}
      {repeating && isValid
        && <CopyValueToNewLabel />}
      { renderChild && renderChild(isValid) }
      {subtext && <span data-test='input-subtext' className='showtime-input__subtext'>{subtext}</span>}
    </div>
  );
};

InputField.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  refFn: PropTypes.func,
  repeating: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf([
    NUMBER,
    TEL,
    TEXT,
    EMAIL,
  ]),
  value: PropTypes.string,
  dataTest: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  children: PropTypes.func,
  inputChildren: PropTypes.node,
  labelEmphasis: PropTypes.bool,
  subtext: PropTypes.string,
  whole: PropTypes.bool,
};

InputField.defaultProps = {
  containerClass: '',
  disabled: false,
  readOnly: false,
  locked: false,
  maxLength: null,
  name: '',
  onBlur: () => { },
  onKeyDown: undefined,
  placeholder: '',
  prefix: '',
  refFn: null,
  repeating: false,
  required: false,
  suffix: '',
  type: TEXT,
  value: undefined,
  dataTest: undefined,
  min: undefined,
  max: undefined,
  children: undefined,
  inputChildren: undefined,
  labelEmphasis: false,
  subtext: undefined,
  whole: false,
};

export default InputField;
