import _ from 'lodash';
import TagConstants from '../../tags/constants';
import { RequestConstants } from '../../request';
import { tagValidator } from '../../tags';

export const tagManagementTableReducer = (function () {
  function getSelectedTag(tags, selectedTagId) {
    return _.find(tags, tag => tag.id === selectedTagId);
  }

  function reduce(state, action) {
    const initialState = {
      isCreatingNewTag: false,
      content: {
        tags: [],
      },
    };
    const UNIMPLEMENTED_ASSOCIATED_TASKS_PLACEHOLDER = '';

    const currentState = state || _.extend({}, initialState);
    let tagData;
    let tags;
    let changesToState;
    let tagsAfterCreate;
    let fetchedTags;
    let tagsAfterDelete;
    let tagsAfterUpdate;
    let selectedTag;
    let newUnsavedTag;
    switch (action.type) {
      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_LOADED):
        tagData = _.cloneDeep(action.content.data);
        fetchedTags = tagData.map(tagDataEntry => ({
          id: tagDataEntry.id,
          tagLabel: tagDataEntry.attributes.label,
          editLabelValue: tagDataEntry.attributes.label,
          showEditMenu: false,
          showDeleteMenu: false,
          totalTasksLabel: UNIMPLEMENTED_ASSOCIATED_TASKS_PLACEHOLDER,
          valid: true,
        }));
        changesToState = {
          content: {
            tags: fetchedTags,
          },
        };
        return _.extend({}, currentState, changesToState);

      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_DELETED):
        tags = _.cloneDeep(currentState.content.tags);
        tagsAfterDelete = _.reject(tags, tag => tag.id === action.content.deletedTagId);
        changesToState = {
          content: {
            tags: tagsAfterDelete,
          },
        };
        return _.extend({}, currentState, changesToState);

      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_CREATED):
        tags = _.cloneDeep(currentState.content.tags);
        tagsAfterCreate = _.reject(tags, tag => tag.id === null);
        tagData = action.content.data;
        tagsAfterCreate.push({
          id: tagData.id,
          tagLabel: tagData.attributes.label,
          editLabelValue: tagData.attributes.label,
          showEditMenu: false,
          showDeleteMenu: false,
          totalTasksLabel: UNIMPLEMENTED_ASSOCIATED_TASKS_PLACEHOLDER,
          valid: true,
        });
        changesToState = {
          content: {
            tags: tagsAfterCreate,
          },
          isCreatingNewTag: false,
        };
        return _.merge({}, currentState, changesToState);

      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_UPDATED):
        tagsAfterUpdate = _.cloneDeep(currentState.content.tags);
        selectedTag = getSelectedTag(tagsAfterUpdate, action.content.tagId);
        selectedTag.showEditMenu = false;
        selectedTag.tagLabel = action.content.label;

        changesToState = {
          content: {
            tags: tagsAfterUpdate,
          },
        };
        return _.extend({}, currentState, changesToState);

      case TagConstants.TOGGLE_DELETE_DIALOG:
        tags = _.cloneDeep(currentState.content.tags);
        selectedTag = getSelectedTag(tags, action.content.tagId);
        selectedTag.showDeleteMenu = !selectedTag.showDeleteMenu;
        changesToState = {
          content: {
            tags,
          },
        };
        return _.merge({}, currentState, changesToState);

      case TagConstants.VALIDATE_TAG:
        tags = _.cloneDeep(currentState.content.tags);
        selectedTag = getSelectedTag(tags, action.content.tagId);
        selectedTag.valid = tagValidator.isLabelValid(action.content.labelToValidate);
        selectedTag.editLabelValue = action.content.labelToValidate;
        changesToState = {
          content: {
            tags,
          },
        };
        return _.extend({}, currentState, changesToState);

      case TagConstants.CANCEL_TAG_EDIT:
        tags = _.cloneDeep(currentState.content.tags);
        selectedTag = getSelectedTag(tags, action.content.tagId);
        if (selectedTag.id === null) {
          tags = _.reject(tags, tag => tag.id === action.content.tagId);
        } else {
          selectedTag.showEditMenu = false;
        }
        changesToState = {
          content: {
            tags,
          },
          isCreatingNewTag: selectedTag.id === null ? false : currentState.isCreatingNewTag,
        };
        return _.extend({}, currentState, changesToState);

      case TagConstants.SHOW_NEW_TAG_DIALOG:
        if (currentState.isCreatingNewTag) return currentState;
        newUnsavedTag = {
          id: null,
          tagLabel: null,
          showEditMenu: true,
          editLabelValue: '',
          showDeleteMenu: false,
          totalTasksLabel: UNIMPLEMENTED_ASSOCIATED_TASKS_PLACEHOLDER,
          valid: false,
        };
        tags = _.cloneDeep(currentState.content.tags);
        tags.push(newUnsavedTag);
        changesToState = {
          content: {
            tags,
          },
          isCreatingNewTag: true,
        };
        return _.merge({}, changesToState);

      case TagConstants.SHOW_TAG_EDIT_DIALOG:
        tags = _.cloneDeep(currentState.content.tags);
        selectedTag = getSelectedTag(tags, action.content.tagId);
        selectedTag.showEditMenu = true;
        selectedTag.editLabelValue = selectedTag.tagLabel;
        changesToState = {
          content: {
            tags,
          },
        };
        return _.merge({}, currentState, changesToState);

      default:
        return currentState;
    }
  }
  return reduce;
}());
