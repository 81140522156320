import _ from 'lodash';

import rulesDict from './rules';

export default (rules, value, hasAction) => {
  if (rules.length < 1) {
    return [];
  }
  const errors = _.chain(rules)
    .reduce((acc, {
      key,
      message,
      options,
    }) => {
      const error = rulesDict[key](value, message, options, hasAction);
      return error ? [...acc, error] : acc;
    }, [])
    .sortBy('type')
    .value();
  return errors;
};
