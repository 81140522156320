import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

import SettingsHeaderRow from './SettingsHeaderRow';
import RadioButton from '../../../common/RadioButton';
import AcceptableRangeMinMax from './AcceptableRangeMinMax';
import AcceptableDependentRangeMinMax from './AcceptableDependentRangeMinMax';

class NumberRange extends Component {
  state = {
    showDependentRange: !_.isEmpty(this.props.rules),
  };

  handleSelectIndependentRange = () => this.setState({ showDependentRange: false });

  handleSelectDependentRange = () => {
    const [{ value: initialRuleColumn } = {}] = this.props.optionsColumns;
    this.props.onDependentRangeInitialize(initialRuleColumn);
    this.setState({ showDependentRange: true });
  };

  render() {
    const {
      columnIndex, onRemoveRange, optionsColumns, intl, errors,
    } = this.props;

    const showRadioButtons = !_.isEmpty(optionsColumns);

    const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.numberColumn');

    return (
      <div className='showtime-record-log-builder-field__config-row showtime-record-log-builder-field__config-row--number'>
        <SettingsHeaderRow
          title={ formatMessage('rangeLabel') }
          onRemove={ onRemoveRange }
        />
        { showRadioButtons && (
          <React.Fragment>
            <RadioButton
              onChange={ this.handleSelectIndependentRange }
              checked={ !this.state.showDependentRange }
              dataTest='range.independent'
            >
              { formatMessage('setRange') }
            </RadioButton>
            <RadioButton
              onChange={ this.handleSelectDependentRange }
              checked={ this.state.showDependentRange }
            >
              { formatMessage('setDependentRange') }
            </RadioButton>
          </React.Fragment>
        )}
        { !this.state.showDependentRange && (
          <AcceptableRangeMinMax columnIndex={ columnIndex } errors={ errors } />
        )}
        { this.state.showDependentRange && (
          <AcceptableDependentRangeMinMax
            columnIndex={ columnIndex }
            optionsColumns={ optionsColumns }
          />
        )}
      </div>
    );
  }
}

NumberRange.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  onRemoveRange: PropTypes.func.isRequired,
  onRulesClear: PropTypes.func.isRequired,
  optionsColumns: PropTypes.any.isRequired,
  rules: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  onDependentRangeInitialize: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { columnIndex }) => {
  const { settings } = TaskWidgetSelectors.editedTaskWidgetConfig(state);
  const {
    rules = {},
  } = _.get(settings, `public.columns[${columnIndex}].cellSettings.validation`, {});

  const optionsColumns = settings.public.columns.reduce((accu, column) => {
    const notOptionsColumn = column.cellType !== 'OPTIONS';
    if (notOptionsColumn) return accu;

    const option = {
      value: column.cellSettings.fieldName,
      label: column.columnTitle,
      values: column.cellSettings.values,
      labels: column.cellSettings.labels,
    };
    return [...accu, option];
  }, []);

  return { rules, settings, optionsColumns };
};

const mapDispatchToProps = (dispatch, { columnIndex, onRemove }) => {
  const {
    clearRulesFromColumn,
    clearAllValidationFromColumn,
    setDependentRuleForColumn,
  } = bindActionCreators(EditWidgetActions, dispatch);

  const onRulesClear = () => clearRulesFromColumn(columnIndex);
  const onRemoveRange = () => {
    clearAllValidationFromColumn(columnIndex);
    onRemove();
  };
  const onDependentRangeInitialize = value => setDependentRuleForColumn(columnIndex, value);

  return { onRulesClear, onRemoveRange, onDependentRangeInitialize };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(NumberRange);
