import { RequestConstants, RequestActions } from '../../request';
import { OrganizationSettingsConstants } from '../constants';

export const OrganizationSettingsActions = {
  getSettings() {
    return RequestActions.request({
      url: OrganizationSettingsConstants.getOrUpdateSettingsRoute(
        window.current_user.organization_id
      ),
      key: OrganizationSettingsConstants.GET_SETTINGS_KEY,
      method: RequestConstants.GET,
      errorMessage: OrganizationSettingsConstants.messages.GET_SETTINGS_FAILED,
    });
  },
  updateSettings(formattedUpdateObject) {
    return RequestActions.request({
      url: OrganizationSettingsConstants.getOrUpdateSettingsRoute(
        window.current_user.organization_id
      ),
      key: OrganizationSettingsConstants.UPDATE_SETTINGS_KEY,
      method: RequestConstants.PUT,
      errorMessage: OrganizationSettingsConstants.messages.UPDATE_SETTINGS_FAILED,
      content: {
        updateObject: formattedUpdateObject,
      },
      options: {
        body: JSON.stringify({
          settings: formattedUpdateObject,
        }),
      },
    });
  },
};
