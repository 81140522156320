import _ from 'lodash';

import { FETCH_TASK_TEMPLATES_KEY } from './constants';
import { requestStatuses } from '../common/requestStatuses';
import { RequestConstants } from '../request';

const loading = RequestConstants.getLoadingActionType(FETCH_TASK_TEMPLATES_KEY);
const loaded = RequestConstants.getLoadedActionType(FETCH_TASK_TEMPLATES_KEY);
const errored = RequestConstants.getErrorActionType(FETCH_TASK_TEMPLATES_KEY);

const actionHandlers = {
  [loading]: state => ({
    ...state,
    status: requestStatuses.LOADING,
  }),
  [loaded]: (state, action) => ({
    status: requestStatuses.LOADED,
    entities: _.keyBy(action.content, 'id'),
  }),
  [errored]: state => ({
    ...state,
    status: requestStatuses.ERROR,
  }),
};

const INITIAL_STATE = { status: requestStatuses.NOT_REQUESTED, entities: {} };

export const taskTemplates = (state, action) => {
  state = state || INITIAL_STATE;
  const handler = actionHandlers[action.type];
  if (handler) return handler(state, action);

  return state;
};
