export const actionTypes = {
  route: '@ROUTER route_to',
};

const taskTemplateLibraryRoute = '/library/:libraryOrgId';
const matchAllCharactersRegex = '(.*)';

const TaskPlannerRoutes = {
  NEW_TASK: '/task/new',
  NEW_TASK_ON_DEMAND: '/task/new?ondemand=true',
  PREVIEW_TASK: `${matchAllCharactersRegex}/task/preview/:previewTaskId`,
  EDIT_TASK: '/task/:editTaskId',
  COPY_TASK: '/task/copy/:copyTaskId',
  ADD_LIBRARY_TASK: `${taskTemplateLibraryRoute}/task/add/:libraryTaskTemplateId`,
  TASK_TEMPLATE_LIBRARY_URL: taskTemplateLibraryRoute,
  TAG_MANAGEMENT: '/tags',
  EDIT_WIDGET: '/record-log/:id',
  WIDGET_PARTIAL: 'record-log',
  TASKS_PARTIAL: 'tasks',
  LIBRARY_PLANNER_MATCH: /\/library\/\d+\//,
};

const ReportsRoutes = {
  ACTIVITY: '/activity',
  SCORES: '/scores',
  TASK_REPORTS: '/tasks',
};

const TrailRoutes = {
  ADD_TASK: '/addTask',
  SEARCH_TASKS: '/searchTasks',
  TASK_HISTORY: '/history/:id',
  TASK_HISTORY_PARTIAL: 'history',
  TRAIL_TASK: '/task/:id',
  FILE_VIEWER: '/file/:id',
  TRAIL_TASK_FILE_VIEWER: '/task/:id/file/:id',
  LINK_VIEWER: '/link/:id',
  TRAIL_TASK_REPORTS: '/task-history',
  TRAIL_TASK_REPORTS_PARTIAL: 'task-history',
  TASK_REPORTS_SEARCH_TASKS: '/search-tasks',
};

const SitesRoutes = {
  NEW_SITE: '/site/new',
  EDIT_SITE: '/site/:id',
};

const UsersRoutes = {
  ROLES: '/roles',
  NEW_ROLE: '/roles/new',
  EDIT_ROLE: '/roles/:id([0-9]+)',
  editRoleUrl: roleId => `/roles/${roleId}`,
};

const buildUrl = (url, parameters) => (
  Object.keys(parameters)
    .reduce((parsedUrl, parameterName) => parsedUrl.replace(`:${parameterName}`, parameters[parameterName]), url)
);

const serverRoutes = {
  TRAIL_URL: '/trail',
  TASK_PLANNER_URL: '/taskplanner',
};

const RouterConstants = {
  CATCH_ALL_PATH: '*',
  BASE_URL: '/',

  ...TaskPlannerRoutes,
  ...ReportsRoutes,
  ...TrailRoutes,
  ...SitesRoutes,
  ...UsersRoutes,
  ...serverRoutes,

  NESTED_PANEL_ROUTES: [
    TaskPlannerRoutes.WIDGET_PARTIAL,
  ],
  PANEL_BASE_MATCHES: [
    TaskPlannerRoutes.LIBRARY_PLANNER_MATCH,
  ],

  NESTED_OVERLAY_ROUTES: [],
  NESTED_OVERLAY_PARTIAL_ROUTES: [
    TaskPlannerRoutes.TASKS_PARTIAL,
    TrailRoutes.TASK_HISTORY_PARTIAL,
    TrailRoutes.TRAIL_TASK_REPORTS_PARTIAL,
  ],

  buildPreviewTaskUrl: (previewTaskId, libraryOrgId) => {
    const taskPlannerRoute = libraryOrgId ? taskTemplateLibraryRoute : '';
    const previewTaskRoute = TaskPlannerRoutes.PREVIEW_TASK.replace(matchAllCharactersRegex, '');

    return buildUrl(`${taskPlannerRoute}${previewTaskRoute}`, { previewTaskId, libraryOrgId });
  },

  buildEditTaskUrl: editTaskId => buildUrl(TaskPlannerRoutes.EDIT_TASK, { editTaskId }),
  buildCopyTaskUrl: copyTaskId => buildUrl(TaskPlannerRoutes.COPY_TASK, { copyTaskId }),
  buildEditWidgetUrl: id => buildUrl(TaskPlannerRoutes.EDIT_WIDGET, { id }),
  buildAddLibraryTaskUrl: (libraryOrgId, libraryTaskTemplateId) => (
    buildUrl(TaskPlannerRoutes.ADD_LIBRARY_TASK, { libraryOrgId, libraryTaskTemplateId })
  ),
  buildTaskTemplateLibraryUrl: libraryOrgId => (
    buildUrl(TaskPlannerRoutes.TASK_TEMPLATE_LIBRARY_URL, { libraryOrgId })
  ),

  buildTaskHistoryUrl: id => buildUrl(TrailRoutes.TASK_HISTORY, { id }),
  buildTrailTaskUrl: id => buildUrl(TrailRoutes.TRAIL_TASK, { id }),

  buildEditSiteUrl: id => buildUrl(SitesRoutes.EDIT_SITE, { id }),
};

export default RouterConstants;
