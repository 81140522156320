import _ from 'lodash';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '../../../common/constants/validation';

export const IS_REQUIRED = 'isRequired';
export const IS_WITHIN_RANGE = 'isWithinRange';
export const IS_NAN = 'isNaN';
export const HAS_EXCEPTIONAL_OPTION_SELECTED = 'hasExceptionalOptionSelected';
export const REQUIRES_ACTION = 'requiresAction';

export const createError = (message, key) => ({
  type: VALIDATION_ERROR, message, key,
});
export const createWarning = (message, key) => ({
  type: VALIDATION_WARNING, message, key,
});

const has = value => ![undefined, null, ''].includes(value);
const aNumber = value => !Number.isNaN(Number(value));
const aNumberInRange = (value, min, max) => (!aNumber(value) || value < min || value > max);

const isWithinRangeRule = (
  value, message, { range: [min, max] = [] }
) => {
  const rangeIsInvalid = !_.every([min, max], _.isNumber);
  if (rangeIsInvalid) return null;

  if (has(value) && aNumberInRange(value, min, max)) return createWarning(message, IS_WITHIN_RANGE);
  return null;
};

const hasExceptionalOptionSelectedRule = (value, defaultMessage, ruleConfig) => {
  const values = ruleConfig.values || [];
  if (!has(value) || !values.length) return null;

  const optionIndex = values.indexOf(value);
  const optionsExceptions = ruleConfig.optionsExceptions || [];
  const optionRaisesException = optionsExceptions[optionIndex] || false;

  const optionsExceptionsWarningTexts = ruleConfig.optionsExceptionsWarningTexts || [];
  const message = optionsExceptionsWarningTexts[optionIndex] || defaultMessage;
  if (optionRaisesException) return createWarning(message, HAS_EXCEPTIONAL_OPTION_SELECTED);

  return null;
};

export default {
  [IS_REQUIRED]: (value, message) => (!has(value))
    ? createError(message, IS_REQUIRED)
    : null,

  [REQUIRES_ACTION]: (value, message, ruleConfig, hasAction) => {
    if (hasAction) return null;

    const optionIsExceptional = !!hasExceptionalOptionSelectedRule(value, message, ruleConfig);
    const optionRequiresMandatoryAction = _.get(
      ruleConfig, ['requireMandatoryOptionAction', _.indexOf(ruleConfig.values, value)], false);
    if (optionIsExceptional && optionRequiresMandatoryAction) {
      return createError(message, REQUIRES_ACTION);
    }

    const numberIsOutOfRange = !!isWithinRangeRule(value, message, ruleConfig);
    const numberRulesRequiresMandatoryAction = ruleConfig.requireMandatoryValidationAction;

    if (numberIsOutOfRange && numberRulesRequiresMandatoryAction) {
      return createError(message, REQUIRES_ACTION);
    }

    return null;
  },

  [IS_WITHIN_RANGE]: isWithinRangeRule,

  [HAS_EXCEPTIONAL_OPTION_SELECTED]: hasExceptionalOptionSelectedRule,

  [IS_NAN]: (value, message) => (Number.isNaN(value)
    ? createError(message, IS_NAN)
    : null
  ),
};
