import _ from 'lodash';

import {
  CLEAR_TASKS,
  TOGGLE_VIEW_FILTERS,
  FETCH_TASK_REPORTS_REQUEST_BATCH_COMPLETE,
  TASK_REPORTS_TASK_TEMPLATES_URL,
  FETCH_TASK_TEMPLATES_KEY,
  COUNT_TASK_REPORTS_URL,
  FETCH_COUNT_TASK_REPORTS_KEY,
  SET_EXPORT_MODE,
  TASK_REPORTS_EXPORT_CSV_URL,
  TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_URL,
  TASK_REPORTS_EXPORT_CSV_KEY,
  TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_KEY,
  SET_SELECTED_TASK_IDS,
} from '../constants';
import { RequestActions, RequestConstants } from '../../request';
import { TaskReportsSelectors } from '../selectors';
import { isNotRequested } from '../../common/requestStatuses';

import doFetchTaskReports from './doFetchTaskReports';
import random from '../../common/random';
import { fetchLocations } from '../../sites/actions';
import { UsersActions } from '../../users/actions';

export const fetchTaskTemplates = () => (dispatch, getState) => {
  const requestStatus = TaskReportsSelectors.taskTemplatesRequestStatusSelector(getState());
  if (!isNotRequested(requestStatus)) return Promise.resolve();

  return dispatch(RequestActions.request({
    url: TASK_REPORTS_TASK_TEMPLATES_URL,
    key: FETCH_TASK_TEMPLATES_KEY,
    method: RequestConstants.GET,
    errorMessage: 'taskReports.timelineTasksFail',
  }));
};

const fetchUserFilterInformation = () => (dispatch) => {
  dispatch(fetchLocations());
  const orgId = _.get(window, 'current_user.home_timeline.organization_id');
  dispatch(UsersActions.fetchOrganizationUsers(orgId));
};

export const fetchTaskCount = location => (dispatch, getState) => {
  const taskReportsBody = TaskReportsSelectors.taskReportsRequestBodySelector(
    getState(), { location });

  return dispatch(RequestActions.request({
    url: COUNT_TASK_REPORTS_URL,
    key: FETCH_COUNT_TASK_REPORTS_KEY,
    method: RequestConstants.POST,
    errorMessage: 'taskReports.timelineTaskCountFail',
    options: {
      body: JSON.stringify(taskReportsBody),
    },
    content: { requestId: random() },
  }));
};

let fetchTaskReportsRequestId = null;

const DEFAULT_TO_AVOID_ACCIDENTAL_INFINITE_LOOP = 30;

const fetchTaskReports = (location, includeCount = false) => async (dispatch, getState) => {
  fetchTaskReportsRequestId = random();
  let pageSize = DEFAULT_TO_AVOID_ACCIDENTAL_INFINITE_LOOP;
  let total = 0;
  let results = null;

  await dispatch(fetchTaskTemplates());
  const taskTemplateIds = TaskReportsSelectors.visibleTaskTemplateIdsSelector(
    getState(), { location });
  if (_.isEmpty(taskTemplateIds)) return Promise.resolve({});

  includeCount && dispatch(fetchTaskCount(location));

  while (total < pageSize) {
    // It is not possible to fetch in parallel
    // eslint-disable-next-line no-await-in-loop
    results = await dispatch(doFetchTaskReports(fetchTaskReportsRequestId, location));

    if (!results || !results.success) return Promise.resolve({});
    if (results.requestId !== fetchTaskReportsRequestId) return Promise.resolve({});

    const { success: { body: { page } } } = results;

    const taskCountFulfilled = TaskReportsSelectors.taskCountFulfilledSelector(
      getState(), { location });
    const shouldWindow = page.windowing_date && !taskCountFulfilled;
    if (!shouldWindow) break;

    pageSize = page.page_size || DEFAULT_TO_AVOID_ACCIDENTAL_INFINITE_LOOP;
    total += results.success.body.data.length;
  }

  dispatch({ type: FETCH_TASK_REPORTS_REQUEST_BATCH_COMPLETE });
  return results;
};

const clearTasks = () => ({ type: CLEAR_TASKS });

const setExportMode = value => ({ type: SET_EXPORT_MODE, value });

const fetchCSVReport = location => (dispatch, getState) => {
  const taskReportsBody = TaskReportsSelectors.taskReportsRequestBodySelector(
    getState(), { location });

  return dispatch(RequestActions.request({
    url: TASK_REPORTS_EXPORT_CSV_URL,
    key: TASK_REPORTS_EXPORT_CSV_KEY,
    method: RequestConstants.POST,
    errorMessage: 'taskReports.timelineTasksFail',
    successMessage: 'taskReports.exportWorked',
    options: { body: JSON.stringify(taskReportsBody) },
  }));
};

const fetchCSVExceptionsReport = location => (dispatch, getState) => {
  const taskReportsBody = TaskReportsSelectors.taskReportsRequestBodySelector(
    getState(), { location });

  return dispatch(RequestActions.request({
    url: TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_URL,
    key: TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_KEY,
    method: RequestConstants.POST,
    errorMessage: 'taskReports.timelineTasksFail',
    successMessage: 'taskReports.exportWorked',
    options: { body: JSON.stringify(taskReportsBody) },
  }));
};

const setSelectedTaskIds = value => ({ type: SET_SELECTED_TASK_IDS, value });

const toggleViewFilters = () => ({ type: TOGGLE_VIEW_FILTERS });

export default {
  fetchTaskReports,
  clearTasks,
  fetchCSVReport,
  fetchCSVExceptionsReport,
  toggleViewFilters,
  setExportMode,
  setSelectedTaskIds,
  fetchUserFilterInformation,
};
