import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { TaskReportsActions } from '../actions';
import ExportOption from '../../common/ExportOption';

const mapDispatchToProps = dispatch => ({
  onSetPDFExport: () => dispatch(TaskReportsActions.setExportMode(true)),
});

const PDFExportOption = ({ intl: { formatMessage }, onSetPDFExport, onClick }) => {
  const handleClick = () => {
    onSetPDFExport();
    onClick();
  };

  return (
    <ExportOption
      iconName='/custom/attachment-icons/file-pdf'
      onClick={ handleClick }
      dataTest='share.pdfExport'
    >
      { formatMessage({ id: 'share.pdfExport' }) }
    </ExportOption>
  );
};

PDFExportOption.propTypes = {
  onSetPDFExport: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  connect(null, mapDispatchToProps)
)(PDFExportOption);
