import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M8,0C3.6,0,0,3.1,0,7c0,3.9,3.6,7,8,7c0.2,0,0.4,0,0.6,0l5.4,2v-4.4c1.2-1.2,2-2.8,2-4.6C16,3.1,12.4,0,8,0z M12,10.8v2.3L8.9,12c0,0-0.7,0-0.9,0c-3.3,0-6-2.2-6-5s2.7-5,6-5s6,2.2,6,5C14,9.2,12,10.8,12,10.8z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
