import { optionErrorPrefix, optionBlankError, optionDuplicatedError } from './constants';

export const getOptionsColumnErrors = (labels, values, columnErrors = []) => [
  ...columnErrors.filter(val => !val.startsWith(optionErrorPrefix)),
  ...labels.reduce((acc, label, index) => {
    const value = values[index];

    if (!label || !value) {
      acc.push(`${optionBlankError}[${index}]`);
    } else if (labels.filter(l => l === label).length > 1) {
      acc.push(`${optionDuplicatedError}[${index}]`);
    }

    return acc;
  }, []),
];
