import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from '../forms/CheckBox';
import RadioButton from '../RadioButton';

export const Item = ({
  children,
  secondaryLabel,
  value,
  checked,
  forceChecked,
  onChange,
  radio,
  dataTest,
  additionalClasses,
  selectAllSecondaryLabel,
  parentId,
}) => {
  const InputComponent = radio ? RadioButton : CheckBox;

  return (
    <li className='showtime-list__item' data-test={ dataTest }>
      <InputComponent
        dataTest={ dataTest === null ? `check-${value}` : `check-${dataTest}` }
        label={ children }
        primaryLabel={ children }
        disabled={ forceChecked }
        checked={ checked || forceChecked }
        stackedDescription
        secondaryLabel={ secondaryLabel }
        onChange={ () => onChange({ value, parentId, isSelected: !checked }) }
        selectAllSecondaryLabel={ selectAllSecondaryLabel }
        additionalClasses={ additionalClasses }
      />
    </li>
  );
};

Item.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondaryLabel: PropTypes.node,
  onChange: PropTypes.func, // Group onSelectChange passed by ListSelection
  forceChecked: PropTypes.bool, // added by ListSelection
  radio: PropTypes.bool,
  selectAllSecondaryLabel: PropTypes.string,
  additionalClasses: PropTypes.string,
  dataTest: PropTypes.string,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Item.defaultProps = {
  checked: false,
  secondaryLabel: null,
  children: null,
  onChange() {},
  forceChecked: false,
  radio: false,
  selectAllSecondaryLabel: undefined,
  additionalClasses: undefined,
  dataTest: null,
  parentId: undefined,
};
