import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { WindowHelper } from '../utils/window/windowHelper';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';

export const HelpMessage = ({
  children, message, markdown, attention, dataTest, upsell,
}) => (
  <div
    { ...dataTest ? { 'data-test': dataTest } : {} }
    className={ classNames(
      'showtime-callout-help',
      {
        'showtime-callout-help--info': !attention,
        'showtime-callout-help--attention': attention,
        'showtime-callout-help--upsell': upsell,
      }
    ) }
  >
    <div className='showtime-callout-help__rows'>
      { markdown
        ? message && <ReactMarkdown className='showtime-callout-help__row-item' source={ message } />
        : message && <p className='showtime-callout-help__row-item'>{ message }</p>}
      { children && (
        <div className='showtime-callout-help__row-item'>
          { children }
        </div>
      )}
    </div>
  </div>
);

HelpMessage.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  markdown: PropTypes.bool,
  attention: PropTypes.bool,
  dataTest: PropTypes.string,
  upsell: PropTypes.bool,
};

HelpMessage.defaultProps = {
  children: null,
  message: '',
  markdown: true,
  attention: false,
  dataTest: null,
  upsell: undefined,
};

export const HelpMessageLink = ({
  openInANewTab,
  href,
  onClick,
  children,
  metricName,
  dataTest,
}) => {
  const handleOnclick = async (evt) => {
    evt.preventDefault();
    await metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.HelpLink.CLICKED,
      { helpLink: metricName }
    );
    if (onClick) onClick(evt);
    else if (openInANewTab) WindowHelper.navigateInNewTabTo(href);
    else WindowHelper.navigateTo(href);
  };
  return (
    <a
      className='showtime-link showtime-link--default showtime-link--underline'
      onClick={ handleOnclick }
      { ...dataTest ? { 'data-test': dataTest } : {} }
    >
      {children}
    </a>
  );
};

HelpMessageLink.propTypes = {
  metricName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  openInANewTab: PropTypes.bool,
  dataTest: PropTypes.string,
};

HelpMessageLink.defaultProps = {
  onClick: null,
  href: null,
  openInANewTab: true,
  dataTest: null,
};
