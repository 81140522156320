import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  taskOnTrailUrl,
  filterQueryKeys,
  filterTypes,
  taskStatusToQueryStatus,
  filterQueryKeysToTypes,
  DATE_FILTER_FORMAT,
  isFilterEnabled,
  TASK_PDF_EXPORT_LIMIT,
  HIDDEN_FILTERS_FOR_ACTIONS,
  TASK_CONTENT_COMMENT_EXCEPTION_TYPES,
} from '../constants';
import { widgetsSelector } from '../../widget/selectors';
import * as requestStatus from '../../common/requestStatuses';
import { getIdsFromQueryValue, getValueFromQuery, getDateRangeFromQueryValue } from '../applyFiltersToQuery';
import { TaskTemplateConstants } from '../../task/constants';
import {
  nonAdminUsersSelector,
  organizationUsersLoadedSelector,
} from '../../users/selectors';
import {
  locationsAndGroupsSelector,
  locationsHaveLoadedSelector,
} from '../../sites/selectors';
import { UserRole } from '../../application/UserRole';
import { getLocationIdsFromTimelineTemplateIds } from '../../sites/selectors/locationSelectors';

const { ACTION, ACTION_TEMPLATE } = TaskTemplateConstants.templateType;

const TASK_REPORTS = 'taskReports';
const TASKS_PATH = [TASK_REPORTS, 'timelineTasks'];
const SUBTASKS_PATH = [TASK_REPORTS, 'timelineTaskSubtasks'];
const TASK_TEMPLATES_PATH = [TASK_REPORTS, 'taskTemplates'];
const ENTITIES = 'entities';
const REQUEST_STATUS = 'requestStatus';

const timelineTasksSelector = state => _.get(state, [...TASKS_PATH, ENTITIES], []);

const viewCommentsSelector = (state, { location }) => location.search.includes('viewComments');
const viewChecklistsSelector = (state, { location }) => location.search.includes('viewChecklists');
const viewRecordLogsSelector = (state, { location }) => location.search.includes('viewRecordLogs');

const timelineTasksRequestStatusSelector = state => _.get(state, [...TASKS_PATH, REQUEST_STATUS]);

const {
  SEARCH, SITES, TAGS, STATUSES, TASKS, DATES, EXCEPTIONS, ACTIONS, ROLES, USERS,
} = filterQueryKeys;

const selectedActionsSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, ACTIONS),
  getIdsFromQueryValue);

const selectedActionTypesSelector = createSelector(
  selectedActionsSelector,
  selectedActions => _.intersection(selectedActions, [ACTION, ACTION_TEMPLATE]));

const selectedActionIdsSelector = createSelector(
  selectedActionsSelector,
  selectedActions => _.difference(selectedActions, [ACTION, ACTION_TEMPLATE]));

const searchTermSelector = (state, { location }) => getValueFromQuery(location.search, SEARCH);

const sitesQuerySelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, SITES),
  getIdsFromQueryValue
);

const selectedRolesSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, ROLES),
  getIdsFromQueryValue
);

const userQuerySelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, USERS),
  getIdsFromQueryValue);

const selectedTagsSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, TAGS),
  getIdsFromQueryValue
);
const selectedStatusesSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, STATUSES),
  getIdsFromQueryValue
);
const selectedExceptionsSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, EXCEPTIONS),
  getIdsFromQueryValue
);
const selectedTaskTemplatesSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, TASKS),
  getIdsFromQueryValue
);

const selectedDateRangeSelector = createSelector(
  (state, { location }) => getValueFromQuery(location.search, DATES),
  getDateRangeFromQueryValue
);

const selectedDateRangeQuerySelector = (state, { location }) => getValueFromQuery(
  location.search, DATES
);

const taskTemplatesSelector = state => _.get(state,
  [...TASK_TEMPLATES_PATH, ENTITIES, 'task_templates'],
  []);

const taskTemplatesRequestStatusSelector = state => _.get(
  state, [...TASK_TEMPLATES_PATH, REQUEST_STATUS]);

const tagsSelector = state => _.get(state, [...TASK_TEMPLATES_PATH, ENTITIES, 'tags'], []);

const tagsForDropdownSelector = createSelector(
  tagsSelector,
  tags => _.sortBy(tags, 'label').map(tag => ({ ...tag, name: tag.label }))
);

const taskTemplatesForDropdownSelector = createSelector(
  taskTemplatesSelector,
  tasks => _.chain(tasks)
    .filter(({ template_type: type }) => ![ACTION, ACTION_TEMPLATE].includes(type))
    .sortBy('name').value()
);

const actionTemplatesForDropdownSelector = createSelector(
  taskTemplatesSelector,
  tasks => _.chain(tasks)
    .filter({ template_type: ACTION_TEMPLATE })
    .sortBy('name').value()
);

const taskTagIdsSelector = createSelector(
  tagsSelector,
  (tags) => {
    const taskTagIds = {};
    tags.forEach((tag) => {
      tag.task_template_ids.forEach((taskTemplateId) => {
        taskTagIds[taskTemplateId] = [...(taskTagIds[taskTemplateId] || []), tag.id];
      });
    });
    return taskTagIds;
  });

const forcedLocationIdSelector = () => _.get(window, 'navigation.locationId');

const nonAdminUsersWithLocationIdsSelector = createSelector(
  nonAdminUsersSelector,
  locationsAndGroupsSelector,
  (users, locationsAndGroups) => _.chain(users)
    .values()
    .map(({ timelineTemplateIds, ...user }) => ({
      ...user,
      locationIds: getLocationIdsFromTimelineTemplateIds(locationsAndGroups, timelineTemplateIds),
    }))
    .value());

const usersListSelector = createSelector(
  nonAdminUsersWithLocationIdsSelector,
  forcedLocationIdSelector,
  (users, forcedLocationId) => {
    if (!forcedLocationId) return users;
    return users.filter(({ locationIds }) => locationIds.includes(forcedLocationId));
  });

const selectedUsersSelector = createSelector(
  userQuerySelector,
  nonAdminUsersWithLocationIdsSelector,
  (selectedUserIds, users) => {
    if (_.isEmpty(selectedUserIds)) return [];

    return users.filter(({ id }) => selectedUserIds.includes(id));
  });

const selectedSitesSelector = createSelector(
  sitesQuerySelector,
  forcedLocationIdSelector,
  (selectedSiteIds, forcedLocationId) => {
    if (forcedLocationId) return [forcedLocationId];

    return selectedSiteIds;
  });

export const readyToQueryTimelineTasksSelector = createSelector(
  userQuerySelector,
  organizationUsersLoadedSelector,
  locationsHaveLoadedSelector,
  (selectedUserIds, usersLoaded, locationsLoaded) => (
    _.isEmpty(selectedUserIds) || (usersLoaded && locationsLoaded)
  ));

const visibleTaskTemplatesSelector = createSelector(
  taskTemplatesSelector,
  searchTermSelector,
  selectedTagsSelector,
  selectedTaskTemplatesSelector,
  taskTagIdsSelector,
  selectedActionsSelector,
  selectedActionIdsSelector,
  selectedActionTypesSelector,
  selectedRolesSelector,
  selectedUsersSelector,
  (
    taskTemplates,
    searchTerm,
    selectedTags,
    selectedTasks,
    taskTagIds,
    selectedActions,
    selectedActionIds,
    selectedActionTypes,
    selectedRoles,
    selectedUsers
  ) => {
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|\[\]{}]/g, '\\$&');
    const searchTermMatchingWordsInAnyOrder = `((${escapedSearchTerm.trim().replace(' ', '.*')} *))`;
    const searchRegex = new RegExp(searchTermMatchingWordsInAnyOrder, 'i');

    return taskTemplates.filter((task) => {
      const allowedBySearch = !searchTerm || searchRegex.test(task.name);
      const tagIds = taskTagIds[task.id];
      const allowedByTags = (
        _.isEmpty(selectedTags) || _.intersection(tagIds, selectedTags).length > 0);
      const allowedByTaskTemplates = _.isEmpty(selectedTasks) || selectedTasks.includes(task.id);
      const allowedByActionIds = selectedActionIds.includes(task.id);
      const allowedByActionType = selectedActionTypes.includes(task.template_type);
      const allowedByAction = _.isEmpty(selectedActions)
        || allowedByActionType || allowedByActionIds;
      const selectedRolesHasTaskPermissionLevel = selectedRoles.includes(task.permission_level);
      const taskHasCustomRole = !_.isEmpty(
        _.intersection(task.assigned_custom_role_ids, selectedRoles)
      );
      const allowedByRole = (_.isEmpty(selectedRoles) ||
        selectedRolesHasTaskPermissionLevel ||
        taskHasCustomRole);

      const allowedByUsers = (
        _.isEmpty(selectedUsers) || selectedUsers.some((user) => {
          const userCanSeeTaskLocation = _.chain(user.locationIds)
            .intersection(task.location_ids)
            .size().value();
          const taskForAllLocations = [
            TaskTemplateConstants.templateType.ON_DEMAND,
            TaskTemplateConstants.templateType.AUTOMATED,
          ].includes(task.template_type);

          const userIsAssignedDirectly = task.assigned_user_ids.includes(user.id);
          const notAnEnduser = !new UserRole(task.permission_level).isEnduser();
          const customRoleId = _.get(user, 'custom_role.id', null);
          const hasSystemRole = customRoleId === null && task.permission_level === user.role;
          const assignedCustomRoleIds = task.assigned_custom_role_ids;
          const hasCustomRole = assignedCustomRoleIds.includes(customRoleId);

          const userIsAssignedByRole = notAnEnduser && (hasSystemRole || hasCustomRole);

          return (userCanSeeTaskLocation || taskForAllLocations) &&
            (userIsAssignedDirectly || userIsAssignedByRole);
        }));

      return (
        allowedBySearch
        && allowedByTags
        && allowedByTaskTemplates
        && allowedByAction
        && allowedByRole
        && allowedByUsers
      );
    });
  });

const visibleTaskTemplatesLengthSelector = createSelector(
  visibleTaskTemplatesSelector,
  _.size
);

const availableFiltersSelector = createSelector(
  () => Object.values(filterTypes).filter(isFilterEnabled),
  selectedActionsSelector,
  (enabledFilters, selectedActions = []) => {
    if (selectedActions.length) {
      return enabledFilters.filter(filter => !HIDDEN_FILTERS_FOR_ACTIONS.includes(filter));
    }

    return enabledFilters;
  });

const getFilterTypeFromQueryPair = (pair) => {
  const queryKey = pair.includes('=') ? pair.split('=')[0] : pair;
  return filterQueryKeysToTypes[queryKey];
};

const selectedFilterTypesSelector = createSelector(
  availableFiltersSelector,
  (state, { location }) => location.search,
  (availableFilters, queryString) => {
    const queryPairs = queryString.slice(1).split('&');
    const filterKeysInRouteOrder = queryPairs.reduce((accu, pair) => {
      const filter = getFilterTypeFromQueryPair(pair);
      if (availableFilters.includes(filter)) return [...accu, filter];
      return accu;
    }, []);

    return filterKeysInRouteOrder;
  }
);

const filterQueryValuesSelector = createSelector(
  (state, { location }) => location.search,
  (queryString) => {
    const queryObject = queryString.slice(1).split('&')
      .reduce((accu, pairString) => {
        const [key, value] = pairString.split('=');
        return { ...accu, [key]: value };
      }, {});

    const queryValues = _.values(filterQueryKeys).map(key => queryObject[key] || '');
    return queryValues;
  }
);

const visibleTaskTemplateIdsSelector = createSelector(
  visibleTaskTemplatesSelector,
  taskTemplates => _.map(taskTemplates, 'id')
);

const visibleTasksSelector = createSelector(
  timelineTasksSelector,
  visibleTaskTemplateIdsSelector,
  selectedSitesSelector,
  selectedStatusesSelector,
  selectedDateRangeSelector,
  selectedExceptionsSelector,
  (
    tasks,
    taskTemplateIds,
    selectedSites,
    selectedStatuses,
    selectedDateRange,
    selectedExceptions
  ) => (
    tasks.filter((task) => {
      const atAllowedSite = _.isEmpty(selectedSites) || selectedSites.includes(task.location_id);
      const hasAllowedStatus = _.isEmpty(selectedStatuses)
        || selectedStatuses.includes(taskStatusToQueryStatus[task.status])
        || selectedStatuses.includes(taskStatusToQueryStatus[task.flagged_status]);
      const hasAllowedTaskTemplate = taskTemplateIds.includes(task.task_template_id);
      const isWithinDateRange = _.isEmpty(selectedDateRange) || _.intersection(
        task.active_dates, selectedDateRange.map(date => date.format(DATE_FILTER_FORMAT))
      ).length > 0;
      const hasDesiredExceptions = _.isEmpty(selectedExceptions) || _.intersection(
        selectedExceptions, task.exceptions).length > 0;
      return (
        hasAllowedTaskTemplate
        && atAllowedSite
        && hasAllowedStatus
        && isWithinDateRange
        && hasDesiredExceptions
      );
    }))
);

const visibleTasksLengthSelector = createSelector(
  visibleTasksSelector,
  _.size
);

const noTasksLoadedSelector = createSelector(
  taskTemplatesRequestStatusSelector,
  visibleTaskTemplatesLengthSelector,
  timelineTasksRequestStatusSelector,
  visibleTasksLengthSelector,
  (taskTemplatesStatus, taskTemplatesLength, timelineTasksStatus, tasksLength) => {
    const noLoadedTaskTemplates = requestStatus.isLoaded(taskTemplatesStatus)
      && taskTemplatesLength === 0;
    if (noLoadedTaskTemplates) return true;

    return requestStatus.isLoaded(timelineTasksStatus) && tasksLength === 0;
  }
);

const taskSelector = createSelector(
  visibleTasksSelector,
  (state, { index }) => index,
  (tasks, index) => {
    const {
      id,
      due_by: dueBy,
      due_from: dueFrom,
      name,
      status,
      location_name: locationName,
      location_time_zone: locationTimeZone,
      completed_at: completedAt,
      completed_by: completedBy,
      height,
      comments_count: commentsCount,
      flagged_checklist_count: flaggedChecklistCount,
      flagged_status: flaggedStatus,
      added_checklist_count: addedChecklistCount,
      comments,
      template_type: templateType,
      action_count: actionCount,
      exception_count: exceptionCount,
      linked_timeline_task_name: linkedTimelineTaskName,
      linked_timeline_task_completed_at: linkedTimelineTaskCompletedAt,
      requires_pin: requiresPin,
    } = tasks[index] || {};
    return {
      id,
      dueBy,
      dueFrom,
      name,
      status,
      locationName,
      locationTimeZone,
      completedAt,
      completedBy,
      height,
      flaggedChecklistCount,
      flaggedStatus,
      addedChecklistCount,
      commentsCount,
      comments,
      requiresPin,
      titleHref: taskOnTrailUrl(id),
      templateType,
      actionCount,
      exceptionCount,
      linkedTimelineTaskName,
      linkedTimelineTaskCompletedAt,
    };
  }
);

const timelineTasksCursorSelector = state => _.get(state, [...TASKS_PATH, 'pageAfterCursor']);
const windowingDateSelector = state => _.get(state, [...TASKS_PATH, 'windowingDate']) || null;

const taskCountSelector = (state, ownProps) => {
  const { taskReports: { timelineTaskCount = {} } } = state;
  if (!requestStatus.isLoaded(timelineTaskCount.requestStatus)) return null;
  if (!visibleTaskTemplatesLengthSelector(state, ownProps)) return 0;

  return timelineTaskCount.value;
};

const maxTaskCountSelector = (state) => {
  const { taskReports: { timelineTaskCount = {} } } = state;
  if (!requestStatus.isLoaded(timelineTaskCount.requestStatus)) return null;

  return timelineTaskCount.maxTaskCount;
};

const taskCountFulfilledSelector = (state, ownProps) => {
  const totalTaskCount = taskCountSelector(state, ownProps);
  if (totalTaskCount === null) return false;
  if (totalTaskCount >= maxTaskCountSelector(state)) return false;

  const loadedTaskCount = visibleTasksLengthSelector(state, ownProps);
  return loadedTaskCount >= totalTaskCount;
};

const shouldLoadMoreTasksSelector = (state, ownProps) => {
  const hasCursor = !!timelineTasksCursorSelector(state);
  const hasWindowingDate = !!windowingDateSelector(state);
  const countIsNotFulfilled = !taskCountFulfilledSelector(state, ownProps);
  return (hasCursor || hasWindowingDate) && countIsNotFulfilled;
};

const widgetIdsFromTasks = tasks => _.chain(tasks)
  .filter('widget_data_id').map('widget_data_id').value();

const taskIdsWithComments = tasks => tasks
  .filter(({ comments_count: commentsCount, exceptions }) => (
    commentsCount > 0 ||
      !_.isEmpty(_.intersection(TASK_CONTENT_COMMENT_EXCEPTION_TYPES, exceptions))
  ))
  .map(({ id }) => id);

const taskLocationTimeZoneSelector = createSelector(
  timelineTasksSelector,
  (state, { id }) => id,
  (tasks, id) => (_.find(tasks, { id }) || {}).location_time_zone
);

const taskSubtasksSelector = createSelector(
  (state, { id }) => _.get(state, [...SUBTASKS_PATH, ENTITIES, id], []),
  taskLocationTimeZoneSelector,
  (subtasks, locationTimeZone) => subtasks
    .map(({
      id,
      name,
      completed_at: completedAt,
      completed_by: completedBy,
      updated_at: updatedAt,
      value,
      is_recurring: recurring,
      group_name: groupName,
      added_by_user_id: addedByUserId,
      timeline_task_id: timelineTaskId,
    }) => ({
      id,
      key: id,
      name,
      completedBy,
      completedAt,
      updatedAt,
      value,
      recurring,
      groupName,
      addedByUserId,
      locationTimeZone,
      timelineTaskId,
    }))
);

const taskSubtasksLengthSelector = createSelector(
  taskSubtasksSelector,
  _.size
);

const taskHasSubtasks = (task = {}) => Boolean(task.checklist_count);

const taskHasSubtasksSelector = createSelector(
  timelineTasksSelector,
  (state, { id }) => id,
  (tasks, id) => taskHasSubtasks(_.find(tasks, { id }))
);

const taskSubtasksAreLoadingSelector = createSelector(
  taskHasSubtasksSelector,
  taskSubtasksLengthSelector,
  (hasSubtasks, loadedSubtaskLength) => hasSubtasks && !loadedSubtaskLength
);

const taskIdsWithSubtasks = tasks => _.chain(tasks)
  .filter(taskHasSubtasks).map('id').value();

const taskWidgetIdSelector = createSelector(
  timelineTasksSelector,
  (state, { id }) => id,
  (tasks, id) => _.chain(tasks).find({ id }).get('widget_data_id').value()
);

const taskWidgetSelector = createSelector(
  taskWidgetIdSelector,
  widgetsSelector,
  (widgetId, widgets) => widgets[widgetId]
);

const widgetByWidgetIdSelector = (state, widgetId) => state.widgets.entities[widgetId];

const taskWidgetIsLoadingSelector = createSelector(
  taskWidgetIdSelector,
  taskWidgetSelector,
  (taskWidgetId, hasWidget) => Boolean(taskWidgetId && !hasWidget)
);

const taskHasVisibleContentSelector = createSelector(
  visibleTasksSelector,
  (state, { index }) => index,
  viewChecklistsSelector,
  viewRecordLogsSelector,
  viewCommentsSelector,
  (tasks, index, viewChecklists, viewRecordLogs, viewComments) => {
    const task = tasks[index] || {};
    const hasVisibleSubtasks = taskHasSubtasks(task) && viewChecklists;
    const hasVisibleWidgets = task.widget_data_id && viewRecordLogs;
    const hasVisibleComments = task.comments_count && viewComments;

    return Boolean(
      hasVisibleSubtasks || hasVisibleWidgets || hasVisibleComments
    );
  }
);

const listHasVisibleContentSelector = createSelector(
  viewChecklistsSelector,
  viewRecordLogsSelector,
  viewCommentsSelector,
  (viewChecklists, viewRecordLogs, viewComments) => Boolean(
    viewChecklists || viewRecordLogs || viewComments
  )
);

const showFiltersSelector = createSelector(
  (state, { forceDisplayFilters }) => forceDisplayFilters,
  state => state.taskReports.viewFilters,
  (forceDisplayFilters, viewFilters) => forceDisplayFilters || viewFilters
);

const taskReportsRequestBodySelector = createSelector(
  selectedSitesSelector,
  selectedStatusesSelector,
  selectedDateRangeQuerySelector,
  visibleTaskTemplateIdsSelector,
  selectedExceptionsSelector,
  userQuerySelector,
  (sites, statuses, dates, taskTemplateIds, exceptions, userIds) => ({
    ...sites.length && { locations: sites },
    ...statuses.length && { statuses },
    ...exceptions.length && { exceptions },
    ...dates && { dates },
    ...userIds.length && { assignedUserIds: userIds },
    taskTemplateIds,
  })
);

const isExportModeSelector = state => state.taskReports.exportMode;

const selectedTaskIdsSelector = state => state.taskReports.selectedTaskIds;

const visibleTaskIdsSelector = createSelector(
  visibleTasksSelector,
  tasks => _.map(tasks, 'id')
);

const selectedTasksLengthSelector = createSelector(
  selectedTaskIdsSelector,
  _.size
);

const bulkSelectionTaskGroupsSelector = createSelector(
  visibleTaskIdsSelector,
  (visibleTaskIds) => {
    const lastTaskIndex = visibleTaskIds.length - 1;
    const groupsLength = Math.ceil(visibleTaskIds.length / TASK_PDF_EXPORT_LIMIT);
    const groups = _.chain(groupsLength)
      .times()
      .map((index) => {
        const startIndex = index * TASK_PDF_EXPORT_LIMIT;
        const endIndex = _.min([lastTaskIndex, startIndex + (TASK_PDF_EXPORT_LIMIT - 1)]);
        const taskIds = visibleTaskIds.slice(startIndex, endIndex + 1);

        return ({ startIndex, endIndex, taskIds });
      }).value();
    return groups;
  }
);

const selectedTaskGroupSelector = createSelector(
  selectedTaskIdsSelector,
  visibleTaskIdsSelector,
  bulkSelectionTaskGroupsSelector,
  (selectedTaskIds, visibleTaskIds, bulkSelectionTaskGroups) => {
    if (!selectedTaskIds.length) return null;

    const startIndex = visibleTaskIds.indexOf(selectedTaskIds[0]);
    const { taskIds: taskGroupIds } = _.find(bulkSelectionTaskGroups, { startIndex }) || {};

    if (_.isEqual(selectedTaskIds, taskGroupIds)) return startIndex;

    return null;
  }
);

const taskIsSelectedSelector = createSelector(
  selectedTaskIdsSelector,
  (state, { id }) => id,
  (selectedTaskIds, id) => selectedTaskIds.includes(id)
);

const taskSelectionDisabledSelector = createSelector(
  selectedTaskIdsSelector,
  taskIsSelectedSelector,
  (selectedTaskIds, isSelected) => (selectedTaskIds.length >= TASK_PDF_EXPORT_LIMIT) && !isSelected
);

const selectedTasksSelector = createSelector(
  selectedTaskIdsSelector,
  timelineTasksSelector,
  (taskIds, tasks) => (
    tasks.filter(task => taskIds.includes(task.id))
  )
);

export default {
  actionTemplatesForDropdownSelector,
  taskSelectionDisabledSelector,
  availableFiltersSelector,
  bulkSelectionTaskGroupsSelector,
  filterQueryValuesSelector,
  isExportModeSelector,
  readyToQueryTimelineTasksSelector,
  selectedTaskIdsSelector,
  selectedTasksSelector,
  widgetByWidgetIdSelector,
  shouldLoadMoreTasksSelector,
  taskCountFulfilledSelector,
  noTasksLoadedSelector,
  timelineTasksCursorSelector,
  searchTermSelector,
  selectedExceptionsSelector,
  selectedFilterTypesSelector,
  selectedSitesSelector,
  selectedTagsSelector,
  selectedTasksLengthSelector,
  selectedTaskGroupSelector,
  selectedStatusesSelector,
  selectedTaskTemplatesSelector,
  selectedDateRangeSelector,
  selectedDateRangeQuerySelector,
  taskSelector,
  taskHasVisibleContentSelector,
  listHasVisibleContentSelector,
  showFiltersSelector,
  tagsForDropdownSelector,
  taskCountSelector,
  maxTaskCountSelector,
  taskTemplatesForDropdownSelector,
  taskSubtasksSelector,
  taskHasSubtasksSelector,
  taskLocationTimeZoneSelector,
  taskIdsWithSubtasks,
  taskIsSelectedSelector,
  taskReportsRequestBodySelector,
  taskSubtasksAreLoadingSelector,
  taskTemplatesRequestStatusSelector,
  timelineTasksRequestStatusSelector,
  usersListSelector,
  visibleTasksSelector,
  viewCommentsSelector,
  viewChecklistsSelector,
  viewRecordLogsSelector,
  taskIdsWithComments,
  visibleTasksLengthSelector,
  visibleTaskTemplateIdsSelector,
  widgetIdsFromTasks,
  taskWidgetSelector,
  taskWidgetIsLoadingSelector,
  windowingDateSelector,
};
