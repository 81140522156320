export default {
  ORGANIZATION_TAGS_DISABLED: 'organization_tags.disabled',
  ORGANIZATION_TAGS_LOADED: 'organization_tags.loaded',
  ORGANIZATION_TAGS_DELETED: 'organization_tags.deleted',
  ORGANIZATION_TAGS_UPDATED: 'organization_tags.updated',
  ORGANIZATION_TAGS_CREATED: 'organization_tags.created',
  ORGANIZATION_TAGS_ERROR(tagId) {
    return `Tag ${tagId} could not be saved`;
  },
  ORGANIZATION_TAGS_URL(organizationId) {
    return `/api/v1/organizations/${organizationId}/tags`;
  },
  ORGANIZATION_TAG_URL(organizationId, tagId) {
    const organizationTagsUrl = this.ORGANIZATION_TAGS_URL(organizationId);
    return `${organizationTagsUrl}/${tagId}`;
  },
  SHOW_NEW_TAG_DIALOG: 'tag.new_tag_dialog.show',
  SHOW_TAG_EDIT_DIALOG: 'tag.edit_tag_dialog.show',
  VALIDATE_TAG: 'tag.validate_tag',
  CANCEL_TAG_EDIT: 'tag.cancel_tag_edit',
  SAVE_TAG_EDIT: 'tag.save_tag_edit',
  TOGGLE_DELETE_DIALOG: 'tags.delete_dialog.show',
};
