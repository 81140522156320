import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { UserRole, getCurrentUserRole } from '../../../../application/UserRole';
import Button from '../../../../common/Button';

const ListActions = ({
  addEmptyRow,
  restrictRows,
  isFirstRecord,
  isLastRecord,
  onNextRecordClick,
  onPreviousRecordClick,
  setTableMode,
  intl,
}) => {
  const buttonClasses = classNames(
    'showtime-button',
    'showtime-button--adjacent',
    'showtime-button--highlight-subtle'
  );
  const CurrentUserRole = getCurrentUserRole();

  const isPermittedToAddRestrictedRows = CurrentUserRole.isManagerOrAbove() &&
    !CurrentUserRole.isPermissionDisabled(UserRole.permissionTypes.MANAGE_RESTRICTED_WIDGET_ROWS);
  const isPermittedOrUnrestricted = isPermittedToAddRestrictedRows || !restrictRows;
  const notPermittedAndRestricted = !isPermittedToAddRestrictedRows && restrictRows;

  return (
    <div className='showtime-widget-v2__list-actions'>
      {!isFirstRecord && (
        <Button
          onClick={ onPreviousRecordClick }
          buttonClasses={ `${buttonClasses} showtime-button--prev` }
          iconName='mini/arrows/arrows-16px-1_tail-triangle-left'
        />
      ) }
      {!isLastRecord && (
        <Button
          onClick={ onNextRecordClick }
          buttonClasses={ `${buttonClasses} showtime-button--block` }
          iconName='mini/arrows/arrows-16px-1_tail-triangle-right'
          iconPosition='right'
          label={ intl.formatMessage({ id: 'trail.widgets.data_capture.next_record' }) }
        />
      ) }
      {isLastRecord && isPermittedOrUnrestricted && (
        <Button
          onClick={ addEmptyRow }
          buttonClasses='showtime-button showtime-button--adjacent showtime-button--block showtime-button--highlight-subtle'
          iconName='mini/ui/ui-16px-1_simple-add'
          label={ intl.formatMessage({ id: 'trail.widgets.data_capture.add_record' }) }
        />
      ) }
      {isLastRecord && notPermittedAndRestricted && (
        <Button
          onClick={ setTableMode }
          buttonClasses='showtime-button showtime-button--adjacent showtime-button--block showtime-button--highlight-subtle'
          label={ intl.formatMessage({ id: 'trail.widgets.data_capture.view_all_records' }) }
        />
      ) }
    </div>
  );
};

ListActions.propTypes = {
  isFirstRecord: PropTypes.bool.isRequired,
  isLastRecord: PropTypes.bool.isRequired,
  onNextRecordClick: PropTypes.func.isRequired,
  onPreviousRecordClick: PropTypes.func.isRequired,
  recordsLength: PropTypes.number.isRequired,
  setTableMode: PropTypes.func.isRequired,
  restrictRows: PropTypes.bool,
  addEmptyRow: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ListActions.defaultProps = {
  restrictRows: false,
};

export default injectIntl(ListActions);
