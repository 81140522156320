import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Callout from '../../common/Callout';
import { TrailConstants } from '../../trail/constants/index';

const STATUS_TO_ICON = {
  [TrailConstants.taskStatus.IN_PROGRESS]: 'mini/ui/ui-16px-1_calendar-60',
  [TrailConstants.taskStatus.LATER]: 'mini/ui/ui-16px-1_calendar-60',
  [TrailConstants.taskStatus.MISSED]: 'mini/ui/ui-16px-1_circle-bold-remove',
  [TrailConstants.taskStatus.COMPLETED]: 'mini/ui/ui-16px-1_check-circle-08',
  [TrailConstants.taskStatus.COMPLETED_LATE]: 'mini/ui/ui-16px-1_check-circle-08',
  [TrailConstants.taskStatus.COMPLETED_EARLY]: 'mini/ui/ui-16px-1_check-circle-08',
  [TrailConstants.taskStatus.SNOOZED]: 'mini/ui/ui-16px-2_time-clock',
  [TrailConstants.taskStatus.OVERDUE]: 'mini/ui/ui-16px-2_time-clock',
  [TrailConstants.taskStatus.FLAGGED]: 'mini/maps-location/location-16px-e_flag-07',
};

const isWarning = status => status === TrailConstants.taskStatus.OVERDUE ||
  status === TrailConstants.taskStatus.COMPLETED_LATE;

const isInactive = status => typeof status === 'undefined' ||
  status === TrailConstants.taskStatus.FLAGGED ||
  status === TrailConstants.taskStatus.SNOOZED ||
  status === TrailConstants.taskStatus.LATER;

const isActive = status => status === TrailConstants.taskStatus.COMPLETED ||
  status === TrailConstants.taskStatus.COMPLETED_EARLY;

export const TaskStatus = ({
  status, intl: { formatMessage },
}) => (
  <Callout
    info={ status === TrailConstants.taskStatus.IN_PROGRESS }
    mandatory={ status === TrailConstants.taskStatus.MISSED }
    active={ isActive(status) }
    inactive={ isInactive(status) }
    warning={ isWarning(status) }
    hasDot={ false }
    iconName={ STATUS_TO_ICON[status] }
  >
    {formatMessage({ id: `taskReports.task.status.${status}` })}
  </Callout>
);

TaskStatus.propTypes = {
  status: PropTypes.string,
};

TaskStatus.defaultProps = {
  status: undefined,
};

export default injectIntl(TaskStatus);
