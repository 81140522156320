import filterBuilder from './filterBuilder';

const hasParameters = parameters => parameters && Object.keys(parameters).length > 0;

export const buildQueryString = parameters => Object.keys(parameters).reduce((accu, key) => {
  const keyValue = parameters[key];
  if (key === '' || typeof keyValue === 'undefined') return accu;
  if (keyValue === true) return [...accu, key];

  const keyFilters = filterBuilder(keyValue);
  return [...accu, `${key}${keyFilters.join(`&${key}`)}`];
}, []).join('&');

const mapParametersToQueryString = parameters => hasParameters(parameters)
  ? `?${buildQueryString(parameters)}`
  : '';

export default (path, parameters) => `${path}${mapParametersToQueryString(parameters)}`;
