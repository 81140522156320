import _ from 'lodash';

import { OPTIONS } from '../constants';

const defaultEmptyString = '';

const descriptionFor = (value, cellSettings) => {
  if (!cellSettings) return value;
  const { labels = [], values = [] } = cellSettings;
  return _.zipObject(values, labels)[value] || value;
};

export const formatCellData = (value, cellType, cellSettings) => {
  if (typeof value === 'object') return defaultEmptyString;
  if (!value) return defaultEmptyString;
  if (cellType === OPTIONS) {
    return descriptionFor(value, cellSettings);
  }

  return value;
};
