import _ from 'lodash';
import { createCachedSelector } from 're-reselect';

import { AttachmentConstants } from '../../attachments/constants';

const taskIdSelector = (state, { taskId }) => taskId;

const taskCommentBoxSelector = createCachedSelector(
  state => state.taskCommentBox,
  taskIdSelector,
  (taskCommentBox, taskId) => {
    taskCommentBox = taskCommentBox || {};
    return taskCommentBox[taskId] || {};
  }
)((store, ownProps) => taskIdSelector(store, ownProps) || 'no_task');

export const taskCommentBoxAttachmentSelector = (state, ownProps) => (
  taskCommentBoxSelector(state, ownProps).attachment || null
);

export const taskCommentBoxMessageSelector = (state, ownProps) => (
  taskCommentBoxSelector(state, ownProps).message || ''
);

export const showUnsavedTaskCommentErrorsSelector = (state, ownProps) => (
  taskCommentBoxSelector(state, ownProps).showUnsavedCommentError || false
);

export const taskCommentBoxHasContentSelector = (state, ownProps) => {
  const attachment = taskCommentBoxAttachmentSelector(state, ownProps);
  const message = taskCommentBoxMessageSelector(state, ownProps);
  return Boolean(attachment || message.trim());
};

export const taskMessageIsDeleting = (state, { messageId }) => _.get(state, `taskCommentBox.messages[${messageId}].deletingMessage`, false);

export const taskCommentBoxAttachmentRequiresUploadSelector = (state, ownProps) => {
  const attachment = taskCommentBoxAttachmentSelector(state, ownProps);

  const hasFile = Boolean(_.get(attachment, 'file'));
  const status = _.get(attachment, 'status', null);
  const isUploading = status === AttachmentConstants.State.UPLOADING;
  const hasFailedToUpload = status === AttachmentConstants.State.FAILED;
  return hasFile && (isUploading || hasFailedToUpload);
};

const flagComment = ({ taskId }) => `taskCommentBox["flag.${taskId}"]`;

export const taskFlagCommentBoxAttachmentSelector = (state, ownProps) => _.get(state, `${flagComment(ownProps)}.attachment`, null);

export const taskFlagCommentBoxMessageSelector = (state, ownProps) => _.get(state, `${flagComment(ownProps)}.message`, '');
