import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCurrentUserRole } from '../application/UserRole';
import FormSectionItem from '../common/forms/FormSectionItem';
import { HelpMessage, HelpMessageLink } from '../common/HelpMessage';
import SVGIcon from '../common/SVGIcon';
import { Tab, Tabs } from '../common/Tabs';
import { RouterConstants } from '../router';
import { DATA_TYPE_REGEX } from '../widget/constants';
import WidgetMultipleTasksInfo from '../widget/edit/WidgetMultipleTasksInfo';
import WidgetSettings from '../widget/edit/WidgetSettings';
import WidgetSettingsCodeEditor from '../widget/edit/WidgetSettingsCodeEditor';
import { isNewWidgetSelector } from '../widget/selectors';
import { helpArticleUrls } from './constants';
import { NEW, TaskWidgetViews } from './constants/taskWidgetConstants';
import { TaskWidgetSelectors } from './selectors';
import ReadonlyWidgetHelpMessage from './WidgetSection/TaskLibraryWidgetHelpMessage';

export const canAccessBuilder = () => {
  const { features: { recordLogBuilder } } = window.config;
  const shadowingOrAdmin = getCurrentUserRole().isSuperAdminOrBeingShadowedWithAllFeatures();
  return shadowingOrAdmin || recordLogBuilder;
};

export const EditableWidgetHelpMessage = ({ intl }) => (
  <HelpMessage message={ intl.formatMessage({ id: 'taskWizard.widget.infoMessage' }) }>
    <HelpMessageLink
      href={ helpArticleUrls.recordLogWithBuilder }
      metricName='Record log - introduction'
    >
      { intl.formatMessage({ id: 'taskWizard.widget.infoMessageLinkText' }) }
    </HelpMessageLink>
    <HelpMessageLink
      href={ helpArticleUrls.audits }
      metricName='Record log - audits'
    >
      { intl.formatMessage({ id: 'taskWizard.widget.auditsInfoLink' })}
    </HelpMessageLink>
  </HelpMessage>
);

const isDataCapture = (integration) => {
  integration = integration || {};
  return DATA_TYPE_REGEX.dataCapture.test(integration.widget_data_type);
};

export class TaskWidget extends Component {
  state = { view: TaskWidgetViews.formView, widgetIsEdited: false };

  setView = view => this.setState({ view });

  onEditWidgetButtonClick = () => {
    const { editedIntegration: { id }, router } = this.props;
    router.push(RouterConstants.buildEditWidgetUrl(id));
  };

  get displayCodeViewTab() {
    const { editedIntegration: widgetSelected } = this.props;
    const shadowingOrAdmin = getCurrentUserRole().isSuperAdminOrBeingShadowedWithAllFeatures();
    return shadowingOrAdmin && widgetSelected;
  }

  renderWidgetSettings = () => {
    const integration = this.props.editedIntegration;

    if (!integration) {
      return null;
    }

    const isForm = DATA_TYPE_REGEX.form.test(integration.widget_data_type);
    const isEditableType = isDataCapture(this.props.editedIntegration) || isForm;

    if (!isEditableType) {
      return null;
    }

    const canRequestChanges = this.shouldShowRequestChangesButton;

    return (
      <React.Fragment>
        { isDataCapture(this.props.editedIntegration) && (
          <FormSectionItem fullWidth paddingBottom>
            <WidgetSettings
              widgetDataType={ _.get(this.props.editedIntegration, 'widget_data_type') }
              editTaskId={ this.props.editTaskId }
            />
          </FormSectionItem>
        )}
        { canRequestChanges && (
          <FormSectionItem fullWidth paddingBottom paddingTop>
            <a
              onClick={ this.onEditWidgetButtonClick }
              className='showtime-link showtime-link--default showtime-link--underline'
              data-test={ `widget${integration.id}.edit` }
            >
              { isDataCapture(this.props.editedIntegration)
                ? this.props.intl.formatMessage({ id: 'taskWizard.widget.editDataCapture' })
                : this.props.intl.formatMessage({ id: 'taskWizard.widget.edit' })}
              <SVGIcon
                classes='showtime-link__icon showtime-link__icon--right'
                iconName='mini/arrows/arrows-16px-4_launch-11'
              />
            </a>
          </FormSectionItem>
        )}
      </React.Fragment>
    );
  };

  get shouldShowRequestChangesButton() {
    const isWidgetBuilderEnabled = window.config.features.recordLogBuilder ||
      getCurrentUserRole().isSuperAdminOrBeingShadowedWithAllFeatures();

    const isWidgetBuilderRendered = (isWidgetBuilderEnabled &&
      isDataCapture(this.props.editedIntegration));

    return !isWidgetBuilderRendered;
  }

  renderCodeView = () => (
    <WidgetSettingsCodeEditor key={ _.get(this.props.editedIntegration, 'id') } />
  );

  renderTabContents = () => {
    switch (this.state.view) {
      case TaskWidgetViews.formView:
        return this.renderWidgetSettings();
      case TaskWidgetViews.codeView:
        return this.renderCodeView();
      default:
        return null;
    }
  };

  get currentWidget() {
    const { editedIntegration, isNewWidget } = this.props;
    if (isNewWidget) return NEW;

    return _.get(editedIntegration, 'id', '');
  }

  renderWidgetTitle = () => {
    if (!isDataCapture(this.props.editedIntegration)) {
      return (
        <FormSectionItem fullWidth paddingTop paddingBottomLarge>
          <div className='showtime-edit-section__item-title' data-test='widget.title'>
            { this.props.editedIntegration.title }
          </div>
        </FormSectionItem>
      );
    }

    return null;
  };

  render() {
    const { savedWidgetId, intl } = this.props;
    const { widgetIsEdited } = this.state;
    const widgetIsOriginal = savedWidgetId === this.currentWidget;
    const widgetIsOriginalAndNotEdited = savedWidgetId !== 0 && widgetIsOriginal && !widgetIsEdited;

    return (
      <div className='showtime-form-section'>
        { this.displayCodeViewTab && (
          <FormSectionItem fullWidth>
            <Tabs activeTab={ this.state.view } onSelect={ this.setView }>
              <Tab name={ TaskWidgetViews.formView } dataTest='tab.formView'>
                { this.props.intl.formatMessage({ id: 'taskWizard.widget.views.form' }) }
              </Tab>
              <Tab name={ TaskWidgetViews.codeView } dataTest='tab.codeView'>
                { this.props.intl.formatMessage({ id: 'taskWizard.widget.views.code' }) }
              </Tab>
            </Tabs>
          </FormSectionItem>
        )}
        {
          widgetIsOriginalAndNotEdited && this.renderWidgetTitle()
        }
        <WidgetMultipleTasksInfo
          widgetDataType={ _.get(this.props.editedIntegration, 'widget_data_type') }
          editTaskId={ this.props.editTaskId }
          isTaskWidget
        />
        { this.renderTabContents() }
        { _.get(window.config, 'features.recordLogBuilder', false)
          ? <EditableWidgetHelpMessage intl={ intl } />
          : <ReadonlyWidgetHelpMessage /> }
      </div>
    );
  }
}

TaskWidget.propTypes = {
  actions: PropTypes.shape({
    newTaskWidget: PropTypes.object,
  }).isRequired,
  router: PropTypes.object.isRequired,
  editTaskId: PropTypes.number,
  editedIntegration: PropTypes.object,
  savedWidgetId: PropTypes.number,
  isNewWidget: PropTypes.bool,
  setWidgetAsNew: PropTypes.func,
};

TaskWidget.defaultProps = {
  editedIntegration: {},
  editTaskId: null,
  isNewWidget: false,
  savedWidgetId: 0,
  setWidgetAsNew: () => {},
};

const mapStateToProps = state => ({
  editedIntegration: TaskWidgetSelectors.editedTaskWidgetConfig(state),
  savedWidgetId: state.newTask.widget.originalContent.widget,
  isNewWidget: isNewWidgetSelector(state),
});

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TaskWidget);
