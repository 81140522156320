import {
  MESSAGE_CHECK_MULTIPLE_CLIENTS,
  OFFLINE_CHECKED_MULTIPLE_CLIENTS,
} from '../../../service-workers/constants';
import safePostMessage from '../helpers/safePostMessage';

export default (client = window) => {
  safePostMessage({
    type: MESSAGE_CHECK_MULTIPLE_CLIENTS,
    payload: {
      count: client[OFFLINE_CHECKED_MULTIPLE_CLIENTS],
    },
  });
};
