import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import PDFTaskStatus from './PDFTaskStatus';
import PDFRequiredPinIcon from './PDFRequiredPinIcon';

const PDFTaskHeader = ({ intl, task }) => (
  <View
    is='div'
    style={ {
      marginBottom: 15,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    } }
  >
    <View
      is='div'
      style={ {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '80%',
      } }
    >
      <Text
        is='span'
        style={ {
          fontSize: 12,
          wordWrap: 'break-word',
        } }
      >
        {task.name}
      </Text>
      <PDFRequiredPinIcon
        requiresPin={ task.requires_pin }
        isCompleted={ Boolean(task.completed_at) }
      />
    </View>
    <PDFTaskStatus
      intl={ intl }
      status={ task.status }
    />
  </View>
);

PDFTaskHeader.propTypes = {
  task: PropTypes.object.isRequired,
};

export default PDFTaskHeader;
