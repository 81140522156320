import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const FormattedMoney = ({ amount, currency }) => (
  <FormattedNumber
    value={ amount }
    style='currency' // eslint-disable-line react/style-prop-object
    currency={ currency }
  />
);

FormattedMoney.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string.isRequired,
};

FormattedMoney.defaultProps = {
  amount: 0,
};

export default FormattedMoney;
