import 'regenerator-runtime/runtime'; // eslint-disable-line

import moment from 'moment-timezone';
import _ from 'lodash';
import { WidgetDataActions } from './widgetDataActions';
import { TagFilterConstants, TrailConstants } from '../constants';
import { TrailStore, WidgetDataStore } from '../store';
import reduxStore from '../../store';
import { TagFilterActions } from '../../tags/actions';
import RouterConstants from '../../router/constants';
import WindowHelper, { cookieJar } from '../../utils/window';
import FetchHelper from '../../utils/fetch';
import FlashActions from '../../flash/actions';
import AppDispatcher from '../../application/dispatcher';
import metricsPublisher, { TrailMetricsDirectory, MetricsPublisherConstants } from '../../metrics';
import TaskCompletionMetrics from '../../metrics/taskCompletionMetrics';
import hasServiceWorker from '../../utils/service-workers/helpers/hasServiceWorker';
import { MESSAGE_INITIATE_UPDATE_TRAIL_WITH_OFFLINE_STATE } from '../../service-workers/constants';
import safePostMessage from '../../utils/service-workers/helpers/safePostMessage';
import { CommentBoxActions } from '../../conversation/actions';

const TrailActions = {
  addCreatedTimelineTaskToTrail(payload) {
    AppDispatcher.dispatch(_.assign({}, payload, {
      actionType: TrailConstants.ADD_CREATED_TIMELINE_TASK,
    }));
  },

  deleteAction(payload) {
    AppDispatcher.dispatch({
      ...payload,
      actionType: TrailConstants.DELETE_ACTION_TASK,
    });
  },

  deleteActions(payload) {
    AppDispatcher.dispatch({
      ...payload,
      actionType: TrailConstants.DELETE_ACTION_TASKS,
    });
  },

  addTaskActionToTrail(payload) {
    AppDispatcher.dispatch({
      ...payload,
      actionType: TrailConstants.ADD_CREATED_TASK_ACTION,
    });
  },

  updateTaskActionOnTrail(payload) {
    AppDispatcher.dispatch({
      ...payload,
      actionType: TrailConstants.UPDATE_TASK_ACTION,
    });
  },

  handleNewTimelineTask(payload, fromPusher) {
    const {
      timeline_task_id: timelineTaskId,
    } = payload;

    FetchHelper.get(
      TrailConstants.getTimelineTaskUrl(timelineTaskId, { fromPusher }),
      ({ body }) => this.addCreatedTimelineTaskToTrail(body)
    );
  },

  messageReceived(payload) {
    AppDispatcher.dispatch(_.assign({}, payload, {
      actionType: TrailConstants.MESSAGE_RECEIVED,
    }));
  },

  messageDeleted(payload) {
    AppDispatcher.dispatch(_.assign({}, payload, {
      actionType: TrailConstants.MESSAGE_DELETED,
    }));
  },

  getCookieOptions(userId) {
    const key = TagFilterConstants.USER_COOKIE_KEY(userId); // eslint-disable-line new-cap
    return cookieJar.getObject(key);
  },

  removeCookieOptions(userId) {
    const key = TagFilterConstants.USER_COOKIE_KEY(userId); // eslint-disable-line new-cap
    cookieJar.remove(key);
  },

  _getTrailRequests: [],

  getTrail(locationId, date, options) {
    options = options || {};
    const requestStartTime = moment();
    if (!this._validTrailParams(locationId, date)) {
      return Promise.resolve();
    }

    const areTagsDisabled = !window.config.features.tags;
    if (areTagsDisabled) {
      this.removeCookieOptions(options.userId);
    }
    const tags = options.tagOptions || this.getCookieOptions(options.userId);
    const loadType = options.loadType || TrailConstants.trailApiLoadType.INITIAL;
    const url = TrailConstants.trailApiRoute(
      locationId, date, tags, options.taskIdsToInclude, loadType);

    if (options.showLoader) {
      this._showTrailLoading();
    }

    this._getTrailRequests.push(url);

    AppDispatcher.dispatch({
      actionType: TrailConstants.SET_TAGS,
      tags,
    });

    const fetchOptions = {
      logoutOn404: true,
    };

    return FetchHelper.get(
      encodeURI(url),
      this._onGetTrailSuccess.bind(this, url, date, options, tags, requestStartTime),
      this._onGetTrailFailure,
      fetchOptions
    );
  },

  _onGetTrailSuccess(url, date, options, tags, requestStartTime, json) {
    reduxStore.dispatch({
      type: TrailConstants.TRAIL_FETCH_SUCCESS,
      content: json.body,
    });

    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.Trail.TRAIL_API_DURATION,
      {
        duration: moment().diff(requestStartTime),
        trailRequestReason: options.loadType || TrailConstants.trailApiLoadType.INITIAL,
      },
      [MetricsPublisherConstants.SEGMENT]
    );
    const { datePeriod } = options;
    const jsonData = json.body;
    const isNewestGetTrailRequest = url === _.last(this._getTrailRequests);
    if (!isNewestGetTrailRequest) {
      return Promise.resolve(json);
    }

    this._setTagsCookie(tags, options.userId);
    AppDispatcher.dispatch({
      actionType: TrailConstants.UPDATE_TRAIL,
      jsonData,
      date,
      datePeriod,
    });

    if (hasServiceWorker() && !navigator.onLine) {
      const trailStore = new TrailStore();

      safePostMessage({
        type: MESSAGE_INITIATE_UPDATE_TRAIL_WITH_OFFLINE_STATE,
        payload: {
          jsonData,
          trailKey: `${trailStore.getLocationId()}-${trailStore.getDate()}`,
          options: {
            date,
            datePeriod,
          },
        },
      });
    }
    return Promise.resolve(json);
  },

  _onGetTrailFailure() {
    FlashActions.alert('trail.unavailable', reduxStore);

    AppDispatcher.dispatch({
      actionType: TrailConstants.TRAIL_UPDATE_FAILED,
    });
    return Promise.resolve();
  },

  _setTagsCookie(tags, userId) {
    if (tags) {
      const storeTags = new TrailStore().getTags();
      if (storeTags !== tags) {
        return;
      }

      const { includeTasksWithoutTags, isAssignedToMe } = tags;

      reduxStore.dispatch(TagFilterActions.select(
        tags.tags,
        { includeTasksWithoutTags, isAssignedToMe }
      ));
      const cookieKey = TagFilterConstants.USER_COOKIE_KEY(userId); // eslint-disable-line new-cap
      cookieJar.set(cookieKey, JSON.stringify(tags));
    }
  },

  handleTaskCompletionViaPusher(task) {
    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_TO_COMPLETED,
      task,
    });

    AppDispatcher.dispatch({
      actionType: TrailConstants.MARK_COMPLETED,
      task,
    });
  },

  markComplete(data, actionOriginatedFromPusher) {
    const task = data.task || {};
    const wasCompletable = task.mandatorySubtasksCompleted;
    const prevStatus = task.status;
    const { pin } = data;
    let markCompleteRequestPromise;

    if (!this._validIdOrGuid(task.id)) {
      return Promise.reject('Invalid task');
    }

    if (actionOriginatedFromPusher) {
      this.handleTaskCompletionViaPusher(task, true);
      return Promise.resolve();
    }

    if (task.mandatorySubtasksCompleted) {
      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_TO_COMPLETED,
        task,
      });
    }

    if (!task.widget_data_id || WidgetDataStore.isSubmitted(task.widget_data_id)) {
      markCompleteRequestPromise = this._makeMarkCompletedRequest(task, wasCompletable, pin);
    } else {
      markCompleteRequestPromise = WidgetDataActions
        .submit(task.widget_data_id)
        .then(() => this._makeMarkCompletedRequest(task, wasCompletable, pin), () => {
          AppDispatcher.dispatch({
            actionType: TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED,
            task,
          });
          return Promise.reject();
        });
    }
    return markCompleteRequestPromise
      .then(TaskCompletionMetrics.sendMetrics.bind(TaskCompletionMetrics, data, prevStatus))
      .catch(_.noop);
  },

  _showTrailLoading() {
    AppDispatcher.dispatch({
      actionType: TrailConstants.TRAIL_LOADING,
    });
  },

  markUncomplete(data, actionOriginatedFromPusher) {
    const task = data.task || {};
    let markUncompleteRequestPromise;

    if (actionOriginatedFromPusher && this._validIdOrGuid(task.id)) {
      this.handleTaskCompletionViaPusher(task, false);
      return Promise.resolve();
    }

    if (!this._validIdOrGuid(task.id) || !task.isCompleted) {
      return Promise.reject('Invalid task');
    }

    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_TO_COMPLETED,
      task,
    });

    if (!task.widget_data_id || !WidgetDataStore.isSubmitted(task.widget_data_id)) {
      markUncompleteRequestPromise = this._makeMarkUncompletedRequest(task);
    } else {
      markUncompleteRequestPromise = WidgetDataActions
        .unsubmit(task.widget_data_id)
        .then((success) => {
          if (success) {
            return this._makeMarkUncompletedRequest(task);
          }
          return Promise.reject();
        }, () => {
          AppDispatcher.dispatch({
            actionType: TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED,
            task,
          });
        });
    }

    return markUncompleteRequestPromise.catch(_.noop);
  },

  requestFlag: (flaggedStatus, task) => {
    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_TO_COMPLETED,
      task,
    });

    const success = (json) => {
      AppDispatcher.dispatch({
        actionType: TrailConstants.TASK_FLAGGED,
        task: json.body,
      });
    };

    const failure = () => {
      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED,
        task,
      });
      FlashActions.alert('trail.errors.flagTask', reduxStore);
    };

    const options = {
      body: JSON.stringify({ flaggedStatus }),
    };

    return FetchHelper.put(
      TrailConstants.getFlagTaskUrl(task.id),
      success,
      failure,
      options
    );
  },

  flagTask: async (task, { flaggedStatus, comment, attachment }) => {
    const { name: userName, avatar_text: userInitials } = window.current_user;
    let uploadedAttachment = null;
    if (attachment) uploadedAttachment = attachment.metadata;

    if (comment || attachment) {
      const sendRequest = await CommentBoxActions.requestSend({
        userName,
        userInitials,
        taskId: task.id,
        conversationId: task.conversationId,
        uploadedAttachment,
        message: comment,
      })(reduxStore.dispatch);
      if (!sendRequest.success) return;
    }
    TrailActions.requestFlag(flaggedStatus, task);
  },

  unflagTask(taskId) {
    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_FROM_COMPLETED,
      task: taskId,
    });

    const success = ({ body }) => {
      AppDispatcher.dispatch({
        actionType: TrailConstants.TASK_UNFLAGGED,
        task: body,
      });
    };

    const failure = () => {
      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_FROM_COMPLETED_FAILED,
        task: taskId,
      });

      FlashActions.alert('trail.errors.unflagTask', reduxStore);
    };

    return FetchHelper.put(
      TrailConstants.getUnflagTaskUrl(taskId),
      success,
      failure
    );
  },

  snoozeTask(taskId, date, type, value) {
    const success = function (json) {
      AppDispatcher.dispatch({
        actionType: TrailConstants.TASK_SNOOZED,
        task: json.body,
      });
      FlashActions.notice('trail.task.snooze.confirmation', reduxStore);
    };

    const failure = function (json) {
      let error;
      if (_.get(json, 'body.errors[0].detail')) {
        error = json.body.errors[0].detail;
      } else {
        error = 'trail.errors.snoozeTask';
      }
      FlashActions.alert(error, reduxStore);
    };

    const snoozeTaskUrl = TrailConstants.snoozeTaskUrl(taskId);

    const options = {
      body: JSON.stringify({
        date,
        type,
        value,
      }),
    };

    return FetchHelper.post(
      snoozeTaskUrl,
      success.bind(this),
      failure,
      options
    );
  },

  _retriggerAnimationToPreventStaleCompleteable(json, wasCompletable) {
    if (!wasCompletable && json.body.mandatorySubtasksCompleted) {
      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_TO_COMPLETED,
        task: json.body,
      });
    }
  },

  _makeMarkCompletedRequest(task, wasCompletable, pin) {
    const success = function (json) {
      this._removeTaskHighlight();
      AppDispatcher.dispatch({
        actionType: TrailConstants.MARK_COMPLETED,
        task: json.body,
      });

      this._retriggerAnimationToPreventStaleCompleteable(json, wasCompletable);
    }.bind(this);
    const failure = function (json) {
      let error;

      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED,
        task,
      });

      if (_.get(json, 'body.error')) {
        error = json.body.error; // eslint-disable-line prefer-destructuring
      } else {
        error = 'trail.errors.markCompleted';
      }

      FlashActions.alert(error, reduxStore);
      return Promise.reject(error);
    };

    const markCompletedUrl = `/api/timeline_tasks/mark_completed/${task.id}`;
    const body = { pin };
    if (task.widget_data_id) {
      const visibleFieldsByRow = WidgetDataStore.visibleFieldsByRow(task.widget_data_id);
      if (visibleFieldsByRow) {
        body.visible_fields_by_row = visibleFieldsByRow;
      }
    }

    return FetchHelper.put(
      markCompletedUrl,
      success,
      failure,
      { body: JSON.stringify(body) }
    );
  },

  _makeMarkUncompletedRequest(task) {
    const success = function (json) {
      this._removeTaskHighlight();

      AppDispatcher.dispatch({
        actionType: TrailConstants.MARK_UNCOMPLETED,
        task: json.body,
      });
      reduxStore.dispatch({
        type: TrailConstants.MARK_UNCOMPLETED,
        taskId: task.id,
      });
    }.bind(this);
    const failure = function () {
      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED,
        task,
      });

      FlashActions.alert('trail.errors.markUncompleted', reduxStore);
      return Promise.reject('trail.errors.markUncompleted', reduxStore); // eslint-disable-line prefer-promise-reject-errors
    };

    const markUncompletedUrl = `/api/timeline_tasks/mark_uncompleted/${task.id}`;
    const options = {};

    return FetchHelper.put(
      markUncompletedUrl,
      success,
      failure,
      options
    );
  },

  _removeTaskHighlight() {
    WindowHelper.navigateToHashRoute(RouterConstants.BASE_URL);
  },

  deleteOnDemandTask(taskId, locationId) {
    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_FROM_COMPLETED,
      task: taskId,
    });

    return this._makeDeleteOnDemandTaskRequest(taskId, locationId);
  },

  onDemandTaskDeleted(taskId) {
    AppDispatcher.dispatch({
      actionType: TrailConstants.MOVE_TASK_FROM_COMPLETED,
      task: taskId,
    });

    AppDispatcher.dispatch({
      actionType: TrailConstants.TASK_DELETED,
      task: { task: { id: taskId } },
    });
  },

  _makeDeleteOnDemandTaskRequest(taskId, locationId) {
    const url = TrailConstants.deleteOnDemandTaskUrl(taskId, locationId);

    const success = function (json) {
      AppDispatcher.dispatch({
        actionType: TrailConstants.TASK_DELETED,
        task: json.body,
      });
      return Promise.resolve(json);
    };
    const failure = function () {
      const error = 'trail.errors.deleteTask';

      AppDispatcher.dispatch({
        actionType: TrailConstants.MOVE_TASK_FROM_COMPLETED_FAILED,
        task: taskId,
      });

      FlashActions.alert(error, reduxStore);
      return Promise.reject(error);
    };

    return FetchHelper.delete(url, success, failure);
  },

  _validIdOrGuid(id) {
    return (
      typeof id === 'string'
    ) || (
      typeof id === 'number' &&
        Number.isFinite(id) &&
        Math.floor(id) === id
    );
  },

  _validTrailParams(locationId, date) {
    return (!locationId || this._validIdOrGuid(locationId)) &&
           (!date || (moment(date).isValid()));
  },

  _getValidTaskIds(taskIds) {
    return taskIds instanceof Array ? taskIds.filter(id => typeof id === 'number' || typeof id === 'string') : [];
  },
};

export { TrailActions };
