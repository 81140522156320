import { TaskTitleConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskTitleActions = {
  change: content => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTitleConstants.CHANGE,
      content,
    });
  },

  reset() {
    return {
      type: TaskTitleConstants.RESET,
    };
  },
};
