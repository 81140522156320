import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

import MinMaxWarningRange from './MinMaxWarningRange';
import { getNumberColumnLookupRangeErrors } from '../../reducers/builderValidation/numberColumnLookupRange';

const NO_SELECTION = 'no selection';

class AcceptableDependentRangeMinMax extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRuleColumn: props.selectedRuleColumn,
    };
  }

  handleSelectedRuleColumnChange(event) {
    this.setState({
      selectedRuleColumn: event.target.value,
    });

    this.props.onRuleColumnChange(event);
  }

  render() {
    const {
      rules, optionsColumns, onMinLimitChange, onMaxLimitChange, onWarningTextChange, intl, errors,
      onRequestValidationActionChange,
      onRequireMandatoryValidationActionChange,
      onActionTemplateIdChange,
    } = this.props;

    const intlKey = 'editWidget.builder.numberColumn.dependentRange';
    const formatMessage = createIntlMessageFormatter(intl, intlKey);
    const formatValidationMessage = createIntlMessageFormatter(intl, `${intlKey}.validation`);

    return (
      <React.Fragment>
        <select
          value={ this.state.selectedRuleColumn }
          onChange={ e => this.handleSelectedRuleColumnChange(e) }
          data-test='number.dependentRange'
        >
          {optionsColumns.length > 1 && (
            <option key='-1' value={ NO_SELECTION }> { formatMessage('columnDropdownNoSelection') }</option>
          )}
          {optionsColumns.map(({ value, label }) => (
            <option key={ value } value={ value }>{ label }</option>
          ))}
        </select>

        { this.state.selectedRuleColumn !== NO_SELECTION
          && optionsColumns.find(c => c.value === this.state.selectedRuleColumn)
            .values
            .map((optionValue, key) => {
              const {
                minValueWarningLimit,
                maxValueWarningLimit,
                outOfRangeWarningText,
                requireValidationAction: requestValidationAction,
                requireMandatoryValidationAction,
                actionTemplateId,
              } = _.get(rules, [optionValue], {});

              const label = optionsColumns.find(o => o.value === this.state.selectedRuleColumn)
                .labels[key];

              const getErrorForField = (fieldName) => {
                const optionErrors = errors.find(optionError => optionError[optionValue]);
                if (!optionErrors || !optionErrors[optionValue].includes(fieldName)) return [];
                return [{ type: 'validation-error', message: formatValidationMessage(fieldName) }];
              };

              return (
                <div key={ optionValue } data-test={ `if.${label}` }>
                  <div className='showtime-record-log-builder-field__config-ref-value'>
                    { formatMessage('ifLabel', { label }) }
                  </div>
                  <MinMaxWarningRange
                    key={ optionValue }
                    minFieldLabel={ formatMessage('minLabel') }
                    minFieldPlaceholder={ formatMessage('minPlaceholder') }
                    minFieldValue={ minValueWarningLimit }
                    minFieldErrors={ getErrorForField('minValueWarningLimit') }
                    onMinFieldChange={ min => onMinLimitChange(optionValue, min) }
                    maxFieldLabel={ formatMessage('maxLabel') }
                    maxFieldPlaceholder={ formatMessage('maxPlaceholder') }
                    maxFieldValue={ maxValueWarningLimit }
                    maxFieldErrors={ getErrorForField('maxValueWarningLimit') }
                    onMaxFieldChange={ max => onMaxLimitChange(optionValue, max) }
                    warningTextFieldLabel={ formatMessage('messageLabel') }
                    warningTextFieldPlaceholder={ formatMessage('messagePlaceholder') }
                    warningTextFieldValue={ outOfRangeWarningText }
                    warningTextFieldErrors={ getErrorForField('outOfRangeWarningText') }
                    onWarningTextFieldChange={ text => onWarningTextChange(optionValue, text) }
                    requestValidationAction={ requestValidationAction }
                    onRequestValidationActionChange={
                      val => onRequestValidationActionChange(optionValue, val)
                    }
                    onRequireMandatoryValidationActionChange={
                      val => onRequireMandatoryValidationActionChange(optionValue, val)
                    }
                    requireMandatoryValidationAction={ requireMandatoryValidationAction }
                    actionTemplateId={ actionTemplateId }
                    onActionTemplateIdChange={
                      val => onActionTemplateIdChange(optionValue, val)
                    }
                  />
                </div>
              );
            })}
      </React.Fragment>
    );
  }
}

AcceptableDependentRangeMinMax.propTypes = {
  onMaxLimitChange: PropTypes.func.isRequired,
  onMinLimitChange: PropTypes.func.isRequired,
  onRuleColumnChange: PropTypes.func.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
  optionsColumns: PropTypes.array.isRequired,
  selectedRuleColumn: PropTypes.string.isRequired,
  onRequestValidationActionChange: PropTypes.func.isRequired,
  onRequireMandatoryValidationActionChange: PropTypes.func.isRequired,
  rules: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  onActionTemplateIdChange: PropTypes.func.isRequired,
};

const parseRules = (rules, settings, ruleColumn) => {
  if (_.isEmpty(rules)) {
    return {};
  }

  const { cellSettings: { values, labels } } = _.chain(settings)
    .get('public.columns', [])
    .find(({ cellSettings }) => cellSettings.fieldName === ruleColumn).value();

  const dependentRuleLabels = _.fromPairs(_.zip(values, labels));

  return Object.keys(rules).reduce((accu, value) => ({
    ...accu,
    [value]: { ...rules[value], label: dependentRuleLabels[value] },
  }), {});
};

const mapStateToProps = (state, { columnIndex }) => {
  const { settings } = TaskWidgetSelectors.editedTaskWidgetConfig(state);

  const { rules, ruleColumn } = _.get(
    settings,
    `public.columns[${columnIndex}].cellSettings.validation`,
    {}
  );

  const selectedRuleColumn = ruleColumn || NO_SELECTION;

  const rulesWithLabels = parseRules(rules, settings, selectedRuleColumn);

  const errors = getNumberColumnLookupRangeErrors(rules);

  return { rules: rulesWithLabels, selectedRuleColumn, errors };
};

const mapDispatchToProps = (dispatch, { columnIndex }) => {
  const {
    setColumnSettings, setDependentRuleForColumn, clearRulesFromColumn,
  } = bindActionCreators(EditWidgetActions, dispatch);

  const setValidationRule = (fieldName, ruleKey, ruleValue) => {
    const val = {
      validation: {
        rules: {
          [fieldName]: { [ruleKey]: ruleValue },
        },
      },
    };
    setColumnSettings(columnIndex, val);
  };

  const onRuleColumnChange = ({ target: { value } }) => {
    value === NO_SELECTION
      ? clearRulesFromColumn(columnIndex)
      : setDependentRuleForColumn(columnIndex, value);
  };

  return {
    onMinLimitChange: (fieldName, value) => setValidationRule(
      fieldName, 'minValueWarningLimit', value
    ),
    onMaxLimitChange: (fieldName, value) => setValidationRule(
      fieldName, 'maxValueWarningLimit', value
    ),
    onWarningTextChange: (fieldName, value) => setValidationRule(
      fieldName, 'outOfRangeWarningText', value
    ),
    onRequestValidationActionChange: (fieldName, value) => setValidationRule(
      fieldName, 'requireValidationAction', value),
    handleRequireMandatoryValidationActionChange: (fieldName, value) => setValidationRule(
      fieldName, 'requireMandatoryValidationAction', value),
    onActionTemplateIdChange: (fieldName, value) => setValidationRule(
      fieldName, 'actionTemplateId', value),
    onRuleColumnChange,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    handleRequireMandatoryValidationActionChange,
    ...actionPropsForComponent
  } = dispatchProps;

  const onRequireMandatoryValidationActionChange = (fieldName, value) => {
    handleRequireMandatoryValidationActionChange(fieldName, value);

    const fieldRules = _.get(stateProps.rules, [fieldName], {});
    const requestActionUnchecked = !fieldRules.requireMandatoryValidationAction;

    if (value && requestActionUnchecked) {
      dispatchProps.onRequestValidationActionChange(fieldName, value);
    }
  };
  return {
    ...ownProps,
    ...actionPropsForComponent,
    ...stateProps,
    onRequireMandatoryValidationActionChange,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(AcceptableDependentRangeMinMax);
