import _ from 'lodash';
import { MandatoryCommentConstants, TaskWizardConstants } from '../constants';
import { RequestConstants } from '../../request';
import { TaskConstants } from '../../task/constants';

const initialState = MandatoryCommentConstants.defaultMandatoryOptions;

export default (
  state,
  action
) => {
  state = state || initialState;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return _.get(action, 'content.completion_rules.comments') || initialState;
    case TaskConstants.RESET:
      return initialState;
    case MandatoryCommentConstants.CHANGE:
      return _.merge({}, state, {
        [action.level]: { [action.mandatoryType]: action.value },
      });
    default:
      return state;
  }
};
