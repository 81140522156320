import _ from 'lodash';
import moment from 'moment-timezone';
import { TaskWizardConstants } from '../constants';
import { TaskTemplateConstants, TaskConstants } from '../../task/constants';
import { TimeslotConstants } from '../../timeslots/constants';
import { RequestConstants } from '../../request';
import TaskScheduleConstants from '../../schedule/constants';
import { timeslotHelper } from '../../timeslots';
import { momentToRecurrenceOn } from '../../schedule/recurrenceOn';
import { getMaxDate } from '../../schedule/nextInstances';
import random from '../../common/random';
import { GET_EDITING_ACTION_KEY } from '../../taskActions/constants';

const numberInRange = (value, min, max) => Number.isInteger(value) && value >= min && value <= max;
const createDefaultSummary = () => ({ nextInstances: [], errorMessage: false });

export const scheduleReducer = (function () {
  const START = 'start';
  const END = 'end';
  const DAY = 'day';
  const WEEKS = 'weeks';
  const MONTHS = 'months';

  const activeSchedule = state => state.content[state.activeScheduleKey];
  const defaultOnValue = () => TaskScheduleConstants.weekDaysNewSchedule.reduce((on, day) => {
    on[day] = false;
    return on;
  }, {});

  const createSchedule = (defaultTimeslotStartAndEnd = {}) => {
    const date = moment().startOf(DAY).toDate();
    const {
      start = { date, value: '09:00', type: TaskScheduleConstants.TIME },
      end = { date, value: '17:00', type: TaskScheduleConstants.TIME },
    } = defaultTimeslotStartAndEnd;

    const recurrence = {
      unit: TaskScheduleConstants.RECUR_PERIODS.Day,
      by: 0,
      on: defaultOnValue(),
    };
    const summary = createDefaultSummary();
    return {
      view: null,
      isEmpty: true,
      showTimeslots: false,
      completionDays: 1,
      start,
      end,
      recurrence,
      summary,
    };
  };

  const initialState = (defaultTimeslotStartAndEnd) => {
    const defaultSchedule = createSchedule(defaultTimeslotStartAndEnd);
    const defaultScheduleKey = random();
    const schedules = { [defaultScheduleKey]: defaultSchedule };

    return {
      scheduleKeys: [defaultScheduleKey],
      activeScheduleKey: defaultScheduleKey,
      content: schedules,
      originalContent: schedules,
    };
  };

  const resetEdits = ({ defaultTimeslotStartAndEnd }) => ({
    ...initialState(defaultTimeslotStartAndEnd),
    defaultTimeslotStartAndEnd,
  });

  const getMaxEndDate = (start, repeats) => {
    switch (repeats.unit) {
      case TaskScheduleConstants.RECUR_PERIODS.Day:
        return moment(start).add(repeats.by, TimeslotConstants.DAYS);
      case TaskScheduleConstants.RECUR_PERIODS.Week:
        return moment(start).add(repeats.by, WEEKS);
      case TaskScheduleConstants.RECUR_PERIODS.Month:
        return moment(start).add(repeats.by, MONTHS);
      default:
        return null;
    }
  };

  const getTimeFromSchedule = (schedule, type) => {
    const timeBlock = schedule[type];
    if (timeBlock.type === TaskScheduleConstants.TIME) {
      return moment(timeBlock.value, TimeslotConstants.TIME_FMT);
    }
    const time = moment(timeBlock.value.time, TimeslotConstants.TIME_FMT);
    return timeslotHelper.applyOffset(timeBlock.value.dayOffset, time);
  };

  const scheduleWithNoError = schedule => _.merge(
    {}, schedule, { summary: { errorMessage: false } });

  const scheduleWithError = (schedule, message) => _.merge(
    {}, schedule, { summary: { errorMessage: message } });

  const validateSchedule = (schedule) => {
    const startDate = moment(schedule.start.date).startOf(DAY);
    const endDate = moment(schedule.end.date).startOf(DAY);
    const startTime = getTimeFromSchedule(schedule, START);
    const endTime = getTimeFromSchedule(schedule, END);
    const maxEndDate = getMaxEndDate(startDate, schedule.recurrence);

    if (startDate.isAfter(endDate)) {
      return scheduleWithError(schedule, TaskScheduleConstants.ERRORS.DATE);
    }

    if (startDate.isSame(endDate) && startTime.isAfter(endTime)) {
      return scheduleWithError(schedule, TaskScheduleConstants.ERRORS.TIME);
    }

    if (!numberInRange(schedule.completionDays, 1, 1000)) {
      return scheduleWithError(schedule, TaskScheduleConstants.ERRORS.COMPLETION_DAYS_RANGE);
    }

    if (!maxEndDate) {
      return scheduleWithError(schedule, TaskScheduleConstants.ERRORS.REPEAT_UNIT);
    }

    if (schedule.recurrence.by !== 0
        && (endDate.isAfter(maxEndDate) || endDate.isSame(maxEndDate))) {
      return scheduleWithError(schedule, TaskScheduleConstants.ERRORS.REPEAT_BY);
    }

    return scheduleWithNoError(schedule);
  };

  const updateSchedule = (state, scheduleKey, content) => {
    const schedule = _.merge({}, state.content[scheduleKey], content);
    const validatedSchedule = validateSchedule(schedule);

    return _.merge({}, state, { content: { [scheduleKey]: validatedSchedule } });
  };

  const updateActiveSchedule = (state, content) => updateSchedule(
    state, state.activeScheduleKey, content);

  const setValueInActiveSchedule = (state, type, contentSection) => {
    const content = {};
    content[type] = contentSection;

    return updateActiveSchedule(state, content);
  };

  const setEndDate = (state, action) => {
    const appliedDate = {
      end: {
        date: action.value,
        updated: true,
      },
      start: {
        updated: true,
      },
    };
    return updateActiveSchedule(state, appliedDate);
  };

  const setStartDate = (state, action) => {
    const startDate = moment(action.value);
    const appliedDate = {
      start: {
        date: action.value,
        updated: true,
      },
      end: {
        updated: true,
      },
    };

    const endDate = moment(activeSchedule(state).end.date);
    if (startDate.isAfter(endDate)) {
      appliedDate.end = {
        date: action.value,
        updated: true,
      };
    }
    return updateActiveSchedule(state, appliedDate);
  };

  const setSingleDate = (state, action) => {
    const content = {
      start: {
        date: action.value,
        updated: true,
      },
      end: {
        date: action.value,
        updated: true,
      },
    };
    return updateActiveSchedule(state, content);
  };

  const setTimeslot = (state, action, type) => {
    const contentSection = {
      value: action.value,
      type: TaskScheduleConstants.TIME_SLOT,
    };

    return setValueInActiveSchedule(state, type, contentSection);
  };

  const setTime = (state, action, type) => {
    const contentSection = {
      value: action.value,
      type: TaskScheduleConstants.TIME,
    };

    return setValueInActiveSchedule(state, type, contentSection);
  };

  const resetRecurrenceOnFromStartDate = ({ unit, startDate, isBetweenDates }) => {
    const startMoment = moment(startDate);

    const isWeekly = unit === TaskScheduleConstants.RECUR_PERIODS.Week;

    const switchingToWeekdaySelection = !isBetweenDates && isWeekly;

    const startWeekday = momentToRecurrenceOn(startMoment);

    return {
      ...defaultOnValue(),
      ...switchingToWeekdaySelection && startWeekday && { [startWeekday]: true },
    };
  };

  const capEndDate = ({
    by, unit, startDate, endDate,
  }) => {
    if (!by) return {};
    const maxDate = getMaxDate(startDate, unit, by);
    const newEnd = moment.min(moment(endDate), maxDate);

    return {
      end: {
        date: newEnd.toDate(),
        updated: !newEnd.isSame(moment(endDate), 'day'),
      },
    };
  };

  const setRecurUnit = (state, action) => {
    const {
      start: { date: startDate },
      end: { date: endDate },
      isBetweenDates,
      recurrence: { by },
    } = activeSchedule(state);
    const unit = action.value;

    return updateActiveSchedule(state, {
      ...capEndDate({
        by, unit, startDate, endDate,
      }),
      recurrence: {
        on: resetRecurrenceOnFromStartDate({ unit, isBetweenDates, startDate }),
        unit,
      },
    });
  };

  const setRecurBy = (state, action) => {
    const {
      start: { date: startDate },
      end: { date: endDate },
      recurrence: { unit },
    } = activeSchedule(state);
    const by = action.value;

    return updateActiveSchedule(state, {
      ...capEndDate({
        by, unit, startDate, endDate,
      }),
      recurrence: { by },
    });
  };

  const setCompletionDays = (state, action) => {
    const value = Number(action.value);
    const completionDays = Number.isNaN(value) ? action.value : value;

    return updateActiveSchedule(state, { completionDays });
  };

  const setActiveSchedule = (state, action) => {
    const { scheduleKey } = action.content;
    if (state.content[scheduleKey]) {
      return _.merge({}, state, {
        activeScheduleKey: scheduleKey,
      });
    }

    return state;
  };

  const getValueFromTimeslot = ({ start: { fmt, dayOffset }, id }) => ({
    time: fmt,
    id,
    dayOffset,
  });

  const selectAllDay = (state, action) => {
    const sortedTimeslots = action.timeslots.sort(timeslotHelper.sortTimeslotByStartTime);
    return updateActiveSchedule(state, {
      start: {
        value: getValueFromTimeslot(sortedTimeslots[0]),
        type: TaskScheduleConstants.TIME_SLOT,
      },
      end: {
        value: getValueFromTimeslot(sortedTimeslots[sortedTimeslots.length - 1]),
        type: TaskScheduleConstants.TIME_SLOT,
      },
      timeslots: action.timeslots,
    });
  };

  const buildStartAndEndTimeValuesForTimeslot = (timeValues, apiTimeslot) => {
    if (apiTimeslot.type !== TimeslotConstants.DEFAULT_TIMESLOTS) {
      return timeValues;
    }
    let newTime = moment(`${apiTimeslot.attributes.hour}:${apiTimeslot.attributes.minute}`, TimeslotConstants.TIME_FMT);

    newTime = timeslotHelper.applyOffset(apiTimeslot.attributes.dayOffset, newTime);

    if (newTime.isBefore(timeValues.start.time) || !timeValues.start.time) {
      timeValues.start = {
        id: apiTimeslot.id,
        time: newTime,
        dayOffset: apiTimeslot.attributes.dayOffset,
      };
    }

    if (newTime.isAfter(timeValues.end.time) || !timeValues.end.time) {
      timeValues.end = {
        id: apiTimeslot.id,
        time: newTime,
        dayOffset: apiTimeslot.attributes.dayOffset,
      };
    }
    return timeValues;
  };

  const setInitialTimeslots = (state, action) => {
    const timeValues = action.content.data.reduce(
      buildStartAndEndTimeValuesForTimeslot, { start: { }, end: { } });
    timeValues.start.time = timeValues.start.time.format(TimeslotConstants.TIME_FMT);
    timeValues.end.time = timeValues.end.time.format(TimeslotConstants.TIME_FMT);

    const start = {
      value: timeValues.start,
      type: TaskScheduleConstants.TIME_SLOT,
    };
    const end = {
      value: timeValues.end,
      type: TaskScheduleConstants.TIME_SLOT,
    };

    return {
      ...activeSchedule(state).isEmpty ? updateActiveSchedule(state, { start, end }) : state,
      defaultTimeslotStartAndEnd: { start, end },
    };
  };

  const toggleDay = (state, action) => {
    const updatedOn = {};
    updatedOn[action.value] = !activeSchedule(state).recurrence.on[action.value];

    return updateActiveSchedule(state, {
      recurrence: {
        on: updatedOn,
      },
    });
  };

  const extractTimeFromPersistedValue = (scheduleTimeBlock, date) => {
    if (scheduleTimeBlock.type === TaskScheduleConstants.TIME) {
      return {
        date: date.startOf(DAY).toDate(),
        value: scheduleTimeBlock.value,
        type: TaskScheduleConstants.TIME,
        updated: false,
      };
    }

    return {
      date: date.startOf(DAY).toDate(),
      value: {
        id: scheduleTimeBlock.value,
      },
      type: TaskScheduleConstants.TIME_SLOT,
      updated: false,
    };
  };

  const selectMulipleDaysFromDefaultValue = (selectedDays) => {
    const defaultRecurOnDays = defaultOnValue();
    selectedDays.forEach((day) => {
      const normalizedDay = _.capitalize(day);
      if (defaultRecurOnDays[normalizedDay] === false) {
        defaultRecurOnDays[normalizedDay] = true;
      }
    });
    return defaultRecurOnDays;
  };

  const setStateFromPersistedSchedule = (state, task) => {
    const templateType = task.template_type;
    const earlyTaskWarning = task.warn_on_early;

    if (templateType === TaskTemplateConstants.templateType.ON_DEMAND) {
      return updateActiveSchedule(state, { view: TaskScheduleConstants.VIEW.AD_HOC });
    }

    if (templateType === TaskTemplateConstants.templateType.ACTION_TEMPLATE) {
      return updateActiveSchedule(state, { view: TaskScheduleConstants.VIEW.ACTION_TEMPLATE });
    }

    const { isCopying = false, schedules = [], schedule: timelineTaskSchedule } = task;
    if (schedules.length === 0 && !timelineTaskSchedule) return state;

    const sortedSchedules =
      schedules.length === 0 ? [timelineTaskSchedule] : _.sortBy(schedules, ['created_at']);
    const [firstSchedule] = sortedSchedules;

    if (templateType === TaskTemplateConstants.templateType.AUTOMATED) {
      const scheduleKey = firstSchedule.schedule_id;

      const defaultScheduleState = createSchedule();
      defaultScheduleState.completionDays = firstSchedule.automated_completion_days;
      defaultScheduleState.view = TaskScheduleConstants.VIEW.AUTOMATED;

      defaultScheduleState.id = isCopying ? undefined : firstSchedule.schedule_id;

      return {
        scheduleKeys: [scheduleKey],
        activeScheduleKey: scheduleKey,
        earlyTaskWarning,
        content: {
          [scheduleKey]: defaultScheduleState,
        },
        originalContent: {
          [scheduleKey]: defaultScheduleState,
        },
      };
    }

    const content = {};
    const scheduleKeys = [];

    sortedSchedules.forEach((schedule) => {
      const key = schedule.id || random();
      scheduleKeys.push(key);

      const {
        duration, recurrence, start_date: startDate, id,
      } = schedule;

      const isOneOff = !recurrence.by
        && recurrence.unit === TaskScheduleConstants.RECUR_PERIODS.Day;

      const view = isOneOff
        ? TaskScheduleConstants.VIEW.ONE_OFF
        : TaskScheduleConstants.VIEW.RECURRING;

      content[key] = {
        view,
        isEmpty: false,
        isBetweenDates: duration.length > 1,
        start: extractTimeFromPersistedValue(duration[0].start, moment(startDate)),
        completionDays: 1,
        end: extractTimeFromPersistedValue(
          duration[duration.length - 1].end,
          moment(firstSchedule.start_date).add(duration.length - 1, 'days')
        ),
        recurrence: {
          by: recurrence.by,
          unit: recurrence.unit,
          on: selectMulipleDaysFromDefaultValue(recurrence.on || []),
        },
        summary: createDefaultSummary(),
        id: isCopying ? undefined : id,
      };
    });

    return {
      content,
      originalContent: content,
      earlyTaskWarning,
      scheduleKeys,
      activeScheduleKey: scheduleKeys[0],
    };
  };

  const setNextInstances = (state, action) => {
    const { scheduleKey, data } = action.content;
    const nextInstances = data.map(
      nextInstance => new Date(nextInstance.attributes.value)
    );

    const changedContent = {
      summary: { nextInstances },
    };

    return updateSchedule(state, scheduleKey, changedContent);
  };

  const transformToDate = dateRepresentation => new Date(dateRepresentation);

  const setNextInstancesFromInstanceSchedules = (state, action) => {
    const { scheduleKey, data } = action.content;
    const firstInstance = data[0];
    const nextInstances = data.map(
      instanceSchedule => transformToDate(instanceSchedule.attributes.start.date)
    );
    const content = {
      isEmpty: false,
      start: {
        date: transformToDate(firstInstance.attributes.start.date),
      },
      end: {
        date: transformToDate(firstInstance.attributes.end.date),
      },
      summary: {
        nextInstances,
        errorMessage: false,
      },
    };

    const updatedSchedule = updateSchedule(state, scheduleKey, content);
    return _.merge(updatedSchedule, { originalContent: { [scheduleKey]: content } });
  };

  const removeCurrentScheduleFromState = (state) => {
    const scheduleKeyToRemove = state.activeScheduleKey;

    const newScheduleKeys = state.scheduleKeys.filter(key => key !== scheduleKeyToRemove);
    const newActiveScheduleKey = newScheduleKeys[0];
    const newState = _.cloneDeep(state);

    newState.scheduleKeys = newScheduleKeys;
    newState.activeScheduleKey = newActiveScheduleKey;
    delete newState.content[scheduleKeyToRemove];

    return newState;
  };

  const removeSchedule = (state) => {
    if (state.scheduleKeys.length > 1) {
      return removeCurrentScheduleFromState(state);
    }

    const newState = _.merge({}, state, {
      content: state.originalContent,
    });
    activeSchedule(newState).view = TaskScheduleConstants.VIEW.SHORTCUTS;
    return newState;
  };

  const setIsBetweenDates = (state, { value: isBetweenDates }) => {
    const { start: { date: startDate }, recurrence: { unit } } = activeSchedule(state);

    const content = {
      isBetweenDates,
      ...!isBetweenDates && {
        end: { date: startDate },
      },
      recurrence: {
        on: resetRecurrenceOnFromStartDate({ startDate, unit, isBetweenDates }),
      },
    };

    return updateActiveSchedule(state, content);
  };

  const setupRecurringView = (state, action) => {
    const timeslotsState = selectAllDay(state, action);
    const newState = updateActiveSchedule(state, {
      start: {
        date: action.date,
        type: activeSchedule(timeslotsState).start.type,
        value: activeSchedule(timeslotsState).start.value,
      },
      end: {
        date: action.date,
        type: activeSchedule(timeslotsState).end.type,
        value: activeSchedule(timeslotsState).end.value,
      },
      view: TaskScheduleConstants.VIEW.RECURRING,
      recurrence: {
        unit: TaskScheduleConstants.RECUR_PERIODS.Day,
        by: 1,
        on: defaultOnValue(),
      },
    });
    newState.originalContent = _.merge({}, state.originalContent);
    return newState;
  };

  const setupOneOffView = (state, action) => {
    const stateWithAllDayTimeslots = selectAllDay(state, action);

    const newState = updateActiveSchedule(state, {
      start: {
        date: action.date,
        type: activeSchedule(stateWithAllDayTimeslots).start.type,
        value: activeSchedule(stateWithAllDayTimeslots).start.value,
      },
      end: {
        date: action.date,
        type: activeSchedule(stateWithAllDayTimeslots).end.type,
        value: activeSchedule(stateWithAllDayTimeslots).end.value,
      },
      view: TaskScheduleConstants.VIEW.ONE_OFF,
      recurrence: {
        unit: TaskScheduleConstants.RECUR_PERIODS.Day,
        by: 0,
      },
    });
    newState.originalContent = _.merge({}, state.originalContent);
    return newState;
  };

  const setStartAndEndAsUpdated = (state) => {
    const updatedDates = {
      start: {
        updated: true,
      },
      end: {
        updated: true,
      },
    };
    return updateActiveSchedule(state, updatedDates);
  };

  const addSchedule = (state) => {
    const newSchedule = createSchedule();
    const newScheduleKey = random();
    const newState = updateSchedule(state, newScheduleKey, newSchedule);
    newState.scheduleKeys.push(newScheduleKey);
    newState.activeScheduleKey = newScheduleKey;
    return newState;
  };

  const reducer = (state, action) => {
    const currentState = state || initialState();
    const nextLoaded = TaskScheduleConstants.NEXT_INSTANCES_LOADED;

    switch (action.type) {
      case TaskConstants.RESET:
        return resetEdits(state, action);
      case TaskScheduleConstants.SET_ACTIVE_SCHEDULE:
        return setActiveSchedule(state, action);
      case TaskScheduleConstants.SET_COMPLETION_DAYS:
        return setCompletionDays(state, action);
      case TaskScheduleConstants.SET_START_DATE:
        return setStartDate(state, action);
      case TaskScheduleConstants.SET_END_DATE:
        return setEndDate(state, action);
      case TaskScheduleConstants.SET_SINGLE_DATE:
        return setSingleDate(state, action);
      case TaskScheduleConstants.SET_START_TIMESLOT:
        return setTimeslot(state, action, START);
      case TaskScheduleConstants.SET_END_TIMESLOT:
        return setTimeslot(state, action, END);
      case TaskScheduleConstants.SET_START_TIME:
        return setTime(state, action, START);
      case TaskScheduleConstants.SET_END_TIME:
        return setTime(state, action, END);
      case TaskScheduleConstants.SET_RECUR_BY:
        return setRecurBy(state, action);
      case TaskScheduleConstants.SET_RECUR_UNIT:
        return setRecurUnit(state, action);
      case TaskScheduleConstants.SELECT_ALL_DAY:
        return selectAllDay(state, action);
      case RequestConstants.getLoadedActionType(TimeslotConstants.TIMESLOTS_LOADED):
        return setInitialTimeslots(state, action);
      case RequestConstants.getLoadedActionType(
        TaskScheduleConstants.FETCHING_SCHEDULE_NEXT_INSTANCES):
        return setNextInstancesFromInstanceSchedules(state, action);
      case TaskScheduleConstants.TOGGLE_DAY:
        return toggleDay(state, action);
      case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
        return setStateFromPersistedSchedule(state, action.content);
      case RequestConstants.getLoadedActionType(GET_EDITING_ACTION_KEY):
        return setStateFromPersistedSchedule(state, action.content.timeline_task);
      case RequestConstants.getLoadedActionType(nextLoaded):
        return setNextInstances(state, action);
      case TaskScheduleConstants.REMOVE_SCHEDULE:
        return removeSchedule(state, action);
      case TaskScheduleConstants.SETUP_ONE_OFF_VIEW:
        return setupOneOffView(state, action);
      case TaskScheduleConstants.SETUP_RECURRING_VIEW:
        return setupRecurringView(state, action);
      case TaskScheduleConstants.SET_VIEW:
        return updateActiveSchedule(state, { view: action.view });
      case TaskTemplateConstants.CHANGE_TEMPLATE_TYPE:
        return setStartAndEndAsUpdated(state);
      case TaskScheduleConstants.SET_IS_BETWEEN_DATES:
        return setIsBetweenDates(state, action);
      case TaskScheduleConstants.ADD_SCHEDULE:
        return addSchedule(state, action);
      case TaskScheduleConstants.TOGGLE_EARLY_TASK_WARNING:
        return { ...state, earlyTaskWarning: !state.earlyTaskWarning };
      default:
        return currentState;
    }
  };

  return reducer;
}());
