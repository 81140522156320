export const CHANGE = 'mandatoryComments.change';

export const MANDATORY_COMMENT_TYPES = ['text', 'file', 'cameraOnly'];
export const MANDATORY_COMMENT_LEVEL = {
  task: 'task',
};

export const defaultMandatoryOptions = {
  [MANDATORY_COMMENT_LEVEL.task]: {
    file: false,
    text: false,
    cameraOnly: false,
  },
};
