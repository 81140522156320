export const rangeErrors = {
  min: 'minValueWarningLimit',
  max: 'maxValueWarningLimit',
  warningText: 'outOfRangeWarningText',
};

export const optionErrorPrefix = 'option';
export const optionBlankError = `${optionErrorPrefix}.blank`;
export const optionDuplicatedError = `${optionErrorPrefix}.duplicated`;
export const missingJumpToFieldError = 'logic.missingField';
export const jumpToHasFieldOrderError = 'logic.jumpToFieldOrderError';
export const isLastColumnError = 'logic.isLastColumnError';
export const noLogicIfCaseValueError = 'logic.noIfCaseValue';

export const titleError = 'title';
