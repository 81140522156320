import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import DropDownMenu from '../menu/DropDownMenu';
import SVGIcon from '../SVGIcon';

const FilterMenuItem = injectIntl(({
  filter, labelPath, onAdd, intl: { formatMessage }, iconName,
}) => (
  <li className='showtime-menu__list-item'>
    <a
      className='showtime-link showtime-link--default showtime-link--block showtime-link--menu-item'
      onClick={ () => onAdd(filter) }
    >
      <SVGIcon
        iconName={ iconName }
        classes='showtime-link__icon showtime-link__icon--left'
      />
      <span className='showtime-menu__list-item-text'>
        { formatMessage({ id: labelPath }) }
      </span>
    </a>
  </li>
));

const AddFilterMenu = ({
  intl: { formatMessage }, filterOptions, onAdd, filterLabelPaths, filterIconPaths, dataTest,
}) => {
  if (filterOptions.length === 0) return null;
  let dropDownMenu;

  const handleAdd = (filter) => {
    dropDownMenu.closeMenu();
    onAdd(filter);
  };
  return (
    <DropDownMenu
      iconName='mini/ui/ui-16px-1_simple-add'
      label={ formatMessage({ id: 'filters.add' }) }
      iconPosition='left'
      buttonClassName='showtime-button--link'
      dataTestKey='addFilter'
      ref={ (ref) => { dropDownMenu = ref; } }
    >
      <ul
        className='showtime-menu__list showtime-menu__list--no-border'
        data-test={ _.compact([dataTest, 'filtersMenu']).join('.') }
      >
        {filterOptions.map(filter => (
          <FilterMenuItem
            key={ filter }
            filter={ filter }
            labelPath={ filterLabelPaths[filter] }
            iconName={ filterIconPaths[filter] }
            onAdd={ handleAdd }
          />
        ))}
      </ul>
    </DropDownMenu>
  );
};

AddFilterMenu.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  filterLabelPaths: PropTypes.object.isRequired,
  filterIconPaths: PropTypes.object.isRequired,
  dataTest: PropTypes.string,
};

AddFilterMenu.defaultProps = {
  dataTest: null,
};

export default injectIntl(AddFilterMenu);
