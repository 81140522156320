import { TaskWidgetConstants } from '../constants';
import { NEW } from '../constants/taskWidgetConstants';
import { taskHasNoExistingWidgetSelector } from '../selectors/taskWidgetSelectors';
import { RequestActions, RequestConstants } from '../../request';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { canAccessBuilder } from '../TaskWidget';
import { featureIsAccessibleForUser } from '../../common/Upsell';

const change = content => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: TaskWidgetConstants.CHANGE,
    content,
    createNew: content === NEW,
  });
};

export const TaskWidgetActions = {
  fetchAvailableIntegrations() {
    return RequestActions.request({
      url: `/api/organizations/${window.current_user.organization_id}/integrations/available`,
      key: TaskWidgetConstants.FETCH_AVAILABLE_INTEGRATIONS,
      method: RequestConstants.GET,
      errorMessage: 'taskWizard.widget.errors.getAvailableIntegrationsError',
    });
  },
  change,
  expand: () => (dispatch, getState) => {
    const taskHasNoWidget = taskHasNoExistingWidgetSelector(getState());

    const widgetCreationDisabled = !canAccessBuilder() || !featureIsAccessibleForUser('editableWidgetConfig');
    if (taskHasNoWidget && !widgetCreationDisabled) {
      dispatch(change(NEW));
    }

    dispatch({
      type: TaskWidgetConstants.EXPAND,
    });
  },
  cancel() {
    return {
      type: TaskWidgetConstants.CANCEL,
    };
  },
  done() {
    return {
      type: TaskWidgetConstants.DONE,
    };
  },
};
