import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Toggle = ({
  checked, onChange, primaryLabel, secondaryLabel, dataTest, name, className, disabled, children,
}) => (
  <div className={ classNames('showtime-form-section', className) } data-test={ `toggle-${dataTest}` }>
    <div className='showtime-form-section__item showtime-form-section__item--full-width'>
      <label className='showtime-switch showtime-switch--stacked'>
        <input
          className='showtime-switch__input'
          type='checkbox'
          { ...{
            checked, onChange, name, disabled,
          } }
          data-test={ dataTest }
        />
        <span className='showtime-switch__plate'>
          <span
            className={
              classNames('showtime-switch__rocker', { 'showtime-switch__rocker--disabled': disabled })
            }
          />
        </span>
        <span className='showtime-switch__label'>
          <span className='showtime-switch__label-primary'>
            { primaryLabel }
          </span>
          <span className='showtime-switch__label-secondary'>
            { secondaryLabel }
          </span>
        </span>
      </label>
      { children && (
        <div className='showtime-switch__secondary-action'>
          { children }
        </div>
      ) }
    </div>
  </div>
);

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  primaryLabel: PropTypes.node,
  secondaryLabel: PropTypes.node,
  dataTest: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Toggle.defaultProps = {
  onChange() {},
  primaryLabel: '',
  secondaryLabel: '',
  dataTest: '',
  name: '',
  className: '',
  disabled: false,
  children: null,
};

export default Toggle;
