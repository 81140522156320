import _ from 'lodash';
import { GET_EDITING_ACTION_KEY, SET_ATTACHMENTS, GET_ACTION_TEMPLATE_KEY } from '../constants';
import { TaskConstants, TaskTemplateConstants } from '../../task/constants';
import { RequestConstants } from '../../request';
import { loadAttachments } from '../../file/formatAttachmentsFromApi';
import { requestStatuses } from '../../common/requestStatuses';
import { isFile } from '../../attachments/constants';

const formatAttachments = action => (
  _.chain(loadAttachments(action))
    .filter(({ type }) => isFile(type))
    .map(metadata => ({
      status: metadata.state,
      metadata,
    })).value()
);

const setModalAttachments = (state, action) => ({
  ...state,
  attachments: formatAttachments({ content: action.content.timeline_task }),
  modalTaskLoadingStatus: requestStatuses.LOADED,
  actionTemplateIsValid:
    action.content.timeline_task.templateType ===
      TaskTemplateConstants.templateType.ACTION_TEMPLATE,
});
const setModalLoading = state => ({
  ...state,
  modalTaskLoadingStatus: requestStatuses.LOADING,
});
const setModalError = state => ({
  ...state,
  modalTaskLoadingStatus: requestStatuses.ERROR,
});
const setActionTemplateLoaded = (state, action) => ({
  ...state,
  attachments: formatAttachments(action),
  modalTaskLoadingStatus: requestStatuses.LOADED,
  actionTemplateIsValid: action.content.status !== TaskTemplateConstants.status.ARCHIVED,
});
const setActionTemplateError = state => ({
  ...state,
  modalTaskLoadingStatus: requestStatuses.LOADED,
  actionTemplateIsValid: false,
});

const initialState = { attachments: [] };

const actionCases = {
  [SET_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.value,
  }),
  [TaskConstants.RESET]: state => ({
    ...state,
    attachments: [],
  }),
  [RequestConstants.getLoadedActionType(GET_ACTION_TEMPLATE_KEY)]: setActionTemplateLoaded,
  [RequestConstants.getLoadingActionType(GET_ACTION_TEMPLATE_KEY)]: setModalLoading,
  [RequestConstants.getErrorActionType(GET_ACTION_TEMPLATE_KEY)]: setActionTemplateError,
  [RequestConstants.getLoadedActionType(
    GET_EDITING_ACTION_KEY
  )]: setModalAttachments,
  [RequestConstants.getLoadingActionType(
    GET_EDITING_ACTION_KEY
  )]: setModalLoading,
  [RequestConstants.getErrorActionType(
    GET_EDITING_ACTION_KEY
  )]: setModalError,
};

export const taskActions = (state = initialState, action = {}) => {
  if (actionCases[action.type]) return actionCases[action.type](state, action);
  return state;
};

export default taskActions;
