import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const LoadingSpinner = ({
  classes, intl, label, showLabel,
}) => {
  classes = ['showtime-loader'].concat(classes).join(' ');
  label = label || intl.formatMessage({ id: 'common.loading' });

  return (
    <div className={ classes } data-test='loading.spinner' >
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='40px'
        height='40px'
        viewBox='0 0 50 50'
      >
        <path fill='#000' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z' />
      </svg>
      { showLabel &&
        <span className='showtime-loader__text'>{ label }</span>
      }
    </div>
  );
};

LoadingSpinner.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  classes: [],
  label: null,
  showLabel: true,
};

export default injectIntl(LoadingSpinner);

