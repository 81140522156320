import _ from 'lodash';
import { createSelector } from 'reselect';
import { organizationUsersSelector } from '../../users/selectors';
import { TaskPermissionConstants } from '../constants';
import { UserRole } from '../../application/UserRole';
import { locationsAndGroupsSelector } from '../../sites/selectors';
import { TaskTemplateConstants } from '../../task/constants';

const { permissions, DEFAULT_PERMISSION } = TaskPermissionConstants;

const { ORG_ADMIN, MANAGER, EVERYONE } = permissions;
const {
  ON_DEMAND, AUTOMATED, SINGLE, RECURRING, ACTION_TEMPLATE,
} = TaskTemplateConstants.templateType;

const assignedUserIdsSelector = state => _.get(state, 'newTask.permissions.assignedUserIds', []);

export const taskPermissionLevelSelector = state => _.get(state, 'newTask.permissions.permissionLevel', DEFAULT_PERMISSION);

export const allManagersAssignedSelector = createSelector(
  taskPermissionLevelSelector,
  permissionLevel => permissionLevel === MANAGER);

export const taskAssignedCustomRoleIdsSelector = state => (
  _.get(state, 'newTask.permissions.assignedCustomRoleIds', []));

export const taskRequiresPinSelector = state => _.get(state, 'newTask.permissions.requiresPin', false);

const templateTypeSelector = state => _.get(state, 'newTask.template.content.value');
export const taskIsSingleOrRecurringSelector = createSelector(
  templateTypeSelector,
  type => [SINGLE, RECURRING].includes(type)
);

export const isActionTemplateTypeSelector = createSelector(
  templateTypeSelector,
  type => type === ACTION_TEMPLATE
);

export const taskHasAssignmentsSelector = createSelector(
  assignedUserIdsSelector,
  taskPermissionLevelSelector,
  taskAssignedCustomRoleIdsSelector,
  (assignedUserIds, permissionLevel, assignedCustomRoleIds) => (
    Boolean(_.size(assignedUserIds))
    || Boolean(_.size(assignedCustomRoleIds))
    || [MANAGER, ORG_ADMIN].includes(permissionLevel)
  ));

export const assignedUserIdsToPersistSelector = createSelector(
  organizationUsersSelector,
  assignedUserIdsSelector,
  allManagersAssignedSelector,
  taskAssignedCustomRoleIdsSelector,
  (users, assignedUserIds, allManagersAssigned) => {
    if (!allManagersAssigned && !allManagersAssigned.length) return assignedUserIds;

    return assignedUserIds.filter((id) => {
      const role = _.get(users, [id, 'role']);
      return (new UserRole(role)).isEnduser();
    });
  });

export const permissionLevelToPersistSelector = createSelector(
  taskPermissionLevelSelector,
  assignedUserIdsSelector,
  taskAssignedCustomRoleIdsSelector,
  (permissionLevel, assignedUserIds, assignedCustomRoleIds) => {
    const hasAssignedUsersOrCustomRoles = assignedUserIds.length || assignedCustomRoleIds.length;
    if (permissionLevel === EVERYONE && hasAssignedUsersOrCustomRoles) {
      return ORG_ADMIN;
    }

    if (permissionLevel === ORG_ADMIN && !hasAssignedUsersOrCustomRoles) {
      return EVERYONE;
    }
    return permissionLevel;
  });

const taskIsAutomatedOrAdHocSelector = createSelector(
  templateTypeSelector,
  type => [ON_DEMAND, AUTOMATED].includes(type)
);

export const taskIsOnDemandSelector = createSelector(
  templateTypeSelector,
  type => ON_DEMAND === type
);

export const isTaskFlaggableSelector = createSelector(
  state => _.get(state, 'newTask.permissions.flaggable', TaskPermissionConstants.FLAG_VALUES.NOT_FLAGGABLE),
  flaggable => flaggable === TaskPermissionConstants.FLAG_VALUES.WITHOUT_COMMENT
);

export const getTaskFlaggableValueSelector = createSelector(
  state => _.get(state, 'newTask.permissions.flaggable', TaskPermissionConstants.FLAG_VALUES.NOT_FLAGGABLE),
  flaggable => flaggable
);

export const taskPrioritySelector = createSelector(
  state => _.get(state, 'newTask.permissions.priority', TaskPermissionConstants.PRIORITY_VALUES.MEDIUM),
  priority => ({
    priority,
    highPriority: priority === TaskPermissionConstants.PRIORITY_VALUES.HIGH,
    lowPriority: priority === TaskPermissionConstants.PRIORITY_VALUES.LOW,
  })
);

export const getTaskPriorityValueSelector = createSelector(
  state => _.get(state, 'newTask.permissions.priority', TaskPermissionConstants.PRIORITY_VALUES.MEDIUM),
  priority => priority
);

export const usersForTaskAssignmentSelector = createSelector(
  organizationUsersSelector,
  assignedUserIdsSelector,
  allManagersAssignedSelector,
  taskAssignedCustomRoleIdsSelector,
  (users, assignedUserIds, allManagersAssigned, assignedCustomRoleIds) => {
    const assignedUsers = _.reduce(users, (accu, user) => {
      const userRole = new UserRole(user.role);
      if (userRole.isOrgAdminOrAbove()) return accu;
      const isAssigned = assignedUserIds.includes(user.id);
      const isAssignedByCustomRole = assignedCustomRoleIds.includes(_.get(user, 'custom_role.id'));
      const isAssignedByManagerRole = (
        allManagersAssigned && userRole.isManagerOrAbove() && !user.custom_role);
      return [
        ...accu,
        {
          ...user,
          isAssigned,
          isAssignedByRole: isAssignedByManagerRole || isAssignedByCustomRole,
        },
      ];
    }, []);

    return _.orderBy(assignedUsers, ['role', 'name'], ['desc', 'asc']);
  });

export const invalidUserAssignmentsSelector = createSelector(
  taskIsAutomatedOrAdHocSelector,
  usersForTaskAssignmentSelector,
  locationsAndGroupsSelector,
  state => _.map(state.newTask.trails.content.value, 'id'),
  (taskIsAutomatedOrAdHoc, users, { locations }, taskAssignedLocationIds) => {
    if (taskIsAutomatedOrAdHoc) return [];

    const taskAssignedLocations = _.pick(locations, taskAssignedLocationIds);
    const taskLocationTimelineTemplateIds = _.map(taskAssignedLocations, 'timelineTemplateId');
    const partiallyAssignedAreaIds = _.map(taskAssignedLocations, 'timelineTemplateParentId');
    const taskAssignedTimelineTemplateIds = [
      ...partiallyAssignedAreaIds, ...taskLocationTimelineTemplateIds];

    return users.filter(({ isAssigned, timelineTemplateIds }) => {
      if (!isAssigned) return false;

      const userHasNoAccessToTaskAssignedSiteOrArea = _.chain(taskAssignedTimelineTemplateIds)
        .intersection(timelineTemplateIds).isEmpty().value();
      return userHasNoAccessToTaskAssignedSiteOrArea;
    });
  });
