import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { SubTaskConstants } from '../../trail/constants';
import { NotApplicableSvg, CantCompleteSvg, CheckCircleSvg } from './svg';
import { Signature } from '../Signature';
import PDFTaskContentComments from './PDFTaskContentComments';

const subtaskIcons = {
  [SubTaskConstants.VALUES.DONE]: CheckCircleSvg,
  [SubTaskConstants.VALUES.NOT_DONE]: CantCompleteSvg,
  [SubTaskConstants.VALUES.UNCHECKED]: CheckCircleSvg,
  [SubTaskConstants.VALUES.NOT_APPLICABLE]: NotApplicableSvg,
  [SubTaskConstants.VALUES.NEVER_APPLICABLE]: NotApplicableSvg,
};

const subtaskIconColor = {
  [SubTaskConstants.VALUES.DONE]: '#13bf1e',
  [SubTaskConstants.VALUES.NOT_DONE]: '#ff513d',
  [SubTaskConstants.VALUES.UNCHECKED]: '#cacaca',
  [SubTaskConstants.VALUES.NOT_APPLICABLE]: '#222234',
  [SubTaskConstants.VALUES.NEVER_APPLICABLE]: '#222234',
};

const PDFSubtask = ({
  intl, subtask, comments,
}) => {
  const Icon = subtaskIcons[subtask.value] || null;
  const iconColor = subtaskIconColor[subtask.value] || '#444';

  return (
    <View
      is='div'
      style={ {
        flexDirection: 'row',
        fontSize: 10,
        flexWrap: 'wrap',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#f6f6f6',
        paddingTop: 5,
        paddingBottom: 5,
      } }
    >
      <Text
        is='span'
        style={ {
          width: '33.33%',
          paddingRight: 10,
        } }
      >
        {subtask.name}
      </Text>
      <View
        is='div'
        style={ {
          flexDirection: 'row',
          alignItems: 'top',
          width: '33.33%',
        } }
      >
        <View is='div' style={ { flexDirection: 'row', alignItems: 'center', marginBottom: 'auto' } }>
          <Icon
            style={ {
              height: 10,
              paddingRight: 2,
              fill: iconColor,
            } }
          />
          <Text is='span'>
            {intl.formatMessage({ id: `taskReports.taskSubtasks.status.${subtask.value}` })}
          </Text>
        </View>
      </View>
      { subtask.completedAt
        ? (
          <Text
            is='span'
            style={ {
              width: '33.33%',
            } }
          >
            <Signature
              completedAt={ subtask.completedAt }
              completedBy={ subtask.completedBy }
              intl={ intl }
              timeZone={ subtask.locationTimeZone }
            />
          </Text>
        )
        : (
          <Text is='span' />
        )}
      <PDFTaskContentComments { ...{ comments, intl } } timeZone={ subtask.locationTimeZone } />
    </View>
  );
};

PDFSubtask.propTypes = {
  subtask: PropTypes.object.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object),
};

PDFSubtask.defaultProps = {
  comments: [],
};

export default PDFSubtask;
