import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MessageInfo from './MessageInfo';
import MessageBody from './MessageBody';
import MessageOptions from './MessageOptions';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';

const onDelete = taskId => () => (
  metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.COMMENT_DELETED, { taskId })
);

export const Comment = ({
  userId,
  userName,
  timestamp,
  converted_task_content_comment: convertedTaskContentComment,
  messageId,
  body,
  attachment,
  taskId,
  disableOptions,
  canDelete,
}) => {
  const isCurrentUser = userId === window.current_user.id;
  const conversationMessageClasses = classNames('showtime-conversation__message', {
    'showtime-conversation__message--you': isCurrentUser,
    'showtime-conversation__message--not-you': !isCurrentUser,
  });
  const { formatMessage } = useIntl();
  return (
    <div className={ conversationMessageClasses } data-test='message'>
      <div className='showtime-conversation__message-contents'>
        <MessageInfo
          userName={ userName }
          messageId={ messageId }
          timestamp={ timestamp }
        />
        { convertedTaskContentComment && (
          <div className='showtime-conversation__message-converted-header'>
            { formatMessage({ id: 'conversation.comment.convertedTaskContentComment' }) }
          </div>
        ) }
        <MessageBody
          attachment={ attachment }
          body={ body }
        />
        {isCurrentUser && canDelete && (
          <MessageOptions
            disabled={ disableOptions }
            body={ body }
            messageId={ messageId }
            taskId={ taskId }
            onDelete={ onDelete(taskId) }
          />
        )}
      </div>
    </div>
  );
};

Comment.propTypes = {
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  converted_task_content_comment: PropTypes.bool,
  body: PropTypes.string.isRequired,
  attachment: PropTypes.object,
  disableOptions: PropTypes.bool,
  canDelete: PropTypes.bool,
};

Comment.defaultProps = {
  attachment: undefined,
  messageId: undefined,
  converted_task_content_comment: false,
  disableOptions: false,
  canDelete: false,
};

export default Comment;
