import { RequestActions, RequestConstants } from '../../request';
import { getReadPolicyUrl, pickPolicyUrl, requestKey } from '../constants';

export const getFilestackPickSignature = () => RequestActions.request({
  url: pickPolicyUrl,
  key: requestKey.pick,
  method: RequestConstants.GET,
});

export const getFilestackReadSignature = ({ handles }) => RequestActions.request({
  url: getReadPolicyUrl(handles),
  key: requestKey.read,
  method: RequestConstants.GET,
  content: { handles },
});
