import React from 'react';
import PropTypes from 'prop-types';

import {
  NUMBER,
  TEL,
  TEXT,
} from '../../../../common/forms/Input';
import InputField from '../../../../common/forms/InputField';
import TextAreaField from '../../../../common/forms/TextAreaField';
import CorrectiveActionButton from './CorrectiveActionButton';

export const Text = ({
  columnTitle,
  disabled,
  errorText,
  errorType,
  focusInput,
  value,
  id,
  inputType,
  onEdit,
  rowId,
  copyValueToNew,
  fieldName,
  mandatory,
  prefixText,
  suffixText,
  onCorrectiveActionClick,
  requireValidationAction,
  hasAction,
  hasCompletedAction,
  readOnly,
  children,
}) => {
  const onEditCallback = fieldValue => onEdit({
    rowId,
    fieldName,
    fieldValue,
  });

  const errors = errorText ? [{ message: errorText, type: errorType }] : [];
  const inputProps = {
    containerClass: 'showtime-widget-v2__list-field',
    disabled,
    errors,
    id,
    label: columnTitle,
    locked: disabled,
    name: fieldName,
    onChange: onEditCallback,
    prefix: prefixText,
    refFn: focusInput,
    repeating: copyValueToNew,
    required: mandatory,
    suffix: suffixText,
    type: inputType,
    value: value || '',
    onCorrectiveActionClick,
    rowId,
    readOnly,
  };

  if (inputType === TEXT) {
    return (
      <TextAreaField { ...inputProps } autoExpand rows='1'>
        { children }
      </TextAreaField>
    );
  }

  return (
    <InputField { ...inputProps } inputChildren={ children }>
      { (isValid) => {
        if (readOnly) return null;
        const showCorrectiveActionButton = !isValid && requireValidationAction;
        if (!showCorrectiveActionButton) return null;
        return (
          <CorrectiveActionButton
            onClick={ onCorrectiveActionClick }
            hasAction={ hasAction }
            hasCompletedAction={ hasCompletedAction }
            fieldName={ fieldName }
            rowId={ rowId }
          />
        );
      } }
    </InputField>
  );
};

Text.propTypes = {
  columnTitle: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  errorType: PropTypes.string,
  focusInput: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf([
    NUMBER,
    TEL,
    TEXT,
  ]),
  onEdit: PropTypes.func.isRequired,
  rowId: PropTypes.number.isRequired,
  copyValueToNew: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  prefixText: PropTypes.string,
  suffixText: PropTypes.string,
  requireValidationAction: PropTypes.bool,
  onCorrectiveActionClick: PropTypes.func,
  hasAction: PropTypes.bool,
  hasCompletedAction: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

Text.defaultProps = {
  columnTitle: '',
  disabled: false,
  focusInput: null,
  inputType: TEXT,
  value: undefined,
  requireValidationAction: false,
  onCorrectiveActionClick() {},
  errorText: undefined,
  errorType: undefined,
  copyValueToNew: undefined,
  mandatory: undefined,
  prefixText: undefined,
  suffixText: undefined,
  hasAction: undefined,
  hasCompletedAction: undefined,
  children: null,
};

export default Text;
