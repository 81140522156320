import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  filterTypes, filterTypeIcons, filterLabelPaths,
} from '../constants';
import { TaskReportsSelectors } from '../selectors';
import SitesFilter from '../../common/Filters/SitesFilter';
import TagsFilter from './TagsFilter';
import StatusesFilter from './StatusesFilter';
import TasksFilter from './TasksFilter';
import DatesFilter from './DatesFilter';
import ExceptionsFilter from './ExceptionsFilter';
import ActionsFilter from './ActionsFilter';
import RolesFilter from './RolesFilter';
import UsersFilter from './UsersFilter';

import Filters from '../../common/Filters';
import { PageNameConstants } from '../../metrics/constants';

const TaskReportFilters = (props) => {
  const filterDropdownForType = {
    [filterTypes.SITES]: SitesFilter,
    [filterTypes.TAGS]: TagsFilter,
    [filterTypes.STATUSES]: StatusesFilter,
    [filterTypes.TASKS]: TasksFilter,
    [filterTypes.DATES]: DatesFilter,
    [filterTypes.EXCEPTIONS]: ExceptionsFilter,
    [filterTypes.ACTIONS]: ActionsFilter,
    [filterTypes.ROLES]: RolesFilter,
    [filterTypes.USERS]: UsersFilter,
  };
  return (
    <Filters
      { ...props }
      className='showtime-task-reports__header-filters'
      dataTest='taskReports.filters'
      filterComponents={ filterDropdownForType }
      filterLabelPaths={ filterLabelPaths }
      filterIconPaths={ filterTypeIcons }
      filterMetric={ PageNameConstants.TASK_REPORTS }
    />
  );
};

TaskReportFilters.propTypes = {
  availableFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(filterTypes))).isRequired,
  setFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(filterTypes))).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  availableFilters: TaskReportsSelectors.availableFiltersSelector(state, ownProps),
  setFilters: TaskReportsSelectors.selectedFilterTypesSelector(state, ownProps),
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(TaskReportFilters);
