import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import taskReportsSelectors from '../selectors/taskReportsSelector';
import ShareMenu from '../../common/ShareMenu';
import PDFExportOption from './PDFExportOption';
import CSVExportOption from './CSVExportOption';
import CSVExceptionsExportOption from './CSVExceptionsExportOption';
import CopyToClipboardTitle from '../../common/CopyToClipboardTitle';
import UserAgentQuery from '../../common/UserAgentQuery';
import { TrailMetricsDirectory } from '../../metrics';

const Count = injectIntl(({ value, maxTaskCount, intl: { formatMessage } }) => (
  <div className='showtime-task-reports__header-count' data-test='taskCount'>
    { _.isNumber(value) && _.isNumber(maxTaskCount) && (
      formatMessage({ id: 'taskReports.count' }, { value, atMaxCount: value >= maxTaskCount })
    )}
  </div>
));
Count.propTypes = {
  value: PropTypes.number,
  maxTaskCount: PropTypes.number,
};
Count.defaultProps = {
  value: null,
  maxTaskCount: null,
};

const ExportOptions = ({
  hideShareMenu, pdfExportEnabled, csvExportEnabled, csvExceptionsExportEnabled,
}) => {
  if (!(pdfExportEnabled || csvExportEnabled)) return null;

  return (
    <React.Fragment>
      { pdfExportEnabled && <PDFExportOption onClick={ hideShareMenu } /> }
      { csvExportEnabled && <CSVExportOption onClick={ hideShareMenu } /> }
      { csvExceptionsExportEnabled && <CSVExceptionsExportOption onClick={ hideShareMenu } /> }
      <CopyToClipboardTitle />
    </React.Fragment>
  );
};

ExportOptions.propTypes = {
  hideShareMenu: PropTypes.func.isRequired,
  pdfExportEnabled: PropTypes.bool.isRequired,
  csvExportEnabled: PropTypes.bool.isRequired,
  csvExceptionsExportEnabled: PropTypes.bool.isRequired,
};

export const TaskReportsTitle = ({
  intl: { formatMessage }, count, maxTaskCount, url, showShareMenu,
}) => (
  <div className='showtime-task-reports__header-title'>
    <h1 className='showtime-task-reports__header-title-label'>
      { formatMessage({ id: 'taskReports.headerTitle' })}
      <Count value={ count } maxTaskCount={ maxTaskCount } />
    </h1>
    { showShareMenu && (
      <ShareMenu
        url={ url }
        clickMetric={ TrailMetricsDirectory.page.TaskReports.SHARE_LINK_COPIED }
        helpMessage={ formatMessage({ id: 'share.forAdminAndManagersOnly' }) }
      >
        { hideShareMenu => !UserAgentQuery.isMobileDevice() && (
          <ExportOptions
            pdfExportEnabled={ window.config.features.pdfExport }
            csvExceptionsExportEnabled={ window.config.features.csvExceptionsExport }
            csvExportEnabled={ window.config.features.csvExport }
            hideShareMenu={ hideShareMenu }
          />
        ) }
      </ShareMenu>
    ) }
  </div>
);

TaskReportsTitle.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  maxTaskCount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  url: PropTypes.string.isRequired,
  showShareMenu: PropTypes.bool,
};

TaskReportsTitle.defaultProps = {
  count: null,
  maxTaskCount: null,
  showShareMenu: true,
};

const mapStateToProps = (state, ownProps) => ({
  count: taskReportsSelectors.taskCountSelector(state, ownProps),
  maxTaskCount: taskReportsSelectors.maxTaskCountSelector(state),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(TaskReportsTitle);
