import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Button from '../../common/Button';

import { CommentBoxActions } from '../../conversation/actions';

import {
  taskCommentBoxAttachmentSelector,
  taskCommentBoxMessageSelector,
  taskCommentBoxAttachmentRequiresUploadSelector,
  taskCommentBoxHasContentSelector,
} from '../selectors';

export const SendCommentButton = ({ canSend, intl, onClick }) => canSend && (
  <div className='showtime-conversation__comment-box-inline-save'>
    <Button
      data-test='sendButton'
      dataTest='conversation.send'
      buttonClasses='showtime-button--highlight-alt showtime-button--small'
      label={ intl.formatMessage({ id: 'conversation.commentBox.sendButtonLabel' }) }
      onClick={ onClick }
    />
  </div>
);

SendCommentButton.propTypes = {
  canSend: PropTypes.bool,
  onClick: PropTypes.func,
};

SendCommentButton.defaultProps = {
  canSend: false,
  onClick() {},
};

const mapDerivedProps = (stateProps, dispatchProps, ownProps) => {
  const { attachment, message, canSend } = stateProps;
  const { metadata = null } = attachment || {};
  const { taskId, conversationId } = ownProps;
  const { name: userName, avatar_text: userInitials } = window.current_user;

  return {
    ...ownProps,
    onClick: () => dispatchProps.requestSend({
      taskId,
      conversationId,
      userName,
      userInitials,
      message,
      uploadedAttachment: metadata,
    }),
    canSend,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(CommentBoxActions, dispatch);

const mapStateToProps = (state, ownProps) => {
  const noPendingUploads = !taskCommentBoxAttachmentRequiresUploadSelector(state, ownProps);
  const hasContent = taskCommentBoxHasContentSelector(state, ownProps);

  return {
    attachment: taskCommentBoxAttachmentSelector(state, ownProps),
    message: taskCommentBoxMessageSelector(state, ownProps),
    canSend: noPendingUploads && hasContent,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mapDerivedProps)
)(SendCommentButton);
