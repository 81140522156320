import { actionConstants } from '../constants';

export default (state, action) => {
  const initialState = {
    heading: '',
  };

  const currState = state || initialState;

  switch (action.type) {
    case actionConstants.SET_CONTENT:
      return action.content;
    case actionConstants.RESET_CONTENT:
      return initialState;
    default:
      return currState;
  }
};
