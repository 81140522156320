import moment from 'moment-timezone';
import Url from 'url';
import _ from 'lodash';

import queryBuilder from '../../common/api/queryBuilder';

const TrailConstants = {
  SCORECARD: 'scorecard',
  section: {
    MISSED: 'missed',
    NOW: 'now',
    TODAY: 'today',
    LATER_TODAY: 'later_today',
    AFTER_TODAY: 'after_today',
    DONE: 'done',
    LATER: 'later',
    AFTER_TOMORROW: 'after_tomorrow',
    ALL_DAY: 'all_day',
  },
  flaggable: {
    NOT_FLAGGABLE: 'not_flaggable',
    WITH_COMMENT: 'with_comment',
    WITHOUT_COMMENT: 'without_comment',
  },

  taskStatus: {
    MISSED: 'missed',
    CURRENT: 'current',
    OVERDUE: 'overdue',
    DONE: 'done',
    LATER: 'later',
    ONDEMAND: 'on_demand',
    SNOOZED: 'snoozed',
    FLAGGED: 'flagged',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    COMPLETED_LATE: 'completed_late',
    COMPLETED_EARLY: 'completed_early',
  },
  datePeriod: {
    TOMORROW: 'date.tomorrow',
    AFTER_TOMORROW: 'date.after_tomorrow',
    TODAY: 'date.today',
    PAST: 'date.past',
    isAfterTomorrow: datePeriod => datePeriod === TrailConstants.datePeriod.AFTER_TOMORROW,
    isFuture: datePeriod => datePeriod === TrailConstants.datePeriod.TOMORROW ||
        datePeriod === TrailConstants.datePeriod.AFTER_TOMORROW,
  },
  tags: {
    FILTER: 'trail.tags.filter',
    FILTER_TASKS_WITH_NO_TAGS: 'trail.tags.filter.tasks.with.no.tags',
    IS_ASSIGNED_TO_ME_TASK: 'trail.tags.filter.tasks.assigned.to.me',
  },
  locationStatus: {
    INACTIVE: 'inactive',
    ACTIVE: 'active',
    DEMO: 'demo',
    BUILDING: 'building',
    ARCHIVED: 'archived',
    SETUP: 'setup',
  },

  trailApiLoadType: {
    INITIAL: 'initial',
    ONLINE: 'online',
    TIMESLOT: 'timeslot',
    FILTERING: 'filtering',
  },

  ADD_CREATED_TIMELINE_TASK: 'trail.add_created_timeline_task',
  ADD_CREATED_TASK_ACTION: 'trail.add_created_task_action',
  UPDATE_TASK_ACTION: 'trail.update_task_action',
  MESSAGE_RECEIVED: 'trail.message_receieved',
  MESSAGE_DELETED: 'trail.message_deleted',
  UPDATE_TRAIL: 'trail.update_trail',
  TRAIL_UPDATE_FAILED: 'trail.trail_update_failed',
  TRAIL_LOADING: 'trail.loading',
  UPDATE_TASKS: 'trail.update_tasks',
  TRAIL_UPDATED: 'trail.trail_updated',
  MARK_COMPLETED: 'trail.mark_completed',
  MARK_UNCOMPLETED: 'trail.mark_uncompleted',
  COMPLETION_ATTEMPTED: 'trail.completion_attempted',
  MOVE_TASK_TO_COMPLETED: 'trail.move_task_to_completed',
  MOVE_TASK_TO_COMPLETED_FAILED: 'trail.move_task_to_completed_failed',
  TASK_DELETED: 'trail.delete_task',
  MOVE_TASK_FROM_COMPLETED: 'trail.move_task_from_completed',
  MOVE_TASK_FROM_COMPLETED_FAILED: 'trail.move_task_from_completed_failed',
  TASK_SNOOZED: 'trail.snoozed_task',
  TASK_FLAGGED: 'trail.flagged_task',
  TASK_UNFLAGGED: 'trail.unflagged_task',
  DELETE_ACTION_TASK: 'trail.delete_action_task',
  DELETE_ACTION_TASKS: 'trail.delete_action_tasks',
  TRAIL_MAX_DATE: 1,
  SET_TAGS: 'trail.set.tags',
  TRAIL_FETCH_SUCCESS: 'trail.get_trail_success',
  SET_FILTER: 'trail.set_filter',

  MIN_OFFLINE_DURATION: 4000,
  MS_IN_AN_HOUR: 3600000,
  MS_IN_A_DAY: 86400000,
};

TrailConstants.TASK_HEADER_STATUS_TO_ICONS = {
  [TrailConstants.taskStatus.OVERDUE]: 'mini/ui/ui-16px-2_time-clock',
  [TrailConstants.taskStatus.DONE]: 'mini/ui/ui-16px-1_check-simple',
  [TrailConstants.taskStatus.ONDEMAND]: 'mini/ui/ui-16px-1_bold-add',
  [TrailConstants.taskStatus.FLAGGED]: 'mini/maps-location/location-16px-e_flag-07',
};

TrailConstants.STATUSES_TO_DISPLAY_ON_TRAIL = [
  TrailConstants.taskStatus.CURRENT,
  TrailConstants.taskStatus.DONE,
  TrailConstants.taskStatus.LATER,
  TrailConstants.taskStatus.MISSED,
  TrailConstants.taskStatus.ONDEMAND,
  TrailConstants.taskStatus.OVERDUE,
  TrailConstants.taskStatus.FLAGGED,
];

TrailConstants.TASK_STATUS_THAT_PREVENT_FLAGGING = [
  TrailConstants.taskStatus.DONE,
  TrailConstants.taskStatus.SNOOZED,
  TrailConstants.taskStatus.FLAGGED,
  TrailConstants.taskStatus.ONDEMAND,
  TrailConstants.taskStatus.COMPLETED,
  TrailConstants.taskStatus.COMPLETED_LATE,
  TrailConstants.taskStatus.COMPLETED_EARLY,
];

TrailConstants.trailApiRoute = function (locationId, date, tagOptions, taskIdsToInclude, loadType) {
  const params = {};
  const tags = _.get(tagOptions, 'tags', null);
  const includeTasksWithoutTags = _.get(tagOptions, 'includeTasksWithoutTags', null);
  const isAssignedToMe = _.get(tagOptions, 'isAssignedToMe', null);

  const trailUrlSuffix = {
    [TrailConstants.trailApiLoadType.INITIAL]: '',
    [TrailConstants.trailApiLoadType.ONLINE]: '_online',
    [TrailConstants.trailApiLoadType.FILTERING]: '_filtering',
    [TrailConstants.trailApiLoadType.TIMESLOT]: '_timeslot',
  };

  let url = `/api/trail${trailUrlSuffix[loadType]}`;

  if (locationId) {
    params.location_id = locationId;
  }

  if (date) {
    params.date = date;
  }

  if (includeTasksWithoutTags) {
    params.includeTasksWithNoTags = 1;
  }

  if (isAssignedToMe) {
    params.isAssignedToMe = 1;
  }

  url = Url.format({
    pathname: url,
    query: params,
  });

  if (tags) {
    const tagQueryString = tags.reduce((queryString, tag) => `${queryString}&tags[]=${tag}`, '');
    url += tagQueryString;
  }

  if (taskIdsToInclude && taskIdsToInclude.length) {
    const taskIdsToIncludeQueryString = taskIdsToInclude.reduce((queryString, taskId) => `${queryString}&taskIdsToInclude[]=${taskId}`, '');
    url += taskIdsToIncludeQueryString;
  }

  return url;
};

TrailConstants.trailPageRoute = function (locationId, date) {
  let route = '/trail';

  if (locationId) {
    route = `${route}/${locationId}`;
  }

  if (date) {
    const parsedDate = moment(date).format('YYYY-MM-DD');
    route = `${route}/${parsedDate}`;
  }

  return route;
};

TrailConstants.taskOnTrailPageRoute = function (locationId, date, taskId) {
  let route = TrailConstants.trailPageRoute(locationId, date);

  if (taskId) {
    route = `${route}#/task/${taskId}`;
  }

  return route;
};

TrailConstants.getFlagTaskUrl = taskId => `${TrailConstants.FLAG_TASK_URL}${taskId}`;
TrailConstants.getUnflagTaskUrl = taskId => `${TrailConstants.UNFLAG_TASK_URL}${taskId}`;

TrailConstants.deleteOnDemandTaskUrl = function (taskId, locationId) {
  const params = { task_id: taskId, location_id: locationId };

  return Url.format({
    pathname: '/api/timeline_tasks/on_demand',
    query: params,
  });
};

TrailConstants.snoozeTaskUrl = function (taskId) {
  const url = `/api/v1/snoozed_tasks/${taskId}`;
  return url;
};

TrailConstants.getTimelineTaskUrl = (timelineTaskId, { fromPusher = false }) => {
  const queries = { fromPusher, ...(fromPusher && { order: -1 }) };

  return queryBuilder(`/api/timeline_tasks/${timelineTaskId}`, queries);
};

TrailConstants.FLAG_TASK_URL = '/api/timeline_tasks/flag/';
TrailConstants.UNFLAG_TASK_URL = '/api/timeline_tasks/unflag/';

export { TrailConstants };
