import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import {
  filterQueryKeys,
  filterTypes,
  getAvailableExceptionOptions,
  exceptionOptions,
} from '../constants';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

const queryKey = filterQueryKeys[filterTypes.EXCEPTIONS];

const { EXCEPTION_FILTER_APPLIED } = TrailMetricsDirectory.page.TaskReports;
const recordExceptionsMetric = (ids) => {
  const params = exceptionOptions.reduce((acc, k) => ({ ...acc, [k]: ids.includes(k) }), {});
  metricsPublisher.recordMetric(EXCEPTION_FILTER_APPLIED, params);
};

export const ExceptionsFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: false, filterMetric }
  );

  const handleSetQueryIds = (ids) => {
    setQueryIds(ids);
    recordExceptionsMetric(ids);
  };

  const { formatMessage } = useIntl();
  return (
    <Filter
      { ...props }
      setQueryIds={ handleSetQueryIds }
      clearQueryIds={ clearQueryIds }
      queryIds={ queryIds }
      items={
        getAvailableExceptionOptions().map(exception => ({
          id: exception,
          name: formatMessage({ id: `taskReports.exceptionFilters.${exception}` }),
        }))
      }
      hasLoaded
      showSearch={ false }
      filterType={ filterTypes.EXCEPTIONS }
      exclusionWhenAllSelected
    />
  );
};

ExceptionsFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default ExceptionsFilter;
