import _ from 'lodash';
import { RequestConstants } from '../request';
import { requestStatuses } from '../common/requestStatuses';
import TagConstants from './constants';

const tagsLoading = RequestConstants.getLoadingActionType(TagConstants.ORGANIZATION_TAGS_LOADED);
const tagsLoaded = RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_LOADED);
const tagsLoadErrored = RequestConstants.getErrorActionType(TagConstants.ORGANIZATION_TAGS_LOADED);

const actionHandlers = {
  [tagsLoading]: state => ({
    ...state,
    status: requestStatuses.LOADING,
  }),
  [tagsLoaded]: (state, action) => ({
    status: requestStatuses.LOADED,
    data: _.keyBy(action.content.data, 'id'),
  }),
  [tagsLoadErrored]: state => ({
    ...state,
    status: requestStatuses.ERROR,
  }),
};

const initialState = {
  status: requestStatuses.NOT_REQUESTED,
  data: {},
};

export const tags = (state, action) => {
  state = state || initialState;
  const handler = actionHandlers[action.type];
  if (handler) return handler(state, action);

  return state;
};
