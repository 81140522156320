import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import Table from '../../common/Table';
import { isValidSubtaskGroupName } from '../../common/subtaskValidator';
import SubtaskRow, { SubtaskName } from './SubtaskRow';

const subtaskRows = subtasks => _.chain(subtasks).orderBy('position').map(SubtaskRow).value();
const withoutAddedSubtasks = ({ addedByUserId }) => !addedByUserId;
const DIVIDER_ROW = ['---', '---'];

const getRowsForGroup = (rows, groupSubtasks, groupName) => {
  if (isValidSubtaskGroupName(groupName)) {
    rows.push([<SubtaskName>{ groupName }</SubtaskName>, null]);
  }
  return [...rows, ...subtaskRows(groupSubtasks)];
};

const SubtaskTable = ({ subtasks, intl: { formatMessage } }) => {
  const groups = _.chain(subtasks).filter(withoutAddedSubtasks).groupBy('groupName').value();
  const addedSubtasks = _.filter(subtasks, 'addedByUserId');

  return (
    <div className='showtime-task-reports__task-content-table'>
      <Table
        columnClasses={ [
          'showtime-task-reports__task-content-table-checklist', 'showtime-task-reports__task-content-table-metadata',
        ] }
        header={ [
          formatMessage({ id: 'taskReports.taskSubtasks.nameHeader' }), '',
        ] }
        rows={ [
          ..._.reduce(groups, getRowsForGroup, []),
          ...addedSubtasks.length ? [DIVIDER_ROW, ...subtaskRows(addedSubtasks)] : [],
        ] }
      />
    </div>
  );
};
SubtaskTable.propTypes = {
  subtasks: PropTypes.arrayOf(
    PropTypes.shape({
      ...SubtaskRow.propTypes,
      position: PropTypes.number,
      groupName: PropTypes.string,
      addedByUserId: PropTypes.number,
    })
  ).isRequired,
};

export default injectIntl(SubtaskTable);
