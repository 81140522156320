import { createCachedSelector } from 're-reselect';

import { VALIDATION_ERROR } from '../../common/constants/validation';
import { DataCaptureSelectors } from '../../widget/selectors';
import {
  taskCommentBoxHasContentSelector,
  showUnsavedTaskCommentErrorsSelector,
} from '../../conversation/selectors';

const taskWidgetIdSelector = (store, { task: { widget_data_id: widgetId } = {} }) => widgetId;

const taskWidgetErrorsSelector = (store, ownProps) => {
  const widgetId = taskWidgetIdSelector(null, ownProps);

  const { all: widgetErrors = {} } = DataCaptureSelectors.widgetErrorsSelector(
    store,
    { ...ownProps, widgetId, actions: ownProps.task.actions || [] },
    widgetId
  ) || {};
  return widgetErrors;
};

const hasWidgetFieldErrorsSelector = createCachedSelector(
  taskWidgetErrorsSelector,
  (widgetErrors) => {
    const recordHasValidationErrors = recordErrors => Object.values(recordErrors).some(fields => (
      fields.some(({ type }) => type === VALIDATION_ERROR)
    ));
    return Object.values(widgetErrors).some(recordHasValidationErrors);
  }
)((store, ownProps) => taskWidgetIdSelector(store, ownProps) || 'no_widget');

const requiresAMandatoryCommentSelector = (store, ownProps) => {
  const {
    completionErrors:
    { textCommentRequired = false, fileCommentRequired = false, cameraOnlyRequired = false } = { },
  } = ownProps;

  return textCommentRequired || fileCommentRequired || cameraOnlyRequired;
};

const commentBoxIsEmptySelector = (store, ownProps) => (
  !taskCommentBoxHasContentSelector(store, { taskId: ownProps.task.id })
);

const canCompleteSelector = createCachedSelector(
  hasWidgetFieldErrorsSelector,
  requiresAMandatoryCommentSelector,
  commentBoxIsEmptySelector,
  (hasWidgetErrors, requiresMandatoryComments, commentBoxIsEmpty) => {
    const noRecordLogErrors = !hasWidgetErrors;
    const noOutstandingMandatoryComment = !requiresMandatoryComments;

    return noRecordLogErrors && noOutstandingMandatoryComment && commentBoxIsEmpty;
  }
)((store, { task }) => task.id);

const showCommentErrorsSelector = (store, ownProps) => {
  if (ownProps.isTaskDone) return false;

  const { doneClicked, taskId } = ownProps;
  const hasContent = taskCommentBoxHasContentSelector(store, { taskId });
  const showUnsavedErrors = showUnsavedTaskCommentErrorsSelector(store, { taskId });
  const hasUnsavedErrors = showUnsavedErrors && hasContent;

  if (hasUnsavedErrors) return true;

  const requiresMandatoryComments = requiresAMandatoryCommentSelector(null, ownProps);
  const hasMandatoryErrors = doneClicked && requiresMandatoryComments;

  return hasMandatoryErrors;
};

const showCompletionErrorsSelector = (store, ownProps) => {
  if (ownProps.isTaskDone) return false;

  const hasVisibleCommentErrors = showCommentErrorsSelector(store, ownProps);
  if (hasVisibleCommentErrors) return true;

  const hasRecordLogErrors = hasWidgetFieldErrorsSelector(store, ownProps);
  return hasRecordLogErrors && ownProps.doneClicked;
};

export default {
  canCompleteSelector,
  taskWidgetIdSelector,
  showCommentErrorsSelector,
  showCompletionErrorsSelector,
};
