import React, { useState } from 'react';

import AnchoredMenu from '../../common/menu/AnchoredMenu';
import BulkTaskSelectionList from './BulkTaskSelectionList';
import BulkExportButton from './BulkExportButton';

const BulkTaskSelection = () => {
  const [showTaskGroupMenu, setShowTaskGroupMenu] = useState(false);

  return (
    <div className='showtime-task-reports__bulk-export-dropdown' data-test='taskReports.bulkExport'>
      <BulkExportButton onClick={ () => setShowTaskGroupMenu(true) } />
      <AnchoredMenu
        classes='showtime-task-reports__bulk-export-menu'
        showMenu={ showTaskGroupMenu }
        onClickOutside={ () => setShowTaskGroupMenu(false) }
      >
        <BulkTaskSelectionList />
      </AnchoredMenu>
    </div>
  );
};

export default BulkTaskSelection;
