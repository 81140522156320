import moment from 'moment-timezone';
import { RequestConstants } from '../../request';
import { TimeslotConstants } from '../constants';
import { timeslotHelper } from '..';

export const timeslotReducer = (function () {
  const initialState = function () {
    return {
      defaultTimeslots: [],
      defaultTimeslotsLoaded: false,
    };
  };

  const createTimeBlock = function (timeslotAttributes) {
    return {
      hour: timeslotAttributes.hour,
      minute: timeslotAttributes.minute,
      fmt: moment(`${timeslotAttributes.hour}:${timeslotAttributes.minute}`, TimeslotConstants.TIME_FMT).format(TimeslotConstants.TIME_FMT),
      dayOffset: timeslotAttributes.dayOffset,
    };
  };

  const endTimeslotIsTheStartOfTomorrow = function (nextTimeslot, index, timeslots) {
    return index === timeslots.length - 1 && nextTimeslot === timeslots[0];
  };

  const mapTimeslotsWithEndTime = function (timeslot, index, timeslots) {
    const nextTimeslot = timeslots[index + 1] || timeslots[0];
    if (endTimeslotIsTheStartOfTomorrow(nextTimeslot, index, timeslots)) {
      nextTimeslot.attributes.dayOffset = timeslot.attributes.dayOffset;
    }
    return {
      type: timeslot.type,
      id: timeslot.id,
      name: timeslot.attributes.name,
      start: createTimeBlock(timeslot.attributes),
      end: createTimeBlock(nextTimeslot.attributes),
    };
  };

  const defaultTimeslots = function (timeslot) {
    return timeslot.type === TimeslotConstants.DEFAULT_TIMESLOTS;
  };

  const transformApiTimeslot = function (apiTimeslot) {
    return {
      id: apiTimeslot.id,
      name: apiTimeslot.attributes.name,
      start: createTimeBlock(apiTimeslot.attributes),
      type: apiTimeslot.type,
    };
  };

  const setTimeslots = function (state, action) {
    const newTimeslots = action.content.data
      .filter(defaultTimeslots)
      .sort((firstApiTimeslot, secondApiTimeslot) => {
        const firstTimeslot = transformApiTimeslot(firstApiTimeslot);
        const secondTimeslot = transformApiTimeslot(secondApiTimeslot);
        return timeslotHelper.sortTimeslotByStartTime(firstTimeslot, secondTimeslot);
      })
      .map(mapTimeslotsWithEndTime);

    return {
      defaultTimeslots: newTimeslots,
      defaultTimeslotsLoaded: true,
    };
  };

  const reducer = function (state, action) {
    const currentState = state || initialState();
    switch (action.type) {
      case RequestConstants.getLoadedActionType(TimeslotConstants.TIMESLOTS_LOADED):
        return setTimeslots(state, action);
      default:
        return currentState;
    }
  };

  return reducer;
}());
