import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ConditionalFadeScroll } from '../forms/ConditionalFadeScroll';

export const ScrollableList = ({ children, dataTest, groupBorderBottom }) => children && (
  <ConditionalFadeScroll dataTest={ dataTest }>
    <ul className={ classNames({
      'showtime-list': true,
      'showtime-list--group-border-bottom': groupBorderBottom,
    }) }
    >
      {children}
    </ul>
  </ConditionalFadeScroll>
);

ScrollableList.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
  groupBorderBottom: PropTypes.bool,
};

ScrollableList.defaultProps = {
  dataTest: null,
  groupBorderBottom: false,
};
