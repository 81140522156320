import _ from 'lodash';

import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { AttachmentActions } from '../../attachments/actions';

const touchAndPassThrough = actionCreator => (...args) => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch(actionCreator(...args));
};

export const TaskAttachmentActions = _.mapValues(AttachmentActions, touchAndPassThrough);
