import moment from 'moment-timezone';

export const dateFilterKeys = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  SINGLE_DATE: 'singleDate',
  CUSTOM: 'custom',
  LAST_30_DAYS: 'last30Days',
  LAST_90_DAYS: 'last90Days',
  UNKNOWN: 'unknown',
};

export const determineDateFilterKey = (startDateMoment, endDateMoment) => {
  const isStartDateDaysAgo = days => startDateMoment.isSame(moment().subtract(days, 'days'), 'day');
  const isEndDateToday = () => endDateMoment.isSame(moment(), 'day');

  if (startDateMoment === null || endDateMoment === null) return dateFilterKeys.UNKNOWN;
  if (startDateMoment.toString() === 'Invalid date' ||
      endDateMoment.toString() === 'Invalid date') {
    return dateFilterKeys.UNKNOWN;
  }

  if (startDateMoment.isSame(endDateMoment, 'day')) {
    if (startDateMoment.isSame(moment(), 'day')) return dateFilterKeys.TODAY;
    if (isStartDateDaysAgo(1)) return dateFilterKeys.YESTERDAY;
    return dateFilterKeys.SINGLE_DATE;
  }

  if (isStartDateDaysAgo(30) && isEndDateToday()) {
    return dateFilterKeys.LAST_30_DAYS;
  }

  if (isStartDateDaysAgo(90) && isEndDateToday()) {
    return dateFilterKeys.LAST_90_DAYS;
  }

  return dateFilterKeys.CUSTOM;
};
