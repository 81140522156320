import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const INITIAL_VISIBLE_TASK_COUNT = 4;

export class WidgetMultipleTasksInfo extends Component {
  state = { showMore: false };

  handleShowMoreClick = () => this.setState({ showMore: true });

  render() {
    const { associatedTaskNames, intl, isTaskWidget } = this.props;
    if (!associatedTaskNames.length) return null;

    const intlFormat = (path, vars) => intl.formatMessage(
      { id: `widgetTaskAssignments.${path}` },
      vars
    );

    const showMoreCount = associatedTaskNames.length - INITIAL_VISIBLE_TASK_COUNT;
    const visibleTaskCount = this.state.showMore
      ? associatedTaskNames.length
      : INITIAL_VISIBLE_TASK_COUNT;

    return (
      <div
        className='showtime-widget-tasks showtime-callout-help showtime-callout-help--attention'
        data-test='widget.multipleTaskAssignment'
      >
        <div className='showtime-callout-help__rows'>
          <div className='showtime-callout-help__row-item'>
            { isTaskWidget
              ? intlFormat('widgetForTask', { by: associatedTaskNames.length })
              : intlFormat('widget') }
            <ul className='showtime-list showtime-list--bulleted'>
              { associatedTaskNames.slice(0, visibleTaskCount).map(name => (
                <li key={ name }>{name}</li>
              )) }
            </ul>
            {showMoreCount > 0 && !this.state.showMore && (
              <a
                className='showtime-link showtime-link--highlight'
                onClick={ this.handleShowMoreClick }
              >
                { intlFormat('showMore', { count: showMoreCount }) }
              </a>
            )}
            {
              associatedTaskNames.length > 0 && (
                <div className='unlink-record-log'>
                  <b> {intlFormat('unlinkRecordLog')} </b>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

WidgetMultipleTasksInfo.propTypes = {
  associatedTaskNames: PropTypes.array.isRequired,
  widgetDataType: PropTypes.string,
  editTaskId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isTaskWidget: PropTypes.bool,
};

WidgetMultipleTasksInfo.defaultProps = {
  editTaskId: null,
  isTaskWidget: false,
  widgetDataType: null,
};

const mapStoreToProps = (store, { widgetDataType, editTaskId }) => {
  const { taskTemplates } = store.base;

  const associatedTaskNames = taskTemplates.reduce((accu, { integration, name, id }) => {
    if (integration !== widgetDataType) return accu;
    if (editTaskId && id === Number.parseInt(editTaskId, 10)) return accu;

    return [...accu, name];
  }, []);

  return { associatedTaskNames };
};

export default compose(
  injectIntl,
  connect(mapStoreToProps)
)(WidgetMultipleTasksInfo);
