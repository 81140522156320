import { TaskPlannerConstants } from '../constants';

export const TaskPlannerTableActions = {
  updateSelectedTasks(taskId) {
    return {
      type: TaskPlannerConstants.UPDATE_SELECTED_TASKS,
      id: taskId,
    };
  },

  toggleTaskExpanded(taskId, allTasks) {
    return {
      type: TaskPlannerConstants.TOGGLE_TASK_EXPANDED,
      id: taskId,
      allTasks,
    };
  },
};
