import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path, Rect } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Rect
      is='rect'
      width='14'
      height='14'
      rx='2'
      fill={ (props.style && props.style.fill) || '#444' }
    />
    <Path
      is='path'
      fill='#fff'
      d='M7 2.625C4.59375 2.625 2.625 4.59375 2.625 7C2.625 9.40625 4.59375 11.375 7 11.375C9.40625 11.375 11.375 9.40625 11.375 7C11.375 4.59375 9.40625 2.625 7 2.625ZM7 3.71875C7.71094 3.71875 8.36719 3.9375 8.91406 4.32031L4.32031 8.91406C3.9375 8.36719 3.71875 7.71094 3.71875 7C3.71875 5.19531 5.19531 3.71875 7 3.71875ZM7 10.2812C6.28906 10.2812 5.63281 10.0625 5.08594 9.67969L9.67969 5.08594C10.0625 5.63281 10.2812 6.28906 10.2812 7C10.2812 8.80469 8.80469 10.2812 7 10.2812Z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
