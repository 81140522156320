import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { RouterConstants } from '../router';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import Button from '../common/Button';

const CopyLibraryTaskButton = ({
  intl,
  taskTemplateId,
  taskTemplateName,
  organizationId,
  history,
  className,
  hideLabel,
  dataHint,
}) => {
  const onClick = () => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskPlanner.LIBRARY_TEMPLATE_SELECTED,
      { taskTemplateId, taskTemplateName }
    );

    const route = RouterConstants.buildAddLibraryTaskUrl(organizationId, taskTemplateId);
    history.push(route);
  };

  return (
    <Button
      onClick={ onClick }
      buttonClasses={ `showtime-button--small ${className}` }
      label={ hideLabel ? '' : intl.formatMessage({ id: 'newTask.libraryAddTaskButton' }) }
      dataTest={ `copyLibraryTask-${taskTemplateId}` }
      iconName='mini/ui/ui-16px-1_simple-add'
      dataHint={ dataHint }
    />
  );
};

CopyLibraryTaskButton.propTypes = {
  intl: PropTypes.object.isRequired,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  taskTemplateId: PropTypes.number.isRequired,
  taskTemplateName: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  organizationId: PropTypes.number.isRequired,
  dataHint: PropTypes.string,
};

CopyLibraryTaskButton.defaultProps = {
  className: '',
  hideLabel: false,
  dataHint: false,
};

export default injectIntl(CopyLibraryTaskButton);
