import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Shortcut from './Shortcut';
import { getFixedShortcuts } from '../constants';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

export const queryMatchesCurrentSearch = (queryString, currentSearch) => (
  currentSearch.slice(1) === queryString
);
const sendShortcutMetric = (formatMessage, labelPath) => (
  metricsPublisher.recordMetric(
    TrailMetricsDirectory.page.TaskReports.SHORTCUT_CLICKED,
    { shortcut: formatMessage({ id: `taskReports.shortcuts.${labelPath}` }) }
  )
);

const FixedShortcuts = ({
  intl: { formatMessage }, history, location: { search: currentSearch },
}) => (
  <div className='showtime-shortcuts'>
    <div className='showtime-shortcuts__label'>
      { formatMessage({ id: 'taskReports.shortcuts.fixedTitle' }) }
    </div>
    {getFixedShortcuts().map(({ labelPath, queryString }) => (
      <Shortcut
        key={ labelPath }
        isSelected={ queryMatchesCurrentSearch(queryString, currentSearch) }
        onClick={
          () => {
            sendShortcutMetric(formatMessage, labelPath);
            history.push({ search: queryString });
          }
        }
      >
        { formatMessage({ id: `taskReports.shortcuts.${labelPath}` }) }
      </Shortcut>
    ))}
  </div>
);

FixedShortcuts.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default compose(
  injectIntl,
  withRouter
)(FixedShortcuts);
