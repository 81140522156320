import { IS_WITHIN_RANGE } from '../rules';

const requiredProperties = [
  'maxValueWarningLimit',
  'minValueWarningLimit',
  'outOfRangeWarningText',
];

export const hasNumberRangeValidation = (ruleConfig) => {
  const settingKeys = Object.keys(ruleConfig);
  return requiredProperties.every(requiredProperty => settingKeys.includes(requiredProperty)
  );
};

export default (ruleConfig) => {
  const alreadyHasRule = ruleConfig.rules && ruleConfig.rules.find(
    ({ key }) => key === IS_WITHIN_RANGE);

  if (alreadyHasRule || !hasNumberRangeValidation(ruleConfig)) {
    return ruleConfig;
  }

  const {
    outOfRangeWarningText,
    maxValueWarningLimit,
    minValueWarningLimit,
  } = ruleConfig;

  return {
    ...ruleConfig,
    rules: [
      ...ruleConfig.rules || [],
      {
        key: IS_WITHIN_RANGE,
        message: outOfRangeWarningText,
        options: {
          range: [minValueWarningLimit, maxValueWarningLimit],
        },
      },
    ],
  };
};
