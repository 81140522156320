import _ from 'lodash';

import { TagFilterConstants } from '../constants';
import TagConstants from '../../tags/constants';
import { RequestConstants } from '../../request';

export default (function () {
  function initialState() {
    return {
      open: false,
      tags: {},
      includeTasksWithoutTags: {
        selected: false,
        loading: false,
      },
      isAssignedToMe: {
        selected: false,
        loading: false,
      },
    };
  }

  function open(state) {
    return _.assign({}, state, {
      open: true,
    });
  }

  function close(state) {
    return _.assign({}, state, {
      open: false,
    });
  }

  function loading(state, action) {
    const tags = _.cloneDeep(state.tags);
    tags[action.tag.id].loading = true;
    return _.assign({}, state, {
      tags,
    });
  }

  function selected(state, action) {
    const tags = _.cloneDeep(state.tags);

    const mappedTags = _.values(tags).reduce((unmappedTags, tag) => {
      if (action.tags.indexOf(tag.id) === -1) {
        return unmappedTags;
      }
      unmappedTags[tag.id] = _.assign({}, tag, {
        loading: false,
        selected: true,
      });
      return unmappedTags;
    }, tags);

    const includeTasksWithoutTags = _.assign({}, state.includeTasksWithoutTags, {
      selected: action.includeTasksWithoutTags,
      loading: false,
    });

    const isAssignedToMe = {
      ...state.isAssignedToMe,
      selected: action.isAssignedToMe,
      loading: false,
    };

    return _.assign({}, state, {
      tags: mappedTags,
      includeTasksWithoutTags,
      isAssignedToMe,
    });
  }

  function deselected(state, action) {
    const tags = _.cloneDeep(state.tags);
    tags[action.tag.id].loading = false;
    tags[action.tag.id].selected = false;

    return _.assign({}, state, {
      tags,
    });
  }

  function clearAll(state) {
    const allTags = _.cloneDeep(state.tags);
    const tags = _.values(allTags).reduce((deselectedTags, tag) => {
      tag.selected = false;
      tag.loading = false;
      deselectedTags[tag.id] = tag;

      return deselectedTags;
    }, {});

    return _.assign({}, state, {
      tags,
      includeTasksWithoutTags: {
        selected: false,
        loading: false,
      },
      isAssignedToMe: {
        selected: false,
        loading: false,
      },
    });
  }

  function tagCanBeRestored(tag, restoredTags) {
    return restoredTags.indexOf(tag.id) !== -1;
  }

  function addOrganizationTags(state, action) {
    const restoredTags = action.content.tags || [];
    const includeTasksWithoutTags = {
      selected: action.content.includeTasksWithoutTags,
      requestingSelected: action.content.includeTasksWithoutTags,
      loading: false,
    };
    const isAssignedToMe = {
      selected: action.content.isAssignedToMe,
      requestingSelected: action.content.isAssignedToMe,
      loading: false,
    };
    const tags = action.content.data.reduce((buildTags, tag) => {
      buildTags[tag.id] = {
        selected: tagCanBeRestored(tag, restoredTags),
        loading: false,
        id: tag.id,
        label: tag.attributes.label,
      };
      return buildTags;
    }, {});

    return _.assign({}, state, {
      tags,
      includeTasksWithoutTags,
      isAssignedToMe,
    });
  }

  function setTaskWithoutTagsToLoading(state) {
    return _.merge({}, state, {
      includeTasksWithoutTags: {
        requestingSelected: !state.includeTasksWithoutTags.selected,
        selected: false,
        loading: true,
      },
    });
  }

  function setIsAssignedToMeLoading(state) {
    return {
      ...state,
      isAssignedToMe: {
        ...state.isAssignedToMe,
        requestingSelected: !state.isAssignedToMe.selected,
        selected: false,
        loading: true,
      },
    };
  }

  function reduce(state, action) {
    const currentState = state || initialState();
    switch (action.type) {
      case TagFilterConstants.OPEN:
        return open(currentState, action);
      case TagFilterConstants.CLOSE:
        return close(currentState, action);
      case TagFilterConstants.LOADING:
        return loading(currentState, action);
      case TagFilterConstants.SELECT:
        return selected(currentState, action);
      case TagFilterConstants.DESELECT:
        return deselected(currentState, action);
      case TagFilterConstants.CLEAR_ALL:
        return clearAll(currentState, action);
      case TagFilterConstants.SET_TAGS_WITHOUT_TAGS_TO_LOADING:
        return setTaskWithoutTagsToLoading(currentState, action);
      case TagFilterConstants.SET_ASSIGN_TO_ME_TASKS_TO_LOADING:
        return setIsAssignedToMeLoading(currentState, action);
      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_LOADED):
        return addOrganizationTags(currentState, action);
      default:
        return currentState;
    }
  }

  return reduce;
}());
