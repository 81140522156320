import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';
import { AnchoredMenu } from '.';
import random from '../random';

export default class DropDownMenu extends Component {
  state = { expand: this.props.initiallyExpanded };

  toggleMenu = () => this.setState(({ expand }) => ({ expand: !expand }));

  hideMenu = () => this.setState({ expand: false });

  closeMenu() {
    this.hideMenu();
  }

  render() {
    const {
      label, dataTestKey, children, containerClassName, iconName,
      iconPosition, secondaryLabel, disabled, buttonClassName,
      onClick, refFn, flexibleLines,
    } = this.props;

    const uniqueClickOutsideClass = `outside--click-ignore-${random() * 10000}`;
    return (
      <div className={ containerClassName } ref={ refFn }>
        <Button
          { ... {
            buttonClasses: classnames(
              'showtime-button--default showtime-button--small',
              buttonClassName,
              {
                'showtime-button--multiline': !flexibleLines && secondaryLabel,
                'showtime-button--flexible-lines': flexibleLines,
                'is-disabled': disabled,
              },
              uniqueClickOutsideClass
            ),
            label,
            secondaryLabel,
            iconName,
            iconPosition,
            onClick: () => {
              onClick();
              this.toggleMenu();
            },
            dataTest: dataTestKey,
          } }
        />
        { this.state.expand && (
          <AnchoredMenu
            align='right'
            onClickOutside={ this.hideMenu }
            outsideClickIgnoreClass={ uniqueClickOutsideClass }
          >
            {children}
          </AnchoredMenu>
        )}
      </div>

    );
  }
}
DropDownMenu.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  label: PropTypes.node.isRequired,
  secondaryLabel: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.string,
  dataTestKey: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  refFn: PropTypes.object,
  initiallyExpanded: PropTypes.bool,
  flexibleLines: PropTypes.bool,
};

DropDownMenu.defaultProps = {
  children: null,
  containerClassName: null,
  buttonClassName: '',
  dataTestKey: undefined,
  secondaryLabel: undefined,
  iconName: undefined,
  iconPosition: 'right',
  disabled: false,
  refFn: null,
  onClick: () => {},
  initiallyExpanded: false,
  flexibleLines: false,
};
