import { getCurrentUserRole } from '../application/UserRole';

const SHADOW_BAR_HEIGHT_PX = 40;
const ADMIN_NAVIGATION_HEIGHT_PX = 64;
const DESKTOP_WIDTH = 1024;

export default (width) => {
  const onDesktop = width >= DESKTOP_WIDTH;
  const onTrail = window.location.pathname.includes('trail');
  const CurrentUserRole = getCurrentUserRole();

  const adminNavOffset = onTrail && onDesktop && CurrentUserRole.isManagerOrAbove()
    ? ADMIN_NAVIGATION_HEIGHT_PX : 0;
  const shadowOffset = CurrentUserRole.userIsBeingShadowed ? SHADOW_BAR_HEIGHT_PX : 0;

  return shadowOffset + adminNavOffset;
};
