export const requestStatuses = {
  LOADED: 'loaded',
  LOADING: 'loading',
  ERROR: 'error',
  NOT_REQUESTED: 'notRequested',
};

export const isLoading = status => status === requestStatuses.LOADING;
export const isLoaded = status => status === requestStatuses.LOADED;
export const hasError = status => status === requestStatuses.ERROR;
export const isNotRequested = status => status === requestStatuses.NOT_REQUESTED;
