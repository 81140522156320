import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ApplicationEventsStore from '../../../../../application/store';
import cellValidationRulesFactory from '../../../cellValidationRulesFactory';
import {
  classNamePrefix,
  FORMATTED_NUMBER,
  FREE_TEXT,
  inputTypes,
  TEXT_INPUT_NUMBER,
  SECTION_TITLE,
  TEXT_INPUT_TEXT,
} from '../../constants';
import getFocusableFieldNames from '../getFocusableFieldNames';
import canCurrentUserModifyCell from '../canCurrentUserModifyCell';
import Header from './Header';
import InputComponent from './InputComponent';
import RecordScore from '../../RecordScore';
import SectionTitle from '../SectionTitle';
import ActionModal from '../../../../../taskActions/ActionModal';
import Timer from '../PrototypeTimer';
import calculateVisibleFieldsByLogic from '../../../../calculateVisibleFieldsByLogic';

const getInputType = (cellType) => {
  switch (cellType) {
    case FORMATTED_NUMBER:
      return TEXT_INPUT_NUMBER;
    case FREE_TEXT:
      return TEXT_INPUT_TEXT;
    default:
      return null;
  }
};

const getActionTemplateId = (cellType, cellSettings, validationRule, value) => {
  if (cellType === FORMATTED_NUMBER) return validationRule.actionTemplateId;
  const valueIndex = _.get(cellSettings, 'values', []).indexOf(value);
  if (valueIndex === -1) return null;

  return _.get(cellSettings, ['actionTemplateIds', valueIndex]);
};

const ALLOWED_SETTINGS = [
  'copyValueToNew',
  'display',
  'fieldName',
  'mandatory',
  'prefixText',
  'suffixText',
  'fieldName',
  'labels',
  'optionType',
  'values',
  'requireOptionAction',
  'requireValidationAction',
  'actionTemplateId',
];

class Record extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOffline: false,
      isDeleteConfirmationShown: false,
      modalFieldName: null,
      modalTaskTemplateId: null,
      modalTimelineTaskId: null,
    };

    this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
    this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  componentDidMount() {
    ApplicationEventsStore.addConnectionStatusListener(this.onConnectionStatusChanged);
  }

  componentWillUnmount() {
    ApplicationEventsStore.removeConnectionStatusListener(this.onConnectionStatusChanged);
  }

  onConnectionStatusChanged = () => {
    this.setState({
      isOffline: !ApplicationEventsStore.isOnline(),
    });
  };

  onCorrectiveActionClick = ({ fieldName, rowId }) => {
    const action = _.find(this.props.actions, {
      widget_row_index: rowId,
      widget_field_name: fieldName,
    });
    const {
      action_timeline_task_id: modalTimelineTaskId = null,
      action_task_template_id: modalTaskTemplateId = null,
    } = action || {};
    this.setState({ modalFieldName: fieldName, modalTaskTemplateId, modalTimelineTaskId });
  };

  handleModalClose = () => {
    this.setState({ modalFieldName: null, modalTaskTemplateId: null });
  };

  showDeleteConfirmation() {
    this.setState({
      isDeleteConfirmationShown: true,
    });
  }

  hideDeleteConfirmation() {
    this.setState({
      isDeleteConfirmationShown: false,
    });
  }

  deleteRecord() {
    const {
      onDelete,
      activeRow: {
        rowId,
      },
    } = this.props;

    this.hideDeleteConfirmation();
    onDelete(rowId);
  }

  render() {
    const {
      visibleActiveRowPosition,
      activeRow,
      columns,
      displayNavElements,
      hideTaskContentComments,
      errors,
      isNewListIndex,
      minimumRows,
      onEdit,
      rowCount,
      widgetId,
      taskId,
      readOnly,
      isTaskCompleted,
      router,
      routePrefix,
      taskHeaderHeight,
      actions,
      preview,
    } = this.props;

    const {
      isDeleteConfirmationShown,
    } = this.state;

    const filteredColumns = columns.reduce((acc, column) => inputTypes.includes(column.cellType)
      ? [
        ...acc,
        column,
      ]
      : acc, []);

    const { rowId } = activeRow;

    const rowActions = _.filter(actions, {
      widget_row_index: rowId,
    });

    const focusableFieldNames = getFocusableFieldNames(activeRow, filteredColumns);

    const columnsBySectionTitle = filteredColumns.reduce((accu, column) => {
      const { cellType, columnTitle } = column;

      if (cellType === SECTION_TITLE) {
        return [...accu, [columnTitle, []]];
      }

      const sectionColumns = _.chain(accu).last().last().value();
      sectionColumns.push(column);
      return accu;
    }, [['', []]]);

    const visibleFieldsByLogic = calculateVisibleFieldsByLogic(activeRow, columns);

    return (
      <Fragment>
        {displayNavElements
          && (
          <Header
            { ...{
              visibleActiveRowPosition,
              deleteRecord: this.deleteRecord,
              hideDeleteConfirmation: this.hideDeleteConfirmation,
              isDeleteConfirmationShown,
              isOffline: this.state.isOffline,
              minimumRows,
              rowCount,
              showDeleteConfirmation: this.showDeleteConfirmation,
              widgetId,
              actionCount: rowActions.length,
            } }
          />
          )}

        <div className={ `${classNamePrefix}-fields` }>
          {columnsBySectionTitle.map(([sectionTitle, sectionColumns]) => (
            <React.Fragment>
              {sectionTitle && (
                <SectionTitle
                  { ...{
                    row: activeRow, sectionColumns, columns, taskHeaderHeight,
                  } }
                >
                  {sectionTitle}
                </SectionTitle>
              )}
              <React.Fragment>
                {sectionColumns.map((column, index) => {
                  const {
                    cellType,
                    cellSettings,
                    columnTitle,
                  } = column;
                  const {
                    cellStyle,
                    cellColumnHeading,
                    columnRestricted,
                    fieldName,
                  } = cellSettings || {};

                  if (!visibleFieldsByLogic.includes(fieldName) && !preview) return null;

                  const containerKey = `input-field__${index}`;
                  const focusInput = isNewListIndex && fieldName === focusableFieldNames[0]
                    ? ref => ref && ref.focus()
                    : null;

                  const cellSettingsWithConditionalValidationRules = _.pick({
                    ...cellSettings,
                    ...cellValidationRulesFactory.build(cellSettings, activeRow),
                  }, ALLOWED_SETTINGS);
                  const identifier = `${widgetId}_${rowId}_${fieldName}`;

                  const {
                    message: errorText, type: errorType,
                  } = _.get(errors, [fieldName, 0], {});

                  const action = _.find(actions, {
                    widget_row_index: rowId,
                    widget_field_name: fieldName,
                  });
                  const hasCompletedAction = !!_.get(action, 'action_completed_at', false);

                  const inputComponentProps = {
                    ...cellSettingsWithConditionalValidationRules,
                    cellClassName: cellSettings ? cellStyle : '',
                    cellColumnHeading: cellSettings ? cellColumnHeading : '',
                    columnId: index,
                    columnTitle,
                    disabled: !canCurrentUserModifyCell(columnRestricted),
                    errorText,
                    errorType,
                    focusInput,
                    id: identifier,
                    inputType: getInputType(cellType),
                    key: identifier,
                    onEdit: readOnly || isTaskCompleted ? () => {} : onEdit,
                    rowId,
                    value: activeRow[fieldName],
                    fieldName,
                    widgetId,
                    taskId,
                    readOnly,
                    isTaskCompleted,
                    router,
                    routePrefix,
                    hideTaskContentComments,
                    onCorrectiveActionClick: this.onCorrectiveActionClick,
                    hasAction: !!action,
                    hasCompletedAction,
                    taskHeaderHeight,
                  };

                  if (!InputComponent) return null;

                  return (
                    <React.Fragment>
                      { this.state.modalFieldName === fieldName && (
                        <ActionModal
                          onClose={ this.handleModalClose }
                          widgetId={ widgetId }
                          rowId={ this.props.activeRow.rowId }
                          fieldName={ this.state.modalFieldName }
                          taskName={ this.props.taskName }
                          taskId={ this.props.taskId }
                          widgetName={ this.props.widgetName }
                          defaultTitle={ columnTitle }
                          modalTaskTemplateId={ this.state.modalTaskTemplateId }
                          modalTimelineTaskId={ this.state.modalTimelineTaskId }
                          actionTemplateId={
                            getActionTemplateId(
                              cellType,
                              cellSettings,
                              cellSettingsWithConditionalValidationRules,
                              activeRow[fieldName]
                            )
                          }
                        />
                      )}
                      <InputComponent
                        key={ containerKey }
                        { ...inputComponentProps }
                        componentType={ cellType }
                        containerClassName={ `${classNamePrefix}-field-container` }
                      />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            </React.Fragment>
          ))}
          { window.config.features.recordTimerPrototype && <Timer { ...this.props } /> }
        </div>

        { displayNavElements && (
          <RecordScore
            columns={ filteredColumns }
            row={ activeRow }
            longForm
          />
        )}
      </Fragment>
    );
  }
}

Record.propTypes = {
  visibleActiveRowPosition: PropTypes.number.isRequired,
  activeRow: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  minimumRows: PropTypes.number.isRequired,
  hideTaskContentComments: PropTypes.bool.isRequired,
  displayNavElements: PropTypes.bool.isRequired,
  isNewListIndex: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  restrictRows: PropTypes.bool,
  rowCount: PropTypes.number.isRequired,
  widgetId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isTaskCompleted: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
  taskHeaderHeight: PropTypes.number,
  taskName: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  widgetName: PropTypes.string.isRequired,
  actions: PropTypes.array,
  preview: PropTypes.bool.isRequired,
};

Record.defaultProps = {
  restrictRows: false,
  routePrefix: '',
  taskHeaderHeight: 0,
  actions: [],
};

export default Record;
