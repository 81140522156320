import { actionConstants } from '../constants';

export default (state, action) => {
  const initialState = false;

  const currState = state || initialState;

  switch (action.type) {
    case actionConstants.SHOW:
      return true;

    case actionConstants.HIDE:
      return false;

    default:
      return currState;
  }
};
