const HOURS = 24;
const MINUTES = 60;
const SECONDS = 60;
const MILISECONDS = 1000;
const DEFAULT_EXPIRY = 14;
const DEFAULT_PATH = '/';

export const cookieJar = (function () {
  const cookiesToObject = () => {
    const cookies = document.cookie;
    const cookieMap = cookies.split(';').reduce((value, cookie) => {
      const [key, data] = cookie.trim().split('=');
      value[key] = data;
      return value;
    }, {});
    return cookieMap;
  };

  return {
    get(key) {
      const cookies = cookiesToObject();
      return cookies[key];
    },
    getObject(key) {
      const value = this.get(key);
      if (!value) {
        return null;
      }
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    },
    set(key, value, daysToExpiry, path) {
      daysToExpiry = daysToExpiry || DEFAULT_EXPIRY;
      path = path || DEFAULT_PATH;
      const date = new Date();
      date.setTime(date.getTime() + (daysToExpiry * HOURS * MINUTES * SECONDS * MILISECONDS));
      const expires = `expires=${date.toUTCString()}`;
      const cookieString = `${key}=${value};${expires};path=${path}`;
      document.cookie = cookieString;
    },
    remove(key, path = DEFAULT_PATH) {
      const cookieString = `${key}=;path=${path};max-age=0`;
      document.cookie = cookieString;
    },
  };
}());
