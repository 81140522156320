import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import FileViewer from '../file/FileViewer';
import Overlay from '../overlay/Overlay';


const OverlayFileViewer = ({ history, intl, match: { params: { handle } } }) => (
  <Overlay
    dataTest='taskReports.attachment.overlay'
    backPage='/reports#/tasks'
    history={ history }
    title={ intl.formatMessage({ id: 'fileViewer.title' }) }
    overlayContainerClasses='showtime-overlay--file-viewer'
  >
    <FileViewer
      handle={ handle }
      organizationId={ window.current_user.organization_id }
    />
  </Overlay>
);

OverlayFileViewer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      handle: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.object.isRequired,
  }).isRequired,
};

export default injectIntl(OverlayFileViewer);
