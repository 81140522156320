const requestMiddlewareConstants = {
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',
  GET: 'get',
  getLoadingActionType: actionType => `${actionType}.request_middleware_loading`,
  getLoadedActionType: actionType => `${actionType}.request_middleware_loaded`,
  getErrorActionType: actionType => `${actionType}.request_middleware_error`,
};

requestMiddlewareConstants.ALLOWED_METHODS = [
  requestMiddlewareConstants.PUT,
  requestMiddlewareConstants.POST,
  requestMiddlewareConstants.DELETE,
  requestMiddlewareConstants.GET,
];

export default requestMiddlewareConstants;
