import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Button from '../../../../common/Button';

const calculateEndTime = ({ hours, minutes, startTime }) => {
  const time = startTime ? new Date(startTime).getTime() : Date.now();

  return new Date(
    time +
    hours * 60 * 60 * 1000 +
    minutes * 60 * 1000
  ).toLocaleTimeString([], { timeStyle: 'short' });
};

const TimerMessage = ({ children }) => (
  <div style={ { height: 35 } }>{children}</div>
);

TimerMessage.propTypes = {
  children: PropTypes.any.isRequired,
};

const Timer = ({
  hours,
  minutes,
  startTime,
}) => (
  <div>
    <p style={ {
      marginBottom: 10,
    } }
    >
      {hours > 1 && `${hours} hours`}
      {hours === 1 && '1 hour'}
      {hours > 0 && minutes > 0 && ' and '}
      {minutes > 1 && `${minutes} minutes`}
      {minutes === 1 && '1 minute'}
    </p>
    <p style={ {
      marginBottom: 10,
    } }
    >
      Ends at {calculateEndTime({ hours, minutes, startTime })}
    </p>
  </div>
);

Timer.propTypes = {
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  startTime: PropTypes.string,
};

Timer.defaultProps = {
  startTime: null,
};

const StartTime = ({
  onClick,
}) => (
  <Button
    onClick={ onClick }
    label='Start timer'
    iconName='mini/ui/ui-16px-2_alarm-clock'
    buttonClasses='showtime-button--block showtime-button--default'
  />
);

StartTime.propTypes = {
  started: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const PrototypeTimer = ({
  onTimerChange,
  activeRow: {
    rowId,
  },
  timers,
  timerSettings,
  taskId,
}) => {
  const {
    name,
    hours,
    minutes,
  } = timerSettings;
  const noSettingsExist = Object.keys(timerSettings).length === 0;
  if (noSettingsExist) return null;

  timers = timers || [];
  const timer = timers.find(({ rowIndex }) => rowIndex === rowId);
  const { startTime, stoppedAt, stoppedBy } = timer || { startTime: null, stoppedAt: null };

  const dispatch = useDispatch();

  const started = startTime !== null;

  const endTime = (startTime ? new Date(startTime).getTime() : Date.now()) +
    60 * 60 * hours * 1000 +
    60 * minutes * 1000;
  let minutesLeft = Math.round((endTime - Date.now()) / 60 / 1000);
  if (stoppedAt || minutesLeft < 0) minutesLeft = 0;

  const stoppedWith = stoppedAt ? Math.round((endTime - stoppedAt) / 1000 / 60) : null;

  const handleEndTimer = () => {
    onTimerChange(rowId, {
      startTime,
      stoppedAt: Date.now(),
      stoppedBy: window.current_user.name,
      taskId,
      seen: true,
      lastUpdated: Date.now(),
    });
    dispatch({ type: 'timer.update' });
  };

  return (
    <div className='showtime-widget-v2__list-field-container'>
      <div className={
        classNames(
          'showtime-widget-v2__list-field',
          { 'is-warning': started }
        )
      }
      >
        <span
          className='showtime-label showtime-label--block'
          style={ {
            marginBottom: 5,
          } }
        >
          <span className='showtime-label__text'>
            {name}
          </span>
        </span>
        {!stoppedAt && (
        <div style={ { marginBottom: 15 } }>
          <Timer
            startTime={ startTime }
            hours={ hours }
            minutes={ minutes }
          />
        </div>
        )}
        {
          started && (
            <div>
              {
                minutesLeft > 1 &&
                  (<TimerMessage>There are {minutesLeft} minutes left</TimerMessage>)
              }
              {
                minutesLeft === 1 &&
                  (<TimerMessage>There is {minutesLeft} minute left</TimerMessage>)
              }
              {
                (stoppedAt || minutesLeft <= 0) &&
                  (<TimerMessage>The timer has finished</TimerMessage>)
              }
              {
                stoppedAt &&
                  (<TimerMessage>The timer was stopped at {new Date(stoppedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} by {stoppedBy}</TimerMessage>)
              }
              {stoppedWith &&
                (<TimerMessage>Stopped {stoppedWith < 0 ? `${-1 * stoppedWith} minutes after the finish time` : `${stoppedWith} minutes before the timer was due`}</TimerMessage>)}
            </div>
          )
        }
        {
          started && (
          <Button
            onClick={ () => {
              if (!stoppedAt) handleEndTimer();
            } }
            disabled={ stoppedAt }
            label='Product sold out'
            iconName='mini/ui/ui-16px-1_simple-remove'
            buttonClasses={ classNames('showtime-button--delete-confirm', { 'is-disabled': stoppedAt }) }
          />
          )
        }
        {!started && (
        <div style={ {
          display: 'flex',
          alignItems: 'center',
        } }
        >
          <div>
            <StartTime
              onClick={ () => {
                const updatedStartTime = started ? null : new Date();
                onTimerChange(rowId, {
                  startTime: updatedStartTime,
                  taskId,
                  seen: false,
                  lastUpdated: Date.now(),
                });
                dispatch({ type: 'timer.update' });
              } }
            />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

PrototypeTimer.propTypes = {
  onTimerChange: PropTypes.func.isRequired,
  activeRow: PropTypes.object.isRequired,
  timerSettings: PropTypes.shape({
    name: PropTypes.string,
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  timers: PropTypes.array,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PrototypeTimer.defaultProps = {
  timerSettings: {
    name: 'Timer',
    hours: 0,
    minutes: 0,
  },
  timers: [],
};

export default PrototypeTimer;
