const railsArrayFilterType = '';
const serializeInFilter = filterValue => `[${filterValue.join(',')}]`;

const serializeFilter = (filterType, filterValue) => {
  let serializedFilter;
  switch (filterType) {
    case railsArrayFilterType:
      return filterValue.map(entry => `=${entry}`);
    case 'in':
      serializedFilter = serializeInFilter(filterValue);
      break;
    case 'gt':
    case 'gte':
    case 'lt':
    case 'lte':
    case 'is':
      serializedFilter = filterValue;
      break;
    default:
      throw new Error(`Unrecognised filter: ${filterType}`);
  }
  return `[${filterType}]=${serializedFilter}`;
};

export default (filters) => {
  if (Array.isArray(filters)) {
    return serializeFilter(railsArrayFilterType, filters);
  } if (typeof filters === 'object') {
    return Object
      .keys(filters)
      .map(filterType => serializeFilter(filterType, filters[filterType]));
  }

  return [`=${filters}`];
};
