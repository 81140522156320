import fp from 'lodash/fp';
import { combineReducers } from 'redux';
import _ from 'lodash';
import WidgetConstants, {
  loading, success, error,
  EMPTY_SCORE, DEFAULT_SCORE,
  optionsDisplay,
} from '../constants';
import { RequestConstants } from '../../request';
import { TaskConstants } from '../../task/constants';
import { TaskWidgetConstants } from '../../task-wizard/constants';
import { OPTIONS } from '../data-capture/v2/constants';
import { getAllColumnErrors } from './builderValidation/allColumnErrors';
import { getNumberColumnAcceptableRangeErrors } from './builderValidation/numberColumnAcceptableRange';
import { getOptionsColumnErrors } from './builderValidation/optionsColumn';
import { getColumnTitleErrors } from './builderValidation/columnTitle';

const { BUTTONS } = optionsDisplay;

const widgetConfigLoadedType = RequestConstants.getLoadedActionType(
  WidgetConstants.WIDGET_CONFIGURATION_LOADED
);
const widgetEditLoadingType = RequestConstants.getLoadingActionType(
  WidgetConstants.WIDGET_EDIT_REQUEST
);

const widgetEditSuccessType = RequestConstants.getLoadedActionType(
  WidgetConstants.WIDGET_EDIT_REQUEST
);

const widgetEditErrorType = RequestConstants.getErrorActionType(
  WidgetConstants.WIDGET_EDIT_REQUEST
);

export const widgetConfigurationReducer = (state = null, action = {}) => {
  switch (action.type) {
    case widgetConfigLoadedType:
      return action.content;
    case WidgetConstants.CLEAR_WIDGET_STATE:
      return null;
    default:
      return state;
  }
};

const BOOL_ARRAY_FIELDS = [
  'optionsExceptions',
  'requireOptionAction',
  'requireMandatoryOptionAction',
  'optionsExceptionsWarningTexts',
  'actionTemplateIds',
];

const COLUMN_BASES_BY_CELL_TYPE = {
  OPTIONS: {
    cellSettings: {
      optionType: 'DROPDOWN',
      display: BUTTONS,
      labels: ['', '', ''],
      values: ['', '', ''],
      ...BOOL_ARRAY_FIELDS.reduce((accu, key) => ({
        ...accu,
        [key]: [false, false, false],
      }), {}),
    },
  },
  FREE_TEXT: {},
  FORMATTED_NUMBER: {},
  FILE: {},
  SECTION_TITLE: {},
};

const RULE_DEFAULT = {
  minValueWarningLimit: 0,
  maxValueWarningLimit: 0,
  outOfRangeWarningText: '',
};

const buildColumnForCellType = (cellType, fieldName) => {
  const column = _.cloneDeep(COLUMN_BASES_BY_CELL_TYPE[cellType]);
  column.cellSettings = column.cellSettings || {};
  column.cellSettings.fieldName = fieldName;
  column.cellType = cellType;
  return column;
};

const getColumnsWithValidationRulesReferencingColumn = (columns, column) => columns
  .filter(({ cellSettings }) => {
    const ruleColumn = _.get(cellSettings, ['validation', 'ruleColumn']);
    return ruleColumn === column.cellSettings.fieldName;
  });

const removeValidationRulesReferencingColumn = (columns, column) => {
  if (column.cellType !== OPTIONS) { return columns; }

  const newColumns = _.cloneDeep(columns);

  getColumnsWithValidationRulesReferencingColumn(newColumns, column)
    .forEach(({ cellSettings }) => {
      delete cellSettings.validation;
    });

  return newColumns;
};

const updateValidationRulesAfterColumnUpdate = (columns, column, oldValue, newValue) => {
  if (column.cellType !== OPTIONS) { return columns; }

  const newColumns = _.cloneDeep(columns);

  getColumnsWithValidationRulesReferencingColumn(newColumns, column)
    .forEach(({ cellSettings }) => {
      cellSettings.validation.rules = _.mapKeys(cellSettings.validation.rules,
        (value, key) => key === oldValue ? newValue : key);
    });

  return newColumns;
};

const findColumnWithFieldName = (fieldName, columns) => columns.find((column = {}) => {
  const { cellSettings } = column;
  return cellSettings && cellSettings.fieldName === fieldName;
});

const getExistingColumnErrors = (state, fieldName) => _.get(state, `errors[${fieldName}]`, []);

export const initialStagedChangesValue = { replace: false, settings: null, isNew: false };

const INITIAL_NEW_DATA_CAPTURE = {
  isNew: true,
  settings: {
    auto_create_widget_data: true,
    public: {
      columns: [],
      widgetTitle: '',
      singleRecord: false,
      restrictRows: false,
      minimumRows: 1,
    },
  },
};

const stagedChangesActionCases = {
  [WidgetConstants.CLEAR_COLUMN_SCORES]: (state, action) => {
    const settings = fp.set(
      `public.columns[${action.columnIndex}].cellSettings.scores`, [], action.settings);

    return {
      ...state,
      settings,
      replace: true,
    };
  },
  [WidgetConstants.SET_WIDGET_AS_NEW]: (state, action) => ({
    ...state,
    settings: action.settings,
    isNew: true,
  }),
  [WidgetConstants.EXPAND_COLUMN]: (state, action) => {
    const shrinkingColumnName = state.expandedColumn;
    const expandingColumnName = action.fieldName;
    const newState = {
      ...state,
      expandedColumn: expandingColumnName,
    };
    if (!shrinkingColumnName || !state.settings) {
      return newState;
    }

    const shrinkingColumn = findColumnWithFieldName(
      shrinkingColumnName,
      state.settings.public.columns
    );
    const shrinkingColumnErrors = getAllColumnErrors(shrinkingColumn);

    return {
      ...newState,
      errors: {
        ...state.errors,
        [shrinkingColumnName]: shrinkingColumnErrors,
      },
    };
  },
  [WidgetConstants.VALIDATE_COLUMN_TITLE]: (state, action) => {
    const { columnId, value } = action;
    const columnErrors = getColumnTitleErrors(value, getExistingColumnErrors(state, columnId));
    return {
      ...state,
      errors: {
        ...state.errors,
        [columnId]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.VALIDATE_COLUMN_DROPDOWN_OPTION_LABEL]: (state, action) => {
    if (!state.settings) return state;

    const { columnId } = action;
    const column = findColumnWithFieldName(columnId, state.settings.public.columns);
    const { cellSettings: { labels, values } } = column;

    const columnErrors = getOptionsColumnErrors(
      labels, values, getExistingColumnErrors(state, columnId)
    );

    return {
      ...state,
      errors: {
        ...state.errors,
        [columnId]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.SET_COLUMN_TITLE]: (state, action) => {
    const { columnId, value } = action;
    const settings = fp.set(
      `public.columns[${action.columnIndex}].columnTitle`, value, action.settings
    );
    const columnErrors = getColumnTitleErrors(value, getExistingColumnErrors(state, columnId));
    return {
      ...state,
      settings,
      errors: {
        ...state.errors,
        [columnId]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.SORT_COLUMNS]: (state, action) => {
    const { fieldNameToNewPosition, settings } = action;
    const columns = _.sortBy(settings.public.columns, ({ cellSettings: { fieldName } }) => {
      const position = fieldNameToNewPosition[fieldName];
      return position;
    });

    return {
      ...state,
      replace: true,
      settings: {
        ...settings,
        public: { ...settings.public, columns },
      },
    };
  },
  [WidgetConstants.ADD_COLUMN_DROPDOWN_OPTION]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { cellSettings } = _.get(settings, ['public', 'columns', action.columnIndex]);
    const { values, labels, scores = [] } = cellSettings;
    values.push('');
    labels.push('');
    scores.length && scores.push(DEFAULT_SCORE);
    BOOL_ARRAY_FIELDS.forEach((key) => {
      cellSettings[key] = cellSettings[key]
        ? [...cellSettings[key], false]
        : new Array(cellSettings.labels.length).fill(false);
    });

    return { ...state, replace: true, settings };
  },
  [WidgetConstants.REMOVE_COLUMN_DROPDOWN_OPTION]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { cellSettings } = _.get(settings, ['public', 'columns', action.columnIndex]);
    const {
      labels,
      values,
      scores = [],
      logic,
      fieldName,
    } = cellSettings;
    values.splice(action.optionIndex, 1);
    labels.splice(action.optionIndex, 1);
    scores.splice(action.optionIndex, 1);
    BOOL_ARRAY_FIELDS.forEach((key) => {
      if (!cellSettings[key]) return;
      cellSettings[key].splice(action.optionIndex, 1);
    });
    _.forEach(logic, ({ ifCase }) => {
      if (!values.includes(ifCase.value)) {
        ifCase.value = null;
      }
    });

    const columnErrors = getOptionsColumnErrors(
      labels,
      values,
      getExistingColumnErrors(state, fieldName)
    );

    return {
      ...state,
      replace: true,
      settings,
      errors: {
        ...state.errors,
        [fieldName]: columnErrors,
      },
    };
  },
  [WidgetConstants.REMOVE_COLUMN_LOGIC_ITEM]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const logic = _.get(settings, `public.columns[${action.columnIndex}].cellSettings.logic`);
    logic.splice(action.logicIndex, 1);

    return {
      ...state,
      replace: true,
      settings,
    };
  },
  [WidgetConstants.CHANGE_COLUMN_DROPDOWN_OPTION_LABEL]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { columns } = settings.public;
    const column = columns[action.columnIndex];
    const {
      cellSettings,
      cellSettings: {
        fieldName, labels, values, logic,
      },
    } = column;

    const newLabelValue = action.value;
    const oldLabelValue = cellSettings.values[action.optionIndex];

    labels[action.optionIndex] = newLabelValue;
    values[action.optionIndex] = newLabelValue;

    const logicReferencingOldValue = _.find(logic, { ifCase: { value: oldLabelValue } });
    if (logicReferencingOldValue) {
      logicReferencingOldValue.ifCase.value = newLabelValue;
    }

    settings.public.columns = updateValidationRulesAfterColumnUpdate(
      columns, column, oldLabelValue, newLabelValue);

    const columnErrors = getOptionsColumnErrors(
      labels, values, getExistingColumnErrors(state, fieldName)
    );

    return {
      ...state,
      replace: true,
      settings,
      errors: {
        ...state.errors,
        [fieldName]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.CHANGE_ACCEPTABLE_RANGE]: (state, action) => {
    const { columnIndex, value, key } = action;
    const settings = _.cloneDeep(action.settings);
    const column = _.get(settings, `public.columns[${columnIndex}]`);
    const cellSettings = _.get(column, 'cellSettings', {});

    cellSettings[key] = value;

    const {
      fieldName, minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText,
    } = cellSettings;
    const columnErrors = getNumberColumnAcceptableRangeErrors(
      minValueWarningLimit,
      maxValueWarningLimit,
      outOfRangeWarningText,
      getExistingColumnErrors(fieldName)
    );

    return {
      ...state,
      replace: true,
      settings,
      errors: {
        ...state.errors,
        [fieldName]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.ADD_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const columns = _.get(settings, ['public', 'columns']);
    const position = action.position || columns.length;
    const newColumn = buildColumnForCellType(action.cellType, action.fieldName);
    columns.splice(position, 0, newColumn);
    return {
      ...state,
      replace: true,
      settings,
      expandedColumn: action.fieldName,
    };
  },
  [WidgetConstants.REMOVE_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { columns } = settings.public;
    const removedColumns = columns.splice(action.columnIndex, 1);
    settings.public.columns = removeValidationRulesReferencingColumn(columns, removedColumns[0]);
    const errors = _.cloneDeep(state.errors);
    delete errors[action.columnId];
    return {
      ...state, replace: true, settings, expandedColumn: null, errors,
    };
  },
  [WidgetConstants.DUPLICATE_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { columns } = settings.public;

    const newColumn = _.cloneDeep(columns[action.columnIndex]);
    newColumn.columnTitle = '';
    newColumn.cellSettings.fieldName = action.fieldName;
    const position = action.columnIndex + 1;
    columns.splice(position, 0, newColumn);

    return {
      ...state, replace: true, settings, expandedColumn: action.fieldName,
    };
  },
  [WidgetConstants.CLEAR_RULES_FROM_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const { cellSettings } = _.get(settings, ['public', 'columns', action.columnIndex]);
    cellSettings.validation.rules = {};
    delete cellSettings.validation.ruleColumn;
    return { ...state, replace: true, settings };
  },
  [WidgetConstants.ADD_RULE_TO_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const cellSettings = _.get(settings, ['public', 'columns', action.columnIndex, 'cellSettings']);
    const firstFieldName = _.get(settings, 'public.columns[0].cellSettings.fieldName');
    cellSettings.validation = {
      rules: { [firstFieldName]: RULE_DEFAULT },
      ruleColumn: firstFieldName,
    };
    return { ...state, replace: true, settings };
  },
  [WidgetConstants.SET_DEPENDENT_RULE_FOR_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);

    const { cellSettings: { values: dependentRuleValues } } = _.chain(settings)
      .get('public.columns', [])
      .find(({ cellSettings }) => cellSettings.fieldName === action.ruleColumn)
      .value();

    const rules = dependentRuleValues.reduce(
      (accu, value) => ({ ...accu, [value]: {} }),
      {}
    );

    const cellSettings = _.get(settings, ['public', 'columns', action.columnIndex, 'cellSettings']);
    cellSettings.validation = {
      rules,
      ruleColumn: action.ruleColumn,
    };
    return { ...state, replace: true, settings };
  },
  [WidgetConstants.CLEAR_ALL_VALIDATION_FROM_COLUMN]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const cellSettings = _.get(settings, ['public', 'columns', action.columnIndex, 'cellSettings']);
    cellSettings.validation = {};
    cellSettings.minValueWarningLimit = undefined;
    cellSettings.maxValueWarningLimit = undefined;
    cellSettings.outOfRangeWarningText = '';

    const {
      fieldName, minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText,
    } = cellSettings;
    const columnErrors = getNumberColumnAcceptableRangeErrors(
      minValueWarningLimit,
      maxValueWarningLimit,
      outOfRangeWarningText,
      getExistingColumnErrors(fieldName)
    );

    return {
      ...state,
      replace: true,
      settings,
      errors: {
        ...state.errors,
        [fieldName]: [...columnErrors],
      },
    };
  },
  [WidgetConstants.REMOVE_COLUMN_PREFIX_AND_SUFFIX]: (state, action) => {
    const settings = _.cloneDeep(action.settings);
    const cellSettings = _.get(settings, ['public', 'columns', action.columnIndex, 'cellSettings']);
    cellSettings.prefixText = '';
    cellSettings.suffixText = '';
    return { ...state, replace: true, settings };
  },
  [WidgetConstants.SET_COLUMN_SETTINGS]: (state, action) => {
    const { columnIndex, value } = action;
    const { fieldName } = action.settings.public.columns[columnIndex].cellSettings;
    const { cellSettings } = _.cloneDeep(_.get(state, `settings.public.columns[${columnIndex}]`, {}));
    const newCellSettings = {
      fieldName,
      ..._.merge(cellSettings, value),
    };
    const settings = fp.set(
      `public.columns[${action.columnIndex}].cellSettings`, newCellSettings, state.settings
    );
    return {
      ...state,
      settings,
    };
  },
  [WidgetConstants.SET_DEFAULT_COLUMN_SCORES]: (state, action) => {
    const columnPath = `public.columns[${action.columnIndex}].cellSettings`;
    const defaultScoresLength = _.get(action.settings, `${columnPath}.values.length`);
    const defaultRest = defaultScoresLength > 3
      ? new Array(defaultScoresLength - 3).fill(DEFAULT_SCORE)
      : [];
    const defaultScores = [1, 0, EMPTY_SCORE, ...defaultRest].slice(0, defaultScoresLength);

    const settings = fp.set(`${columnPath}.scores`, defaultScores, action.settings);

    return {
      ...state,
      settings,
      replace: true,
    };
  },
  [WidgetConstants.SET_PUBLIC_SETTINGS]: (state, action) => ({
    ...state,
    settings: fp.set(`public.${action.path}`, action.value, state.settings),
  }),
  [WidgetConstants.SET_SETTINGS]: (state, action) => ({
    ...state, replace: true, settings: action.value,
  }),
  [TaskWidgetConstants.CHANGE]: (state, action) => {
    if (action.createNew) return INITIAL_NEW_DATA_CAPTURE;

    return initialStagedChangesValue;
  },
};

export const stagedChangesReducer = (state = initialStagedChangesValue, action = {}) => {
  if (stagedChangesActionCases[action.type]) {
    return stagedChangesActionCases[action.type](state, action);
  }

  switch (action.type) {
    case TaskConstants.RESET:
      return initialStagedChangesValue;
    default:
      return state;
  }
};

export const requestedChangesReducer = (state = null, action = {}) => {
  switch (action.type) {
    case WidgetConstants.SET_WIDGET_REQUEST:
      return fp.set(action.path, action.value, state);
    case widgetConfigLoadedType:
    case WidgetConstants.CLEAR_WIDGET_STATE:
      return null;
    default:
      return state;
  }
};

export const requestStatusReducer = (state = null, action = {}) => {
  switch (action.type) {
    case widgetEditLoadingType:
      return loading;
    case widgetEditSuccessType:
      return success;
    case widgetEditErrorType:
      return error;
    case widgetConfigLoadedType:
    case WidgetConstants.SET_PUBLIC_SETTINGS:
    case WidgetConstants.CLEAR_WIDGET_STATE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  widgetConfiguration: widgetConfigurationReducer,
  stagedSettingsChanges: stagedChangesReducer,
  requestStatus: requestStatusReducer,
  requestedChanges: requestedChangesReducer,
});
