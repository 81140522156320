import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFileCdnUrl } from '../file/filestackApi';
import { canMakeValidCdnUrl } from '../file/securityValidation';
import { readPolicyHOC } from '../file/hoc';

export class AttachmentDownloader extends Component {
  static propTypes = {
    handle: PropTypes.string.isRequired,
    policy: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
  };
  downloadLink = null;
  downloadLinkRef = (el) => { this.downloadLink = el; }

  componentDidMount() {
    if (canMakeValidCdnUrl(this.props)) {
      this.triggerDownload(this.props);
    }
  }
  componentDidUpdate(prevProps) {
    if (canMakeValidCdnUrl(this.props) && !canMakeValidCdnUrl(prevProps)) {
      this.triggerDownload(this.props);
    }
  }

  triggerDownload = ({ handle, policy, signature }) => {
    if (this.downloadLink) {
      const url = getFileCdnUrl({ handle, policy, signature });
      const elem = this.downloadLink;
      elem.href = url;
      elem.click();
    }
  }

  render() {
    return (
      <a // eslint-disable-line jsx-a11y/anchor-has-content
        style={ { display: 'none' } }
        ref={ this.downloadLinkRef }
        download
      />
    );
  }
}

export default readPolicyHOC(AttachmentDownloader);
