import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SVGIcon from '../common/SVGIcon';
import { ToolTipWrapper } from '../common/ToolTip';

const RequiredPinIcon = ({ requiresPin, isTaskDone }) => {
  const { formatMessage } = useIntl();

  if (!window.config.features.taskPinSignOff) return null;
  if (!requiresPin || !isTaskDone) return null;

  return (
    <ToolTipWrapper dataTest='trail.task.status.requiresPin' message={ formatMessage({ id: 'trail.task.status.requiresPin' }) } bottom>
      <SVGIcon
        classes='showtime-icon showtime-icon--size-12 showtime-task__status-icon'
        iconName='mini/ui/ui-16px-1_lock'
      />
    </ToolTipWrapper>
  );
};

RequiredPinIcon.propTypes = {
  requiresPin: PropTypes.bool,
  isTaskDone: PropTypes.bool,
};

RequiredPinIcon.defaultProps = {
  requiresPin: false,
  isTaskDone: false,
};

export default RequiredPinIcon;
