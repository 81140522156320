/*
 * This TabPanel component is useful because it makes it easy to include
 * custom html inside the tab headers.
 *
 * Usage:
 *  <TabPanel selected={2}>
 *    <Tab>
 *      <TabHeader>Tab <b>#1</b></TabHeader>
 *      <TabContent>
 *        <p>Content.</p>
 *      </TabContent>
 *    </Tab>
 *    <Tab>
 *      <TabHeader>Tab <b>#2</b></TabHeader>
 *      <TabContent>
 *        <p>Content.</p>
 *      </TabContent>
 *    </Tab>
 *  </TabPanel>
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import TabHeader from './TabHeader';
import TabContent from './TabContent';

const TabType = (<Tab />).type;
const TabHeaderType = (<TabHeader />).type;
const TabContentType = (<TabContent />).type;

class TabPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
    };
  }

  selectChildrenWithType(children, type) {
    const result = [];
    React.Children.forEach(children, (child) => {
      if (child && child.type && child.type === type) {
        result.push(child);
      }
    });
    return result;
  }

  getTabChild(tab, type) {
    const element = this.selectChildrenWithType(tab.props.children, type);
    if (element.length === 0) throw new Error(`Tab without ${type.name}.`);
    if (element.length > 1) throw new Error(`Tab has more than one ${type.name}.`);
    return element[0];
  }

  prepareTabChild(tab, type, i) {
    const props = {
      selected: i === this.state.selected,
      index: i,
      key: type.name + i.toString(),
    };
    if (type === TabHeaderType) {
      props.onClick = (e) => {
        this.setSelected(i);
        e.preventDefault();
      };
    }
    return React.cloneElement(this.getTabChild(tab, type), props);
  }

  getTabs() {
    return this.selectChildrenWithType(this.props.children, TabType)
      .map((tab, i) => ({
        TabHeader: this.prepareTabChild(tab, TabHeaderType, i),
        TabContent: this.prepareTabChild(tab, TabContentType, i),
      }));
  }

  setSelected(index) {
    this.setState({ selected: index });
    this.props.onSelect(index);
  }

  render() {
    const tabs = this.getTabs();
    return (
      <div className='showtime-tabs-underline'>
        <div className='showtime-tabs-underline-headers'>
          { tabs.map(tab => tab.TabHeader) }
        </div>
        <div className='showtime-tabs-underline-contents'>
          { tabs.map(tab => tab.TabContent) }
        </div>
      </div>
    );
  }
}

TabPanel.propTypes = {
  selected: PropTypes.number,
  onSelect: PropTypes.func,
  children: PropTypes.array.isRequired,
};

TabPanel.defaultProps = {
  selected: 0,
  onSelect: () => {},
};

export default TabPanel;
