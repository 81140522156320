import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { EditWidgetActions } from '../../actions';
import { FILE, OPTIONS } from '../../data-capture/v2/constants';

import CheckBox from '../../../common/forms/CheckBox';
import MandatoryWarningInput from './MandatoryWarningInput';

const {
  clearColumnScores,
  setDefaultColumnScores,
  setWidgetPublicSettings,
} = EditWidgetActions;

export const SettingsCheckboxWrapper = ({ dataHint, dataTest, children }) => (
  <div
    className='showtime-record-log-builder-field__settings-item hint--info hint--medium hint--top'
    data-hint={ dataHint }
    data-test={ dataTest }
  >
    { children }
  </div>
);

SettingsCheckboxWrapper.defaultProps = {
  dataTest: null,
};
SettingsCheckboxWrapper.propTypes = {
  dataTest: PropTypes.string,
  dataHint: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const checkboxPropTypes = {
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const CopyValueToNewCheckbox = ({ onChecked, checked }) => {
  const { formatMessage } = useIntl();
  return (
    <SettingsCheckboxWrapper
      dataHint={ formatMessage({ id: 'editWidget.builder.copyValueToNewHint' }) }
      dataTest='widget.columns.copyValueToNewWrapper'
    >
      <CheckBox
        checked={ checked }
        checkboxClasses='showtime-checkbox--small'
        label={ formatMessage({ id: 'editWidget.builder.copyValueToNewLabel' }) }
        dataTest='widget.columns.copyValueToNew'
        onChange={ () => onChecked('copyValueToNew', !checked) }
      />
    </SettingsCheckboxWrapper>
  );
};
CopyValueToNewCheckbox.propTypes = checkboxPropTypes;

const RestrictedCheckbox = ({
  onChecked, checked,
}) => {
  const { formatMessage } = useIntl();
  return (
    <SettingsCheckboxWrapper
      dataHint={ formatMessage({ id: 'editWidget.builder.restrictedHint' }) }
      dataTest='widget.columns.restrictedWrapper'
    >
      <CheckBox
        checked={ checked }
        checkboxClasses='showtime-checkbox--small'
        label={ formatMessage({ id: 'editWidget.builder.restrictedLabel' }) }
        dataTest='widget.columns.restricted'
        onChange={ () => onChecked('columnRestricted', !checked) }
      />
    </SettingsCheckboxWrapper>
  );
};
RestrictedCheckbox.propTypes = checkboxPropTypes;

export const EditMessageLink = ({ onClick, children, dataTest }) => (
  <a
    onClick={ onClick }
    data-test={ dataTest }
    className='showtime-link showtime-link--highlight showtime-link--small'
  >
    { children }
  </a>
);
EditMessageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
};

EditMessageLink.defaultProps = {
  dataTest: undefined,
};

const MandatoryCheckbox = ({
  checked, onChecked, handleShowMandatoryWarningTextInput, mandatoryWarningText,
}) => {
  const { formatMessage } = useIntl();
  return (
    <SettingsCheckboxWrapper
      dataHint={ formatMessage({ id: 'editWidget.builder.mandatoryHint' }) }
    >
      <CheckBox
        checked={ checked }
        checkboxClasses='showtime-checkbox--small'
        label={ formatMessage({ id: 'editWidget.builder.mandatoryLabel' }) }
        dataTest='widget.columns.mandatory'
        onChange={ () => onChecked('mandatory', !checked) }
        data-test='widget.columns.mandatoryWrapper'
      />
      { checked && !mandatoryWarningText && (
        <EditMessageLink onClick={ handleShowMandatoryWarningTextInput }>
          { formatMessage({ id: 'editWidget.builder.editMandatoryMessage' }) }
        </EditMessageLink>
      )}
    </SettingsCheckboxWrapper>
  );
};
MandatoryCheckbox.propTypes = {
  ...checkboxPropTypes,
  handleShowMandatoryWarningTextInput: PropTypes.func.isRequired,
  mandatoryWarningText: PropTypes.string.isRequired,
};

const ScoresCheckbox = ({ checked, onChecked }) => {
  const { formatMessage } = useIntl();
  return (
    <SettingsCheckboxWrapper dataHint={ formatMessage({ id: 'editWidget.builder.scoresHint' }) }>
      <CheckBox
        checked={ checked }
        checkboxClasses='showtime-checkbox--small'
        label={ formatMessage({ id: 'editWidget.builder.scoresLabel' }) }
        dataTest='scores.checkbox'
        onChange={ () => onChecked(!checked) }
      />
    </SettingsCheckboxWrapper>
  );
};
ScoresCheckbox.propTypes = checkboxPropTypes;

const EditableColumnSettings = ({
  column, columnIndex,
}) => {
  const {
    columnRestricted,
    mandatory,
    mandatoryWarningText = false,
    copyValueToNew,
  } = _.get(column, 'cellSettings', {});
  const columnPath = `columns[${columnIndex}]`;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onChecked = (key, value) => dispatch(
    setWidgetPublicSettings(`${columnPath}.cellSettings.${key}`, value)
  );

  const onScoresChecked = isChecked => isChecked
    ? dispatch(setDefaultColumnScores(columnIndex))
    : dispatch(clearColumnScores(columnIndex));

  const [
    showMandatoryWarningTextInput, setShowMandatoryWarningTextInput,
  ] = useState(mandatoryWarningText);

  const handleShowMandatoryWarningTextInput = () => {
    dispatch(setWidgetPublicSettings(
      `columns[${columnIndex}].cellSettings.mandatoryWarningText`,
      formatMessage({ id: 'trail.widgets.data_capture.errors.blank' })
    ));
    setShowMandatoryWarningTextInput(true);
  };

  const columnIsOption = column.cellType === OPTIONS;

  return (
    <div className='showtime-record-log-builder-field__settings'>
      <MandatoryCheckbox
        checked={ mandatory }
        onChecked={ onChecked }
        handleShowMandatoryWarningTextInput={ handleShowMandatoryWarningTextInput }
        mandatoryWarningText={ mandatoryWarningText }
      />
      <RestrictedCheckbox checked={ columnRestricted } onChecked={ onChecked } />
      { column.cellType !== FILE && (
        <CopyValueToNewCheckbox checked={ copyValueToNew } onChecked={ onChecked } />
      ) }
      { columnIsOption && (
        <ScoresCheckbox
          checked={ !!_.get(column, 'cellSettings.scores.length') }
          onChecked={ onScoresChecked }
        />
      )}
      { showMandatoryWarningTextInput && (
        <MandatoryWarningInput
          columnIndex={ columnIndex }
          onDelete={ () => setShowMandatoryWarningTextInput(false) }
        />
      )}
    </div>
  );
};

EditableColumnSettings.propTypes = {
  column: PropTypes.object.isRequired,
  columnIndex: PropTypes.number.isRequired,
};

export default EditableColumnSettings;
