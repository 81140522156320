import _ from 'lodash';

import { RequestConstants } from '../../request';
import {
  CURRENT_USER_LOCATIONS_FETCH_KEY,
  ASSIGN_ORG_TO_USER,
  UNASSIGN_ORG_FROM_USER,
  ASSIGN_GROUP_TO_USER,
  UNASSIGN_GROUP_FROM_USER,
  ASSIGN_LOCATION_TO_USER,
  UNASSIGN_LOCATION_FROM_USER,
  SET_INITIAL_USER_ASSIGNMENTS,
  SET_USER_ROLE,
  SET_USER_SITE_SEARCH,
} from '../constants';

import { UserRole } from '../../application/UserRole';

const initialAssigned = {
  organizations: [],
  groups: [],
  locations: [],
};

export const initialStore = {
  organizations: {},
  locations: {},
  groups: {},
  assigned: initialAssigned,
  optionsHaveFetched: false,
  search: '',
};

const currentUserLocationsLoading = RequestConstants
  .getLoadingActionType(CURRENT_USER_LOCATIONS_FETCH_KEY);

const currentUserLocationsLoaded = RequestConstants
  .getLoadedActionType(CURRENT_USER_LOCATIONS_FETCH_KEY);

const actionCases = {
  [SET_INITIAL_USER_ASSIGNMENTS]: (store, { assignments }) => ({
    ...store,
    assigned: { // we initially don't know if existing assignments are groups or locations
      ...store.assigned,
      initial: assignments,
    },
  }),
  [currentUserLocationsLoaded]: (store, { content }) => {
    const isIncludedIn = (items) => {
      const timelineTemplateIds = items.map(item => item.timelineTemplateId);
      return assignmentId => timelineTemplateIds.includes(assignmentId);
    };

    const groups = content.groups.map(group => ({ ...group, timelineTemplateId: group.id }));
    const { assigned: { initial: initialAssignments = [] } = {} } = store;
    const assigned = {
      organizations: initialAssignments.filter(isIncludedIn(content.organizations)),
      groups: initialAssignments.filter(isIncludedIn(groups)),
      locations: initialAssignments.filter(isIncludedIn(content.locations)),
    };

    return {
      ...store,
      assigned,
      organizations: _.keyBy(content.organizations, 'id'),
      locations: _.keyBy(content.locations, 'id'),
      groups: _.keyBy(groups, 'id'),
      optionsHaveFetched: true,
      optionsAreFetching: false,
    };
  },
  [currentUserLocationsLoading]: store => ({
    ...store,
    optionsAreFetching: true,
  }),
  [SET_USER_ROLE]: (store, { value }) => {
    const userRole = new UserRole(value);
    if (userRole.isEnduser()) {
      return {
        ...store,
        assigned: {
          organizations: [],
          groups: [],
          locations: store.assigned.locations[0] ? [store.assigned.locations[0]] : [],
        },
      };
    }

    return store;
  },
  [SET_USER_SITE_SEARCH]: (store, action) => ({
    ...store,
    search: action.value,
  }),
  [ASSIGN_ORG_TO_USER]: (store, action) => {
    const { timelineTemplateId: orgTimelineTemplateId } = action;

    return ({
      ...store,
      search: '',
      assigned: {
        organizations: [...store.assigned.organizations, orgTimelineTemplateId],
        groups: [],
        locations: [],
      },
    });
  },
  [UNASSIGN_ORG_FROM_USER]: (store, { timelineTemplateId }) => ({
    ...store,
    search: '',
    assigned: {
      ...store.assigned,
      organizations: store.assigned.organizations.filter(o => o !== timelineTemplateId),
    },
  }),
  [ASSIGN_GROUP_TO_USER]: (store, action) => {
    const { timelineTemplateId: groupTimelineTemplateId, id: groupId } = action;

    const group = store.groups[groupId];
    const groupLocationsTimelineTemplateIds = group.locations.map((locationId) => {
      const location = store.locations[locationId];
      return location.timelineTemplateId;
    });

    const isNotInGroup = locationTimelineTemplateId => !groupLocationsTimelineTemplateIds
      .includes(locationTimelineTemplateId);

    return ({
      ...store,
      search: '',
      assigned: {
        ...store.assigned,
        groups: [...store.assigned.groups, groupTimelineTemplateId],
        locations: store.assigned.locations.filter(isNotInGroup),
      },
    });
  },
  [UNASSIGN_GROUP_FROM_USER]: (store, { timelineTemplateId }) => ({
    ...store,
    search: '',
    assigned: {
      ...store.assigned,
      groups: store.assigned.groups.filter(g => g !== timelineTemplateId),
    },
  }),
  [ASSIGN_LOCATION_TO_USER]: (store, { timelineTemplateId, userRole }) => {
    const assignedLocations = new UserRole(userRole).isEnduser()
      ? [timelineTemplateId]
      : [...store.assigned.locations, timelineTemplateId];
    return {
      ...store,
      search: '',
      assigned: {
        ...store.assigned,
        locations: assignedLocations,
      },
    };
  },
  [UNASSIGN_LOCATION_FROM_USER]: (store, { timelineTemplateId }) => ({
    ...store,
    search: '',
    assigned: {
      ...store.assigned,
      locations: store.assigned.locations.filter(l => l !== timelineTemplateId),
    },
  }),
};

export const siteAccess = (store, action) => {
  store = store || initialStore;
  const caseMethod = actionCases[action.type];
  return caseMethod ? caseMethod(store, action) : store;
};
