import {
  isEmpty, size,
  times,
  uniqueId,
} from 'lodash';
import { VALIDATE_PROMO_CODE } from '../../billing/constants';
import { RequestConstants } from '../../request';
import {
  ADD_SITE_NAME_ACTION,
  EMAIL,
  FIELD_CHANGE_ACTION,
  FORM_SUBMITTED_ACTION,
  MANDATORY_FIELDS,
  OPTIONAL_FIELDS,
  REMOVE_SITE_NAME_ACTION,
  SIGN_UP_KEY,
  SITE_NAME_CHANGE_ACTION,
  SITE_NAME_PREFIX,
  SUBMIT_ERROR_ACTION,
  TOTAL_SITES,
} from '../constants';
import { getSignInValue, getSignUp } from '../cookie-utils';

const getInitialForm = () => {
  const form = [...MANDATORY_FIELDS, ...OPTIONAL_FIELDS]
    .reduce((accu, key) => {
      const value = getSignInValue(key, '');
      const isMandatory = MANDATORY_FIELDS.includes(key);
      const valid = !isMandatory || (isMandatory && value !== '');
      return { ...accu, [key]: { value, valid } };
    }, {});

  if (window.current_user && !isEmpty(window.current_user)) {
    form[EMAIL] = { value: window.current_user.email, valid: true };
  } else if (window.referral_email) {
    form[EMAIL] = { value: window.referral_email, valid: true };
  }

  return form;
};

const getInitialSiteNames = (form) => {
  const totalSites = Number(form[TOTAL_SITES].value);
  return times(totalSites, () => uniqueId(SITE_NAME_PREFIX)).reduce((acc, id) => ({ ...acc, [id]: '' }), {});
};

const getInitialState = () => {
  const form = getInitialForm();

  const { coupon, promo } = getSignUp();

  return {
    form,
    formError: false,
    submitError: false,
    loading: false,
    promo,
    coupon,
    promoError: undefined,
    promoLoading: false,
    promoFetchErrored: false,
    siteNames: getInitialSiteNames(form),
  };
};

const signUpError = RequestConstants.getErrorActionType(SIGN_UP_KEY);
const signUpLoading = RequestConstants.getLoadingActionType(SIGN_UP_KEY);

const promoValidationError = RequestConstants.getErrorActionType(VALIDATE_PROMO_CODE);
const promoValidationLoaded = RequestConstants.getLoadedActionType(VALIDATE_PROMO_CODE);
const promoValidationLoading = RequestConstants.getLoadingActionType(VALIDATE_PROMO_CODE);

const actionCases = {
  [FIELD_CHANGE_ACTION]: (state, { content }) => {
    const { name, value, valid } = content;

    let { siteNames } = state;
    if (name === TOTAL_SITES) {
      const totalSites = Number(value);
      siteNames = times(totalSites, () => uniqueId(SITE_NAME_PREFIX))
        .reduce((acc, id) => ({ ...acc, [id]: '' }), {});
    }

    return {
      ...state,
      form: { ...state.form, [name]: { value, valid } },
      siteNames,
      formError: false,
      submitError: false,
      loading: false,
    };
  },
  [SITE_NAME_CHANGE_ACTION]: (state, { content: { id, value } }) => {
    const { siteNames } = state;
    return {
      ...state,
      siteNames: {
        ...siteNames,
        [id]: value,
      },
    };
  },
  [REMOVE_SITE_NAME_ACTION]: (state, { content: { id } }) => {
    const { form } = state;
    const { [id]: removedSite, ...updatedSiteNames } = state.siteNames;
    let siteNames = updatedSiteNames;
    if (isEmpty(updatedSiteNames)) siteNames = { [uniqueId(SITE_NAME_PREFIX)]: '' };

    return {
      ...state,
      form: { ...form, totalSites: { valid: true, value: size(siteNames) } },
      siteNames,
    };
  },
  [ADD_SITE_NAME_ACTION]: (state) => {
    const { form } = state;
    const { siteNames: currenSiteNames } = state;
    const siteNames = {
      ...currenSiteNames,
      [uniqueId(SITE_NAME_PREFIX)]: '',
    };
    return {
      ...state,
      siteNames,
      form: { ...form, totalSites: { valid: true, value: size(siteNames) } },
    };
  },
  [SUBMIT_ERROR_ACTION]: state => ({ ...state, formError: true }),
  [signUpError]: state => ({
    ...state,
    formError: true,
    submitError: true,
    loading: false,
  }),
  [signUpLoading]: state => ({ ...state, loading: true }),
  [promoValidationLoaded]: (state, { content }) => {
    if (content.error) {
      return {
        ...state,
        promoError: content.error,
        promoLoading: false,
        promoFetchErrored: false,
      };
    }
    return {
      ...state,
      ...content,
      promoError: undefined,
      promoLoading: false,
      promoFetchErrored: false,
    };
  },
  [promoValidationLoading]: state => ({ ...state, promoLoading: true, promoFetchErrored: false }),
  [promoValidationError]: state => ({ ...state, promoLoading: false, promoFetchErrored: true }),
  [FORM_SUBMITTED_ACTION]: state => ({ ...state, formSubmitted: true }),
};

export const signUp = (state, action) => {
  const actionCase = actionCases[action.type];
  if (actionCase) return actionCase(state, action);
  return state || getInitialState();
};
