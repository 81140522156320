import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import {
  mapFieldNameToColumn, getErrorSvg, formatFieldValue, colorForFieldError,
} from './utils.jsx';
import PDFTaskContentComments from './PDFTaskContentComments';

const PDFWidgetList = ({
  intl, widget, errors, timeZone, comments,
}) => {
  const fieldNameToColumn = mapFieldNameToColumn(widget);
  const fieldNames = Object.keys(fieldNameToColumn);
  const row = _.get(widget, 'custom_fields.dataCaptureRows[0]', {});

  if (_.isEmpty(row) && _.isEmpty(comments)) {
    return null;
  }

  return (
    <View
      is='div'
      style={
        {
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 10,
        }
      }
    >
      {
        fieldNames.map(
          (field) => {
            const fieldColor = colorForFieldError(errors, 0, field);

            return (
              <View is='div' style={ { marginBottom: 10 } }>
                <View is='div' wrap={ false }>
                  <Text
                    is='div'
                    style={ {
                      fontWeight: 600,
                      marginBottom: 10,
                      borderBottom: '1px solid #f6f6f6',
                      textTransform: 'uppercase',
                      color: '#92929b',
                      fontSize: 10,
                    } }
                  >
                    { fieldNameToColumn[field].columnTitle || intl.formatMessage({ id: 'trail.widgets.data_capture.last_updated' }) }
                  </Text>
                  <View
                    is='div'
                    style={ {
                      display: 'flex',
                      flexDirection: 'row',
                      color: fieldColor,
                    } }
                  >
                    {
                      _.get(errors, `[0][${field}]`, []).length > 0 &&
                        getErrorSvg(errors[0][field][0].type, fieldColor)
                    }
                    <Text is='div'>
                      {
                        formatFieldValue(
                          row[field], fieldNameToColumn[field], field, intl, timeZone) ||
                          intl.formatMessage({ id: 'taskReports.export.PDF.widget.emptyField' })
                      }
                    </Text>
                  </View>
                </View>
                <PDFTaskContentComments
                  { ...{ intl, timeZone } }
                  comments={
                    _.filter(
                      comments,
                      { linking_metadata: { widgetRowId: 0, widgetFieldName: field } }
                    )
}
                />
              </View>
            );
          }
        )
      }
    </View>
  );
};

PDFWidgetList.propTypes = {
  widget: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  comments: PropTypes.array,
  timeZone: PropTypes.string.isRequired,
};

PDFWidgetList.defaultProps = {
  comments: [],
};

export default PDFWidgetList;
