/* eslint-disable no-console */
import { AddTaskToTrailConstants } from '../constants';
import reduxStore from '../../store';
import AppDispatcher from '../../application/dispatcher';
import FlashActions from '../../flash/actions';
import FetchHelper from '../../utils/fetch';
import WindowHelper from '../../utils/window';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

const {
  ON_DEMAND_TASK_TEMPLATES_ROUTE: onDemandTaskTemplatesRoute,
  CREATE_ON_DEMAND_TASK_ROUTE: createOnDemandTaskRoute,
  TRAIL_ROUTE: trailRoute,
} = AddTaskToTrailConstants;

const AddTaskToTrailActions = {
  fetchTaskTemplates(locationId) {
    if (locationId) {
      const url = onDemandTaskTemplatesRoute(locationId);

      return FetchHelper.get(
        url,
        this._onFetchTaskTemplatesSuccess,
        this._onFetchTaskTemplatesError
      );
    }
    return this._onFetchTaskTemplatesError();
  },

  _onFetchTaskTemplatesError(error) {
    if (error) {
      console.warn(error);
    }

    FlashActions.alert('addTaskToTrail.errors.onDemandTaskTemplatesUnavailable', reduxStore);
  },

  _onFetchTaskTemplatesSuccess(response) {
    AppDispatcher.dispatch({
      actionType: AddTaskToTrailConstants.TASK_TEMPLATES_FETCHED,
      taskTemplates: response.body.on_demand_task_templates,
    });
  },

  addTaskToTrail(locationId, taskTemplateId) {
    if (locationId && taskTemplateId) {
      const success = this._onAddTaskToTrailSuccess.bind(this, taskTemplateId, locationId);
      const failure = this._onAddTaskToTrailFailure.bind(this);
      const url = createOnDemandTaskRoute(locationId, taskTemplateId);

      AppDispatcher.dispatch({
        actionType: AddTaskToTrailConstants.ADD_TASK_STARTED,
        taskTemplateId,
      });

      FetchHelper.post(url, success, failure);
    } else {
      this._showAddTaskToTrailError();
    }
  },

  _onAddTaskToTrailSuccess(taskTemplateId, locationId) {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.Trail.TASK_ADDED,
      { taskTemplateId, locationId }
    );

    this.navigateToTrail(locationId);
  },

  _onAddTaskToTrailFailure(error) {
    AppDispatcher.dispatch({
      actionType: AddTaskToTrailConstants.ADD_TASK_FAILED,
    });

    this._showAddTaskToTrailError(error);
  },

  _showAddTaskToTrailError(error) {
    if (error) {
      console.warn(error);
    }

    FlashActions.alert('addTaskToTrail.errors.createOnDemandTaskError', reduxStore);
  },

  navigateToTrail(locationId) {
    if (locationId) {
      WindowHelper.navigateTo(trailRoute(locationId));
    }
  },
};

export { AddTaskToTrailActions };
