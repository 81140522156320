import FormWidget from './form';
import MysteryDinerWidget from './mystery-diner';
import widgetSelectorHelper from './widgetSelectorHelper';
import DataCaptureWidget from './data-capture/v2';

const WidgetRegistry = {
  componentByType: {
    'DataCapture.*WidgetData': DataCaptureWidget,
    'Form.*WidgetData': FormWidget,
    'MysteryDiner.*WidgetData': MysteryDinerWidget,
  },
  widgetTypesReadyForPreview: ['DataCapture.*WidgetData'],
};

WidgetRegistry.getComponentForType = type => widgetSelectorHelper(
  WidgetRegistry.componentByType, type
);

WidgetRegistry.widgetReadyForPreview = (type) => {
  const matchingTypesReadyForPreview =
    WidgetRegistry.widgetTypesReadyForPreview
      .filter(keyRegExp => new RegExp(keyRegExp).test(type));

  return matchingTypesReadyForPreview.length === 1;
};

export { WidgetRegistry };
