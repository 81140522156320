import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SelectField from '../../../../common/forms/SelectField';
import ButtonSelectField from '../../../../common/forms/ButtonSelectField';
import { optionsDisplay } from '../../../constants';
import CorrectiveActionButton from './CorrectiveActionButton';

const { BUTTONS, DROPDOWN } = optionsDisplay;

export const Options = ({
  columnTitle,
  disabled,
  display,
  errorText,
  errorType,
  focusInput,
  id,
  labels = [],
  onEdit,
  rowId,
  copyValueToNew,
  fieldName,
  mandatory,
  value,
  values = [],
  requireOptionAction = [],
  onCorrectiveActionClick,
  hasAction,
  hasCompletedAction,
  readOnly,
  children,
}) => {
  const handleInputChange = fieldValue => onEdit({
    rowId,
    fieldName,
    fieldValue,
  });

  const options = labels.map((label, index) => ({
    label,
    value: values[index],
  }));
  const showCorrectiveActionButton = requireOptionAction[values.indexOf(value)] || false;

  const errors = errorText ? [{ message: errorText, type: errorType }] : [];

  const isInvalid = errors.length > 0;

  const collapsed = (disabled && !isInvalid) || (copyValueToNew && !isInvalid);

  const Field = (display === BUTTONS) ? ButtonSelectField : SelectField;

  return (
    <Field
      collapsed={ collapsed }
      containerClass='showtime-widget-v2__list-field'
      disabled={ disabled }
      readOnly={ readOnly }
      locked={ disabled }
      errors={ errors }
      id={ id }
      includeEmptyOption
      label={ columnTitle }
      name={ fieldName }
      onChange={ handleInputChange }
      options={ options }
      refFn={ focusInput }
      repeating={ copyValueToNew }
      required={ mandatory }
      value={ value }
      inputChildren={ children }
    >
      { showCorrectiveActionButton && !readOnly && (
        <CorrectiveActionButton
          onClick={ onCorrectiveActionClick }
          hasAction={ hasAction }
          hasCompletedAction={ hasCompletedAction }
          fieldName={ fieldName }
          rowId={ rowId }
        />
      ) }
    </Field>
  );
};

Options.propTypes = {
  columnTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  errorText: PropTypes.string,
  errorType: PropTypes.string,
  focusInput: PropTypes.func,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.any,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEdit: PropTypes.func,
  rowId: PropTypes.number,
  widgetId: PropTypes.number,
  copyValueToNew: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  requireOptionAction: PropTypes.arrayOf(PropTypes.bool),
  onCorrectiveActionClick: PropTypes.func,
  hasAction: PropTypes.bool.isRequired,
  hasCompletedAction: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

Options.defaultProps = {
  copyValueToNew: false,
  disabled: false,
  display: DROPDOWN,
  errorText: null,
  errorType: null,
  focusInput: _.noop,
  widgetId: undefined,
  initialValue: '',
  mandatory: false,
  onEdit: _.noop,
  rowId: null,
  value: undefined,
  requireOptionAction: [],
  onCorrectiveActionClick() {},
  children: null,
};

export default Options;
