import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { CommentBoxActions } from './actions';
import { ConfirmationMenu } from '../common/menu/ConfirmationMenu';
import { taskMessageIsDeleting } from './selectors';

const DeleteConfirmationMenu = ({
  onDelete,
  formatMessage,
  hideMenu,
  menuShown,
  menuId,
}) => (
  <ConfirmationMenu
    align='left'
    title={ formatMessage({ id: `${menuId}.title` }) }
    abort={ formatMessage({ id: `${menuId}.cancel` }) }
    confirm={ formatMessage({ id: `${menuId}.confirm` }) }
    description={ formatMessage({ id: `${menuId}.description` }) }
    onConfirm={ onDelete }
    onClickOutside={ hideMenu }
    onAbort={ hideMenu }
    showMenu={ menuShown }
    isDestructive
  />
);

DeleteConfirmationMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
  menuShown: PropTypes.bool.isRequired,
  menuId: PropTypes.string.isRequired,
};

const DeletingMessage = ({ formatMessage }) => (
  <span className='showtime-utility-font-body-small'>
    {formatMessage({ id: 'conversation.comment.deleting' })}
  </span>
);

DeletingMessage.propTypes = {
  formatMessage: PropTypes.func.isRequired,
};

const DeleteOption = ({
  formatMessage,
  disabled,
  showMenu,
}) => {
  const className = classnames(
    'showtime-link showtime-link--underline showtime-link--small', {
      'hint--blocked hint--medium hint--top-left': disabled,
    }
  );
  return (
    <a
      data-hint={ formatMessage({ id: 'conversation.comment.options.delete.disabled' }, { disabled }) }
      data-test='messageOption.delete'
      className={ className }
      onClick={ showMenu }
    >
      {formatMessage({ id: 'conversation.comment.options.delete.label' })}
    </a>
  );
};

DeleteOption.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showMenu: PropTypes.func.isRequired,
};

export const MessageOptions = ({
  intl: { formatMessage },
  body,
  requestDeleteMessage,
  messageId,
  taskId,
  isDeleting,
  disabled,
  onDelete,
}) => {
  const [menuShown, setShowMenu] = useState(false);
  const showMenu = () => {
    if (!disabled) setShowMenu(true);
  };
  const hideMenu = () => { setShowMenu(false); };

  return isDeleting
    ? (<DeletingMessage formatMessage={ formatMessage } />)
    : (
      <div
        className='showtime-conversation__message-options'
        data-test='messageOption'
      >
        <DeleteOption
          formatMessage={ formatMessage }
          disabled={ disabled }
          showMenu={ showMenu }
        />
        <DeleteConfirmationMenu
          onDelete={ () => {
            hideMenu();
            requestDeleteMessage({ taskId, messageId });
            onDelete();
          } }
          formatMessage={ formatMessage }
          hideMenu={ hideMenu }
          menuShown={ menuShown }
          menuId={
            body
              ? 'conversation.comment.options.delete.confirmation.message'
              : 'conversation.comment.options.delete.confirmation.file'
          }
        />
      </div>
    );
};

MessageOptions.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  body: PropTypes.string,
  messageId: PropTypes.string,
  taskId: PropTypes.string.isRequired,
  requestDeleteMessage: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

MessageOptions.defaultProps = {
  messageId: null,
  body: '',
  onDelete: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  isDeleting: taskMessageIsDeleting(state, ownProps),
});

const mapDispatchToProps = dispatch => bindActionCreators(CommentBoxActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MessageOptions));
