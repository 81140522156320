import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { DataCaptureScoreSelectors } from '../../selectors';

const RecordLogScore = ({
  score, scoreOutOf, className, intl: { formatMessage }, paddingTop,
}) => !!scoreOutOf && (
  <div
    className={
      classNames(
        className,
        'showtime-record-log-score showtime-record-log-score--overall',
        { 'showtime-record-log-score--padding-top': paddingTop }
      )
    }
    data-test='score.rows'
  >
    <div>
      { formatMessage({ id: 'trail.widgets.data_capture.overallScore' }, {
        score,
        scoreOutOf,
      })}
    </div>
    <div className='showtime-record-log-score__percent'>
      {formatMessage({ id: 'trail.widgets.data_capture.scorePercent' }, {
        percentRatio: score / scoreOutOf,
      })}
    </div>
  </div>
);

RecordLogScore.propTypes = {
  score: PropTypes.number.isRequired,
  scoreOutOf: PropTypes.number.isRequired,
  className: PropTypes.string,
  paddingTop: PropTypes.bool,
};

RecordLogScore.defaultProps = {
  className: '',
  paddingTop: false,
};

export default compose(
  connect(DataCaptureScoreSelectors.rowsScoreSelector),
  injectIntl
)(RecordLogScore);
