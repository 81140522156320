const templateType = {
  ACTION: 'action',
  ACTION_TEMPLATE: 'action_template',
  AUTOMATED: 'automated',
  ON_DEMAND: 'on_demand',
  SINGLE: 'single',
  RECURRING: 'recurring',
};

export const TaskTemplateConstants = {
  templateType,
  templatesWithoutSchedule: [
    templateType.ACTION_TEMPLATE,
    templateType.AUTOMATED,
    templateType.ON_DEMAND,
  ],
  actionTypes: [
    templateType.ACTION_TEMPLATE,
    templateType.ACTION,
  ],
  status: {
    DRAFT: 'draft',
    ARCHIVED: 'archived',
    PUBLISHED: 'published',
  },
  CHANGE_TEMPLATE_TYPE: 'task.change_template_type',
  TOGGLE_LOCK_TASK: 'task.toggle_lock_task',
  COPYING: true,
};
