import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import contentReducer from './contentReducer';
import isShownReducer from './isShownReducer';

const reducers = combineReducers({
  content: contentReducer,
  isShown: isShownReducer,
});

const modalAlertStore = createStore(
  reducers,
  {},
  composeWithDevTools()
);

export default modalAlertStore;
