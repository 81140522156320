import _ from 'lodash';
import { RequestConstants } from '../../request';
import { requestKey } from '../constants';

const onReadPolicyLoading = (state, action) => {
  state = state || {};
  const handleLoadingPairs = action.content.handles.map(handle => (
    [handle, { isLoading: true, handle }]
  ));
  return {
    ...state,
    read: {
      ...state.read,
      ..._.fromPairs(handleLoadingPairs),
    },
  };
};

export const securitiesReducer = (state, action) => {
  state = state || {};
  switch (action.type) {
    case RequestConstants.getLoadedActionType(requestKey.pick):
      return { ...state, pick: action.content.data };
    case RequestConstants.getLoadingActionType(requestKey.read):
      return onReadPolicyLoading(state, action);
    case RequestConstants.getLoadedActionType(requestKey.read):
      return {
        ...state,
        read: { ...state.read, ...action.content.data.handles },
      };
    default:
      return state;
  }
};
