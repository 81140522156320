import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const CustomDateInput = ({
  label, classNames, iconName, dataLabel, onClick,
}) => classNames &&
  (
    <div data-datepicker-button={ dataLabel }>
      <Button
        onClick={
          (e) => {
            e.preventDefault();
            onClick();
          }
        }
        label={ label }
        buttonClasses={ classNames }
        iconName={ iconName }
      />
    </div>
  );

CustomDateInput.propTypes = {
  label: PropTypes.string,
  classNames: PropTypes.string,
  iconName: PropTypes.string,
  dataLabel: PropTypes.string,
  onClick: PropTypes.func,
};

CustomDateInput.defaultProps = {
  label: null,
  classNames: null,
  iconName: null,
  dataLabel: null,
  onClick: null,
};

export default CustomDateInput;
