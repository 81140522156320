import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import CheckBox from '../../../common/forms/CheckBox';
import SettingsMessageField from './SettingsMessageField';
import { SettingsCheckboxWrapper, EditMessageLink } from './EditableColumnSettings';
import ActionTemplateSelection from './ActionTemplateSelection';

const AddExceptionMessageLink = ({ index, onWarningTextChange }) => {
  const { formatMessage } = useIntl();
  return (
    <EditMessageLink
      dataTest={ `add-options-warning-message-${index}` }
      onClick={ () => {
        const defaultException = formatMessage({ id: 'trail.widgets.data_capture.errors.option_exception' });
        onWarningTextChange(defaultException);
      } }
    >
      {formatMessage({ id: 'editWidget.builder.addOptionsWarningMessage' })}
    </EditMessageLink>
  );
};

AddExceptionMessageLink.propTypes = {
  index: PropTypes.number.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
};

const OptionException = ({
  index,
  hasException,
  warningText,
  requestAction,
  onExceptionChange,
  onRequestActionChange,
  onActionTemplateIdChange,
  actionTemplateId,
  onWarningTextChange,
  requireMandatoryAction,
  onRequireMandatoryActionChange,
}) => {
  const { formatMessage } = useIntl();
  const hasWarningText = typeof warningText === 'string';

  const {
    exceptionReporting, correctiveActions,
  } = window.config.features;

  return (
    <div className='showtime-record-log-builder-field__options_exception'>
      <div className='showtime-record-log-builder-field__options_exception-options'>
        { exceptionReporting && (
        <SettingsCheckboxWrapper
          dataHint={ formatMessage({ id: 'editWidget.builder.logExceptionHint' }) }
        >
          <CheckBox
            dataTest={ `log-exception-${index}` }
            checked={ hasException }
            disabled={ requestAction }
            onChange={ () => { onExceptionChange(!hasException); } }
            label={ formatMessage({ id: 'editWidget.builder.logExceptionCheckboxLabel' }) }
            small
          />
          {hasException && !hasWarningText && (
            <AddExceptionMessageLink
              index={ index }
              onWarningTextChange={ onWarningTextChange }
            />
          )}
        </SettingsCheckboxWrapper>
        ) }
        { correctiveActions && (
          <SettingsCheckboxWrapper
            dataHint={ formatMessage({ id: 'editWidget.builder.requireOptionActionHint' }) }
            dataTest='raise-action-checkbox-container'
          >
            <CheckBox
              checked={ requestAction }
              disabled={ requireMandatoryAction }
              dataTest='raise-action-checkbox'
              onChange={ onRequestActionChange }
              label={ formatMessage({ id: 'editWidget.builder.requireOptionActionCheckboxLabel' }) }
              small
            />
          </SettingsCheckboxWrapper>
        ) }
        { correctiveActions && (
          <SettingsCheckboxWrapper
            dataHint={ formatMessage({ id: 'editWidget.builder.requireMandatoryOptionActionHint' }) }
            dataTest='raise-mandatory-action-checkbox-container'
          >
            <CheckBox
              checked={ requireMandatoryAction }
              dataTest='raise-mandatory-action-checkbox'
              onChange={ onRequireMandatoryActionChange }
              label={ formatMessage({ id: 'editWidget.builder.requireMandatoryOptionActionCheckboxLabel' }) }
              small
            />
          </SettingsCheckboxWrapper>
        ) }
      </div>
      { exceptionReporting && hasWarningText && (
      <SettingsMessageField
        dataTest={ `options-message-field-${index}` }
        value={ warningText }
        onChange={ onWarningTextChange }
        onDelete={ () => { onWarningTextChange(false); } }
        label={ formatMessage({ id: 'editWidget.builder.optionsWarningMessageLabel' }) }
        inline
        placeholder={ formatMessage({ id: 'editWidget.builder.optionsWarningPlaceholder' }) }
      />
      )}
      { requestAction && (
        <ActionTemplateSelection
          onChange={ onActionTemplateIdChange }
          value={ actionTemplateId }
        />
      )}
    </div>
  );
};

OptionException.propTypes = {
  index: PropTypes.number.isRequired,
  hasException: PropTypes.bool.isRequired,
  warningText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  onExceptionChange: PropTypes.func.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
  onRequestActionChange: PropTypes.func.isRequired,
  onRequireMandatoryActionChange: PropTypes.func.isRequired,
  requestAction: PropTypes.bool.isRequired,
  requireMandatoryAction: PropTypes.bool.isRequired,
  columnPath: PropTypes.string.isRequired,
  onActionTemplateIdChange: PropTypes.func.isRequired,
  actionTemplateId: PropTypes.number,
};

OptionException.defaultProps = {
  actionTemplateId: undefined,
};

export default OptionException;
