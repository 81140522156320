const sections = {
  CONTENT: 'content',
  SCHEDULE: 'schedule',
  ASSIGN: 'assign',
  REPORT: 'report',
};

export const TaskWizardConstants = {
  CREATE_TASK_KEY: 'task_wizard.create_task',
  EDIT_TASK_KEY: 'task_wizard.edit_task',
  GET_TASK_KEY: 'task_wizard.get_task',
  BEGIN_SAVING: 'task_wizard.begin_saving',
  get_library_template_task_KEY: 'task_wizard.get_library_template_task',
  CREATE_TASK_ROUTE: '/api/v1/task_templates',
  getOrUpdateTaskRoute: taskTemplateId => `/api/v1/task_templates/${taskTemplateId}`,
  getLibraryTemplateTaskRoute: taskTemplateId => `/api/v1/task_templates/copy_for_new/${taskTemplateId}`,
  TASK_PLANNER_ROUTE: '/taskplanner',
  pages: {
    EDIT_TASK: 'new_task.edit_task',
    PLAN: 'new_task.plan',
  },
  loadTaskStatus: {
    NOT_LOADED: 'load_task_status.not_loaded',
    LOADED: 'load_task_status.loaded',
    FAILED: 'load_task_status.failed',
  },
  DEFAULT_PARAMS: {
    alert_minutes_before: null,
    due_by_datetime: null,
    integration: null,
    links: [],
    locations: [],
    reportable: true,
    task_files: [],
    recur_by: null,
    start_date: null,
    recurrence_unit: null,
    location_time_slots: [],
    time_slot_name: null,
    time_type: null,
  },
  EXTRA_PARAMS_FOR_RECURRING: {
    recur_by: 1,
    recurrence_unit: 'daily',
    time_slot_name: 'Morning',
    time_type: 'time_slot',
  },
  sections,
  SELECT_ACTIVE_SECTION: 'active_section.select',
  TAB_SEQUENCE: [
    sections.CONTENT,
    sections.SCHEDULE,
    sections.ASSIGN,
    sections.REPORT,
  ],
};
