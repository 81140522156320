import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '../Button';

const SelectableMenuItemButton = ({
  intlPath, intl: { formatMessage }, onClick, dataTest, selected,
}) => (
  <li
    className='showtime-menu__list-item showtime-menu__list-item--selectable'
    data-test={ `menuItem.${selected ? 'selected' : 'deselected'}` }
  >
    <Button
      { ...{
        dataTest,
        onClick,
        baseClass: '',
        className: classNames(
          'showtime-link',
          'showtime-link--menu-item',
          {
            'showtime-link--highlight': selected,
            'showtime-link--default': !selected,
          }
        ),
        ...selected && {
          iconName: 'mini/ui/ui-16px-1_check-CUSTOM',
          iconPosition: 'right',
        },
      } }
    >
      <span className='showtime-menu__list-item-text'>
        {formatMessage({ id: intlPath })}
      </span>
    </Button>
  </li>
);

SelectableMenuItemButton.propTypes = {
  intlPath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  dataTest: PropTypes.string,
  selected: PropTypes.bool,
};

SelectableMenuItemButton.defaultProps = {
  onClick() {},
  dataTest: null,
  selected: false,
};

export default injectIntl(SelectableMenuItemButton);
