import React from 'react';
import PropTypes from 'prop-types';
import { LockSvg } from './svg';

const PDFRequiredPinIcon = ({ requiresPin, isCompleted }) => {
  if (!window.config.features.taskPinSignOff) return null;
  if (!requiresPin || !isCompleted) return null;

  return (
    <LockSvg
      style={ {
        fill: '#646470',
        height: 10,
        marginLeft: 2,
      } }
    />
  );
};

PDFRequiredPinIcon.propTypes = {
  requiresPin: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default PDFRequiredPinIcon;
