import _ from 'lodash';
import AttachmentConstants from '../constants';
import { RequestConstants } from '../../request';
import { TaskWizardConstants } from '../../task-wizard/constants';
import { TaskConstants } from '../../task/constants';
import { loadAttachments } from '../../file/formatAttachmentsFromApi';

export const attachmentReducer = (function () {
  const getFilestackHandle = function (filestackUrl) {
    const parts = filestackUrl.split('/');

    return parts.pop();
  };

  const { State } = AttachmentConstants;

  function add(currentState) {
    const state = currentState.slice();

    const newAttachment = {
      state: State.PENDING_UPLOAD,
      type: AttachmentConstants.FILE_TYPE,
    };
    state.push(newAttachment);

    return state;
  }

  function deleteAttachment(currentState, action) {
    const state = currentState.slice();

    state.splice(action.attachmentIndex, 1);

    return state;
  }

  function select(currentState, action) {
    const state = currentState.slice();

    const attachment = state[action.attachmentIndex];

    attachment.previousName = attachment.name;
    attachment.state = State.SAVED;

    return state;
  }

  function changeName(currentState, action) {
    const state = currentState.slice();

    if (action.name && action.name !== '') {
      state[action.attachmentIndex].name = action.name;
    }

    return state;
  }

  function startUpload(currentState, action) {
    const state = currentState.slice();

    state[action.attachmentIndex].state = State.UPLOADING;

    return state;
  }

  function completeUpload(currentState, action) {
    const state = currentState.slice();
    const { attachmentIndex } = action;
    const attachment = _.clone(state[action.attachmentIndex]);

    state[attachmentIndex] = _.merge(attachment, {
      name: action.fileName,
      previousName: action.fileName,
      fileName: action.fileName,
      url: action.filestackUrl,
      filestackHandle: getFilestackHandle(action.filestackUrl),
      mimeType: action.mimeType,
      fileSize: action.fileSize,
      s3Key: action.s3Key,
      state: State.SAVED,
    });

    return state;
  }

  function formatUrl(url) {
    if (url.lastIndexOf('://') !== -1) {
      return url;
    }
    return `http://${url}`;
  }

  function addLink(currentState, action) {
    const state = currentState.slice();
    const attachment = {
      url: formatUrl(action.url),
      name: action.name || action.url,
      state: State.SAVED,
      type: AttachmentConstants.LINK_TYPE,
    };
    state[action.index] = attachment;
    return state;
  }

  function reset() {
    return [];
  }

  function reducer(currentState, action) {
    currentState = currentState || [];
    if (action.attachmentIndex >= currentState.length) {
      return currentState;
    }

    switch (action.type) {
      case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
        return loadAttachments(action);
      case TaskConstants.RESET:
        return reset();
      case AttachmentConstants.ADD:
        return add(currentState, action);
      case AttachmentConstants.DELETE:
      case AttachmentConstants.CANCEL_UPLOAD:
        return deleteAttachment(currentState, action);
      case AttachmentConstants.SELECT:
        return select(currentState, action);
      case AttachmentConstants.CHANGE_NAME:
        return changeName(currentState, action);
      case AttachmentConstants.START_UPLOAD:
        return startUpload(currentState, action);
      case AttachmentConstants.COMPLETE_UPLOAD:
        return completeUpload(currentState, action);
      case AttachmentConstants.ADD_LINK:
        return addLink(currentState, action);
      default:
        return currentState;
    }
  }

  return reducer;
}());
