import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TaskTemplateConstants } from '../task/constants';
import Callout from './Callout';

export const TaskTemplateStatus = ({ status, intl }) => {
  switch (status) {
    case TaskTemplateConstants.status.PUBLISHED:
      return (<Callout active>
        { intl.formatMessage({ id: 'taskTemplate.status.published' }) }
      </Callout>);
    case TaskTemplateConstants.status.DRAFT:
      return (<Callout inactive>
        { intl.formatMessage({ id: 'taskTemplate.status.draft' }) }
      </Callout>);
    case TaskTemplateConstants.status.ARCHIVED:
      return (<Callout warning>
        { intl.formatMessage({ id: 'taskTemplate.status.archived' }) }
      </Callout>);
    default:
      return null;
  }
};

TaskTemplateStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default injectIntl(TaskTemplateStatus);
