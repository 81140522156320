import _ from 'lodash';

import { RequestConstants } from '../../request';
import {
  GET_SUBSCRIPTION_KEY,
  VALIDATE_PROMO_CODE,
  CREATE_SUBSCRIPTION_KEY,
  CREATE_SUBSCRIPTION_STARTED,
  CREDIT_CARD_CREATED,
  CREDIT_CARD_CHANGED,
  PRICE_CHANGED,
  SHOW_PROMO_CODE_INPUT,
  STRIPE_BILLING_DETAILS_CHANGED,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_FAILED,
  CREATE_PAYMENT_METHOD_SUCCESS,
  PLAN_CHANGED,
  IS_EDITING_PAYMENT_METHOD_CHANGED,
  CURRENT_PAYMENT_METHOD_TYPE_CHANGED,
  UPDATE_SUBSCRIPTION_KEY,
  GET_NEXT_INVOICE_PREVIEW,
  NO_PAYMENT_METHOD,
} from '../constants';

export const INITIAL_STATE = {
  fetchingBillingData: true,
  subscriptionFetchError: false,
  plan: window.current_user.plan || 'Team',
  currentPriceId: null,
  isEditingPaymentMethod: false,
};

const subscriptionLoaded = RequestConstants.getLoadedActionType(GET_SUBSCRIPTION_KEY);
const getSubscriptionError = RequestConstants.getErrorActionType(GET_SUBSCRIPTION_KEY);
const promoValidationLoaded = RequestConstants.getLoadedActionType(VALIDATE_PROMO_CODE);
const createSubscriptionError = RequestConstants.getErrorActionType(CREATE_SUBSCRIPTION_KEY);
const updateSubscriptionError = RequestConstants.getErrorActionType(UPDATE_SUBSCRIPTION_KEY);
const nextInvoiceLoaded = RequestConstants.getLoadedActionType(GET_NEXT_INVOICE_PREVIEW);
const updateSubscriptionStarted = RequestConstants.getLoadingActionType(UPDATE_SUBSCRIPTION_KEY);

const setSubmitError = (state, action) => ({
  ...state,
  submitError: action.content.body.errors[0].message,
});

const setSubmitted = state => ({
  ...state,
  submitted: true,
});

const actionCases = {
  [CREDIT_CARD_CREATED]: (state, action) => ({
    ...state,
    card: action.card,
  }),
  [CURRENT_PAYMENT_METHOD_TYPE_CHANGED]: (state, action) => ({
    ...state,
    currentPaymentMethodType: action.value,
    complete: false,
  }),
  [IS_EDITING_PAYMENT_METHOD_CHANGED]: (state, action) => ({
    ...state,
    isEditingPaymentMethod: action.value,
    ...action.value && { complete: false },
  }),
  [CREDIT_CARD_CHANGED]: (state, action) => {
    if (action.error) {
      return { ...state, cardError: action.error.message };
    }

    return {
      ...state,
      submitError: null,
      submitted: false,
      cardError: '',
      complete: action.complete,
    };
  },
  [getSubscriptionError]: state => ({
    ...state,
    subscriptionFetchError: true,
    fetchingBillingData: false,
  }),
  [subscriptionLoaded]: (state, action) => {
    const currentPaymentMethodType = _.get(action, 'content.paymentMethod.type');
    const paymentMethod = _.get(action, 'content.paymentMethod');

    const { previousState = null, ...responseData } = action.content;

    const redirectingBackFromCheckout = responseData.stripeCheckoutStatus && !!previousState;
    const initial = redirectingBackFromCheckout ? previousState : { ...state, ...responseData };
    const complete = (paymentMethod && paymentMethod.id != null);

    const noPaymentMethod = currentPaymentMethodType === NO_PAYMENT_METHOD;
    const isEditingPaymentMethod = !redirectingBackFromCheckout && noPaymentMethod;

    return {
      ...initial,
      fetchingBillingData: false,
      complete,
      paymentMethod,
      isEditingPaymentMethod,
      currentPaymentMethodType,
    };
  },
  [nextInvoiceLoaded]: (state, action) => ({
    ...state,
    nextInvoice: { date: new Date().toDateString(), amountDue: action.content.amountDue },
  }),
  [PRICE_CHANGED]: (state, action) => ({
    ...state,
    currentPriceId: action.currentPriceId,
    submitError: null,
    submitted: false,
  }),
  [SHOW_PROMO_CODE_INPUT]: state => ({
    ...state,
    showPromoCodeInput: true,
    submitError: null,
    submitted: false,
  }),
  [promoValidationLoaded]: (state, action) => {
    const { coupon, promo, error } = action.content;
    if (error) {
      return { ...state, promoError: error };
    }

    return {
      ...state,
      coupon,
      promo,
      showPromoCodeInput: false,
      promoError: null,
      submitError: null,
      submitted: false,
    };
  },
  [STRIPE_BILLING_DETAILS_CHANGED]: (state, action) => ({
    ...state,
    stripeBillingDetails: {
      ...state.stripeBillingDetails,
      ...action.value,
    },
    submitError: null,
    submitted: false,
  }),
  [CREATE_PAYMENT_METHOD]: state => ({
    ...state,
    submitted: true,
    submitError: null,
  }),
  [CREATE_PAYMENT_METHOD_FAILED]: (state, action) => ({
    ...state,
    submitError: action.error,
  }),
  [CREATE_PAYMENT_METHOD_SUCCESS]: (state, action) => ({
    ...state,
    paymentMethod: action.paymentMethod,
  }),
  [createSubscriptionError]: setSubmitError,
  [updateSubscriptionError]: setSubmitError,
  [CREATE_SUBSCRIPTION_STARTED]: setSubmitted,
  [updateSubscriptionStarted]: setSubmitted,
  [PLAN_CHANGED]: (state, action) => ({
    ...state,
    plan: action.currentPlan.name,
    currentPriceId: action.currentPrice.id,
    currentProduct: action.currentPlan,
    currentPrices: action.currentPrices,
  }),
};

export const billingReducer = (state, action) => {
  state = state || INITIAL_STATE;
  const actionCase = actionCases[action.type];
  if (actionCase) return actionCase(state, action);

  return state;
};
