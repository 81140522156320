import _ from 'lodash';
import { REQUIRES_ACTION } from '../rules';
import { hasNumberRangeValidation } from './isWithinRange';

export default (ruleConfig, intl) => {
  const alreadyHasRule = ruleConfig.rules && _.find(ruleConfig.rules, { key: REQUIRES_ACTION });

  if (alreadyHasRule) return ruleConfig;

  if (!window.config.features.correctiveActions) return ruleConfig;

  const hasNoExceptionalOptions = !_.some(ruleConfig.optionsExceptions);
  if (!hasNumberRangeValidation(ruleConfig) && hasNoExceptionalOptions) return ruleConfig;

  const {
    maxValueWarningLimit,
    minValueWarningLimit,
  } = ruleConfig;

  return {
    ...ruleConfig,
    rules: [
      ...ruleConfig.rules || [],
      {
        key: REQUIRES_ACTION,
        message: intl.formatMessage({ id: 'trail.widgets.data_capture.errors.requires_action' }),
        options: {
          ...ruleConfig,
          ...hasNumberRangeValidation(ruleConfig)
            ? { range: [minValueWarningLimit, maxValueWarningLimit] }
            : {},
        },
      },
    ],
  };
};
