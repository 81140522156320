import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from '../../../../common/forms/Label';
import ValidationMessage from '../../../../common/forms/ValidationMessage';
import FilePicker from '../../../../file/FilePicker';
import AttachmentGrid from '../../../../common/AttachmentGrid';

const removeFile = (filestackHandle, files, rowId, fieldName, onEdit) => {
  const filteredFiles = files.filter(file => file.filestackHandle !== filestackHandle);

  onEdit({
    rowId,
    fieldName,
    fieldValue: filteredFiles,
  });
};

export const FileInput = ({
  columnTitle,
  onEdit,
  fieldName,
  rowId,
  value,
  mandatory,
  errorText,
  readOnly,
  router,
  routePrefix,
  children,
}) => (
  <div
    className={
      classnames('showtime-widget-v2__list-field', {
        'is-invalid': errorText,
      })
    }
  >
    <Label required={ mandatory }>{columnTitle}</Label>
    <div className='showtime-input--files'>
      <div>
        <AttachmentGrid
          routePrefix={ routePrefix }
          files={ value }
          isClickable
          router={ router }
          onDelete={ readOnly
            ? null
            : (
              filestackHandle => removeFile(filestackHandle, value, rowId, fieldName, onEdit)
            ) }
        />
        {!readOnly && (
          <FilePicker
            onUploadDone={
              ({ filesUploaded }) => {
                onEdit({
                  rowId,
                  fieldName,
                  fieldValue: (value || []).concat(filesUploaded),
                });
              }
            }
          />
        )}
      </div>
      { children }
    </div>
    { errorText && <ValidationMessage>{errorText}</ValidationMessage> }
  </div>
);

FileInput.propTypes = {
  columnTitle: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  rowId: PropTypes.number.isRequired,
  value: PropTypes.array,
  mandatory: PropTypes.bool,
  errorText: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
  children: PropTypes.node,
};

FileInput.defaultProps = {
  mandatory: false,
  errorText: '',
  value: [],
  routePrefix: '',
  children: null,
};

export default FileInput;
