import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { TaskReportsActions } from '../actions';
import { TaskReportsSelectors } from '../selectors';
import ExportOption from '../../common/ExportOption';
import { TrailMetricsDirectory } from '../../metrics';

const mapStateToProps = (state, ownProps) => ({
  noVisibleTasks: TaskReportsSelectors.noTasksLoadedSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetCSVExport: () => dispatch(TaskReportsActions.fetchCSVReport(ownProps.location)),
});

const CSVExportOption = ({
  intl: { formatMessage }, onSetCSVExport, onClick, noVisibleTasks,
}) => {
  const handleClick = () => {
    onSetCSVExport();
    onClick();
  };

  return (
    <ExportOption
      iconName='/custom/attachment-icons/file-csv'
      onClick={ noVisibleTasks ? null : handleClick }
      dataTest='share.csvExport'
      clickMetric={ TrailMetricsDirectory.page.TaskReports.CSV_DOWNLOAD_TRIGGERED }
    >
      <span
        className='hint--medium hint--right hint--right-center'
        data-hint={
          noVisibleTasks
            ? formatMessage({ id: 'taskReports.share.noTasksToExport' })
            : formatMessage({ id: 'taskReports.share.useFiltersCSV' })
        }
      >
        { formatMessage({ id: 'share.csvExport' }) }
      </span>
    </ExportOption>
  );
};

CSVExportOption.propTypes = {
  onSetCSVExport: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  noVisibleTasks: PropTypes.bool.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CSVExportOption);
