import React from 'react';
import PropTypes from 'prop-types';

const BlockTitle = ({ children }) => (
  <div className='showtime-record-log-builder-field--block-title'>{children}</div>);

BlockTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlockTitle;
