export class StatusLegacy {
  statusElement = () => document.getElementById('location_status');

  activeDateElement = () => document.getElementById('location_active_date');

  activatedByNameElement = () => document.getElementById('location_activated_by_name');

  archivedAtElement = () => document.getElementById('location_archived_at');

  archivedByNameElement = () => document.getElementById('location_archived_by_name');

  isCreatingElement = () => document.getElementById('location_is_creating');

  deleteElement = () => document.querySelector("[data-method='delete']");

  getStatus = () => this.statusElement().value;

  setStatus(status) {
    this.statusElement().value = status;
  }

  getActiveDate = () => this.activeDateElement().value;

  setActiveDate(date) {
    this.activeDateElement().value = date;
  }

  getActivatedByName = () => this.activatedByNameElement().value;

  getArchivedAt = () => this.archivedAtElement().value;

  getArchivedByName = () => this.archivedByNameElement().value;

  deleteLocation = () => {
    this.deleteElement().click();
  };

  getIsCreating = () => this.isCreatingElement().value === 'true';
}
