import { rangeValidator } from './rangeValidator';

export const getNumberColumnLookupRangeErrors = (rules) => {
  if (!rules) return [];

  return Object.keys(rules).reduce((acc, ruleKey) => {
    const {
      minValueWarningLimit,
      maxValueWarningLimit,
      outOfRangeWarningText,
    } = rules[ruleKey];

    const errors = rangeValidator(
      minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText
    );

    if (errors.length) {
      acc.push({ [ruleKey]: errors });
    }

    return acc;
  }, []);
};
