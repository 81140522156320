import _ from 'lodash';
import constants from './constants';
import { RequestConstants } from '../request';

export const notifications = (state, action) => {
  const initialState = {
    content: [],
    isLoaded: false,
  };

  const currState = { ...state } || { ...initialState };

  switch (action.type) {
    case RequestConstants.getLoadedActionType(constants.INCOMPLETE_KEY): {
      return {
        content: [...action.content.notifications],
        isLoaded: true,
      };
    }
    case RequestConstants.getLoadedActionType(constants.DISMISS_KEY): {
      const newContent = state.content.reduce((acc, curr) => {
        const notification = curr.id === action.content.id ? action.content : curr;
        return acc.concat(notification);
      }, []);
      return {
        content: newContent,
        isLoaded: true,
      };
    }
    case constants.UPDATE: {
      const content = [action.notification, ...currState.content];
      return {
        content: _.uniqBy(content, notification => notification.notification_id),
        isLoaded: true,
      };
    }
    default:
      return currState;
  }
};

export default notifications;
