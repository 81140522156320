import _ from 'lodash';

import { EMPTY_SCORE } from '../constants';
import { SECTION_TITLE } from '../data-capture/v2/constants';
import calculateVisibleFieldsByLogic from '../calculateVisibleFieldsByLogic';

const baseScore = () => ({ score: 0, scoreOutOf: 0 });

const fieldScore = ({ row, column, visible }) => {
  if (!visible) return baseScore();

  const {
    fieldName, scores = [], values = [],
  } = column.cellSettings || {};
  const fieldValue = row[fieldName];

  const score = _.chain(values)
    .zip(scores)
    .fromPairs()
    .get(fieldValue)
    .value();

  const noScoreToCalculate = score === EMPTY_SCORE || !_.size(scores);
  if (noScoreToCalculate) return baseScore();

  const scoresWithoutNA = scores.filter(filter => filter !== EMPTY_SCORE);
  return { score: score || 0, scoreOutOf: _.max(scoresWithoutNA) || 0 };
};

const columnsScore = (columns, row, visibleFieldsByLogic) => (
  columns.reduce((accu, column) => {
    const { cellSettings: { fieldName } } = column;
    const visible = visibleFieldsByLogic.includes(fieldName);
    const scoreForField = fieldScore({ column, row, visible });
    return {
      score: accu.score + scoreForField.score,
      scoreOutOf: accu.scoreOutOf + scoreForField.scoreOutOf,
    };
  }, baseScore())
);

const sectionScoreSelector = (state, { columns, sectionColumns, row }) => {
  const visibleFieldsByLogic = calculateVisibleFieldsByLogic(row, columns);

  return columnsScore(sectionColumns, row, visibleFieldsByLogic);
};

const rowScoreSelector = (state, { columns, row }) => {
  const visibleFieldsByLogic = calculateVisibleFieldsByLogic(row, columns);

  return columnsScore(columns, row, visibleFieldsByLogic);
};

const rowsScoreSelector = (state, { columns, rows }) => rows.reduce((accu, row) => {
  if (row.rowDeleted) return accu;

  const { score, scoreOutOf } = rowScoreSelector(state, { columns, row });
  return {
    score: accu.score + score,
    scoreOutOf: accu.scoreOutOf + scoreOutOf,
  };
}, baseScore());

const percent = ({ score, scoreOutOf }) => {
  if (score < 0) return 0;
  const ratio = score / scoreOutOf;
  return Math.round(ratio * 10000) / 100;
};

const dataCaptureScoresSelector = (state, { columns, rows = [] }) => {
  const widgetTotal = baseScore();

  const rowScores = rows.reduce((rowsAccu, row) => {
    if (row.rowDeleted) return [...rowsAccu, { rowDeleted: true }];

    const rowTotal = baseScore();
    const visibleFieldsByLogic = calculateVisibleFieldsByLogic(row, columns);

    let currentSectionFieldName;

    const columnScores = columns.reduce((columnsAccu, column) => {
      const { cellSettings: { fieldName } } = column;
      const visible = visibleFieldsByLogic.includes(fieldName);

      if (column.cellType === SECTION_TITLE) {
        currentSectionFieldName = fieldName;
        return {
          ...columnsAccu,
          [currentSectionFieldName]: baseScore(),
        };
      }

      const { score, scoreOutOf } = fieldScore({ row, column, visible });
      if (!scoreOutOf) return columnsAccu;

      if (currentSectionFieldName) {
        const sectionTotal = columnsAccu[currentSectionFieldName];
        sectionTotal.score += score;
        sectionTotal.scoreOutOf += scoreOutOf;
        sectionTotal.percentage = percent(sectionTotal);
      }

      rowTotal.score += score;
      rowTotal.scoreOutOf += scoreOutOf;

      widgetTotal.score += score;
      widgetTotal.scoreOutOf += scoreOutOf;

      return {
        ...columnsAccu,
        [fieldName]: {
          score, scoreOutOf, percentage: percent({ score, scoreOutOf }),
        },
      };
    }, {});

    rowTotal.percentage = percent(rowTotal);
    return [...rowsAccu, { columns: columnScores, total: rowTotal }];
  }, []);

  return ({
    rows: rowScores,
    total: {
      ...widgetTotal,
      percentage: percent(widgetTotal),
    },
  });
};

export default {
  dataCaptureScoresSelector,
  rowScoreSelector,
  rowsScoreSelector,
  sectionScoreSelector,
};
