import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon';
import Button from './Button';

class Message extends Component {
  _getIcon() {
    let icon = null;

    if (this.props.iconName) {
      icon = (
        <SVGIcon
          key='icon'
          classes='showtime-message__icon'
          iconName={ this.props.iconName }
        />
      );
    }

    return icon;
  }

  _getMessageList() {
    let messageList = null;

    if (this.props.messages) {
      messageList = (
        <div key='messages' className='showtime-message__body'>
          {this._getIcon()}
          <div className='showtime-message__text'>
            {this._getMessages(this.props.messages)}
          </div>
        </div>
      );
    }

    return messageList;
  }

  _getMessages(messages) {
    const messageElements = [];

    for (let i = 0; i < messages.length; i += 1) {
      messageElements.push((
        <p
          className='showtime-message__text-item'
          key={ `message${i}` }
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: messages[i] } }
        />
      ));
    }

    return messageElements;
  }

  _getButton() {
    let button = null;

    // currently we can only use basic buttons (without icons) in messages
    if (this.props.button) {
      button = (
        <div key='button' className='showtime-message__button'>
          <Button
            buttonClasses={ this.props.button.buttonClasses }
            onClick={ this.props.button.onClick }
            uri={ this.props.button.uri }
            label={ this.props.button.label }
          />
        </div>
      );
    }

    return button;
  }


  render() {
    const messageClasses = `showtime-message ${this.props.messageClasses}`;

    const innerElements = [
      this._getMessageList(),
      this._getButton(),
    ];

    const element = (
      <div className={ messageClasses }>
        <div className='showtime-message__inner'>
          {innerElements}
        </div>
      </div>
    );

    return element;
  }
}

Message.propTypes = {
  messageClasses: PropTypes.string,
  iconName: PropTypes.string,
  messages: PropTypes.array.isRequired,
  button: PropTypes.shape({
    buttonClasses: PropTypes.string,
    onClick: PropTypes.func,
    uri: PropTypes.string,
    label: PropTypes.string,
  }),
};

Message.defaultProps = {
  messageClasses: '',
  iconName: '',
  button: undefined,
};

export default Message;
