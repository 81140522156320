import React from 'react';
import _ from 'lodash';
import EventEmitter from 'wolfy87-eventemitter';
import ReactInlineSVG from 'react-inlinesvg';

const { config } = window;

export const IconStore = _.extend({}, EventEmitter.prototype, {
  iconAssetPath() {
    return config.iconAssetPath;
  },

  logoAssetPath() {
    return config.logoAssetPath;
  },

  getIcon(code) {
    return this.iconAssetPath().replace('[CODE]', code);
  },

  getLogo(filename) {
    return this.logoAssetPath().replace('[FILENAME]', filename);
  },

  iconic(size, code, options) {
    return this.iconicImage(size, code, options);
  },

  iconicImage(size, code, options) {
    const opts = _.merge({ useImage: false }, options);
    const classes = `icon iconic ${size}`;
    const attrs = _.merge({ className: classes, src: this.getIcon(code) }, options);
    const usingAlt = _.filter(_.keys(attrs), a => a.indexOf('data-') >= 0).length > 0;
    if (usingAlt || opts.useImage) {
      return <img { ...attrs } alt='' />;
    }
    return <ReactInlineSVG { ...attrs } />;
  },

  getLoadingIcon() {
    return config.loadingIconPath;
  },
});
