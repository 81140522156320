import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M15,2h-2V1c0-0.6-0.4-1-1-1h-1c-0.6,0-1,0.4-1,1v1H6V1c0-0.6-0.4-1-1-1H4C3.4,0,3,0.4,3,1v1H1 C0.4,2,0,2.4,0,3v12c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V3C16,2.4,15.6,2,15,2z M14,14H2V7h12V14z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
