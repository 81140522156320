import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutsideHOC from 'react-onclickoutside';

const OnClickOutside = onClickOutsideHOC(({
  className, dataTest, children,
}) => <div className={ className } data-test={ dataTest }>{ children }</div>);

const ModalCard = ({
  children, dataTest, className, noPadding, onClickOutside,
}) => (
  <div className='modal-screen'>
    <div className='modal-card'>
      <OnClickOutside
        className={
          classNames(
            'modal-card__content',
            {
              'modal-card__content--no-padding': noPadding,
              [className]: className,
            }
          )
        }
        handleClickOutside={ onClickOutside }
        dataTest={ dataTest }
      >
        <div className='ignore-react-onclickoutside'>
          { children }
        </div>
      </OnClickOutside>
    </div>
  </div>
);

ModalCard.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
  noPadding: PropTypes.bool,
  onClickOutside: PropTypes.func,
  className: PropTypes.string,
};

ModalCard.defaultProps = {
  children: null,
  dataTest: null,
  noPadding: false,
  onClickOutside() {},
  className: '',
};

export const CardTitle = ({ children }) => (<div className='modal-card__title'>{ children }</div>);
CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CardDescription = ({ children }) => (<div className='modal-card__description'>{ children }</div>);
CardDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CardActions = ({ children }) => (<div className='modal-card__actions'>{ children }</div>);
CardActions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalCard;
