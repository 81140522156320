export default {
  INCOMPLETE_KEY: 'notifications-incomplete',
  DISMISS_KEY: 'notifications-dismiss',
  UPDATE: 'notifications.update',
  TYPES: {
    LINK: 'LINK',
    TASK_COMPLETED: 'task_completed',
    WEEKLY_AUDIT: 'weekly_audit',
    DAILY_DIGEST: 'daily_digest',
  },
};
