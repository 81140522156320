import _ from 'lodash';
import { createSelector } from 'reselect';
import { TaskTemplateConstants } from '../../task/constants';
import { TaskPlannerConstants } from '../constants';
import { isFreePlan } from '../../sign-up/plan-utils';
import { FREE_PLAN_AD_HOC_LIMIT } from '../../sign-up/constants';

const isTaskTemplateOnDemand = taskTemplate => taskTemplate
  .template_type === TaskTemplateConstants.templateType.ON_DEMAND;

const isTaskTemplateArchived = taskTemplate => taskTemplate
  .status === TaskTemplateConstants.status.ARCHIVED;

const taskTemplatesSelector = state => _.get(state, 'base.taskTemplates', []);
const taskPlannerLoadingSelector = state => _.get(state, 'base.isLoading', false);

const selectedTaskTemplates = (store) => {
  const selectedTaskTemplateIds = _.get(store, 'table.selectedTasks', []);
  const taskTemplates = _.get(store, 'base.taskTemplates', []);

  return _.filter(taskTemplates,
    taskTemplate => _.includes(selectedTaskTemplateIds, taskTemplate.id));
};

const isEverySelectedTaskOnDemand = store => selectedTaskTemplates(store)
  .every(isTaskTemplateOnDemand);

const isEverySelectedTaskArchived = store => selectedTaskTemplates(store)
  .every(isTaskTemplateArchived);

const locationsForSelectedTasksExcludingOnDemand = (store, props) => {
  const taskTemplates = selectedTaskTemplates(store);
  const taskTemplatesExcludingOnDemand = _.reject(taskTemplates, isTaskTemplateOnDemand);

  const selectedLocationIds = _.flatMap(taskTemplatesExcludingOnDemand, 'locations').map(l => l.id);

  const selectedLocations = _.filter(props.locations,
    location => _.includes(selectedLocationIds, location.id));

  return selectedLocations;
};

const taskTemplateById = (store, { taskTemplateId }) => {
  const taskTemplates = _.get(store, 'base.taskTemplates', []);

  return _.find(taskTemplates, { id: taskTemplateId });
};

export const taskContainsBlockedFeaturesSelector = (state, { id }) => {
  const {
    uses_features_disabled_for_organization: usesDisabledFeatures,
  } = taskTemplateById(state, { taskTemplateId: id }) || {};
  return Boolean(usesDisabledFeatures);
};

const taskPreviewsLoading = store => _.get(store, 'base.previewsLoading', false);

const filterTaskTemplates = (taskTemplates, filters) => {
  const nameFilter = filters[TaskPlannerConstants.FILTER_BY_NAME];
  const nameRegex = new RegExp(nameFilter, 'i');
  const filterByName = name => nameRegex.exec(name);

  const tagFilter = filters[TaskPlannerConstants.FILTER_BY_TAG];
  const filterByTag = tags => !tagFilter ||
    tagFilter.length === 0 ||
    (tags && tags.some(t => tagFilter.findIndex(id => id === t.id) > -1));

  const locationFilter = filters[TaskPlannerConstants.FILTER_BY_TRAIL];
  const filterByLocation = locs => !locationFilter ||
    locationFilter.length === 0 ||
    (locs && locs.some(l => locationFilter.findIndex(id => id === l.id) > -1));

  const areaFilter = filters[TaskPlannerConstants.FILTER_BY_GROUP];
  const filterByArea = locs => !areaFilter ||
    areaFilter.length === 0 ||
    (locs && locs.some(l => areaFilter.findIndex(id => id === l.area_id) > -1));

  return (taskTemplates || [])
    .filter(t => filterByName(t.name) &&
                 filterByTag(t.tags) &&
                 filterByLocation(t.locations) &&
                 filterByArea(t.locations));
};

export const taskPlannerSelector = store => ({
  ...store.base,
  filteredTaskTemplates: filterTaskTemplates(store.base.taskTemplates, store.base.filters),
});

const tagsForSelectedTasks = (store) => {
  const taskTemplates = selectedTaskTemplates(store);

  const taskTemplatesWithTags = taskTemplates.filter(task => 'tags' in task);
  const selectedTagsIds = _.flatMap(taskTemplatesWithTags, 'tags').map(t => t.id);

  const selectedTags = _.filter(taskPlannerSelector(store).tags,
    tag => _.includes(selectedTagsIds, tag.id));
  return selectedTags;
};

const actionTemplatesSelector = createSelector(
  store => _.get(store, 'base.taskTemplates', []),
  taskTemplates => _.filter(
    taskTemplates, { template_type: TaskTemplateConstants.templateType.ACTION_TEMPLATE }
  )
);

export const canAddAdHocSelector = createSelector(
  store => _.get(store, 'base.taskTemplates', []),
  (taskTemplates) => {
    const onDemandTemplates = _.filter(taskTemplates, isTaskTemplateOnDemand);
    const onDemandTemplatesAtOrOverLimit = onDemandTemplates.length >= FREE_PLAN_AD_HOC_LIMIT;
    return !(isFreePlan() && onDemandTemplatesAtOrOverLimit);
  }
);

const adHocTaskCountSelector = createSelector(
  taskTemplatesSelector,
  taskPlannerLoadingSelector,
  isFreePlan,
  (taskTemplates, isLoading, freePlan) => {
    if (isLoading) return null;
    if (!freePlan) return null;
    const adHocTasks = taskTemplates.filter(isTaskTemplateOnDemand);
    return _.min([adHocTasks.length, FREE_PLAN_AD_HOC_LIMIT]);
  });

export default {
  actionTemplatesSelector,
  adHocTaskCountSelector,
  isEverySelectedTaskOnDemand,
  isEverySelectedTaskArchived,
  locationsForSelectedTasksExcludingOnDemand,
  tagsForSelectedTasks,
  taskTemplateById,
  taskPreviewsLoading,
  taskPlannerSelector,
  canAddAdHocSelector,
  taskContainsBlockedFeaturesSelector,
};
