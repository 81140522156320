export const types = [
  'error',
  'info',
  'success',
  'warning',
];

export const widths = [
  '',
  'wider',
];

export const actionConstants = {
  SHOW: 'modalAlert.show',
  HIDE: 'modalAlert.hide',
  SET_CONTENT: 'modalAlert.setContent',
  RESET_CONTENT: 'modalAlert.resetContent',
};
