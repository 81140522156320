import { Component } from 'react';
import PropTypes from 'prop-types';
import { IconStore } from './store';

class Iconic extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const icon = IconStore.iconic(this.props.classes, this.props.icon, this.props.options);
    // TODO check this... IconicJS().inject('.iconic');
    return icon;
  }
}

Iconic.propTypes = {
  classes: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
};

export default Iconic;
