import PropTypes from 'prop-types';
import React from 'react';

import SVGIcon from './SVGIcon';

const DragHandle = ({ dragProvided, classes }) => (
  <div
    className={ classes }
    { ...dragProvided.dragHandleProps }
  >
    <SVGIcon
      classes='showtime-icon showtime-icon--size-14'
      iconName='mini/ui/ui-16px-1_drag-CUSTOM'
    />
  </div>
);

DragHandle.propTypes = {
  dragProvided: PropTypes.shape({
    dragHandleProps: PropTypes.object,
  }).isRequired,
  classes: PropTypes.string,
};

DragHandle.defaultProps = {
  classes: '',
};

export default DragHandle;
