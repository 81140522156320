import _ from 'lodash';

const MysteryDinerWidgetData = function (rawWidgetData) {
  _.extend(this, rawWidgetData);

  const customData = this.custom_fields.questions || [];
  const predefinedData = ((this.predef && this.predef.groups) || []).map(({ items }) => items);

  this.custom_fields.questions = predefinedData.map(
    (items, index) => customData[index] || items || []
  );
};

export default MysteryDinerWidgetData;
