import _ from 'lodash';
import { TaskTitleConstants, TaskWizardConstants } from '../constants';
import { TaskTemplateConstants } from '../../task/constants';
import {
  GET_EDITING_ACTION_KEY, SET_ACTION_DEFAULTS, GET_ACTION_TEMPLATE_KEY,
} from '../../taskActions/constants';
import { RequestConstants } from '../../request';

const setTitle = (state, task) => _.extend({}, state, {
  content: {
    value: task.taskTitleFormat ?
      task.taskTitleFormat.replace(TaskTitleConstants.FORMAT_MATCH, task.name) :
      task.name,
  },
});

export const taskTitleReducer = function (state, action) {
  const initialState = {
    content: {
      value: '',
    },
  };
  const currState = state || _.extend({}, initialState);

  switch (action.type) {
    case SET_ACTION_DEFAULTS:
      return _.extend({}, currState, {
        content: {
          value: action.values.title,
        },
      });
    case TaskTitleConstants.CHANGE:
      return _.extend({}, currState, {
        content: {
          value: action.content,
        },
      });
    case TaskTitleConstants.RESET:
      return _.extend({}, initialState);
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return setTitle(currState, action.content);
    case RequestConstants.getLoadedActionType(GET_ACTION_TEMPLATE_KEY):
      if (action.content.status === TaskTemplateConstants.status.ARCHIVED) {
        return setTitle(currState, { name: action.content.fallbackTitle });
      }

      return setTitle(currState, action.content);
    case RequestConstants.getLoadedActionType(GET_EDITING_ACTION_KEY):
      return setTitle(currState, action.content.timeline_task);
    default:
      return currState;
  }
};
