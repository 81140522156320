import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { IconStore } from '../common/legacy/store';
import { TimeSlotConfigurationConstants } from './constants';
import { TimeSlotConfigurationStore } from './store.js';
import { TimeSlotConfigurationActions } from './actions';
import SelectTimePicker from './SelectTimePicker';

class EditableTimeSlot extends Component {
  renderClosedCheckbox(timeslotName) {
    const testProp = { 'data-test': `checkbox-${timeslotName.toLowerCase()}` };
    return (
      <label className='checkbox checkbox_primary' { ...testProp } >
        <input
          className='checkbox-input'
          type='checkbox'
          checked={ this.getTimeSlot().isClosed }
          onChange={ e => this.toggleClosed(e) }
          { ...testProp }
        />
        <span className='icon' />
        <span className='text'>{timeslotName}</span>
      </label>
    );
  }

  renderDestroyButton() {
    return (
      <div className='small-1 grid-content'>
        <a href='javascript:;'>{IconStore.iconic('xsm', 'trash')}</a>
      </div>
    );
  }

  setStartTime = (value) => {
    TimeSlotConfigurationActions.setStartTime(this.props.level, this.props.id, value);
  }

  setEndTime = (value) => {
    TimeSlotConfigurationActions.setEndTime(this.props.level, this.props.id, value);
  }

  minEndHour() {
    const timeSlot = this.getTimeSlot();
    if (timeSlot && timeSlot.position === TimeSlotConfigurationStore.finalPosition()) {
      return 24;
    }
    if (timeSlot && timeSlot.startTime) {
      return parseInt(timeSlot.startTime.split(':')[0], 10);
    }
    return 0;
  }

  todayLabel = () => {
    const timeSlot = this.getTimeSlot();
    return TimeSlotConfigurationConstants.DAYS_ORDER[timeSlot.dayOfWeekNum];
  }

  tomorrowLabel = () => {
    const timeSlot = this.getTimeSlot();
    return TimeSlotConfigurationConstants.DAYS_ORDER[(timeSlot.dayOfWeekNum + 1) % 7];
  }

  renderTimePicker() {
    const timeSlot = this.getTimeSlot();
    return (
      <div className='editable-start-end-time' >
        <SelectTimePicker
          key='startTime'
          readOnly={ this.props.readOnly }
          value={ timeSlot.startTime }
          minuteStep={ 15 }
          maxHour={ 48 }
          minHour={ 0 }
          todayLabel={ this.todayLabel }
          tomorrowLabel={ this.tomorrowLabel }
          onChange={ this.setStartTime }
        />
        <span className='separator' key='separator'>&nbsp;to&nbsp;</span>
        <SelectTimePicker
          key='endTime'
          readOnly={ this.props.readOnly }
          value={ timeSlot.endTime }
          minuteStep={ 15 }
          minHour={ this.minEndHour() }
          maxHour={ 48 }
          todayLabel={ this.todayLabel }
          tomorrowLabel={ this.tomorrowLabel }
          onChange={ this.setEndTime }
        />
      </div>
    );
  }

  getTimeSlot() {
    return TimeSlotConfigurationStore.getTimeSlot(this.props.level, this.props.id);
  }

  render() {
    const timeSlot = this.getTimeSlot();
    const classes = classNames({
      'editable-time-slot': true,
      'grid-block': true,
      'is-closed': timeSlot.isClosed,
    });
    return (
      <div className={ classes } data-test={ `editableTimeslotContainer-${timeSlot.name}` }>
        <div className='editable-time-slot__name'>
          {this.props.isCloseable && !this.props.readOnly
            ? this.renderClosedCheckbox(timeSlot.name)
            : timeSlot.name}
        </div>
        <div className='editable-time-slot__times' data-test={ `editable-slot-${timeSlot.name}` }>
          {
            timeSlot.isClosed ?
              this.props.intl.formatMessage({ id: 'editableTimeSlotList.timeslotClosed' }) :
              this.renderTimePicker()
          }
        </div>
        {this.props.isDestroyable ? this.renderDestroyButton() : null}
      </div>
    );
  }

  destroy() {
    // This method is part of the next feature in the roadmap
    TimeSlotConfigurationActions.destroy(this.props.level, this.props.id);
  }

  toggleClosed(e) {
    TimeSlotConfigurationActions.setClosed(this.props.level, this.props.id, e.target.checked);
  }

  changeStartTime(e) {
    TimeSlotConfigurationActions.setStartTime(this.props.level, this.props.id, e.fieldValue);
  }

  changeEndTime(e) {
    TimeSlotConfigurationActions.setEndTime(this.props.level, this.props.id, e.fieldValue);
  }
}

EditableTimeSlot.propTypes = {
  isCloseable: PropTypes.bool,
  isDestroyable: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

EditableTimeSlot.defaultProps = {
  isDestroyable: false,
  isCloseable: false,
};

export default injectIntl(EditableTimeSlot);
