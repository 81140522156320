import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';

import Button from '../common/Button';
import { RouterConstants, getBaseRoute } from '../router';
import PanelConstants from './constants';

export class Panel extends Component {
  componentDidMount() {
    this.showSelf();
  }

  componentWillUnmount() {
    this.hideSelf();
  }

  showSelf() {
    document.addEventListener(PanelConstants.KEY_UP_EVENT, this.onKeyUp);
    document.body.classList.add('showtime-panel-active');

    if (this.props.blockPage) {
      document.body.classList.add('showtime-panel-active--block-page');
    } else {
      document.body.classList.remove('showtime-panel-active--block-page');
    }
  }

  hideSelf() {
    document.removeEventListener(PanelConstants.KEY_UP_EVENT, this.onKeyUp);

    const panelLength = document.getElementsByClassName('showtime-panel').length;
    const isLastVisiblePanel = panelLength === 1;

    if (isLastVisiblePanel) {
      document.body.classList.remove('showtime-panel-active');
      document.body.classList.remove('showtime-panel-active--block-page');
    }
  }

  routeAway = () => {
    this.props.onHidden();

    if (this.props.history && this.props.location) {
      const { pathname } = this.props.location;
      const shouldGoBack = this.pathNameIsANestedRoute(pathname) || this.props.goBack;
      if (shouldGoBack) {
        this.props.history.goBack();
      } else {
        if (this.props.backPage && !this.props.backPageSoftRoute) {
          window.location.href = this.props.backPage;
          return;
        }

        const softRoute = this.props.backPage || getBaseRoute(pathname);
        this.props.history.push(softRoute);
      }
    }
  };

  pathNameIsANestedRoute(pathname) {
    const partialRoute = this.partialRoute(pathname);
    const isNested = RouterConstants.NESTED_PANEL_ROUTES.indexOf(partialRoute) !== -1;

    return isNested;
  }

  partialRoute(pathname) {
    return pathname.split('/')[1];
  }

  onKeyUp = (event) => {
    if (event.keyCode === PanelConstants.ESC_KEY_CODE) {
      event.preventDefault();
      this.routeAway();
    }
  };

  renderHeaderActions() {
    let headerActions = null;
    if (this.props.headerContent) {
      headerActions = (
        <div className='showtime-panel__header-actions'>
          { this.props.headerContent }
        </div>
      );
    }
    return headerActions;
  }

  renderStatusLabel() {
    let statusContent = null;

    if (this.props.statusContent) {
      statusContent = (
        <div className='showtime-panel__header-status'>
          { this.props.statusContent }
        </div>
      );
    }

    return statusContent;
  }

  renderSecondaryHeader() {
    let secondaryHeader = null;
    if (this.props.secondaryHeader) {
      secondaryHeader = (
        <div className='showtime-panel__header-secondary'>
          { this.props.secondaryHeader }
        </div>
      );
    }
    return secondaryHeader;
  }

  render() {
    const panelContainerClasses = classNames(
      'showtime-panel',
      this.props.panelContainerClasses,
      { 'showtime-panel--wide': this.props.isWide }
    );

    const { dataTest } = this.props;
    const dataTestProps = dataTest && { 'data-test': dataTest };

    return (
      <div { ...dataTestProps } className={ panelContainerClasses }>
        <div className='showtime-panel__inner'>
          <header className='showtime-panel__header'>
            <div className='showtime-panel__header-inner'>
              <div className='showtime-panel__header-close'>
                <Button
                  onClick={ this.routeAway }
                  dataTest='panel-close'
                  buttonClasses='showtime-button--small showtime-button--panel-close'
                  iconName='mini/arrows/arrows-16px-1_minimal-left'
                />
              </div>
              { this.renderHeaderActions() }
              <div className='showtime-panel__header-title utility-truncate'>
                <h1 className='showtime-panel__header-title--primary'>
                  <span>{ this.props.title }</span>
                </h1>
                { this.props.secondaryTitle && (
                  <div className='showtime-panel__header-title--secondary' data-test='panel.title.secondary'>
                    <span>{this.props.secondaryTitle}</span>
                  </div>
                )}
              </div>
              { this.renderStatusLabel() }
            </div>

            { this.renderSecondaryHeader() }
          </header>

          <section className='showtime-panel__content'>
            <div className='showtime-panel__content-inner'>
              { this.props.children }
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Panel.propTypes = {
  blockPage: PropTypes.bool,
  isWide: PropTypes.bool,
  children: PropTypes.node,
  dataTest: PropTypes.string,
  statusContent: PropTypes.element,
  headerContent: PropTypes.element,
  onHidden: PropTypes.func,
  panelContainerClasses: PropTypes.string,
  secondaryHeader: PropTypes.node,
  title: PropTypes.string,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  backPage: PropTypes.string,
  backPageSoftRoute: PropTypes.bool,
  goBack: PropTypes.bool,
  secondaryTitle: PropTypes.node,
};

Panel.defaultProps = {
  blockPage: null,
  isWide: false,
  children: null,
  dataTest: undefined,
  statusContent: null,
  headerContent: null,
  onHidden() {},
  panelContainerClasses: '',
  secondaryHeader: null,
  title: '',
  backPage: null,
  backPageSoftRoute: false,
  goBack: false,
  secondaryTitle: null,
};

export default withRouter(Panel);
