import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import WindowHelper from '../utils/window';
import OfflineInteractionBlocker from '../offline-interaction-blocker';
import { TOP } from '../interaction-blocker/positions';
import { SMALL } from '../interaction-blocker/sizes';

import SVGIcon from '../common/SVGIcon.jsx';

export const CameraCapture = ({
  onCapture, disabled, children, intl, label,
}) => {
  const { capture: captureSupported } = WindowHelper.browserSupport();
  if (!captureSupported) return null;

  const classes = classNames(
    'showtime-button showtime-button--input-file',
    {
      'showtime-button--adjacent': label !== '',
      'is-disabled': disabled,
      'showtime-button--no-border': label === '',
      'showtime-button--default': label !== '',
    }
  );

  return (
    <OfflineInteractionBlocker
      tooltipMessage={ intl.formatMessage({ id: 'conversation.commentBox.upload.offline.notAvailable' }) }
      size={ SMALL }
      position={ TOP }
    >
      <label
        data-test='camera.capture'
        className={ classes }
      >
        <SVGIcon
          classes='showtime-button__icon'
          iconName='media/media-16px-1_camera-19'
        />
        {(label || children) && (
          <span className='showtime-button__text'>{ (label || children) }</span>
        )}
        <input
          onChange={ onCapture }
          type='file'
          accept='image/*'
          capture
        />
      </label>
    </OfflineInteractionBlocker>
  );
};

CameraCapture.propTypes = {
  onCapture: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
};

CameraCapture.defaultProps = {
  disabled: false,
  onCapture() {},
  children: null,
  label: '',
};

export default injectIntl(CameraCapture);
