import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';
import _ from 'lodash';

import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import { RouterConstants, getBaseRoute } from '../router';
import { TrailConstants } from '../trail/constants';
import { TaskPlannerConstants } from './constants/taskPlannerConstants';
import { TaskTemplateConstants } from '../task/constants';
import * as ReportConstants from '../task-wizard/constants/reportConstants';
import { TaskPlannerActions, TaskPlannerTableActions } from './actions';

import WindowHelper from '../utils/window';
import { getCurrentUserRole, UserRole } from '../application/UserRole';
import scrollToComponent from '../common/scrollToComponent';

import TaskTemplateStatus from '../common/TaskTemplateStatus';
import TaskPreview from './TaskPreview';
import HistoryStatus from './HistoryStatus';
import ScheduleStatus from './ScheduleStatus';
import CopyLibraryTaskButton from './CopyLibraryTaskButton';
import Button from '../common/Button';
import SVGIcon from '../common/SVGIcon';
import ToolTip from '../common/ToolTip';
import CheckBox from '../common/forms/CheckBox';
import EmptyState from '../common/EmptyState';
import TaskAssignedSitesDescription from './TaskAssignedSitesDescription';
import { taskContainsBlockedFeaturesSelector } from './selectors/taskPlannerSelectors';
import { UpsellPill, UpsellPrimaryButton } from '../common/Upsell';

const TaskHeaderCallout = ({ taskId, status, hideStatus }) => {
  const containsBlockedFeatures = useSelector(
    state => taskContainsBlockedFeaturesSelector(state, { id: taskId }));
  if (containsBlockedFeatures) {
    return (
      <UpsellPill />
    );
  }
  return !hideStatus && <TaskTemplateStatus status={ status } />;
};

TaskHeaderCallout.propTypes = {
  taskId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  hideStatus: PropTypes.bool.isRequired,
};

export class TaskPlannerTableComponent extends Component {
  taskRefs = {};

  componentDidUpdate() { return this.scrollIfNecessary(); }

  scrollIfNecessary = () => {
    const { taskPlannerTable: { scrollToTaskTemplateId } } = this.props;

    if (scrollToTaskTemplateId && this.taskRefs[scrollToTaskTemplateId]) {
      const taskRef = this.taskRefs[scrollToTaskTemplateId];
      scrollToComponent(taskRef, { behavior: 'auto' });
      this.resetHighlightedTaskTemplateId();
    }
  };

  resetHighlightedTaskTemplateId = () => this.props.actions.taskPlanner.highlightTaskTemplate(null);

  onTagClick = () => {
    const metric = TaskPlannerConstants.metricsDirectoryPage(this.props.singleLocation)
      .TASK_TAG_CLICKED;
    metricsPublisher.recordMetric(metric);
  };

  onHistoryButtonClick(taskTemplate) {
    this.props.router.push(RouterConstants.buildTaskHistoryUrl(taskTemplate.id));
  }

  viewTaskInstanceOnTrail = (taskTemplate) => {
    if (this.props.datePeriod !== TrailConstants.datePeriod.TODAY) {
      const route = TrailConstants.taskOnTrailPageRoute(
        this.props.singleLocationId, null, taskTemplate.todays_instance_id
      );
      WindowHelper.navigateTo(route);
    }

    const route = RouterConstants.buildTrailTaskUrl(taskTemplate.todays_instance_id);
    this.props.router.push(route);
  };

  editTaskTemplate(taskTemplate) {
    const route = RouterConstants.buildEditTaskUrl(taskTemplate.id);
    this.props.router.push(route);
  }

  previewTaskTemplate(taskTemplate) {
    const libraryOrgId = this.props.isLibraryPlanner ? this.props.organizationId : null;
    const route = RouterConstants.buildPreviewTaskUrl(taskTemplate.id, libraryOrgId);

    this.props.router.push(route);
  }

  taskPrimaryActionForSingleLocation(taskTemplate) {
    if (taskTemplate.template_type === 'on_demand' || !taskTemplate.todays_instance_id) {
      return null;
    }

    return this.viewTaskInstanceOnTrail.bind(this);
  }

  taskPrimaryAction(taskTemplate, isExpanded) {
    if (this.props.singleLocation) {
      return this.taskPrimaryActionForSingleLocation(taskTemplate);
    }

    if (isExpanded) return null;

    const { router, previewTaskId } = this.props;
    const onPreviewRoute = taskTemplate.id === previewTaskId;

    if (onPreviewRoute) {
      const { location: { pathname } } = this.props;
      return () => router.push(getBaseRoute(pathname));
    }

    return () => this.previewTaskTemplate(taskTemplate);
  }

  renderHistoryButton(taskTemplate) {
    const historyButtonClasses = classNames({
      'showtime-button--small': true,
      'showtime-button--collapse-icon': true,
      'showtime-button--highlight': true,
    });

    return (
      <Button
        onClick={ () => this.onHistoryButtonClick(taskTemplate) }
        buttonClasses={ historyButtonClasses }
        label={ this.props.intl.formatMessage({ id: 'searchTasks.history' }) }
        ref={ `taskHistory${taskTemplate.id}` }
        dataTest={ `task${taskTemplate.id}.history` }
        iconName='mini/arrows/arrows-16px-1_tail-triangle-right'
        iconPosition='right'
      />
    );
  }

  renderEditButton(taskTemplate, isExpanded) {
    const editButtonClasses = classNames({
      'showtime-button--small': true,
      'hint--small': true,
      'hint--top-left': true,
      'hint--info': true,
      'showtime-button--lowlight-subtle': !isExpanded,
      'showtime-button--highlight': isExpanded,
    });

    return (
      <Button
        onClick={ () => { this.editTaskTemplate(taskTemplate); } }
        buttonClasses={ editButtonClasses }
        label={ isExpanded ? this.props.intl.formatMessage({ id: 'taskplanner.edit' }) : '' }
        dataHint='Edit task'
        dataTest='task.edit'
        ref={ `taskEdit${taskTemplate.id}` }
        key={ `taskEdit${taskTemplate.id}` }
        iconName='mini/design-development/design-16px_pen-01'
      />
    );
  }

  renderCopyButton(taskTemplate, isExpanded) {
    const { organizationId, router: history } = this.props;

    return (
      <CopyLibraryTaskButton
        { ...{ organizationId, history } }
        taskTemplateName={ taskTemplate.name }
        taskTemplateId={ taskTemplate.id }
        className={
          classNames({
            'hint--medium': true,
            'hint--top-left': true,
            'hint--info': true,
            'showtime-button--lowlight-subtle': !isExpanded,
            'showtime-button--create': isExpanded,
          })
        }
        dataHint='Add to my tasks'
        hideLabel={ !isExpanded }
        key={ `copyLibraryTask${taskTemplate.id}` }
      />
    );
  }

  renderTaskAdditionalActions(taskTemplate, isExpanded) {
    if (this.props.singleLocation) {
      return this.renderHistoryButton(taskTemplate);
    }

    const { uses_features_disabled_for_organization: restrictedFeatures } = taskTemplate;
    if (restrictedFeatures && !isExpanded) return null;
    if (restrictedFeatures) return (<UpsellPrimaryButton />);

    if (this.props.isLibraryPlanner) {
      return this.renderCopyButton(taskTemplate, isExpanded);
    }
    if (taskTemplate.editable) {
      return this.renderEditButton(taskTemplate, isExpanded);
    }

    return null;
  }

  renderTableRow = (taskTemplate) => {
    const isSelected = _.includes(
      this.props.taskPlannerTable.selectedTasks,
      taskTemplate.id
    );
    const isExpanded = _.includes(
      this.props.taskPlannerTable.expandedTasks,
      taskTemplate.id
    );

    const isPreview = taskTemplate.id === this.props.previewTaskId;

    const primaryAction = this.taskPrimaryAction(taskTemplate, isExpanded);

    const taskInnerClasses = classNames({
      'showtime-task-planner-task': true,
      'showtime-task-planner-task--selected': isSelected,
      'is-editable': primaryAction || !this.props.singleLocation,
      'is-expanded': isExpanded,
      'is-preview': isPreview,
      'has-no-schedule': taskTemplate.template_type === TaskTemplateConstants.templateType.ON_DEMAND
        || taskTemplate.template_type === TaskTemplateConstants.templateType.AUTOMATED,
    });

    const isChecked = _.includes(
      this.props.taskPlannerTable.selectedTasks,
      taskTemplate.id
    );

    const { isLibraryPlanner } = this.props;
    const canTempateBeSelected = !isExpanded && !isLibraryPlanner;
    const notPlannerOrSingleLocation = !(this.props.isLibraryPlanner || this.props.singleLocation);
    return (
      <div
        className={ taskInnerClasses }
        key={ `task-template-${taskTemplate.id}` }
        ref={ (element) => { this.taskRefs[taskTemplate.id] = element; } }
        data-test={ `task${taskTemplate.id}` }
      >
        { canTempateBeSelected && this.renderCheckBox(taskTemplate, isChecked) }

        <div
          className='showtime-task-planner-task__inner'
          onClick={ () => primaryAction && primaryAction(taskTemplate) }
        >
          <div
            className='showtime-task-planner-task__primary'
            data-test={ `taskPrimary${taskTemplate.id}` }
          >
            <div className='showtime-task-planner-task__primary-title' data-test='task.title'>
              {taskTemplate.name}
            </div>
            <div>
              {isExpanded ? this.renderTaskAdditionalActions(taskTemplate, isExpanded) : null }
              {!isExpanded && (
              <TaskHeaderCallout
                status={ (taskTemplate || {}).status }
                taskId={ _.get(taskTemplate, 'id') }
                hideStatus={ !notPlannerOrSingleLocation }
              />
              ) }
            </div>
          </div>
          <div className='showtime-task-planner-task__secondary'>
            <div className='showtime-task-planner-task__secondary-assignment'>
              <div className='showtime-task-planner-task__secondary-assignment-group'>
                { notPlannerOrSingleLocation && this.renderAssignedSites(taskTemplate) }
                { this.renderSchedule(taskTemplate) }
              </div>
              <div className='showtime-task-planner-task__secondary-assignment-group'>
                { !this.props.singleLocation && this.renderPermissionsSection(taskTemplate) }
                { !this.props.singleLocation && this.renderNotificationSection(taskTemplate) }
              </div>
            </div>
            <div className='showtime-task-planner-task__secondary-meta'>
              { this.renderTagSection(taskTemplate) }
            </div>
          </div>
        </div>

        {!isExpanded ? (
          <div className='showtime-task-planner-task__actions'>
            { this.renderTaskAdditionalActions(taskTemplate, isExpanded) }
          </div>
        ) : null}

        { isExpanded ? <TaskPreview taskTemplate={ taskTemplate } /> : null }
      </div>
    );
  };

  renderAssignedSites(taskTemplate) {
    if (taskTemplate.template_type === TaskTemplateConstants.templateType.ACTION_TEMPLATE) {
      return null;
    }

    return (
      <div className='showtime-task-planner-task__assigned-sites' data-test='assignedSites'>
        <SVGIcon
          classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
          iconName='mini/shopping/shopping-16px_shop'
        />
        <span>
          <TaskAssignedSitesDescription
            taskAssignedSites={ taskTemplate.locations }
            templateType={ taskTemplate.template_type }
          />
        </span>
      </div>
    );
  }

  renderSchedule(taskTemplate) {
    if (this.props.singleLocation) {
      return (
        <HistoryStatus
          dataTest={ `taskMetadata${taskTemplate.id}` }
          taskTemplate={ taskTemplate }
        />
      );
    }

    return (
      <ScheduleStatus
        dataTest={ `taskMetadata${taskTemplate.id}` }
        taskTemplate={ taskTemplate }
        scheduleCategories={ this.props.scheduleCategories }
      />
    );
  }

  renderCheckBox(taskTemplate, isChecked) {
    const hasRestrictedTaskAccess = getCurrentUserRole().isPermissionEnabled(
      UserRole.permissionTypes.MANAGE_PERMITTED_TASKS);
    if (hasRestrictedTaskAccess) return null;

    return this.props.singleLocation ? null : (
      <div className='showtime-task-planner-task__checkbox'>
        <CheckBox
          checkboxClasses='showtime-checkbox--small'
          checked={ isChecked }
          onChange={ () => this.toggleSelected(isChecked, taskTemplate.id) }
          dataTest='task.checkbox'
        />
      </div>
    );
  }

  renderNotificationSection(taskTemplate) {
    const notificationsTurnedOn = [
      ReportConstants.selectMode.COMPLETED,
      ReportConstants.selectMode.COMPLETED_WITH_EXCEPTIONS,
    ].includes(taskTemplate.notify_all_users);

    if (notificationsTurnedOn) {
      return (
        <div
          className='showtime-task-planner-task__notifications'
        >
          <SVGIcon
            classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
            iconName='mini/ui/ui-16px-1_bell-53'
          />
          <span className='showtime-task-planner-task__notifications-text'>
            { this.props.intl.formatMessage({ id: 'taskplanner.notifications' }) }
          </span>
        </div>
      );
    }
    return null;
  }

  renderPermissionsSection(taskTemplate) {
    if (taskTemplate.permission_level !== 'manager') {
      return null;
    }

    return (
      <div
        className='showtime-task-planner-task__permissions'
        data-test={ `task${taskTemplate.id}.permission` }
      >
        <SVGIcon
          classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
          iconName='mini/ui/ui-16px-1_eye-ban-18'
        />
        <span className='showtime-task-planner-task__permissions-text'>
          { this.props.intl.formatMessage({ id: 'taskplanner.permissions_admin_manager_only_label' }) }
        </span>
      </div>
    );
  }

  renderTagSection(taskTemplate) {
    const tagsEnabled = _.get(window.config, 'features.tags', false);
    let tags = null;

    if (taskTemplate.tags.length && tagsEnabled) {
      tags = (
        <div className='showtime-task-planner-task__tags'>
          { taskTemplate.tags.map(tag => this.renderTags(tag, taskTemplate.id)) }
        </div>
      );
    }

    return tags;
  }

  renderTags(tag, templateId) {
    return (
      <span className='showtime-tag showtime-tag--default' key={ `rendered-tag-${tag.label}-${templateId}` } onClick={ this.onTagClick }>
        {tag.label}
      </span>
    );
  }

  toggleSelected(isChecked, taskTemplateId) {
    const metric = isChecked ?
      TrailMetricsDirectory.page.TaskPlanner.TASK_CHECKBOX_DESELECTED :
      TrailMetricsDirectory.page.TaskPlanner.TASK_CHECKBOX_SELECTED;
    metricsPublisher.recordMetric(metric, { taskTemplateId });

    this.props.actions.taskPlannerTable.updateSelectedTasks(taskTemplateId);
  }

  renderCategories(name, rows, startTime, endTime) {
    const times = { startTime, endTime };
    const categoryHasTime = _.some(
      _.values(times).map(time => time.getTime()),
      _.isFinite
    );

    return rows.length ?
      (
        <div
          key={ `task-template-wrapper-${name}` }
          className='showtime-task-planner-section'
          data-test={ `${name}-section` }
        >
          <div
            className='showtime-task-planner-section-title'
            data-test='task.timeslot'
          >
            <div className='showtime-task-planner-section-title-emphasis'>{ name }</div>
            { categoryHasTime && !this.props.singleLocation && (
              <ToolTip
                key={ `${name}TimesTip` }
                classes={ ['showtime-layout-admin-management__tasks-help', 'hint--bottom'] }
                message={ this.props.intl.formatMessage({ id: 'taskplanner.timeslotTimes' }, times) }
              />
            )}
          </div>
          <div className='showtime-task-planner-list'>
            { rows }
          </div>
        </div>
      ) : null;
  }

  renderCategory = (category) => {
    const categoryTemplates = category.task_templates
      .map(id => _.find(this.props.taskTemplates, {
        id,
      }))
      .filter(task => task != null);

    const renderedTimeslotRows = categoryTemplates.map(this.renderTableRow);

    return this.renderCategories(
      category.name,
      renderedTimeslotRows,
      new Date(null, null, null, category.start.hour, category.start.minute),
      new Date(null, null, null, category.end.hour, category.end.minute)
    );
  };

  render() {
    const emptyStateTitle = this.props.intl.formatMessage({ id: 'taskplanner.empty_state.title' });
    const hasRestrictedTaskAccess = getCurrentUserRole().isPermissionEnabled(
      UserRole.permissionTypes.MANAGE_PERMITTED_TASKS);
    const emptyStateDescription = this.props.intl.formatMessage({ id: 'taskplanner.empty_state.description' }, { hasRestrictedTaskAccess });

    const renderTemplates = this.props.taskTemplates.length || this.props.singleLocation;
    return (
      <div>
        { renderTemplates ?
          this.props.scheduleCategories.map(this.renderCategory)
          : (
            <EmptyState
              messageTitle={ emptyStateTitle }
              messageDescription={ emptyStateDescription }
              iconName='empty-states/planner_empty_state'
            />
          )}
      </div>
    );
  }
}

TaskPlannerTableComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  taskTemplates: PropTypes.array.isRequired,
  scheduleCategories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  taskPlanner: PropTypes.object.isRequired,
  taskPlannerTable: PropTypes.object.isRequired,
  singleLocation: PropTypes.bool.isRequired,
  singleLocationId: PropTypes.number,
  datePeriod: PropTypes.string,
  isLibraryPlanner: PropTypes.bool,
  organizationId: PropTypes.number,
  previewTaskId: PropTypes.number,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    taskPlanner: PropTypes.object,
    taskPlannerTable: PropTypes.object,
  }).isRequired,
};

TaskPlannerTableComponent.defaultProps = {
  singleLocationId: null,
  datePeriod: TrailConstants.datePeriod.TODAY,
  isLibraryPlanner: false,
  organizationId: null,
  previewTaskId: null,
};

const mapStateToProps = state => ({
  taskPlanner: state.base,
  taskPlannerTable: state.table,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    taskPlanner: bindActionCreators(TaskPlannerActions, dispatch),
    taskPlannerTable: bindActionCreators(TaskPlannerTableActions, dispatch),
  },
});

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(TaskPlannerTableComponent);
