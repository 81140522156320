import EventEmitter from 'wolfy87-eventemitter';
import _ from 'lodash';
import { SubTaskConstants, TrailConstants } from '../constants';
import AppDispatcher from '../../application/dispatcher';
import ApplicationConstant from '../../application/constants';

const SubTaskStore = new _.extend({}, EventEmitter.prototype, { // eslint-disable-line new-cap

  _subTasks: {},
  _focusedInputId: null,

  focusSubtaskInput({ subtaskId, taskId }) {
    this._focusedInputId = subtaskId;
    this.emitTaskChange(taskId);
  },

  addPendingSubtask(payload) {
    this._subTasks[payload.temporaryId] = {
      id: payload.temporaryId,
      temporaryId: payload.temporaryId,
      name: payload.value,
      task_id: payload.taskId,
      value: SubTaskConstants.VALUES.UNCHECKED,
      completed: false,
      added_by_user_id: payload.addedByUserId,
      subtask_template_id: payload.subtaskTemplateId,
    };
  },

  addSubtaskFromPusher(payload) {
    const { existingSubtask, addedSubtaskData } = payload;

    if (existingSubtask) { return; }
    this._subTasks[addedSubtaskData.id] = addedSubtaskData;
    this.emitTaskChange(addedSubtaskData.task_id);
  },

  subtaskRemovedFromPusher(payload) {
    const { existingSubtask } = payload;

    if (!existingSubtask) { return; }
    delete this._subTasks[existingSubtask.id];
    this.emitTaskChange(existingSubtask.task_id);
  },

  subtaskCreationSucceeded(payload) {
    const { data } = payload;
    delete this._subTasks[payload.temporaryId];
    this._subTasks[data.id] = data;
  },

  subtaskCreationFailed(payload) {
    delete this._subTasks[payload.temporaryId];
  },

  startDeletingSubtask(payload) {
    const subtask = SubTaskStore.getSubTaskById(payload.subtaskId);
    subtask.pendingDeletion = true;
    this.emitTaskChange(subtask.task_id);
  },

  deletingSubtaskSucceeded(payload) {
    const subtask = SubTaskStore.getSubTaskById(payload.subtaskId);
    delete this._subTasks[subtask.id];
    this.emitTaskChange(subtask.task_id);
  },

  deletingSubtaskFailed(payload) {
    const subtask = SubTaskStore.getSubTaskById(payload.subtaskId);
    subtask.pendingDeletion = false;
    this.emitTaskChange(subtask.task_id);
  },

  getSubTasks(taskId) {
    return _.sortBy(_.filter(this._subTasks || {}, subTask => subTask.task_id === taskId), 'position');
  },

  getAllSubTasks() {
    return this._subTasks;
  },

  setSubTasks(newSubTasks) {
    _.each(newSubTasks, (subTask) => {
      this._subTasks[subTask.id] = subTask;
    });
  },

  getSubTaskById(id) {
    return this._subTasks[id];
  },

  getFocusedSubtaskInput() {
    return this._focusedInputId;
  },

  changeValue(id, payload) {
    this._subTasks[id] = _.extend({}, this._subTasks[id], {
      completed: payload.completed,
      completed_at: payload.completedAt,
      completed_by: payload.completedBy,
      value: payload.value,
    });
  },

  addChangeListener(callback) {
    this.on(ApplicationConstant.CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(ApplicationConstant.CHANGE_EVENT, callback);
  },

  emitChange() {
    this.emit(ApplicationConstant.CHANGE_EVENT);
  },

  emitTaskChange(taskId) {
    this.emit(SubTaskConstants.TASK_CHANGE_EVENT, taskId);
  },
});

AppDispatcher.register((payload) => {
  const action = payload.actionType;
  switch (action) {
    case TrailConstants.UPDATE_TRAIL:
      SubTaskStore.setSubTasks(payload.jsonData.subtasks);
      SubTaskStore.emitChange();
      break;
    case SubTaskConstants.VALUE_CHANGED:
      SubTaskStore.changeValue(payload.id, payload);
      SubTaskStore.emitTaskChange(payload.taskId);
      break;
    case TrailConstants.ADD_CREATED_TIMELINE_TASK:
      SubTaskStore.setSubTasks(payload.subtasks);
      SubTaskStore.emitChange();
      break;
    case SubTaskConstants.ACTION_SUBTASKS_ADDED:
      SubTaskStore.setSubTasks(payload.actionSubtasks);
      SubTaskStore.emitTaskChange(payload.actionTimelineTaskId);
      break;
    case SubTaskConstants.CREATION_STARTED:
      SubTaskStore.addPendingSubtask(payload);
      SubTaskStore.emitTaskChange(payload.taskId);
      break;
    case SubTaskConstants.CREATION_SUCCEEDED:
      SubTaskStore.subtaskCreationSucceeded(payload);
      SubTaskStore.emitTaskChange(payload.taskId);
      break;
    case SubTaskConstants.CREATION_FAILED:
      SubTaskStore.subtaskCreationFailed(payload);
      SubTaskStore.emitTaskChange(payload.taskId);
      break;
    case SubTaskConstants.DELETION_STARTED:
      SubTaskStore.startDeletingSubtask(payload);
      break;
    case SubTaskConstants.DELETION_SUCCEEDED:
      SubTaskStore.deletingSubtaskSucceeded(payload);
      break;
    case SubTaskConstants.DELETION_FAILED:
      SubTaskStore.deletingSubtaskFailed(payload);
      break;
    case SubTaskConstants.SUBTASK_ADDED_FROM_PUSHER:
      SubTaskStore.addSubtaskFromPusher(payload);
      break;
    case SubTaskConstants.SUBTASK_REMOVED_FROM_PUSHER:
      SubTaskStore.subtaskRemovedFromPusher(payload);
      break;
    case SubTaskConstants.FOCUS_SUBTASK_INPUT:
      SubTaskStore.focusSubtaskInput(payload);
      break;
    default:
      // NO-OP
  }
});

export { SubTaskStore };
