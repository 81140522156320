export const VALIDATION_ERROR = 'validation-error';
export const VALIDATION_WARNING = 'validation-warning';

export const analyzeErrors = (allErrorsAndWarnings) => {
  const isValid = allErrorsAndWarnings.length === 0;
  const anyValidationErrors = !isValid &&
    allErrorsAndWarnings.some(e => e.type === VALIDATION_ERROR);
  const allValidationWarnings = !isValid &&
    allErrorsAndWarnings.every(e => e.type === VALIDATION_WARNING);

  return {
    isValid,
    anyValidationErrors,
    allValidationWarnings,
  };
};

export const looksLikeANumber = (num, { whole = false } = {}) => {
  if (!whole) {
    if (num === '-') return true;
    if (num === '.') return true;
  }
  if (num === ' ') return false;
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(num);
};
