import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { TaskReportsSelectors } from '../selectors';
import { TaskReportsActions } from '../actions';
import ListSelection, { Item, Group } from '../../common/ListSelection';

const BulkTaskSelectionList = ({
  taskGroups,
  selectedTaskGroupStartIndex,
  setSelectedTaskIds,
  intl: { formatMessage },
}) => {
  const onTaskGroupSelect = ({ value: startIndex, isSelected }) => {
    const ids = isSelected
      ? _.find(taskGroups, { startIndex }).taskIds
      : [];
    setSelectedTaskIds(ids);
  };
  return (
    <ListSelection showSearch={ false } separateSelected={ false }>
      <Group onSelectChange={ onTaskGroupSelect } id='taskgroups'>
        { taskGroups.map(({ startIndex, endIndex }) => (
          <Item
            key={ startIndex }
            value={ startIndex }
            checked={ startIndex === selectedTaskGroupStartIndex }
            radio
          >
            { formatMessage(
              { id: 'taskReports.selection.bulkOption' },
              { start: startIndex + 1, end: endIndex + 1 }
            )}
          </Item>
        ))
      }
      </Group>
    </ListSelection>
  );
};

BulkTaskSelectionList.propTypes = {
  taskGroups: PropTypes.arrayOf(PropTypes.shape({
    taskIds: PropTypes.arrayOf(PropTypes.number),
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
  })).isRequired,
  selectedTaskGroupStartIndex: PropTypes.number,
  setSelectedTaskIds: PropTypes.func.isRequired,
};

BulkTaskSelectionList.defaultProps = {
  selectedTaskGroupStartIndex: null,
};

const mapStateToProps = (state, ownProps) => ({
  taskGroups: TaskReportsSelectors.bulkSelectionTaskGroupsSelector(state, ownProps),
  selectedTaskGroupStartIndex: TaskReportsSelectors.selectedTaskGroupSelector(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  setSelectedTaskIds: ids => dispatch(TaskReportsActions.setSelectedTaskIds(ids)),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BulkTaskSelectionList);
