import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { SubTaskConstants } from '../../../trail/constants';
import SVGIcon from '../../../common/SVGIcon.jsx';

const {
  UNCHECKED, DONE, NOT_APPLICABLE, NEVER_APPLICABLE, NOT_DONE,
} = SubTaskConstants.VALUES;
export const isIncomplete = value => value === UNCHECKED;

const statusesToIcons = {
  [UNCHECKED]: 'incomplete',
  [DONE]: 'complete',
  [NOT_APPLICABLE]: 'not-applicable',
  [NEVER_APPLICABLE]: 'not-applicable',
  [NOT_DONE]: 'cant-complete',
};

export const SubtaskStatus = ({ value, intl: { formatMessage } }) => (
  <td className='showtime-task-reports__subtask-status'>
    <SVGIcon
      iconName={ `mini/reports/${statusesToIcons[value]}` }
    />
    <span className='showtime-task-reports__subtask-status-text'>
      { formatMessage({ id: `taskReports.taskSubtasks.status.${value}` })}
    </span>
  </td>
);
SubtaskStatus.propTypes = {
  value: PropTypes.oneOf(Object.values(SubTaskConstants.VALUES)).isRequired,
};

export default injectIntl(SubtaskStatus);
