import { RequestActions, RequestConstants } from '../../request';
import { TrailConstants } from '../../trail/constants';
import {
  ACTION_API,
  CREATE_ACTION_KEY,
  GET_EDITING_ACTION_KEY,
  SET_ACTION_DEFAULTS,
  SET_ATTACHMENTS,
  UPDATE_ACTION_KEY,
  GET_ACTION_TEMPLATE_KEY,
  getActionTemplateRoute,
} from '../constants';

import { actionRequestBodySelector } from '../selectors';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { SubTaskActions, TrailActions, WidgetDataActions } from '../../trail/actions';

export const getEditingTaskAction = timelineTaskId => RequestActions.request({
  url: TrailConstants.getTimelineTaskUrl(timelineTaskId, {}),
  key: GET_EDITING_ACTION_KEY,
  method: RequestConstants.GET,
  errorMessage: 'trail.task.action.error.fetch',
});

export const getActionTemplate = (actionTemplateId, fallbackTitle) => RequestActions.request({
  url: getActionTemplateRoute(actionTemplateId),
  key: GET_ACTION_TEMPLATE_KEY,
  method: RequestConstants.GET,
  errorMessage: 'newTask.errors.cannotGetTask',
  content: { fallbackTitle },
});

export const createAction = ({
  actionTemplateId, linkedTimelineTaskId, rowId, fieldName,
}) => async (dispatch, getState) => {
  const actionRequestBody = actionRequestBodySelector(getState(), { actionModal: true });

  const request = await dispatch(RequestActions.request({
    url: ACTION_API,
    key: CREATE_ACTION_KEY,
    method: RequestConstants.POST,
    errorMessage: 'trail.task.action.error.create',
    successMessage: 'trail.task.action.success.create',
    options: {
      body: JSON.stringify({
        actionTemplateId,
        linkedTimelineTaskId,
        rowId,
        fieldName,
        ...actionRequestBody,
      }),
    },
  }));

  if (request.success) {
    dispatch(unsavedChangesActions.cleared());
    TrailActions.addTaskActionToTrail({
      ...request.success.body,
      linkedTimelineTaskId,
    });
    SubTaskActions.addActionSubtasksToTrail(
      request.success.body.action_subtasks,
      request.success.body.action_timeline_task.id
    );
    WidgetDataActions.addActionWidgetToTrail(
      request.success.body.action_widget
    );
  }
  return request;
};

export const updateAction = ({
  actionTemplateId, modalTimelineTaskId, modalTaskTemplateId, linkedTimelineTaskId,
}) => async (dispatch, getState) => {
  const actionRequestBody = actionRequestBodySelector(getState(), { actionModal: true });

  const request = await dispatch(RequestActions.request({
    url: `${ACTION_API}/${modalTaskTemplateId}`,
    key: UPDATE_ACTION_KEY,
    method: RequestConstants.PUT,
    errorMessage: 'trail.task.action.error.update',
    successMessage: 'trail.task.action.success.update',
    options: {
      body: JSON.stringify({
        actionTemplateId,
        modalTimelineTaskId,
        ...actionRequestBody,
      }),
    },
  }));

  if (request.success) {
    dispatch(unsavedChangesActions.cleared());
    TrailActions.updateTaskActionOnTrail({
      ...request.success.body,
      linkedTimelineTaskId,
    });
  }
  return request;
};

export const setAttachments = value => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  return dispatch({ type: SET_ATTACHMENTS, value });
};

export const setDefaultActionValues = values => ({
  type: SET_ACTION_DEFAULTS,
  values,
});
