import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../common/SVGIcon.jsx';

function EmptyState(props) {
  const { baseClass, dataTest } = props;
  return (
    <div className={ `showtime-${baseClass}` } data-test={ dataTest }>
      <div className={ `showtime-${baseClass}__inner` }>
        <SVGIcon
          classes={ `showtime-${baseClass}__icon` }
          iconName={ props.iconName }
        />
        <span className={ `showtime-${baseClass}__title` }>
          {props.messageTitle}
        </span>
        <span className={ `showtime-${baseClass}__description` }>
          {props.messageDescription}
        </span>
        <div className={ `showtime-${baseClass}__action` }>
          {props.children}
        </div>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  iconName: PropTypes.string,
  messageTitle: PropTypes.string,
  messageDescription: PropTypes.string,
  baseClass: PropTypes.string,
  children: PropTypes.array,
  dataTest: PropTypes.string,
};

EmptyState.defaultProps = {
  baseClass: 'empty-state',
  iconName: '',
  messageTitle: '',
  messageDescription: '',
  children: undefined,
  dataTest: undefined,
};

export default EmptyState;
