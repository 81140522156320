import _ from 'lodash';

import { UserRole } from '../../application/UserRole';

const roles = _.get(UserRole, 'prototype.roles', []);

export const getUserRole = ({ userSettings }) => userSettings.role;
export const userHasCustomRoleSelector = (state) => {
  const role = getUserRole(state);
  return Boolean(role) && !Object.values(roles).includes(role);
};

export const getUserRoleHelper = store => new UserRole(getUserRole(store));
