class SubmitButtonDisable {
  constructor(element) {
    this.element = element;
  }

  disable() {
    this.disabled = true;
  }

  set disabled(value) {
    this.buttonTargets.forEach((button) => {
      const list = button.classList;
      const classes = ['is-disabled', 'is-loading'];
      if (value) {
        list.add(...classes);
      } else {
        list.remove(...classes);
      }
    });
  }

  get buttonTargets() {
    return Array.from(this.element.querySelectorAll('button[type=submit]'));
  }
}

const handleSubmit = (event) => {
  const handler = new SubmitButtonDisable(event.target);
  handler.disable();
};

const setup = () => {
  const forms = Array.from(
    document.querySelectorAll('form[data-submit-button-disable]')
  );
  forms.forEach(form => form.addEventListener('submit', handleSubmit));
};

document.addEventListener('DOMContentLoaded', setup);
