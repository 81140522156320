import { MandatoryCommentConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

const updateMandatoryCommentRules = ({ level, mandatoryType, value }) => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: MandatoryCommentConstants.CHANGE,
    mandatoryType,
    level,
    value,
  });
};

export const MandatoryCommentActions = {
  updateMandatoryCommentRules,
};
