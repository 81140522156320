import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M4.5,4.5c1.9-1.9,5.1-1.9,7,0c0.7,0.7,1.2,1.7,1.4,2.7l2-0.3C14.7,5.4,14,4.1,13,3.1c-2.7-2.7-7.1-2.7-9.9,0
         L0.9,0.9L0.2,7.3l6.4-0.7L4.5,4.5z'
    />
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M15.8,8.7L9.4,9.4l2.1,2.1c-1.9,1.9-5.1,1.9-7,0c-0.7-0.7-1.2-1.7-1.4-2.7l-2,0.3
         C1.3,10.6,2,11.9,3,12.9c1.4,1.4,3.1,2,4.9,2c1.8,0,3.6-0.7,4.9-2l2.2,2.2L15.8,8.7z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
