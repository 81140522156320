import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withViewportWidth from '../../../../common/withViewportWidth';
import getStickyOffset from '../../../../common/getStickyOffset';
import OffsetSticky from '../../../../common/OffsetSticky';
import DataCaptureScoreSelectors from '../../../selectors/dataCaptureScoreSelectors';

export const SectionTitle = ({
  children, score, scoreOutOf, stickyOffset, taskHeaderHeight,
}) => (
  <OffsetSticky
    boundaryElement='.showtime-widget-v2__list-fields'
    top={ stickyOffset + taskHeaderHeight }
    stickyClassName='showtime-widget-v2__list-section-title-container--is-stuck'
    parentBorderWidth={ 1 }
  >
    <div className='showtime-widget-v2__list-section-title' data-test='section.title'>
      {children}
      {!!scoreOutOf && (
        <span
          className='showtime-record-log-score showtime-record-log-score--section'
          data-test='score.section'
        >
          {score}/{scoreOutOf}
        </span>
      )}
    </div>
  </OffsetSticky>
);

SectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  scoreOutOf: PropTypes.number.isRequired,
  stickyOffset: PropTypes.number.isRequired,
  taskHeaderHeight: PropTypes.number.isRequired,
};

const mapViewportWidthToProps = width => ({
  stickyOffset: getStickyOffset(width),
});

export default compose(
  withViewportWidth(mapViewportWidthToProps),
  connect(DataCaptureScoreSelectors.sectionScoreSelector)
)(SectionTitle);
