import _ from 'lodash';
import { RequestConstants } from '../../request';
import { LocationConstants } from '../constants';

const initialState = {
  loaded: false,
  data: {
    locations: {},
    groups: {},
  },
};

const formatLocations = rawLocations => _.chain(rawLocations)
  .map(({ active_date: activeDate, ...location }) => ({
    ...location,
    taskCount: location.taskCount || 0,
    lastSeen: location.lastSeen ? new Date(location.lastSeen) : null,
    activeDate: activeDate ? new Date(activeDate) : null,
  }))
  .keyBy('id')
  .value();

const formatGroups = rawGroups => _.keyBy(rawGroups, 'id');

const locationsLoaded = (state, action) => ({
  loaded: true,
  data: {
    locations: formatLocations(action.content.locations),
    groups: formatGroups(action.content.groups),
  },
});

const mergeUpdatedLocation = (locations, updatedLocation, locationWasDeleted) => {
  const { id } = updatedLocation;

  return {
    ..._.omit(locations, id),
    ...(locationWasDeleted ||
      {
        [id]: {
          ...locations[id],
          ...updatedLocation,
        },
      }
    ),
  };
};

const updateLocationGroups = (groups, locations, updatedLocation, locationWasDeleted) => {
  const { id: locationId, timelineTemplateParentId: groupId } = updatedLocation;
  const { timelineTemplateParentId: oldGroupId } = locations[locationId] || {};

  if (oldGroupId === groupId) {
    return groups;
  }

  const updatedGroups = _.cloneDeep(groups);
  updatedGroups[oldGroupId] && _.pull(updatedGroups[oldGroupId].locations, locationId);

  if (locationWasDeleted) {
    return updatedGroups;
  }

  updatedGroups[groupId] && updatedGroups[groupId].locations.push(locationId);

  return updatedGroups;
};

const locationUpdated = (state, action) => {
  const { data: { locations, groups } } = state;
  const { content: updatedLocation } = action;
  const locationWasDeleted = false;

  return {
    ...state,
    data: {
      ...state.data,
      locations: mergeUpdatedLocation(locations, updatedLocation, locationWasDeleted),
      groups: updateLocationGroups(groups, locations, updatedLocation, locationWasDeleted),
    },
  };
};

const locationDeleted = (state, action) => {
  const { data: { locations, groups } } = state;
  const deletedLocation = { id: action.content.deletedLocationId };
  const locationWasDeleted = true;

  return {
    ...state,
    data: {
      ...state.data,
      locations: mergeUpdatedLocation(locations, deletedLocation, locationWasDeleted),
      groups: updateLocationGroups(groups, locations, deletedLocation, locationWasDeleted),
    },
  };
};

export const sitesReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(LocationConstants.GET_LOCATIONS_KEY):
      return locationsLoaded(state, action);
    case RequestConstants.getLoadedActionType(LocationConstants.UPDATE_LOCATION_KEY):
    case RequestConstants.getLoadedActionType(LocationConstants.CREATE_LOCATION_KEY):
      return locationUpdated(state, action);
    case RequestConstants.getLoadedActionType(LocationConstants.DELETE_LOCATION_KEY):
      return locationDeleted(state, action);
    default:
      return state;
  }
};
