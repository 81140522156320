import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';

import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';
import {
  optionBlankError,
  optionDuplicatedError,
} from '../../reducers/builderValidation/constants.js';
import { EMPTY_SCORE } from '../../constants';

import Button from '../../../common/Button';
import SVGIcon from '../../../common/SVGIcon';
import InputField from '../../../common/forms/InputField';
import NumberField from '../../../common/forms/NumberField';
import OptionsDisplaySelection from './OptionsDisplaySelection';
import OptionSettings from './OptionSettings';
import OptionsLogicSelection from './logic/OptionsLogicSelection';

export const SettingsOptions = ({
  column,
  columnIndex,
  onAddOption,
  onRemoveOption,
  onChangeOptionLabel,
  onBlurOptionLabel,
  onChangeOptionScore,
  errors,
  intl,
}) => {
  const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.optionsColumn');

  const numberOfOptions = column.cellSettings.labels.length;

  return (
    <div className='showtime-record-log-builder-field__config'>
      { column.cellSettings.labels.map((option, index) => {
        const optionBlankLabelErrorIdentifier = `${optionBlankError}[${index}]`;
        const optionDuplicatedLabelErrorIdentifier = `${optionDuplicatedError}[${index}]`;

        const optionLabelErrors = [];
        if (errors.includes(optionBlankLabelErrorIdentifier)) {
          optionLabelErrors.push({ type: 'validation-error', message: formatMessage('optionBlankValidation') });
        } else if (errors.includes(optionDuplicatedLabelErrorIdentifier)) {
          optionLabelErrors.push({ type: 'validation-error', message: formatMessage('optionDuplicatedValidation') });
        }

        return (
          <div
            className='showtime-record-log-builder-field__config-row showtime-record-log-builder-field__config-row--option'
            data-test='widget.columns.optionWrapper'
          >
            <span className='showtime-record-log-builder-field__config-row-handle'>
              <SVGIcon
                classes='showtime-icon showtime-icon--size-14'
                iconName='mini/ui/ui-16px-1_drag-CUSTOM-90'
              />
            </span>
            <div className='showtime-record-log-builder-field__input-wrapper'>
              <InputField
                label={ formatMessage('optionHeader') }
                onChange={ value => onChangeOptionLabel(index, value) }
                errors={ optionLabelErrors }
                value={ option }
                onBlur={ value => onBlurOptionLabel(index, value) }
                placeholder={ `${formatMessage('labelPlaceholder')} ${index + 1}` }
                containerClass='showtime-record-log-builder-field__input'
                dataTest='widget.columns.optionLabel'
              />
              <div className='showtime-record-log-builder-field__secondary'>
                { !!_.size(column.cellSettings.scores) && (
                  <div
                    className='hint--info hint--medium hint--bottom'
                    data-hint={ column.cellSettings.scores[index] === EMPTY_SCORE ? formatMessage('scoreNaHint') : '' }
                  >
                    <NumberField
                      label={ formatMessage('scoreHeader') }
                      onChange={ value => onChangeOptionScore(index, value) }
                      key={ option }
                      name='score'
                      defaultValue={ column.cellSettings.scores[index] }
                      containerClass='showtime-record-log-builder-field__input showtime-record-log-builder-field__input--narrow'
                    />
                  </div>
                )}
              </div>
            </div>
            {
              numberOfOptions > 2 && (
                <Button
                  onClick={ () => onRemoveOption(index) }
                  buttonClasses='showtime-button--lowlight-subtle showtime-button--small hint--top-left hint--info'
                  dataTest='widget.columns.removeOption'
                  dataHint={ formatMessage('removeHint') }
                  iconName='/mini/ui/ui-16px-1_simple-remove'
                />
              )
            }
            <OptionSettings columnIndex={ columnIndex } index={ index } />
          </div>
        );
      })}
      <div className='showtime-record-log-builder-field__config-links'>
        <a
          className='showtime-link showtime-link--small showtime-link--highlight'
          onClick={ onAddOption }
          data-test='widget.columns.addOption'
        >
          { formatMessage('addOption') }
        </a>
      </div>
      <OptionsLogicSelection columnIndex={ columnIndex } />
      <OptionsDisplaySelection columnIndex={ columnIndex } />
    </div>
  );
};

SettingsOptions.propTypes = {
  column: PropTypes.shape({
    cellSettings: PropTypes.shape({
      labels: PropTypes.array.isRequired,
      values: PropTypes.array.isRequired,
      scores: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  columnIndex: PropTypes.number.isRequired,
  onAddOption: PropTypes.func.isRequired,
  onChangeOptionLabel: PropTypes.func.isRequired,
  onChangeOptionScore: PropTypes.func.isRequired,
  onChangeOptionValue: PropTypes.func.isRequired,
  onBlurOptionLabel: PropTypes.func.isRequired,
  onRemoveOption: PropTypes.func.isRequired,
  showLogException: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch, { columnIndex, column: { id } }) => {
  const {
    addColumnDropdownOption,
    removeColumnDropdownOption,
    changeColumnDropdownOptionLabel,
    setWidgetPublicSettings,
    validateColumnDropdownOptionLabel,
  } = bindActionCreators(EditWidgetActions, dispatch);

  return {
    onAddOption: () => addColumnDropdownOption(columnIndex),
    onRemoveOption: optionIndex => removeColumnDropdownOption(columnIndex, optionIndex),
    onChangeOptionLabel: (optionIndex, value) => changeColumnDropdownOptionLabel(
      columnIndex,
      optionIndex,
      value
    ),
    onBlurOptionLabel: (optionIndex, value) => validateColumnDropdownOptionLabel(
      id,
      value,
      optionIndex
    ),
    onChangeOptionScore: (optionIndex, value) => {
      value = _.isNaN(value) ? EMPTY_SCORE : value;
      setWidgetPublicSettings(
        `columns[${columnIndex}].cellSettings.scores[${optionIndex}]`, value);
    },
  };
};

export default compose(
  injectIntl,
  connect(null, mapDispatchToProps)
)(SettingsOptions);
