import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router';

import SVGIcon from '../common/SVGIcon';
import { SPREADSHEET_MIME_TYPES, supportedMimeTypes } from '../file/constants';
import { getEmbedSrc, getYoutubeCode } from '../link/embeddedVideo';
import RouterConstants from '../router/constants';
import WindowHelper from '../utils/window';
import AttachmentIcon from './AttachmentIcon';
import { isFile, isImage } from './constants';

import AttachmentDownloader from './AttachmentDownloader';
import AttachmentImage from './AttachmentImage';

export const canPreview = type => _.some(supportedMimeTypes, reg => reg.test(type));

const isSpreadsheetAttachment = mimeType => SPREADSHEET_MIME_TYPES.includes(mimeType);

export const Attachment = ({
  type, mimeType, url, id, openInNewTab, subtitle, isClickable, renderImageOption, handle, title,
}) => {
  const [showDownload, setShowDownload] = useState(false);
  const { search, pathname } = useLocation();
  const { formatMessage } = useIntl();
  const router = useHistory();

  const getPartialFileViewerUrl = () => {
    const prefix = pathname.length > 1 ? pathname : '';
    return `${prefix}${RouterConstants.FILE_VIEWER.replace(':id', handle)}`;
  };

  const canUseFileViewer = () => isFile(type) && canPreview(mimeType);

  const onSelected = () => {
    if (canUseFileViewer()) {
      return router.push({
        pathname: getPartialFileViewerUrl(),
        search,
      });
    }
    if (isFile(type)) {
      if (isSpreadsheetAttachment(mimeType)) {
        // eslint-disable-next-line no-alert
        if (window.confirm(
          formatMessage({ id: 'conversation.comment.spreadsheetWarning' })
        )) {
          setShowDownload(true);
        }
      } else {
        setShowDownload(true);
      }
      return null;
    }
    if (getEmbedSrc(url)) {
      return router.push(RouterConstants.LINK_VIEWER.replace(':id', id));
    }
    if (openInNewTab && url) {
      return WindowHelper.navigateInNewTabTo(url);
    }
    if (url) {
      return WindowHelper.navigateTo(url);
    }
    return null;
  };

  const getLinkSubtitle = () => subtitle || url;

  const renderLinkSubtitle = () => (
    <p className='showtime-attachment__info-path'>
      {getLinkSubtitle()}
      <SVGIcon
        classes='showtime-attachment__info-path-icon'
        iconName='mini/arrows/arrows-16px-4_launch-11'
      />
    </p>
  );

  const renderFileSubtitle = () => {
    if (subtitle) {
      return (
        <p className='showtime-attachment__info-path'>
          { subtitle }
        </p>
      );
    }
    return null;
  };

  const renderSubtitle = () => {
    switch (type) {
      case 'link':
        return renderLinkSubtitle();
      case 'file':
        return renderFileSubtitle();
      default:
        return null;
    }
  };

  const renderImage = () => {
    const WrapperElement = isClickable ? 'a' : 'div';

    return (
      <WrapperElement
        { ...isClickable && { onClick: onSelected } }
        data-test='attachment.image.link'
      >
        <AttachmentImage
          handle={ handle }
          data-test='attachment.image'
          alt='View attachment'
          renderImageOption={ renderImageOption }
        />
      </WrapperElement>
    );
  };

  const renderAttachment = () => {
    const classes = classNames({
      'showtime-attachment': true,
      'is-link': isClickable,
    });

    const WrapperElement = isClickable ? 'a' : 'div';

    return (
      <React.Fragment>
        <WrapperElement
          className={ classes }
          data-test='attachment.file'
          { ...isClickable && { onClick: onSelected } }
        >
          <div className='showtime-attachment__indicator'>
            <AttachmentIcon
              { ...{
                attachmentType: type,
                mimeType,
                youtubeCode: getYoutubeCode(url),
              } }
            />
          </div>
          <div className='showtime-attachment__info'>
            <div className='showtime-attachment__info-title'>
              <span>{title}</span>
            </div>
            { renderSubtitle() }
          </div>
        </WrapperElement>
        { showDownload && <AttachmentDownloader handle={ handle } /> }
      </React.Fragment>
    );
  };

  if (renderImageOption && isImage(mimeType)) {
    return renderImage();
  }

  return renderAttachment();
};

Attachment.propTypes = {
  isClickable: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  openInNewTab: PropTypes.bool,
  mimeType: PropTypes.string,
  type: PropTypes.string.isRequired,
  renderImageOption: PropTypes.string,
  handle: PropTypes.string.isRequired,
  router: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Attachment.defaultProps = {
  title: '',
  subtitle: '',
  url: null,
  id: null,
  openInNewTab: false,
  mimeType: '',
  renderImageOption: null,
  isClickable: false,
};

export default Attachment;
