import TagConstants from '../constants';
import { TagFilterConstants } from '../../trail/constants';
import { RequestActions, RequestConstants } from '../../request';
import { cookieJar } from '../../utils/window';

export const TagActions = {
  fetchOrganizationTags(organizationId, userId) {
    const tagsEnabled = window.current_user.being_shadowed ||
      window.config.features.tags;
    if (!tagsEnabled) {
      return {
        type: TagConstants.ORGANIZATION_TAGS_DISABLED,
      };
    }

    // Warning - contains side effect of getting tag options from cookies
    const tagShell = {
      tags: [],
    };
    const tagCookieKey = TagFilterConstants.USER_COOKIE_KEY(userId);
    const tagCookie = cookieJar.getObject(tagCookieKey) || tagShell;

    return RequestActions.request({
      key: TagConstants.ORGANIZATION_TAGS_LOADED,
      method: RequestConstants.GET,
      url: TagConstants.ORGANIZATION_TAGS_URL(organizationId),
      content: tagCookie,
    });
  },

  addTagToOrganization(organizationId, tag) {
    return RequestActions.request({
      key: TagConstants.ORGANIZATION_TAGS_CREATED,
      method: RequestConstants.POST,
      errorMessage: TagConstants.ORGANIZATION_TAGS_ERROR(tag),
      url: TagConstants.ORGANIZATION_TAGS_URL(organizationId),
      options: {
        body: JSON.stringify({
          tag,
        }),
      },
    });
  },

  deleteTagFromOrganization(organizationId, tagId) {
    return RequestActions.request({
      key: TagConstants.ORGANIZATION_TAGS_DELETED,
      method: RequestConstants.DELETE,
      errorMessage: TagConstants.ORGANIZATION_TAGS_ERROR(tagId),
      url: TagConstants.ORGANIZATION_TAG_URL(organizationId, tagId),
      content: {
        deletedTagId: tagId,
      },
    });
  },

  updateTagFromOrganization(organizationId, tagId, tag) {
    return RequestActions.request({
      key: TagConstants.ORGANIZATION_TAGS_UPDATED,
      method: RequestConstants.PUT,
      errorMessage: TagConstants.ORGANIZATION_TAGS_ERROR(tag),
      url: TagConstants.ORGANIZATION_TAG_URL(organizationId, tagId),
      options: {
        body: JSON.stringify({
          tag,
        }),
      },
      content: {
        tagId,
        label: tag,
      },
    });
  },

  toggleDeleteDialog(tagId) {
    return {
      type: TagConstants.TOGGLE_DELETE_DIALOG,
      content: {
        tagId,
      },
    };
  },

  showNewTagDialog() {
    return {
      type: TagConstants.SHOW_NEW_TAG_DIALOG,
    };
  },

  showTagEditDialog(tagId) {
    return {
      type: TagConstants.SHOW_TAG_EDIT_DIALOG,
      content: {
        tagId,
      },
    };
  },

  validateTag(tagId, labelToValidate) {
    return {
      type: TagConstants.VALIDATE_TAG,
      content: {
        tagId,
        labelToValidate,
      },
    };
  },

  cancelTagEdit(tagId) {
    return {
      type: TagConstants.CANCEL_TAG_EDIT,
      content: {
        tagId,
      },
    };
  },
};
