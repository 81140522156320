import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MysteryDinerQuestion from './MysteryDinerQuestion';

class MysteryDinerQuestionGroup extends Component {
  render() {
    const questions = this.props.questions || [];
    return (
      <table className="legacy-table table-responsive">
        <colgroup>
          <col span="1" className="col-fluid" />
          <col span="1" className="col-xs" />
          <col span="1" className="col-fluid" />
        </colgroup>
        <tbody>
          <tr className='row-subgroup'>
            <td colSpan='3'>
              {this.props.groupName}
            </td>
          </tr>
          {
            questions.map((item, index) =>
              <MysteryDinerQuestion
                key={`${this.props.groupId}.${index}`}
                onChange={ this.props.questionChange }
                groupId={this.props.groupId}
                questionId={index}
                question={item}
              />)
          }
        </tbody>
      </table>
    );
  }
}

MysteryDinerQuestionGroup.propTypes = {
  groupName: PropTypes.string,
  groupId: PropTypes.number,
  questions: PropTypes.array,
  questionChange: PropTypes.func,
};

MysteryDinerQuestionGroup.defaultProps = {
  questions: [],
};

export default MysteryDinerQuestionGroup;
