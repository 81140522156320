import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import { TaskReportsSelectors } from '../selectors';
import SelectedTasksCount from './SelectedTasksCount';

const SelectedTasksSummary = ({ taskCount, maxTaskCount }) => (
  <div className='showtime-task-reports__bulk-export-button-text'>
    <FormattedMessage
      id='taskReports.selection.summary'
      values={ {
        taskCount,
        atMax: taskCount >= maxTaskCount,
        selectedTasksMessage: <SelectedTasksCount />,
      } }
    />
  </div>
);

SelectedTasksSummary.propTypes = {
  selectedTaskCount: PropTypes.number.isRequired,
  taskCount: PropTypes.number.isRequired,
  maxTaskCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  taskCount: TaskReportsSelectors.taskCountSelector(state, ownProps),
  maxTaskCount: TaskReportsSelectors.maxTaskCountSelector(state),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(SelectedTasksSummary);
