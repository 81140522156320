import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import EditableColumn from './EditableColumn';
import ColumnSummary from './ColumnSummary';
import DragHandle from '../../../common/DragHandle';

const Column = ({
  column, isExpanded, onExpand, dragProvided, index, errors, isDragging,
}) => {
  const { mandatory, fieldName } = _.get(column, 'cellSettings', {});
  const containsErrors = errors && errors.length > 0;

  const className = classNames(
    'showtime-record-log-builder-field',
    {
      'showtime-record-log-builder-field--edit': isExpanded,
      'showtime-record-log-builder-field--summary': !isExpanded,
      'showtime-record-log-builder-field--dragging': isDragging,
      'has-error': containsErrors,
    }
  );

  return (
    <div className={ className }>
      <DragHandle dragProvided={ dragProvided } classes={ 'showtime-record-log-builder-field__drag-handle' } />
      { isExpanded
        ? <EditableColumn column={ column } index={ index } errors={ errors } />
        : (
          <ColumnSummary
            cellType={ column.cellType }
            containsErrors={ containsErrors }
            onExpand={ () => onExpand(fieldName) }
            isMandatory={ mandatory }
            dragProvided={ dragProvided }
          >
            {column.columnTitle}
          </ColumnSummary>
        )
      }
    </div>
  );
};

Column.propTypes = {
  column: PropTypes.object.isRequired,
  dragProvided: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
};

Column.defaultProps = {
  isDragging: false,
};

export default Column;
