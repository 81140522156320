import EventEmitter from 'wolfy87-eventemitter';
import _ from 'lodash';
import ApplicationEventsStore from '../../application/store';
import { TaskPositionConstants } from '../constants';

const taskPositionStoreFactory = function () {
  const TaskPositionStore = function () {
    this.taskBodies = [];
    ApplicationEventsStore.addScrollListener(this.emitFooterUpdates.bind(this));
    this.emitFooterUpdates();
  };

  _.extend(TaskPositionStore.prototype, EventEmitter.prototype, {
    taskBodies: [],

    addTask(id) {
      const taskBody = document.getElementById(`task-body-${id}`);
      const footer = document.getElementById(`task-action-bar-${id}`);

      if (!taskBody || !footer) {
        return false;
      }

      const taskBodyPartiallyVisible = this._taskBodyPartiallyVisible(taskBody, footer);

      this.taskBodies.push({
        taskBody,
        footer,
        partiallyVisible: taskBodyPartiallyVisible,
      });

      if (taskBodyPartiallyVisible) {
        this.emit(TaskPositionConstants.TASK_FOOTER_FIXED_CHANGE, taskBody.id, true);
      }

      return true;
    },

    removeTask(id) {
      _.remove(this.taskBodies, taskBodyObject => taskBodyObject.taskBody.id === `task-body-${id}`);
    },

    emitFooterUpdates() {
      let i;
      let taskBodyPartiallyVisible;
      for (i = this.taskBodies.length - 1; i >= 0; i -= 1) {
        taskBodyPartiallyVisible = this._taskBodyPartiallyVisible(
          this.taskBodies[i].taskBody,
          this.taskBodies[i].footer
        );

        if (taskBodyPartiallyVisible && !this.taskBodies[i].partiallyVisible) {
          this.emit(TaskPositionConstants.TASK_FOOTER_FIXED_CHANGE,
            this.taskBodies[i].taskBody.id,
            true);
          this.taskBodies[i].partiallyVisible = true;
        } else if (!taskBodyPartiallyVisible && this.taskBodies[i].partiallyVisible) {
          this.emit(TaskPositionConstants.TASK_FOOTER_FIXED_CHANGE,
            this.taskBodies[i].taskBody.id,
            false);
          this.taskBodies[i].partiallyVisible = false;
        }
      }
    },

    _taskBodyPartiallyVisible(taskBody, footer) {
      const footerHeight = footer.clientHeight;
      const taskBodyPosition = taskBody.getBoundingClientRect();

      return (
        (taskBodyPosition.top + footerHeight) - 10
      ) < window.innerHeight &&
            (
              (taskBodyPosition.top + taskBodyPosition.height + footerHeight) - 10
            ) > window.innerHeight;
    },
  });

  return new TaskPositionStore();
};

const TaskPositionStore = taskPositionStoreFactory();
export { TaskPositionStore };
