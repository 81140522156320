import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import LoadingSpinner from '../../common/LoadingSpinner';
import { TaskReportsSelectors } from '../selectors';
import SubtaskTable from './SubtaskTable';

const SubtaskWrapper = ({ children, expanded }) => (
  <div data-test='taskReports.taskSubtasks'>
    { expanded && children }
  </div>
);
SubtaskWrapper.propTypes = {
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const TaskSubtasks = ({
  subtasks, hasSubtasks, isLoading, viewChecklists, intl: { formatMessage },
}) => {
  if (!hasSubtasks) return null;
  return (
    <SubtaskWrapper expanded={ viewChecklists }>
      { isLoading
        ? (
          <LoadingSpinner
            classes='showtime-loader--padding-reduced'
            label={ formatMessage({ id: 'taskReports.task.fetchingChecklists' }) }
          />
          )
        : <SubtaskTable subtasks={ subtasks } />
      }
    </SubtaskWrapper>
  );
};

TaskSubtasks.propTypes = {
  ...SubtaskTable.propTypes,
  hasSubtasks: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  viewChecklists: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  subtasks: TaskReportsSelectors.taskSubtasksSelector(state, ownProps),
  hasSubtasks: TaskReportsSelectors.taskHasSubtasksSelector(state, ownProps),
  isLoading: TaskReportsSelectors.taskSubtasksAreLoadingSelector(state, ownProps),
  viewChecklists: TaskReportsSelectors.viewChecklistsSelector(state, ownProps),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(TaskSubtasks);
