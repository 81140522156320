import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import { isLoaded } from '../../common/requestStatuses';
import { TaskReportsSelectors } from '../selectors';
import applyFiltersToQuery from '../applyFiltersToQuery';
import { TaskTemplateConstants } from '../../task/constants';
import {
  filterQueryKeys,
  filterTypes,
  HIDDEN_FILTERS_FOR_ACTIONS,
} from '../constants';

const { ACTION, ACTION_TEMPLATE } = TaskTemplateConstants.templateType;

const queryKey = filterQueryKeys[filterTypes.ACTIONS];

const hiddenQueries = HIDDEN_FILTERS_FOR_ACTIONS.reduce((accu, filter) => ({
  ...accu,
  [filterQueryKeys[filter]]: '',
}), {});

const ActionsFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });

  const { formatMessage } = useIntl();
  const history = useHistory();

  useEffect(() => {
    applyFiltersToQuery(
      '', history, hiddenQueries, { sendMetrics: false });
  }, []);

  const actionTemplateItems = useSelector(TaskReportsSelectors.actionTemplatesForDropdownSelector);
  const items = [
    { id: ACTION, name: formatMessage({ id: 'taskReports.actionFilter.adHocActions' }) },
    ...actionTemplateItems,
  ];
  const hasLoaded = isLoaded(
    useSelector(TaskReportsSelectors.taskTemplatesRequestStatusSelector));

  const selectedIdsByActionTemplate = queryIds.includes(ACTION_TEMPLATE)
    ? _.chain(actionTemplateItems).filter({ template_type: ACTION_TEMPLATE }).map('id').value()
    : [];

  const selectedActionIds = [
    ..._.pull([...queryIds], ACTION_TEMPLATE),
    ...selectedIdsByActionTemplate,
  ];

  return (
    <Filter
      { ...props }
      { ...actionTemplateItems.length ? { selectAll: true } : {} }
      { ...{
        setQueryIds, clearQueryIds, items, hasLoaded,
      } }
      filterType={ filterTypes.ACTIONS }
      queryIds={ selectedActionIds }
      exclusionWhenAllSelected
    />
  );
};

ActionsFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default ActionsFilter;
