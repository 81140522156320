import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import Button from './Button';
import Input from './forms/Input';
import Label from './Label';
import CopyToClipboard from './CopyToClipboard';

export const MenuWrapper = ({ children }) => (
  <div
    className='showtime-copy-to-clipboard__value'
    data-test='copyToClipboard'
  >
    { children }
  </div>
);

MenuWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CopyToClipboardValue = ({
  copyHint,
  copiedHint,
  help,
  copyValue,
  onCopy,
}) => {
  const inputRef = useRef();
  return (
    <React.Fragment>
      <div className='showtime-input showtime-input--block showtime-input--button showtime-input--button-right showtime-input--small'>
        <Input
          id='copyToClipboard'
          dataTest='copyToClipboard.value'
          refFn={ inputRef }
          onClick={ () => { inputRef.current.select(); } }
          readOnly
          value={ copyValue }
          onCopy={ onCopy }
        />
        <CopyToClipboard
          value={ copyValue }
          copyHint={ copyHint }
          copiedHint={ copiedHint }
          onCopy={ onCopy }
        />
      </div>
      <Label
        labelClasses='showtime-copy-to-clipboard__value-help'
        text={ help }
      />
    </React.Fragment>
  );
};

CopyToClipboardValue.propTypes = {
  copyHint: PropTypes.string.isRequired,
  copiedHint: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  copyValue: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

CopyToClipboardValue.defaultProps = {
  onCopy: () => {},
};

const CopyToClipboardMenu = ({
  showClipboard,
  onShow,
  buttonLabel,
  copyHint,
  copiedHint,
  help,
  copyValue,
  iconName,
  onCopy,
}) => (
  <div>
    <Button
      dataTest='copyToClipboard.button'
      buttonClasses='showtime-button--small showtime-button--default'
      label={ buttonLabel }
      iconName={ iconName }
      onClick={ onShow }
    />
    {showClipboard && (
      <MenuWrapper>
        <CopyToClipboardValue
          copyHint={ copyHint }
          copiedHint={ copiedHint }
          help={ help }
          copyValue={ copyValue }
          onCopy={ onCopy }
        />
      </MenuWrapper>
    )}
  </div>
);

CopyToClipboardMenu.propTypes = {
  showClipboard: PropTypes.bool,
  onShow: PropTypes.func.isRequired,
  copyHint: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  copiedHint: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  copyValue: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

CopyToClipboardMenu.defaultProps = {
  showClipboard: false,
  onCopy: () => {},
};

export const CopyToClipboardMenuClickOutside = onClickOutside(CopyToClipboardMenu);

const CopyToClipboardMenuWrapper = ({
  buttonLabel,
  copyHint,
  copiedHint,
  help,
  copyValue,
  iconName,
  onCopy,
}) => {
  const [showClipboard, setShowClipboard] = useState(false);
  return (
    <CopyToClipboardMenuClickOutside
      buttonLabel={ buttonLabel }
      copyHint={ copyHint }
      copiedHint={ copiedHint }
      help={ help }
      iconName={ iconName }
      copyValue={ copyValue }
      showClipboard={ showClipboard }
      onShow={ () => { setShowClipboard(!showClipboard); } }
      handleClickOutside={ () => { setShowClipboard(false); } }
      onCopy={ onCopy }
    />
  );
};

CopyToClipboardMenuWrapper.propTypes = {
  copyHint: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  copiedHint: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  copyValue: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

CopyToClipboardMenuWrapper.defaultProps = {
  onCopy: () => {},
};

export default CopyToClipboardMenuWrapper;
