import { REQUEST_FILESTACK_UPLOAD } from './constants';
import { getFilePickerPolicy } from '../../file/selectors';
import { filestackClient } from '../../file/filestackApi';

const isPickerRequested = type => type === REQUEST_FILESTACK_UPLOAD;

const filestackMiddleware = (store) => {
  const requestFilepicker = ({ errorAction, successAction }) => (security) => {
    const onUploadDone = ({ filesUploaded }) => {
      filesUploaded.forEach((file) => {
        const actionToDispatch = {
          ...file,
          ...successAction,
        };
        store.dispatch(actionToDispatch);
      });
    };

    const onFileUploadFailed = () => store.dispatch(errorAction);

    const client = filestackClient({
      security,
      onUploadDone,
      onFileUploadFailed,
    });
    client.open();
  };

  let stalledPickerRequest = null;
  return next => (action) => {
    const resultingStore = next(action);
    const newState = store.getState();
    const authentication = getFilePickerPolicy(newState);
    const isPickPolicyInState = authentication.policy && authentication.signature;

    if (isPickerRequested(action.type)) {
      if (isPickPolicyInState) {
        requestFilepicker(action)(authentication);
      } else {
        stalledPickerRequest = requestFilepicker(action);
      }
    }

    const shouldTriggerStalledUpload = stalledPickerRequest && isPickPolicyInState;
    if (shouldTriggerStalledUpload) {
      stalledPickerRequest(authentication);
      stalledPickerRequest = null;
    }

    return resultingStore;
  };
};

export default filestackMiddleware;
