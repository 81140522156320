import React from 'react';
import PropTypes from 'prop-types';

import Attachment from '../../attachments/Attachment';

export const AttachmentMetadataPreview = ({ ...attachment }) => (
  <div className='showtime-file-preview__attachment'>
    <Attachment
      title={ attachment.name }
      mimeType={ attachment.mimeType }
      url={ attachment.url }
      type={ attachment.type }
      handle={ attachment.filestackHandle }
      isClickable={ false }
      isEditable={ false }
    />
  </div>
);

AttachmentMetadataPreview.propTypes = {
  name: PropTypes.string,
  mimeType: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  handle: PropTypes.string,
};

AttachmentMetadataPreview.defaultProps = {
  name: undefined,
  mimeType: undefined,
  url: undefined,
  type: undefined,
  handle: undefined,
};

export default AttachmentMetadataPreview;
