import Ajv from 'ajv';
import metaSchema from 'ajv/lib/refs/json-schema-draft-04.json';

const ajv = new Ajv({ schemaId: 'id' });

ajv.addMetaSchema(metaSchema);
const defaultErrorFormatter = error => `${error.dataPath} ${error.message}`;
const enumErrorFormatter = error => `${error.dataPath} must be one of \"${error.params.allowedValues.join(', ')}\"`;

const oneOfErrorMessage = 'Unable to determine which schema to use. It may be that some property values are missing or the wrong combination is being used.';
const oneOfErrorFormatter = () => oneOfErrorMessage;

const formatError = (error) => {
  switch (error.keyword) {
    case 'enum':
      return enumErrorFormatter(error);
    case 'oneOf':
      return oneOfErrorFormatter(error);
    default:
      return defaultErrorFormatter(error);
  }
};

export class SchemaValidator {
  constructor(schema) {
    this.ajvValidate = ajv.compile(schema);
  }

  validate = (value) => {
    let json;
    try {
      json = JSON.parse(value);
    } catch (e) {
      return [`This JSON is invalid. ${e.message}`];
    }

    this.ajvValidate(json);

    if (!this.ajvValidate.errors) return null;

    const distinctErrors = this.ajvValidate.errors &&
      [...new Set(this.ajvValidate.errors.map(e => formatError(e)))];

    return distinctErrors.length > 1 ?
      distinctErrors.filter(e => e !== oneOfErrorMessage)
      : distinctErrors;
  };
}

export default schema => new SchemaValidator(schema);
