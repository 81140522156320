export const AddTaskToTrailConstants = {
  TASK_TEMPLATES_FETCHED: 'add_task_to_trail.task_templates_fetched',

  ADD_TASK_STARTED: 'add_task_to_trail.add_task_started',
  ADD_TASK_FAILED: 'add_task_to_trail.add_task_failed',

  ON_DEMAND_TASK_TEMPLATES_ROUTE(locationId) {
    return `/api/timeline_tasks/on_demand/task_templates?location_id=${locationId}`;
  },
  CREATE_ON_DEMAND_TASK_ROUTE(locationId, taskTemplateId) {
    return `/api/timeline_tasks/on_demand?task_template_id=${taskTemplateId
    }&location_id=${locationId}`;
  },
  TRAIL_ROUTE(locationId) {
    return `/trail/${locationId}`;
  },
};
