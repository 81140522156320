import React from 'react';
import PropTypes from 'prop-types';
import Attachment from '../attachments/Attachment';
import { COMMENT_IMAGE_OPTION } from '../file/constants';

const MessageBody = ({
  attachment,
  body,
}) => (
  <div data-test='body' className='showtime-conversation__message-body'>
    {attachment && (
      <div className='showtime-conversation__message-attachment'>
        <Attachment
          title={ attachment.name }
          subtitle={ attachment.fileName }
          handle={ attachment.filestackHandle }
          mimeType={ attachment.mimeType }
          url={ attachment.url }
          type={ attachment.type }
          isClickable
          isEditable={ false }
          data-test='attachment'
          renderImageOption={ COMMENT_IMAGE_OPTION }
          openInNewTab
        />
      </div>
    )}
    { body }
  </div>
);

MessageBody.propTypes = {
  body: PropTypes.string.isRequired,
  attachment: PropTypes.object,
};

MessageBody.defaultProps = {
  attachment: undefined,
};

export default MessageBody;
