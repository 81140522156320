import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';
import { NUMBER } from './Input';

const getInputValue = (value, allowDecimal) => {
  if (Number.isNaN(value)) return '';

  if (allowDecimal) return `${value}`;

  return `${value}`.split('.')[0];
};

const getOutputValue = (value, allowDecimal) => {
  if (Number.isNaN(value)) return value;

  return allowDecimal ? parseFloat(value) : parseInt(value, 10);
};

class NumberField extends Component {
  state = {
    value: getInputValue(this.props.defaultValue, this.props.allowDecimal),
  };

  handleChange = (value) => {
    const { allowDecimal, onChange } = this.props;

    const inputValue = getInputValue(value, allowDecimal);
    this.setState({ value: inputValue });

    const outputValue = getOutputValue(value, allowDecimal);
    onChange(outputValue);
  };

  render() {
    const {
      label, placeholder, errors, dataTest, maxLength,
      containerClass, min, max, name, disabled, refFn, whole, onKeyDown,
    } = this.props;

    return (
      <InputField
        label={ label }
        placeholder={ placeholder }
        value={ this.state.value }
        onChange={ this.handleChange }
        onKeyDown={ onKeyDown }
        errors={ errors }
        type={ NUMBER }
        containerClass={ containerClass }
        dataTest={ dataTest }
        maxLength={ maxLength }
        whole={ whole }
        min={ min }
        max={ max }
        name={ name }
        refFn={ refFn }
        disabled={ disabled }
      />
    );
  }
}

NumberField.propTypes = {
  defaultValue: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  dataTest: PropTypes.string,
  allowDecimal: PropTypes.bool,
  containerClass: PropTypes.string,
  onKeyDown: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  refFn: PropTypes.object,
  whole: PropTypes.bool,
};

NumberField.defaultProps = {
  defaultValue: '',
  label: '',
  placeholder: '',
  errors: [],
  dataTest: undefined,
  allowDecimal: false,
  containerClass: '',
  onKeyDown: null,
  min: undefined,
  max: undefined,
  name: undefined,
  disabled: false,
  maxLength: undefined,
  refFn: null,
  whole: false,
};

export default NumberField;
