import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View } from '@react-pdf/renderer';

import { DATA_TYPE_REGEX } from '../../widget/constants';
import PDFTaskHeader from './PDFTaskHeader';
import PDFTaskSubheader from './PDFTaskSubheader';
import PDFTaskSubtasks from './PDFTaskSubtasks';
import PDFTaskComments from './PDFTaskComments';
import PDFTaskWidget from './PDFTaskWidget';
import { commentableTypes } from '../../taskContentComments/constants';

const PDFTask = ({
  task,
  widget,
  widgetErrors,
  subtasks,
  comments,
  taskContentComments,
  intl,
}) => (
  <View
    is='div'
    style={ {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#E1E1E1',
      borderRadius: 3,
      padding: '10px 15px',
      marginBottom: 15,
    } }
    wrap
  >
    <PDFTaskHeader { ...{ intl, task } } />
    <PDFTaskSubheader { ...{ intl, task } } />
    {
      !_.isEmpty(subtasks) && (
        <PDFTaskSubtasks { ...{
          intl,
          subtasks,
          comments: _.filter(taskContentComments, { commentable_type: commentableTypes.subtask }),
        } }
        />
      )
    }
    {
      !_.isEmpty(comments) && (
        <PDFTaskComments
          taskId={ task.id }
          timeZone={ task.location_time_zone }
          { ...{ comments, intl } }
        />
      )
    }
    {
      !_.isEmpty(widget) && (
        DATA_TYPE_REGEX.dataCapture.test(widget.type) && (
          <PDFTaskWidget
            taskId={ task.id }
            errors={ widgetErrors }
            timeZone={ task.location_time_zone }
            comments={
              _.filter(
                taskContentComments,
                { commentable_id: widget.id, commentable_type: commentableTypes.widget })
            }
            { ...{ widget, intl } }
          />
        )
      )
    }
  </View>
);

PDFTask.propTypes = {
  task: PropTypes.object.isRequired,
  widget: PropTypes.object,
  widgetErrors: PropTypes.array,
  subtasks: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.arrayOf(PropTypes.object),
  taskContentComments: PropTypes.arrayOf(PropTypes.object),
};

PDFTask.defaultProps = {
  widget: {},
  widgetErrors: [],
  subtasks: [],
  comments: [],
  taskContentComments: [],
};

export default PDFTask;
