import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateSelect from '../DateSelect';
import Label from '../Label';

export const DateFilter = ({
  labelText,
  dateFormat,
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
}) => (
  <div className='showtime-scores-controls__filters-item-date'>
    <Label text={ labelText } />
    <DateSelect
      input={
        {
          label: selectedDate.format(dateFormat),
          classNames: 'showtime-button--default showtime-button--block ',
          iconName: 'mini/ui/ui-16px-1_calendar-60',
        }
      }
      dateFormat={ dateFormat }
      dates={ {
        min: minDate,
        max: maxDate,
        selected: selectedDate,
      } }
      utcOffset={ moment().utcOffset() }
      onChange={ onDateChange }
    />
  </div>
);

DateFilter.propTypes = {
  labelText: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(moment().constructor).isRequired,
  onDateChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(moment().constructor),
  maxDate: PropTypes.instanceOf(moment().constructor),
};

DateFilter.defaultProps = {
  minDate: moment({ year: 2019, month: 3, day: 20 }),
  maxDate: moment().subtract(1, 'days').startOf('day'),
};

export default DateFilter;
