import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { EditWidgetActions } from '../actions';
import { TaskWidgetSelectors } from '../../task-wizard/selectors';
import Toggle from '../../common/forms/Toggle';
import NumberField from '../../common/forms/NumberField';
import FormSectionItem from '../../common/forms/FormSectionItem';
import Columns from './Columns';
import { createIntlMessageFormatter } from '../../common/intlMessageFormatter';
import { UserRole, getCurrentUserRole } from '../../application/UserRole';
import ConfirmableButton from '../../common/ConfirmableButton';

export const WidgetSettings = ({
  minimumRows, restrictRows, title, intl, singleRecord,
  onRestrictRowsChange, onMinimumRowsChange, onTitleChange,
  associatedTaskNames, setWidgetAsNew,
}) => {
  const onUnlinkButtonClick = () => {
    setWidgetAsNew();
  };
  const CurrentUserRole = getCurrentUserRole();

  const format = createIntlMessageFormatter(intl, 'editWidget.extraSettings');
  const taskWidgetUnlinkMessage = createIntlMessageFormatter(intl, 'editWidget.unlink');
  const renderWidgetBuilder = window.config.features.recordLogBuilder ||
    CurrentUserRole.isSuperAdminOrBeingShadowedWithAllFeatures();
  const canCopyWidgets = !CurrentUserRole.isPermissionEnabled(
    UserRole.permissionTypes.MANAGE_PERMITTED_TASKS);
  const multipleTaskAssignmentRecordLog = associatedTaskNames.length > 0;
  return (
    <div>
      <FormSectionItem fullWidth paddingTop paddingBottomLarge className='showtime-edit-section__item-title'>
        <div className='showtime-input showtime-input--block'>
          <input
            type='text'
            name='widget-title'
            data-test='widget.title'
            maxLength='255'
            placeholder={ intl.formatMessage({ id: 'editWidget.title.placeholder' }) }
            value={ title }
            onChange={ onTitleChange }
          />
        </div>
        {multipleTaskAssignmentRecordLog && canCopyWidgets && (
          <div data-test='confirmableButton.wrapper'>
            <ConfirmableButton
              buttonLabel={ taskWidgetUnlinkMessage('buttonLabel') }
              buttonClasses='showtime-button showtime-button--lowlight-subtle showtime-button--small showtime-link--menu-item-auto-width showtime-unlink-button'
              dataTest='widgetSettings.unlink'
              title={ taskWidgetUnlinkMessage('title') }
              onConfirm={ onUnlinkButtonClick }
              description={ taskWidgetUnlinkMessage('description') }
              confirm={ taskWidgetUnlinkMessage('confirm') }
              abort={ taskWidgetUnlinkMessage('abort') }
              align='left'
            />
          </div>
        )}
      </FormSectionItem>

      { renderWidgetBuilder && <Columns /> }

      { !singleRecord && (
        <div className='showtime-label showtime-label--subtitle'>
          <span className='showtime-label__text'>
            { format('titleSettings') }
          </span>
        </div>
      )}

      {!singleRecord && (
        <React.Fragment>
          <FormSectionItem>
            <div className='showtime-input showtime-input--narrow'>
              <NumberField
                defaultValue={ minimumRows }
                dataTest='edit_widget.minRows'
                onChange={ onMinimumRowsChange }
                min={ 1 }
                max={ 100 }
              />

              <div className='showtime-label'>
                <span className='showtime-label__primary-text'>{format('minRows.primary')}</span>
                <span className='showtime-label__secondary-text'>{format('minRows.secondary')}</span>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem paddingTop paddingBottom>
            <Toggle
              checked={ restrictRows }
              onChange={ () => onRestrictRowsChange(!restrictRows) }
              primaryLabel={ format('restrictRowsPrimary') }
              secondaryLabel={ format('restrictRowsSecondary') }
              name='restrictRows'
              dataTest='widget.restrictRows'
            />
          </FormSectionItem>
        </React.Fragment>
      )}
    </div>
  );
};

WidgetSettings.propTypes = {
  minimumRows: PropTypes.number,
  restrictRows: PropTypes.bool,
  singleRecord: PropTypes.bool,
  onRestrictRowsChange: PropTypes.func,
  onMinimumRowsChange: PropTypes.func,
  onSingleRecordChange: PropTypes.func,
  intl: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  widgetDataType: PropTypes.string,
  editTaskId: PropTypes.string,
  associatedTaskNames: PropTypes.array,
  setWidgetAsNew: PropTypes.func,
};

WidgetSettings.defaultProps = {
  restrictRows: false,
  singleRecord: false,
  onRestrictRowsChange() {},
  onMinimumRowsChange() {},
  onSingleRecordChange() {},
  minimumRows: undefined,
  widgetDataType: '',
  editTaskId: '',
  associatedTaskNames: [],
  setWidgetAsNew: () => {},
};

const dispatchExtraSettingsActionsToProps = (dispatch) => {
  const { setWidgetPublicSettings } = bindActionCreators(EditWidgetActions, dispatch);
  const { setWidgetAsNew } = bindActionCreators(EditWidgetActions, dispatch);

  const onSingleRecordChange = (value) => {
    setWidgetPublicSettings('singleRecord', value);

    if (value) {
      setWidgetPublicSettings('minimumRows', 1);
    }
  };

  return {
    onRestrictRowsChange: restrictRows => setWidgetPublicSettings('restrictRows', restrictRows),
    onMinimumRowsChange: value => setWidgetPublicSettings('minimumRows', value),
    onTitleChange: e => setWidgetPublicSettings('widgetTitle', e.target.value),
    onSingleRecordChange,
    setWidgetAsNew,
  };
};

const mapStateToProps = (state, ownProps) => {
  const { taskTemplates } = state.base;
  const { editTaskId, widgetDataType } = ownProps;

  const associatedTaskNames = taskTemplates.reduce((accu, { integration, name, id }) => {
    if (integration !== widgetDataType) return accu;
    if (editTaskId && id === Number.parseInt(editTaskId, 10)) return accu;

    return [...accu, name];
  }, []);

  const {
    minimumRows, restrictRows, widgetTitle, singleRecord,
  } = _.get(TaskWidgetSelectors.editedTaskWidgetConfig(state), 'settings.public', {});

  return {
    minimumRows,
    restrictRows,
    title: widgetTitle,
    singleRecord,
    associatedTaskNames,
  };
};

export default compose(
  connect(mapStateToProps, dispatchExtraSettingsActionsToProps),
  injectIntl
)(WidgetSettings);
