import RouterConstants from '../../router/constants';

const reactSiteEditEnabled = () => (window.config.features.reactSiteEdit || false);

export const LocationConstants = {
  STATUSES: {
    demo: 'demo',
    active: 'active',
    inactive: 'inactive',
    setup: 'setup',
    archived: 'archived',
    building: 'building',
  },
  statusNumbers: {
    ACTIVE: 0,
    INACTIVE: 1,
    DEMO: 2,
    BUILDING: 3,
    ARCHIVED: 4,
    SETUP: 5,
  },
  STATUS_DATE_FORMAT: 'DD/MM/YYYY',

  RESET: 'sites.reset',
  LOCATION_CHANGED: 'sites.location_changed',
  SET_LIVE_SITE: '?set-live',

  GET_LOCATIONS_ROUTE: '/api/v1/user/locations',
  GET_NEW_LOCATION_ROUTE: '/api/v1/user/locations/new',
  CREATE_LOCATION_ROUTE: '/api/v1/user/locations',

  GET_LOCATIONS_KEY: 'sites.get_locations',
  GET_NEW_LOCATION_KEY: 'sites.get_new_location',
  GET_LOCATION_KEY: 'sites.get_location',
  CREATE_LOCATION_KEY: 'sites.create_location',
  UPDATE_LOCATION_KEY: 'sites.update_location',
  DELETE_LOCATION_KEY: 'sites.delete_location',

  newLocationRoute(organizationId) {
    return reactSiteEditEnabled() ?
      `/sites#${RouterConstants.NEW_SITE}` :
      `/organizations/${organizationId}/locations/new`;
  },

  editLocationRoute(organizationId, locationId) {
    return reactSiteEditEnabled() ?
      `/sites#${RouterConstants.buildEditSiteUrl(locationId)}` :
      `/organizations/${organizationId}/locations/${locationId}/edit`;
  },

  setLiveSitesRoute(locationId) {
    return `/sites#${RouterConstants.buildEditSiteUrl(locationId)}?set-live`;
  },

  getOrUpdateLocationRoute(locationId) {
    return `/api/v1/user/locations/${locationId}`;
  },

  todaysTrailRoute(id) {
    return `/trail/${id}`;
  },
};
