import { TrailMetricsDirectory } from '../../metrics';

export const TaskPlannerConstants = {
  GET_TASK_PLANNER_KEY: 'task_planner-get_task_templates',
  GET_TASK_PREVIEWS_KEY: 'task_planner-get_task_previews',
  FILTER_BY_NAME: 'task_planner-filter_by_name',
  UPDATE_SELECTED_TASKS: 'task_planner-updated_selected_tasks',
  CLEAR_FILTER: 'task_planner-clear_filter',
  FILTER_BY_TRAIL: 'task_planner-filter_by_trail',
  FILTER_BY_GROUP: 'task_planner-filter_by_group',
  FILTER_BY_TAG: 'task_planner-filter_by_tag',
  SELECT_TRAIL: 'task_planner-select_trail',
  CANCEL_ASSIGNMENT: 'task_planner-cancel_assignment',
  CANCEL_SELECT_TRAIL: 'task_planner-cancel_select_trail',
  SELECT_ALL_TASKS: 'task_planner-select_all_tasks',
  CLEAR_ALL_TASKS: 'task_planner-clear_all_tasks',
  ASSIGN_TO_TRAIL: 'task_planner-assign_to_trail',
  ADD_TAGS_TO_TASKS: 'task_planner-add_tags_to_tasks',
  ADD_TAGS_TO_TASKS_URL: '/api/v1/task_templates/add_tags_to_tasks',
  REMOVE_ASSIGNMENT_SUCCESS_NOTICE: 'task_planner-remove_assignment_success_notice',
  ADD_TAGS_TO_TASKS_SUCCESS_NOTICE: 'task_planner-add_tags_to_tasks_success_notice',
  REMOVE_TAGS_FROM_TASKS_SUCCESS_NOTICE: 'task_planner-remove_tags_from_tasks_success_notice',
  ASSIGN_TO_TRAIL_URL: '/api/v1/task_templates/assign_to_trail',
  CONFIRM_DELETE: 'task_planner-confirm_delete',
  DELETE_TASK_URL: 'api/v1/task_templates/delete_multiple_tasks',
  DELETE_TASK_KEY: 'task_planner-delete_task',
  ARCHIVE_TASK_URL: 'api/v1/task_templates/archive_tasks',
  ARCHIVE_TASK_KEY: 'task_planner-archive_task',
  REMOVE_DELETE_SUCCESS_NOTICE: 'task_planner-remove_delete_success_notice',
  UNASSIGN_FROM_TRAIL: 'task_planner-unassign_from_trail',
  REMOVE_UNASSIGNMENT_SUCCESS_NOTICE: 'task_planner-remove_unassignment_success_notice',
  UNASSIGN_FROM_TRAIL_URL: '/api/v1/task_templates/unassign_from_trail',
  REMOVE_TAGS_FROM_TASKS: 'task_planner-remove_tags_from_tasks',
  REMOVE_TAGS_FROM_TASKS_URL: '/api/v1/task_templates/remove_tags_from_tasks',
  TOGGLE_TASK_EXPANDED: 'task_planner-toggle_task_expanded',
  EXPAND_ALL_TASKS: 'task_planner-expand_all_tasks',
  COLLAPSE_ALL_TASKS: 'task_planner-collapse_all_tasks',
  HIGHLIGHT_TASK_TEMPLATE: 'task_planner-highlight-task-template',
  RESET: 'task_planner-reset',
  buildSingleLocationTaskplannerUrl(id) {
    return `/api/v1/locations/${id}/taskplanner`;
  },
  buildMoveTaskUrl(id, offset) {
    return `/api/v1/task_templates/${id}/move/${offset}`;
  },
  buildGetTaskPlannerUrl(organizationId) {
    return `/api/v1/user/taskplanner${organizationId ? `/${organizationId}` : ''}`;
  },
  metricsDirectoryPage(singleLocation) {
    return singleLocation ?
      TrailMetricsDirectory.page.SearchTasks :
      TrailMetricsDirectory.page.TaskPlanner;
  },
  getTaskPreviewsUrl() {
    return '/api/v1/task_templates/preview/';
  },
};
