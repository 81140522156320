import PropTypes from 'prop-types';
import React, { createRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { EditWidgetActions } from '../../actions';
import { SECTION_TITLE } from '../../data-capture/v2/constants';

import EditableColumnHeader from './EditableColumnHeader';
import EditableColumnSettings from './EditableColumnSettings';

import TextAreaField from '../../../common/forms/TextAreaField';
import SettingsFile from './SettingsFile';
import SettingsFreeText from './SettingsFreeText';
import SettingsNumber from './SettingsNumber';
import SettingsOptions from './SettingsOptions';

const { validateColumnTitle, setColumnTitle } = EditWidgetActions;

const ColumnTitle = ({
  column: { cellType, columnTitle, cellSettings: { fieldName } = {} }, columnIndex, errors,
}) => {
  const fieldLabelInput = createRef();

  useEffect(() => {
    fieldLabelInput.current.focus();
  }, []);

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const onTitleBlur = value => dispatch(validateColumnTitle(fieldName, value));
  const onTitleChange = value => dispatch(setColumnTitle(fieldName, value, columnIndex));

  const missingTitleError = [{
    type: 'validation-error',
    message: formatMessage({ id: 'editWidget.builder.missingTitle' }),
  }];

  return (
    <EditableColumnHeader { ...{ cellType, columnIndex, fieldName } }>
      <TextAreaField
        dataTest='widget.columns.title'
        autoExpand
        rows='1'
        errors={ errors.includes('title') ? missingTitleError : [] }
        refFn={ fieldLabelInput }
        value={ columnTitle }
        onChange={ onTitleChange }
        onBlur={ onTitleBlur }
        placeholder={ formatMessage({ id: 'editWidget.builder.titlePlaceholder' }) }
        containerClass='showtime-record-log-builder-field__input'
      />
    </EditableColumnHeader>
  );
};

ColumnTitle.propTypes = {
  column: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnIndex: PropTypes.number.isRequired,
};

const CELL_TYPE_TO_EDIT_COMPONENT = {
  FREE_TEXT: SettingsFreeText,
  FORMATTED_NUMBER: SettingsNumber,
  OPTIONS: SettingsOptions,
  FILE: SettingsFile,
  SECTION_TITLE: () => null,
};

const EditableColumn = ({ column, index: columnIndex, errors }) => {
  const EditComponent = CELL_TYPE_TO_EDIT_COMPONENT[column.cellType];

  return (
    <React.Fragment>
      <ColumnTitle { ...{ column, columnIndex, errors } } />

      <EditComponent
        { ...{
          column, columnIndex, errors,
        } }
      />

      { column.cellType !== SECTION_TITLE && (
        <EditableColumnSettings
          { ...{
            column, columnIndex,
          } }
        />
      )}

    </React.Fragment>
  );
};

EditableColumn.propTypes = {
  column: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
};

export default EditableColumn;
