import React from 'react';
import PropTypes from 'prop-types';

export const TextArea = ({
  onChange, onFocus, onBlur, commentBoxRef, value, placeholder,
}) => (
  <textarea
    className='showtime-conversation__comment-box-inline-textarea'
    placeholder={ placeholder }
    type='text'
    onChange={ onChange }
    onFocus={ onFocus }
    onBlur={ onBlur }
    value={ value }
    { ...commentBoxRef && { ref: commentBoxRef } }
  />
);

TextArea.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  commentBoxRef: PropTypes.shape({ current: PropTypes.any }),
  placeholder: PropTypes.string,
};

TextArea.defaultProps = {
  onBlur() {},
  onChange() {},
  onFocus() {},
  value: '',
  commentBoxRef: null,
  placeholder: undefined,
};

export default TextArea;
