import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CapturePreview from './CapturePreview';
import CameraUploadStatus from './CameraUploadStatus';
import FileDeleteButton from './FileDeleteButton';
import { AttachmentMetadataPreview } from './AttachmentMetadataPreview';
import { AttachmentConstants } from '../../attachments/constants';

const { State: { SAVED } } = AttachmentConstants;

const AttachmentPreview = ({
  attachment, progress, onUploadRetry, onDelete,
}) => {
  if (!attachment) return null;
  const { status, file, metadata } = attachment;
  if (!file && !metadata) return null;

  const intl = useIntl();
  const handleUploadRetry = () => onUploadRetry(attachment.file);

  return (
    <div data-test='file.preview'>
      <div className='showtime-file-preview'>
        {file ? (
          <React.Fragment>
            <CapturePreview file={ file } blur={ status !== SAVED } />
            <CameraUploadStatus
              { ...{ status, progress, intl } }
              onUploadRetry={ handleUploadRetry }
            />
          </React.Fragment>
        ) : (
          <AttachmentMetadataPreview { ...metadata } />
        )}
        <FileDeleteButton onClick={ onDelete } />
      </div>
    </div>
  );
};

AttachmentPreview.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.object,
    metadata: PropTypes.object,
    status: PropTypes.oneOf(Object.values(AttachmentConstants.State)),
  }),
  progress: PropTypes.number,
  onUploadRetry: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

AttachmentPreview.defaultProps = {
  attachment: null,
  progress: 0,
};

export default AttachmentPreview;
