import _ from 'lodash';
import constants from '../constants';
import fetchHelper from '../../utils/fetch';
import { notice, alert } from '../../flash/actionCreators';

export const request = ({
  key,
  method,
  url,
  content,
  errorMessage,
  successMessage,
  options: fetchOptions = {},
  authenticated = true,
}) => (dispatch) => {
  if (
    !(key && method && url && constants.ALLOWED_METHODS.includes(method))
  ) {
    // eslint-disable-next-line no-console
    return console.error(`You must provide an action key (arbitrary string)
    a method (PUT/POST/GET) and a url to perform a Request`);
  }

  dispatch({
    type: constants.getLoadingActionType(key),
    content: _.extend({}, content),
  });

  return fetchHelper[method.toLowerCase()](
    url,
    (response) => {
      dispatch({
        type: constants.getLoadedActionType(key),
        content: _.extend({}, response.body, content),
      });
      successMessage && dispatch(notice(successMessage));

      return { success: response };
    },
    (error) => {
      dispatch({
        type: constants.getErrorActionType(key),
        content: _.extend({}, error, content),
      });
      errorMessage && dispatch(alert(errorMessage));

      return { error };
    },
    fetchOptions,
    authenticated
  );
};
