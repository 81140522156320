import React from 'react';
import classNames from 'classnames';

import SVGIcon from '../SVGIcon';

const annotationClasses = [
  'showtime-label',
  'showtime-label--block',
  'showtime-label--subtle',
];

const repeatingClasses = classNames(
  ...annotationClasses,
  'showtime-label--repeating',
);

const restrictedClasses = classNames(
  ...annotationClasses,
  'showtime-label--restricted',
);

export const CopyValueToNewLabel = () => (
  <label className={ repeatingClasses }>
    <SVGIcon
      classes='showtime-label__icon'
      iconName='mini/arrows/arrows-16px-e_refresh-20'
    />
    <span className='showtime-label__text'>
      Repeats every time
    </span>
  </label>
);

export const LockedLabel = () => (
  <label className={ restrictedClasses }>
    <SVGIcon
      classes='showtime-label__icon'
      iconName='mini/ui/ui-16px-2_ban'
    />
    <span className='showtime-label__text'>
      Contact your manager to make changes
    </span>
  </label>
);
