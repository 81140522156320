import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../../common/Button';

const CorrectiveActionButton = ({
  onClick,
  hasAction,
  hasCompletedAction,
  fieldName,
  rowId,
}) => {
  const intl = useIntl();

  if (!window.config.features.correctiveActions) return null;

  const handleOnClick = () => onClick({ fieldName, rowId });

  let iconName = 'mini/ui/ui-16px-1_simple-add';
  let label = 'raiseAction';

  if (hasCompletedAction) {
    label = 'actionCompleted';
    iconName = 'mini/ui/ui-16px-1_check-simple';
  } else if (hasAction) {
    label = 'editAction';
    iconName = 'mini/ui/ui-16px-1_pencil';
  }

  return (
    <Button
      small
      link
      noPadding
      noShadow
      marginTop
      iconName={ iconName }
      dataTest='trail.widget.raiseAction'
      blockOffline
      intl={ intl }
      onClick={ handleOnClick }
    >
      { intl.formatMessage({ id: `trail.widgets.data_capture.${label}` }) }
    </Button>
  );
};

CorrectiveActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasAction: PropTypes.bool.isRequired,
  hasCompletedAction: PropTypes.bool.isRequired,
  fieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.number.isRequired,
};

export default CorrectiveActionButton;
