import { HAS_EXCEPTIONAL_OPTION_SELECTED } from '../rules';

export default (ruleConfig, intl) => {
  const rules = ruleConfig.rules || [];
  const exceptionalOptionSelected = rules.some(
    ({ key }) => key === HAS_EXCEPTIONAL_OPTION_SELECTED
  );

  if (exceptionalOptionSelected) return ruleConfig;

  const {
    values,
    optionsExceptions,
    optionsExceptionsWarningTexts,
  } = ruleConfig;

  return {
    ...ruleConfig,
    rules: [
      ...rules,
      {
        key: HAS_EXCEPTIONAL_OPTION_SELECTED,
        message: intl.formatMessage({ id: 'trail.widgets.data_capture.errors.option_exception' }),
        options: { values, optionsExceptions, optionsExceptionsWarningTexts },
      },
    ],
  };
};
