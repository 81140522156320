import React, { memo } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import SVGIcon from '../SVGIcon.jsx';

const FilterSummary = ({
  label, secondaryLabel, onClear, className, iconName, onClick, intl: { formatMessage },
}) => {
  const LabelComponent = onClick ? 'a' : 'div';
  return (
    <div className={ classNames('showtime-filter__summary', className) } data-test='filterSummary'>
      <LabelComponent
        data-hint={ secondaryLabel }
        data-test='filterSummary.label'
        { ...onClick ? { onClick } : {} }
        className={ classNames('showtime-filter__summary-label', {
          'hint--info hint--medium hint--top-right': secondaryLabel,
        }) }
      >
        { iconName && <SVGIcon iconName={ iconName } classes='showtime-icon' /> }
        <div className='showtime-filter__summary-label-primary'>
          { label }
        </div>
        <div className='utility-truncate showtime-filter__summary-label-secondary'>
          { secondaryLabel }
        </div>
      </LabelComponent>
      {onClear && (
        <a
          data-hint={ formatMessage({ id: 'dropdownFilter.remove' }) }
          data-test='filterSummary.removeFilter'
          className='hint--medium hint--bottom-right showtime_filter__remove-tooltip'
          onClick={ onClear }
        >
          <SVGIcon
            classes='showtime-filter__remove-icon'
            iconName='mini/ui/ui-16px-1_simple-remove'
          />
        </a>
      )}
    </div>
  );
};

FilterSummary.propTypes = {
  label: PropTypes.node,
  secondaryLabel: PropTypes.node,
  onClear: PropTypes.func,
  className: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
};

FilterSummary.defaultProps = {
  label: null,
  secondaryLabel: null,
  onClear: null,
  className: '',
  iconName: null,
  onClick: undefined,
};

export default memo(injectIntl(FilterSummary));
