import PropTypes from 'prop-types';
import React from 'react';

import { injectIntl } from 'react-intl';

import ColumnIcon from './ColumnIcon';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

const ColumnSummary = ({
  cellType, children, onExpand, isMandatory, dragProvided, containsErrors, intl,
}) => {
  const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder');

  return (
    <div onClick={ onExpand } className='showtime-record-log-builder-field__header' { ...dragProvided.dragHandleProps }>
      <ColumnIcon cellType={ containsErrors ? 'ERROR' : cellType } />
      <h4 className='showtime-record-log-builder-field__header-title'>
        { containsErrors ? formatMessage('fieldErrors') : children }
        { isMandatory && !containsErrors ?
          <span className='showtime-record-log-builder-field__header-title-required'>*</span>
          : null}
      </h4>
    </div>
  );
};

ColumnSummary.propTypes = {
  cellType: PropTypes.string.isRequired,
  children: PropTypes.node,
  containsErrors: PropTypes.bool.isRequired,
  dragProvided: PropTypes.shape({
    dragHandleProps: PropTypes.object.isRequired,
  }).isRequired,
  isMandatory: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
};

ColumnSummary.defaultProps = {
  isMandatory: undefined,
  children: null,
};

export default injectIntl(ColumnSummary);
