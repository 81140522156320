import _ from 'lodash';
import { requestStatuses } from '../common/requestStatuses';

export const customRolesSelector = state => _.chain(state)
  .get('customRoles.entities', {}).values().sortBy('name')
  .value();

export const customRoleByIdSelector = (state, { id }) => _.get(state, `customRoles.entities.${id}`, null);

export const customRolesLoadedSelector = state => _.get(state, 'customRoles.status') === requestStatuses.LOADED;
