import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TaskReportsSelectors } from '../selectors';

import CancelExportButton from './CancelExportButton';
import BulkTaskSelection from './BulkTaskSelection';
import PreviewPDFButton from './PreviewPDFButton';
import OffsetSticky from '../../common/OffsetSticky';

const ExportModeHeader = ({
  hasTasksToExport, selectedTasksLength, bulkGroupSelected, exportModeRef,
}) => (
  <div className='showtime-task-reports__header-title-container'>
    <OffsetSticky
      parentBorderWidth={ 1 }
      stickyClassName='showtime-task-reports__header-title--stuck-export-mode'
    >
      <div
        className='showtime-task-reports__header-title showtime-task-reports__header-title--export-mode'
        ref={ exportModeRef }
      >
        <BulkTaskSelection />
        <div
          className='showtime-task-reports__export-actions'
        >
          <CancelExportButton />
          {
            hasTasksToExport &&
              <PreviewPDFButton { ...{ selectedTasksLength, bulkGroupSelected } } />
          }
        </div>
      </div>
    </OffsetSticky>
  </div>
);


ExportModeHeader.propTypes = {
  hasTasksToExport: PropTypes.bool,
  exportModeRef: PropTypes.object.isRequired,
  selectedTasksLength: PropTypes.number.isRequired,
  bulkGroupSelected: PropTypes.bool.isRequired,
};

ExportModeHeader.defaultProps = {
  hasTasksToExport: false,
};

const mapStateToProps = (state, ownProps) => {
  const selectedTasksLength = TaskReportsSelectors.selectedTaskIdsSelector(state, ownProps).length;

  return {
    selectedTasksLength,
    hasTasksToExport: selectedTasksLength > 0,
    bulkGroupSelected: TaskReportsSelectors.selectedTaskGroupSelector(state, ownProps) !== null,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ExportModeHeader);
