import 'regenerator-runtime/runtime'; // eslint-disable-line

import moment from 'moment-timezone';

import {
  MESSAGE_CHECK_BUSINESS_DAY_END,
  MESSAGE_CHECK_CURRENT_TIMESLOT,
  OFFLINE_INTERVAL_DURATION,
  MESSAGE_CHECK_ONLINE,
  MESSAGE_OFFLINE,
} from '../../../service-workers/constants';
import { TrailStore } from '../../../trail/store';
import floatificationsStore from '../../../store';
import { actionConstants } from '../../../floatifications/constants';
import safePostMessage from '../helpers/safePostMessage';

export default (client = window) => {
  const trailStore = new TrailStore();

  floatificationsStore.dispatch({
    type: actionConstants.CLEAR_MESSAGES,
  });

  safePostMessage({
    type: MESSAGE_OFFLINE,
  });

  if (client) {
    client.offlineInterval = setInterval(() => {
      const newTimeslotId = trailStore.getCurrentTimeslotId();

      safePostMessage({
        type: MESSAGE_CHECK_CURRENT_TIMESLOT,
        payload: {
          id: newTimeslotId,
        },
      });

      safePostMessage({
        type: MESSAGE_CHECK_BUSINESS_DAY_END,
        payload: {
          time: moment().toString(),
        },
      });

      safePostMessage({
        type: MESSAGE_CHECK_ONLINE,
      });
    }, OFFLINE_INTERVAL_DURATION);
  }
};
