import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../common/Button';
import { RouterConstants, getBaseRoute } from '../router';
import { WindowHistory } from '../utils/window';
import OverlayConstants from './constants';

export const OverlayHeader = ({
  onClose, title, secondaryHeader, headerContent, inline, fullScreen,
}) => (
  <header
    className={ classNames(
      'showtime-overlay-header is-visible',
      { 'grid-block': !inline },
      { 'showtime-overlay-header--inline': inline }) }
  >
    <div
      className={ classNames(
        'showtime-overlay-header-inner showtime-grid-container',
        { 'showtime-grid-container--wide': fullScreen }) }
    >
      <div className='showtime-overlay-header--primary utility-truncate'>
        <div className='showtime-overlay-header-close'>
          <Button
            onClick={ onClose }
            dataTest='overlay-close'
            buttonClasses='showtime-button--small showtime-button--lowlight-alt'
            iconName='mini/ui/ui-16px-1_simple-remove'
          />
        </div>
        <h1 className='showtime-overlay-header-title utility-truncate'>
          { title }
        </h1>
      </div>
      <div className='showtime-overlay-header--secondary'>
        { secondaryHeader }
      </div>
      <div className='showtime-overlay-header-actions'>
        { headerContent }
      </div>
    </div>
  </header>
);

OverlayHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  secondaryHeader: PropTypes.node,
  headerContent: PropTypes.node,
  inline: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

OverlayHeader.defaultProps = {
  title: null,
  secondaryHeader: null,
  headerContent: null,
  inline: false,
  fullScreen: false,
};

class Overlay extends Component {
  componentDidMount() {
    this.onShown();
  }

  componentWillUnmount() {
    this.onHidden();
  }

  onShown() {
    document.addEventListener(OverlayConstants.KEY_UP_EVENT, this.onKeyUp);
    document.body.classList.add('showtime-overlay-active');
  }

  onHidden() {
    document.removeEventListener(OverlayConstants.KEY_UP_EVENT, this.onKeyUp);

    const overlayLength = document.getElementsByClassName('showtime-overlay is-visible').length;
    const isLastVisibleOverlay = overlayLength === 1;
    if (isLastVisibleOverlay) {
      document.body.classList.remove('showtime-overlay-active');
    }
  }

  hide = () => {
    // Remove this when we will roll out react-router everywhere
    if (!WindowHistory) return;
    if (!this.props.skipRouting) {
      const { pathname } = WindowHistory.location;
      if (WindowHistory.action !== 'POP' && this.pathNameIsANestedRoute(pathname)) {
        WindowHistory.goBack();
      } else if (this.props.backPage) {
        window.location.href = this.props.backPage;
      } else {
        const baseRoute = getBaseRoute(pathname);
        WindowHistory.push(baseRoute);
      }
    }
    this.props.onHidden();
    this.onHidden();
  };

  pathNameIsANestedRoute(pathname) {
    const partialPathname = this.partialRoute(pathname);
    const isNested = RouterConstants.NESTED_OVERLAY_ROUTES.indexOf(pathname) !== -1;
    const isPartiallyNested =
      RouterConstants.NESTED_OVERLAY_PARTIAL_ROUTES.indexOf(partialPathname) !== -1;

    return isNested || isPartiallyNested;
  }

  partialRoute(pathname) {
    return pathname.split('/')[1];
  }

  onKeyUp = (event) => {
    if (event.keyCode === OverlayConstants.ESC_KEY_CODE) {
      event.preventDefault();
      this.hide();
    }
  };

  render() {
    const {
      dataTest, title, secondaryHeader, headerContent, fullScreen,
    } = this.props;
    const fullHeight = fullScreen || this.props.fullHeight;

    const overlayContainerClasses = classNames(
      'showtime-overlay is-visible',
      this.props.overlayContainerClasses,
      { 'showtime-overlay--flex': fullScreen || fullHeight }
    );

    const innerContainerClasses = classNames(
      'showtime-overlay__content-container showtime-grid-container',
      {
        'showtime-grid-container--wide': fullScreen,
        'showtime-grid-container--full-height': fullHeight,
      }
    );
    const innerContentClasses = classNames(
      'showtime-overlay__content-inner grid-block vertical',
      {
        'showtime-overlay__content-inner--full-height': fullHeight,
      }
    );

    return (
      <div { ...dataTest && { 'data-test': dataTest } }>
        <section className={ `${this.props.overlayContainerClasses} is-visible showtime-overlay-header-container` }>
          <OverlayHeader
            onClose={ this.hide }
            { ...{
              title, secondaryHeader, headerContent, fullScreen,
            } }
          />
        </section>
        <section className={ overlayContainerClasses }>
          <div className='showtime-overlay__inner'>
            <section className={ innerContainerClasses }>
              <div className={ innerContentClasses }>
                { this.props.children }
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  headerContent: PropTypes.element,
  overlayContainerClasses: PropTypes.string,
  onHidden: PropTypes.func,
  dataTest: PropTypes.string,
  secondaryHeader: PropTypes.node,
  backPage: PropTypes.string,
  fullScreen: PropTypes.bool,
  fullHeight: PropTypes.bool,
  skipRouting: PropTypes.bool,
};

Overlay.defaultProps = {
  title: '',
  headerContent: null,
  overlayContainerClasses: '',
  children: null,
  onHidden() {},
  dataTest: undefined,
  secondaryHeader: null,
  backPage: null,
  fullScreen: false,
  fullHeight: false,
  skipRouting: undefined,
};

export default Overlay;
