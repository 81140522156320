import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import { filterQueryKeys, filterTypes } from '../constants';
import { UserRole } from '../../application/UserRole';
import { fetchCustomRoles } from '../../customRoles/actions';
import { customRolesLoadedSelector, customRolesSelector } from '../../customRoles/selectors';

const queryKey = filterQueryKeys[filterTypes.ROLES];

const { MANAGER } = _.get(UserRole, 'prototype.roles', {});

const RolesFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });
  const { formatMessage } = useIntl();
  const items = [
    { id: MANAGER, name: formatMessage({ id: `taskReports.roleFilter.${MANAGER}` }) },
  ];

  const customRoles = useSelector(customRolesSelector);
  const hasLoaded = useSelector(customRolesLoadedSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCustomRoles());
  }, []);

  return (
    <Filter
      { ...props }
      { ...{ queryIds, setQueryIds, clearQueryIds } }
      items={ items.concat(customRoles) }
      hasLoaded={ hasLoaded }
      showSearch={ false }
      filterType={ filterTypes.ROLES }
      exclusionWhenAllSelected
    />
  );
};

RolesFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default RolesFilter;
