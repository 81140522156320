import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { TaskTitleActions } from '../task-wizard/actions';
import { titleSelector } from '../task-wizard/selectors';
import InputField from '../common/forms/InputField';
import { TITLE_MAXIMUM_LENGTH } from './constants/index.js';

const TaskTitleInput = ({ readOnly }) => {
  const dispatch = useDispatch();
  const value = useSelector(titleSelector);
  const onChange = v => dispatch(TaskTitleActions.change(v));
  const { formatMessage } = useIntl();

  return (
    <InputField
      value={ value }
      disabled={ readOnly }
      containerClass='showtime-task-action__input'
      onChange={ onChange }
      label={ formatMessage({ id: 'taskWizard.taskTitle.title' }) }
      dataTest='action.title'
      maxLength={ TITLE_MAXIMUM_LENGTH.toString() }
      required
      errors={ [] }
    />
  );
};

TaskTitleInput.propTypes = {
  readOnly: PropTypes.bool,
};

TaskTitleInput.defaultProps = {
  readOnly: false,
};

export default TaskTitleInput;
