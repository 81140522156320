import ChecklistSvg from './ChecklistSvg';
import CommentSvg from './CommentSvg';
import FlagSvg from './FlagSvg';
import SiteSvg from './SiteSvg';
import CalendarSvg from './CalendarSvg';
import CheckCircleSvg from './CheckCircleSvg';
import ClockSvg from './ClockSvg';
import RemoveCircleSvg from './RemoveCircleSvg';
import RepeatSvg from './RepeatSvg';
import NotApplicableSvg from './NotApplicableSvg';
import CantCompleteSvg from './CantCompleteSvg';
import ErrorSvg from './ErrorSvg';
import WarningSvg from './WarningSvg';
import LockSvg from './LockSvg';

export {
  ChecklistSvg,
  CommentSvg,
  FlagSvg,
  SiteSvg,
  CalendarSvg,
  CheckCircleSvg,
  ClockSvg,
  RemoveCircleSvg,
  RepeatSvg,
  NotApplicableSvg,
  CantCompleteSvg,
  ErrorSvg,
  WarningSvg,
  LockSvg,
};
