import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import TaskScheduleConstants from './constants';

export class ScheduleText extends Component {
  filterTimeslotsById(id) {
    const castId = id.toString();
    return this.props.timeslots.filter(t => (t.id || '').toString() === castId)[0] || {};
  }

  selectedDays(schedule) {
    const days = schedule.recurrence.on || [];
    if (Array.isArray(days)) {
      return days;
    }
    return Object.keys(days).filter(day => days[day] === true);
  }

  generateDailyTimeslotSummary(opening, closing, timeType, unit, by, startDate, endDate) {
    const startDay = startDate.format('dddd');
    const endDay = endDate.format('dddd');
    const weekCount = Math.floor(endDate.diff(startDate, 'day') / 7) + 1;
    const dayDifference = endDate.diff(startDate, 'day') % 7;

    if (moment(endDate).startOf('day').isAfter(moment(startDate).startOf('day'))) {
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingOngoingTimeslots' },
        {
          opening, closing, by, unit, startDay, endDay, weekCount, dayDifference, timeType,
        });
    } else if (opening === closing) {
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingTimeslot' },
        { closing, by, timeType });
    }

    return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingTimeslots' },
      {
        opening, closing, by, timeType,
      });
  }

  generateWeeklyTimeslotSummary(opening, closing, timeType, unit, by, startDate, endDate) {
    const startDay = startDate.format('dddd');
    const endDay = endDate.format('dddd');
    const weekCount = Math.floor(endDate.diff(startDate, 'day') / 7) + 1;
    const dayDifference = endDate.diff(startDate, 'day') % 7;

    if (startDate.isSame(endDate, 'day')) {
      if (opening === closing) {
        return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingWeeklyTimeslot' },
          {
            closing, by, startDay, timeType,
          });
      }
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingWeeklyTimeslots' },
        {
          opening, closing, by, startDay, timeType,
        });
    }

    return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingOngoingTimeslots' },
      {
        opening, closing, by, unit, startDay, endDay, weekCount, dayDifference,
      });
  }

  generateMonthlyTimeslotSummary(opening, closing, timeType, by, startDate, endDate) {
    const startDayNumber = startDate.format('Do');
    const endDayNumber = endDate.format('Do');

    if (moment(endDate).startOf('day').isAfter(moment(startDate).startOf('day'))) {
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingMonthlyOngoingTimeslots' },
        {
          opening, closing, by, startDayNumber, endDayNumber,
        });
    } else if (opening === closing) {
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingMonthlyTimeslot' },
        {
          closing, by, timeType, startDayNumber,
        });
    }

    return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingMonthlyTimeslots' },
      {
        opening, closing, by, startDayNumber, timeType,
      });
  }

  generateRepeatingWeekSummary(closing, timeType, schedule, by) {
    const week = this.selectedDays(schedule).reduce((previous, current, index) => {
      previous[`day${index}`] = this.props.intl.formatMessage(
        { id: `taskWizard.schedule.weekdays.${current.toLowerCase()}`, defaultMessage: current }
      );
      return previous;
    }, {});

    const summaryOptions = _.assign({}, week, {
      by,
      timeType,
      closing,
      selectedDaysLength: Object.keys(week).length,
    });

    return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.repeatingWeekSummary' },
      summaryOptions);
  }

  generateDoesNotRepeatSummary(opening, closing, timeType, startDate, endDate) {
    const startDay = startDate.format('DD/MM/YYYY');
    const endDay = endDate.format('DD/MM/YYYY');

    if (startDate.isSame(endDate, 'day')) {
      if (opening === closing) {
        return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.doesNotRepeatTimeslot' },
          { closing, endDay, timeType });
      }
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.doesNotRepeatTimeslots' },
        {
          closing, opening, endDay, timeType,
        });
    }

    if (opening === closing) {
      return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.doesNotRepeatDateRangeTimeslot' },
        {
          closing, startDay, endDay, timeType,
        });
    }

    return this.props.intl.formatMessage({ id: 'taskWizard.schedule.summary.doesNotRepeatDateRangeTimeslots' },
      {
        closing, opening, startDay, endDay, timeType,
      });
  }

  generateMultipleSchedulesSummary(schedules) {
    const repeatingCount = schedules.filter(s => s.recurrence.by > 0).length;
    const oneOffCount = schedules.filter(s => s.recurrence.by === 0).length;

    const repeatingMessage = this.props.intl.formatMessage(
      { id: 'taskWizard.schedule.summary.multipleSchedules.repeating' },
      { repeatingCount });
    const oneOffMessage = this.props.intl.formatMessage(
      { id: 'taskWizard.schedule.summary.multipleSchedules.oneOff' },
      { oneOffCount });

    if (repeatingCount === 0) {
      return oneOffMessage;
    } else if (oneOffCount === 0) {
      return repeatingMessage;
    }

    return this.props.intl.formatMessage(
      { id: 'taskWizard.schedule.summary.multipleSchedules.bothSchedules' },
      { repeatingMessage, oneOffMessage });
  }

  getValue(value) {
    return typeof value === 'object' ? value.id : value;
  }

  getTimeslotSummary() {
    const { schedules } = this.props;

    if (schedules.length > 1) {
      return this.generateMultipleSchedulesSummary(schedules);
    }

    const [schedule] = schedules;
    const startValue = this.getValue(schedule.start.value);
    const endValue = this.getValue(schedule.end.value);
    const opening = schedule.start.type === TaskScheduleConstants.TIME
      ? startValue : this.filterTimeslotsById(startValue).name;

    const closing = schedule.end.type === TaskScheduleConstants.TIME
      ? endValue : this.filterTimeslotsById(endValue).name;

    const timeType = this.props.isAllDay ?
      TaskScheduleConstants.ALL_DAY : schedule.end.type;
    const { unit } = schedule.recurrence;
    const { by } = schedule.recurrence;
    const startDate = moment(schedule.start.date);
    const endDate = moment(startDate).add(schedule.totalDays - 1, 'days');

    if (by === 0) {
      return this.generateDoesNotRepeatSummary(opening, closing, timeType, startDate, endDate);
    }

    if (this.selectedDays(schedule).length > 0) {
      return this.generateRepeatingWeekSummary(closing, timeType, schedule, by);
    }

    switch (unit) {
      case TaskScheduleConstants.RECUR_PERIODS.Day: {
        return this.generateDailyTimeslotSummary(
          opening, closing, timeType, unit, by, startDate, endDate);
      }
      case TaskScheduleConstants.RECUR_PERIODS.Week: {
        return this.generateWeeklyTimeslotSummary(
          opening, closing, timeType, unit, by, startDate, endDate);
      }
      case TaskScheduleConstants.RECUR_PERIODS.Month: {
        return this.generateMonthlyTimeslotSummary(
          opening, closing, timeType, by, startDate, endDate);
      }
      default: {
        return this.generateDailyTimeslotSummary(
          opening, closing, timeType, unit, by, startDate, endDate);
      }
    }
  }

  render() {
    return (
      <span className='showtime-task-planner-task__schedule-text'>{ this.getTimeslotSummary() }</span>
    );
  }
}

ScheduleText.propTypes = {
  timeslots: PropTypes.array.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.shape({
      date: PropTypes.date,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      type: PropTypes.string,
    }),
    end: PropTypes.shape({
      date: PropTypes.date,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      type: PropTypes.string,
    }),
    recurrence: PropTypes.shape({
      unit: PropTypes.string,
      by: PropTypes.number,
      on: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
      ]),
    }),
  })).isRequired,
  isAllDay: PropTypes.bool.isRequired,
};


export default injectIntl(ScheduleText);
