import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { threadSelector } from '../../../taskContentComments/selectors';
import { commentableTypes } from '../../../taskContentComments/constants';
import { TaskReportsSelectors } from '../../selectors';
import { TableComments } from '../../../common/Table';
import { TASK_ROUTE_PREFIX } from '../../constants';

const { viewCommentsSelector } = TaskReportsSelectors;

const SubtaskComments = ({
  id, timelineTaskId,
}) => {
  const location = useLocation();
  const viewComments = useSelector(state => viewCommentsSelector(state, { location }));

  const commentLinkingProps = {
    timelineTaskId,
    commentableId: id,
    commentableType: commentableTypes.subtask,
  };
  const comments = useSelector(state => threadSelector(state, commentLinkingProps));

  if (!viewComments) return null;
  return <TableComments comments={ comments } isClickable routePrefix={ TASK_ROUTE_PREFIX } />;
};

SubtaskComments.propTypes = {
  id: PropTypes.number.isRequired,
  timelineTaskId: PropTypes.number.isRequired,
};

export default SubtaskComments;
