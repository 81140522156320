import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { TaskTemplateConstants } from '../task/constants';
import SVGIcon from '../common/SVGIcon';

export class HistoryStatus extends Component {
  formatHistoryString(taskTemplate) {
    const nextInstanceDate = this.getNextInstanceDate(taskTemplate);

    if (this.props.taskTemplate.template_type === TaskTemplateConstants.templateType.ON_DEMAND) {
      return null;
    }
    if (!nextInstanceDate) {
      return null;
    }
    if (nextInstanceDate.isSame(moment(), 'day')) {
      return this.props.intl.formatMessage({ id: 'taskplanner.historyString.taskToday' });
    }

    const formattedDate = nextInstanceDate
      .format(this.props.intl.formatMessage({ id: 'taskplanner.historyString.nextInstanceDateFormat' }));

    return this.props.intl.formatMessage({ id: 'taskplanner.historyString.taskInFuture' }, { date: formattedDate });
  }

  getNextInstanceDate(taskTemplate) {
    if (taskTemplate.todays_instance_id) {
      return moment();
    }

    return taskTemplate.next_instance_date ? moment(taskTemplate.next_instance_date) : null;
  }

  renderHistory(taskTemplate) {
    let historyComponent = null;
    const historyString = this.formatHistoryString(taskTemplate);

    if (historyString) {
      historyComponent = (
        <div className='showtime-task-planner-task__schedule' data-test={ this.props.dataTest }>
          <SVGIcon
            classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
            iconName='mini/ui/ui-16px-1_calendar-60'
          />
          <span className='showtime-task-planner-task__schedule-text'>{ historyString }</span>
        </div>
      );
    }

    return historyComponent;
  }

  render() {
    return this.renderHistory(this.props.taskTemplate);
  }
}

HistoryStatus.propTypes = {
  dataTest: PropTypes.string,
  taskTemplate: PropTypes.object.isRequired,
};

HistoryStatus.defaultProps = {
  dataTest: '',
};

export default injectIntl(HistoryStatus);
