import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';

import OptionException from './OptionException';

const OptionSettings = ({
  columnIndex,
  index,
}) => {
  const optionsExceptions = useSelector(
    state => TaskWidgetSelectors.optionsExceptionsSelector(state, { columnIndex })
  );
  const column = useSelector(
    state => TaskWidgetSelectors.taskWidgetColumnSelector(state, { columnIndex }));
  const {
    requireOptionAction = [],
    requireMandatoryOptionAction = [],
    actionTemplateIds = [],
  } = column.cellSettings;

  const optionsExceptionsWarningTexts = useSelector(
    state => TaskWidgetSelectors.optionsExceptionsWarningTextsSelector(state, { columnIndex })
  );

  const hasException = optionsExceptions[index];
  const warningText = optionsExceptionsWarningTexts[index];

  const dispatch = useDispatch();
  const columnPath = `columns[${columnIndex}]`;

  const defaultedOptionArray = key => (
    column.cellSettings[key]
      ? [...column.cellSettings[key]]
      : new Array(column.cellSettings.labels.length).fill(false)
  );
  const handleOptionChange = (key, changedValue) => {
    const value = defaultedOptionArray(key);
    value[index] = changedValue || !(column.cellSettings[key] || [])[index];
    dispatch(
      EditWidgetActions.setWidgetPublicSettings(`${columnPath}.cellSettings.${key}`, value)
    );
  };

  const handleRequestOptionActionChange = () => {
    handleOptionChange('requireOptionAction');

    const goingToRequireAction = !requireOptionAction[index];
    if (goingToRequireAction && !optionsExceptions[index]) handleOptionChange('optionsExceptions');
  };

  const handleRequireMandatoryActionChange = () => {
    handleOptionChange('requireMandatoryOptionAction');

    const mandatoryActionWillBeRequired = !requireMandatoryOptionAction[index];
    const requestActionNotRequired = !requireOptionAction[index];
    if (mandatoryActionWillBeRequired && requestActionNotRequired) {
      handleRequestOptionActionChange();
    }
  };

  const handleExceptionChange = (hasExceptionChange) => {
    optionsExceptions[index] = hasExceptionChange;
    if (!hasExceptionChange) optionsExceptionsWarningTexts[index] = false;
    dispatch(
      EditWidgetActions.setWidgetPublicSettings(`${columnPath}.cellSettings.optionsExceptions`, optionsExceptions)
    );
    dispatch(
      EditWidgetActions.setWidgetPublicSettings(`${columnPath}.cellSettings.optionsExceptionsWarningTexts`, optionsExceptionsWarningTexts)
    );
  };

  const handleWarningTextChange = (value) => {
    optionsExceptionsWarningTexts[index] = value;
    dispatch(
      EditWidgetActions.setWidgetPublicSettings(`${columnPath}.cellSettings.optionsExceptionsWarningTexts`, optionsExceptionsWarningTexts)
    );
  };

  const handleExceptionActionTemplateIdChange = (actionTemplateId) => {
    const key = 'actionTemplateIds';

    if (actionTemplateId === null) {
      const value = defaultedOptionArray(key);
      value[index] = false;
      dispatch(
        EditWidgetActions.setWidgetPublicSettings(`${columnPath}.cellSettings.${key}`, value)
      );
    } else {
      handleOptionChange(key, actionTemplateId);
    }
  };

  return (
    <OptionException
      index={ index }
      columnPath={ columnPath }
      hasException={ hasException }
      requestAction={ requireOptionAction[index] }
      warningText={ warningText }
      onExceptionChange={ handleExceptionChange }
      onRequestActionChange={ handleRequestOptionActionChange }
      onWarningTextChange={ handleWarningTextChange }
      requireMandatoryAction={ requireMandatoryOptionAction[index] }
      onRequireMandatoryActionChange={ handleRequireMandatoryActionChange }
      actionTemplateId={ actionTemplateIds[index] }
      onActionTemplateIdChange={ handleExceptionActionTemplateIdChange }
    />
  );
};

OptionSettings.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default OptionSettings;
