import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { DateUtils } from 'react-day-picker';

const removeDatesEarlierThan = (dateArray, ignoreDatesBefore) => dateArray.filter(date =>
  !DateUtils.isDayBefore(date, ignoreDatesBefore));

const weekDaysToName = weekDays => ({
  0: weekDays('sunday'),
  1: weekDays('monday'),
  2: weekDays('tuesday'),
  3: weekDays('wednesday'),
  4: weekDays('thursday'),
  5: weekDays('friday'),
  6: weekDays('saturday'),
});

const isSameMonthAndYear = (date1, date2) =>
  date1.getMonth() === date2.getMonth() && date1.getYear() === date2.getYear();

const dateSorter = (date1, date2) => date1 - date2;

const groupConsecutiveDates = dateArray => dateArray.sort(dateSorter).reduce((acc, date) => {
  const group = acc[acc.length - 1];
  const lastInGroup = moment(group[group.length - 1] || date);
  if (moment(date).diff(lastInGroup, 'days') <= 1) {
    group.push(date);
  } else {
    acc.push([date]);
  }
  return acc;
}, [[]]);

class UpcomingClosures extends PureComponent {
  static propTypes = {
    datesLocationIsClosed: PropTypes.array,
    daysTimeslotClosed: PropTypes.array,
    siteName: PropTypes.string.isRequired,
    ignoreDatesBefore: PropTypes.instanceOf(Date),
  };
  static defaultProps = {
    ignoreDatesBefore: new Date(),
    datesLocationIsClosed: [],
    daysTimeslotClosed: [],
  };

  futureDayClosures = null;

  createIntlLookupFn() {
    return (ymlFullKey, replacements) =>
      this.props.intl.formatMessage({ id: ymlFullKey }, replacements);
  }

  lookupFromIntl(key, values) {
    key = `upcomingClosures.${key}`;
    return this.createIntlLookupFn()(key, values);
  }

  anyTimeslotClosures() {
    return this.props.daysTimeslotClosed.length !== 0;
  }

  anyFutureDayClosures() {
    return this.futureDayClosures.length !== 0;
  }

  noClosures() {
    return !this.anyTimeslotClosures() && !this.anyFutureDayClosures();
  }

  renderTimeslotClosuresBullet() {
    const weekDaysMap = weekDaysToName(key => this.createIntlLookupFn()(`taskWizard.schedule.weekdays.${key}`));
    const weekDays = this.props.daysTimeslotClosed.map(closedDay => weekDaysMap[closedDay]).join(', ');
    return (
      <li>{ this.lookupFromIntl('timeslotClosures', { weekDays }) }</li>
    );
  }

  formatSingleDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  formatSingleDateForKey(date) {
    return moment(date).format('YYYYMMDD');
  }

  renderSingleDate(date) {
    return (
      <li key={ this.formatSingleDateForKey(date) }>{ this.formatSingleDate(date) }</li>
    );
  }

  renderDateRange(dateRange) {
    const lastDateInRange = dateRange[dateRange.length - 1];
    if (isSameMonthAndYear(dateRange[0], lastDateInRange)) {
      return (
        <li key={ this.formatSingleDateForKey(dateRange[0]) }>
          { moment(dateRange[0]).format('DD') } - { this.formatSingleDate(lastDateInRange) }
        </li>
      );
    }
    return (
      <li key={ this.formatSingleDateForKey(dateRange[0]) }>
        { this.formatSingleDate(dateRange[0]) } - { this.formatSingleDate(lastDateInRange) }
      </li>
    );
  }

  renderSiteClosuresBullets() {
    const datesOrDateRanges = groupConsecutiveDates(this.futureDayClosures);
    const bullets = [];
    datesOrDateRanges.forEach((dateOrDateRange) => {
      if (dateOrDateRange.length === 1) {
        bullets.push(this.renderSingleDate(dateOrDateRange[0]));
      } else {
        bullets.push(this.renderDateRange(dateOrDateRange));
      }
    });
    return bullets;
  }

  render() {
    let componentBody = null;

    this.futureDayClosures = removeDatesEarlierThan(
      this.props.datesLocationIsClosed,
      this.props.ignoreDatesBefore
    );

    if (this.noClosures()) {
      componentBody = (
        <div className='no-upcoming-closures'>
          { this.lookupFromIntl('noUpcomingClosures', { site: this.props.siteName }) }
        </div>
      );
    } else {
      let timeslotClosuresBullet = null;
      if (this.anyTimeslotClosures()) {
        timeslotClosuresBullet = this.renderTimeslotClosuresBullet();
      }
      let siteClosuresBullets = null;
      if (this.anyFutureDayClosures()) {
        siteClosuresBullets = this.renderSiteClosuresBullets();
      }
      componentBody = (
        <ul>
          { timeslotClosuresBullet }
          { siteClosuresBullets }
        </ul>
      );
    }

    return (
      <div>
        <h3 className='showtime-utility-font-headline-small-loose-uppercase-bold'>
          { this.lookupFromIntl('headline') }
        </h3>
        { componentBody }
      </div>
    );
  }
}

export default injectIntl(UpcomingClosures);
