import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ outerBarClasses, innerBarClasses, percentage }) => (
  <div className={ outerBarClasses }>
    <div
      className={ innerBarClasses }
      style={ { width: `${percentage}%` } }
    />
  </div>
);

ProgressBar.propTypes = {
  outerBarClasses: PropTypes.string,
  innerBarClasses: PropTypes.string,
  percentage: PropTypes.number,
};

ProgressBar.defaultProps = {
  outerBarClasses: 'showtime-progress-bar',
  innerBarClasses: 'showtime-progress-bar__inner',
  percentage: 0,
};

export default ProgressBar;
