import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '../../Button';
import { AnchoredMenu } from '../';
import CloseMenuButton from '../../../navigation/CloseMenuButton';

class MoreMenu extends Component {
  state = { showMenu: false }

  toggleMenu = () => this.setState(prevState => ({ showMenu: !prevState.showMenu }))

  renderMenu = () => {
    const { children } = this.props;
    const onTrigger = action => () => {
      this.toggleMenu();
      action();
    };

    return (
      <AnchoredMenu
        align='left'
        onClickOutside={ this.toggleMenu }
        classes='showtime-menu--fullscreen'
      >
        <ul className='showtime-menu__list'>
          <CloseMenuButton
            onClick={ this.toggleMenu }
          />
          {
            React.Children.toArray(children).map(child => React.cloneElement(child, { onTrigger }))
          }
        </ul>
      </AnchoredMenu>
    );
  }

  render = () => {
    const { intl, saving, dataTest } = this.props;
    const buttonProps = {
      buttonClasses: classNames(
        'showtime-button--default',
        'showtime-button--small',
        'showtime-button--collapse-text',
        {
          'is-disabled': saving,
        }
      ),
      label: intl.formatMessage({ id: 'common.menu.more' }),
      iconName: 'mini/arrows/arrows-16px-1_minimal-down',
      iconPosition: 'right',
      onClick: this.toggleMenu,
      dataTest,
    };

    return (
      <div className='showtime-panel__header-actions-more'>
        <Button { ...buttonProps } />
        { this.state.showMenu && this.renderMenu() }
      </div>
    );
  }
}

MoreMenu.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  saving: PropTypes.bool,
  dataTest: PropTypes.string,
};

MoreMenu.defaultProps = {
  saving: false,
  dataTest: 'menu.more',
};

export default injectIntl(MoreMenu);
