/* eslint-disable react/prop-types */
import { get } from 'lodash/fp';
import React from 'react';
import WindowHelper from '../../window';

import {
  FLOATIFICATION_QUEUE_GETTING_TRAIL_UPDATES,
  FLOATIFICATION_OFFLINE_ID,
  FLOATIFICATION_ONLINE_ID,
  FLOATIFICATION_QUEUE_PROCESSING_ID,
  FLOATIFICATION_QUEUE_PROCESSED_SUCCESS_ID,
  FLOATIFICATION_QUEUE_PROCESSED_FAILED_ID,
  FLOATIFICATION_QUEUE_UPDATED_ID,
  FLOATIFICATION_TIMESLOT_EXPIRED_ID,
  FLOATIFICATION_REFRESH_ID,
  MESSAGE_QUEUE_PROCESSED,
  MESSAGE_QUEUE_PROCESSING,
  MESSAGE_QUEUE_PROCESSING_INITIALISED,
  MESSAGE_QUEUE_BEFORE_UPDATED,
  MESSAGE_QUEUE_UPDATED,
  MESSAGE_TIMESLOT_EXPIRED,
  MESSAGE_AFTER_BUSINESS_DAY_END,
  MESSAGE_TRIGGER_REFRESH,
  MESSAGE_CHECK_ONLINE,
  MESSAGE_OFFLINE_FLOATIFICATION,
  MESSAGE_UPDATE_TRAIL_WITH_OFFLINE_STATE,
  MESSAGE_SET_OFFLINE_STATE,
  MESSAGE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE,
  MESSAGE_HANDLE_ONLINE,
  MESSAGE_POST_METRIC,
  MESSAGE_SET_CHECKED_MULTIPLE_CLIENTS,
  OFFLINE_CHECKED_MULTIPLE_CLIENTS,
  MESSAGE_FETCH_ALL_WIDGETS,
} from '../../../service-workers/constants';
import {
  actionConstants as floatificationActions,
  FLOATIFICATION_PERSIST_DURATION,
  INFO,
  WARNING,
  SUCCESS,
} from '../../../floatifications/constants';
import { OFFLINE_ICON } from '../../../offline/constants';
import floatificationsStore from '../../../store';
import { actionConstants as modalAlertActions } from '../../../modal-alert/constants';
import modalAlertStore from '../../../modal-alert/store';
import { TrailStore } from '../../../trail/store';
import metricsPublisher, { MetricsPublisherConstants, TrailMetricsDirectory } from '../../../metrics';
import getStoreForKey from '../offline-states/getStoreForKey';
import updateTrailWithOfflineState from '../offline-states/updateTrailWithOfflineState';
import updateWidgetStoreWithOfflineState from '../offline-states/updateWidgetStoreWithOfflineState';
import setWindowQueueProcessing from './setWindowQueueProcessing';
import handleOnline from './handleOnline';
import offlineFloatification from './offlineFloatification';
import tryUpdateTrail from './tryUpdateTrail';
import safePostMessage from '../helpers/safePostMessage';
import { WidgetDataActions } from '../../../trail/actions/widgetDataActions';
import requestQueueDeDuper from '../helpers/requestQueueDeDuper';

export default () => ({
  [MESSAGE_QUEUE_PROCESSING_INITIALISED]: () => setWindowQueueProcessing(true),

  [MESSAGE_QUEUE_PROCESSING]: ({ queueLength }) => {
    floatificationsStore.dispatch({
      type: floatificationActions.REMOVE_MESSAGE_BY_ID,
      id: FLOATIFICATION_ONLINE_ID,
    });

    floatificationsStore.dispatch({
      type: floatificationActions.ADD_MESSAGE,
      message: {
        id: FLOATIFICATION_QUEUE_PROCESSING_ID,
        text: `ONLINE - saving ${queueLength} changes...`,
        type: SUCCESS,
        icon: 'mini/ui/ui-16px-1_check-circle-08',
        expandedText: 'We\'re syncing changes you\'ve made offline with the Trail servers.',
      },
    });
  },

  [MESSAGE_QUEUE_PROCESSED]: async ({
    queueLength,
    successfulResponses,
  }) => {
    setWindowQueueProcessing(false);

    floatificationsStore.dispatch({
      type: floatificationActions.ADD_MESSAGE,
      message: {
        id: FLOATIFICATION_QUEUE_GETTING_TRAIL_UPDATES,
        text: 'Getting updates...',
        type: SUCCESS,
      },
    });

    await tryUpdateTrail();
    floatificationsStore.dispatch({
      type: floatificationActions.REMOVE_MESSAGE_BY_ID,
      id: FLOATIFICATION_QUEUE_GETTING_TRAIL_UPDATES,
    });

    floatificationsStore.dispatch({
      type: floatificationActions.REMOVE_MESSAGE_BY_ID,
      id: FLOATIFICATION_QUEUE_PROCESSING_ID,
    });

    if (successfulResponses) {
      floatificationsStore.dispatch({
        type: floatificationActions.ADD_MESSAGE,
        message: {
          dismissible: true,
          id: FLOATIFICATION_QUEUE_PROCESSED_SUCCESS_ID,
          text: `${successfulResponses} changes successfully saved!`,
          type: SUCCESS,
          icon: 'mini/ui/ui-16px-1_check-circle-08',
        },
      });
    }

    if (queueLength !== successfulResponses) {
      floatificationsStore.dispatch({
        type: floatificationActions.ADD_MESSAGE,
        message: {
          dismissible: true,
          id: FLOATIFICATION_QUEUE_PROCESSED_FAILED_ID,
          text: `Failed to save ${queueLength - successfulResponses} changes`,
          type: WARNING,
          icon: 'mini/ui/ui-16px-2_alert-circle-error',
        },
      });
    }

    setTimeout(() => {
      floatificationsStore.dispatch({
        type: floatificationActions.REMOVE_MESSAGE_BY_ID,
        id: FLOATIFICATION_QUEUE_PROCESSED_SUCCESS_ID,
      });

      floatificationsStore.dispatch({
        type: floatificationActions.REMOVE_MESSAGE_BY_ID,
        id: FLOATIFICATION_QUEUE_PROCESSED_FAILED_ID,
      });
    }, FLOATIFICATION_PERSIST_DURATION);
  },

  [MESSAGE_QUEUE_BEFORE_UPDATED]: ({
    request,
    queue,
  }, serviceWorkerResponder) => {
    const deDupedQueue = requestQueueDeDuper(request, queue);
    serviceWorkerResponder.postMessage(deDupedQueue);
  },

  [MESSAGE_QUEUE_UPDATED]: ({
    queueLength,
    storeKey,
  }) => {
    floatificationsStore.dispatch({
      type: floatificationActions.REMOVE_MESSAGE_BY_ID,
      id: FLOATIFICATION_OFFLINE_ID,
    });

    if (queueLength > 0) {
      floatificationsStore.dispatch({
        type: floatificationActions.ADD_MESSAGE,
        message: {
          id: FLOATIFICATION_QUEUE_UPDATED_ID,
          text: `OFFLINE - ${queueLength} unsaved changes`,
          type: INFO,
          icon: 'mini/ui/ui-16px-1_offline-CUSTOM',
          expandedText: 'You can still enter most data in offline mode. Your changes will be synced when you reconnect.',
        },
      });
    } else {
      floatificationsStore.dispatch({
        type: floatificationActions.CLEAR_MESSAGES,
      });
    }

    if (storeKey) {
      setTimeout(() => {
        const trailStore = new TrailStore();
        const storeForKey = getStoreForKey(storeKey)();

        safePostMessage({
          type: MESSAGE_SET_OFFLINE_STATE,
          payload: {
            trailKey: `${trailStore.getLocationId()}-${trailStore.getDate()}`,
            storeKey,
            store: JSON.stringify(storeForKey),
          },
        });
      });
    }
  },

  [MESSAGE_TIMESLOT_EXPIRED]: () => {
    floatificationsStore.dispatch({
      type: floatificationActions.ADD_MESSAGE,
      message: {
        dismissible: true,
        id: FLOATIFICATION_TIMESLOT_EXPIRED_ID,
        text: 'Reconnect for latest updates',
        type: WARNING,
      },
    });
  },

  [MESSAGE_AFTER_BUSINESS_DAY_END]: () => {
    modalAlertStore.dispatch({
      type: modalAlertActions.SET_CONTENT,
      content: {
        heading: 'Reconnect to internet',
        body: (
          <p>You need to reconnect to the internet to get the latest tasks to continue</p>
        ),
        type: WARNING,
        icon: OFFLINE_ICON,
      },
    });

    modalAlertStore.dispatch({
      type: modalAlertActions.SHOW,
    });
  },

  [MESSAGE_TRIGGER_REFRESH]: () => {
    floatificationsStore.dispatch({
      type: floatificationActions.ADD_MESSAGE,
      message: {
        action: WindowHelper.refreshPage,
        id: FLOATIFICATION_REFRESH_ID,
        text: 'Click to reload and see updates',
        type: WARNING,
      },
    });
  },

  [MESSAGE_CHECK_ONLINE]: () => {
    if (navigator.onLine) {
      handleOnline();
    }
  },

  [MESSAGE_HANDLE_ONLINE]: () => {
    handleOnline();
  },

  [MESSAGE_OFFLINE_FLOATIFICATION]: ({ requestQueueLength }) => offlineFloatification(
    requestQueueLength
  ),

  [MESSAGE_UPDATE_TRAIL_WITH_OFFLINE_STATE]: ({
    jsonData,
    statesStore,
    trailKey,
    options,
  }) => updateTrailWithOfflineState(trailKey, jsonData, statesStore, options),

  [MESSAGE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE]:
    ({ stateStore: { widget_data: widgetData } }) => (
      widgetData
        ? updateWidgetStoreWithOfflineState(widgetData)
        : null
    ),

  [MESSAGE_POST_METRIC]: ({
    keyPath,
    properties,
  }) => {
    metricsPublisher.recordMetric(
      get(keyPath, TrailMetricsDirectory),
      properties,
      [MetricsPublisherConstants.SEGMENT]
    );
  },

  [MESSAGE_SET_CHECKED_MULTIPLE_CLIENTS]: (count, _serviceWorkerResponder, client = window) => {
    client[OFFLINE_CHECKED_MULTIPLE_CLIENTS] = count;
  },

  [MESSAGE_FETCH_ALL_WIDGETS]: () => WidgetDataActions.fetchAllWidgets(),
});
