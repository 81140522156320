import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';

import applyFiltersToQuery, { getIdsAndAllFromQueryValue, getValueFromQuery } from '../applyFiltersToQuery';

const useIdsQuery = (queryKey, { sendMetrics = false, filterMetric } = {}) => {
  const { search: queryString } = useLocation();
  const queryValue = getValueFromQuery(queryString, queryKey);
  const queryIds = useMemo(() => getIdsAndAllFromQueryValue(queryValue), [queryValue]);

  const history = useHistory();
  const setQueryIds = ids => applyFiltersToQuery(filterMetric, history, { [queryKey]: ids.join(',') }, { sendMetrics });
  const clearQueryIds = () => applyFiltersToQuery(filterMetric, history, { [queryKey]: '' }, { sendMetrics });
  return { queryIds, setQueryIds, clearQueryIds };
};

export default useIdsQuery;
