import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const initialState = {
  applyOverlayStyling: false,
};

export const FORM_ITEM_FULL_WIDTH_CLASSNAME = 'showtime-form-section__item showtime-form-section__item--full-width';
export const OVERFLOW_FADE_CLASSNAME = 'showtime-form-section--fixed-height-overflow';
export const OVERFLOW_SCROLL_CLASSNAME = 'showtime-form-section__item--fixed-height-overflow';

export class ConditionalFadeScroll extends Component {
  state = initialState;

  scrollerElement = null;

  scrollerRef = (e) => { this.scrollerElement = e; };

  shouldElementOverflow = () => {
    const { clientHeight, scrollHeight } = this.scrollerElement || {};
    return scrollHeight > clientHeight;
  }

  scrolledToBottom = () => {
    const { scrollTop, clientHeight, scrollHeight } = this.scrollerElement;
    const scrollPosition = scrollTop + clientHeight;
    return scrollPosition === scrollHeight;
  }

  handleContentChange = (state) => {
    if (this.scrollerElement) {
      this.scrollerElement.onscroll = () => {
        this.setState({ applyOverlayStyling: !this.scrolledToBottom() });
      };
    }
    const applyOverlayStyling = this.shouldElementOverflow();
    if (state.applyOverlayStyling === applyOverlayStyling) {
      return;
    }
    this.setState({ applyOverlayStyling }); // eslint-disable-line react/no-did-update-set-state
  }

  componentDidMount() {
    this.handleContentChange(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleContentChange(prevState);
  }
  render() {
    return (
      <div
        data-test={ this.props.dataTest }
        className={
          classNames(
            FORM_ITEM_FULL_WIDTH_CLASSNAME,
            { [OVERFLOW_FADE_CLASSNAME]: this.state.applyOverlayStyling }
          )
        }
      >
        <div ref={ this.scrollerRef } className={ OVERFLOW_SCROLL_CLASSNAME }>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ConditionalFadeScroll.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
};

ConditionalFadeScroll.defaultProps = {
  children: null,
  dataTest: null,
};
