import _ from 'lodash';
import { TrailConstants } from '../trail/constants';
import { RequestConstants } from '../request';
import { sendTaskContentCommentKey, commentAdded, commentableTypes } from './constants';
import { FETCH_TASK_REPORT_CONTENTS_KEY } from '../taskReports/constants';
import { TaskHistoryConstants } from '../task-history/constants';
import { DATA_CAPTURE_ACTION_TYPES } from '../widget/constants';

const { CLEAR_SKIPPED_FIELD_COMMENTS } = DATA_CAPTURE_ACTION_TYPES;

const commentSubmitted = RequestConstants.getLoadingActionType(sendTaskContentCommentKey);
const commentSubmitSuccess = RequestConstants.getLoadedActionType(sendTaskContentCommentKey);
const commentSubmitFailed = RequestConstants.getErrorActionType(sendTaskContentCommentKey);
const taskReportsContentCommentsFetched = RequestConstants.getLoadedActionType(
  FETCH_TASK_REPORT_CONTENTS_KEY
);
const taskHistoryContentCommentsFetched = RequestConstants.getLoadedActionType(
  TaskHistoryConstants.GET_LAST_INSTANCES_KEY);

const loadTaskContentComments = (state, { content: { tasks = [] } = {} }) => tasks
  .reduce((accu, { id, task_content_comments: taskContentComments = [] }) => ({
    ...accu,
    [id]: taskContentComments,
  }), { ...state });

const addComment = (state, { content }) => ({
  ...state,
  [content.timeline_task_id]: [
    ...(state[content.timeline_task_id] || []),
    content,
  ],
});

const filterSkippedFieldComments = (widgetId, rowId, skippedFields) => (comment) => {
  const commentForSelectedWidget = comment.commentable_id === widgetId &&
    comment.commentable_type === commentableTypes.widget;

  if (!commentForSelectedWidget) {
    return true;
  }

  const commentForSkippedField = comment.linking_metadata.widgetRowId === rowId &&
    skippedFields.includes(comment.linking_metadata.widgetFieldName);

  return !commentForSkippedField;
};

const actionCases = {
  [TrailConstants.TRAIL_FETCH_SUCCESS]: loadTaskContentComments,
  [taskHistoryContentCommentsFetched]: loadTaskContentComments,
  [taskReportsContentCommentsFetched]: (state, { content: { data } }) => ({
    ...state,
    ..._.reduce(
      data, (accu, val, key) => ({ ...accu, [key]: val.task_content_comments || [] }), {}
    ),
  }),
  [commentAdded]: addComment,
  [commentSubmitted]: addComment,
  [commentSubmitSuccess]: (state, { content }) => ({
    ...state,
    [content.timeline_task_id]: (state[content.timeline_task_id] || [])
      .map(cm => cm.temporaryId === content.temporaryId ? content : cm),
  }),
  [commentSubmitFailed]: (state, { content }) => ({
    ...state,
    [content.timeline_task_id]: (state[content.timeline_task_id] || [])
      .filter(cm => cm.temporaryId !== content.temporaryId),
  }),
  [CLEAR_SKIPPED_FIELD_COMMENTS]: (state, {
    taskId, widgetId, rowId, skippedFields,
  }) => ({
    ...state,
    [taskId]: (state[taskId] || []).filter(
      filterSkippedFieldComments(widgetId, rowId, skippedFields)
    ),
  }),
};

export const taskContentComments = (state = {}, action = {}) => {
  if (actionCases[action.type]) return actionCases[action.type](state, action);
  return state;
};
