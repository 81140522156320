import React, { PureComponent } from 'react';

export default (Element) => {
  class WithPureComponent extends PureComponent {
    render() {
      if (!Element) return null;

      return <Element { ...this.props } />;
    }
  }
  const elementName = Element.displayName || Element.name || 'Component';
  WithPureComponent.displayName = `withPureComponent(${elementName})`;
  return WithPureComponent;
};
