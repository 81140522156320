import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import EmptyState from '../common/EmptyState';
import LoadingSpinner from '../common/LoadingSpinner';
import {
  isModalLoadingSelector, hasModalTaskLoadingErrorSelector, isValidActionTemplateSelector,
} from './selectors';
import SubmitButton from './SubmitButton';
import TaskActionScheduler from './TaskActionScheduler';
import TaskDescriptionAndFilesTextArea from './TaskDescriptionAndFilesTextArea';
import TaskTitleInput from './TaskTitleInput';

const ActionModalContent = ({
  taskId,
  rowId,
  fieldName,
  onSubmitSuccess,
  showAsOverlay,
  modalTaskTemplateId,
  modalTimelineTaskId,
  actionTemplateId,
}) => {
  const { formatMessage } = useIntl();

  const hasError = useSelector(hasModalTaskLoadingErrorSelector);
  const isLoading = useSelector(isModalLoadingSelector);
  const actionTemplateIsValid = useSelector(isValidActionTemplateSelector);

  if (hasError) {
    return (
      <div className='showtime-task-action__content'>
        <EmptyState
          dataTest='action-modal-error'
          iconName='mini/ui/ui-16px-3_alert'
          messageTitle={ formatMessage({ id: 'trail.task.action.error.load' }) }
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='showtime-task-action__content'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className='showtime-task-action__content'>
      <TaskTitleInput readOnly={ !!actionTemplateIsValid } />
      <TaskDescriptionAndFilesTextArea readOnly={ !!actionTemplateIsValid } />
      <TaskActionScheduler inAnchoredMenu={ !showAsOverlay } />
      <SubmitButton
        { ...{
          linkedTimelineTaskId: taskId,
          rowId,
          fieldName,
          modalTaskTemplateId,
          modalTimelineTaskId,
          actionTemplateId: actionTemplateIsValid ? actionTemplateId : null,
        } }
        onSubmitSuccess={ onSubmitSuccess }
      />
    </div>
  );
};

ActionModalContent.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  rowId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  showAsOverlay: PropTypes.bool.isRequired,
  modalTaskTemplateId: PropTypes.number,
  modalTimelineTaskId: PropTypes.number,
  actionTemplateId: PropTypes.number,
};

ActionModalContent.defaultProps = {
  modalTaskTemplateId: null,
  modalTimelineTaskId: null,
  actionTemplateId: null,
};

export default ActionModalContent;
