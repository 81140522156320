import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { getErrorSvg } from './utils.jsx';
import PDFTaskContentComments from './PDFTaskContentComments';

const cellStyles = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  flexWrap: 'wrap',
};

const PDFWidgetFieldCell = ({
  intl,
  errorType,
  fieldColor,
  children,
  isFinalField,
  comments,
}) => (
  <View
    is='div'
    style={
     {
       ...cellStyles,
       ...isFinalField ? {} : { paddingRight: 10 },
     }
    }
  >
    <View is='div' style={ { color: fieldColor } }>
      { errorType && getErrorSvg(errorType, fieldColor) }
      <Text is='span'>
        {
          children ||
            intl.formatMessage({ id: 'taskReports.export.PDF.widget.emptyField' })
        }
      </Text>
    </View>
    <PDFTaskContentComments
      comments={ comments }
      showSignature={ false }
    />
  </View>
);

PDFWidgetFieldCell.propTypes = {
  errorType: PropTypes.string,
  fieldColor: PropTypes.string,
  children: PropTypes.node,
  isFinalField: PropTypes.bool.isRequired,
  comments: PropTypes.array,
};

PDFWidgetFieldCell.defaultProps = {
  errorType: null,
  comments: [],
  fieldColor: null,
  children: null,
};

export default PDFWidgetFieldCell;
