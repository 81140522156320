import React from 'react';
import PropTypes from 'prop-types';

import { AttachmentConstants } from '../../attachments/constants';
import OfflineInteractionBlocker from '../../offline-interaction-blocker';
import { TOP } from '../../interaction-blocker/positions';
import { SMALL } from '../../interaction-blocker/sizes';

const { State: { UPLOADING, FAILED } } = AttachmentConstants;

export const CameraUploadFailed = ({ intl, onUploadRetry }) => (
  <div className='showtime-file-preview__error'>
    <OfflineInteractionBlocker
      tooltipMessage={ intl.formatMessage({ id: 'offline.notAvailable' }) }
      size={ SMALL }
      position={ TOP }
    >
      <a
        onClick={ onUploadRetry }
        href='javascript:void(0);'
        data-test='attachment.upload.retry'
      >
        <span className='showtime-button showtime-button--small'>
          { intl.formatMessage({ id: 'conversation.commentBox.retryUpload' }) }
        </span>
      </a>
    </OfflineInteractionBlocker>
  </div>
);

CameraUploadFailed.propTypes = {
  onUploadRetry: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

CameraUploadFailed.defaultProps = {
  onUploadRetry() {},
};

const PROGRESS_RING_RADIUS = 25;
const PROGRESS_RING_STROKE = 4;

export const CameraUploadProgress = ({ progress }) => {
  if (!progress) return null;

  const normalizedRadius = PROGRESS_RING_RADIUS - (PROGRESS_RING_STROKE * 2);
  const circumference = (normalizedRadius * 2) * Math.PI;

  const strokeDashoffset = circumference - ((progress / 100) * circumference);

  return (
    <div className='showtime-file-preview__image-overlay' data-test='camera.upload.progress'>
      <svg
        height={ PROGRESS_RING_RADIUS * 2 }
        width={ PROGRESS_RING_RADIUS * 2 }
        className='showtime-progress'
      >
        <circle
          className='showtime-progress__circle'
          stroke='white'
          fill='transparent'
          strokeWidth={ PROGRESS_RING_STROKE }
          strokeDasharray={ `${circumference} ${circumference}` }
          style={ { strokeDashoffset } }
          r={ normalizedRadius }
          cx={ PROGRESS_RING_RADIUS }
          cy={ PROGRESS_RING_RADIUS }
        />
      </svg>
    </div>
  );
};

CameraUploadProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export const CameraUploadStatus = ({
  status, onUploadRetry, progress, intl,
}) => {
  if (status === UPLOADING) return <CameraUploadProgress progress={ progress } />;

  if (status === FAILED) {
    return <CameraUploadFailed { ...{ onUploadRetry, intl } } />;
  }

  return null;
};

CameraUploadStatus.propTypes = {
  status: PropTypes.string.isRequired,
  onUploadRetry: PropTypes.func,
  intl: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

CameraUploadStatus.defaultProps = {
  onUploadRetry() {},
  progress: null,
};

export default CameraUploadStatus;
