const DataReloadWatchdog = function DataReloadWatchdog(
  minimumTickPeriodMilliseconds, totalLifetimeMilliseconds
) {
  this._minimumTickPeriodMilliseconds = minimumTickPeriodMilliseconds;
  this._totalLifetimeMilliseconds = totalLifetimeMilliseconds;
  this.reset();
};

DataReloadWatchdog.prototype.reset = function reset() {
  this._expirationTime = this.currentTimeMs() + this._totalLifetimeMilliseconds;
  this._resetLastCallTime();
};

DataReloadWatchdog.prototype._resetLastCallTime = function _resetLastCallTime() {
  this._lastCallTime = this.currentTimeMs();
};

DataReloadWatchdog.prototype.recentActivityRecorded = function recentActivityRecorded() {
  const timeGap = this.currentTimeMs() - this._lastCallTime;
  this._resetLastCallTime();
  return timeGap < this._minimumTickPeriodMilliseconds;
};

DataReloadWatchdog.prototype.currentTimeMs = function currentTimeMs() {
  return (new Date()).getTime();
};

DataReloadWatchdog.prototype.hasExpired = function hasExpired() {
  return this.currentTimeMs() > this._expirationTime;
};

export default DataReloadWatchdog;
