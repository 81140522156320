import _ from 'lodash';

export const MetricDataFactory = (function () {
  function populateDerivedValues(metricData, metric) {
    const metricPropertyNames = Object.keys(metric.properties || {});
    const derivedValues = metricPropertyNames.reduce(((result, propertyName) => {
      const property = metric.properties[propertyName];

      if (property.value) {
        result[propertyName] = property.value;
      } else if (property.path) {
        result[propertyName] = _.get(metricData, property.path);
      }

      return result;
    }), {});

    return {
      name: metricData.name,
      properties: _.merge(derivedValues, metricData.properties),
    };
  }

  function build(data, metric) {
    return populateDerivedValues({
      name: metric.name,
      properties: _.clone(data),
    }, metric);
  }

  return {
    build,
  };
}());
