import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Table from '../../common/Table';
import Attachment from '../../attachments/Attachment';

import { COMMENT_IMAGE_OPTION } from '../../file/constants';
import Signature from '../Signature';

const TaskCommentContent = ({
  content,
  attachment,
}) => (
  <div className='showtime-task-reports__task_comments_content'>
    {attachment && (
      <Attachment
        isClickable
        openInNewTab
        title={ attachment.fileName }
        handle={ attachment.filestackHandle }
        mimeType={ attachment.mimeType }
        url={ attachment.url }
        type={ attachment.type }
        renderImageOption={ COMMENT_IMAGE_OPTION }
      />
    ) }
    { content }
  </div>
);

TaskCommentContent.propTypes = {
  content: PropTypes.string,
  attachment: PropTypes.objectOf({
    fileName: PropTypes.string,
    filestackHandle: PropTypes.string,
    mimeType: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  history: PropTypes.object.isRequired,
};

TaskCommentContent.defaultProps = {
  content: undefined,
  attachment: undefined,
};

const TaskComments = ({
  comments,
  timeZone,
}) => (
  <div className='showtime-task-reports__task-content-table' data-test='task-comments'>
    <Table
      columnClasses={ [
        'showtime-task-reports__task-content-table-comment', 'showtime-task-reports__task-content-table-timestamp',
      ] }
      header={ [
        useIntl().formatMessage({ id: 'taskReports.taskComments.commentHeader' }), '',
      ] }
      rows={
        comments.map(comment => [
          <TaskCommentContent
            content={ comment.content }
            attachment={ comment.attachment }
          />,
          <Signature
            completedAt={ comment.timestamp }
            completedBy={ comment.user_name }
            timeZone={ timeZone }
          />,
        ])
      }
    />
  </div>
);

TaskComments.propTypes = {
  comments: PropTypes.array.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default TaskComments;
