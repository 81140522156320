import _ from 'lodash';
import { TaskWizardConstants, ReportConstants } from '../constants';
import { RequestConstants } from '../../request';
import { TaskConstants } from '../../task/constants';

const { OFF, COMPLETED, COMPLETED_WITH_EXCEPTIONS } = ReportConstants.selectMode;

const initialState = {
  content: {
    notifyAllUsers: false,
    selectMode: OFF,
    taskCompletionUserSubscriptions: {},
  },
};

const getSelectModeFromApi = ({
  taskCompletedWithExceptionsSubscribedUserIds, taskCompletedSubscribedUserIds,
}) => {
  if (_.size(taskCompletedWithExceptionsSubscribedUserIds)) return COMPLETED_WITH_EXCEPTIONS;
  if (_.size(taskCompletedSubscribedUserIds)) return COMPLETED;
  return OFF;
};

const loadTaskSubscriptions = (state, action) => {
  const notifyAllUsers = [COMPLETED, COMPLETED_WITH_EXCEPTIONS].includes(
    action.content.notify_all_users
  );

  const taskCompletionUserSubscriptions = _.chain(action.content.taskCompletedSubscribedUserIds)
    .merge(action.content.taskCompletedWithExceptionsSubscribedUserIds)
    .map(id => ({ id, isSubscribed: true }))
    .keyBy('id')
    .value();

  const selectMode = notifyAllUsers
    ? (action.content.notify_all_users || COMPLETED)
    : getSelectModeFromApi(action.content);

  return {
    content: {
      notifyAllUsers,
      selectMode,
      taskCompletionUserSubscriptions,
    },
  };
};

const unsubscribeAllSubscribedUsers = state => _.mapValues(
  state.content.taskCompletionUserSubscriptions,
  user => ({ ...user, isSubscribed: false })
);

const setSelectMode = (state, action) => _.merge({},
  state,
  { content: { selectMode: action.value } }
);

const setNotifyAllUsers = (state, { value }) => _.merge({},
  state,
  {
    content: {
      notifyAllUsers: value,
      taskCompletionUserSubscriptions: unsubscribeAllSubscribedUsers(state),
    },
  });

const setUserSubscription = (state, action) => {
  const { userId, isSelected } = action;

  const user = { id: userId, isSubscribed: isSelected };

  if (!isSelected && state.content.notifyAllUsers) {
    const otherUserSubscriptions = _.fromPairs(
      action.users.map(({ id }) => [id, { id, isSubscribed: true }]));
    return _.merge({}, state, {
      content: {
        notifyAllUsers: false,
        taskCompletionUserSubscriptions: {
          ...otherUserSubscriptions,
          [action.userId]: user,
        },
      },
    });
  }

  return _.merge({}, state, {
    content: {
      taskCompletionUserSubscriptions: { [action.userId]: user },
    },
  });
};

const clearUserSubscriptions = state => ({
  content: {
    notifyAllUsers: false,
    selectMode: OFF,
    taskCompletionUserSubscriptions: unsubscribeAllSubscribedUsers(state),
  },
});

const actionCases = {
  [RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY)]: loadTaskSubscriptions,
  [ReportConstants.SET_SELECT_MODE]: setSelectMode,
  [ReportConstants.SET_NOTIFY_ALL_USERS]: setNotifyAllUsers,
  [ReportConstants.SET_TASK_COMPLETION_USER_SUBSCRIPTION]: setUserSubscription,
  [ReportConstants.CLEAR_ALL_TASK_COMPLETION_SUBSCRIPTIONS]: clearUserSubscriptions,
  [TaskConstants.RESET]: () => initialState,
};

export default (
  state,
  action
) => {
  state = state || initialState;
  if (actionCases[action.type]) return actionCases[action.type](state, action);

  return state;
};
