import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import SVGIcon from '../common/SVGIcon';

class EmptyWidget extends Component {
  render() {
    return (
      <div className={this.props.widgetKey + ' widget'}>
        <div className='widget-header'>
          <span className='text-md'>
            {
              this.props.widgetHeader || this.props.intl.formatMessage({ id: 'emptyWidget.header' })
            }
          </span>
        </div>
        <div className="widget-body message-only">
          <div className="message">
            <SVGIcon
              classes="showtime-icon showtime-icon--size-28 showtime-icon--color-warning"
              iconName="outline/32px/objects/objects-outline_puzzle-10"
              />
            <div className="top-line">
              <span className="text-xl">
                { this.props.messageTopLine || this.props.intl.formatMessage({ id: 'emptyWidget.title' }) }
              </span>
            </div>
            {this.createMessageBottomComponent()}
          </div>
        </div>
      </div>
    );
  }

  createMessageBottomComponent() {
    const emailTitle = this.props.intl.formatMessage({ id: 'emptyWidget.emailTitle' });
    const emailHref = `mailto:support@trailapp.com?Subject=${emailTitle}`;
    const lines = this.props.messageBottomLines ||
      [
        <FormattedMessage
          id='emptyWidget.contactUs'
          values={ {
            intercomLink: (
              <a href='#' onClick={ () => window.Intercom('show') }>
                { this.props.intl.formatMessage({ id: 'emptyWidget.intercomLink' }) }
              </a>
            ),
            emailLink: (
              <a href={ emailHref } target='_top'>
                { this.props.intl.formatMessage({ id: 'emptyWidget.emailLink' }) }
              </a>
            ),
          } }
        />,
      ];
    return (<div className="bottom-line">
      {
        lines.map(line => <div className="text-md">{ line }</div>)
      }
    </div>);
  }
}

export default injectIntl(EmptyWidget);
