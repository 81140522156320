import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';
import SortableList from '../../../common/SortableList';
import Column from './Column';
import AddColumnButton from './AddColumnButton';

const getFieldName = column => _.get(column, 'cellSettings.fieldName');

export const Columns = ({
  columns,
  errors,
  expandedColumn,
  onExpandColumn,
  onSortColumns,
}) => (
  <div data-test='widget.columns'>
    <SortableList
      id='widget.columns'
      onSort={ onSortColumns }
      items={
        columns.map(column => ({ ...column, id: getFieldName(column) }))
      }
      draggingClass={ 'showtime-record-log-builder-list--dragging' }
    >
      {({ item: column, dragProvided, index, isDragging }) => {
        const fieldName = getFieldName(column);
        const isExpanded = fieldName === expandedColumn;
        const handleExpand = () => onExpandColumn(fieldName);
        const isLastColumn = columns.length === index + 1;
        const erroredFields = errors[column.id] || [];

        return (
          <React.Fragment>
            <Column
              column={ column }
              errors={ erroredFields }
              isExpanded={ isExpanded }
              isDragging={ isDragging }
              onExpand={ handleExpand }
              dragProvided={ dragProvided }
              index={ index }
            />
            { !isLastColumn && <AddColumnButton
              divider
              position={ index }
              dataTest={ `widget.addButton${index}` }
            /> }
          </React.Fragment>
        );
      }}
    </SortableList>
    <AddColumnButton
      dataTest='widget.columns.add'
    />
  </div>
);

Columns.propTypes = {
  columns: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  expandedColumn: PropTypes.string,
  onExpandColumn: PropTypes.func.isRequired,
  onSortColumns: PropTypes.any.isRequired,
};

Columns.defaultProps = {
  expandedColumn: null,
};

const mapStateToProps = (state) => {
  const {
    columns = [],
  } = _.get(TaskWidgetSelectors.editedTaskWidgetConfig(state), 'settings.public', {});

  const {
    errors,
    expandedColumn,
  } = TaskWidgetSelectors.widgetBuilderStatus(state);

  return {
    columns,
    errors,
    expandedColumn,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    sortColumns,
    expandColumn,
  } = bindActionCreators(EditWidgetActions, dispatch);

  const onSortColumns = (sortItems) => {
    const fieldNameToPosition = sortItems.reduce((accu, item) => ({
      ...accu,
      [item.id]: item.position,
    }), {});

    sortColumns(fieldNameToPosition);
  };

  return {
    onSortColumns,
    onExpandColumn: fieldName => expandColumn(fieldName),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Columns);
