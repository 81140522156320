export const TaskWidgetConstants = {
  FETCH_AVAILABLE_INTEGRATIONS: 'task_widget.fetch_available_integrations',
  CHANGE: 'task_widget.change',
  EXPAND: 'task_widget.expand',
};

export const NEW = 'NEW_TASK_WIDGET';

export const TaskWidgetViews = {
  codeView: 'view - code',
  formView: 'view - form',
};
