import {
  FLOATIFICATION_ONLINE_ID,
  MESSAGE_ONLINE,
} from '../../../service-workers/constants';
import {
  actionConstants as floatificationActions,
  FLOATIFICATION_PERSIST_DURATION,
  ONLINE,
} from '../../../floatifications/constants';
import floatificationsStore from '../../../store';
import { actionConstants as modalAlertActions } from '../../../modal-alert/constants';
import modalAlertStore from '../../../modal-alert/store';
import { TrailStore } from '../../../trail/store';
import safePostMessage from '../helpers/safePostMessage';

export default async (client = window, view = document) => {
  if (client.offlineMessageTimeout) {
    clearTimeout(client.offlineMessageTimeout);
    client.offlineMessageTimeout = null;
  }

  if (client.offlineInterval) {
    client.clearInterval(client.offlineInterval);
    client.offlineInterval = null;
  }

  if (!view.hidden) {
    safePostMessage({ type: MESSAGE_ONLINE });
  }

  floatificationsStore.dispatch({
    type: floatificationActions.CLEAR_MESSAGES,
  });

  floatificationsStore.dispatch({
    type: floatificationActions.ADD_MESSAGE,
    message: {
      id: FLOATIFICATION_ONLINE_ID,
      text: 'ONLINE',
      type: ONLINE,
      icon: 'mini/ui/ui-16px-1_check-circle-08',
    },
  });

  const { isShown } = modalAlertStore.getState();

  if (isShown) {
    modalAlertStore.dispatch({
      type: modalAlertActions.HIDE,
    });

    await new TrailStore().forceFetch();
  }

  setTimeout(() => {
    floatificationsStore.dispatch({
      type: floatificationActions.REMOVE_MESSAGE_BY_ID,
      id: FLOATIFICATION_ONLINE_ID,
    });
  }, FLOATIFICATION_PERSIST_DURATION);
};
