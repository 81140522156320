import { createHashHistory } from 'history';
import metricsPublisher from '../../metrics';

const history = createHashHistory();

history.listen(() => {
  const { location } = document;
  const fullPath = location.pathname + location.search + location.hash;
  metricsPublisher.recordPageView(fullPath, document.title);
});

export { history as WindowHistory };
