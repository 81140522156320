import _ from 'lodash';
import EventEmitter from 'wolfy87-eventemitter';

import AppDispatcher from '../application/dispatcher';
import { TimeSlotConfigurationConstants } from './constants';
import ApplicationConstant from '../application/constants';
import { getSaveableTimeSlot } from '../timeslots/projectionToLegacyTimeslots';

const LAST_TIMESLOT_POSITION = 7;
const FIRST_TIME_SLOT_POSITION = 0;

export const TimeSlotConfigurationStore = new _.extend({}, EventEmitter.prototype, { // eslint-disable-line

  MAX_HOUR_NEXT_DAY: 6,
  changed: false,

  _timeSlots: {
    dayDefault: {},
    organization: {},
    location: {},
  },

  _setTimeSlots(level, timeSlots) {
    _.each(timeSlots, (timeSlot) => {
      this._timeSlots[level][timeSlot.id] = timeSlot;
    });
  },

  getTimeSlot(levelOrTimeSlot, id) {
    if (levelOrTimeSlot.id) {
      // If the first argument is a time slot, return the time slot
      return levelOrTimeSlot;
    }

    return this._timeSlots[levelOrTimeSlot][id];
  },

  getTimeSlots(level, ids) {
    return _.chain(ids)
      .map(id => this.getTimeSlot(level, id))
      .sortBy(this._sorter)
      .value();
  },

  getSaveableTimeSlots() {
    const saveableTimeslots = _.mapValues(this._timeSlots, timeslotsHash => (
      _.mapValues(timeslotsHash, getSaveableTimeSlot)
    ));

    return saveableTimeslots;
  },

  getDefaultTimeSlots() {
    return _.sortBy(this._timeSlots.dayDefault, this._sorter);
  },

  getDefaultTimeSlot(name) {
    return _.find(this._timeSlots.dayDefault, defaultTimeSlot => defaultTimeSlot.name === name);
  },

  getTimeSlotsForDayOfWeek(dayOfWeek) {
    return _.chain(this._timeSlots.organization)
      .filter(timeSlot => timeSlot.dayOfWeek === dayOfWeek)
      .sortBy(this._sorter)
      .value();
  },

  isDefaultDayOfWeekOverridden(dayOfWeek) {
    return _.some(
      this.getTimeSlotsForDayOfWeek(dayOfWeek),
      timeSlot => TimeSlotConfigurationStore.timeSlotOverridesDefault(timeSlot)
    );
  },

  isOrganizationDayOfWeekOverridden(locationId, dayOfWeek) {
    return _.some(
      this.getLocationTimeSlotsForDayOfWeek(locationId, dayOfWeek),
      timeSlot => this.locationTimeSlotOverridesOrganization(timeSlot)
    );
  },

  getLocationTimeSlotsForDayOfWeek(locationId, dayOfWeek) {
    return _.chain(this._timeSlots.location)
      .filter(timeSlot => timeSlot.locationId === locationId && timeSlot.dayOfWeek === dayOfWeek)
      .sortBy(this._sorter)
      .value();
  },

  locationTimeSlotOverridesOrganization(timeSlot) {
    if (_.isEmpty(this._timeSlots.organization)) {
      return false;
    }
    const timeSlotFound = this._timeSlots.organization[timeSlot.timeSlotId];
    return this.slotTimesAreDifferent(timeSlot, timeSlotFound, true);
  },

  timeSlotOverridesDefault(levelOrTimeSlot, id) {
    const timeSlot = this.getTimeSlot(levelOrTimeSlot, id);

    if (timeSlot.level === 'dayDefault') {
      return false;
    }

    // any organization level time slots which are closed must be overriding defaults:
    if (timeSlot.level === 'organization' && timeSlot.isClosed) {
      return true;
    }

    const timeSlotFound = _.find(
      this._timeSlots.dayDefault,
      defaultTimeSlot => defaultTimeSlot.name === timeSlot.name
    );

    return this.slotTimesAreDifferent(timeSlot, timeSlotFound, false);
  },

  slotTimesAreDifferent(slotA, slotB, checkIsClosed) {
    if (slotA === undefined || slotB === undefined) {
      return true;
    }
    const closedStatusesDiffer = Boolean(slotA.isClosed) !== Boolean(slotB.isClosed);
    if (checkIsClosed && closedStatusesDiffer) {
      return true;
    }
    return slotA.startTime !== slotB.startTime || slotA.endTime !== slotB.endTime;
  },

  setTime(level, id, startOrEnd, time) {
    if (level === 'dayDefault') {
      this._setDaysWithDefaultValue(id, startOrEnd, time);
    }
    this._setTime(level, id, startOrEnd, time);
  },

  _setDaysWithDefaultValue(id, startOrEnd, time) {
    const nonOverriddenDaysOfWeek = this._nonOverriddenDaysOfWeek();
    const amendedTimeslot = this.getTimeSlot('dayDefault', id);
    nonOverriddenDaysOfWeek.forEach((d) => {
      const timeslot = this._findTimeSlotOnDay(d, amendedTimeslot.name);
      if (timeslot) this._setTime('organization', timeslot.id, startOrEnd, time);
    });
  },

  _findTimeSlotOnDay(dayOfWeek, name) {
    return _.find(this._timeSlots.organization,
      timeSlot => timeSlot.name === name && timeSlot.dayOfWeek === dayOfWeek);
  },

  _setTime(level, id, startOrEnd, time) {
    const currentTimeslot = this.getTimeSlot(level, id);
    const adjacentTimeslot = this._getAdjacentTimeslot(level, id, startOrEnd);
    const isAdjacentFirstTime = startOrEnd === 'end' && adjacentTimeslot.position === 0;
    const isAdjacentFinalTime = startOrEnd === 'start' &&
                                adjacentTimeslot.position === LAST_TIMESLOT_POSITION;

    this._changeTime(level, id, startOrEnd, time);
    this._changeTime(
      level,
      adjacentTimeslot.id,
      this._oppositeTime(startOrEnd),
      this._processTimeForAdjacent(time, isAdjacentFirstTime, isAdjacentFinalTime)
    );

    if (currentTimeslot.startTime > currentTimeslot.endTime) {
      this._setTime(level, id, this._oppositeTime(startOrEnd), time);
    }
    if (adjacentTimeslot.startTime > adjacentTimeslot.endTime) {
      const adjacentTime = startOrEnd === 'end' ? adjacentTimeslot.startTime : adjacentTimeslot.endTime;
      this._setTime(level, adjacentTimeslot.id, startOrEnd, adjacentTime);
    }
  },

  _nonOverriddenDaysOfWeek() {
    return _.reject(
      TimeSlotConfigurationConstants.DAYS_OF_THE_WEEK,
      dayOfWeek => this.isDefaultDayOfWeekOverridden(dayOfWeek)
    );
  },

  _getAdjacentTimeslot(level, currentId, startOrEnd) {
    const currentTimeslot = this.getTimeSlot(level, currentId);
    let adjacentPosition;
    let adjacentDayNum;
    let adjacentTimeslot;

    if (startOrEnd === 'start') {
      adjacentPosition =
        currentTimeslot.position === FIRST_TIME_SLOT_POSITION
          ? LAST_TIMESLOT_POSITION : currentTimeslot.position - 1;

      if (level !== 'dayDefault') {
        adjacentDayNum = currentTimeslot.position === 0
          ? currentTimeslot.dayOfWeekNum - 1 : currentTimeslot.dayOfWeekNum;
        adjacentDayNum = adjacentDayNum < 0 ? 6 : adjacentDayNum;
      }

      adjacentTimeslot = _.find(
        this._timeSlots[level],
        timeslot => timeslot.position === adjacentPosition &&
          timeslot.dayOfWeekNum === adjacentDayNum
      );

      return adjacentTimeslot;
    }

    adjacentPosition = currentTimeslot.position === LAST_TIMESLOT_POSITION
      ? 0 : currentTimeslot.position + 1;
    if (level !== 'dayDefault') {
      adjacentDayNum = currentTimeslot.position === LAST_TIMESLOT_POSITION
        ? currentTimeslot.dayOfWeekNum + 1 : currentTimeslot.dayOfWeekNum;
      adjacentDayNum = adjacentDayNum > 6 ? 0 : adjacentDayNum;
    }

    adjacentTimeslot = _.find(
      this._timeSlots[level],
      timeslot => timeslot.position === adjacentPosition && timeslot.dayOfWeekNum === adjacentDayNum
    );

    return adjacentTimeslot;
  },

  _changeTime(level, id, startOrEnd, time) {
    this._timeSlots[level][id][`${startOrEnd}Time`] = time;
  },

  _oppositeTime(startOrEnd) {
    return startOrEnd === 'start' ? 'end' : 'start';
  },

  _processTimeForAdjacent(time, isFirstTime, isFinalTime) {
    const hoursAndMinutes = time.split(':');
    const hour = parseInt(hoursAndMinutes[0], 10);
    if (isFirstTime && hour > 23) {
      hoursAndMinutes[0] = this._format24HourTime(hour - 24);
    }
    if (isFinalTime) {
      hoursAndMinutes[0] = this._format24HourTime(hour + 24);
    }
    return hoursAndMinutes.join(':');
  },

  finalPosition() {
    return LAST_TIMESLOT_POSITION;
  },

  _format24HourTime(number) {
    return `${number}`.padStart(2, '0');
  },

  setClosed(level, id, closed) {
    this._timeSlots[level][id].isClosed = closed;
  },

  setBatchClosed(level, ids, closed) {
    _.each(ids, (id) => {
      this.setClosed(level, id, closed);
    });
  },

  resetLocationDayOfWeek(locationId, dayOfWeek) {
    _.each(this.getLocationTimeSlotsForDayOfWeek(locationId, dayOfWeek), (timeSlot) => {
      const defaultTimeSlot = this.getTimeSlot('organization', timeSlot.timeSlotId);
      this.setTime('location', timeSlot.id, 'start', defaultTimeSlot.startTime);
      this.setTime('location', timeSlot.id, 'end', defaultTimeSlot.endTime);
      this.setClosed('location', timeSlot.id, defaultTimeSlot.isClosed);
    });
  },

  resetDayOfWeek(dayOfWeek) {
    _.each(this.getTimeSlotsForDayOfWeek(dayOfWeek), (timeSlot) => {
      const defaultTimeSlot = this.getDefaultTimeSlot(timeSlot.name);
      this.setTime('organization', timeSlot.id, 'start', defaultTimeSlot.startTime);
      this.setTime('organization', timeSlot.id, 'end', defaultTimeSlot.endTime);
      this.setClosed('organization', timeSlot.id, false);
    });
  },

  _sorter(timeSlot) {
    return [
      timeSlot.level === 'dayDefault' ? '_' : timeSlot.dayOfWeekNum,
      timeSlot.position,
    ]
      .join('_');
  },

  isAllClosed(level, ids) {
    return _.every(this.getTimeSlots(level, ids), timeSlot => timeSlot.isClosed);
  },

  addChangeListener(callback) { this.on(ApplicationConstant.CHANGE_EVENT, callback); },

  removeChangeListener(callback) {
    this.removeListener(ApplicationConstant.CHANGE_EVENT, callback);
  },

  resetStore() {
    this.changed = false;
    this._timeSlots = {
      dayDefault: {},
      organization: {},
      location: {},
    };
  },

  markChange() {
    this.changed = true;
  },

  emitChange() {
    this.emit(ApplicationConstant.CHANGE_EVENT);
  },
});

AppDispatcher.register((payload) => {
  const action = payload.actionType;

  switch (action) {
    case TimeSlotConfigurationConstants.SET_START_TIME:
      TimeSlotConfigurationStore.setTime(payload.level, payload.id, 'start', payload.time);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.SET_END_TIME:
      TimeSlotConfigurationStore.setTime(payload.level, payload.id, 'end', payload.time);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.SET_CLOSED:
      TimeSlotConfigurationStore.setClosed(payload.level, payload.id, payload.closed);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.SET_BATCH_CLOSED:
      TimeSlotConfigurationStore.setBatchClosed(payload.level, payload.ids, payload.closed);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.RESET_LOCATION_DAY_OF_WEEK:
      TimeSlotConfigurationStore.resetLocationDayOfWeek(payload.locationId, payload.dayOfWeek);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.RESET_DAY_OF_WEEK:
      TimeSlotConfigurationStore.resetDayOfWeek(payload.dayOfWeek);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.DESTROY:
      TimeSlotConfigurationStore.destroy(payload.level, payload.id);
      TimeSlotConfigurationStore.markChange();
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.SET_TIMESLOTS:
      TimeSlotConfigurationStore._setTimeSlots(payload.type, payload.timeslots);
      TimeSlotConfigurationStore.emitChange();
      break;
    case TimeSlotConfigurationConstants.RESET_STORE:
      TimeSlotConfigurationStore.resetStore();
      TimeSlotConfigurationStore.emitChange();
      break;
    default:
      // NO-OP
  }
});

if (process.env.TEST === true) {
  window.TimeSlotConfigurationStore = TimeSlotConfigurationStore;
}
