import _ from 'lodash';
import { createSelector } from 'reselect';

import { TaskWidgetSelectors } from '.';
import { TaskWizardConstants, TaskCheckListConstants } from '../constants';
import { TaskTemplateConstants } from '../../task/constants';
import AttachmentConstants from '../../attachments/constants';
import { cleanSubtasks } from '../taskWizardHelper';
import { widgetBuilderErrorsSelector, missingWidgetTitleSelector } from './taskWidgetSelectors';

const { ON_DEMAND } = TaskTemplateConstants.templateType;

const {
  sections: {
    CONTENT, SCHEDULE, ASSIGN, REPORT,
  },
  TAB_SEQUENCE,
} = TaskWizardConstants;

const showNotificationToggleSelector = (state, ownProps) => ownProps.showNotificationToggle;

export const visibleSectionsSelector = createSelector(
  showNotificationToggleSelector,
  (showNotificationToggle) => {
    const allowedSections = [
      CONTENT, ASSIGN, SCHEDULE, ...showNotificationToggle ? [REPORT] : [],
    ];
    return TAB_SEQUENCE.filter(tab => allowedSections.includes(tab));
  }
);

export const activeSectionSelector = state => state.taskWizard.activeSection;

export const nextVisibleSectionSelector = (state, ownProps) => {
  const visibleTabs = visibleSectionsSelector(null, ownProps);
  const activeTab = activeSectionSelector(state);

  const activeTabIndex = visibleTabs.indexOf(activeTab);
  return visibleTabs[activeTabIndex + 1];
};

const mapChecklistToSubtaskPreview = (checklist) => {
  const subtasks = [];

  let currentGroupName = null;

  cleanSubtasks(checklist).forEach((item, position) => {
    if (item.type === TaskCheckListConstants.TYPE.GROUP) {
      currentGroupName = item.name;
    } else {
      subtasks.push({
        name: item.name,
        group_name: currentGroupName,
        position,
      });
    }
  });

  return subtasks;
};

const mapAttachmentsToContentsPreview = attachments => attachments.reduce((accu, attachment) => {
  if (attachment.state !== AttachmentConstants.State.SAVED) return accu;

  return [
    ...accu,
    {
      ...attachment,
      attachment_content_type: attachment.mimeType,
    },
  ];
}, []);

export const taskTemplatePreviewSelector = (state) => {
  const { newTask } = state;
  const widgetPreview = TaskWidgetSelectors.editedTaskWidgetConfigForPreview(state);

  return {
    name: newTask.title.content.value,
    notes: newTask.helpText.content.value,
    contents: mapAttachmentsToContentsPreview(newTask.attachments || []),
    integration: widgetPreview ? widgetPreview.widget_data_type : null,
    subtasks: mapChecklistToSubtaskPreview(newTask.checkList.content.checkList),
    widget_configuration: widgetPreview,
  };
};

const anyWidgetBuilderErrors = (state) => {
  const columnErrors = widgetBuilderErrorsSelector(state);
  return Object.values(columnErrors).some(x => x.length);
};

const noColumns = (stagedSettingsChanges) => {
  const columns = _.get(stagedSettingsChanges, 'settings.public.columns');
  return columns && columns.length === 0;
};

export const taskCreatedAtSelector = state => _.get(state, 'taskWizard.createdAt');
export const taskCreatedByUserNameSelector = state => _.get(state, 'taskWizard.createdByUserName');

export const errorMessageSelector = (state) => {
  const { newTask, editWidget: { stagedSettingsChanges } } = state;

  if (!_.get(newTask, 'title.content.value')) {
    return 'newTask.errors.missingTitle';
  }

  const missingWidgetTitle = missingWidgetTitleSelector(state);
  if (missingWidgetTitle) return missingWidgetTitle;

  if (anyWidgetBuilderErrors(state)) {
    return 'newTask.errors.widgetBuilder';
  }

  if (noColumns(stagedSettingsChanges)) {
    return 'newTask.errors.noColumns';
  }

  const isOnDemandTemplate = _.get(newTask, 'template.content.value') === ON_DEMAND;
  if (isOnDemandTemplate) {
    return undefined;
  }
  return _.get(newTask, 'schedule.content.summary.errorMessage');
};
