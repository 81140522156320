import tv4 from 'tv4';

const MetricValidator = {
  validateMetricData(metricData, metric) {
    const requiredProps = Object.keys(metric.properties || {})
      .reduce((result, key) => {
        if (metric.properties[key].required) {
          result.push(key);
        }
        return result;
      }, []);

    const validationInfo = tv4.validateMultiple(metricData.properties, {
      type: 'object',
      properties: metric.properties,
      required: requiredProps,
    }, false, false);

    return validationInfo;
  },

  getFormattedErrorMessage(metricValidationInfo) {
    return metricValidationInfo.errors
      .map((error) => {
        switch (error.code) {
          case 302:
            return `- ${error.message}`;
          default:
            return `- ${error.dataPath.replace('/', '')}: ${error.message}`;
        }
      })
      .join('\n');
  },
};

export { MetricValidator };
