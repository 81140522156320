export const Sections = {
  ACTIVITY: 'activity',
  SITES: 'sites',
  TASKS: 'tasks',
  TEAMS: 'teams',
  REPORTS: 'reports',
  SCORES: 'scores',
  TASK_REPORTS: 'taskReports',
  NONE: 'none',
};

export const completeSection = {
  ICON: 'mini/ui/ui-16px-1_check-simple',
  LABEL_PATH: 'navigation.section.completeTasks',
  NAME: 'sites',
};
