import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { Signature } from '../Signature';

const commentStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  marginTop: 10,
};

const contentStyles = {
  paddingLeft: 8,
  paddingRight: 10,
  flex: 1,
  display: 'inline-flex',
  borderLeft: '3px solid #646470',
};

const PDFComment = ({
  content, commentedAt, commentedBy, timeZone, intl, showSignature,
}) => (
  <View
    is='div'
    style={ commentStyles }
  >
    <Text
      is='span'
      style={ {
        ...contentStyles,
        ...showSignature ? { flexBasis: '66.66%' } : {},
      } }
    >
      { content }
    </Text>
    { showSignature && (
    <Text is='span' style={ { float: 'right', width: '33.33%', display: 'inline-flex' } }>
      <Signature
        completedAt={ commentedAt }
        completedBy={ commentedBy }
        intl={ intl }
        timeZone={ timeZone }
      />
    </Text>
    ) }
  </View>
);

PDFComment.propTypes = {
  content: PropTypes.string.isRequired,
  commentedAt: PropTypes.string.isRequired,
  commentedBy: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  showSignature: PropTypes.bool,
};

PDFComment.defaultProps = {
  showSignature: true,
};

export default PDFComment;
