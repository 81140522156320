import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { TimeSlotConfigurationStore } from './store.js';
import { TimeSlotConfigurationActions } from './actions';
import EditableTimeSlotSection from './EditableTimeSlotSection';
import EditableTimeSlot from './EditableTimeSlot';

export const calculateOpeningAndClosingTimeSlots = (level, timeSlotIds = []) => {
  const response = { opening: { id: null }, closing: { id: null }, numberOfOpenTimeSlots: 0 };

  const openTimeSlots = timeSlotIds
    .map(id => TimeSlotConfigurationStore.getTimeSlot(level, id))
    .filter(timeslot => !timeslot.isClosed);

  response.numberOfOpenTimeSlots = openTimeSlots.length;

  if (!openTimeSlots.length) return response;

  // eslint-disable-next-line prefer-destructuring
  response.opening = openTimeSlots[0];
  response.closing = openTimeSlots[openTimeSlots.length - 1];
  return response;
};

class EditableTimeSlotList extends Component {
  isAllClosed() {
    return TimeSlotConfigurationStore.isAllClosed(this.props.level, this.props.timeSlotIds);
  }

  renderClosedCheckbox() {
    return (
      <div className='editable-time-slot'>
        <label className='checkbox_primary checkbox' data-test='checkbox-all-day'>
          <input
            className='checkbox-input'
            data-test='checkbox-all-day'
            type='checkbox'
            checked={ this.isAllClosed() }
            onChange={ e => this.toggleClosed(e) }
          />
          <span className='icon' />
          <span className='text'>
            {this.props.intl.formatMessage({ id: 'editableTimeSlotList.closedAllDayButtonLabel' })}
          </span>
        </label>
      </div>
    );
  }

  timeSlotList = () => {
    const {
      isCloseable, isDestroyable, readOnly, level,
    } = this.props;

    const { intl } = this.props;
    const { opening, closing, numberOfOpenTimeSlots } =
      calculateOpeningAndClosingTimeSlots(level, this.props.timeSlotIds);

    return this.props.timeSlotIds.reduce((acc, timeSlotId) => {
      if (numberOfOpenTimeSlots > 2 && closing.id === timeSlotId) {
        acc.push(<EditableTimeSlotSection
          key='closingTime'
          time={ closing.startTime }
          label={ intl.formatMessage({ id: 'editableTimeSlotList.closingTime' }) }
        />);
      }

      acc.push(<EditableTimeSlot
        key={ timeSlotId }
        isCloseable={ isCloseable }
        isDestroyable={ isDestroyable }
        readOnly={ readOnly }
        level={ level }
        id={ timeSlotId }
      />);

      if (numberOfOpenTimeSlots > 2 && opening.id === timeSlotId) {
        acc.push(<EditableTimeSlotSection
          key='openingTime'
          time={ opening.endTime }
          label={ intl.formatMessage({ id: 'editableTimeSlotList.openingTime' }) }
        />);
      }

      return acc;
    }, []);
  }

  render() {
    const classes = classNames({
      'editable-time-slot-list': true,
      'editable-time-slot-list--read-only': this.props.readOnly,
    });

    return (
      <div className={ classes }>
        {this.props.isCloseable && !this.props.readOnly ? this.renderClosedCheckbox() : null}
        { this.timeSlotList() }
      </div>
    );
  }

  toggleClosed(e) {
    TimeSlotConfigurationActions.setBatchClosed(
      this.props.level, this.props.timeSlotIds, e.target.checked);
  }
}

EditableTimeSlotList.propTypes = {
  isCloseable: PropTypes.bool,
  isDestroyable: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  timeSlotIds: PropTypes.array,
  level: PropTypes.string.isRequired,
};

EditableTimeSlotList.defaultProps = {
  isCloseable: false,
  isDestroyable: false,
  timeSlotIds: [],
};

export default injectIntl(EditableTimeSlotList);
