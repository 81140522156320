import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';

import { looksLikeANumber } from '../constants/validation';

import Input, { NUMBER, TEXT } from './Input';

const USER_AGENT_DEVICES_WITHOUT_NEGATIVE_KEYBOARDS = [
  'LG',
  'Samsung',
];

const CLIENT_HINT_SAMSUNG_DEVICE_NAME = 'SM';

const IOS_PATTERN_FOR_NUMBER_KEYBOARD = '[0-9]*';

const NumberInput = (props) => {
  const {
    onChange,
    type,
    whole,
  } = props;

  const [fieldType, setFieldType] = useState(type);
  useEffect(() => {
    const replaceFieldTypeForCertainManufacturers = () => {
      if (whole) {
        setFieldType(TEXT);
        return;
      }
      if (navigator.userAgentData) {
        navigator.userAgentData
          .getHighEntropyValues(['model']).then((ua) => {
            const devicePreventsNegativeNumbers = ua.model
              .includes(CLIENT_HINT_SAMSUNG_DEVICE_NAME);
            if (devicePreventsNegativeNumbers) setFieldType(TEXT);
          });
      } else {
        const devicePreventsNegativeNumbers = USER_AGENT_DEVICES_WITHOUT_NEGATIVE_KEYBOARDS
          .includes((new UAParser()).getDevice().vendor);
        if (devicePreventsNegativeNumbers) setFieldType(TEXT);
      }
    };
    replaceFieldTypeForCertainManufacturers();
  }, [navigator.userAgentData, whole]);

  const onNumberWithTextTypeChange = (newValue) => {
    if (!newValue || looksLikeANumber(newValue, { whole })) {
      onChange(newValue);
    }
  };

  const handleChange = (newValue, validity) => {
    newValue = validity.badInput ? NaN : `${newValue}`.split('e')[0];

    const hasTextField = fieldType === TEXT;
    const callback = hasTextField ? onNumberWithTextTypeChange : onChange;
    callback(newValue, validity);
  };

  return (
    <Input
      { ...props }
      type={ fieldType }
      onChange={ handleChange }
      { ...whole && {
        pattern: IOS_PATTERN_FOR_NUMBER_KEYBOARD,
        inputMode: 'numeric',
      } }
    />
  );
};

NumberInput.propTypes = {
  dataTest: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  max: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  refFn: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    NUMBER,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  whole: PropTypes.bool,
};

NumberInput.defaultProps = {
  dataTest: null,
  autoComplete: null,
  disabled: false,
  max: null,
  minLength: null,
  maxLength: null,
  min: null,
  name: null,
  onBlur: () => { },
  onKeyDown: null,
  placeholder: null,
  refFn: null,
  required: false,
  type: TEXT,
  value: undefined,
  whole: false,
};

export default NumberInput;
