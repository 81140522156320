import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Overlay from '../../overlay/Overlay';
import DataCaptureSelectors from '../../widget/selectors/dataCaptureSelectors';
import { TaskReportsSelectors } from '../selectors';
import { taskContentCommentsSelector } from '../../taskContentComments/selectors';
import PDF from './PDF';

const PDFOverlay = ({
  selectedTasks, subtasksByTaskId, widgetsByTaskId, widgetErrorsByTaskId, displayComments,
  taskContentCommentsByTaskId,
  displaySubtasks, displayWidgets, bulkStartIndex, bulkEndIndex, taskCount,
  reportUrl, intl,
}) => (
  <Overlay dataTest='taskReports.pdfOverlay' fullScreen>
    <PDF
      {
        ...{
          selectedTasks,
          displayComments,
          displaySubtasks,
          displayWidgets,
          widgetsByTaskId,
          widgetErrorsByTaskId,
          taskContentCommentsByTaskId,
          subtasksByTaskId,
          bulkStartIndex,
          bulkEndIndex,
          taskCount,
          reportUrl,
          intl,
        }
      }
    />
  </Overlay>
);

PDFOverlay.propTypes = {
  selectedTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtasksByTaskId: PropTypes.object.isRequired,
  taskContentCommentsByTaskId: PropTypes.object.isRequired,
  widgetsByTaskId: PropTypes.object.isRequired,
  widgetErrorsByTaskId: PropTypes.object.isRequired,
  displayComments: PropTypes.bool.isRequired,
  displaySubtasks: PropTypes.bool.isRequired,
  displayWidgets: PropTypes.bool.isRequired,
  bulkStartIndex: PropTypes.number.isRequired,
  bulkEndIndex: PropTypes.number.isRequired,
  taskCount: PropTypes.number.isRequired,
  reportUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const selectedIds = TaskReportsSelectors.selectedTaskIdsSelector(state);
  const selectedTasks = TaskReportsSelectors.selectedTasksSelector(state);
  const subtasksByTaskId = {};
  selectedIds.forEach(
    (id) => { subtasksByTaskId[id] = TaskReportsSelectors.taskSubtasksSelector(state, { id }); }
  );
  const widgetsByTaskId = {};
  const widgetErrorsByTaskId = {};
  selectedTasks.forEach(
    (task) => {
      widgetsByTaskId[task.id] = TaskReportsSelectors.widgetByWidgetIdSelector(
        state, task.widget_data_id
      );
      const widget = widgetsByTaskId[task.id];
      if (widget) {
        widgetErrorsByTaskId[task.id] = DataCaptureSelectors.visibleErrorsSelector(
          state, { showAllErrors: true, widgetData: widget, intl: ownProps.intl }, widget.id
        ) || [];
      }
    }
  );
  const taskCount = TaskReportsSelectors.taskCountSelector(state, ownProps);
  const bulkStartIndex = TaskReportsSelectors.selectedTaskGroupSelector(state, ownProps);
  let bulkEndIndex;
  if (Number.isInteger(bulkStartIndex)) {
    bulkEndIndex = bulkStartIndex + (selectedTasks.length - 1);
  }

  const taskContentCommentsByTaskId = _.mapValues(
    taskContentCommentsSelector(state),
    comments => _.sortBy(comments, 'commented_at'));

  return {
    ...ownProps,
    selectedTasks,
    subtasksByTaskId,
    widgetsByTaskId,
    widgetErrorsByTaskId,
    displayWidgets: TaskReportsSelectors.viewRecordLogsSelector(state, ownProps),
    displayComments: TaskReportsSelectors.viewCommentsSelector(state, ownProps),
    displaySubtasks: TaskReportsSelectors.viewChecklistsSelector(state, ownProps),
    bulkStartIndex,
    bulkEndIndex,
    taskCount,
    reportUrl: `${window.location.origin}/reports#/tasks${ownProps.location.search}`,
    taskContentCommentsByTaskId,
  };
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(PDFOverlay);
