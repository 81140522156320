import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export const PREFIX = 'prefix';
export const SUFFIX = 'suffix';

const Affix = ({
  children,
  position,
}) => {
  const affixClasses = classNames(
    `showtime-input__${position}`,
  );

  return children && (
    <div className={ affixClasses } data-test={ position }>
      <span className='showtime-label__text'>
        { children }
      </span>
    </div>
  );
};

Affix.propTypes = {
  position: PropTypes.oneOf([
    PREFIX,
    SUFFIX,
  ]).isRequired,
  children: PropTypes.node.isRequired,
};

export default Affix;
