import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

export class TypeformAnswer extends Component {
  render() {
    return (<span>{this.getFormattedAnswer(this.props.answer)}</span>);
  }

  getFormattedAnswer(answer) {
    var formattedAnswer = '';

    switch (answer.type) {
      case 'boolean':
        formattedAnswer = answer[answer.type] ?
          this.props.intl.formatMessage({ id: 'formWidget.answerTrue' }) :
          this.props.intl.formatMessage({ id: 'formWidget.answerFalse' });
        break;
      case 'choice':
        formattedAnswer = answer[answer.type].label || answer[answer.type].other;
        break;
      case 'choices':
        var answers = [].concat(answer[answer.type].labels || []);

        if (answer[answer.type].other) {
          answers.push(answer[answer.type].other);
        }

        formattedAnswer = answers.join(', ');
        break;
      case 'date':
        var dateValue = answer.date || answer.text;
        formattedAnswer = moment(dateValue).format('YYYY-MM-DD');
        break;
      case 'file_url':
        var url = answer[answer.type];
        formattedAnswer = (<a href={url}>{url}</a>);
        break;
      default:
        formattedAnswer = answer[answer.type];
        break;
    }

    return formattedAnswer;
  }
}

TypeformAnswer.propTypes = {
  answer: PropTypes.object.isRequired
};

export default injectIntl(TypeformAnswer);
