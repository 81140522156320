import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  inputTypes, columnPropTypes, dateFormat, FILE, SECTION_TITLE,
} from '../constants';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '../../../../common/constants/validation';
import { formatCellData } from '../formatters/cellDataFormatter';
import { threadSelector } from '../../../../taskContentComments/selectors';
import { commentableTypes } from '../../../../taskContentComments/constants';
import ValidationIcon from '../ValidationIcon';
import AttachmentGrid from '../../../../common/AttachmentGrid';
import RecordScore from '../RecordScore';
import DateTimeZone from '../../../../common/DateTimeZone';
import { TableComments } from '../../../../common/Table';

const doesRowHaveErrors = errorsForRow => (
  Object.values(errorsForRow).some(rowError => rowError.some(e => e.type === VALIDATION_ERROR))
);

const allFieldsTouched = (errorsForRow) => {
  Object.keys(errorsForRow).every(errorKey => errorsForRow[errorKey] !== undefined);
};

const calculateValidationStatus = (errorsForRow, fieldName) => {
  const fieldErrors = errorsForRow[fieldName] || [];
  const response = { validationStatus: null, validationMessage: null };

  if (fieldErrors.length === 0) return response;

  if (fieldErrors.some(e => e.type === VALIDATION_ERROR)) {
    response.validationStatus = VALIDATION_ERROR;
  } else if (fieldErrors.some(e => e.type === VALIDATION_WARNING)) {
    response.validationStatus = VALIDATION_WARNING;
  }

  response.validationMessage = fieldErrors.find(
    e => e.type === response.validationStatus).message;

  return response;
};

const isSectionTitle = ({ cellType }) => cellType === SECTION_TITLE;

const getSectionSubColumns = (columns, index) => {
  const subColumns = _.slice(columns, index + 1);
  if (!subColumns.length) return [];

  let nextSectionTitleIndex = _.findIndex(subColumns, isSectionTitle);
  nextSectionTitleIndex = (nextSectionTitleIndex === -1) ? undefined : nextSectionTitleIndex;
  return _.slice(subColumns, 0, nextSectionTitleIndex);
};

const Body = ({
  widgetId,
  timelineTaskId,
  columns,
  errors,
  rows,
  setListMode,
  isTaskCompleted,
  hideTaskContentComments,
  router,
  routePrefix,
  timeZone,
}) => (
  <tbody className='showtime-table__body'>
    {rows.map((row, rowIndex) => {
      const { rowId } = row;
      const rowKey = `table__row--${rowId}`;

      const errorsForRow = errors[rowId] || {};
      const isRowInvalid = doesRowHaveErrors(errorsForRow);
      const isValid = !isRowInvalid && allFieldsTouched(errorsForRow);

      const rowCounterOrValidationIcon = isRowInvalid || isValid
        ? <ValidationIcon status={ VALIDATION_ERROR } />
        : rowIndex + 1;

      const counterCellClassNames = classNames(
        'showtime-table__cell',
        'showtime-table__cell--large',
        'showtime-table__cell--center',
        'showtime-widget-v2__overview__cell',
        {
          'is-invalid': isRowInvalid,
          'is-valid': isValid,
        }
      );

      const rowClassNames = classNames(
        'showtime-table__row',
        { 'showtime-table__row--highlight-hover': !isTaskCompleted }
      );

      return (
        <tr
          className={ rowClassNames }
          key={ rowKey }
          onClick={ () => setListMode(rowIndex) }
        >
          <td className={ counterCellClassNames }>
            <span className='showtime-label showtime-label--pill is-muted'>
              <span className='showtime-label__text'>
                { rowCounterOrValidationIcon }
              </span>
            </span>
          </td>
          {columns.map((column, index) => {
            const { cellSettings = {}, cellType, columnTitle } = column;
            const {
              cellColumnHeading,
              cellStyle,
              fieldName,
              prefixText,
              suffixText,
            } = cellSettings;

            const unknownCellType = !inputTypes.includes(cellType);
            if (unknownCellType) {
              return null;
            }

            const defaultValue = '-';
            const value = _.get(row, fieldName, defaultValue);

            const columnId = `${rowId}--${columnTitle}`;
            const {
              validationStatus,
              validationMessage,
            } = calculateValidationStatus(errorsForRow, fieldName);

            const cellClassNames = classNames(
              'showtime-table__cell',
              'showtime-widget-v2__overview__cell',
              cellStyle || '',
              {
                'is-invalid': validationStatus === VALIDATION_ERROR,
                'is-warning': validationStatus === VALIDATION_WARNING,
              }
            );

            const isLastRow = rowIndex === rows.length - 1;
            const iconClassNames = classNames(
              'showtime-table__cell__error-prefix',
              'hint--medium',
              isLastRow
                ? 'hint--top'
                : 'hint--bottom',
              validationStatus === VALIDATION_ERROR
                ? 'hint--error'
                : 'hint--warning'
            );

            const subColumns = isSectionTitle(column) ? getSectionSubColumns(columns, index) : [];
            const sectionScore = !!subColumns.length && (
              <RecordScore row={ row } columns={ subColumns } defaultValue='-' />
            );

            const widgetFieldComments = useSelector(
              state => (
                threadSelector(
                  state,
                  {
                    timelineTaskId,
                    commentableType: commentableTypes.widget,
                    commentableId: widgetId,
                    widgetRowId: rowId,
                    widgetFieldName: fieldName,
                  }
                )
              )
            );

            return (
              <td
                key={ columnId }
                className={ cellClassNames }
                data-col-heading={ cellColumnHeading || '' }
                data-test={ fieldName }
              >
                { (validationStatus) && (
                  <span className={ iconClassNames } data-hint={ validationMessage } data-test={ `${validationStatus}` }>
                    <ValidationIcon status={ validationStatus } />
                  </span>
                )}
                {prefixText &&
                  (
                    <label className='showtime-label showtime-label--prefix'>
                      <span className='showtime-label__text'>
                        { prefixText }
                      </span>
                    </label>
                  )}
                { cellType === FILE && value !== defaultValue && (
                  <AttachmentGrid
                    files={ value }
                    isClickable={ isTaskCompleted }
                    router={ router }
                    routePrefix={ routePrefix }
                  />
                )}
                { sectionScore }
                { !sectionScore && formatCellData(value, cellType, cellSettings) }
                {suffixText &&
                  (
                    <label className='showtime-label showtime-label--suffix'>
                      <span className='showtime-label__text'>
                        { suffixText }
                      </span>
                    </label>
                  )}
                { !hideTaskContentComments && (
                  <TableComments
                    comments={ widgetFieldComments }
                    isClickable={ isTaskCompleted }
                    routePrefix={ routePrefix }
                    viewSignature={ false }
                  />
                ) }
              </td>
            );
          })}
          <td className='showtime-table__cell showtime-widget-v2__overview__cell' data-test='lastUpdated'>
            <span className='showtime-label__text'>
              {row.lastUpdated && (
                <DateTimeZone
                  date={ row.lastUpdated }
                  format={ dateFormat }
                  timeZone={ timeZone }
                />
              ) }
            </span>
          </td>
          {columns.some(({ cellSettings: { scores } = {} }) => _.size(scores)) && (
            <td className='showtime-table__cell showtime-widget-v2__overview__cell'>
              <span className='showtime-label__text'>
                <RecordScore row={ row } columns={ columns } />
              </span>
            </td>
          )}
        </tr>
      );
    })}
  </tbody>
);

Body.propTypes = {
  ...columnPropTypes,
  widgetId: PropTypes.number,
  timelineTaskId: PropTypes.number,
  errors: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  setListMode: PropTypes.func.isRequired,
  isTaskCompleted: PropTypes.bool.isRequired,
  hideTaskContentComments: PropTypes.bool,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
};

Body.defaultProps = {
  widgetId: null,
  timelineTaskId: null,
  hideTaskContentComments: false,
  routePrefix: '',
};

export default Body;
