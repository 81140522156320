export const permissions = {
  EVERYONE: 'enduser',
  MANAGER: 'manager',
  ORG_ADMIN: 'organization_admin',
};

export const PERMISSION_OPTIONS = [
  permissions.EVERYONE, permissions.MANAGER,
];

export const DEFAULT_PERMISSION = permissions.EVERYONE;

export const CHANGE_PERMISSION_LEVEL = '@TASK_PERMISSION change_permission_level';
export const UPDATE_ASSIGNED_USER = '@TASK_PERMISSION update_assigned_user';
export const CLEAR_ASSIGNMENTS = '@TASK_PERMISSION clear_assignments';
export const UPDATE_ASSIGNED_CUSTOM_ROLE = '@TASK_PERMISSION update_assigned_custom_role';
export const UPDATE_CUSTOM_ROLE_PERMISSIONS = '@TASK_PERMISSION update_custom_role_permissions';
export const CHANGE_TASK_FLAGGING = '@TASK_PERMISSION change_task_flagging';
export const CHANGE_TASK_PRIORITY = '@TASK_PERMISSION change_task_priority';
export const CHANGE_TASK_REQUIRES_PIN = '@TASK_PERMISSION change_task_requires_pin';

export const PRIORITY_VALUES = {
  MEDIUM: 'medium',
  HIGH: 'high',
  LOW: 'low',
};

export const FLAG_VALUES = {
  WITHOUT_COMMENT: 'without_comment',
  NOT_FLAGGABLE: 'not_flaggable',
};

export const editPermissions = {
  ALL: 'all',
  SCHEDULE: 'schedule',
  ASSIGN_SITES: 'assign_sites',
};

export const editPermissionOptions = {
  ALL: [editPermissions.ALL],
  NONE: [],
  SCHEDULE_AND_ASSIGN_SITES: [editPermissions.SCHEDULE, editPermissions.ASSIGN_SITES],
};
