import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import PDFComment from './PDFComment';

const PDFTaskComments = ({
  intl, taskId, comments, timeZone,
}) => (
  <View is='div'>
    <Text
      is='span'
      style={ {
        textTransform: 'uppercase',
        color: '#92929b',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#f6f6f6',
        marginTop: 15,
        paddingBottom: 5,
        fontSize: 10,
        fontWeight: 600,
      } }
    >
      {intl.formatMessage({ id: 'taskReports.taskComments.commentHeader' })}
    </Text>
    <View
      is='div'
      style={ {
        fontSize: 10,
      } }
    >
      {
        comments.map(
          comment => (
            <PDFComment
              key={ `comment-${taskId}-${comment.timestamp}` }
              commentedBy={ comment.user_name }
              commentedAt={ comment.timestamp }
              content={ comment.content }
              intl={ intl }
              timeZone={ timeZone }
            />
          ))
      }
    </View>
  </View>
);

PDFTaskComments.propTypes = {
  taskId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default PDFTaskComments;
