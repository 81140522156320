import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import Input from '../common/forms/Input';
import Button from '../common/Button';

class CouponPromoCodeInput extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      disabled: true,
    };
  }

  onChange = (value) => {
    this.setState({ value, disabled: false });
  };

  handleApplyClick = () => {
    this.state.value !== '' && this.props.onClick(this.state.value);
    this.setState({ disabled: true });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className='showtime-billing__new-promo'
          data-test='new-promo'
        >
          <div className='showtime-input'>
            <Input
              dataTest='new-promo-value'
              id='promo'
              placeholder={ this.props.intl.formatMessage({ id: 'billing.newPromoPlaceholder' }) }
              onChange={ this.onChange }
              value={ this.state.value }
            />
          </div>
          <Button
            loading={ this.props.loading }
            dataTest='apply-promo'
            className={ classnames(
              'showtime-button--lowlight', {
                'is-disabled': this.state.disabled,
              }
            ) }
            onClick={ this.handleApplyClick }
          >
            {this.props.intl.formatMessage({ id: 'billing.applyPromo' })}
          </Button>
        </div>
        <div className='showtime-billing__new-promo-error' data-test='new-promo-error'>{this.props.promoError}</div>
      </React.Fragment>
    );
  }
}

CouponPromoCodeInput.propTypes = {
  promoError: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CouponPromoCodeInput.defaultProps = {
  promoError: null,
  loading: false,
};

export default injectIntl(CouponPromoCodeInput);
