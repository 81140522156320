import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import { logicConditions } from '../../../constants';
import { EditWidgetActions } from '../../../actions';
import SelectField from '../../../../common/forms/SelectField';
import { NO_SELECTION } from '../../../../common/forms/Select';
import { noLogicIfCaseValueError } from '../../../reducers/builderValidation/constants';
import { VALIDATION_ERROR } from '../../../../common/constants/validation';
import { createIntlMessageFormatter } from '../../../../common/intlMessageFormatter';

const {
  columnLogicByIndexSelector, columnLogicPathSelector, logicIfValueOptionsSelector,
  widgetBuilderColumnLogicErrorsSelector,
} = TaskWidgetSelectors;
const { setWidgetPublicSettings } = EditWidgetActions;

const LogicIf = ({ columnIndex, logicIndex }) => {
  const formatLogicMessage = createIntlMessageFormatter(useIntl(), 'editWidget.builder.optionsColumn.logic');
  const { ifCase = {} } = useSelector(state => (
    columnLogicByIndexSelector(state, { columnIndex, logicIndex })
  ));

  const options = useSelector(
    state => logicIfValueOptionsSelector(state, { columnIndex, logicIndex }));

  const dispatch = useDispatch();
  const logicUpdatePath = useSelector(
    state => columnLogicPathSelector(state, { logicIndex, columnIndex }));
  const updateIfCase = (key) => {
    const updatePath = `${logicUpdatePath}.ifCase.${key}`;
    return value => dispatch(setWidgetPublicSettings(updatePath, value));
  };

  const errors = useSelector(
    state => widgetBuilderColumnLogicErrorsSelector(state, { columnIndex }));
  const validationMessages = errors.includes(`${noLogicIfCaseValueError}[${logicIndex}]`)
    ? [{ type: VALIDATION_ERROR, message: formatLogicMessage('errors.noIfCaseValue') }]
    : [];

  return (
    <div className='showtime-record-log-builder-field--block'>
      <SelectField
        onChange={ updateIfCase('value') }
        value={ _.find(options, { value: ifCase.value }) ? ifCase.value : NO_SELECTION }
        includeEmptyOption
        dataTest='logic.ifCase.value'
        options={ options }
        errors={ validationMessages }
      />
      <div className='showtime-utility-font-body-small'>
        { formatLogicMessage('condition.is') }
      </div>
      <SelectField
        onChange={ updateIfCase('condition') }
        value={ ifCase.condition }
        dataTest='logic.ifCase.condition'
        errors={ [] }
        options={
          [logicConditions.selected, logicConditions.notSelected].map(value => ({
            value, label: formatLogicMessage(`condition.${value}`),
          }))
        }
      />
    </div>
  );
};

LogicIf.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  logicIndex: PropTypes.number.isRequired,
};

export default LogicIf;
