import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Label from './Label';
import ValidationMessages from './ValidationMessages';
import { analyzeErrors } from '../constants/validation';
import Button from '../Button';

import {
  CopyValueToNewLabel,
  LockedLabel,
} from './AnnotationLabels';

const ButtonSelect = ({
  onChange, value, options, disabled,
}) => options.map((option, index) => {
  if (!option.label) return null;

  const isSelected = value === option.value;
  const keyToAvoidDuplicateValueBugsOnPreview = `${index}${option.value}`;
  return (
    <Button
      dataTest={ `button-select-option-${isSelected ? 'selected' : 'deselected'}` }
      key={ keyToAvoidDuplicateValueBugsOnPreview }
      className={ classNames(
        {
          'showtime-button--record-selected': isSelected,
          'showtime-button--default': !isSelected,
          'is-disabled': disabled,
        }
      ) }
      onClick={ () => onChange(option.value) }
    >
      {option.label}
    </Button>
  );
});

export const ButtonSelectField = ({
  containerClass,
  disabled,
  locked,
  errors,
  id,
  label,
  name,
  onChange,
  options,
  refFn,
  repeating,
  required,
  value,
  dataTest,
  children,
  inputChildren,
}) => {
  const isEmpty = value === '';
  const {
    isValid,
    anyValidationErrors,
    allValidationWarnings,
  } = analyzeErrors(errors);

  const containerClasses = classNames(
    containerClass, {
      'is-empty': disabled && isEmpty,
      'is-invalid': anyValidationErrors,
      'is-warning': allValidationWarnings,
      'is-restricted': disabled && !isEmpty,
    }
  );

  const dropdownClasses = classNames(
    'showtime-input--button-options'
  );

  const dataTestProps = dataTest && { 'data-test': dataTest };

  return (
    <div className={ containerClasses } { ...dataTestProps }>
      <Label htmlFor={ id } required={ required }>{ label }</Label>
      <div className={ dropdownClasses }>
        <ButtonSelect
          disabled={ disabled }
          id={ id }
          name={ name }
          onChange={ onChange }
          options={ options }
          refFn={ refFn }
          required={ required }
          value={ value }
        />
        { inputChildren }
      </div>
      {!isValid
        && (
        <ValidationMessages
          fieldId={ id }
          errors={ errors }
          someErrors={ anyValidationErrors }
        />
        )}
      {locked
        && <LockedLabel />}
      {repeating && isValid
        && <CopyValueToNewLabel />}
      { children }
    </div>
  );
};

ButtonSelectField.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ).isRequired,
  refFn: PropTypes.func,
  repeating: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  dataTest: PropTypes.string,
  children: PropTypes.node,
  inputChildren: PropTypes.node,
};

ButtonSelectField.defaultProps = {
  containerClass: '',
  disabled: false,
  locked: false,
  name: null,
  refFn: () => {},
  repeating: false,
  required: false,
  value: undefined,
  dataTest: undefined,
  children: null,
  inputChildren: null,
};

export default ButtonSelectField;
