import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  STATUS_SAVED,
  STATUS_SAVING,
  STATUS_OFFLINE,
} from '../constants';

const SaveStatus = ({
  intl,
  status,
}) => (
  <label className='showtime-label showtime-label--status'>
    <span className='showtime-label__text'>{ intl.formatMessage({ id: `trail.widgets.header.${status}` }) }</span>
  </label>
);

SaveStatus.propTypes = {
  status: PropTypes.oneOf([
    STATUS_SAVED,
    STATUS_SAVING,
    STATUS_OFFLINE,
  ]).isRequired,
};

export default injectIntl(SaveStatus);
