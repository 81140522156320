import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { TrailConstants } from '../../trail/constants/index';
import { CalendarSvg, CheckCircleSvg, RemoveCircleSvg, ClockSvg, FlagSvg } from './svg';

const statusIcons = {
  [TrailConstants.taskStatus.IN_PROGRESS]: CalendarSvg,
  [TrailConstants.taskStatus.LATER]: CalendarSvg,
  [TrailConstants.taskStatus.MISSED]: RemoveCircleSvg,
  [TrailConstants.taskStatus.COMPLETED]: CheckCircleSvg,
  [TrailConstants.taskStatus.COMPLETED_LATE]: CheckCircleSvg,
  [TrailConstants.taskStatus.COMPLETED_EARLY]: CheckCircleSvg,
  [TrailConstants.taskStatus.SNOOZED]: ClockSvg,
  [TrailConstants.taskStatus.OVERDUE]: ClockSvg,
  [TrailConstants.taskStatus.FLAGGED]: FlagSvg,
};

const statusColors = {
  [TrailConstants.taskStatus.IN_PROGRESS]: '#4688F1',
  [TrailConstants.taskStatus.MISSED]: '#FF513D',
  [TrailConstants.taskStatus.COMPLETED]: '#13BF1F',
  [TrailConstants.taskStatus.COMPLETED_LATE]: '#FF9100',
  [TrailConstants.taskStatus.COMPLETED_EARLY]: '#13BF1F',
  [TrailConstants.taskStatus.OVERDUE]: '#FF9100',
  [TrailConstants.taskStatus.FLAGGED]: '#646470',
  [TrailConstants.taskStatus.SNOOZED]: '#646470',
};

const borderColors = {
  [TrailConstants.taskStatus.IN_PROGRESS]: '#C3D8FA',
  [TrailConstants.taskStatus.MISSED]: '#FFCCC7',
  [TrailConstants.taskStatus.COMPLETED]: '#BBEDBF',
  [TrailConstants.taskStatus.COMPLETED_LATE]: '#FFDFB5',
  [TrailConstants.taskStatus.COMPLETED_EARLY]: '#BBEDBF',
  [TrailConstants.taskStatus.OVERDUE]: '#FFDFB5',
  [TrailConstants.taskStatus.FLAGGED]: '#D2D2D6',
  [TrailConstants.taskStatus.SNOOZED]: '#D2D2D6',
};

const backgroundColors = {
  [TrailConstants.taskStatus.IN_PROGRESS]: '#ECF3FE',
  [TrailConstants.taskStatus.MISSED]: '#FFF6F5',
  [TrailConstants.taskStatus.COMPLETED]: '#F3FCF4',
  [TrailConstants.taskStatus.COMPLETED_LATE]: '#FFF9F2',
  [TrailConstants.taskStatus.COMPLETED_EARLY]: '#F3FCF4',
  [TrailConstants.taskStatus.OVERDUE]: '#FFF9F2',
  [TrailConstants.taskStatus.FLAGGED]: '#F7F7F8',
  [TrailConstants.taskStatus.SNOOZED]: '#F7F7F8',
};

const PDFTaskStatus = ({ intl, status }) => {
  const color = (
    statusColors[status] ||
    statusColors[TrailConstants.taskStatus.FLAGGED]
  );
  const backgroundColor = (
    backgroundColors[status] ||
    backgroundColors[TrailConstants.taskStatus.FLAGGED]
  );
  const borderColor = (
    borderColors[status] ||
    borderColors[TrailConstants.taskStatus.FLAGGED]
  );
  const Icon = statusIcons[status] || null;

  return (
    <View
      is='div'
      style={ {
        padding: '3px 5px',
        fontSize: 9,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 999,
        backgroundColor,
        color,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor,
      } }
    >
      {Icon && <Icon
        style={ {
          fill: color,
          height: 9,
        } }
      />}
      <Text is='span'>
        {intl.formatMessage({ id: `taskReports.task.status.${status}` })}
      </Text>
    </View>
  );
};

PDFTaskStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PDFTaskStatus;
