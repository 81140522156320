/* eslint-disable no-console */
import _ from 'lodash';
import { MetricDataFactory, MetricValidator } from '.';

const MetricsPublisher = (function () {
  function MetricsPublisherConstructor() {
    this.transports = {};
    this.requiredMethods = [
      'init',
      'getKey',
      'recordPageView',
      'recordMetric',
    ];
  }

  function recordMetric(metric, data, selectedTransports) {
    const metricData = MetricDataFactory.build(data, metric);
    const metricValidationInfo = MetricValidator.validateMetricData(metricData, metric);

    if (!metricValidationInfo.valid) {
      const formattedErrorMessage = MetricValidator.getFormattedErrorMessage(metricValidationInfo);
      console.warn(`Invalid metric recorded: '${metricData.name}'\n${formattedErrorMessage}`);
    }

    const transports = this.getTransports(selectedTransports || []);
    const transportTrackCalls = transports
      .map((transport) => {
        if (window.logMetrics) {
          console.info(`Fired tracking event "${metricData.name}" using ${
            transport.getKey()} transport`, metricData.properties);
        }
        return transport.recordMetric(metricData.name, metricData.properties);
      }, this);

    return Promise.all(transportTrackCalls);
  }

  MetricsPublisherConstructor.prototype.getTransports = function (transports) {
    transports = Array.isArray(transports) ? transports : [transports];

    if (!transports.length) {
      return Object.keys(this.transports)
        .map(key => this.transports[key]);
    }

    return Object.keys(this.transports)
      .filter(transportName => (!transports.length) ||
          transports.indexOf(transportName) > -1)
      .map(transportName => this.transports[transportName]);
  };

  MetricsPublisherConstructor.prototype.validateTransport = function (transport) {
    return _.every(this.requiredMethods, requiredMethod => transport[requiredMethod] && typeof transport[requiredMethod] === 'function');
  };

  MetricsPublisherConstructor.prototype.addTransport = function (transport) {
    if (this.validateTransport(transport)) {
      const key = transport.getKey();
      this.transports[key] = transport;
      this.transports[key].init();
    } else {
      console.warn('Invalid transport supplied to MetricsPublisherConstructor', transport);
    }
  };

  MetricsPublisherConstructor.prototype.recordMetric =
    function (metric, data, selectedTransports) {
      if (window.config.env === 'test') {
        return Promise.resolve();
      }

      if (metric.enabled) {
        try {
          return recordMetric.call(this, metric, data, selectedTransports);
        } catch (error) {
          console.error('MetricsPublisher: error recording metric: ', error);
        }
      }
      return Promise.resolve();
    };

  MetricsPublisherConstructor.prototype.recordPageView =
    function (path, title, selectedTransports) {
      selectedTransports = selectedTransports || [];

      this.getTransports(selectedTransports)
        .forEach((transport) => {
          transport.recordPageView.call(transport, path, title);

          if (window.logMetrics) {
            console.info(`Fired page view"${path}" using ${
              transport.getKey()} transport`);
          }
        });
    };

  MetricsPublisherConstructor.prototype.identify =
  function (props, selectedTransports) {
    selectedTransports = selectedTransports || [];

    this.getTransports(selectedTransports)
      .forEach((transport) => {
        if (transport.identify) {
          transport.identify(props);
        }
      });
  };

  return MetricsPublisherConstructor;
}());

export { MetricsPublisher };
