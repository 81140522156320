import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';

import InputField from '../../../common/forms/InputField';
import SettingsHeaderRow from './SettingsHeaderRow';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

const PrefixSuffixSettings = ({
  prefixText, suffixText, onPrefixChange, onSuffixChange, onRemove, intl,
}) => {
  const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.numberColumn');

  return (
    <div className='showtime-record-log-builder-field__config-row'>
      <SettingsHeaderRow
        title={ formatMessage('prefixSuffixLabel') }
        onRemove={ onRemove }
      />
      <div className='showtime-record-log-builder-field__config-split-input'>
        <InputField
          errors={ [] }
          value={ prefixText }
          onChange={ onPrefixChange }
          placeholder={ formatMessage('prefixPlaceholder') }
          containerClass='showtime-record-log-builder-field__input showtime-record-log-builder-field__input--prefix'
          dataTest='widget.columns.prefix'
        />
        <InputField
          disabled
          errors={ [] }
          placeholder={ formatMessage('answer') }
          containerClass='showtime-record-log-builder-field__input showtime-record-log-builder-field__input--placeholder'
        />
        <InputField
          errors={ [] }
          value={ suffixText }
          onChange={ onSuffixChange }
          placeholder={ formatMessage('suffixPlaceholder') }
          containerClass='showtime-record-log-builder-field__input showtime-record-log-builder-field__input--suffix'
          dataTest='widget.columns.suffix'
        />
      </div>
    </div>
  );
};

PrefixSuffixSettings.propTypes = {
  prefixText: PropTypes.string,
  suffixText: PropTypes.string,
  onPrefixChange: PropTypes.func,
  onSuffixChange: PropTypes.func,
  onRemove: PropTypes.func,
};

PrefixSuffixSettings.defaultProps = {
  prefixText: '',
  suffixText: '',
  onPrefixChange() {},
  onSuffixChange() {},
  onRemove() {},
};

const mapStateToProps = (state, { columnIndex }) => {
  const { settings } = TaskWidgetSelectors.editedTaskWidgetConfig(state);
  const { prefixText, suffixText } = _.get(settings, `public.columns[${columnIndex}].cellSettings`, {});

  return { prefixText, suffixText };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    setWidgetPublicSettings, removeColumnPrefixAndSuffix,
  } = bindActionCreators(EditWidgetActions, dispatch);

  const cellSettingsPath = `columns[${ownProps.columnIndex}].cellSettings`;

  const onRemove = () => {
    removeColumnPrefixAndSuffix(ownProps.columnIndex);
    ownProps.onRemove();
  };

  return {
    onPrefixChange: value => setWidgetPublicSettings(`${cellSettingsPath}.prefixText`, value),
    onSuffixChange: value => setWidgetPublicSettings(`${cellSettingsPath}.suffixText`, value),
    onRemove,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(PrefixSuffixSettings);
