import WindowHelper, { WindowHistory } from '../../utils/window';
import { actionTypes } from '../constants';

export default () => next => (action) => {
  if (action.type === actionTypes.route) {
    if (action.js) {
      const history = action.history || WindowHistory;
      const { allowSoftRerouteWithUnsavedChanges, search } = action;
      history.push(
        { pathname: action.route, search, state: { allowSoftRerouteWithUnsavedChanges } }
      );
    } else {
      WindowHelper.navigateTo(action.route);
    }
  }
  return next(action);
};
