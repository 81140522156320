import {
  tasksPath, businessHoursPath, inviteUsersPath, goLivePath,
} from '../constants';

const selectProgressCount = (setupGuide) => {
  const { progress } = setupGuide;

  const steps = progress ? Object.keys(progress) : null;
  if (!steps) return { completedStepCount: 0, totalStepCount: 0 };

  const totalStepCount = steps.length;
  if (totalStepCount === 0) return { completedStepCount: 0, totalStepCount: 0 };

  const completedStepCount = steps.filter(step => progress[step].isComplete).length;

  return { completedStepCount, totalStepCount, progress };
};

const isStepComplete = (setupGuide, stepName) => {
  if (
    setupGuide
    && setupGuide.progress
    && setupGuide.progress[stepName]
  ) return setupGuide.progress[stepName].isComplete;
  return false;
};

const getDefaultPath = (setupGuide) => {
  if (!isStepComplete(setupGuide, 'tasks')) return tasksPath;
  if (!isStepComplete(setupGuide, 'hours')) return businessHoursPath;
  if (!isStepComplete(setupGuide, 'users')) return inviteUsersPath;
  return goLivePath;
};

const getTrialStatus = (setupGuide) => {
  if (setupGuide.trial) return setupGuide.trial;
  return {};
};

const isLoading = (setupGuide) => {
  if ('loading' in setupGuide) return setupGuide.loading;
  return true;
};

export default {
  isLoading: ({ setupGuide }) => isLoading(setupGuide),
  progressSelector: ({ setupGuide }) => selectProgressCount(setupGuide),
  isTasksStepCompleteSelector: ({ setupGuide }) => isStepComplete(setupGuide, 'tasks'),
  isHoursStepCompleteSelector: ({ setupGuide }) => isStepComplete(setupGuide, 'hours'),
  isUsersStepCompleteSelector: ({ setupGuide }) => isStepComplete(setupGuide, 'users'),
  isLiveStepCompleteSelector: ({ setupGuide }) => isStepComplete(setupGuide, 'live'),
  defaultPath: ({ setupGuide }) => getDefaultPath(setupGuide),
  trialStatus: ({ setupGuide }) => getTrialStatus(setupGuide),
};
