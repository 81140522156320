import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Url from 'url';
import _ from 'lodash';
import createWidgetComponent from '../HoC';
import widgetDataListenerHoC from '../HoC/widgetDataListener';
import { WidgetDataStore } from '../../trail/store';
import ExpandableContent from '../../common/ExpandableContent';
import TypeformAnswer from './TypeformAnswer';
import WindowHelper from '../../utils/window';
import SVGIcon from '../../common/SVGIcon';
import { WidgetDataConstants } from '../../trail/constants';
import { WidgetDataActions } from '../../trail/actions';
import Button from '../../common/Button';
import OfflineInteractionBlocker from '../../offline-interaction-blocker';
import hasServiceWorker from '../../utils/service-workers/helpers/hasServiceWorker';

export class FormWidget extends Component {
  constructor(props) {
    super(props);
    this.pollingInterval = null;
  }

  componentWillMount() {
    if (!this.hasFormDataLoaded()) {
      this.pollingInterval = setInterval(
        !(hasServiceWorker() && !navigator.onLine) &&
          WidgetDataActions.getWidgetDataBatch.bind(
            WidgetDataActions,
            [this.props.widget.widget().id]
          ),
        WidgetDataConstants.POLLING_INTERVAL_PERIOD);
    }

    WidgetDataStore.on(
      WidgetDataConstants.WIDGET_UPDATED, (id, data) =>
        this.onWidgetUpdate(id, data)
    );
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
    WidgetDataStore.removeListener(WidgetDataConstants.WIDGET_UPDATED, this.onWidgetUpdate);
  }

  onWidgetUpdate(id, data) {
    if (this.props.widget.widget().id === id && !!data.custom_fields.form_data) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  displayFormData() {
    const answers = (this.props.widget.widget().custom_fields &&
                      this.props.widget.widget().custom_fields.form_data &&
                      this.props.widget.widget().custom_fields.form_data.answers) || [];

    return (
      <div className='showtime-widget__body'>
        <ExpandableContent>
          <ul className='showtime-list'>
            { answers.map((answer, index) => (
              <li className='showtime-list__item' key={ `answer-${  index}` }>
                <div className='showtime-report'>
                  <p className='showtime-report__question'>
                    {answer.field.question}
                  </p>
                  <p className='showtime-report__answer'>
                    <TypeformAnswer answer={ answer } />
                  </p>
                </div>
              </li>
              ), this) }
          </ul>
        </ExpandableContent>
      </div>
    );
  }

  displayFormLink() {
    return (this.props.widget.widget().public_settings
      ? (
        <div className='showtime-widget__action-bar'>
          <OfflineInteractionBlocker
            tooltipMessage={ this.props.intl.formatMessage({ id: 'offline.notAvailable' }) }
          >
            <Button
              onClick={ this.handleLinkClick }
              buttonClasses='showtime-button--default'
              label={ this.props.intl.formatMessage({ id: 'formWidget.actionCreate' }) }
            />
          </OfflineInteractionBlocker>
        </div>
      )
      : null
    );
  }

  handleLinkClick = () => {
    WindowHelper.navigateTo(this.getUrl());
  }

  hasFormDataLoaded() {
    const customFields = this.props.widget.widget().custom_fields;
    return customFields && customFields.form_data;
  }

  getUrl() {
    const formUrl = Url.parse(this.props.widget.widget().public_settings &&
                            this.props.widget.widget().public_settings.form_url);

    const preprocessedData = _.get(this.props.widget.widget(), 'preprocessed_data', {});
    const params = {
      trail_token: preprocessedData.form_token,
      env: window.config.env,
      trail_name: window.config.locationName,
    };

    return params.trail_token ? Url.format(_.merge(formUrl, {
      query: params,
    })) : '';
  }

  render() {
    return (
      <div className='showtime-widget'>
        <div className='showtime-widget__header'>
          <div className='showtime-widget__title-and-icon'>
            <div className='showtime-widget__icon'>
              <SVGIcon
                classes='showtime-icon showtime-icon--size-16'
                iconName='mini/business-finance/business-16px_notes'
              />
            </div>
            <div className='showtime-widget__title'>
              { this.props.widget.widget().public_settings && this.props.widget.widget().public_settings.heading }
            </div>
          </div>
          <div className='showtime-widget__status'>{this.renderStatus()}</div>
        </div>
        { this.hasFormDataLoaded()
          ? this.displayFormData()
          : this.displayFormLink()
        }
      </div>
    );
  }

  renderStatus() {
    let status = '';

    if (!this.hasFormDataLoaded()) {
      status = this.props.intl.formatMessage({ id: 'formWidget.statusWaiting' });
    }

    return status;
  }
}

const createWidgetHoC = createWidgetComponent(WidgetDataStore);
export default injectIntl(createWidgetHoC(widgetDataListenerHoC(FormWidget)));
