import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import * as requestStatus from '../../common/requestStatuses';
import { TaskReportsSelectors } from '../selectors';

import LoadingSpinner from '../../common/LoadingSpinner';
import Button from '../../common/Button';

const {
  taskTemplatesRequestStatusSelector,
  timelineTasksRequestStatusSelector,
  shouldLoadMoreTasksSelector,
  windowingDateSelector,
} = TaskReportsSelectors;

const EndOfTasks = injectIntl(({ intl: { formatMessage } }) => (
  <div className='showtime-task-reports__footer showtime-task-reports__end-of-tasks' data-test='end-of-tasks'>
    {formatMessage({ id: 'taskReports.endOfTasks' })}
  </div>
));

const RetryButton = injectIntl(({ onClick, intl: { formatMessage } }) => (
  <div className='showtime-task-reports__footer'>
    <Button
      onClick={ onClick }
      className='showtime-button showtime-button--default'
      dataTest='taskReports.retry'
    >
      { formatMessage({ id: 'taskReports.retryTasksFetch' })}
    </Button>
  </div>
));

const searchMessage = (formatMessage, windowingDate) => {
  if (!windowingDate) return null;

  const dateFormat = formatMessage({ id: 'taskReports.searchToDateFormat' });
  const formattedDate = moment(windowingDate).format(dateFormat);
  return formatMessage({ id: 'taskReports.searchTo' }, { windowingDate: formattedDate });
};

const Loading = injectIntl(({ windowingDate, intl: { formatMessage } }) => {
  const message = searchMessage(formatMessage, windowingDate);
  return <LoadingSpinner label={ message } />;
});

export const TasksFooter = ({
  hasMore, isLoading, windowingDate, hasErrored, onRetry, isNotRequested,
}) => {
  if (isNotRequested) return null;
  if (isLoading) return <Loading windowingDate={ windowingDate } />;
  if (hasErrored) return <RetryButton onClick={ onRetry } />;

  if (!hasMore) return <EndOfTasks />;
  return <Loading windowingDate={ windowingDate } />;
};

TasksFooter.propTypes = {
  hasErrored: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  windowingDate: PropTypes.string,
  isNotRequested: PropTypes.bool.isRequired,
};

TasksFooter.defaultProps = {
  windowingDate: null,
};

const mapStateToProps = (state, ownProps) => {
  const taskRequests = [
    taskTemplatesRequestStatusSelector(state, ownProps),
    timelineTasksRequestStatusSelector(state, ownProps),
  ];
  return ({
    isNotRequested: taskRequests.some(requestStatus.isNotRequested),
    isLoading: taskRequests.some(requestStatus.isLoading),
    hasErrored: taskRequests.some(requestStatus.hasError),
    hasMore: shouldLoadMoreTasksSelector(state, ownProps),
    windowingDate: windowingDateSelector(state, ownProps),
  });
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(TasksFooter);
