import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import TaskBody from '../trail/TaskBody';

export class TaskPreview extends Component {
  state = {
    previewWarning: false,
  };

  toggleWarning = () => {
    this.setState(prevState => ({ previewWarning: !prevState.previewWarning }));
  };

  resetPreviewWarning = () => {
    this.setState({ previewWarning: false });
  };

  hasPreviewData = ({
    notes, contents, subtasks, widget_configuration: widgetConfiguration,
  }) => (
    Boolean(notes ||
      widgetConfiguration ||
      (contents && contents.length) ||
      (subtasks && subtasks.length))
  );

  renderPreviewWarning = () => {
    if (this.state.previewWarning) {
      return (
        <div className='showtime-task-preview__warning' data-test='task.preview.warning'>
          <div className='showtime-task-preview__warning-title'>
            { this.props.intl.formatMessage({ id: 'taskplanner.previewNoEdit' }) }
          </div>
          <div className='showtime-task-preview__warning-description'>
            { this.props.intl.formatMessage({ id: 'taskplanner.previewNoEditDescription' }) }
          </div>
        </div>
      );
    }

    return null;
  };

  renderPreviewHeader = (showHeader, taskPreview) => {
    if (showHeader) {
      const taskTitle = taskPreview.name ||
        this.props.intl.formatMessage({ id: 'newTask.defaultTitle' });

      return (
        <div className='showtime-task__header'>
          <div className='showtime-task__info'>
            <h4 className='showtime-task__title' data-test='task.title'>{ taskTitle }</h4>
          </div>
        </div>
      );
    }

    return null;
  };

  renderPreviewTaskBody = (taskPreview, subtasksPreview, widgetDataPreview) => {
    if (this.hasPreviewData(taskPreview)) {
      return (
        <TaskBody
          task={ taskPreview }
          subtasks={ subtasksPreview }
          widgetData={ widgetDataPreview }
          taskExpanded
          readOnly
          preview
          allowComments={ false }
        />
      );
    }

    return (
      <div className='showtime-task__body'>
        <div className='showtime-task__body-item showtime-task__body-item--description'>
          { this.props.intl.formatMessage({ id: 'taskplanner.noPreviewContent' }) }
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.taskTemplate.id !== this.props.taskTemplate.id) {
      this.resetPreviewWarning();
    }
  }

  render() {
    const { taskTemplate } = this.props;
    const taskPreview = taskTemplate;
    const subtasksPreview = taskTemplate.subtasks || [];
    const widgetDataPreview = taskTemplate.widget_configuration;
    const previewClasses = classNames({
      'showtime-task-preview': true,
      'is-warning': this.state.previewWarning,
    });

    return (
      <div
        className={ previewClasses }
        data-test='task.preview'
        onClick={ this.toggleWarning }
      >
        { !this.props.hidePreviewWarning && this.renderPreviewWarning() }
        <div className='showtime-task-preview__content'>
          {
            this.renderPreviewHeader(
              this.props.showHeader, taskPreview
            )
          }
          { this.renderPreviewTaskBody(taskPreview, subtasksPreview, widgetDataPreview) }
        </div>
      </div>
    );
  }
}

TaskPreview.propTypes = {
  taskTemplate: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
  hidePreviewWarning: PropTypes.bool,
};

TaskPreview.defaultProps = {
  hidePreviewWarning: false,
};

export default injectIntl(TaskPreview);
