import PropTypes from 'prop-types';
import React from 'react';

export const NO_SELECTION = 'No Selection';

const Select = ({
  disabled,
  className,
  dataTest,
  id,
  includeEmptyOption,
  name,
  onChange,
  options,
  refFn,
  value,
  onFocus,
  onBlur,
}) => (
  <select
    disabled={ disabled }
    id={ id }
    name={ name }
    onChange={ ({ target: { value: newValue } }) => onChange(newValue) }
    ref={ refFn }
    value={ value }
    className={ className }
    data-test={ dataTest }
    onFocus={ onFocus }
    onBlur={ onBlur }
  >
    {includeEmptyOption &&
      <option disabled key='-1' selected value={ NO_SELECTION } />}

    {options.map(({
      label,
      value: optionValue,
    }) => (
      <option
        key={ `select-${id}-${optionValue}-${label}` }
        value={ optionValue }
      >
        {label}
      </option>
    ))}
  </select>
);

Select.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  includeEmptyOption: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ).isRequired,
  name: PropTypes.string,
  refFn: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  dataTest: PropTypes.string,
  onFocus: PropTypes.bool,
  onBlur: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  dataTest: undefined,
  includeEmptyOption: false,
  name: null,
  refFn: null,
  value: undefined,
  className: undefined,
  onFocus: undefined,
  onBlur: undefined,
};

export default Select;
