import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import { TaskPlannerActions, TaskPlannerTableActions } from './actions';
import { TaskPlannerConstants } from './constants';
import FlashActions from '../flash/actions';
import { TaskPlannerSelectors } from './selectors';

import Button from '../common/Button';
import ClearableSearchInput from '../common/ClearableSearchInput';

import store from '../store';
import MoreActionMenu from './MoreActionMenu';
import TaskTemplateCounts from './TaskTemplateCounts';
import AdminActionBarButton from './AdminActionBarButton';

export class TaskPlannerActionBar extends Component {
  componentWillReceiveProps(nextProps) {
    const numberOfTasks = this.props.table.selectedTasks.length || 1;
    if (nextProps.table.showAssignToTrailSuccessNotice) {
      const notice = `${numberOfTasks}${this.props.intl.formatMessage({ id: 'taskplanner.tasks_updated' })}`;
      FlashActions.notice(notice, store);
      this.props.actions.taskPlanner.removeAssignmentSuccessNotice();
    }
    if (nextProps.table.showAddTagsToTasksSuccessNotice) {
      const notice = `${numberOfTasks}${this.props.intl.formatMessage({ id: 'taskplanner.tasks_updated' })}`;
      FlashActions.notice(notice, store);
      this.props.actions.taskPlanner.removeAddTagsToTasksSuccessNotice();
    }
    if (nextProps.table.showRemoveTagsFromTasksSuccessNotice) {
      const notice = `${numberOfTasks}${this.props.intl.formatMessage({ id: 'taskplanner.tasks_updated' })}`;
      FlashActions.notice(notice, store);
      this.props.actions.taskPlanner.removeTagsFromTasksSuccessNotice();
    }
    if (nextProps.table.showUnassignFromTrailSuccessNotice) {
      const notice = `${numberOfTasks}${this.props.intl.formatMessage({ id: 'taskplanner.tasks_updated' })}`;
      FlashActions.notice(notice, store);
      this.props.actions.taskPlanner.removeUnassignmentSuccessNotice();
    }
    if (nextProps.table.showDeleteSuccessNotice) {
      const notice = `${numberOfTasks}${this.props.intl.formatMessage({ id: 'taskplanner.tasks_deleted' })}`;
      FlashActions.notice(notice, store);
      this.props.actions.taskPlanner.removeDeleteSuccessNotice();
    }
  }

  onSearchBlur = (searchTerm) => {
    if (searchTerm) {
      metricsPublisher.recordMetric(
        TaskPlannerConstants.metricsDirectoryPage(this.props.singleLocation).SEARCH_PERFORMED,
        { searchTerm }
      );
    }
  };

  onSelectAll = () => {
    const metric = TrailMetricsDirectory.page.TaskPlanner.SELECT_ALL_TASK_TEMPLATES_CLICKED;
    metricsPublisher.recordMetric(metric);

    this.props.actions.taskPlanner.selectAllTasks(this.props.taskPlanner.filteredTaskTemplates);
  };

  onClearAll = () => {
    const metric = TrailMetricsDirectory.page.TaskPlanner.CLEAR_SELECTED_TASK_TEMPLATES_CLICKED;
    metricsPublisher.recordMetric(metric);

    this.props.actions.taskPlanner.clearAllTasks();
  };

  onSelectingTrail = (event) => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskPlanner.ASSIGN_TO_TRAIL_LOCATION_SELECTED,
      { trailId: parseInt(event.target.value, 10) }
    );

    this.props.actions.taskPlanner.selectTrail(event.target.value);
  };

  onConfirmAssignToTrail = (locationIds) => {
    const { selectedTasks } = this.props.table;
    const metric = TrailMetricsDirectory.page.TaskPlanner.ASSIGN_TO_TRAIL_CONFIRMED;
    metricsPublisher.recordMetric(metric);

    this.props.actions.taskPlanner.confirmAssign(selectedTasks, locationIds);
  };

  onConfirmAddTagsToTasks = (tagIds) => {
    const { selectedTasks } = this.props.table;

    this.props.actions.taskPlanner.confirmAddTagsToTasks(selectedTasks, tagIds);
  };

  onCancelAssignToTrail = () => {
    metricsPublisher.recordMetric(TrailMetricsDirectory.page.TaskPlanner.ASSIGN_TO_TRAIL_CANCELED);

    this.props.actions.taskPlanner.toggleAssignConfirmation(-1);
  };

  onCancelAddTagsToTrail = () => {
    this.props.actions.taskPlanner.toggleAddTagsToTasks(-1);
  };

  onConfirmUnassignToTrail = (locationIds) => {
    const { selectedTasks } = this.props.table;
    const metric = TrailMetricsDirectory.page.TaskPlanner.UNASSIGN_FROM_TRAIL_CONFIRMED;
    metricsPublisher.recordMetric(metric);
    this.setState({
      showUnassign: false,
    });

    this.props.actions.taskPlanner.confirmUnassign(selectedTasks, locationIds);
  };

  onCancelUnassignToTrail = () => {
    const metric = TrailMetricsDirectory.page.TaskPlanner.UNASSIGN_FROM_TRAIL_CANCELED;
    metricsPublisher.recordMetric(metric);
    this.setState({
      showUnassign: false,
    });

    this.props.actions.taskPlanner.toggleUnassignConfirmation(-1);
  };

  onConfirmRemoveTagsFromTasks = (tagIds) => {
    const { selectedTasks } = this.props.table;

    this.setState({
      showRemoveTag: false,
    });

    this.props.actions.taskPlanner.confirmRemoveTagsFromTasks(selectedTasks, tagIds);
  };

  onCancelRemoveTagsFromTasks = () => {
    this.setState({
      showRemoveTag: false,
    });

    this.props.actions.taskPlanner.toggleRemoveTagsFromTasks(-1);
  };

  isATaskSelected() {
    return !!this.props.table.selectedTasks.length;
  }

  renderAppropriateView() {
    if (this.isATaskSelected()) {
      return this.renderTaskSelectedView();
    }
    return this.renderDefaultView();
  }

  onPreviewAllClick = () => {
    const metric = this.props.table.allTasksExpanded ?
      TrailMetricsDirectory.page.TaskPlanner.COLLAPSE_ALL_CLICKED :
      TrailMetricsDirectory.page.TaskPlanner.PREVIEW_ALL_CLICKED;
    const buttonAction = this.props.table.allTasksExpanded ?
      this.props.actions.taskPlanner.collapseAllTasks :
      this.props.actions.taskPlanner.expandAllTasks;

    buttonAction(this.props.taskPlanner.taskTemplates);
    metricsPublisher.recordMetric(metric);
  };

  state = {
    showValueList: false,
    showRemoveTag: false,
    showUnassign: false,
  };

  onRemoveTag = () => {
    this.setState({
      showRemoveTag: true,
    });
  };

  onUnassignSites = () => {
    this.setState({
      showUnassign: true,
    });
  };

  onClickOutside = () => {
    this.setState({
      showRemoveTag: false,
      showUnassign: false,
    });
  };

  renderPreviewAllButton() {
    if (this.props.taskPlanner.previewsLoading) {
      return null;
    }

    const buttonLabel = this.props.table.allTasksExpanded ?
      this.props.intl.formatMessage({ id: 'taskplanner.collapseAll' }) :
      this.props.intl.formatMessage({ id: 'taskplanner.previewAll' });

    return (
      <div className='showtime-layout-admin-management__preview-all'>
        <Button
          onClick={ this.onPreviewAllClick }
          label={ buttonLabel }
          buttonClasses='showtime-button--small showtime-button--default showtime-button--collapse-text'
          dataTest='previewAll'
          iconName='mini/arrows/arrows-16px-e_resize-v'
        />
      </div>
    );
  }

  renderDefaultView() {
    const searchValue = this.props.taskPlanner.filters[TaskPlannerConstants.FILTER_BY_NAME];

    return (
      <div className='showtime-layout-admin-management__action-bar'>
        {
          this.props.libraryTabs || (
          <TaskTemplateCounts
            intl={ this.props.intl }
            singleLocation={ this.props.singleLocation }
            filteredTaskTemplates={ this.props.taskPlanner.filteredTaskTemplates }
            taskTemplates={ this.props.taskPlanner.taskTemplates }
          />
          )
        }

        <div className='showtime-layout-admin-management__actions'>
          {this.props.singleLocation ? null : this.renderPreviewAllButton()}
          <div className='showtime-layout-admin-management__search'>
            <ClearableSearchInput
              placeholder={ this.props.intl.formatMessage({ id: 'taskplanner.search' }) }
              onChange={ this.props.actions.taskPlanner.filterByName }
              onBlur={ this.onSearchBlur }
              value={ searchValue }
              dataTest='task.planner.search'
              className='showtime-input--small'
            />
          </div>
          {this.renderNewTaskButton()}
        </div>
      </div>
    );
  }

  renderNewTaskButton() {
    return this.props.singleLocation ? null : (
      <div className='showtime-layout-admin-management__create-button'>
        <Button
          label={ this.props.intl.formatMessage({ id: 'taskplanner.create' }) }
          buttonClasses='showtime-button--small showtime-button--create'
          iconName='mini/ui/ui-16px-1_simple-add'
          onClick={ this.props.onCreateNewTask }
          ref='newTaskButton'
          dataTest='task.create'
        />
      </div>
    );
  }

  handleDeleteConfirmation = () => {
    this.props.actions.taskPlanner.confirmDelete(this.props.table.selectedTasks);
  };

  handleArchiveConfirmation = () => {
    const successMessage = this.props.intl.formatMessage(
      { id: 'taskplanner.tasks_archived' }, { numberOfTasks: this.props.table.selectedTasks.length }
    );
    this.props.actions.taskPlanner.confirmArchive(this.props.table.selectedTasks, successMessage);
  };

  renderTaskSelectedView() {
    const { selectedTasks } = this.props.table;
    const showBulkTagging = window.config.features.tags;
    const taskCount = selectedTasks.length;
    return (
      <div className='showtime-layout-admin-management__action-bar is-selected'>
        <div className='showtime-layout-admin-management_action-text'>
          {this.props.intl.formatMessage({ id: 'taskplanner.tasks_selected' }, { taskCount })}
        </div>
        <div className='showtime-layout-admin-management__select-all'>
          <a
            className='showtime-link showtime-link--highlight'
            onClick={ this.onSelectAll }
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.select_all' })}
          </a>
        </div>
        <div className='showtime-layout-admin-management__bulk-actions'>
          <div className='showtime-layout-admin-management__buttons'>
            {!this.props.isEverySelectedTaskOnDemand && (
              <AdminActionBarButton
                values={ this.props.locations.map(l => ({ id: l.id, name: l.name })) }
                valueType='assign'
                componentName='trailsDropdown'
                taskCount={ taskCount }
                onConfirm={ this.onConfirmAssignToTrail }
                onCancel={ this.onCancelAssignToTrail }
                saving={ this.props.taskPlanner.isAssigningTasks }
                showValueList={ this.state.showValueList }
                iconName='glyph/24px/shopping/shopping-24px-glyph_shop-location'
              />
            )}
            {showBulkTagging && (
            <AdminActionBarButton
              values={ this.props.taskPlanner.tags.map(t => ({ id: t.id, name: t.label })) }
              valueType='add_tags_to_task'
              componentName='add_tags_to_taskDropdown'
              taskCount={ taskCount }
              onConfirm={ this.onConfirmAddTagsToTasks }
              onCancel={ this.onCancelAddTagsToTrail }
              saving={ this.props.taskPlanner.isAddingTagsToTasks }
              showValueList={ this.state.showValueList }
              iconName='glyph/24px/shopping/shopping-24px-glyph_tag'
            />
            )}
            {!this.props.isEverySelectedTaskOnDemand && this.state.showUnassign && (
              <AdminActionBarButton
                values={ this.props.locationsForSelectedTasksExcludingOnDemand
                  .map(l => ({ id: l.id, name: l.name })) }
                valueType='unassign'
                componentName='bulk-unassignDropdown'
                taskCount={ taskCount }
                onConfirm={ this.onConfirmUnassignToTrail }
                onCancel={ this.onCancelUnassignToTrail }
                saving={ this.props.taskPlanner.isAssigningTasks }
                showValueList={ this.state.showUnassign }
                onCloseMenu={ this.onClickOutside }
              />
            )}
            {this.state.showRemoveTag
            && showBulkTagging && (
            <AdminActionBarButton
              values={ this.props.tagsForSelectedTasks
                .map(t => ({ id: t.id, name: t.label })) }
              valueType='remove_tags_from_tasks'
              componentName='remove_tags_from_tasksDropdown'
              taskCount={ taskCount }
              onConfirm={ this.onConfirmRemoveTagsFromTasks }
              onCancel={ this.onCancelRemoveTagsFromTasks }
              saving={ this.props.taskPlanner.isRemovingTagsFromTasks }
              showValueList={ this.state.showRemoveTag }
              onCloseMenu={ this.onClickOutside }
            />
            )}
            <MoreActionMenu
              handleArchiveConfirmation={ this.handleArchiveConfirmation }
              handleDeleteConfirmation={ this.handleDeleteConfirmation }
              onRemoveTag={ this.onRemoveTag }
              onUnassignSites={ this.onUnassignSites }
              showUnassign={ !this.props.isEverySelectedTaskOnDemand }
              showBulkTagging={ showBulkTagging }
              showArchive={ !this.props.isEverySelectedTaskArchived }
              showDelete={ this.props.isEverySelectedTaskArchived }
            />
          </div>
          <Button
            className='showtime-button showtime-button--lowlight-close showtime-button--small showtime-button--adjacent'
            onClick={ this.onClearAll }
            dataTest='taskplanner.closeSelectMode'
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.cancel' })}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (this.renderAppropriateView());
  }
}

TaskPlannerActionBar.propTypes = {
  actions: PropTypes.shape({
    taskPlanner: PropTypes.object,
  }).isRequired,
  isEverySelectedTaskOnDemand: PropTypes.bool.isRequired,
  isEverySelectedTaskArchived: PropTypes.bool.isRequired,
  libraryTabs: PropTypes.node,
  locations: PropTypes.array.isRequired,
  locationsForSelectedTasksExcludingOnDemand: PropTypes.array.isRequired,
  tagsForSelectedTasks: PropTypes.array.isRequired,
  onCreateNewTask: PropTypes.func,
  singleLocation: PropTypes.bool.isRequired,
  table: PropTypes.object.isRequired,
  taskPlanner: PropTypes.object.isRequired,
};

TaskPlannerActionBar.defaultProps = {
  onCreateNewTask: () => { },
  libraryTabs: null,
};

const mapStateToProps = (state, props) => ({
  taskPlanner: TaskPlannerSelectors.taskPlannerSelector(state, props),
  table: state.table,
  locationsForSelectedTasksExcludingOnDemand:
    TaskPlannerSelectors.locationsForSelectedTasksExcludingOnDemand(state, props),
  tagsForSelectedTasks:
    TaskPlannerSelectors.tagsForSelectedTasks(state, props),
  isEverySelectedTaskOnDemand: TaskPlannerSelectors.isEverySelectedTaskOnDemand(state),
  isEverySelectedTaskArchived: TaskPlannerSelectors.isEverySelectedTaskArchived(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    taskPlanner: bindActionCreators(TaskPlannerActions, dispatch),
    taskPlannerTable: bindActionCreators(TaskPlannerTableActions, dispatch),
  },
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TaskPlannerActionBar));
