const cellValidationRulesFactory = {
  build(cellSettings, row) {
    cellSettings = cellSettings || {};
    const validationSettings = cellSettings.validation || {};
    const { ruleColumn, rules } = validationSettings;
    let validationRules = {};

    if (ruleColumn && rules && row) {
      const ruleName = row[ruleColumn];
      validationRules = ruleName ? rules[ruleName] : {};
    }

    return validationRules || {};
  },
};

export default cellValidationRulesFactory;
