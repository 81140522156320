import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TaskPlannerSelectors } from '../../../task-planner/selectors';
import DropDownMenu from '../../../common/menu/DropDownMenu';
import { ConditionalFadeScroll } from '../../../common/forms/ConditionalFadeScroll';

const { actionTemplatesSelector } = TaskPlannerSelectors;

const UNSELECTED_ICON = 'mini/ui/ui-16px-1_zoom';
const UNSELECTED_CONTAINER_CLASS = 'showtime-task-wizard__action-template-list-unselected';
const SELECTED_ICON = 'mini/ui/ui-16px-1_pencil';
const SELECTED_CONTAINER_CLASS = 'showtime-task-wizard__action-template-list-selected';

const ActionTemplateList = ({ actionTemplates, onChange, formatMessage }) => (
  <ConditionalFadeScroll>
    <ul>
      <li className='showtime-menu__list-item' key='0'>
        <a
          className='showtime-link showtime-link--default showtime-link--block showtime-link--menu-item'
          onClick={ () => onChange(null) }
          data-test='actionTemplateDropdown.noTemplate'
        >
          <span className='showtime-menu__list-item-text'>
            { formatMessage({ id: 'taskWizard.widget.actionTemplateDropdown.noTemplateOption' }) }
          </span>
        </a>
      </li>
      {actionTemplates.map(template => (
        <ActionTemplateItem { ...{ template, onChange } } />
      ))}
    </ul>
  </ConditionalFadeScroll>
);

ActionTemplateList.propTypes = {
  actionTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
};

const ActionTemplateItem = ({ template, onChange }) => (
  <li className='showtime-menu__list-item' key={ template.id }>
    <a
      className='showtime-link showtime-link--default showtime-link--block showtime-link--menu-item'
      onClick={ () => onChange(template.id) }
      data-test={ `actionTemplateDropdown.${template.id}` }
    >
      <span className='showtime-menu__list-item-text'>
        { template.name }
      </span>
    </a>
  </li>
);

ActionTemplateItem.propTypes = {
  template: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ActionTemplateSelection = ({ value, onChange }) => {
  const actionTemplates = useSelector(actionTemplatesSelector);
  const currentActionTemplateId = value;
  const currentActionTemplate = _.find(actionTemplates, { id: currentActionTemplateId });

  if (_.isEmpty(actionTemplates)) {
    return null;
  }
  if (!window.config.features.actionTemplates) return null;

  let dropDownMenu;
  const { formatMessage } = useIntl();

  const unselectedTitle = formatMessage({ id: 'taskWizard.widget.actionTemplateDropdown.title' });
  const unselectedSecondary = formatMessage(
    { id: 'taskWizard.widget.actionTemplateDropdown.label' }
  );

  const handleChange = (templateId) => {
    onChange(templateId);
    dropDownMenu.closeMenu();
  };
  const noTemplateSelected = _.isEmpty(currentActionTemplate);

  return (
    <DropDownMenu
      ref={ (ref) => { dropDownMenu = ref; } }
      dataTestKey='actionTemplateDropdown'
      containerClassName={
        classNames({
          'showtime-action-template-selection': true,
          [SELECTED_CONTAINER_CLASS]: !noTemplateSelected,
          [UNSELECTED_CONTAINER_CLASS]: noTemplateSelected,
        })
      }
      buttonClassName='showtime-selection-list__item showtime-selection-list__item--dropdown-menu showtime-selection-list__item--clickable'
      iconName={ currentActionTemplate ? SELECTED_ICON : UNSELECTED_ICON }
      label={ currentActionTemplate ? currentActionTemplate.name : unselectedTitle }
      secondaryLabel={ currentActionTemplate ? null : unselectedSecondary }
      flexibleLines
      iconPosition='left'
    >
      <ActionTemplateList { ...{ actionTemplates, onChange: handleChange, formatMessage } } />
    </DropDownMenu>
  );
};

ActionTemplateSelection.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

ActionTemplateSelection.defaultProps = {
  value: undefined,
};

export default ActionTemplateSelection;
