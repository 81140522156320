import { organizationPlans } from '../organization/constants';

export const KNOWN_PLANS = [
  'team', 'standard',
];

export const PlanIcons = {
  FREE: 'mini/business-finance/business-16px_checklist',
  TEAM: 'mini/shopping/shopping-16px_shop',
  STANDARD: 'mini/business-finance/business-16px_building',
};

export const PromoIcons = {
  ERROR: 'mini/ui/ui-16px-2_alert-circle-error',
  SUCCESS: 'mini/ui/ui-16px-1_check-circle-08',
};

export const couponDurations = {
  once: 'once',
  repeating: 'repeating',
  forever: 'forever',
};

export const planNamesInOrder = [
  organizationPlans.TEAM, organizationPlans.STANDARD,
];

export const PLANS_PATH = '/plans';
export const BILLING_PATH = '/';
export const PLAN_PATH_URL = '/billing#/plans';

export const previewInvoiceUrl = price => `/api/billing/preview_next_invoice?price=${price}`;
export const BILLING_URL = '/api/billing';
export const PROMO_URL = '/api/unauthenticated/validate_promo';
export const CREATE_DIRECT_DEBIT_CHECKOUT_SESSION_URL = '/api/billing/create_direct_debit_checkout_session';

export const GET_SUBSCRIPTION_KEY = '@billing get_subscription';
export const GET_NEXT_INVOICE_PREVIEW = '@billing get_next_invoice_preview';
export const SHOW_PROMO_CODE_INPUT = '@billing show_promo_code_input';
export const VALIDATE_PROMO_CODE = '@billing validate_promo_code';
export const CREDIT_CARD_CREATED = '@billing credit_card_created';
export const CREDIT_CARD_CHANGED = '@billing credit_card_changed';
export const PRICE_CHANGED = '@billing price_changed';
export const STRIPE_BILLING_DETAILS_CHANGED = '@billing details_changed';
export const CREATE_PAYMENT_METHOD = '@billing create_payment_method';
export const CREATE_PAYMENT_METHOD_FAILED = '@billing create_payment_method_failed';
export const CREATE_PAYMENT_METHOD_SUCCESS = '@billing create_payment_method_success';
export const CREATE_SUBSCRIPTION_KEY = '@billing create_subscription';
export const CURRENT_PAYMENT_METHOD_TYPE_CHANGED = '@billing current_payment_method_type_changed';
export const UPDATE_SUBSCRIPTION_KEY = '@billing update_subscription';
export const CREATE_SUBSCRIPTION_STARTED = '@billing create_subscription_started';

export const PLAN_CHANGED = '@billing plan_changed';
export const IS_EDITING_PAYMENT_METHOD_CHANGED = '@billing is_editing_payment_method_changed';
export const CARD_ID = 'card-element';

export const STRIPE_INTERVALS = ['year', 'month'];

export const MANUAL_PAYMENT_METHOD = 'manual';
export const CARD_PAYMENT_METHOD = 'card';
export const UNKNOWN_PAYMENT_METHOD = 'unknown';
export const NEW_PAYMENT_METHOD = 'new';
export const NO_PAYMENT_METHOD = 'none';
export const BACS_DEBIT_PAYMENT_METHOD = 'bacs_debit';
export const SEPA_DEBIT_PAYMENT_METHOD = 'sepa_debit';
export const PAYMENT_METHODS = [
  CARD_PAYMENT_METHOD,
  MANUAL_PAYMENT_METHOD,
  UNKNOWN_PAYMENT_METHOD,
  NEW_PAYMENT_METHOD,
  NO_PAYMENT_METHOD,
  BACS_DEBIT_PAYMENT_METHOD,
  SEPA_DEBIT_PAYMENT_METHOD,
];
export const DUE_TODAY_TOTAL = 0;
