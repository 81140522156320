import moment from 'moment-timezone';

const TaskScheduleConstants = {
  unitOptions: {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
  },
  dueOptions: {
    TIMESLOT: 'Timeslot',
    SPECIFIC_TIME: 'Specific time',
  },
  weekDays: [
    moment('Mon', 'ddd').format('ddd'),
    moment('Tue', 'ddd').format('ddd'),
    moment('Wed', 'ddd').format('ddd'),
    moment('Thu', 'ddd').format('ddd'),
    moment('Fri', 'ddd').format('ddd'),
    moment('Sat', 'ddd').format('ddd'),
    moment('Sun', 'ddd').format('ddd'),
  ],
  weekDaysNewSchedule: [
    moment('Mon', 'ddd').format('dddd'),
    moment('Tue', 'ddd').format('dddd'),
    moment('Wed', 'ddd').format('dddd'),
    moment('Thu', 'ddd').format('dddd'),
    moment('Fri', 'ddd').format('dddd'),
    moment('Sat', 'ddd').format('dddd'),
    moment('Sun', 'ddd').format('dddd'),
  ],
  recurrenceOptions: {
    RECURRING: 'recurring',
    NON_RECURRING: 'nonRecurring',
  },
  UNTOUCHED: 'UNTOUCHED',
  TIME: 'time',
  TIME_SLOT: 'time_slot',
  RECUR_PERIODS: {
    Day: 'daily',
    Week: 'weekly',
    Month: 'monthly',
  },
  CANCEL: 'task_schedule.cancel',
  DONE: 'task_schedule.done',
  RESET: 'reset',
  SET_TYPE: 'task_schedule.set.type',
  SET_REPEAT_UNIT: 'task_schedule.repeat.unit',
  SET_REPEAT_COUNT: 'task_schedule.repeat.count',
  SET_REPEAT_DUE_ON: 'task_schedule.repeat.due.on',
  SET_REPEAT_DAYS_BEFORE: 'task_schedule.repeat.days.before',
  SET_DUE_BY_TYPE: 'task_schedule.due.type',
  NEXT_INSTANCES_LOADED: 'task_schedule.fetch.next_instances',
  SET_TIME_SLOT: 'task_schedule.due.timeslot.id',
  NON_REPEATING_DUE_ON: 'task_schedule.nonrepeat.dueOn',
  NON_REPEATING_CAN_BE_STARTED: 'task_schedule.nonrepeat.canBeStarted',
  SET_SPECIFIC_TIME: 'task_schedule.due.specificTime',
  SET_COMPLETION_DAYS: 'task_schedule.completionDays',
  SET_START_DATE: 'task_schedule.start.date',
  SET_END_DATE: 'task_schedule.end.date',
  SET_SINGLE_DATE: 'task_schedule.single.date',
  SET_START_TIMESLOT: 'task_schedule.start.timeslot',
  SET_END_TIMESLOT: 'task_schedule.end.timeslot',
  SET_START_TIME: 'task_schedule.start.time',
  SET_END_TIME: 'task_schedule.end.time',
  SET_RECUR_BY: 'task.schedule.recur.by',
  SET_RECUR_UNIT: 'task.schedule.recur.unit',
  HIDE_SCHEDULE_VIEW: 'task.schedule.hide.schedule.view',
  SET_ACTIVE_SCHEDULE: 'task.schedule.setActiveSchedule',
  REMOVE_SCHEDULE: 'task.schedule.remove.schedule',
  SETUP_ONE_OFF_VIEW: 'task.schedule.oneOffView',
  SETUP_RECURRING_VIEW: 'task.schedule.recurringView',
  SET_VIEW: 'task.schedule.setView',
  SET_IS_BETWEEN_DATES: 'task.schedule.set_is_between_dates',
  TOGGLE_DAY: 'task.schedule.toggle_day',
  SELECT_ALL_DAY: 'task.schedule.select_all_day',
  ADD_SCHEDULE: 'task.schedule.add',
  FETCHING_SCHEDULE_NEXT_INSTANCES: 'task.schedule.fetch.next_instances',
  TOGGLE_EARLY_TASK_WARNING: 'task.schedule.toggle_early_task_warning',
  ALL_DAY: 'all_day',
  ONGOING: 'ongoing',
  FIXED: 'fixed',
  VIEW: {
    SHORTCUTS: 'task.schedule.view.shortcuts',
    ONE_OFF: 'task.schedule.view.single',
    RECURRING: 'task.schedule.view.recurring',
    AD_HOC: 'task.schedule.view.on_demand',
    AUTOMATED: 'task.schedule.view.automated',
    ACTION_TEMPLATE: 'task.schedule.view.action_template',
  },
  NEXT_INSTANCES_URL(start, recurBy, recurOn, recurUnit) {
    const baseUrl = `/api/v1/schedule?recur_by=${recurBy}&start_date=${start}&limit=3&recur_unit=${recurUnit}`;
    return recurOn.reduce((buildUrl, day) => `${buildUrl}&days[]=${day}`, baseUrl);
  },
  DATE_FMT: 'YYYY-MM-DD',
  ERRORS: {
    COMPLETION_DAYS_RANGE: 'taskWizard.schedule.errors.completionDays',
    SCHEDULE_NOT_SET: 'taskWizard.schedule.errors.notSet',
    DATE: 'taskWizard.schedule.errors.date',
    TIME: 'taskWizard.schedule.errors.time',
    REPEAT_BY: 'taskWizard.schedule.errors.repeat.by',
    REPEAT_UNIT: 'taskWizard.schedule.errors.repeat.unit',
  },
};

export default TaskScheduleConstants;
