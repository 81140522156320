import _ from 'lodash';
import { TaskWizardConstants } from '../constants';
import { TaskTemplateConstants, TaskConstants } from '../../task/constants';
import { RequestConstants } from '../../request';

export const taskTemplateReducer = function (state, action) {
  const initialState = {
    content: {
      value: TaskTemplateConstants.templateType.RECURRING,
    },
  };
  const currState = state || _.extend({}, initialState);

  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return {
        content: {
          value: action.content.template_type,
          isLocked: action.content.locked,
        },
      };
    case TaskTemplateConstants.CHANGE_TEMPLATE_TYPE:
      if (_.values(TaskTemplateConstants.templateType).indexOf(action.templateType) !== -1) {
        return _.merge({}, currState, {
          content: {
            value: action.templateType,
          },
        });
      }

      return currState;
    case TaskTemplateConstants.TOGGLE_LOCK_TASK:
      return _.merge({}, currState, {
        content: {
          isLocked: !currState.content.isLocked,
        },
      });
    case TaskConstants.RESET:
      return _.merge({}, currState, {
        content: {
          isLocked: false,
        },
      });
    default:
      return currState;
  }
};
