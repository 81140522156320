import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { EditWidgetActions } from '../../../actions';
import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import SVGIcon from '../../../../common/SVGIcon';

const {
  columnLogicPathSelector, columnLogicLengthSelector, defaultJumpLogicForColumnSelector,
} = TaskWidgetSelectors;

const AddNewJumpButton = ({ columnIndex }) => {
  const { formatMessage } = useIntl();

  const logicLength = useSelector(state => columnLogicLengthSelector(state, { columnIndex }));
  const logicPath = useSelector(
    state => columnLogicPathSelector(state, { columnIndex, logicIndex: logicLength }));
  const defaultJumpLogic = useSelector(
    state => defaultJumpLogicForColumnSelector(state, { columnIndex }));

  const dispatch = useDispatch();
  const addNewLogicJump = () => dispatch(
    EditWidgetActions.setWidgetPublicSettings(logicPath, defaultJumpLogic));

  return (
    <div className='showtime-record-log-builder-field__config-links'>
      <a
        className='showtime-link showtime-link--small showtime-link--highlight'
        onClick={ addNewLogicJump }
        data-test='logic.addAnother'
      >
        <SVGIcon classes='showtime-record-log-builder-field__header-icon' iconName='mini/ui/ui-16px-1_simple-add' />
        { formatMessage({ id: 'editWidget.builder.optionsColumn.addNewLogicJump' }) }
      </a>
    </div>
  );
};

AddNewJumpButton.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default AddNewJumpButton;
