import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { TaskReportsActions } from '../actions';
import { TaskReportsSelectors } from '../selectors';
import { exceptionTypes } from '../constants';
import ExportOption from '../../common/ExportOption';
import { TrailMetricsDirectory } from '../../metrics';

const CSVExceptionsExportOption = ({ onClick }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onlyRecordLogExceptionsSelected = _.isEqual(useSelector(
    state => TaskReportsSelectors.selectedExceptionsSelector(state, { location })
  ), [exceptionTypes.widget]);

  if (!onlyRecordLogExceptionsSelected) return null;

  const handleClick = () => {
    dispatch(TaskReportsActions.fetchCSVExceptionsReport(location));
    onClick();
  };

  const noVisibleTasks = useSelector(
    state => TaskReportsSelectors.noTasksLoadedSelector(state, { location }));

  return (
    <ExportOption
      iconName='/custom/attachment-icons/file-csv'
      onClick={ noVisibleTasks ? null : handleClick }
      dataTest='share.csvExceptionsExport'
      clickMetric={ TrailMetricsDirectory.page.TaskReports.CSV_EXCEPTIONS_DOWNLOAD_TRIGGERED }
    >
      <span
        className='hint--medium hint--bottom'
        data-hint={
          noVisibleTasks
            ? formatMessage({ id: 'taskReports.share.noTasksToExport' })
            : formatMessage({ id: 'taskReports.share.useFiltersCSV' })
        }
      >
        { formatMessage({ id: 'share.csvExceptionExport' }) }
      </span>
    </ExportOption>
  );
};

CSVExceptionsExportOption.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CSVExceptionsExportOption;
