import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const schemaErrorSummary = 'There appear to be problem(s) with the configuration';

const SchemaErrors = ({ errors }) => {
  SchemaErrors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  if (!errors || !errors.length) return null;

  return (
    <div className='showtime-callout-help showtime-callout-help--attention'>
      { schemaErrorSummary } <br />
      { errors.map(e => <Fragment key={ e } data-test='schema-error'>{e} <br /></Fragment>) }
    </div>
  );
};
export default SchemaErrors;
