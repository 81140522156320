import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon from './SVGIcon';
import metricsPublisher from '../metrics';

const ExportOption = ({
  children, iconName, onClick, dataTest, clickMetric,
}) => {
  const Element = onClick ? 'a' : 'div';
  const handleClick = () => {
    clickMetric && metricsPublisher.recordMetric(clickMetric);
    onClick();
  };

  return (
    <Element
      onClick={ onClick ? handleClick : null }
      className='showtime-copy-to-clipboard__option'
      data-test={ dataTest }
    >
      { iconName && <SVGIcon classes='showtime-copy-to-clipboard__icon' iconName={ iconName } /> }
      { children }
    </Element>
  );
};

ExportOption.propTypes = {
  children: PropTypes.node,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  dataTest: PropTypes.string,
  clickMetric: PropTypes.object,
};

ExportOption.defaultProps = {
  onClick: null,
  dataTest: null,
  iconName: null,
  children: null,
  clickMetric: null,
};

export default ExportOption;
