import { RequestConstants } from '../../request';
import { LocationConstants } from '../constants';

const initialState = {
  loaded: false,
  saving: false,
  data: {
    readOnly: false,
    timeZones: [],
    location: {
      name: '',
      city: '',
      address: '',
      zipcode: '',
      externalId: '',
      timelineTemplateParentId: null,
      subscriptionId: null,
    },
  },
};

const formatLocation = location => ({
  ...location,
  name: location.name || '',
  city: location.city || '',
  address: location.address || '',
  zipcode: location.zipcode || '',
  externalId: location.externalId || '',
  closedDates: location.closedDates ? location.closedDates.map(date => new Date(date)) : [],
});

const locationLoaded = (state, action) => {
  const {
    defaultTimeSlots,
    locationTimeSlots,
    organizationTimeSlots,
    readOnly,
    timeZones,
    ...location
  } = action.content;

  return ({
    ...state,
    loaded: true,
    data: {
      ...state.data,
      defaultTimeSlots,
      locationTimeSlots,
      organizationTimeSlots,
      readOnly,
      timeZones,
      location: formatLocation(location),
    },
  });
};

const locationChanged = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    location: {
      ...state.data.location,
      ...action.content,
    },
  },
});

const savingLocation = state => ({
  ...state,
  saving: true,
});

const savingError = state => ({
  ...state,
  saving: false,
});

export const siteEditReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(LocationConstants.GET_LOCATION_KEY):
    case RequestConstants.getLoadedActionType(LocationConstants.GET_NEW_LOCATION_KEY):
      return locationLoaded(state, action);
    case LocationConstants.LOCATION_CHANGED:
      return locationChanged(state, action);
    case RequestConstants.getLoadingActionType(LocationConstants.CREATE_LOCATION_KEY):
    case RequestConstants.getLoadingActionType(LocationConstants.UPDATE_LOCATION_KEY):
    case RequestConstants.getLoadingActionType(LocationConstants.DELETE_LOCATION_KEY):
      return savingLocation(state);
    case RequestConstants.getErrorActionType(LocationConstants.CREATE_LOCATION_KEY):
    case RequestConstants.getErrorActionType(LocationConstants.UPDATE_LOCATION_KEY):
    case RequestConstants.getErrorActionType(LocationConstants.DELETE_LOCATION_KEY):
      return savingError(state);
    case LocationConstants.RESET:
      return initialState;
    default:
      return state;
  }
};
