import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';

import DateRangeSelection from '../task-wizard/Scheduling/DateRangeSelection';
import { TaskScheduleSelectors } from '../task-wizard/selectors';
import { ScheduleActions } from '../task-wizard/actions';
import SVGIcon from '../common/SVGIcon';

const TaskActionScheduler = ({ inAnchoredMenu }) => {
  const activeSchedule = useSelector(TaskScheduleSelectors.activeScheduleSelector);
  const dispatch = useDispatch();
  const actions = bindActionCreators(ScheduleActions, dispatch);
  const { formatMessage } = useIntl();
  return (
    <div className='showtime-scheduling showtime-task-action__scheduler'>
      <div className='showtime-task-action__scheduler-title'>
        <SVGIcon iconName='mini/ui/ui-16px-1_calendar-60' classes='showtime-icon' />
        { formatMessage({ id: 'trail.task.action.schedulerTitle' }) }
      </div>
      <DateRangeSelection
        content={ activeSchedule }
        actions={ actions }
        minDate={ moment().valueOf() }
        forceLeftTooltips={ inAnchoredMenu }
      />
    </div>
  );
};

TaskActionScheduler.propTypes = {
  inAnchoredMenu: PropTypes.bool.isRequired,
};

export default TaskActionScheduler;
