import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DayPicker, { DateUtils } from 'react-day-picker';
import { dateHelper } from './legacy';

class ClosedDays {
  constructor(dateArray) {
    this.closures = dateArray;
  }

  add = (day) => {
    this.closures = [...this.closures, day];
  };

  remove = (date) => {
    this.closures = this.closures.filter(item => !DateUtils.isSameDay(item, date));
  };
}

const decideNumberOfMonthsToDisplay = () => {
  const windowClasslist = document.documentElement.classList;
  let monthsToDisplay = 2;
  if (windowClasslist.contains('maxw_320') || windowClasslist.contains('maxw_480')) {
    monthsToDisplay = 1;
  }
  return monthsToDisplay;
};

const dateHasPassed = (date, dateToDisableEarlierThan) => !DateUtils.isDayAfter(date, dateToDisableEarlierThan);

const dayIsClosedDueToTimeslot = (date, daysClosedByTimeslot) => daysClosedByTimeslot.includes(date.getDay());

const getYesterdaysDate = () => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
};

export class OrganizationLocationClosedDays extends PureComponent {
  static propTypes = {
    initialMonth: PropTypes.instanceOf(Date),
    disableUpToAndIncludingDate: PropTypes.instanceOf(Date),
    onDateSelectionChange: PropTypes.func,
    datesLocationIsClosed: PropTypes.array,
    daysTimeslotClosed: PropTypes.array,
  };

  static defaultProps = {
    initialMonth: new Date(),
    disableUpToAndIncludingDate: getYesterdaysDate(),
    onDateSelectionChange: () => { },
    datesLocationIsClosed: [],
    daysTimeslotClosed: [],
  };

  state = {
    closedDays: this.props.datesLocationIsClosed,
    numberOfMonths: decideNumberOfMonthsToDisplay(),
  };

  onResize = () => {
    this.setState({
      numberOfMonths: decideNumberOfMonthsToDisplay(),
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  handleDayClick = (day, { selected }) => {
    if (dateHasPassed(day, this.props.disableUpToAndIncludingDate)) return;
    if (dayIsClosedDueToTimeslot(day, this.props.daysTimeslotClosed)) return;

    const closedDays = new ClosedDays(this.state.closedDays);
    if (selected) {
      closedDays.remove(day);
    } else {
      closedDays.add(day);
    }
    this.setState({ closedDays: closedDays.closures });

    const standardisedDays = closedDays.closures.map(date => new Date(`${dateHelper.jsToRails(date)}T00:00:00Z`));
    this.props.onDateSelectionChange(standardisedDays);
  };

  render() {
    return (
      <div className='showtime-daypicker showtime-daypicker--closed-days'>
        <DayPicker
          initialMonth={ this.props.initialMonth }
          numberOfMonths={ this.state.numberOfMonths }
          onDayClick={ this.handleDayClick }
          selectedDays={ this.state.closedDays }
          modifiers={ {
            dayHasPassed: day => dateHasPassed(day, this.props.disableUpToAndIncludingDate),
            dayIsClosedDueToTimeslot: day => dayIsClosedDueToTimeslot(
              day, this.props.daysTimeslotClosed),
          } }
        />
      </div>
    );
  }
}

export default injectIntl(OrganizationLocationClosedDays);
