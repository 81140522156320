import _ from 'lodash';
import { RequestConstants } from '../../request';
import { TaskHistoryConstants } from '../constants';

export const taskHistoryReducer = function (state, action) {
  const initialState = {
    tasksLoaded: false,
    tasks: [],
    subtasks: [],
    widgetData: [],
  };
  const currState = state || _.extend({}, initialState);

  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskHistoryConstants.GET_LAST_INSTANCES_KEY):
      return _.extend({}, currState, {
        tasksLoaded: true,
        tasks: action.content.tasks,
        subtasks: action.content.subtasks,
        widgetData: action.content.widget_data,
      });
    case TaskHistoryConstants.RESET:
      return initialState;
    default:
      return currState;
  }
};
