import moment from 'moment-timezone';

import { TrailConstants } from './constants';
import { TaskTemplateConstants } from '../task/constants';

const hasDueTime = task => (
  task.due_by_datetime != null
);

const isOnDemand = task => (
  task.templateType === 'on_demand'
);

export const isAutomated = ({ templateType }) => (
  templateType === TaskTemplateConstants.templateType.AUTOMATED
);

const isLate = (task, threshold = 0) => {
  const completeBy = moment(task.due_by_datetime).add(threshold, 'minutes');
  return moment(task.completed_at).isAfter(completeBy);
};

export const isCompleted = task => (
  task.status === TrailConstants.taskStatus.DONE
);

export const isOnDemandAndIncomplete = task => (
  !isCompleted(task) && isOnDemand(task)
);

export const isFlagged = task => task.status === TrailConstants.taskStatus.FLAGGED;

export const isOverdue = task => (
  task.status === TrailConstants.taskStatus.OVERDUE
);

export const isMissed = task => (
  task.status === TrailConstants.taskStatus.MISSED
);

export const isOnTimeButIncomplete = task => (
  !isCompleted(task) && hasDueTime(task) && Date.now() < moment(task.due_by_datetime)
);

export const lessThanADayLate = msLate => (
  msLate < TrailConstants.MS_IN_A_DAY
);

export const wasCompletedLate = (task, threshold = 0) => (
  !isOnDemand(task) && isCompleted(task) && hasDueTime(task) && isLate(task, threshold)
);
