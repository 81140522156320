import _ from 'lodash';
import moment from 'moment-timezone';

import metricsPublisher, { TrailMetricsDirectory } from '.';
import { TrailConstants } from '../trail/constants';
import { TrailStore } from '../trail/store';

export default {
  sendMetrics(data, prevStatus) {
    const metricData = {};
    const store = new TrailStore();
    const taskLatenessDifference = this._getTaskLateness(data.task.due_by_datetime,
      data.task.completed_at);
    const taskStatus = this._getTaskStatus(prevStatus);
    const taskStatusNumber = this._getTaskStatusNumber(taskStatus);

    metricData.timeslot = this._getTimeslotTracking(data.task);

    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.Trail.TASK_COMPLETED,
      _.merge(metricData, {
        totalTasks: store.getTasks().length,
        taskStatus,
        taskStatusNumber,
        taskLatenessDifference,
        taskName: data.task.name,
        index_in_timeslot: data.timeSlotIndex,
        templateType: data.task.templateType,
      })
    );
  },

  _getTaskStatus(prevStatus) {
    switch (prevStatus) {
      case TrailConstants.taskStatus.CURRENT:
      case TrailConstants.taskStatus.LATER:
        return 'on time';
      case TrailConstants.taskStatus.OVERDUE:
        return 'overdue';
      case TrailConstants.taskStatus.ONDEMAND:
        return 'on demand';
      default:
        return '';
    }
  },

  _getTaskStatusNumber(taskStatus) {
    switch (taskStatus) {
      case 'on time':
        return 1;
      case 'overdue':
        return -1;
      default:
        return 0;
    }
  },

  _getTaskLateness(dueBy, completedAt) {
    if (!dueBy || !completedAt) {
      return 0;
    }

    try {
      return moment(completedAt).diff(moment(dueBy), 'minutes');
    } catch (e) {
      return 0;
    }
  },

  _getTimeslotTracking(task) {
    const store = new TrailStore();
    const timeslot = store.getTimeSlot(task.timeslot_id);

    if (timeslot) {
      return `${timeslot.name} (${
        this._getTimeslotTime(timeslot.startTime)} - ${
        this._getTimeslotTime(timeslot.endTime)})`;
    }

    return null;
  },

  _getTimeslotTime(time) {
    return moment().set({
      hour: time.hour,
      minute: time.minute,
    }).format('HH:mm');
  },
};
