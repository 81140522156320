import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withPureComponent from '../../../../common/withPureComponent';
import Button from '../../../../common/Button';

export const NavItem = ({
  classNamePrefix, id, onClick, isActive, isValid, isInvalid, children, counts,
}) => {
  const listButtonClasses = classNames(
    'showtime-button--small',
    'showtime-button--record-table-nav',
    'showtime-button--block-left',
    'showtime-button--collapse-text',
    {
      'is-active': isActive,
      'is-invalid': isInvalid,
      'is-valid': isValid,
    }
  );

  return (
    <li className={ `${classNamePrefix}-nav-item` }>
      <Button
        onClick={ () => onClick(id) }
        dataTest='dataCapture.nav.item'
        buttonClasses={ listButtonClasses }
        label={ children }
        counts={ { left: counts } }
        iconName={ isInvalid ? 'mini/ui/ui-16px-2_alert-circle-error' : null }
      />
    </li>
  );
};

NavItem.propTypes = {
  classNamePrefix: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  children: PropTypes.node,
  counts: PropTypes.number.isRequired,
};

NavItem.defaultProps = {
  isActive: false,
  isValid: false,
  isInvalid: false,
  children: null,
};

export default withPureComponent(NavItem);
