import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import onClickOutside from 'react-onclickoutside';

import { CopyToClipboardValue, MenuWrapper } from './CopyToClipboardMenu';
import metricsPublisher from '../metrics';
import Button from './Button';

const OutsideClickWrapper = onClickOutside(({ children }) => <div>{children}</div>);

const ShareMenu = ({
  intl: { formatMessage }, url, children: renderChildren, clickMetric, helpMessage,
}) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const hideShareMenu = () => setShowShareMenu(false);
  const toggleShareMenu = () => setShowShareMenu(!showShareMenu);
  const onCopy = () => {
    clickMetric && metricsPublisher.recordMetric(clickMetric);
  };

  return (
    <OutsideClickWrapper handleClickOutside={ hideShareMenu }>
      <Button
        dataTest='share.button'
        className='showtime-button--small showtime-button--default'
        iconName='mini/arrows/arrows-16px-1_share-91'
        onClick={ toggleShareMenu }
      >
        { formatMessage({ id: 'share.label' }) }
      </Button>
      { showShareMenu && (
        <MenuWrapper>
          { renderChildren(hideShareMenu) }
          <CopyToClipboardValue
            copyHint={ formatMessage({ id: 'share.copyHint' }) }
            copiedHint={ formatMessage({ id: 'share.copiedHint' }) }
            help={ helpMessage }
            copyValue={ url }
            onCopy={ onCopy }
          />
        </MenuWrapper>
      ) }
    </OutsideClickWrapper>
  );
};

ShareMenu.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.func,
  clickMetric: PropTypes.object,
  helpMessage: PropTypes.node,
};

ShareMenu.defaultProps = {
  children() {},
  clickMetric: null,
  helpMessage: null,
};

export default injectIntl(ShareMenu);
