import _ from 'lodash';
import { TaskPlannerConstants } from '../constants';
import { RequestConstants } from '../../request';
import TagConstants from '../../tags/constants';
import { TaskWizardConstants } from '../../task-wizard/constants';

const loading = key => RequestConstants.getLoadingActionType(key);
const loaded = key => RequestConstants.getLoadedActionType(key);

export const taskPlannerReducer = (function () {
  const defaultFilters = {};
  defaultFilters[TaskPlannerConstants.FILTER_BY_NAME] = '';
  defaultFilters[TaskPlannerConstants.FILTER_BY_TRAIL] = [];
  defaultFilters[TaskPlannerConstants.FILTER_BY_GROUP] = [];
  defaultFilters[TaskPlannerConstants.FILTER_BY_TAG] = [];

  function updateOnTaskPlannerLoaded(currState, action) {
    const taskMetadataById = action.content.task_metadata_by_id || {};
    const extendedTaskTemplates = action.content.task_templates.map(
      template => _.extend({}, template, taskMetadataById[template.id])
    );

    return _.extend({}, currState, {
      isLoading: false,
      scheduleCategories: [].concat(action.content.schedule_categories),
      taskTemplates: extendedTaskTemplates,
    });
  }

  function updateOnTaskPreviewsLoaded(currState, action) {
    const taskPreviewById = _.groupBy(action.content.task_previews, 'id');
    const extendedTaskTemplates = currState.taskTemplates.map(template => _.extend(
      {}, template, _.get(taskPreviewById, [template.id, 0], {}))
    );

    return _.extend({}, currState, {
      previewsLoading: false,
      taskTemplates: extendedTaskTemplates,
    });
  }

  function addOrRemoveFromList(list, id, isChecked) {
    return isChecked
      ? [].concat(list, [id])
      : _.without(list, id);
  }

  function normalizeText(text) {
    return text.toLowerCase();
  }

  function updateFilter(filters, key, value) {
    return _.extend({}, filters, _.chain({})
      .set(key, value)
      .value()
    );
  }

  function addFilterFunction(state, name, func) {
    const newFilterFunctions = _.extend({}, state.filterFunctions);
    newFilterFunctions[name] = func;

    return _.extend({}, state, {
      filterFunctions: newFilterFunctions,
    });
  }

  function addFetchedOrganizationTags(state, action) {
    return _.assign({}, state, {
      tags: action.content.data.map(tag => ({ id: tag.id, label: tag.attributes.label })),
    });
  }

  function removeFilterFunction(state, name) {
    const newFilterFunctions = _.omit(state.filterFunctions, name);
    const newFilters = _.extend({}, state.filters);
    newFilters[name] = _.clone(defaultFilters[name]);

    return _.extend({}, state, {
      filters: newFilters,
      filterFunctions: newFilterFunctions,
    });
  }

  function updateFilterState(state) {
    return _.values(state.filterFunctions)
      .reduce((currState, filterFunction) => filterFunction(
        currState
      ), state);
  }

  function buildFilterByTrail(action, activeFilters) {
    const isChecked = activeFilters[TaskPlannerConstants.FILTER_BY_TRAIL]
      .indexOf(action.locationId) === -1;

    return function filterByTrail(state) {
      const locIds = addOrRemoveFromList(
        state.filters[TaskPlannerConstants.FILTER_BY_TRAIL],
        action.locationId,
        isChecked
      );

      return _.extend({}, state, {
        filters: updateFilter(
          state.filters,
          TaskPlannerConstants.FILTER_BY_TRAIL,
          locIds
        ),
      });
    };
  }

  function buildFilterByGroup(action, activeFilters) {
    const isChecked = activeFilters[TaskPlannerConstants.FILTER_BY_GROUP]
      .indexOf(action.timelineTemplateId) === -1;

    return function filterByGroup(state) {
      const timelineTemplateIds = addOrRemoveFromList(
        state.filters[TaskPlannerConstants.FILTER_BY_GROUP],
        action.timelineTemplateId,
        isChecked
      );

      return _.extend({}, state, {
        filters: updateFilter(
          state.filters,
          TaskPlannerConstants.FILTER_BY_GROUP,
          timelineTemplateIds
        ),
      });
    };
  }

  function buildFilterByTag(action, activeFilters) {
    const isChecked = activeFilters[TaskPlannerConstants.FILTER_BY_TAG]
      .indexOf(action.tagId) === -1;

    return function filterByTag(state) {
      const selectedTagIds = addOrRemoveFromList(
        state.filters[TaskPlannerConstants.FILTER_BY_TAG],
        action.tagId,
        isChecked
      );

      return _.extend({}, state, {
        filters: updateFilter(
          state.filters,
          TaskPlannerConstants.FILTER_BY_TAG,
          selectedTagIds
        ),
      });
    };
  }

  function buildFilterByName(action) {
    return function filterByName(state) {
      const searchText = normalizeText(action.text || '');
      return _.extend({}, state, {
        filters: updateFilter(
          state.filters,
          TaskPlannerConstants.FILTER_BY_NAME,
          searchText
        ),
      });
    };
  }
  function replaceUpdatedTaskTemplatesWithSchedules(currState, action) {
    return currState.taskTemplates.reduce((acc, { schedules, ...task }) => {
      const updatedTask = _.find(action.content.task_templates, { id: task.id }) || task;
      acc.push({ ...updatedTask, schedules });
      return acc;
    }, []);
  }

  function updateTaskTemplatesAfterUpdate(currState, action) {
    return _.extend({}, currState, {
      taskTemplates: replaceUpdatedTaskTemplatesWithSchedules(currState, action),
    });
  }

  function removeTagFromTaskTemplates(taskTemplatesToUpdate, tagId) {
    taskTemplatesToUpdate.forEach((taskTemplate) => {
      taskTemplate.tags = _.reject(taskTemplate.tags, tag => tag === tagId);
    });
    return taskTemplatesToUpdate;
  }

  function removeTagFromTasks(state, action) {
    const taskTemplatesToUpdate = _.cloneDeep(state.taskTemplates);
    const taskTemplates = removeTagFromTaskTemplates(
      taskTemplatesToUpdate,
      action.content.deletedTagId);

    const changesToState = _.assign({}, state, {
      taskTemplates,
    });
    return _.extend({}, state, changesToState);
  }

  function updateWithDeletedTasks(currState, action) {
    const deletedTaskIds = action.content;
    const newTaskTemplates = currState.taskTemplates.filter((task) => {
      if (!_.includes(deletedTaskIds, task.id)) {
        return task;
      }

      return null;
    });
    return _.extend({}, currState, {
      taskTemplates: newTaskTemplates,
      isLoading: false,
    });
  }

  function reducer(state, action) {
    const initialState = {
      isLoading: true,
      previewsLoading: true,
      scheduleCategories: [],
      tags: [],
      taskTemplates: [],
      filters: defaultFilters,
      filterFunctions: {},
      isAssigningTasks: false,
      isAddingTagsToTasks: false,
      isRemovingTagsFromTasks: false,
    };

    const currState = state || _.extend({}, initialState);
    let updatedState = {};
    switch (action.type) {
      case loading(TaskPlannerConstants.GET_TASK_PLANNER_KEY):
        return _.extend({}, currState, {
          isLoading: true,
          previewsLoading: true,
        });

      case loaded(TaskPlannerConstants.GET_TASK_PLANNER_KEY):
        updatedState = updateOnTaskPlannerLoaded(currState, action);
        return updateFilterState(updatedState);

      case loading(TaskPlannerConstants.GET_TASK_PREVIEWS_KEY):
        return _.extend({}, currState, {
          previewsLoading: true,
        });

      case loaded(TaskPlannerConstants.GET_TASK_PREVIEWS_KEY):
        updatedState = updateOnTaskPreviewsLoaded(currState, action);
        return updateFilterState(updatedState);

      case loading(TaskPlannerConstants.ASSIGN_TO_TRAIL):
        return _.extend({}, currState, {
          isAssigningTasks: true,
        });

      case loaded(TaskPlannerConstants.ASSIGN_TO_TRAIL):
        updatedState = updateTaskTemplatesAfterUpdate(currState, action);
        updatedState.isAssigningTasks = false;
        return updateFilterState(updatedState);

      case loading(TaskPlannerConstants.ADD_TAGS_TO_TASKS):
        return _.extend({}, currState, {
          isAddingTagsToTasks: true,
        });

      case loaded(TaskPlannerConstants.ADD_TAGS_TO_TASKS):
        updatedState = updateTaskTemplatesAfterUpdate(currState, action);
        updatedState.isAddingTagsToTasks = false;
        return updateFilterState(updatedState);

      case loading(TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS):
        return _.extend({}, currState, {
          isRemovingTagsFromTasks: true,
        });

      case loaded(TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS):
        updatedState = updateTaskTemplatesAfterUpdate(currState, action);
        updatedState.isRemovingTagsFromTasks = false;
        return updateFilterState(updatedState);

      case loading(TaskPlannerConstants.UNASSIGN_FROM_TRAIL):
        return _.extend({}, currState, {
          isAssigningTasks: true,
        });

      case loaded(TaskPlannerConstants.UNASSIGN_FROM_TRAIL):
        updatedState = updateTaskTemplatesAfterUpdate(currState, action);
        updatedState.isAssigningTasks = false;
        return updateFilterState(updatedState);

      case loaded(TagConstants.ORGANIZATION_TAGS_LOADED):
        return addFetchedOrganizationTags(currState, action);

      case loaded(TagConstants.ORGANIZATION_TAGS_DELETED):
        return removeTagFromTasks(currState, action);

      case loading(TaskPlannerConstants.DELETE_TASK_KEY):
        return _.extend({}, currState, {
          isLoading: true,
        });

      case loaded(TaskPlannerConstants.DELETE_TASK_KEY):
        updatedState = updateWithDeletedTasks(state, action);
        return updateFilterState(updatedState);

      case loaded(TaskWizardConstants.CREATE_TASK_KEY):
        if (action.content.isLibraryTask) return _.merge({}, currState, { filters: { [TaskPlannerConstants.FILTER_BY_NAME]: '' } });
        return currState;

      case TaskPlannerConstants.RESET:
        return initialState;

      case TaskPlannerConstants.FILTER_BY_NAME:
        updatedState = addFilterFunction(
          currState,
          action.type,
          buildFilterByName(action)
        );
        return updateFilterState(updatedState);

      case TaskPlannerConstants.FILTER_BY_TRAIL:
        updatedState = addFilterFunction(
          currState,
          action.type,
          buildFilterByTrail(action, currState.filters)
        );
        return updateFilterState(updatedState);

      case TaskPlannerConstants.FILTER_BY_GROUP:
        updatedState = addFilterFunction(
          currState,
          action.type,
          buildFilterByGroup(action, currState.filters)
        );
        return updateFilterState(updatedState);

      case TaskPlannerConstants.FILTER_BY_TAG:
        updatedState = addFilterFunction(
          currState,
          action.type,
          buildFilterByTag(action, currState.filters)
        );
        return updateFilterState(updatedState);

      case TaskPlannerConstants.CLEAR_FILTER:
        updatedState = removeFilterFunction(
          currState,
          action.filterName
        );
        return updateFilterState(updatedState);

      default:
        return currState;
    }
  }

  return reducer;
}());
