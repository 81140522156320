import { createSelector } from 'reselect';
import _ from 'lodash';

export const taskContentCommentsSelector = store => store.taskContentComments;

export const threadSelector = createSelector(
  taskContentCommentsSelector,
  (store, ownProps) => ownProps,
  (
    taskContentComments,
    {
      timelineTaskId, commentableType, commentableId, widgetRowId, widgetFieldName,
    }
  ) => {
    const allContentCommentsForTask = taskContentComments[timelineTaskId] || [];
    if (!allContentCommentsForTask.length) return [];

    const linkingMetadata = (_.isNumber(widgetRowId) && widgetFieldName)
      ? { widgetRowId, widgetFieldName }
      : null;

    return _.chain(allContentCommentsForTask)
      .filter({
        ...linkingMetadata && { linking_metadata: linkingMetadata },
        commentable_type: commentableType,
        commentable_id: commentableId,
      })
      .sortBy('commented_at')
      .value();
  }
);

export const threadCountSelector = createSelector(threadSelector, _.size);
