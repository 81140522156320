import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import AddNewJumpButton from './AddNewJumpButton';
import LogicJump from './LogicJump';
import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import SettingsHeaderRow from '../SettingsHeaderRow';

const { columnLogicLengthSelector } = TaskWidgetSelectors;

const LogicJumps = ({ columnIndex }) => {
  const { formatMessage } = useIntl();
  const logicLength = useSelector(state => columnLogicLengthSelector(state, { columnIndex }));

  if (logicLength === 0) return null;

  return (
    <SettingsHeaderRow
      title={ formatMessage({ id: 'editWidget.builder.optionsColumn.logic.sectionTitle' }) }
      canHide
      className='showtime-record-log-builder-field__config-row'
    >
      <div className='showtime-record-log-builder-field__config'>
        {_.range(logicLength)
          .map(logicIndex => <LogicJump { ...{ columnIndex, logicIndex } } />)}
      </div>
      <AddNewJumpButton columnIndex={ columnIndex } />
    </SettingsHeaderRow>
  );
};
LogicJumps.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default LogicJumps;
