import {
  getAssignedLocationTimelineTemplateIds,
  getAssignedGroupTimelineTemplateIds,
  getAssignedOrganizationTimelineTemplateIds,
} from './siteAccessSelectors';
import { getUserRoleHelper } from './roleSelectors';

export const getSaveableSiteAssignments = (store) => {
  const user = getUserRoleHelper(store);
  if (user.isOrgAdminOrAbove()) {
    return [window.prefetch.org_user_timeline_template_id];
  }
  const locationAssignments = getAssignedLocationTimelineTemplateIds(store);
  if (user.isEnduser()) {
    return locationAssignments;
  }
  const groupAssignments = getAssignedGroupTimelineTemplateIds(store);
  const orgAssignments = getAssignedOrganizationTimelineTemplateIds(store);
  return [...locationAssignments, ...groupAssignments, ...orgAssignments];
};

export * from './siteAccessSelectors';
export * from './roleSelectors';
