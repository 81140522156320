import { getSaveableSiteAssignments } from '../selectors';
import {
  ASSIGN_ORG_TO_USER,
  UNASSIGN_ORG_FROM_USER,
  ASSIGN_GROUP_TO_USER,
  UNASSIGN_GROUP_FROM_USER,
  ASSIGN_LOCATION_TO_USER,
  UNASSIGN_LOCATION_FROM_USER,
  SET_USER_ROLE,
  SET_INITIAL_USER_ASSIGNMENTS,
} from '../constants';
import { TimelineTemplateIdsLegacy } from '../legacy';

const assignmentChangingActions = [
  ASSIGN_ORG_TO_USER,
  UNASSIGN_ORG_FROM_USER,
  ASSIGN_GROUP_TO_USER,
  UNASSIGN_GROUP_FROM_USER,
  ASSIGN_LOCATION_TO_USER,
  UNASSIGN_LOCATION_FROM_USER,
  SET_USER_ROLE,
  SET_INITIAL_USER_ASSIGNMENTS,
];

export default store => next => (action) => {
  const result = next(action);
  if (assignmentChangingActions.includes(action.type)) {
    const saveableAssignments = getSaveableSiteAssignments(store.getState());
    if (saveableAssignments.length > 0) {
      new TimelineTemplateIdsLegacy().value = saveableAssignments;
    }
  }
  return result;
};
