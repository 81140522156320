import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabHeader = (props) => {
  const {
    selected, index, children, ...otherProps
  } = props;
  const classes = classNames({
    'showtime-tabs-underline-headers-item': true,
    'is-inactive': !selected,
    'is-active': selected,
  });
  return (
    <a className={ classes } href='javascript:;' role='tab' data-tabindex={ index } { ...otherProps }>
      { children }
    </a>
  );
};

TabHeader.propTypes = {
  index: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
};

TabHeader.defaultProps = {
  index: 0,
  selected: false,
  onClick: () => { },
  children: [],
};

export default TabHeader;
