import 'whatwg-fetch';
import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

// remove this when we stop using phantomjs
if (!window.const) {
  window.const = window.var;
}
