export const TimeSlotConfigurationConstants = {
  DAYS_ORDER: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  DAYS_OF_THE_WEEK: {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
  },
  SET_START_TIME: 'time_slot_configuration.set_start_time',
  SET_TIMESLOTS: 'time_slot_configuration.set_timeslots',
  SET_END_TIME: 'time_slot_configuration.set_end_time',
  SET_CLOSED: 'time_slot_configuration.set_closed',
  SET_BATCH_CLOSED: 'time_slot_configuration.set_batch_closed',
  RESET_DAY_OF_WEEK: 'time_slot_configuration.reset_day_of_week',
  RESET_LOCATION_DAY_OF_WEEK: 'time_slot_configuration.reset_location_day_of_week',
  DESTROY: 'time_slot_configuration.destroy',
  RESET_STORE: 'time_slot_configuration.reset_store',
};
