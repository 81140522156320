import fp from 'lodash/fp';
import { TaskPermissionConstants, TaskWizardConstants } from '../constants';
import { RequestConstants } from '../../request';
import { TaskConstants } from '../../task/constants';

const initialState = ({
  permissionLevel: TaskPermissionConstants.DEFAULT_PERMISSION,
  assignedUserIds: [],
  assignedCustomRoleIds: [],
  createdByCustomRole: null,
  flaggable: TaskPermissionConstants.FLAG_VALUES.NOT_FLAGGABLE,
  priority: TaskPermissionConstants.PRIORITY_VALUES.MEDIUM,
  requiresPin: false,
});

const taskLoaded = RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY);

const addOrRemove = (item, list) => list.includes(item) ? fp.pull(item, list) : [...list, item];

const taskPermissionsActionCases = {
  [taskLoaded]: (state, { content }) => ({
    permissionLevel: content.permission_level,
    assignedUserIds: content.assigned_user_ids || [],
    assignedCustomRoleIds: content.assigned_custom_role_ids || [],
    createdByCustomRole: content.created_by_custom_role,
    editPermissionTypes: content.edit_permission_types,
    flaggable: content.flaggable,
    priority: content.priority,
    requiresPin: content.requires_pin,
  }),
  [TaskPermissionConstants.UPDATE_ASSIGNED_CUSTOM_ROLE]: (state, { customRoleId }) => ({
    ...state,
    assignedCustomRoleIds: addOrRemove(customRoleId, state.assignedCustomRoleIds),
  }),
  [TaskPermissionConstants.CHANGE_PERMISSION_LEVEL]: (state, { permission }) => ({
    ...state,
    permissionLevel: permission,
  }),
  [TaskPermissionConstants.UPDATE_CUSTOM_ROLE_PERMISSIONS]: (state, { permissionTypes }) => ({
    ...state,
    editPermissionTypes: permissionTypes,
  }),
  [TaskPermissionConstants.UPDATE_ASSIGNED_USER]: (state, { userId }) => ({
    ...state,
    assignedUserIds: addOrRemove(userId, state.assignedUserIds),
  }),
  [TaskPermissionConstants.CHANGE_TASK_FLAGGING]: (state, { flagging }) => ({
    ...state,
    flaggable: flagging,
  }),
  [TaskPermissionConstants.CHANGE_TASK_PRIORITY]: (state, { priority }) => ({
    ...state,
    priority,
  }),
  [TaskPermissionConstants.CHANGE_TASK_REQUIRES_PIN]: (state, { requiresPin }) => ({
    ...state,
    requiresPin,
  }),
  [TaskConstants.RESET]: () => initialState,
  [TaskPermissionConstants.CLEAR_ASSIGNMENTS]: () => initialState,
};

const taskPermissionsReducer = (state, action) => {
  state = state || initialState;
  const actionCase = taskPermissionsActionCases[action.type];
  if (actionCase) return actionCase(state, action);
  return state;
};

export default taskPermissionsReducer;
