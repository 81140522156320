import { LocationConstants } from '../sites/constants';

export const MOBILE_BREAKPOINT = 710;

export const GET_SETUP_GUIDE_PROGRESS = '@setup_guide_link get_setup_guide_progress';
export const SKIP_SETUP_GUIDE_PROGRESS = '@setup_guide_link skip_setup_guide_progress';
export const SETUP_GUIDE_PROGRESS_URL = '/api/setup_guide';

export const billingPage = '/billing';
export const rootPath = '/';
export const tasksPath = '/tasks';
export const businessHoursPath = '/hours';
export const inviteUsersPath = '/users';
export const goLivePath = '/live';
export const sitesPage = '/sites';

const orgBusinessHoursPage = '/organizations/{organizationId}/edit';
const siteSettingsPage = `/sites#/site/{locationId}${LocationConstants.SET_LIVE_SITE}`;
const newUserPage = '/organizations/{organizationId}/users/new';

export const generateOrgBusinessHoursPagePath = organizationId => orgBusinessHoursPage.replace('{organizationId}', organizationId);
export const generateSiteSettingsPagePath = locationId => siteSettingsPage.replace('{locationId}', locationId);
export const generateNewUserPagePath = organizationId => newUserPage.replace('{organizationId}', organizationId);
