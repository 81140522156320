import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import { routeTo } from '../router/actionCreators';
import Callout from './Callout';
import { HelpMessage, HelpMessageLink } from './HelpMessage';
import SVGIcon from './SVGIcon';
import Button from './Button';
import { getCurrentUserRole } from '../application/UserRole';
import { ToolTipWrapper } from './ToolTip';

const {
  FEATURE_UPSELL, DISABLED_FEATURE_INTERACTION,
} = TrailMetricsDirectory.application.account;

const DIAMOND_ICON_PATH = 'mini/objects/object-16px-diamond';

const BILLING_ROUTE = '/billing#/plans';

export const useBillingRouting = (featureName) => {
  const dispatch = useDispatch();
  const routeToPlans = () => dispatch(routeTo(BILLING_ROUTE, { js: false }));
  const onPlanRequested = () => {
    const { current_user: { plan } } = window;

    const metric = { ...FEATURE_UPSELL, name: `${FEATURE_UPSELL.name} - ${featureName}` };

    metricsPublisher.recordMetric(metric, { plan }).then(routeToPlans);
  };
  return onPlanRequested;
};

export const UpsellPill = ({ tooltip }) => {
  const { formatMessage } = useIntl();
  const pill = (
    <Callout upsell dataTest='upsell.pill' hasDot={ false } iconName={ DIAMOND_ICON_PATH }>
      { formatMessage({ id: 'upsell.pill' }) }
    </Callout>
  );
  if (!tooltip) return pill;

  return (
    <ToolTipWrapper
      message={ formatMessage({ id: 'upsell.tooltip' }) }
      upsell
    >
      { pill }
    </ToolTipWrapper>
  );
};

UpsellPill.propTypes = {
  tooltip: PropTypes.bool,
};
UpsellPill.defaultProps = {
  tooltip: false,
};

export const UpsellPrimaryButton = ({ featureName }) => {
  const onPlanRequested = useBillingRouting(featureName);
  return (
    <Button
      small
      create
      onClick={ onPlanRequested }
      iconName={ DIAMOND_ICON_PATH }
      dataTest='upsell.button'
    >
      { useIntl().formatMessage({ id: 'upsell.pill' }) }
    </Button>
  );
};

UpsellPrimaryButton.propTypes = {
  featureName: PropTypes.string.isRequired,
};

const UpsellBanner = ({
  title,
  message,
}) => (
  <div
    className='showtime-task-preview__warning showtime-task-preview__warning--upsell'
    data-test='upsell.banner'
  >
    <div className='showtime-task-preview__warning-title'>
      <SVGIcon
        classes='showtime-icon showtime-icon--size-16'
        iconName={ DIAMOND_ICON_PATH }
      />
      { title }
    </div>
    <div className='showtime-task-preview__warning-description'>
      { message }
    </div>
  </div>
);

UpsellBanner.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const RestrictedTaskUpsellBanner = ({ isLibrary }) => {
  const { formatMessage } = useIntl();

  const title = isLibrary
    ? formatMessage({ id: 'upsell.libraryBannerTitle' })
    : formatMessage({ id: 'upsell.bannerTitle' });
  return (
    <UpsellBanner
      title={ title }
      message={ (
        <FormattedMessage
          id='upsell.bannerMessage'
          values={ {
            link: (
              <a
                className='showtime-link showtime-link--highlight showtime-link--underline'
                onClick={ useBillingRouting('Restricted Task') }
                href='javascript:void(0)'
              >
                {formatMessage({ id: 'upsell.bannerLinkText' })}
              </a>
            ),
          } }
        />
      ) }
    />
  );
};

RestrictedTaskUpsellBanner.propTypes = {
  isLibrary: PropTypes.bool,
};

RestrictedTaskUpsellBanner.defaultProps = {
  isLibrary: false,
};

const UpsellHelpMessage = ({ featureName }) => {
  const { formatMessage } = useIntl();
  const onPlanRequested = useBillingRouting(featureName);
  return (
    <HelpMessage
      message={ formatMessage({ id: 'upsell.helpMessage' }) }
      upsell
      dataTest='upsell.help'
    >
      <HelpMessageLink onClick={ onPlanRequested } dataTest='upsell.action' metricName='Upsell'>
        {formatMessage({ id: 'upsell.helpMessageLink' })}
      </HelpMessageLink>
    </HelpMessage>
  );
};

UpsellHelpMessage.propTypes = {
  featureName: PropTypes.string,
};
UpsellHelpMessage.defaultProps = {
  featureName: undefined,
};

export const shouldShowUpsell = featureName => featureName && !window.config.features[featureName];

export const featureIsAccessibleForUser = featureName => Boolean(featureName) && (
  window.config.features[featureName] ||
  getCurrentUserRole().isSuperAdminOrBeingShadowedWithAllFeatures()
);

export const UpsellDisabled = ({ featureName, children, disabled }) => {
  if (!disabled) return children;

  const isBeingShadowed = getCurrentUserRole().isSuperAdminOrBeingShadowedWithAllFeatures();

  const onDisabledAreaClick = () => {
    const metric = { ...DISABLED_FEATURE_INTERACTION, name: `${DISABLED_FEATURE_INTERACTION.name} - ${featureName}` };
    metricsPublisher.recordMetric(metric, { plan: window.current_user.plan });
  };

  const upsellChildrenClassname = classNames({
    'showtime-upsell__children--super': isBeingShadowed,
    'showtime-upsell__children--default': !isBeingShadowed,
  });
  return (
    <div data-test='upsell-children' onClick={ onDisabledAreaClick }>
      <div className={ upsellChildrenClassname }>
        {children}
      </div>
    </div>
  );
};

UpsellDisabled.propTypes = {
  featureName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

UpsellDisabled.defaultProps = {
  featureName: undefined,
  children: null,
  disabled: false,
};

const Upsell = ({ featureName, children, helpMessage }) => {
  if (!shouldShowUpsell(featureName)) return children;

  return (
    <div className='showtime-upsell'>
      { helpMessage && <UpsellHelpMessage featureName={ featureName } /> }
      <UpsellDisabled featureName={ featureName } disabled>
        { children }
      </UpsellDisabled>
    </div>
  );
};

Upsell.propTypes = {
  featureName: PropTypes.string,
  children: PropTypes.node,
  helpMessage: PropTypes.bool,
};

Upsell.defaultProps = {
  featureName: '',
  children: null,
  helpMessage: false,
};

export default Upsell;
