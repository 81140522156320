import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckBoxLabel extends Component {
  secondaryLabelElement() {
    if (!this.props.secondaryLabel) { return null; }
    return (
      <span
        className='showtime-checkbox__label-secondary'
        data-test='subtask.secondaryLabel'
      >
        { this.props.secondaryLabel }
      </span>
    );
  }

  primaryLabelElement() {
    if (!this.props.label) { return null; }
    return (
      <span
        data-test='subtask.primaryLabel'
        className='showtime-checkbox__label-primary'
      >
        { this.props.label }
      </span>
    );
  }

  render() {
    const { label, secondaryLabel } = this.props;
    if (!label && !secondaryLabel) { return null; }

    return (
      <span className='showtime-checkbox__label' data-test='checkbox.label'>
        { this.primaryLabelElement() }
        { this.secondaryLabelElement() }
      </span>
    );
  }
}

CheckBoxLabel.propTypes = {
  label: PropTypes.string,
  secondaryLabel: PropTypes.node,
};

CheckBoxLabel.defaultProps = {
  label: '',
  secondaryLabel: '',
};

export default CheckBoxLabel;
