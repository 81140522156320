import _ from 'lodash';

export const getYoutubeCode = url => _.get(/(?:\.be\/|\/watch\?v=|\/(?=p\/))([\w\/\-]+)/.exec(url), 1);

export const getVimeoCode = url => _.get(/(?:^|https?:\/\/|www.)vimeo\.com\/(\d+)/.exec(url), 1);

export const getEmbedSrc = (url) => {
  const youtubeCode = getYoutubeCode(url);
  if (youtubeCode) {
    return `https://www.youtube.com/embed/${youtubeCode}`;
  }
  const vimeoCode = getVimeoCode(url);
  if (vimeoCode) {
    return `https://player.vimeo.com/video/${vimeoCode}`;
  }
  return null;
};
