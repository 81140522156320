import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import fp from 'lodash/fp';

import { TaskReportsActions } from '../actions';
import { TaskReportsSelectors } from '../selectors';
import CheckBox from '../../common/forms/CheckBox';
import { TASK_PDF_EXPORT_LIMIT } from '../constants';

export const TaskCheckbox = ({
  isChecked, onCheck, disabled, intl: { formatMessage },
}) => (
  <CheckBox
    checkboxClasses='showtime-checkbox--small'
    checked={ isChecked }
    onChange={ onCheck }
    disabled={ disabled }
    dataTest='task.checkbox'
    dataHint={ disabled
      ? formatMessage({ id: 'taskReports.exportLimitTooltip' }, { limit: TASK_PDF_EXPORT_LIMIT })
      : undefined
    }
    hintRight
  />
);

TaskCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  isChecked: TaskReportsSelectors.taskIsSelectedSelector(state, ownProps),
  selectedTaskIds: TaskReportsSelectors.selectedTaskIdsSelector(state),
  disabled: TaskReportsSelectors.taskSelectionDisabledSelector(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  setSelectedTaskIds: value => dispatch(TaskReportsActions.setSelectedTaskIds(value)),
});

const mergeProps = (
  { isChecked, selectedTaskIds, disabled }, { setSelectedTaskIds }, { id, intl }
) => ({
  isChecked,
  disabled,
  intl,
  onCheck: () => {
    const ids = isChecked ? fp.pull(id, selectedTaskIds) : [...selectedTaskIds, id];
    setSelectedTaskIds(ids);
  },
});

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(TaskCheckbox);
