const SAVEABLE_TIMESLOT_FIELDS = [
  'dayOfWeek',
  'dayOfWeekNum',
  'endTime',
  'isClosed',
  'level',
  'locationId',
  'name',
  'position',
  'startTime',
  'uuid',
  'baseTimeslotUuid',
];

export const TimeslotConstants = {
  TIME_FMT: 'HH:mm',
  DAYS: 'days',
  OPEN: 'time_slot.open',
  CLOSE: 'time_slot.close',
  SET_TIME: 'time_slot.set_time',
  DEFAULT_TIMESLOTS: 'defaultTimeslots',
  TIMESLOTS_LOADED: 'time_slot.loaded',
  ORGANIZATION_TIMESLOTS_URL(id) {
    return `/api/v1/organizations/${id}/timeslots`;
  },
  locationTimeslotsUrl: id => `/api/v1/locations/${id}/timeslots`,
  SAVEABLE_TIMESLOT_FIELDS,
};
