import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path, Polygon } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z'
    />
    <Polygon
      is='polygon'
      fill={ (props.style && props.style.fill) || '#444' }
      points='9,4 7,4 7,9 12,9 12,7 9,7 '
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
