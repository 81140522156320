import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { HelpMessage, HelpMessageLink } from '../common/HelpMessage';
import SVGIcon from '../common/SVGIcon';

const InactivePaidSitesMessage = ({
  minimumSites, paidSiteCount, additionalLink, intl,
}) => {
  if (paidSiteCount >= minimumSites) return null;

  const sitesToActivateCount = minimumSites - paidSiteCount;
  const message = intl.formatMessage(
    { id: 'sites.inactivePaidSites.description' }, { siteCount: sitesToActivateCount });
  return (
    <HelpMessage attention message={ message }>
      <div className='showtime-callout-help--vertical-align'>
        { additionalLink }
        <HelpMessageLink
          href='https://intercom.help/trail/en/articles/923110-creating-and-managing-sites'
          metricName='SitesPanel - inactive paid sites'
        >
          <SVGIcon
            classes='showtime-icon showtime-icon--margin-right'
            iconName='mini/arrows/arrows-16px-4_launch-11'
          />
          { intl.formatMessage({ id: 'sites.inactivePaidSites.link' }) }
        </HelpMessageLink>
      </div>
    </HelpMessage>
  );
};

InactivePaidSitesMessage.propTypes = {
  minimumSites: PropTypes.number,
  paidSiteCount: PropTypes.number,
  additionalLink: PropTypes.node,
};

InactivePaidSitesMessage.defaultProps = {
  minimumSites: 0,
  paidSiteCount: 0,
  additionalLink: null,
};

export default injectIntl(InactivePaidSitesMessage);
