import _ from 'lodash';
import { RequestConstants } from '../../request';
import { TaskWizardConstants } from '../constants';
import { TaskConstants } from '../../task/constants';
import WidgetConstants from '../../widget/constants';

export const taskWizardReducer = function (state, action) {
  const initialState = {
    content: {
      isSavingTask: false,
      taskSaved: false,
      loadTaskStatus: TaskWizardConstants.loadTaskStatus.NOT_LOADED,
    },
    activeSection: TaskWizardConstants.sections.CONTENT,
    createdAt: null,
    createdByUserName: null,
  };
  const currState = state || _.extend({}, initialState);
  let newContent;

  switch (action.type) {
    case TaskWizardConstants.BEGIN_SAVING:
      return _.merge({}, currState, {
        content: {
          isSavingTask: true,
        },
      });
    case RequestConstants.getLoadedActionType(TaskWizardConstants.CREATE_TASK_KEY):
    case RequestConstants.getLoadedActionType(TaskWizardConstants.EDIT_TASK_KEY):
      newContent = {
        taskSaved: true,
        legacy: {
          templateId: action.content.id,
        },
      };

      return _.merge({}, currState, {
        content: newContent,
      });
    case RequestConstants.getErrorActionType(TaskWizardConstants.CREATE_TASK_KEY):
    case RequestConstants.getErrorActionType(TaskWizardConstants.EDIT_TASK_KEY):
    case RequestConstants.getErrorActionType(WidgetConstants.CREATE_WIDGET_KEY):
    case RequestConstants.getErrorActionType(WidgetConstants.UPDATE_WIDGET_KEY):
      newContent = {
        isSavingTask: false,
      };

      return _.merge({}, currState, {
        content: newContent,
      });
    case RequestConstants.getLoadingActionType(TaskWizardConstants.GET_TASK_KEY):
      newContent = {
        loadTaskStatus: TaskWizardConstants.loadTaskStatus.NOT_LOADED,
      };

      return _.merge({}, currState, {
        content: newContent,
      });
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      newContent = {
        loadTaskStatus: TaskWizardConstants.loadTaskStatus.LOADED,
      };

      return _.merge({}, currState, {
        content: newContent,
        createdAt: action.content.created_at,
        createdByUserName: action.content.created_by_user_name,
      });
    case RequestConstants.getErrorActionType(TaskWizardConstants.GET_TASK_KEY):
      newContent = {
        loadTaskStatus: TaskWizardConstants.loadTaskStatus.FAILED,
      };

      return _.merge({}, currState, {
        content: newContent,
      });
    case TaskWizardConstants.SELECT_ACTIVE_SECTION:
      return { ...state, activeSection: action.activeSection };
    case TaskConstants.RESET:
      return initialState;
    default:
      return currState;
  }
};
