import { NoticeConstants } from '../constants';
import { TimeoutMiddlewareConstants } from '../../middleware/timeout';

export const NoticeActions = {
  notice(message) {
    return {
      type: NoticeConstants.NOTICE,
      noticeMessage: message,
    };
  },

  clear(delayInMs) {
    if (delayInMs && delayInMs > 0) {
      return {
        type: TimeoutMiddlewareConstants.TIMEOUT,
        delayedAction: {
          type: NoticeConstants.CLEAR,
        },
        delayPeriodMs: delayInMs,
      };
    }

    return {
      type: NoticeConstants.CLEAR,
    };
  },
};
