import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { DateFilter } from './DateFilter';
import DropDownMenu from '../menu/DropDownMenu';

const isSameDay = (momentDate, daysAgo) => momentDate.isSame(moment().subtract(daysAgo, 'days'), 'day');

const prettyPrintPeriod = (startDate, endDate, yml) => {
  if (isSameDay(endDate, 1)) {
    if (isSameDay(startDate, 30)) {
      return yml('last30Days');
    }
  }
  return yml(
    'currentPeriod',
    { startDate: startDate.format(yml('dateFormat')), endDate: endDate.format(yml('dateFormat')) }
  );
};

export const DateSelectFilter = ({
  intl,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  buttonLabel,
  iconName,
  iconPosition,
  className,
  dropdownClassName,
  minDate,
  maxDate,
  dataTest,
  initiallyExpanded,
  dropdownContent,
}) => {
  const yml = (identifier, replacements) => intl.formatMessage(
    { id: `scores.filters.dateFilter.${identifier}` }, replacements
  );

  const dropdown = (
    <React.Fragment>
      <div className={
        classnames(
          'showtime-scores-controls__filters-item-menu', {
            [dropdownClassName]: dropdownClassName,
          }
        )
      }
      >
        { dropdownContent }
        <DateFilter
          labelText={ yml('startDate') }
          dateFormat={ yml('dateFormat') }
          selectedDate={ startDate }
          onDateChange={ onStartDateChange }
          minDate={ minDate }
          maxDate={ moment.min(_.compact([maxDate, endDate])) }
        />
        <DateFilter
          labelText={ yml('endDate') }
          dateFormat={ yml('dateFormat') }
          selectedDate={ endDate }
          onDateChange={ onEndDateChange }
          minDate={ moment.max(_.compact([minDate, startDate])) }
          maxDate={ maxDate }
        />
      </div>
    </React.Fragment>
  );

  const menuButtonLabel = buttonLabel ||
    yml('buttonText', { currentPeriod: prettyPrintPeriod(startDate, endDate, yml) });

  return (
    <div data-test={ dataTest }>
      <DropDownMenu
        label={ menuButtonLabel }
        dataTestKey='filterSummary.label'
        containerClassName={ className }
        iconName={ iconName }
        iconPosition={ iconPosition }
        initiallyExpanded={ initiallyExpanded }
      >
        { dropdown }
      </DropDownMenu>
    </div>
  );
};

DateSelectFilter.propTypes = {
  startDate: PropTypes.instanceOf(moment().constructor).isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(moment().constructor).isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  buttonLabel: PropTypes.any,
  iconName: PropTypes.string,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  dropdownContent: PropTypes.node,
  minDate: PropTypes.instanceOf(moment().constructor),
  maxDate: PropTypes.instanceOf(moment().constructor),
  dataTest: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
};

DateSelectFilter.defaultProps = {
  buttonLabel: undefined,
  iconName: undefined,
  iconPosition: undefined,
  className: 'showtime-scores-controls__filters-item',
  dropdownClassName: undefined,
  minDate: undefined,
  maxDate: undefined,
  dataTest: undefined,
  initiallyExpanded: false,
  dropdownContent: null,
};

export default injectIntl(DateSelectFilter);
