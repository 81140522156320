import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import PDFWidgetList from './PDFWidgetList';
import PDFWidgetTable from './PDFWidgetTable';

const PDFTaskWidget = props => (
  <View is='div'>
    { props.widget.public_settings.singleRecord
      ? <PDFWidgetList { ...props } />
      : <PDFWidgetTable { ...props } /> }
  </View>
);

PDFTaskWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

export default PDFTaskWidget;
