import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormSectionItem = ({
  className,
  fullWidth,
  paddingTop,
  paddingTopSmall,
  paddingBottom,
  paddingBottomLarge,
  children,
}) => (
  <div
    className={ classNames(
      'showtime-form-section__item',
      className,
      {
        'showtime-form-section__item--full-width': fullWidth,
        'showtime-form-section__item--padding-top': paddingTop,
        'showtime-form-section__item--padding-top-small': paddingTopSmall,
        'showtime-form-section__item--padding-bottom': paddingBottom,
        'showtime-form-section__item--padding-bottom-large': paddingBottomLarge,
      }
    ) }
  >
    { children }
  </div>
);

FormSectionItem.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  paddingTop: PropTypes.bool,
  paddingTopSmall: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  paddingBottomLarge: PropTypes.bool,
  children: PropTypes.node,
};

FormSectionItem.defaultProps = {
  className: '',
  fullWidth: false,
  paddingTop: false,
  paddingBottom: false,
  paddingBottomLarge: false,
  paddingTopSmall: false,
  children: null,
};

export default FormSectionItem;
