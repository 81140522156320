import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { RepeatSvg } from './svg';

const PDFWidgetTableHeader = ({ intl, widget }) => (
  <View
    is='div'
    style={
      {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        textTransform: 'uppercase',
        color: '#92929b',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#f6f6f6',
        fontSize: 10,
        fontWeight: 600,
        paddingBottom: 5,
      }
    }
  >
    {
      [
        ..._.get(widget, 'public_settings.columns', []).map(
          column => (
            <View
              key={ `${widget.id} ${column.columnTitle}` }
              is='div'
              style={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                paddingRight: 10,
              } }
            >
              <Text is='span'>{column.columnTitle}</Text>
              { column.cellSettings.copyValueToNew ?
                <RepeatSvg
                  style={ {
                    fill: '#cacaca',
                    height: 10,
                    marginTop: 2,
                  } }
                /> : null }
            </View>
          )
        ),
        <View key={ `${widget.id} lastUpdated` } is='div' style={ { flex: 1 } }>
          <Text is='span'>{ intl.formatMessage({ id: 'trail.widgets.data_capture.last_updated' }) }</Text>
        </View>,
      ]
    }
  </View>
);

PDFWidgetTableHeader.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default PDFWidgetTableHeader;
