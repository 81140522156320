export const TagFilterConstants = {
  OPEN: 'tag.filter.open',
  CLOSE: 'tag.filter.close',
  LOADING: 'tag.filter.loading',
  SELECT: 'tag.filter.select',
  DESELECT: 'tag.filter.deselect',
  CLEAR_ALL: 'tag.filter.clearAll',
  SET_TAGS_WITHOUT_TAGS_TO_LOADING: 'tag.filer.no.tags.loading',
  SET_ASSIGN_TO_ME_TASKS_TO_LOADING: 'assign.to.me.tasks.loading',
  USER_COOKIE_KEY(username) {
    return `${username}tags`;
  },
};
