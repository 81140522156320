import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import * as filestack from 'filestack-js';
import { compose } from 'redux';

import EmptyState from '../common/EmptyState';
import { readPolicyHOC } from './hoc';
import LoadingSpinner from '../common/LoadingSpinner';
import { canMakeValidCdnUrl } from './securityValidation';

export const PREVIEWER_SELECTOR = 'filestack_previewer';

export class FileViewer extends Component {
  filestackClient = null;

  filestackApiKey = _.get(window, 'config.filestackApiKey');

  hasPreviewed = false;

  componentDidMount() {
    this.previewFileWithFilestack();
  }

  componentDidUpdate() {
    this.previewFileWithFilestack();
  }

  previewFileWithFilestack = () => {
    const { config: { filestackApiKey } = {} } = window;
    const canPreview = filestackApiKey && canMakeValidCdnUrl(this.props);
    if (canPreview && !this.hasPreviewed) {
      const { handle, policy, signature } = this.props;
      const security = { policy, signature };

      this.filestackClient = filestack.init(this.filestackApiKey, { security });
      this.filestackClient.preview(handle, {
        id: PREVIEWER_SELECTOR,
        css: `${window.location.origin}/fileviewer.css`,
      });
      this.hasPreviewed = true;
    }
  }

  render() {
    const { policy, signature } = this.props;
    return this.filestackApiKey
      ? (
        <React.Fragment>
          {!(policy && signature) && <LoadingSpinner />}
          <div id={ PREVIEWER_SELECTOR } />
        </React.Fragment>
      ) : (
        <EmptyState
          iconName='mini/ui/ui-16px-3_alert'
          messageTitle={ this.props.intl.formatMessage({ id: 'fileViewer.errors.noApiKey' }) }
        />
      );
  }
}

FileViewer.propTypes = {
  handle: PropTypes.string.isRequired,
  policy: PropTypes.string,
  signature: PropTypes.string,
};

FileViewer.defaultProps = {
  policy: undefined,
  signature: undefined,
};

export default compose(
  readPolicyHOC,
  injectIntl
)(FileViewer);
