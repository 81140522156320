import { RequestConstants } from '../../request';
import { LINK_FETCH_KEY } from '../constants';

export const linkReducer = (store, action) => {
  store = store || {};
  switch (action.type) {
    case RequestConstants.getLoadedActionType(LINK_FETCH_KEY):
      return {
        ...store,
        [action.content.id]: {
          url: action.content.url,
          id: action.content.id,
          youtubeCode: action.content.youtube_code,
        },
      };
    default:
      return store;
  }
};
