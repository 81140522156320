import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { threadSelector } from './selectors';
import TaskContentCommentBox from './TaskContentCommentBox';
import Comment from '../conversation/Comment';
import { AttachmentConstants } from '../attachments/constants';

const { FILE_TYPE } = AttachmentConstants;

const TaskContentCommentThread = (props) => {
  const comments = useSelector(state => threadSelector(state, props));

  return (
    <div
      className='showtime-task__body-item--conversation showtime-task__conversation'
      data-test='trail.taskContentCommentThread'
    >
      { comments.map(comment => (
        <Comment
          key={ comment.commented_at }
          userId={ comment.commented_by }
          taskId={ comment.timeline_task_id }
          userName={ comment.commented_by_user_name }
          timestamp={ comment.commented_at }
          messageId={ comment.id }
          body={ comment.content }
          attachment={
            _.map(comment.attachments, a => a && ({ ...a, type: FILE_TYPE }))[0]
          }
        />
      )) }
      { !props.readOnly && <TaskContentCommentBox { ...props } /> }
    </div>
  );
};

TaskContentCommentThread.propTypes = {
  timelineTaskId: PropTypes.number.isRequired,
  commentableId: PropTypes.number.isRequired,
  commentableType: PropTypes.string.isRequired,
  widgetRowId: PropTypes.number,
  widgetFieldName: PropTypes.string,
  readOnly: PropTypes.bool,
};

TaskContentCommentThread.defaultProps = {
  widgetRowId: null,
  widgetFieldName: null,
  readOnly: false,
};

export default TaskContentCommentThread;
