import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

import MinMaxWarningRange from './MinMaxWarningRange';

const AcceptableRangeMinMax = ({
  minValueWarningLimit, maxValueWarningLimit, outOfRangeWarningText, requestValidationAction,
  onMinLimitChange, onMaxLimitChange, onWarningTextChange, onRequestValidationActionChange,
  onRequireMandatoryValidationActionChange, requireMandatoryValidationAction,
  intl, errors, onActionTemplateIdChange, actionTemplateId,
}) => {
  const intlKey = 'editWidget.builder.numberColumn.range';
  const formatMessage = createIntlMessageFormatter(intl, intlKey);
  const formatValidationMessage = createIntlMessageFormatter(intl, `${intlKey}.validation`);

  const getErrorForField = (fieldName) => {
    const fieldNames = Array.isArray(fieldName) ? fieldName : [fieldName];
    const fieldErrors = errors.filter(error => fieldNames.includes(error));

    if (fieldErrors.length === 0) return [];
    return [{ type: 'validation-error', message: formatValidationMessage(fieldErrors[0]) }];
  };

  return (
    <MinMaxWarningRange
      minFieldLabel={ formatMessage('minLabel') }
      minFieldPlaceholder={ formatMessage('minPlaceholder') }
      minFieldValue={ minValueWarningLimit }
      minFieldErrors={ getErrorForField(['minValueWarningLimit', 'isMaxLessThanMin']) }
      onMinFieldChange={ onMinLimitChange }
      maxFieldLabel={ formatMessage('maxLabel') }
      maxFieldPlaceholder={ formatMessage('maxPlaceholder') }
      maxFieldValue={ maxValueWarningLimit }
      maxFieldErrors={ getErrorForField('maxValueWarningLimit') }
      onMaxFieldChange={ onMaxLimitChange }
      warningTextFieldLabel={ formatMessage('messageLabel') }
      warningTextFieldPlaceholder={ formatMessage('messagePlaceholder') }
      warningTextFieldValue={ outOfRangeWarningText }
      warningTextFieldErrors={ getErrorForField('outOfRangeWarningText') }
      onWarningTextFieldChange={ onWarningTextChange }
      requestValidationAction={ requestValidationAction }
      onRequestValidationActionChange={ onRequestValidationActionChange }
      onRequireMandatoryValidationActionChange={ onRequireMandatoryValidationActionChange }
      requireMandatoryValidationAction={ requireMandatoryValidationAction }
      { ...{ onActionTemplateIdChange, actionTemplateId } }
    />
  );
};

AcceptableRangeMinMax.propTypes = {
  maxValueWarningLimit: PropTypes.number.isRequired,
  minValueWarningLimit: PropTypes.number.isRequired,
  onMaxLimitChange: PropTypes.func.isRequired,
  onMinLimitChange: PropTypes.func.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
  outOfRangeWarningText: PropTypes.string.isRequired,
  requestValidationAction: PropTypes.bool.isRequired,
  requireMandatoryValidationAction: PropTypes.bool.isRequired,
  onRequestValidationActionChange: PropTypes.func.isRequired,
  onRequireMandatoryValidationActionChange: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  onActionTemplateIdChange: PropTypes.func.isRequired,
  actionTemplateId: PropTypes.number,
};

AcceptableRangeMinMax.defaultProps = {
  actionTemplateId: undefined,
};

const mapStateToProps = (state, { columnIndex }) => {
  const { settings } = TaskWidgetSelectors.editedTaskWidgetConfig(state);
  const {
    minValueWarningLimit,
    maxValueWarningLimit,
    outOfRangeWarningText,
    requireValidationAction: requestValidationAction,
    requireMandatoryValidationAction,
    actionTemplateId,
  } = _.get(settings, `public.columns[${columnIndex}].cellSettings`, {});

  return {
    minValueWarningLimit,
    maxValueWarningLimit,
    outOfRangeWarningText,
    requestValidationAction,
    requireMandatoryValidationAction,
    actionTemplateId,
  };
};

const mapDispatchToProps = (dispatch, { columnIndex }) => {
  const { changeColumnAcceptableRange } = bindActionCreators(EditWidgetActions, dispatch);

  return {
    onActionTemplateIdChange: value => changeColumnAcceptableRange(columnIndex, value, 'actionTemplateId'),
    onMinLimitChange: value => changeColumnAcceptableRange(columnIndex, value, 'minValueWarningLimit'),
    onMaxLimitChange: value => changeColumnAcceptableRange(columnIndex, value, 'maxValueWarningLimit'),
    onWarningTextChange: value => changeColumnAcceptableRange(columnIndex, value, 'outOfRangeWarningText'),
    onRequestValidationActionChange: value => changeColumnAcceptableRange(columnIndex, value, 'requireValidationAction'),
    handleRequireMandatoryValidationActionChange: value => changeColumnAcceptableRange(columnIndex, value, 'requireMandatoryValidationAction'),
  };
};

const mergeProps = (
  stateProps,
  { handleRequireMandatoryValidationActionChange, ...dispatchProps },
  ownProps
) => {
  const onRequireMandatoryValidationActionChange = (value) => {
    handleRequireMandatoryValidationActionChange(value);

    if (value && !stateProps.requestValidationAction) {
      dispatchProps.onRequestValidationActionChange(value);
    }
  };

  return {
    ...stateProps, ...dispatchProps, ...ownProps, onRequireMandatoryValidationActionChange,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(AcceptableRangeMinMax);
