import _ from 'lodash';

export class Role {
  roleRadios = null;

  constructor() {
    this.roleRadios = Array.from(document.getElementsByName('user[role]'));
  }

  get value() {
    return _.find(this.roleRadios, { checked: true }).value;
  }

  onChange = (callback) => {
    this.roleRadios.forEach((radio) => {
      const onChange = () => callback(radio.value);
      radio.addEventListener('change', onChange);
    });
  };
}
