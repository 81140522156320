import _ from 'lodash';
import widgetConstants from '../constants';
import { RequestConstants } from '../../request';
import { requestStatuses } from '../../common/requestStatuses';

const { FETCH_WIDGETS_KEY } = widgetConstants;

const INITIAL_STATE = {
  status: requestStatuses.NOT_REQUESTED,
  entities: {},
};

const widgetsLoading = RequestConstants.getLoadingActionType(FETCH_WIDGETS_KEY);
const setWidgetsLoading = state => ({
  ...state,
  status: requestStatuses.LOADING,
});

const widgetsLoaded = RequestConstants.getLoadedActionType(FETCH_WIDGETS_KEY);
const loadWidgets = (state, action) => ({
  entities: {
    ...state.entities,
    ..._.keyBy(action.content, 'id'),
  },
  status: requestStatuses.LOADED,
});

const widgetsError = RequestConstants.getErrorActionType(FETCH_WIDGETS_KEY);
const setWidgetsError = state => ({
  ...state,
  status: requestStatuses.ERROR,
});

const actionCases = {
  [widgetsLoaded]: loadWidgets,
  [widgetsLoading]: setWidgetsLoading,
  [widgetsError]: setWidgetsError,
};

export const widgets = (state, action) => {
  state = state || INITIAL_STATE;
  if (actionCases[action.type]) return actionCases[action.type](state, action);

  return state;
};
