import { MESSAGE_DEVICE_BECAME_VISIBLE } from '../../../service-workers/constants';
import safePostMessage from '../helpers/safePostMessage';

export default (client = window) => {
  if (client.document.visibilityState === 'hidden') {
    return;
  }

  safePostMessage({ type: MESSAGE_DEVICE_BECAME_VISIBLE });
};
