import { TaskConstants } from '../../task/constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskActions = {
  reset: () => (dispatch) => {
    dispatch(unsavedChangesActions.cleared());

    dispatch({
      type: TaskConstants.RESET,
    });
  },
};
