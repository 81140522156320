import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TaskReportsSelectors } from '../selectors';
import CheckBox from '../../common/forms/CheckBox';
import SelectedTasksSummary from './SelectedTasksSummary';

const BulkExportButton = ({ onClick, checked }) => (
  <a
    className='showtime-task-reports__bulk-export-button'
    data-test='taskReports.bulkExportButton'
    onClick={ onClick }
  >
    <CheckBox
      checked={ checked }
      disabled
    />
    <SelectedTasksSummary />
  </a>
);

BulkExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  checked: !!TaskReportsSelectors.selectedTasksLengthSelector(state),
});

export default connect(mapStateToProps)(BulkExportButton);
