import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import EmptyWidget from '../EmptyWidget';

const createWidgetComponent = WidgetDataStore => WidgetComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      WidgetComponent.displayName = `CreatedWidgetComponent(${WidgetComponent.displayName || WidgetComponent.name})`;
    }
    getWidgetState() {
      const widget = this.widget();

      if (widget) {
        return {
          widget,
        };
      }
      return {
        widget: {},
      };
    }

    widget() {
      return this.props.widgetData || WidgetDataStore.getWidget(this.props.id);
    }

    custom(key) {
      const widgetData = this.widget();
      if (!_.isEmpty(widgetData) && widgetData.custom_fields) {
        return widgetData.custom_fields[key] || '';
      }
      return WidgetDataStore.getCustomField(this.props.id, key);
    }

    widgetId() {
      return this.props.id;
    }


    widgetFormClasses(widgetKey) {
      const widgetClass = {};
      widgetClass[widgetKey] = true;
      return classNames(_.merge({
        widget: true,
        'is-editing': this.widget().dirty,
        'is-clean': !this.widget().dirty,
        'is-unsubmitted': this.widget().submitted_at === null,
        'is-submitted': this.widget().submitted_at !== null,
      }, widgetClass));
    }

    renderEmptyWidget(widgetKey, widgetHeader, messageTopLine, messageBottomLines) {
      return (
        <EmptyWidget
          widgetKey={ widgetKey }
          widgetHeader={ widgetHeader }
          messageTopLine={ messageTopLine }
          messageBottomLines={ messageBottomLines }
        />
      );
    }

    render() {
      const widgetProps = {
        widget: () => this.widget(),
        getWidgetState: () => this.getWidgetState(),
        custom: key => this.custom(key),
        widgetFormClasses: key => this.widgetFormClasses(key),
        widgetId: () => this.widgetId(),
        renderEmptyWidget: (...args) => this.renderEmptyWidget(...args),
      };
      return <WidgetComponent widget={ widgetProps } { ...this.props } />;
    }
  };

export default createWidgetComponent;
