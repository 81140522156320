import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { threadCountSelector } from './selectors';
import Button from '../common/Button';

const CommentButton = ({
  onClick,
  ...props
}) => {
  const messageCount = useSelector(state => threadCountSelector(state, props));
  return (
    <Button
      small
      lowlightAlt
      className='showtime-task__conversation-button'
      dataTest='trail.taskContentComments'
      onClick={ onClick }
      counts={ { right: messageCount } }
      iconName={ !messageCount && 'mini/ui/ui-16px-2_chat-round-content' }
    />
  );
};

CommentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CommentButton;
