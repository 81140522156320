import { TrailStore } from '../../../trail/store';
import { TrailConstants } from '../../../trail/constants';

let blockUpdates = false;

export default async () => {
  if (blockUpdates) {
    return Promise.resolve();
  }
  blockUpdates = true;

  const fetchPromise = new TrailStore().forceFetch();

  setTimeout(() => {
    blockUpdates = false;
  }, TrailConstants.MIN_OFFLINE_DURATION);

  return fetchPromise;
};
