/**
 * Complete index of all recorded metrics in the Trail client application
 * Structure is: `TrailMetricsDirectory[<Page>][<Metric>]`
 *
 * Metric properties should conform to JSON Schema V4 except when identifying
 * properties as required, which should be included inline.
 *
 * Metric: {
 *   name: Value that will be sent to metric provider to identify this event,
 *   description: Optional string explaining what this metric is recording,
 *   enabled: true|false if this metric should be recorded,
 *   properties: {
 *     <property name>: {
 *       description: Optional string explaining the property
 *       type: JSON Schema compatible type information
 *       required: true|false should validation fail if this value is omitted
 *     }
 *   }
 * }
 */
export const TrailMetricsDirectory = {
  application: {
    connection: {
      ONLINE: {
        enabled: false,
        name: 'User came online',
        description: 'Recorded when a user is (re)connected to the internet',
        properties: {
          triggeredTime: {
            description: 'Time the event occurred in ISO 8601 format',
            type: 'string',
            required: true,
          },
        },
      },
      OFFLINE: {
        enabled: false,
        name: 'User went offline',
        description: 'Recorded when a user is disconnected to the internet',
        properties: {
          triggeredTime: {
            description: 'Time the event occurred in ISO 8601 format',
            type: 'string',
            required: true,
          },
        },
      },
    },
    offline: {
      queueProcessing: {
        ERROR: {
          enabled: false,
          name: 'Processing request queue error',
          description: 'Recorded when offline request queue results in caught error',
          properties: {
            queueLength: {
              description: 'The number of remaining items in the queue',
              type: 'integer',
              required: true,
            },
            message: {
              description: 'The error message thrown during request queue processing',
              type: 'string',
              required: true,
            },
          },
        },
        START: {
          enabled: true,
          name: 'Processing request queue start',
          description: 'Recorded when starting to process offline request queue',
          properties: {
            queueLength: {
              description: 'The number of remaining items in the queue',
              type: 'integer',
              required: true,
            },
          },
        },
        FINISH: {
          enabled: true,
          name: 'Processing request queue finish',
          description: 'Recorded when finished processing offline request queue',
          properties: {
            queueLength: {
              description: 'The number of remaining items in the queue',
              type: 'integer',
              required: true,
            },
            successfulResponses: {
              description: 'The number of successfully-processed items',
              type: 'integer',
              required: true,
            },
          },
        },
      },
      cacheRequest: {
        ERROR: {
          enabled: true,
          name: 'Cache request error',
          description: 'Recorded when offline request caching attempt throws an error',
          properties: {
            message: {
              description: 'The error message thrown during offline request caching',
              type: 'string',
              required: true,
            },
            url: {
              description: 'The url for the failed cache attempt',
              type: 'string',
              required: true,
            },
          },
        },
      },
    },
    handleGetRequest: {
      cacheRequest: {
        ERROR: {
          enabled: true,
          name: 'GET Cache request error',
          description: 'Recorded when GET request caching attempt throws an error',
          properties: {
            message: {
              description: 'The error thrown during GET request caching attempt',
              type: 'string',
              required: true,
            },
            url: {
              description: 'The url for the failed cache attempt',
              type: 'string',
              required: true,
            },
          },
        },
      },
      setServerTimeDiff: {
        ERROR: {
          enabled: true,
          name: 'GET setServerTimeDiff error',
          description: 'Recorded when GET request setServerTimeDiff attempt throws an error',
          properties: {
            message: {
              description: 'The error message thrown during GET request server time diff attempt',
              type: 'string',
              required: true,
            },
          },
        },
      },
    },
    handleQueuedRequest: {
      ERROR: {
        enabled: true,
        name: 'Handle queued request error',
        description: 'Recorded when queued request handler throws an error',
        properties: {
          message: {
            description: 'The error message thrown during queued request handling',
            type: 'string',
            required: true,
          },
          url: {
            description: 'The url for the failed queued request',
            type: 'string',
            required: true,
          },
        },
      },
    },
    account: {
      FEATURE_UPSELL: {
        enabled: true,
        name: 'Account - Upsell clicked',
        description: 'Recorded when the upsell feature has been requested',
        properties: {
          plan: {
            description: 'Account plan',
            type: 'string',
            required: true,
          },
        },
      },
      DISABLED_FEATURE_INTERACTION: {
        enabled: true,
        name: 'Account - Disabled feature clicked',
        description: 'Recorded when the disabled upsell feature is interacted with',
        properties: {
          plan: {
            description: 'Account plan',
            type: 'string',
            required: true,
          },
        },
      },
      SETUP: {
        name: 'Setup_Tasks',
        enabled: true,
        description: 'Recorded during task setup using the setup guide',
      },
    },
  },
  page: {
    HelpLink: {
      CLICKED: {
        enabled: true,
        name: 'Help Link Clicked',
        description: 'Recorded when a help link is clicked',
        properties: {
          helpLink: {
            type: 'string',
            description: 'The name of the help link',
            required: true,
          },
        },
      },
    },
    SignUp: {
      TRAIL_LOGO_CHECKOUT: {
        enabled: true,
        name: 'Trail Checkout Logo Clicked',
        description: 'Recorded when the checkout logo is clicked on to redirect to the marketing site',
      },
      COOKIE_CONSENT: {
        enabled: true,
        name: 'Cookie Consent',
        description: 'Recorded when a cookie has been accepted',
      },
      CHECKOUT_STEP_VIEWED: {
        enabled: true,
        name: 'Checkout Step Viewed',
        description: 'Recorded when proceeding from the account details step or sites step',
        properties: {
          step: {
            type: 'string',
            description: 'Current step user is on after the checkout page either accountDetail or siteNames',
            required: true,
          },
          checkout_id: {
            type: 'string',
            description: 'A UUID stored in a cookie to track the user on return',
            required: true,
          },
          plan: {
            type: 'string',
            description: 'Plan identifier string',
            required: true,
          },
        },
      },
      CHECKOUT_STEP_COMPLETED: {
        enabled: true,
        name: 'Checkout Step Completed',
        description: 'Recorded when the checkout step is clicked going to the siteNames page or sign up when single site',
        properties: {
          step: {
            type: 'string',
            description: 'Current step user is on after the checkout page, always accountDetails',
            required: true,
          },
          checkout_id: {
            type: 'string',
            description: 'A UUID stored in a cookie to track the user on return',
            required: true,
          },
          plan: {
            type: 'string',
            description: 'Plan identifier string',
            required: true,
          },
        },
      },
      ORDER_COMPLETED: {
        enabled: true,
        name: 'Order Completed',
        description: 'Recorded when sign up is completed',
        properties: {
          checkout_id: {
            type: 'string',
            description: 'A UUID stored in a cookie to track the user on return',
            required: true,
          },
          currency: {
            type: 'string',
            description: 'Always GBP',
            required: true,
          },
          order_id: {
            type: 'string',
            description: 'Used to identity the order, always Cardless',
            required: true,
          },
          plan: {
            type: 'string',
            description: 'Plan identifier string',
            required: true,
          },
          total: {
            type: 'number',
            description: 'The price total, plan price * total sites',
            required: true,
          },
          products: {
            type: 'array',
            description: 'A list of product details from Strpe including the name, price, product_id, quantity and variant',
          },
        },
      },
      ORDER_FAILED: {
        enabled: true,
        name: 'Order failed',
        description: 'Recorded when sign up has failed',
        properties: {
          checkout_id: {
            type: 'string',
            description: 'A UUID stored in a cookie to track the user on return',
            required: true,
          },
          currency: {
            type: 'string',
            description: 'Always GBP',
            required: true,
          },
          order_id: {
            type: 'string',
            description: 'Used to identity the order, always Cardless',
            required: true,
          },
          plan: {
            type: 'string',
            description: 'Plan identifier string',
            required: true,
          },
          total: {
            type: 'number',
            description: 'The price total, plan price * total sites',
            required: true,
          },
          status: {
            type: 'string',
            description: 'The error status from the server',
            required: true,
          },
          error: {
            type: 'string',
            description: 'The error message from the server',
            required: true,
          },
          products: {
            type: 'array',
            description: 'A list of product details from Strpe including the name, price, product_id, quantity and variant',
          },
        },
      },
    },
    Trail: {
      COMPLETE_TASK_EARLY_CONFIRMED: {
        enabled: true,
        name: 'Site - Early Task completion confirmed',
        description: 'Recorded when a task is completed early and the warning modal is confirmed',
      },
      COMPLETE_TASK_EARLY_CANCELLED: {
        enabled: true,
        name: 'Site - Early Task completion cancelled',
        description: 'Recorded when a task is completed early and the warning modal is cancelled',
      },
      TASK_COMPLETED: {
        enabled: true,
        name: 'Site - Task completed',
        description: 'Recorded when a task is marked as done for the first time',
        properties: {
          taskName: {
            description: 'Display name of the task',
            type: 'string',
            required: true,
          },
          totalTasks: {
            description: 'Total number of tasks on the Trail on which this task was completed',
            type: 'integer',
          },
          taskStatus: {
            description: 'Status of the task prior to completion',
            type: 'string',
            required: true,
          },
          taskStatusNumber: {
            description: 'Numerical representation of the status',
            type: 'integer',
          },
          taskLatenessDifference: {
            description: 'Delta (in minutes) between due-by and completed-at times',
            type: 'number',
          },
          index_in_timeslot: {
            description: 'Position of the task among its siblings',
            type: 'integer',
          },
          templateType: {
            description: 'The template type of the task',
            type: 'string',
          },
          category: {
            type: 'string',
            value: 'Tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.taskStatus',
          },
        },
      },
      TASK_ADDED: {
        enabled: true,
        name: 'Site - On Demand Task Added',
        description: 'On demand task added to the Trail',
        properties: {
          taskTemplateId: {
            description: 'ID of the task template added',
            type: 'integer',
            required: true,
          },
          locationId: {
            description: 'ID of the location added to',
            type: 'integer',
            required: true,
          },
        },
      },
      AD_HOC_TASK_PICKER: {
        enabled: true,
        name: 'Ad Hoc Task Picker - Search Performed',
        description: 'This event fires whenever a user types in the Ad Hoc Task search bar',
      },
      ACTION_TASK_CREATED: {
        enabled: true,
        name: 'Trail - Task Action Created',
        description: 'Action created for task on the trail',
        properties: {
          userRole: {
            description: 'role of the user who added the action',
            type: 'string',
            required: true,
          },
          locationId: {
            description: 'ID of the location action was added to',
            type: 'integer',
            required: true,
          },
        },
      },
      ACTION_TASK_UPDATED: {
        enabled: true,
        name: 'Trail - Task Action Updated',
        description: 'Action updated for task on the trail',
        properties: {
          userRole: {
            description: 'Role of the user who added the action',
            type: 'string',
            required: true,
          },
          locationId: {
            description: 'ID of the location action was added to',
            type: 'integer',
            required: true,
          },
        },
      },
      ACTION_REMOVAL_CONFIRMATION: {
        enabled: true,
        name: 'Trail - Action Removal confirmation requested',
        description: 'Record log warned user that an action will be removed',
        properties: {
          removalType: {
            description: 'Cause of action removal (field change or row deletion)',
            type: 'string',
            required: true,
          },
          actionRemoved: {
            description: 'Whether the user confirmed to remove the action',
            type: 'boolean',
            required: true,
          },
        },
      },
      TRAIL_REFRESHED: {
        enabled: false,
        name: 'Trail refreshed',
        description: 'Recorded when a user is offline for an extended period, and upon reconnection the Trail is refreshed',
        properties: {
          triggeredTime: {
            description: 'Time the event occurred in ISO 8601 format',
            type: 'string',
            required: true,
          },
        },
      },
      TRAIL_API_DURATION: {
        enabled: true,
        name: 'Trail API Duration',
        description: 'Record how long calls to the /trail API take',
        properties: {
          duration: {
            description: 'The duration of the API call in MS',
            type: 'integer',
            required: true,
          },
        },
      },
      REMINDER_DISMISSED: {
        enabled: true,
        name: 'Prototype Timer - Reminder dismissed',
        description: 'Recorded when a reminder is dismissed',
        properties: {
          button: {
            description: 'Which button was pressed',
            type: 'string',
          },
          reminderTime: {
            description: 'The time the reminder was meant to occur',
            type: 'string',
          },
          endTime: {
            description: 'The time the timer was meant to end by',
            type: 'string',
          },
          clickedTime: {
            description: 'The time the dismiss button was clicked',
            type: 'string',
          },
          rowIndex: {
            description: 'The row index of the widget',
            type: 'integer',
          },
          timerIndex: {
            description: 'The timer index, which reminder was seen and dismissed',
            type: 'integer',
          },
          widgetId: {
            description: 'The widget id',
            type: 'integer',
          },
          taskId: {
            description: 'The task id',
            type: 'integer',
          },
        },
      },
      COMMENT_DELETED: {
        enabled: true,
        name: 'Site - Comment Deleted',
        description: 'Recorded when a comment is deleted',
        properties: {
          taskId: {
            description: 'The ID of the Task Instance the comment was deleted from',
            type: 'integer',
          },
        },
      },
      CONVERSATION_OPENED: {
        enabled: true,
        name: 'Site - Conversation Button Clicked',
        description: 'Conversation Overlay opened via a button on a Task',
        properties: {
          dayDifference: {
            description: 'Difference between today and day on which it was clicked (0 = Today, 1 = Tomorrow, -1 = Yesterday, and so on)',
            type: 'integer',
          },
          interactionType: {
            description: 'Which Conversation CTA was used (`header` or `body`)',
            type: 'string',
          },
          taskId: {
            description: 'The ID of the Task Instance the Conversation was opened for',
            type: 'integer',
          },
        },
      },
      TASK_SNOOZED: {
        enabled: true,
        name: 'Site - Task Snoozed',
        description: 'Recorded when Task is snoozed on the Trail',
        properties: {
          taskId: {
            type: 'string',
            required: true,
          },
          taskType: {
            description: 'Identifies whether task type is `on_demand` or `recurring`',
            type: 'string',
            required: true,
          },
          interactionType: {
            description: 'Identifies whether snooze was clicked in `Task Header` or `Task Footer`',
            type: 'string',
          },
          category: {
            type: 'string',
            value: 'Tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.taskType',
          },
        },
      },
      SUBTASK_VALUE_CHANGED: {
        enabled: false,
        name: 'Site - Subtask value changed',
        description: 'Record when a Subtask value has changed',
        properties: {
          value: {
            description: 'Identifies what the value has been changed too, e.g. \'done\' or \'unchecked\'',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      FLAG_SUBTASK_ICON_CLICKED: {
        enabled: false,
        name: 'Site - Subtask flag icon clicked',
        description: 'Shows how many interactions with the subtask flag icon we receive',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SUBTASK_INPUT_SELECTED: {
        enabled: true,
        name: 'Site - Subtask input selected',
        description: 'Monitors when the add subtask item input is selected',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SUBTASK_ADDED: {
        enabled: true,
        name: 'Site - Subtask added',
        description: 'Monitors when the add subtask item is added to the checklist',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SUBTASK_DELETED_CONFIRMED: {
        enabled: true,
        name: 'Site - Subtask deleted confirmed',
        description: 'Monitors when a user confirms the deletion of a subtask item',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SUBTASK_DELETED_CANCELED: {
        enabled: true,
        name: 'Site - Subtask deleted cancelled',
        description: 'Monitors when a user cancels the deletion of a subtask item',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SUBTASK_MENU_SELECTED: {
        enabled: true,
        name: 'Site - Subtask menu selected',
        description: 'Monitors when a user opens the subtask menu',
        properties: {
          category: {
            type: 'string',
            value: 'Subtask',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SEARCHED_TASKS: {
        enabled: true,
        name: 'Site - Searched Tasks',
        description: 'User clicked the search tasks button',
      },
      FILTER_ASSIGNED_TO_ME_TASKS: {
        enabled: true,
        name: 'Clicked Assigned to me',
        description: 'User clicked the Assigned to me button',
      },
      TASK_CONTENT_COMMENT_SENT: {
        enabled: true,
        name: 'Trail - Task Content Comment Sent',
        description: 'Record when a task content comment is sent',
        properties: {
          commentableType: {
            description: 'Type of object the comment has been made on',
            type: 'string',
            required: true,
          },
          locationId: {
            description: 'ID of the location the comment was sent on',
            type: 'integer',
          },
        },
      },
    },
    TaskReports: {
      VIEW_CONTENT_APPLIED: {
        enabled: true,
        name: 'Task Reports - View Content Applied',
        description: 'Recorded when a view content is selected on the Task Reports page',
        properties: {
          newViews: {
            description: 'Names of the views applied, as an alphabetised comma separated list',
            type: 'string',
            required: true,
          },
          allViews: {
            description: 'All currently applied views, as an alphabetised comma separated list',
            type: 'string',
            required: true,
          },
        },
      },
      EXCEPTION_FILTER_APPLIED: {
        enabled: true,
        name: 'Task Reports - Exception Filter Applied',
        description: 'Recorded when an exception filter is applied on the Task Reports page',
        properties: {
          comment: {
            description: 'If the comment exception filter is applied',
            type: 'boolean',
            required: true,
          },
          flagged_subtask: {
            description: 'If the flagged subtask exception filter is applied',
            type: 'boolean',
            required: true,
          },
          new_subtask: {
            description: 'If the new subtask exception filter is applied',
            type: 'boolean',
            required: true,
          },
          reopened: {
            description: 'If the reopened task exception filter is applied',
            type: 'boolean',
            required: true,
          },
          widget: {
            description: 'If the widget data exception filter is applied',
            type: 'boolean',
            required: true,
          },
        },
      },
      GROUP_FILTER_APPLIED: {
        enabled: true,
        name: 'Task Reports - Group Filter Applied',
        properties: {
          isSelected: {
            description: 'If a group has been checked or unchecked',
            type: 'boolean',
            required: true,
          },
          id: {
            description: 'The group item id',
            type: 'string',
            required: true,
          },
          groupId: {
            description: 'The group id',
            type: 'string',
            required: true,
          },
          filterType: {
            description: 'The filter type',
            type: 'string',
            required: true,
          },
        },
      },
      FILTER_APPLIED: {
        enabled: true,
        name: 'Task Reports - Filter Applied',
        description: 'Recorded when a filter is applied on the Task Reports page',
        properties: {
          filterName: {
            description: 'Name of the filter applied',
            type: 'string',
            required: true,
          },
          filterValue: {
            description: 'Value of the filter applied, normalised for certain values',
            type: 'string',
            required: true,
          },
          currentFilters: {
            description: 'All currently applied filters, as an alphabetised comma separated list',
            type: 'string',
            required: true,
          },
        },
      },
      SHARE_LINK_COPIED: {
        enabled: true,
        name: 'Task Reports - Share Link Copied',
        description: 'Recorded when the Task Reports share link is copied',
      },
      SHORTCUT_CLICKED: {
        enabled: true,
        name: 'Task Reports - Shortcut Clicked',
        description: 'Recorded when a filter shortcut is clicked on the Task Reports page',
        properties: {
          shortcut: {
            description: 'Name of the shortcut clicked',
            type: 'string',
            required: true,
          },
        },
      },
      PDF_PREVIEWED: {
        enabled: true,
        name: 'Task Reports - PDF Previewed',
        description: 'Recorded when a PDF export is previewed from the Task Reports page',
        properties: {
          numberOfTasks: {
            description: 'Number of tasks selected',
            type: 'number',
            required: true,
          },
          bulkGroupSelected: {
            description: 'Whether a bulk group has been selected',
            type: 'boolean',
            required: true,
          },
        },
      },
      CSV_DOWNLOAD_TRIGGERED: {
        enabled: true,
        name: 'Task Reports - CSV Download Triggered',
        description: 'Recorded when a CSV export download is triggered from the Task Reports page',
      },
      CSV_EXCEPTIONS_DOWNLOAD_TRIGGERED: {
        enabled: true,
        name: 'Task Reports - CSV Exceptions Download Triggered',
        description: 'Recorded when a CSV Exceptions export download is triggered from the Task Reports page',
      },
      LOGIC_ADDED: {
        enabled: true,
        name: 'Task Reports - Logic added to record log',
        description: 'Recorded when logic is added to a record log',
      },
    },
    Scores: {
      SCORE_COMPARISON_TOOLTIP: {
        enabled: true,
        name: 'Scores - Score Comparison ToolTip',
        description: 'Recorded when the score comparison tooltip is hovered or clicked',
      },
      SHARE_LINK_COPIED: {
        enabled: true,
        name: 'Scores - Share Link Copied',
        description: 'Recorded when the Scores share link is copied',
      },
      CSV_DOWNLOAD_TRIGGERED: {
        enabled: true,
        name: 'Scores - CSV Download Triggered',
        description: 'Recorded when a CSV export download is triggered from the Scores page',
      },
      GROUP_FILTER_APPLIED: {
        enabled: true,
        name: 'Scores - Group Filter Applied',
        properties: {
          isSelected: {
            description: 'If a group has been checked or unchecked',
            type: 'boolean',
            required: true,
          },
          id: {
            description: 'The group item id',
            type: 'string',
            required: true,
          },
          groupId: {
            description: 'The group id',
            type: 'string',
            required: true,
          },
          filterType: {
            description: 'The filter type',
            type: 'string',
            required: true,
          },
        },
      },
      FILTER_APPLIED: {
        enabled: true,
        name: 'Scores - Filter Applied',
        description: 'Recorded when a filter is applied on the Task Reports page',
        properties: {
          filterName: {
            description: 'Name of the filter applied',
            type: 'string',
            required: true,
          },
          filterValue: {
            description: 'Value of the filter applied, normalised for certain values',
            type: 'string',
            required: true,
          },
          currentFilters: {
            description: 'All currently applied filters, as an alphabetised comma separated list',
            type: 'string',
            required: true,
          },
        },
      },
    },
    TaskWizard: {
      TASK_CREATED: {
        enabled: true,
        name: 'Wizard - Task Created',
        description: 'Recorded when a task is created on the task (wizard) page',
        properties: {
          name: {
            description: 'Display name of the task',
            type: 'string',
            required: true,
          },
          type: {
            description: 'Identifies whether a task is on_demand, recurring or single',
            type: 'string',
            required: true,
          },
        },
      },
      TASK_EDITED: {
        enabled: true,
        name: 'Wizard - Task Edited',
        description: 'Recorded when a task is edited on the task (wizard) page',
        properties: {
          name: {
            description: 'Display name of the task',
            type: 'string',
            required: true,
          },
          type: {
            description: 'Identifies whether a task is on_demand, recurring or single',
            type: 'string',
            required: true,
          },
        },
      },
      SCHEDULE_CREATED: {
        enabled: true,
        name: 'Wizard - Schedule Created',
        description: 'Recorded based on the recurrence values of a schedule on the task (wizard) page',
        properties: {
          type: {
            description: 'Identifies whether a schedule is ongoing or insert term here',
            type: 'string',
            required: true,
          },
        },
      },
      SCHEDULE_EDITED: {
        enabled: true,
        name: 'Wizard - Schedule Edited',
        description: 'Recorded based on the recurrence values of a schedule on the task (wizard) page',
        properties: {
          type: {
            description: 'Identifies whether a schedule is ongoing or insert term here',
            type: 'string',
            required: true,
          },
        },
      },
      MULTIPLE_SCHEDULES_ADDED: {
        enabled: true,
        name: 'Wizard - Multiple Schedules Added',
        description: 'Recorded multiple schedules are added to a task on the Task Wizard',
        properties: {
          editing: {
            description: 'Whether the task is being edited or created',
            type: 'boolean',
            required: true,
          },
          scheduleCount: {
            description: 'How many schedules the task has after creation/editing',
            type: 'number',
            required: true,
          },
          originalScheduleCount: {
            description: 'How many schedules the task had before creation/editing',
            type: 'number',
            required: true,
          },
        },
      },
      MULTIPLE_SCHEDULES_REMOVED: {
        enabled: true,
        name: 'Wizard - Multiple Schedules Removed',
        description: 'Recorded multiple schedules are removed from a task on the Task Wizard',
        properties: {
          editing: {
            description: 'Whether the task is being edited or created',
            type: 'boolean',
            required: true,
          },
          scheduleCount: {
            description: 'How many schedules the task has after creation/editing',
            type: 'number',
            required: true,
          },
          originalScheduleCount: {
            description: 'How many schedules the task had before creation/editing',
            type: 'number',
            required: true,
          },
        },
      },
      TAG_CREATED: {
        enabled: true,
        name: 'Wizard - Tag Created',
        description: 'A new tag has been created',
        properties: {
          name: {
            description: 'Name of the created tag',
            type: 'string',
            required: true,
          },
        },
      },
      ASSIGN_TO_TRAIL_CONFIRMED: {
        enabled: true,
        name: 'Wizard - Assign To Trail Confirmed',
        description: 'Site(s) have been assigned to a task template',
        properties: {
          category: {
            type: 'string',
            value: 'Task Wizard',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      CHECKLIST_SINGLE_LINE_PASTE: {
        enabled: true,
        name: 'Wizard - Checklist Single Line Pasted',
        description: 'A single line has been pasted into a checklist item',
        properties: {
          taskName: {
            description: 'Display name of the task',
            type: 'string',
            required: true,
          },
          templateType: {
            description: 'Identifies whether a task is on_demand, recurring or single',
            type: 'string',
            required: true,
          },
        },
      },
      CHECKLIST_MULTI_LINE_PASTE: {
        enabled: true,
        name: 'Wizard - Checklist Multi Line Pasted',
        description: 'Multiple lines have been pasted into a checklist item',
        properties: {
          taskName: {
            description: 'Display name of the task',
            type: 'string',
            required: true,
          },
          templateType: {
            description: 'Identifies whether a task is on_demand, recurring or single',
            type: 'string',
            required: true,
          },
        },
      },
      ADD_WIDGET: {
        single: {
          enabled: true,
          name: 'wizard - record log form',
          description: 'Form - a new form has been created',
        },
        multi: {
          enabled: true,
          name: 'wizard - record log table',
          description: 'Table - a new table has been created',
        },
        reuse: {
          enabled: true,
          name: 'wizard - record log reuse',
          description: 'Reuse - a record log has been reused',
        },
      },
      UPSELL_SECTION_EXPANDED: {
        enabled: true,
        name: 'Wizard - Upsell section expanded',
        description: 'Recorded when an empty section with Upsell is expanded',
        properties: {
          plan: {
            description: 'Account plan',
            type: 'string',
            required: true,
          },
        },
      },
    },
    TaskPlanner: {
      LIBRARY_TEMPLATE_SELECTED: {
        enabled: true,
        name: 'Planner - Library template selected',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateName',
          },
          taskTemplateId: {
            description: 'Id of the task template',
            type: 'integer',
            required: true,
          },
          taskTemplateName: {
            description: 'Name of the task template',
            type: 'string',
            required: true,
          },
        },
      },
      LIBRARY_TEMPLATE_COPIED: {
        enabled: true,
        name: 'Planner - Library template copied',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
          taskTemplateId: {
            description: 'Id of the task template',
            type: 'integer',
            required: true,
          },
        },
      },
      LIBRARY_TEMPLATE_CANCELED: {
        enabled: true,
        name: 'Planner - Library template cancelled',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
          taskTemplateId: {
            description: 'Id of the task template',
            type: 'integer',
            required: true,
          },
        },
      },
      GROUP_FILTER_SELECTED: {
        enabled: true,
        name: 'Planner - Group Filter Selected',
        description: '',
        properties: {
          groupId: {
            description: 'The group that has been selected',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.groupId',
          },
        },
      },
      GROUP_FILTER_DESELECTED: {
        enabled: true,
        name: 'Planner - Group Filter Deselected',
        description: '',
        properties: {
          groupId: {
            description: 'The group that has been deselected',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.groupId',
          },
        },
      },
      GROUP_FILTERS_CLEARED: {
        enabled: true,
        name: 'Planner - Group Filters Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      GROUP_FILTER_TOOLTIP_VIEWED: {
        enabled: true,
        name: 'Planner - Group Filter Tooltip Viewed',
        description: 'When the user clicks or hovers the tooltip in group filters',
        properties: {
          interactionType: {
            description: 'Type of user interaction. `hover` or `click`',
            type: 'string',
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.interactionType',
          },
        },
      },
      TRAIL_FILTER_SELECTED: {
        enabled: true,
        name: 'Planner - Filter Selected',
        description: '',
        properties: {
          trailId: {
            description: 'The trail that has been selected',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.trailId',
          },
        },
      },
      TRAIL_FILTER_DESELECTED: {
        enabled: true,
        name: 'Planner - Filter Deselected',
        description: '',
        properties: {
          trailId: {
            description: 'The trail that has been deselected',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.trailId',
          },
        },
      },
      TRAIL_FILTERS_CLEARED: {
        enabled: true,
        name: 'Planner - Filters Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TRAIL_FILTER_LINK_OPENED: {
        enabled: true,
        name: 'Planner - Filter Link Opened',
        description: '',
        properties: {
          trailId: {
            description: 'The trail that has been opened',
            type: 'integer',
            required: true,
          },
          trailFilterSelected: {
            description: 'True if the opened trail was selected',
            type: 'boolean',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.trailId',
          },
        },
      },
      TAG_FILTER_SELECTED: {
        enabled: true,
        name: 'Planner - Tag Filter Selected',
        description: '',
        properties: {
          tagId: {
            description: 'The tag that has been selected',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.tagId',
          },
        },
      },
      TAG_FILTER_DESELECTED: {
        enabled: true,
        name: 'Planner - Tag Filter Deselected',
        description: '',
        properties: {
          tagId: {
            description: 'The tag that has been deselected',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.tagId',
          },
        },
      },
      TAG_FILTERS_CLEARED: {
        enabled: true,
        name: 'Planner - Tag Filters Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TAG_FILTER_TOOLTIP_VIEWED: {
        enabled: true,
        name: 'Planner - Tag Filter Tooltip Viewed',
        description: 'When the user clicks or hovers the tooltip in tag filters',
        properties: {
          interactionType: {
            description: 'Type of user interaction. `hover` or `click`',
            type: 'string',
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.interactionType',
          },
        },
      },
      SEARCH_PERFORMED: {
        enabled: true,
        name: 'Planner - Search Performed',
        description: 'When the user types something in the search filter and exits the search box',
        properties: {
          searchTerm: {
            description: 'The search term entered',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.searchTerm',
          },
        },
      },
      SEARCH_QUERY_CLEARED: {
        enabled: true,
        name: 'Planner - Search Query Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      SELECT_ALL_TASK_TEMPLATES_CLICKED: {
        enabled: true,
        name: 'Planner - Select All Task Templates Clicked',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      CLEAR_SELECTED_TASK_TEMPLATES_CLICKED: {
        enabled: true,
        name: 'Planner - Clear Selected Task Templates Clicked',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      ASSIGN_TO_TRAIL_LOCATION_SELECTED: {
        enabled: true,
        name: 'Planner - Assign To Trail Location Selected',
        description: '',
        properties: {
          trailId: {
            description: 'The trail we are trying to assign task templates to',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.trailId',
          },
        },
      },
      ASSIGN_TO_TRAIL_CONFIRMED: {
        enabled: true,
        name: 'Planner - Assign To Trail Confirmed',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      ASSIGN_TO_TRAIL_CANCELED: {
        enabled: true,
        name: 'Planner - Assign To Trail Cancelled',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      UNASSIGN_FROM_TRAIL_CONFIRMED: {
        enabled: true,
        name: 'Planner - Unassign From Trail Confirmed',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      UNASSIGN_FROM_TRAIL_CANCELED: {
        enabled: true,
        name: 'Planner - Unassign From Trail Cancelled',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TASK_TAG_CLICKED: {
        enabled: true,
        name: 'Planner - Tag Click',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TASK_CHECKBOX_SELECTED: {
        enabled: true,
        name: 'Planner - Task Checkbox Selected',
        description: '',
        properties: {
          taskTemplateId: {
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
        },
      },
      TASK_CHECKBOX_DESELECTED: {
        enabled: true,
        name: 'Planner - Task Checkbox Deselected',
        description: '',
        properties: {
          taskTemplateId: {
            description: 'The affected task template',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
        },
      },
      TASK_PREVIEW_EXPANDED: {
        enabled: true,
        name: 'Planner - Preview Expanded',
        description: '',
        properties: {
          taskTemplateId: {
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
        },
      },
      TASK_PREVIEW_COLLAPSED: {
        enabled: true,
        name: 'Planner - Preview Collapsed',
        description: '',
        properties: {
          taskTemplateId: {
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.taskTemplateId',
          },
        },
      },
      PREVIEW_ALL_CLICKED: {
        enabled: true,
        name: 'Planner - Preview All Clicked',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      COLLAPSE_ALL_CLICKED: {
        enabled: true,
        name: 'Planner - Collapse All Clicked',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Task Planner',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
    },
    SearchTasks: {
      TAG_FILTER_SELECTED: {
        enabled: true,
        name: 'Planner - Tag Filter Selected',
        description: '',
        properties: {
          tagId: {
            description: 'The tag that has been selected',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.tagId',
          },
        },
      },
      TAG_FILTER_DESELECTED: {
        enabled: true,
        name: 'Planner - Tag Filter Deselected',
        description: '',
        properties: {
          tagId: {
            description: 'The tag that has been deselected',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.tagId',
          },
        },
      },
      TAG_FILTERS_CLEARED: {
        enabled: true,
        name: 'Planner - Tag Filters Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TAG_FILTER_TOOLTIP_VIEWED: {
        enabled: true,
        name: 'Planner - Tag Filter Tooltip Viewed',
        description: 'When the user clicks or hovers the tooltip in tag filters',
        properties: {
          interactionType: {
            description: 'Type of user interaction. `hover` or `click`',
            type: 'string',
          },
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.interactionType',
          },
        },
      },
      SEARCH_PERFORMED: {
        enabled: true,
        name: 'Planner - Search Performed',
        description: 'When the user types something in the search filter and exits the search box',
        properties: {
          searchTerm: {
            description: 'The search term entered',
            type: 'string',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'string',
            path: 'properties.searchTerm',
          },
        },
      },
      SEARCH_QUERY_CLEARED: {
        enabled: true,
        name: 'Planner - Search Query Cleared',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
      TASK_TAG_CLICKED: {
        enabled: true,
        name: 'Planner - Tag Click',
        description: '',
        properties: {
          category: {
            type: 'string',
            value: 'Search tasks',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
    },
    Conversation: {
      COMMENT_SENT: {
        enabled: true,
        name: 'Conversation - Comment Sent',
        description: 'Comment has been Sent on the Conversation Overlay',
        properties: {
          taskId: {
            description: 'The ID of the Task Instance the Comment was sent for.',
            type: 'integer',
          },
          attachmentType: {
            description: 'MIME Type of the Attachment on the Comment. (none) means no Attachment was added.',
            type: 'string',
          },
        },
      },
    },
    Areas: {
      AREA_CREATED: {
        enabled: true,
        name: 'Areas - Area Created',
        description: 'A new area has been created',
        properties: {
          createdAreaId: {
            description: 'The ID of the area created.',
            type: 'integer',
            required: true,
          },
          category: {
            type: 'string',
            value: 'Areas',
          },
        },
      },
    },
    Sites: {
      SITE_CREATED: {
        enabled: true,
        name: 'Sites - Site Created',
        description: 'A new site has been created',
        properties: {
          createdLocationId: {
            description: 'The ID of the site created.',
            type: 'integer',
            required: true,
          },
          areaId: {
            description: 'The ID of the area of the site created.',
            type: 'integer',
          },
          category: {
            type: 'string',
            value: 'Sites',
          },
          action: {
            type: 'string',
            path: 'name',
          },
          label: {
            type: 'integer',
            path: 'properties.createdLocationId',
          },
        },
      },
      SITE_LAUNCH_DATE_CHANGED: {
        enabled: true,
        name: 'Sites - Site Launch Date Changed',
        description: 'A site\'s launch date has changed',
        properties: {
          locationId: {
            description: 'The ID of the site which had its launch date changed',
            type: 'integer',
            required: true,
          },
          locationName: {
            description: 'The name of the site which had its launch date changed',
            type: 'string',
            required: true,
          },
          locationLaunchDate: {
            description: 'The new launch date of the site',
            type: 'string',
            required: true,
          },
        },
      },
      SITE_DEACTIVATED: {
        enabled: true,
        name: 'Sites - Site Deactivated',
        description: 'A site has been deactivated',
        properties: {
          locationId: {
            description: 'The ID of the site which has been deactivated',
            type: 'integer',
            required: true,
          },
          locationName: {
            description: 'The name of the site which has been deactivated',
            type: 'string',
            required: true,
          },
        },
      },
      SITE_ARCHIVED: {
        enabled: true,
        name: 'Sites - Site Archived',
        description: 'A site has been archived',
        properties: {
          locationId: {
            description: 'The ID of the site which has been archived',
            type: 'integer',
            required: true,
          },
          locationName: {
            description: 'The name of the site which has been archived',
            type: 'string',
            required: true,
          },
        },
      },
      SITE_UNARCHIVED: {
        enabled: true,
        name: 'Sites - Site Unarchived',
        description: 'A site has been unarchived',
        properties: {
          locationId: {
            description: 'The ID of the site which has been unarchived',
            type: 'integer',
            required: true,
          },
          locationName: {
            description: 'The name of the site which has been unarchived',
            type: 'string',
            required: true,
          },
        },
      },
      SITE_DELETED: {
        enabled: true,
        name: 'Sites - Site Deleted',
        description: 'A site has been deleted',
        properties: {
          locationId: {
            description: 'The ID of the site which has been deleted',
            type: 'integer',
            required: true,
          },
          locationName: {
            description: 'The name of the site which has been deleted',
            type: 'string',
            required: true,
          },
        },
      },
      SITE_TIMESLOT_CHANGED: {
        enabled: true,
        name: 'Sites - Timeslot changed',
        description: 'A site\'s timeslots has been changed',
      },
    },
    Billing: {
      SUBSCRIPTION_ACTIVATED: {
        enabled: true,
        name: 'Billing - Subscription Activated',
        description: 'A new subscription has been activated',
      },
      PLAN_UPGRADED: {
        enabled: true,
        name: 'Billing - Subscription Plan Upgraded',
        description: 'A subscription has upgraded its plan',
      },
    },
    Users: {
      NEW_USER: {
        enabled: true,
        name: 'Users - New User',
        description: 'A new user has been created',
        properties: {
          createdUserId: {
            description: 'The ID of the user created.',
            type: 'string',
            required: true,
          },
          createdUserTimelineTemplateId: {
            description: 'The ID of the timeline template the user is assigned to.',
            type: 'integer',
          },
          role: {
            type: 'string',
            required: true,
            description: 'The role of the user created',
          },
          invited: {
            type: 'boolean',
            required: true,
            description: 'If the user was invited',
          },
          category: {
            type: 'string',
            value: 'Users',
          },
          action: {
            type: 'string',
            path: 'name',
          },
        },
      },
    },
    Organization: {
      ORGANIZATION_TIMESLOT_CHANGED: {
        enabled: true,
        name: 'Organization - Timeslot changed',
        description: 'A user has changed a timeslot',
      },
    },
    NavigationBar: {
      PRODUCT_UPDATES_VIEWED: {
        enabled: true,
        name: 'Navigation Bar - Product updates viewed',
        description: 'When the user views the product updates from the help menu.',
      },
      CLICK_CHOOSE_PLAN: {
        enabled: true,
        name: 'Navigation Bar - Choose Plan',
        description: 'When the user clicks Choose Plan button.',
      },
    },
    ReportTabs: {
      CLICK_SITE_SCORES_TAB: {
        enabled: true,
        name: 'Reports - Visit Scores',
        description: 'When the user clicks on the Scores tab',
      },
      CLICK_TASK_REPORTS_TAB: {
        enabled: true,
        name: 'Reports - Visit Task Reports',
        description: 'When the user clicks on the Task Reports tab',
      },
      CLICK_ACTIVITY_TAB: {
        enabled: true,
        name: 'Reports - Visit Activity',
        description: 'When the user clicks on the Activity tab',
      },
    },
    SetupGuideButtonSteps: {
      CLICK_NEXT_CONFIGURE_TASKS: {
        enabled: true,
        name: 'Guide Button - Configure tasks',
        description: 'When the user clicks on the Next: Configure tasks',
      },
      CLICK_NEXT_BUSINESS_HOURS: {
        enabled: true,
        name: 'Guide Button - Business hours',
        description: 'When the user clicks on the Next: Business hours',
      },
      CLICK_NEXT_INVITE_USERS: {
        enabled: true,
        name: 'Guide Button - Invite users',
        description: 'When the user clicks on the Next: Invite users',
      },
      CLICK_NEXT_GO_LIVE: {
        enabled: true,
        name: 'Guide Button - Go live',
        description: 'When the user clicks on the Next: Go live',
      },
      CLICK_NEXT_GO_TO_BILLING: {
        enabled: true,
        name: 'Guide Button - Go to billing',
        description: 'When the user clicks on the Next: Go to billing',
      },
    },
    SetupGuideTrailCountdown: {
      CLICK_ACTIVATE_NOW: {
        enabled: true,
        name: 'Setup Guide Activate Now Clicked',
        description: 'When the user clicks on the Activate now button',
      },
    },
  },
};
