import { WindowHistory } from '../../utils/window';
import { unsavedChangesSelector } from '../selectors';
import { cleared } from '../actions';

export const confirmMessage = 'You have unsaved changes, are you sure you want to leave?';
let previousState = {};

export default (store) => {
  const unsavedChangesExist = () => unsavedChangesSelector(store.getState());
  const shouldAllowSoftReroute = (state, action) => {
    const currentState = state || {};
    const goingBackFromPageWithAllowSoftReroute = (
      action === 'POP' && previousState.allowSoftRerouteWithUnsavedChanges);

    return (
      currentState.allowSoftRerouteWithUnsavedChanges
      || goingBackFromPageWithAllowSoftReroute
      || false
    );
  };

  WindowHistory.block((location, action) => {
    const allowSoftRerouteWithUnsavedChanges = shouldAllowSoftReroute(location.state, action);
    previousState = location.state || {};

    if (unsavedChangesExist() && !allowSoftRerouteWithUnsavedChanges) return confirmMessage;
    return null;
  });

  WindowHistory.listen((location, action) => {
    const allowSoftRerouteWithUnsavedChanges = shouldAllowSoftReroute(location.state, action);
    if (allowSoftRerouteWithUnsavedChanges) return;

    if (!unsavedChangesExist()) return;

    store.dispatch(cleared());
  });

  window.addEventListener('beforeunload', (event = window.event) => {
    if (!unsavedChangesExist()) return undefined;

    event.preventDefault();
    event.returnValue = confirmMessage;
    return confirmMessage;
  });

  return next => action => next(action);
};
