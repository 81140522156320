import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import SelectableMenuItemButton from '../../common/menu/SelectableMenuItemButton';

const daysAgo = days => moment().startOf('day').subtract(days, 'days');

const DateFilterMenu = ({ setQueryDates, startDate, endDate }) => {
  const today = moment().startOf('day');
  const yesterday = daysAgo(1);
  const thirtyDaysAgo = daysAgo(30);
  const ninetyDaysAgo = daysAgo(90);
  const setToday = () => setQueryDates(today, today);
  const setYesterday = () => setQueryDates(yesterday, yesterday);
  const setLast30Days = () => setQueryDates(thirtyDaysAgo, today);
  const setLast90Days = () => setQueryDates(ninetyDaysAgo, today);

  const isSameDateRange = (comparedStartDate, comparedEndDate) => (
    startDate.isSame(comparedStartDate, 'day') && endDate.isSame(comparedEndDate, 'day')
  );

  return (
    <div className='showtime-menu__list showtime-menu__list--no-border'>
      <SelectableMenuItemButton
        onClick={ setToday }
        intlPath='taskReports.dateFilterLabel.today'
        selected={ isSameDateRange(today, today) }
        dataTest='taskReports.dateFilter.today'
      />
      <SelectableMenuItemButton
        onClick={ setYesterday }
        intlPath='taskReports.dateFilterLabel.yesterday'
        selected={ isSameDateRange(yesterday, yesterday) }
        dataTest='taskReports.dateFilter.yesterday'
      />
      <SelectableMenuItemButton
        onClick={ setLast30Days }
        intlPath='taskReports.dateFilterLabel.lastThirtydays'
        selected={ isSameDateRange(thirtyDaysAgo, today) }
        dataTest='taskReports.dateFilter.last30Days'
      />
      <SelectableMenuItemButton
        onClick={ setLast90Days }
        intlPath='taskReports.dateFilterLabel.lastNinetyDays'
        selected={ isSameDateRange(ninetyDaysAgo, today) }
        dataTest='taskReports.dateFilter.last90Days'
      />
    </div>
  );
};

DateFilterMenu.propTypes = {
  setQueryDates: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
};

export default DateFilterMenu;
