import { createSelector } from 'reselect';
import {
  descriptionSelector,
  titleSelector,
  TaskScheduleSelectors,
  tagsSelector,
} from '../../task-wizard/selectors';
import { AttachmentConstants } from '../../attachments/constants';
import { isLoading, hasError } from '../../common/requestStatuses';
import { unsavedChangesSelector } from '../../unsaved-changes/selectors';
import { TaskTemplateConstants } from '../../task/constants';

const {
  State: { SAVED },
} = AttachmentConstants;

export const attachmentsSelector = ({ taskActions: { attachments = [] } = {} }) => attachments;

export const canSaveTaskActionSelector = createSelector(
  titleSelector,
  attachmentsSelector,
  unsavedChangesSelector,
  (title, attachments, hasUnsavedChanges) => {
    const hasAttachments = attachments.every(({ status }) => status === SAVED);
    return !!title && hasAttachments && hasUnsavedChanges;
  }
);

export const filesForTaskActionBodySelector = createSelector(
  attachmentsSelector,
  attachments => attachments.reduce((accu, attachment) => {
    if (attachment.status !== SAVED) return accu;
    const { metadata } = attachment;
    return [
      ...accu,
      {
        name: metadata.name,
        file_name: metadata.fileName,
        filestack_handle: metadata.filestackHandle,
        mime_type: metadata.mimeType,
        file_size: metadata.fileSize,
        s3_key: metadata.s3Key,
        type: metadata.type,
      },
    ];
  }, [])
);

export const actionRequestBodySelector = createSelector(
  TaskScheduleSelectors.scheduleFieldsForRequestSelector,
  titleSelector,
  descriptionSelector,
  tagsSelector,
  filesForTaskActionBodySelector,
  (scheduleFieldMappings, name, notes, tags, files) => ({
    ...scheduleFieldMappings,
    name,
    notes,
    files,
    tags,
    template_type: TaskTemplateConstants.templateType.ACTION,
  })
);

export const isValidActionTemplateSelector = (state) => {
  const { taskActions: { actionTemplateIsValid } } = state;
  return actionTemplateIsValid === true;
};

export const isModalLoadingSelector = (state) => {
  const { timeslots: { defaultTimeslotsLoaded }, taskActions: { modalTaskLoadingStatus } } = state;
  return isLoading(modalTaskLoadingStatus) || !defaultTimeslotsLoaded || false;
};

export const hasModalTaskLoadingErrorSelector = (state) => {
  const { taskActions: { modalTaskLoadingStatus } } = state;
  return hasError(modalTaskLoadingStatus);
};
