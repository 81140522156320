import _ from 'lodash';
import moment from 'moment-timezone';
import { buildQueryString } from '../common/api/queryBuilder';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import {
  ALL, DATE_QUERY_SEPARATOR, filterQueryKeys, datesMetricValues,
} from './constants';
import { dateFilterKeys, determineDateFilterKey } from './dateFilterKeyHelper';

const extractQuery = (search) => {
  const query = {};
  search
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .forEach(([key, value]) => {
      query[key] = value || true;
    });
  return query;
};

const updateParam = (query, key, value) => {
  if (value === '') delete query[key];
  else if (typeof value !== 'undefined') query[key] = value;
};

const updateBoolParams = (query, boolFilters) => {
  if (boolFilters) {
    Object.keys(boolFilters)
      .forEach((boolKey) => {
        const value = boolFilters[boolKey];
        if (value === false) delete query[boolKey];
        else query[boolKey] = value;
      });
  }
};

export const getValueFromQuery = (queryString, queryKey) => {
  const queryRegex = new RegExp(`(^|&|/?)${queryKey}=(.+?)(&|$)`);
  const value = _.get(queryRegex.exec(queryString), 2, '');
  try {
    return decodeURIComponent(value);
  } catch (err) {
    return value;
  }
};

export const getIdsAndAllFromQueryValue = queryValue => _.chain(queryValue)
  .split(',').map(value => _.toNumber(value) || value).compact()
  .value();

export const getIdsFromQueryValue = queryValue => getIdsAndAllFromQueryValue(queryValue)
  .filter(value => value !== ALL);

const dateRangeBetweenTwoDates = (startDate, endDate) => {
  const currentDate = moment(startDate);
  const dateRange = [];

  while (currentDate.isSameOrBefore(endDate)) {
    dateRange.push(moment(currentDate));
    currentDate.add(1, 'day');
  }

  return dateRange;
};

export const getDateRangeFromQueryValue = (queryValue) => {
  const [startDate, endDate] = _.chain(queryValue)
    .split(DATE_QUERY_SEPARATOR)
    .compact()
    .map(value => moment(value))
    .value();

  if (!startDate || !endDate) {
    return [];
  }

  return dateRangeBetweenTwoDates(startDate, endDate);
};

const normalizeQueryValueForMetrics = (key, baseValue) => {
  try {
    switch (key) {
      case filterQueryKeys.DATES: {
        const [startDate, endDate] = baseValue.split('..').map(dateString => moment(dateString));
        const dateFilterKey = determineDateFilterKey(startDate, endDate);
        switch (dateFilterKey) {
          case dateFilterKeys.LAST_30_DAYS: return datesMetricValues.LAST30;
          case dateFilterKeys.YESTERDAY: return datesMetricValues.YESTERDAY;
          case dateFilterKeys.TODAY: return datesMetricValues.TODAY;
          case dateFilterKeys.CUSTOM: return datesMetricValues.CUSTOM;
          default: return baseValue;
        }
      }
      case filterQueryKeys.SITES:
      case filterQueryKeys.TAGS:
      case filterQueryKeys.STATUSES:
      case filterQueryKeys.TASKS:
        return baseValue.split(',').sort().join(',');
      default:
        return baseValue;
    }
  } catch (error) {
    return baseValue;
  }
};

const sendMetricsForUpdatedFilters = (
  filterMetric, previousQuery = {}, currentQuery = {}, params = {}
) => {
  const paramKeys = Object.keys(params);
  const currentKeys = Object.keys(currentQuery);
  const currentFilters = currentKeys.sort().join(',');

  const createFilterMetric = key => ({
    filterName: key,
    filterValue: normalizeQueryValueForMetrics(key, currentQuery[key]),
    currentFilters,
  });

  const metricsProperties = currentKeys
    .filter(key => previousQuery[key] !== currentQuery[key] && paramKeys.includes(key))
    .map(createFilterMetric);

  const metric = TrailMetricsDirectory.page[filterMetric].FILTER_APPLIED;
  const publish = propertiesForMetric => metricsPublisher.recordMetric(metric, propertiesForMetric);
  metricsProperties.forEach(publish);
};

const sendMetricsForUpdatedBoolParams = (
  previousQuery = {}, currentQuery = {}, boolParams = {}
) => {
  const previousQueryKeys = Object.keys(previousQuery);
  const newBoolKeys = Object.keys(boolParams).filter(key => !previousQueryKeys.includes(key));
  const appliedBoolKeys = Object.keys(currentQuery).filter(key => currentQuery[key] === true);

  if (newBoolKeys.length) {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskReports.VIEW_CONTENT_APPLIED,
      { newViews: newBoolKeys.sort().join(','), allViews: appliedBoolKeys.sort().join(',') }
    );
  }
};

export default (filterMetric, history, { boolFilters, ...params }, { sendMetrics = true } = {}) => {
  const { search } = history.location;
  const initialQuery = extractQuery(search);
  const updatedQuery = _.extend({}, initialQuery);
  Object.keys(params).forEach(key => updateParam(updatedQuery, key, params[key]));
  updateBoolParams(updatedQuery, boolFilters);

  if (sendMetrics) {
    sendMetricsForUpdatedFilters(filterMetric, initialQuery, updatedQuery, params);
    sendMetricsForUpdatedBoolParams(initialQuery, updatedQuery, boolFilters);
  }

  history.replace({
    search: buildQueryString(updatedQuery),
  });
};
