import windowHelper from '../window';

const authenticatedFetch = (route, opts) => (
  fetch(route, opts).then((response) => {
    const { status } = response;
    if (status === 401 || (status === 404 && opts.logoutOn404)) {
      windowHelper.navigateTo('/u/sign_in');
    }
    return response;
  }));

export const asyncAuthenticatedFetch = async (route, opts) => {
  const res = await fetch(route, opts);
  const { status } = res;
  if (status === 401 || (status === 404 && opts.logoutOn404)) {
    windowHelper.navigateTo('/u/sign_in');
  }
  return res;
};

export default authenticatedFetch;
