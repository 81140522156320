import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckBoxLabel from './CheckBoxLabel.jsx';

export const CheckBoxWrapperComponent = (props) => {
  const {
    checkboxClasses,
    additionalClasses,
    onChange,
    label,
    secondaryLabel,
    children,
    checked,
    disabled,
    dataTest,
    dataHint,
    hintRight,
    hintTop,
    hintInfo,
    stackedDescription,
    small,
  } = props;

  const checkboxComponentClasses = classNames({
    'showtime-checkbox': true,
    'is-selected': checked,
    'is-selected-disabled': checked && disabled,
    'showtime-checkbox--stacked': stackedDescription,
    'hint--medium': dataHint,
    'hint--right': dataHint && hintRight,
    'hint--top': dataHint && hintTop,
    'hint--info': dataHint && hintInfo,
    'showtime-checkbox--small': small,
  });

  const classes = `${checkboxComponentClasses} ${checkboxClasses} ${additionalClasses}`.trim();

  return (
    <div
      data-name='checkbox'
      { ...dataTest && { 'data-test': dataTest } }
      className={ classes }
      onClick={ disabled ? undefined : onChange }
      data-hint={ dataHint }
    >
      <span className='showtime-checkbox__tick'>
        { children }
      </span>
      <CheckBoxLabel
        label={ label }
        secondaryLabel={ secondaryLabel }
      />
    </div>
  );
};

CheckBoxWrapperComponent.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired,
  dataTest: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.node,
  checkboxClasses: PropTypes.string,
  additionalClasses: PropTypes.string,
  stackedDescription: PropTypes.bool,
  dataHint: PropTypes.string,
  hintRight: PropTypes.bool,
  hintTop: PropTypes.bool,
  hintInfo: PropTypes.bool,
  small: PropTypes.bool,
};

CheckBoxWrapperComponent.defaultProps = {
  dataTest: undefined,
  disabled: false,
  label: '',
  secondaryLabel: '',
  checkboxClasses: '',
  additionalClasses: '',
  stackedDescription: false,
  dataHint: null,
  hintRight: false,
  hintTop: false,
  hintInfo: false,
  small: false,
  onChange: undefined,
};

export default CheckBoxWrapperComponent;
