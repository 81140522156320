import _ from 'lodash';
import { isArchived } from '../../sites/status';

const getUserLocations = ({ userSettings: { siteAccess: { locations } } }) => locations;

export const getUserLocationsList = store => _.sortBy(Object.values(getUserLocations(store)), 'name');

const getUserGroups = ({ userSettings: { siteAccess: { groups } } }) => groups;

export const getUserGroupsList = store => _.sortBy(Object.values(getUserGroups(store)), 'name');

const getUserOrgs = ({ userSettings: { siteAccess: { organizations } } }) => organizations;

export const getUserOrgsList = store => _.sortBy(Object.values(getUserOrgs(store)), 'name');

export const getGroupLocationNames = (store, { group }) => {
  const locations = getUserLocations(store);
  return group.locations.reduce((locationNames, locationId) => {
    const location = locations[locationId] || {};
    if (isArchived(location)) {
      return locationNames;
    }
    return [...locationNames, location.name];
  }, []).sort();
};

export const getAssignedLocationTimelineTemplateIds = (store) => {
  if (!window.config.features.multiSite) {
    const defaultLocation = window.current_user.home_location.timeline_template_id;
    return [defaultLocation];
  }

  const { assigned } = store.userSettings.siteAccess;
  return assigned.locations;
};

export const getAssignedGroupTimelineTemplateIds = (store) => {
  const { assigned } = store.userSettings.siteAccess;
  return assigned.groups;
};

export const getAssignedOrganizationTimelineTemplateIds = (store) => {
  const { assigned } = store.userSettings.siteAccess;
  return assigned.organizations;
};

export const getUserSiteSearch = store => store.userSettings.siteAccess.search || '';

const filterByCurrentSearch = (store, { name } = {}) => {
  const currentSearch = getUserSiteSearch(store);
  return !currentSearch || name.toLowerCase().includes(currentSearch.toLowerCase());
};

export const getUserAssignedOrgs = (store, { searchFilter } = {}) => {
  const assignedOrgIds = getAssignedOrganizationTimelineTemplateIds(store);
  const orgs = getUserOrgsList(store);
  return orgs.filter((org) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, org);
    const isAssigned = assignedOrgIds.includes(org.timelineTemplateId);
    return includedInSearch && isAssigned;
  });
};

export const getUserAssignedGroups = (store, { searchFilter } = {}) => {
  const assignedGroupIds = getAssignedGroupTimelineTemplateIds(store);
  const groups = getUserGroupsList(store);
  return groups.filter((group) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, group);
    const isAssigned = assignedGroupIds.includes(group.timelineTemplateId);
    return includedInSearch && isAssigned;
  });
};

export const getLocationIdsInUserAssignedGroups = (store) => {
  const assignedGroups = getUserAssignedGroups(store);
  return _.flatMap(assignedGroups, 'locations');
};

export const getLocationIdsInUserAssignedOrgs = (store) => {
  const assignedOrgs = getUserAssignedOrgs(store);
  return _.flatMap(assignedOrgs, 'locations');
};

export const getUserOptionsAreFetching = ({ userSettings: { siteAccess } }) => (
  siteAccess.optionsAreFetching
);

export const getUserOptionsHaveFetched = ({ userSettings: { siteAccess } }) => (
  siteAccess.optionsHaveFetched
);

export const getLocationAssignedGroupNames = (store, { location }) => {
  const assignedGroups = getUserAssignedGroups(store);
  const assignedGroupNamesLocationBelongsTo = assignedGroups
    .reduce((nameList, group) => {
      const groupIncludesLocation = group.locations.includes(location.id);
      if (groupIncludesLocation) {
        return [...nameList, group.name];
      }
      return nameList;
    }, []);
  return assignedGroupNamesLocationBelongsTo.sort();
};

export const getUserAssignedLocations = (store, { searchFilter } = {}) => {
  const assignedLocationTimelineTemplateIds = getAssignedLocationTimelineTemplateIds(store);
  const locations = getUserLocationsList(store);
  return locations.filter(({ timelineTemplateId, name }) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, { name });
    const isAssigned = assignedLocationTimelineTemplateIds.includes(timelineTemplateId);
    return includedInSearch && isAssigned;
  });
};

export const getUserUnassignedLocations = (store, { searchFilter } = {}) => {
  const assignedLocationTimelineTemplateIds = getAssignedLocationTimelineTemplateIds(store);
  const locations = getUserLocationsList(store);
  return locations.filter(({ timelineTemplateId, name, status }) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, { name });
    const isUnassigned = !assignedLocationTimelineTemplateIds.includes(timelineTemplateId);
    return includedInSearch && isUnassigned && !isArchived({ status });
  });
};

export const getUserUnassignedGroups = (store, { searchFilter } = {}) => {
  const assignedGroupTimelineTemplateIds = getAssignedGroupTimelineTemplateIds(store);
  const groups = getUserGroupsList(store);
  return groups.filter(({ timelineTemplateId, name }) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, { name });
    const isUnassigned = !assignedGroupTimelineTemplateIds.includes(timelineTemplateId);
    return includedInSearch && isUnassigned;
  });
};

export const getUserUnassignedOrganizations = (store, { searchFilter } = {}) => {
  const assignedOrgTimelineTemplateIds = getAssignedOrganizationTimelineTemplateIds(store);
  const orgs = getUserOrgsList(store);
  return orgs.filter(({ timelineTemplateId, name }) => {
    const includedInSearch = !searchFilter || filterByCurrentSearch(store, { name });
    const isUnassigned = !assignedOrgTimelineTemplateIds.includes(timelineTemplateId);
    return includedInSearch && isUnassigned;
  });
};
