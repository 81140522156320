import _ from 'lodash';
import { FluxBridgeMiddlewareConstants } from './constants';
import AppDispatcher from '../../application/dispatcher';

const fluxBridgeMiddleware = function () {
  let fluxOptions;

  return function (next) {
    return function (action) {
      if (action.type === FluxBridgeMiddlewareConstants.DISPATCH) {
        if (!action.fluxActionType) {
          // eslint-disable-next-line no-console
          return console.error('You must provide a Flux action type to dispatch a Flux action');
        }

        fluxOptions = _.extend({}, action.options || {}, {
          actionType: action.fluxActionType,
        });

        AppDispatcher.dispatch(fluxOptions);
      }

      return next(action);
    };
  };
};

export default fluxBridgeMiddleware;
