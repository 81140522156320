import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioButton = props => (
  <label
    className={
      classNames(
        'showtime-radio-button',
        props.classes,
        {
          'showtime-radio-button--stacked': props.stackedDescription,
          'is-disabled': props.disabled,
        }
      )
    }
    data-test={ `radio-label${classNames({ '-is-selected': props.checked })}` }
    data-test-value={ `radio-label-${props.value}` }
  >
    <input
      className='showtime-radio-button__input'
      type='radio'
      name={ props.name }
      checked={ props.checked }
      value={ props.value }
      onChange={ e => props.onChange(e) }
    />
    <span className='showtime-radio-button__icon' />
    <span className='showtime-radio-button__label'>
      <span className='showtime-radio-button__label-primary'>
        { props.primaryLabel || props.children }
      </span>
      {
        props.secondaryLabel ?
          <span className='showtime-radio-button__label-secondary'>
            { props.secondaryLabel }
          </span> :
          null
      }
    </span>
  </label>);

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  primaryLabel: PropTypes.string,
  children: PropTypes.node,
  secondaryLabel: PropTypes.node,
  checked: PropTypes.bool,
  stackedDescription: PropTypes.bool,
  classes: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  name: '',
  value: '',
  primaryLabel: '',
  secondaryLabel: '',
  children: null,
  checked: false,
  stackedDescription: false,
  classes: '',
  onChange: () => {},
  disabled: false,
};

export default RadioButton;
