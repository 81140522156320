import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { REPLACE_STATE_IN_STORE, RESET_ENTIRE_STORE, SET_STATE_IN_STORE } from '../test/utils/actionTypes';

import { admin } from './admin/reducers';
import { notifications } from './activity/reducers';
import { attachments } from './attachments/reducers';
import { taskCommentBox } from './conversation/reducers';
import { initialState as flashState, notices, errors } from './flash/reducers';
import { organization } from './organization/reducers';
import { taskHistory } from './task-history/reducers';
import { base, table } from './task-planner/reducers';
import * as taskWizardReducers from './task-wizard/reducers';
import { timeslots } from './timeslots/reducers';
import { filters as trailFilters, tagFilter } from './trail/reducers';
import { editWidget, dataCapture, widgets } from './widget/reducers';
import { link } from './link/reducers';
import { fileSecurities } from './file/reducers';
import { userSettings } from './user/reducers';
import { users, roles } from './users/reducers';
import { sites, siteEdit } from './sites/reducers';
import { unsavedChanges } from './unsaved-changes/reducers';
import { billing } from './billing/reducers';
import { taskTemplates } from './taskTemplates/reducers';
import { tags } from './tags/reducers';
import { taskReports } from './taskReports/reducers';
import { floatifications } from './floatifications/reducers';
import { setupGuide } from './setup-guide/reducers';
import { taskActions } from './taskActions/reducers';
import { scheduleCategories } from './scheduleCategories/reducers';

import middleware from './middleware';
import { siteAccessMiddleware } from './user/middleware';
import { routerMiddleware } from './router/middleware';
import { unsavedChangesMiddleware } from './unsaved-changes/middleware';
import { taskContentComments } from './taskContentComments/reducers';
import { signUp } from './sign-up/reducers';

import { customRoles } from './customRoles/reducers';

const {
  tagManagementTable, taskWizard,
  ...newTaskReducers
} = taskWizardReducers;

const appReducer = combineReducers({
  admin,
  floatifications,
  notifications,
  notices,
  errors,
  organization,
  base,
  table,
  newTask: combineReducers({
    ...newTaskReducers,
    attachments,
  }),
  scheduleCategories,
  tagManagementTable,
  taskWizard,
  tagFilter,
  trail: combineReducers({
    filters: trailFilters,
  }),
  taskActions,
  taskContentComments,
  taskHistory,
  timeslots,
  taskCommentBox,
  editWidget,
  link,
  fileSecurities,
  userSettings,
  dataCapture,
  sites,
  siteEdit,
  unsavedChanges,
  users,
  roles,
  billing,
  taskReports,
  taskTemplates,
  tags,
  widgets,
  setupGuide,
  signUp,
  customRoles,
});

const isTest = process.env.NODE_ENV === 'test';

const testActions = {
  [RESET_ENTIRE_STORE]: () => undefined,
  [SET_STATE_IN_STORE]: (state, action) => ({ ...state, ...action.content }),
  [REPLACE_STATE_IN_STORE]: (state, action) => action.content,
};

export const reducers = (state, action) => {
  if (isTest && action.type in testActions) {
    state = testActions[action.type](state, action);
  }

  return appReducer(state, action);
};

export const state = {
  ...flashState,
};

export const instantiateStore = () => createStore(
  reducers,
  state,
  composeWithDevTools(applyMiddleware(
    thunk, ...middleware, siteAccessMiddleware, routerMiddleware, unsavedChangesMiddleware
  ))
);

const store = instantiateStore();

export default store;
