import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { WidgetRegistry } from '../../widget/widgetRegistry';
import { TaskReportsSelectors } from '../selectors';
import LoadingSpinner from '../../common/LoadingSpinner';
import { TrailConstants } from '../../trail/constants';
import { TASK_ROUTE_PREFIX } from '../constants';

const TaskWidgetWrapper = ({ expanded, children }) => (
  <div data-test='taskReports.taskWidget'>
    { expanded && children }
  </div>
);
TaskWidgetWrapper.propTypes = {
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const TaskWidget = ({
  id, widgetData = {}, isLoading, viewRecordLogs, viewComments,
  intl: { formatMessage }, history, locationTimeZone,
}) => {
  if (isLoading) {
    return (
      <TaskWidgetWrapper expanded={ viewRecordLogs }>
        <LoadingSpinner
          classes='showtime-loader--padding-reduced'
          label={ formatMessage({ id: 'taskReports.task.fetchingRecordLogs' }) }
        />
      </TaskWidgetWrapper>
    );
  }

  const widgetType = widgetData.type;
  if (!WidgetRegistry.widgetReadyForPreview(widgetType)) return null;

  const WidgetComponent = WidgetRegistry.getComponentForType(widgetType);
  return (
    <TaskWidgetWrapper expanded={ viewRecordLogs }>
      <WidgetComponent
        widgetData={ widgetData }
        taskStatus={ TrailConstants.taskStatus.DONE }
        widgetId={ widgetData.id }
        id={ widgetData.id }
        taskId={ id }
        router={ history }
        readOnly
        hideHeader
        hideTaskContentComments={ !viewComments }
        showAllErrors
        routePrefix={ TASK_ROUTE_PREFIX }
        timeZone={ locationTimeZone }
      />
    </TaskWidgetWrapper>
  );
};

TaskWidget.propTypes = {
  id: PropTypes.number.isRequired,
  widgetData: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  viewRecordLogs: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

TaskWidget.defaultProps = {
  widgetData: {},
};

const mapStateToProps = (state, ownProps) => ({
  widgetData: TaskReportsSelectors.taskWidgetSelector(state, ownProps),
  locationTimeZone: TaskReportsSelectors.taskLocationTimeZoneSelector(state, ownProps),
  isLoading: TaskReportsSelectors.taskWidgetIsLoadingSelector(state, ownProps),
  viewRecordLogs: TaskReportsSelectors.viewRecordLogsSelector(state, ownProps),
  viewComments: TaskReportsSelectors.viewCommentsSelector(state, ownProps),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(TaskWidget);
