import React from 'react';
import PropTypes from 'prop-types';

import { RecurringTooltip } from '../../../trail/Subtask';

const SubtaskName = ({ children, recurring }) => (
  <div>
    { children }
    { recurring && <RecurringTooltip /> }
  </div>
);
SubtaskName.propTypes = {
  children: PropTypes.node,
  recurring: PropTypes.bool,
};
SubtaskName.defaultProps = {
  recurring: false,
  children: null,
};

export default SubtaskName;
