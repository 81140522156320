import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import SVGIcon from './SVGIcon';

export class ClearableSearchInput extends PureComponent {
  inputField = createRef();
  state = { value: this.props.value };

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
    this.props.onChange(value);
  }

  handleClear = () => {
    this.setState({ value: '' });
    this.props.onChange('');
    this.inputField.current.focus();
  }

  handleExternalClear = () => {
    this.setState({ value: '' }); // eslint-disable-line react/no-did-update-set-state
    this.inputField.current.focus();
  }

  componentDidMount() {
    if (this.props.initialFocus) {
      this.inputField.current.focus();
    }
  }

  componentDidUpdate({ value: prevValue }) {
    if (prevValue && !this.props.value) {
      this.handleExternalClear();
    }
  }

  render() {
    const {
      placeholder, className, onBlur, dataTest,
    } = this.props;
    const { value } = this.state;

    return (
      <div
        className={ `showtime-input showtime-input--search showtime-input--block ${className}` }
      >
        <SVGIcon
          classes='showtime-input__search-icon'
          iconName='mini/ui/ui-16px-1_zoom'
        />
        <input
          type='text'
          placeholder={ placeholder }
          onChange={ this.handleChange }
          onBlur={ onBlur }
          value={ value }
          ref={ this.inputField }
          data-test={ dataTest }
        />
        { Boolean(value) &&
          <a
            onClick={ this.handleClear }
            className='showtime-link showtime-link--alternate'
            data-test='clear-search'
          >
            <SVGIcon
              classes='showtime-link__icon showtime-link__icon--left'
              iconName='mini/ui/ui-16px-1_simple-remove'
            />
          </a>
        }
      </div>
    );
  }
}

ClearableSearchInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  initialFocus: PropTypes.bool,
  dataTest: PropTypes.string,
};

ClearableSearchInput.defaultProps = {
  onChange() {},
  onBlur() {},
  placeholder: '',
  value: '',
  className: '',
  initialFocus: false,
  dataTest: undefined,
};

export default ClearableSearchInput;
