import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactInlineSVG from 'react-inlinesvg';

const ACCEPTED_SVG_PATHS = [
  'customers',
  'custom',
  'custom/guide',
  'custom/attachment-icons',
  'empty-states',
  'glyph/24px/design-development',
  'glyph/24px/shopping',
  'glyph/24px/text-editing',
  'glyph/24px/ui',
  'logo',
  'media',
  'mini/arrows',
  'mini/business-finance',
  'mini/design-development',
  'mini/files-folders',
  'mini/healthcare-medical',
  'mini/maps-location',
  'mini/objects',
  'mini/shopping',
  'mini/technology',
  'mini/ui',
  'mini/users',
  'outline/32px/objects',
  'outline/48px',
];

class SVGIcon extends PureComponent {
  getReactInlineIconPath() {
    return `${window.config.assetPath}nucleo/${this.props.iconName}.svg`;
  }

  getSvgrComponent = (iconName) => {
    const firstIndex = iconName.indexOf('/') === 0 ? 1 : 0;
    const fileStartIndex = iconName.lastIndexOf('/') + 1;
    const fileName = iconName.substring(fileStartIndex);
    const folderName = iconName.substring(firstIndex, fileStartIndex - 1);
    /* eslint-disable global-require */
    /* eslint-disable import/no-dynamic-require */
    if (ACCEPTED_SVG_PATHS.includes(folderName)) {
      try {
        return require(`../svgs/${folderName}/${fileName}.svg`).default;
      } catch (error) {
        return null;
      }
    }
    /* eslint-enable global-require */
    /* eslint-enable import/no-dynamic-require */

    return null;
  };

  render() {
    if (!this.props.iconName) return null;

    const SvgR = this.getSvgrComponent(this.props.iconName);
    if (SvgR) {
      const classNames = `isvg loaded ${this.props.classes}`;
      return (
        <span className={ classNames }>
          <SvgR />
        </span>
      );
    }

    return (
      <ReactInlineSVG
        className={ this.props.classes }
        src={ this.getReactInlineIconPath() }
        cacheGetRequests
      />
    );
  }
}

SVGIcon.propTypes = {
  iconName: PropTypes.string,
  classes: PropTypes.string,
};

SVGIcon.defaultProps = {
  iconName: undefined,
  classes: undefined,
};

export default SVGIcon;
