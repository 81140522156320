import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button';

const CopyToClipboard = ({
  value,
  copiedHint,
  copyHint,
  className,
  onCopy,
}) => {
  const [copied, setCopied] = useState(false);

  className = classnames(className, {
    'showtime-button--default showtime-button--small showtime-button--no-radius-left-side hint--top-left': true,
    'hint--info': !copied,
    'hint--success': copied,
  });

  return (
    <Button
      dataTest='copyToClipboard.copyButton'
      onMouseLeave={ () => {
        setCopied(false);
      } }
      onClick={ () => {
        copy(value);
        setCopied(true);
        onCopy();
      } }
      dataHint={ copied ? copiedHint : copyHint }
      iconName='custom/copy-task-24px'
      className={ className }
    />
  );
};

CopyToClipboard.propTypes = {
  value: PropTypes.string.isRequired,
  copiedHint: PropTypes.string.isRequired,
  copyHint: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
  className: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  onCopy: () => {},
  className: '',
};

export default CopyToClipboard;
