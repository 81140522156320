export const twoDigits = number => number.toString().padStart(2, '0');

export const hourLabel = (hour) => {
  const h = parseInt(hour, 10);
  if (h < 24) return twoDigits(hour);

  return `${'↷'}${(twoDigits(hour - 24))}`;
};

export const parseTimeSlotTime = (valueToParse) => {
  const mappedParts = valueToParse.split(':').map(num => twoDigits(parseInt(num, 10)));

  return {
    h: mappedParts[0],
    m: mappedParts[1],
  };
};
