import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import metricsPublisher, {
  TrailMetricsDirectory, MetricsPublisherConstants,
} from '../../../../../metrics';
import { UserRole, getCurrentUserRole } from '../../../../../application/UserRole';
import Button from '../../../../../common/Button';
import { ConfirmationMenu } from '../../../../../common/menu';
import withPureComponent from '../../../../../common/withPureComponent';
import {
  classNamePrefix,
  STATUS_OFFLINE,
  STATUS_SAVING,
  STATUS_SAVED,
} from '../../constants';
import SaveStatus from '../SaveStatus';
import { autosaveWidgetListener } from '../../../../HoC';

export const Header = ({
  visibleActiveRowPosition,
  deleteRecord,
  hideDeleteConfirmation,
  isDeleteConfirmationShown,
  isOffline,
  minimumRows,
  rowCount,
  showDeleteConfirmation,
  widgetId,
  saving,
  intl,
  actionCount,
}) => {
  const outsideClickIgnoreClass = `ignore-onclickoutside-menu-dots-${widgetId}`;

  const deleteButtonClasses = classNames(
    'showtime-button--small',
    'showtime-button--record-action',
    outsideClickIgnoreClass
  );

  const getSaveStatus = () => {
    if (isOffline) {
      return STATUS_OFFLINE;
    }

    if (saving) {
      return STATUS_SAVING;
    }

    return STATUS_SAVED;
  };
  const CurrentUserRole = getCurrentUserRole();

  const currentUserCanRemoveRow = rowCount > 1
    && (
      (
        CurrentUserRole.isManagerOrAbove() &&
        !CurrentUserRole.isPermissionDisabled(
          UserRole.permissionTypes.MANAGE_RESTRICTED_WIDGET_ROWS
        )
      ) || rowCount > minimumRows
    );

  const formatConfirmationMessage = message => intl.formatMessage({ id: `trail.widgets.data_capture.delete_record_confirmation.${message}` }, { actionCount });

  const sendActionMetric = (actionRemoved) => {
    if (actionCount > 0) {
      metricsPublisher.recordMetric(
        TrailMetricsDirectory.page.Trail.ACTION_REMOVAL_CONFIRMATION,
        {
          actionRemoved,
          removalType: MetricsPublisherConstants.actionRemovalType.ROW_DELETED,
        }
      );
    }
  };

  const handleDeleteRecord = () => {
    sendActionMetric(true);

    deleteRecord();
  };
  const handleHideDeleteConfirmation = () => {
    sendActionMetric(false);

    hideDeleteConfirmation();
  };

  return (
    <div className={ `${classNamePrefix}-content-header` }>
      <span className='showtime-label showtime-label--pill is-muted'>
        <span className='showtime-label__text'>
          { visibleActiveRowPosition } / { rowCount }
        </span>
      </span>

      <div className={ `${classNamePrefix}-content-header-actions` }>
        <SaveStatus
          status={ getSaveStatus() }
        />

        { currentUserCanRemoveRow
          && (
          <Button
            buttonClasses={ deleteButtonClasses }
            iconName='mini/ui/ui-16px-1_trash-simple'
            onClick={ showDeleteConfirmation }
            dataTest='delete.record'
          />
          )}

        { isDeleteConfirmationShown
          && (
          <ConfirmationMenu
            { ...{
              showMenu: isDeleteConfirmationShown,
              onClickOutside: handleHideDeleteConfirmation,
              disableOnClickOutside: !isDeleteConfirmationShown,
              align: 'left',
              outsideClickIgnoreClass,
              onConfirm: handleDeleteRecord,
              onAbort: handleHideDeleteConfirmation,
              title: formatConfirmationMessage('title'),
              description: formatConfirmationMessage('description'),
              abort: formatConfirmationMessage('cancel'),
              confirm: formatConfirmationMessage('confirm'),
            } }
          />
          )}
      </div>
    </div>
  );
};

Header.propTypes = {
  visibleActiveRowPosition: PropTypes.number.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  hideDeleteConfirmation: PropTypes.func.isRequired,
  isDeleteConfirmationShown: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  minimumRows: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  showDeleteConfirmation: PropTypes.func.isRequired,
  widgetId: PropTypes.number.isRequired,
  saving: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  actionCount: PropTypes.number,
};

Header.defaultProps = {
  actionCount: 0,
};

const withAutosaveStatus = autosaveWidgetListener(({ widgetId }) => widgetId);

export default compose(
  injectIntl,
  withAutosaveStatus,
  withPureComponent
)(Header);
