import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as _ from 'lodash';

import { SubTaskConstants } from '../constants';
import Button from '../../common/Button';
import AnchoredMenu from '../../common/menu/AnchoredMenu';

const naButtonProps = {
  buttonClasses: 'showtime-button showtime-button--highlight-alt',
  clickValue: SubTaskConstants.VALUES.NOT_APPLICABLE,
  iconName: 'mini/ui/ui-16px-2_ban',
};

const primaryActions = [
  {
    buttonClasses: 'showtime-button showtime-button--delete-confirm',
    labelId: 'trail.subtasks.cantComplete',
    test: 'cant-complete-button',
    clickValue: SubTaskConstants.VALUES.NOT_DONE,
    iconName: 'mini/ui/ui-16px-1_simple-remove',
  },
  {
    ...naButtonProps,
    labelId: 'trail.subtasks.notApplicable',
    test: 'na-button',
  },
];

const secondaryActions = [
  {
    ...naButtonProps,
    labelId: 'trail.subtasks.notApplicableToday',
    test: 'na-button-today',
  }, {
    ...naButtonProps,
    labelId: 'trail.subtasks.neverApplicable',
    test: 'na-button-recurring',
    clickValue: SubTaskConstants.VALUES.NEVER_APPLICABLE,
  },
];

const secondaryNAActions = [
  {
    ...naButtonProps,
    labelId: 'trail.subtasks.neverApplicable',
    test: 'na-button-recurring',
    clickValue: SubTaskConstants.VALUES.NEVER_APPLICABLE,
  },
];

export class FlagOptionsMenu extends Component {
  constructor(props) {
    super(props);
    const displaySecondaryNAActions = this.props.showOnlyNeverApplicableFlagOption &&
      !this.props.showNotApplicableFlagOptions &&
      this.props.canSetRecurring;

    this.state = {
      actions: displaySecondaryNAActions ? secondaryNAActions : primaryActions,
    };
  }

  handleSelectOption(value) {
    const showSecondaryActions = this.props.canSetRecurring
      && value === SubTaskConstants.VALUES.NOT_APPLICABLE
      && _.isEqual(this.state.actions, primaryActions)
      && _.isNil(this.props.added_by_user_id);

    const {
      showOnlyNeverApplicableFlagOption,
      showNotApplicableFlagOptions,
    } = this.props;

    const displaySecondaryNAActions = showOnlyNeverApplicableFlagOption &&
    !showNotApplicableFlagOptions;

    const hasBothCustomRolePermissions = showOnlyNeverApplicableFlagOption ===
    showNotApplicableFlagOptions;

    const displaySecondaryActions = showSecondaryActions && hasBothCustomRolePermissions;

    if (displaySecondaryActions) {
      this.setState({ actions: secondaryActions });
    } else if (displaySecondaryNAActions) {
      this.setState({ actions: secondaryNAActions });
      this.props.onSelectOption(value);
    } else {
      this.props.onSelectOption(value);
    }
  }

  componentWillReceiveProps({ showMenu }) {
    if (this.props.showMenu === true && showMenu === false) {
      this.setState({ actions: primaryActions });
    }
  }

  render() {
    const { intl, ...menuProps } = this.props;
    const { actions } = this.state;

    return (
      <AnchoredMenu
        { ...{
          ...menuProps,
          content: (
            <div className='showtime-stacked-modal-padded'>
              <p>{ intl.formatMessage({ id: 'trail.subtasks.flagHelpText' }) }</p>
              <div className='showtime-stacked-modal__actions'>
                {actions.map(({
                  buttonClasses, labelId, test, clickValue, iconName,
                }) => (
                  <Button
                    { ...{
                      key: test,
                      buttonClasses,
                      label: intl.formatMessage({ id: labelId }),
                      dataTest: test,
                      onClick: () => this.handleSelectOption(clickValue),
                      iconName,
                    } }
                  />
                ))}
              </div>
            </div>
          ),
        } }
      />
    );
  }
}

FlagOptionsMenu.propTypes = {
  onSelectOption: PropTypes.func,
  canSetRecurring: PropTypes.bool,
  showMenu: PropTypes.bool,
  added_by_user_id: PropTypes.number,
  showNotApplicableFlagOptions: PropTypes.bool,
  showOnlyNeverApplicableFlagOption: PropTypes.bool,
};

FlagOptionsMenu.defaultProps = {
  onSelectOption() {},
  canSetRecurring: false,
  showMenu: false,
  added_by_user_id: undefined,
  showNotApplicableFlagOptions: true,
  showOnlyNeverApplicableFlagOption: true,
};

export default injectIntl(FlagOptionsMenu);
