import { RequestConstants, RequestActions } from '../../request';
import { pickPolicyUrl, requestKey } from '../../file/constants';
import { CommentBoxConstants } from '../constants';

const {
  SET_ATTACHMENT,
} = CommentBoxConstants;

export const getFilestackPickSignatureThunk = () => RequestActions.request({
  url: pickPolicyUrl,
  key: requestKey.pick,
  method: RequestConstants.GET,
});

const setAttachment = ({ attachment, taskId }) => ({
  type: SET_ATTACHMENT,
  attachment,
  taskId,
});

export const TaskCommentBoxActions = {
  setAttachment,
};
