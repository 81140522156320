import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AttachmentGrid from '../AttachmentGrid';
import DateTimeZone from '../DateTimeZone';

export const TableComments = ({
  comments, isClickable, routePrefix, viewSignature, timeZone,
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  return comments.map(
    comment => (
      <tr className='showtime-widget-v2__cell-comment' data-test='table.comment' key={ comment.id }>
        <td>
          <div className='showtime-widget-v2__cell-comment-content'>
            { comment.content }
            { !_.isEmpty(comment.attachments) && (
              <AttachmentGrid
                files={ comment.attachments }
                isClickable={ isClickable }
                router={ history }
                routePrefix={ routePrefix }
              />
            ) }
          </div>
        </td>
        { viewSignature && (
          <td className='showtime-widget-v2__cell-comment-signature'>
            <DateTimeZone
              date={ comment.commented_at }
              format={ formatMessage({ id: 'taskReports.signature.timeDateFormat' }) }
              timeZone={ timeZone }
            />
            {
              formatMessage({ id: 'taskReports.signature.name' }, { name: comment.commented_by_user_name })
            }
          </td>
        ) }
      </tr>
    )
  );
};

TableComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    attachments: PropTypes.array,
  })).isRequired,
  isClickable: PropTypes.bool.isRequired,
  routePrefix: PropTypes.string.isRequired,
  viewSignature: PropTypes.bool,
};

TableComments.defaultProps = {
  viewSignature: true,
};
