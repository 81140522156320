import { tagManagementTableReducer as tagManagementTable } from './tagManagementTableReducer';
import { taskWizardReducer as taskWizard } from './taskWizardReducer';

import { taskCheckListReducer as checkList } from './taskCheckListReducer';
import { taskHelpTextReducer as helpText } from './taskHelpTextReducer';
import { taskTemplateReducer as template } from './taskTemplateReducer';
import { scheduleReducer as schedule } from './scheduleReducer';
import { taskStatusReducer as status } from './taskStatusReducer';
import { taskTagReducer as tags } from './taskTagReducer';
import { taskTitleReducer as title } from './taskTitleReducer';
import { taskTrailsSelectorReducer as trails } from './taskTrailsSelectorReducer';
import { taskWidgetReducer as widget } from './taskWidgetReducer';

import permissions from './taskPermissionsReducer';

import mandatoryComments from './mandatoryCommentsReducer';
import report from './reportReducer';

const reducers = {
  checkList,
  helpText,
  template,
  schedule,
  status,
  tags,
  title,
  trails,
  widget,
  tagManagementTable,
  taskWizard,
  permissions,
  mandatoryComments,
  report,
};

export {
  checkList,
  helpText,
  template,
  schedule,
  status,
  tags,
  title,
  trails,
  widget,
  tagManagementTable,
  taskWizard,
  permissions,
  mandatoryComments,
  report,
};

export default reducers;
