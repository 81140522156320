import _ from 'lodash';
import FlashConstants, { ErrorConstants } from '../constants';

export const errorsReducer = function (state, action) {
  const initialState = {
    content: {
      value: '',
    },
  };
  const currState = state || _.extend({}, initialState);
  const errorMessage = action.errorMessage ||
                     (action.content && action.content.body && action.content.body.error);
  const typeRegex = /\.request_middleware_error/;
  let actionType = typeRegex.exec(action.type);
  actionType = Array.isArray(actionType) ? actionType.pop() : action.type;

  switch (actionType) {
    case '.request_middleware_error':
    case FlashConstants.ALERT:
      return _.extend({}, currState, {
        content: {
          value: errorMessage,
        },
      });
    case ErrorConstants.CLEAR:
      return _.extend({}, currState, {
        content: {
          value: '',
        },
      });
    default:
      return currState;
  }
};
