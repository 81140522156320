import { cookieJar } from '../utils/window';
import { COOKIE_KEY, COOKIE_CHECKOUT_ID_IDENTIFIER } from './constants';
import random from '../common/random';

export const getSignUp = () => cookieJar.getObject(COOKIE_KEY) || {};

export const getSignInValue = (name, defaultValue = '') => getSignUp()[name] || defaultValue;

export const setSignInValue = (name, value) => {
  const signInCookies = getSignUp();
  cookieJar.set(COOKIE_KEY, JSON.stringify({ ...signInCookies, [name]: value }));
};

const generateGuid = () => {
  // eslint-disable-next-line no-bitwise
  const s4 = () => (((1 + random()) * 0x10000) | 0).toString(16).substring(1);
  return (`${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`);
};

export const checkoutId = () => {
  const checkoutIdValue = cookieJar.get(COOKIE_CHECKOUT_ID_IDENTIFIER) || generateGuid();
  cookieJar.set(COOKIE_CHECKOUT_ID_IDENTIFIER, checkoutIdValue);
  return checkoutIdValue;
};
