import AttachmentConstants from '../constants';
import { REQUEST_FILESTACK_UPLOAD } from '../../middleware/filestack/constants';

export const AttachmentActions = {
  add() {
    return {
      type: AttachmentConstants.ADD,
    };
  },

  delete(attachmentIndex, { taskId } = {}) {
    return {
      type: AttachmentConstants.DELETE,
      attachmentIndex,
      taskId,
    };
  },

  select(attachmentIndex) {
    return {
      type: AttachmentConstants.SELECT,
      attachmentIndex,
    };
  },

  changeName(attachmentIndex, name) {
    return {
      type: AttachmentConstants.CHANGE_NAME,
      attachmentIndex,
      name,
    };
  },

  startUpload(attachmentIndex) {
    return {
      type: AttachmentConstants.START_UPLOAD,
      attachmentIndex,
    };
  },

  requestFilestackUpload(attachmentIndex, { taskId } = {}) {
    return {
      type: REQUEST_FILESTACK_UPLOAD,
      successAction: {
        type: AttachmentConstants.COMPLETE_UPLOAD,
        attachmentIndex,
        taskId,
      },
      errorAction: {
        type: AttachmentConstants.CANCEL_UPLOAD,
        attachmentIndex,
      },
    };
  },

  addLink(linkDetails) {
    return {
      type: AttachmentConstants.ADD_LINK,
      index: linkDetails.index,
      url: linkDetails.url,
    };
  },
};
