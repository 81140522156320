import { TaskCheckListConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

export const TaskCheckListActions = {
  onAddItem: (type, position) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskCheckListConstants.ADD_ITEM,
      itemType: type,
      position,
    });
  },
  onRemoveItem: position => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskCheckListConstants.REMOVE_ITEM,
      position,
    });
  },
  onSortItems: sortedItems => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    const itemIdToNewPosition = sortedItems.reduce((accu, item) => ({
      ...accu,
      [item.id]: item.position,
    }), {});

    dispatch({
      type: TaskCheckListConstants.SORT_ITEMS,
      itemIdToNewPosition,
    });
  },
  onItemNamePaste: (
    pastedText, selectionStart, selectionEnd, position, taskName, templateType
  ) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());
    const metricsEvent = /\n/.exec(pastedText)
      ? TrailMetricsDirectory.page.TaskWizard.CHECKLIST_MULTI_LINE_PASTE
      : TrailMetricsDirectory.page.TaskWizard.CHECKLIST_SINGLE_LINE_PASTE;
    metricsPublisher.recordMetric(metricsEvent, {
      taskName,
      templateType,
    });

    dispatch({
      type: TaskCheckListConstants.PASTE_INTO_ITEM_NAME,
      pastedText,
      position,
      selectionStart,
      selectionEnd,
    });
  },
  onItemNameChange: (item, value) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskCheckListConstants.CHANGE_ITEM_NAME,
      item,
      value,
    });
  },
  onExpandItem(itemId) {
    return { type: TaskCheckListConstants.EXPAND_ITEM, itemId };
  },
  onConvertItem(item) {
    return { type: TaskCheckListConstants.CONVERT_ITEM, item };
  },
  onUnexpandItem() {
    return { type: TaskCheckListConstants.UNEXPAND_ITEM };
  },
  changeIsMandatory: isMandatory => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskCheckListConstants.CHANGE_IS_MANDATORY,
      isMandatory,
    });
  },
  expand() {
    return {
      type: TaskCheckListConstants.EXPAND,
    };
  },
};
