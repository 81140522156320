import _ from 'lodash';
import moment from 'moment-timezone';
import { TaskTemplateConstants } from '../../task/constants';
import { TaskWizardConstants } from '../constants';
import TaskScheduleConstants from '../../schedule/constants';

const {
  ON_DEMAND, AUTOMATED, ACTION_TEMPLATE,
} = TaskTemplateConstants.templateType;

const oneOffAndRecurringViews = [
  TaskScheduleConstants.VIEW.RECURRING,
  TaskScheduleConstants.VIEW.ONE_OFF,
];

export const canAddSchedule = ({ newTask }) => !TaskTemplateConstants.templatesWithoutSchedule.includes(_.get(newTask, 'template.content.value'));

export const activeScheduleSelector = (state) => {
  const {
    newTask: { schedule: { activeScheduleKey, content: schedules } },
  } = state;
  return schedules[activeScheduleKey];
};

export const scheduleHasShortcutView = ({ view }) => (
  !view || view === TaskScheduleConstants.VIEW.SHORTCUTS
);

export const canSetWarnOnEarly = ({ view }) => oneOffAndRecurringViews.includes(view);

export const earlyTaskWarningSelector = (state) => {
  const schedules = _.get(state, 'newTask.schedule.content', {});
  if (_.isEmpty(schedules)) return false;

  if (!Object.values(schedules).every(canSetWarnOnEarly)) return false;

  return _.get(state, 'newTask.schedule.earlyTaskWarning', false);
};

const getTimeFromTimeBlock = (timeBlock, type) => {
  if (timeBlock.type !== type) {
    return undefined;
  }
  if (type === TaskScheduleConstants.TIME_SLOT) {
    return timeBlock.value.id;
  }
  return timeBlock.value;
};

const getTemplateTypeFromRecurrenceBy = (recurrenceBy) => {
  if (recurrenceBy > 0) {
    return TaskScheduleConstants.recurrenceOptions.RECURRING;
  }
  return TaskTemplateConstants.templateType.SINGLE;
};

const getSelectedDayValuesFromRecurrence = (onDays) => {
  if (!onDays) {
    return undefined;
  }
  return Object.keys(onDays).filter(key => onDays[key]);
};

const getDateFromDateBlock = (dateBlock, isNewSchedule) => {
  const DATE_FMT = 'YYYY-MM-DD';
  if (isNewSchedule) {
    return moment(dateBlock.date).format(DATE_FMT);
  }
  if (dateBlock.updated) {
    return moment(dateBlock.date).format(DATE_FMT);
  }
  return undefined;
};

const formatSchedule = ({
  id, start, end, recurrence,
}) => {
  const isNewSchedule = !id;
  return {
    schedule_id: id,
    has_been_updated: !isNewSchedule,
    first_instance_start_date: getDateFromDateBlock(start, isNewSchedule),
    first_instance_end_date: getDateFromDateBlock(end, isNewSchedule),
    duration: {
      start_timeslot: getTimeFromTimeBlock(start, TaskScheduleConstants.TIME_SLOT),
      end_timeslot: getTimeFromTimeBlock(end, TaskScheduleConstants.TIME_SLOT),
      start_time: getTimeFromTimeBlock(start, TaskScheduleConstants.TIME),
      end_time: getTimeFromTimeBlock(end, TaskScheduleConstants.TIME),
    },
    recurrence: {
      by: recurrence.by,
      unit: recurrence.unit,
      on: getSelectedDayValuesFromRecurrence(recurrence.on),
    },
  };
};

export const scheduleFieldsForRequestSelector = (state, { actionModal = false } = {}) => {
  const {
    newTask: {
      template: { content: { value: taskTemplateType } },
    },
  } = state;

  const isOnDemand = taskTemplateType === ON_DEMAND;
  if (isOnDemand) {
    return { template_type: ON_DEMAND };
  }

  const isActionTemplate = taskTemplateType === ACTION_TEMPLATE;
  if (isActionTemplate && !actionModal) {
    return { template_type: ACTION_TEMPLATE };
  }

  const isAutomated = taskTemplateType === AUTOMATED;
  const { completionDays, id } = activeScheduleSelector(state) || {};
  if (isAutomated) {
    return {
      template_type: AUTOMATED,
      schedules: [
        {
          automated_completion_days: completionDays,
          schedule_id: id,
        },
      ],
    };
  }

  const {
    newTask: {
      schedule: {
        scheduleKeys: scheduleKeyOrder,
        content: taskSchedules,
      },
    },
  } = state;

  const schedules = scheduleKeyOrder.reduce((accu, key) => {
    const scheduleIsMidSelection = scheduleHasShortcutView(taskSchedules[key]);
    if (scheduleIsMidSelection && scheduleKeyOrder.length > 1) return accu;

    return [...accu, formatSchedule(taskSchedules[key])];
  }, []);

  const firstSchedule = taskSchedules[scheduleKeyOrder[0]];
  const isNewSchedule = !firstSchedule.id;

  const { start, recurrence } = firstSchedule;

  return {
    ...TaskWizardConstants.EXTRA_PARAMS_FOR_RECURRING,
    schedules,
    recurrence_unit: recurrence.unit,
    recur_by: recurrence.by || null,
    start_date: getDateFromDateBlock(start, isNewSchedule),
    time: start.value,
    timeslot_id: getTimeFromTimeBlock(start, TaskScheduleConstants.TIME_SLOT),
    template_type: getTemplateTypeFromRecurrenceBy(firstSchedule.recurrence.by),
    time_type: firstSchedule.start.type,
  };
};
