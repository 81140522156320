import widgetConstants from '../constants';
import { RequestActions, RequestConstants } from '../../request';

const { fetchWidgetsUrl, FETCH_WIDGETS_KEY } = widgetConstants;

const fetchWidgets = widgetIds => RequestActions.request({
  url: fetchWidgetsUrl(widgetIds),
  key: FETCH_WIDGETS_KEY,
  method: RequestConstants.GET,
});

export const WidgetActions = {
  fetchWidgets,
};
