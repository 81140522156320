import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DateTimeZone from '../common/DateTimeZone';

export const Signature = ({
  completedAt, updatedAt, completedBy, intl: { formatMessage }, timeZone,
}) => (
  <React.Fragment>
    <DateTimeZone
      date={ completedAt || updatedAt }
      format={ formatMessage({ id: 'taskReports.signature.timeDateFormat' }) }
      timeZone={ timeZone }
    />
    {completedBy &&
      formatMessage({ id: 'taskReports.signature.name' }, { name: completedBy })
    }
  </React.Fragment>
);
Signature.propTypes = {
  completedBy: PropTypes.string,
  completedAt: PropTypes.string,
  updatedAt: PropTypes.string,
};
Signature.defaultProps = {
  completedAt: null,
  completedBy: null,
  updatedAt: null,
};

export default injectIntl(Signature);
