import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import { NoticeActions, ErrorActions } from './actions';
import FlashConstants from './constants';
import FlashMessageContent from './FlashMessageContent';

export class FlashMessage extends Component {
  componentDidMount() {
    this.clearAnyMessages(this.props, FlashConstants.TRANSITION_TIMEOUT);
  }

  componentWillReceiveProps(nextProps) {
    this.clearAnyMessages(nextProps);
  }

  clearAnyMessages(props, delay) {
    var clearDelay = delay || 0;
    if (props.errors.content.value) {
      this.props.actions.error.clear(clearDelay);
    }
    if (props.notices.content.value) {
      this.props.actions.notice.clear(clearDelay);
    }
  }

  getBannerProps() {
    const errorValues = this.props.errors.content.value;
    if (errorValues && errorValues.length > 0) {
      return {
        message: this.props.intl.formatMessage({ id: this.props.errors.content.value, defaultMessage: this.props.errors.content.value }),
        type: 'showtime-message--error',
        iconPath: 'mini/ui/ui-16px-e_round-e-alert',
        buttonLabel: this.props.intl.formatMessage({ id: 'common.confirm' }),
        action: (e) => { this.dismiss(e); },
      };
    } else if (this.props.notices.content.value.length > 0) {
      return {
        message: this.props.intl.formatMessage({ id: this.props.notices.content.value, defaultMessage: this.props.notices.content.value }),
        type: 'showtime-message--success',
        iconPath: 'mini/ui/ui-16px-1_check',
        action: (e) => { this.dismiss(e); },
        buttonLabel: this.props.intl.formatMessage({ id: 'common.confirm' }),
      };
    }
    return null;
  }

  render() {
    const props = this.getBannerProps();
    const notification = props ? <FlashMessageContent {...props} /> : null;
    return (
      <CSSTransitionGroup
        transitionName="slide"
        transitionEnterTimeout={FlashConstants.TRANSITION_TIMEOUT}
        transitionLeaveTimeout={FlashConstants.TRANSITION_TIMEOUT}
      >
        {notification}
      </CSSTransitionGroup>
    );
  }

  dismiss(e) {
    e.preventDefault();
    this.clearAnyMessages(this.props);
  }
}


FlashMessage.propTypes = {
  errors: PropTypes.object,
  notices: PropTypes.object,
  actions: PropTypes.shape({
    error: PropTypes.object,
    notice: PropTypes.object,
  }),
};

const mapStateToProps = ({ notices, errors }) => ({ notices, errors });
const mapDispatchToProps = dispatch => ({
  actions: {
    error: bindActionCreators(ErrorActions, dispatch),
    notice: bindActionCreators(NoticeActions, dispatch),
  },
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FlashMessage));
