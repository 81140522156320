import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import { EditWidgetActions } from '../../actions';

import SettingsMessageField from './SettingsMessageField';

const MandatoryWarningInput = ({
  value,
  onChange,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  return (
    <SettingsMessageField
      { ...{ value, onChange, onDelete } }
      label={ formatMessage({ id: 'editWidget.builder.mandatoryMessageLabel' }) }
    />
  );
};

MandatoryWarningInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { setWidgetPublicSettings } = bindActionCreators(EditWidgetActions, dispatch);

  const path = `columns[${ownProps.columnIndex}].cellSettings.mandatoryWarningText`;
  const onChange = value => setWidgetPublicSettings(path, value);
  const onDelete = () => {
    setWidgetPublicSettings(path, '');
    ownProps.onDelete();
  };
  return { onChange, onDelete };
};

const mapStateToProps = (state, { columnIndex }) => {
  const { settings } = TaskWidgetSelectors.editedTaskWidgetConfig(state);
  const { mandatoryWarningText } = _.get(settings, `public.columns[${columnIndex}].cellSettings`, {});

  return { value: mandatoryWarningText };
};

export default connect(mapStateToProps, mapDispatchToProps)(MandatoryWarningInput);
