import { RequestConstants } from '../../request';
import { GET_SETUP_GUIDE_PROGRESS } from '../constants';

export const INITIAL_STATE = {};

const PROGRESS_LOADED = RequestConstants.getLoadedActionType(GET_SETUP_GUIDE_PROGRESS);
const PROGRESS_LOADING = RequestConstants.getLoadingActionType(GET_SETUP_GUIDE_PROGRESS);

const actionCases = {
  [PROGRESS_LOADED]: (state, action) => ({
    ...state,
    loading: false,
    progress: action.content.progress,
    trial: action.content.trial,
  }),
  [PROGRESS_LOADING]: (state, action) => ({
    ...state,
    loading: true,
    progress: action.content.progress,
    trial: action.content.trial,
  }),
};

export const setupGuideReducer = (state, action) => {
  state = state || INITIAL_STATE;
  const actionCase = actionCases[action.type];
  if (actionCase) return actionCase(state, action);
  return state;
};
