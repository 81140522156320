import React from 'react';
import { injectIntl } from 'react-intl';

import ExportOption from './ExportOption';

const CopyToClipboardTitle = injectIntl(({ intl: { formatMessage } }) => (
  <React.Fragment>
    <hr className='showtime-copy-to-clipboard__divider' />
    <ExportOption iconName='mini/ui/ui-16px-share'>
      { formatMessage({ id: 'share.copyTitle' })}
    </ExportOption>
  </React.Fragment>
));

export default CopyToClipboardTitle;
