import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import PrefixSuffixSettings from './PrefixSuffixSettings';
import NumberRange from './NumberRange';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

class SettingsNumber extends Component {
  state = {
    showPrefixSuffix: Boolean(
      this.props.column.cellSettings.prefixText
      || this.props.column.cellSettings.suffixText
    ),
    showAcceptableRange: this.props.column.cellSettings.minValueWarningLimit
      || this.props.column.cellSettings.maxValueWarningLimit
      || this.props.column.cellSettings.outOfRangeWarningText
      || _.get(this.props, 'column.cellSettings.validation.ruleColumn'),
  };

  showPrefixSuffix = () => this.setState({ showPrefixSuffix: true });
  removePrefixSuffix = () => this.setState({ showPrefixSuffix: false });

  showAcceptableRange = () => this.setState({ showAcceptableRange: true });
  hideAcceptableRange = () => this.setState({ showAcceptableRange: false });

  render() {
    const { columnIndex, intl, errors } = this.props;

    const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.numberColumn');

    return (
      <div className='showtime-record-log-builder-field__config'>
        { this.state.showPrefixSuffix && (
          <PrefixSuffixSettings columnIndex={ columnIndex } onRemove={ this.removePrefixSuffix } />
        )}
        { this.state.showAcceptableRange && (
          <NumberRange
            columnIndex={ columnIndex }
            onRemove={ this.hideAcceptableRange }
            errors={ errors }
          />
        )}
        <div className='showtime-record-log-builder-field__config-links'>
          { !this.state.showPrefixSuffix && (
            <a
              className='showtime-link showtime-link--small showtime-link--highlight'
              onClick={ this.showPrefixSuffix }
              data-test='widget.columns.addPrefixSuffix'
            >
              { formatMessage('addPrefixSuffix') }
            </a>
          )}
          { !this.state.showAcceptableRange && (
            <a
              className='showtime-link showtime-link--small showtime-link--highlight'
              onClick={ this.showAcceptableRange }
              data-test='widget.columns.addRange'
            >
              { formatMessage('addRange') }
            </a>
          )}
        </div>
      </div>
    );
  }
}

SettingsNumber.propTypes = {
  column: PropTypes.any.isRequired,
  columnIndex: PropTypes.number.isRequired,
  errors: PropTypes.array.isRequired,
};

export default compose(
  injectIntl
)(SettingsNumber);
