import React from 'react';

import SubtaskStatus from './SubtaskStatus';
import SubtaskName from './SubtaskName';
import Signature from '../../Signature';
import { SubTaskConstants } from '../../../trail/constants';
import SubtaskComments from './SubtaskComments';

export const isIncomplete = value => value === SubTaskConstants.VALUES.UNCHECKED;

const SubtaskRow = ({
  name, recurring, value, completedBy, completedAt, updatedAt, locationTimeZone, id, timelineTaskId,
}) => ([
  <SubtaskName recurring={ recurring }>{ name }</SubtaskName>,
  <table>
    <tr className='showtime-task-reports__task-content-table-metadata-header'>
      <SubtaskStatus value={ value } />
      <td className='showtime-widget-v2__cell-comment-signature'>
        { !isIncomplete(value) && (
          <Signature
            { ...{
              completedBy, completedAt, updatedAt, timeZone: locationTimeZone,
            } }
          />
        ) }
      </td>
    </tr>
    <SubtaskComments { ...{ id, timelineTaskId, timeZone: locationTimeZone } } />
  </table>,
]);
SubtaskRow.propTypes = {
  ...SubtaskName.propTypes,
  ...SubtaskStatus.propTypes,
  ...Signature.propTypes,
};

export default SubtaskRow;
