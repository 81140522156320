import { actionTypes } from '../constants';

export default (state, action) => {
  state = state || false;
  switch (action.type) {
    case actionTypes.touched:
      return true;
    case actionTypes.cleared:
      return false;
    default:
      return state;
  }
};
