import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { TaskTemplateConstants } from '../task/constants';
import { getCurrentUserRole } from '../application/UserRole';

import { locationsByIdSelector } from '../sites/selectors';

const { templateType: { ON_DEMAND, AUTOMATED } } = TaskTemplateConstants;

const TaskAssignedSitesDescription = ({
  taskAssignedSites, templateType,
}) => {
  const { formatMessage } = useIntl();

  const userAssignedSites = useSelector(locationsByIdSelector);

  if ([ON_DEMAND, AUTOMATED].includes(templateType)) {
    return formatMessage({ id: 'taskplanner.assigned_to_all_trails' });
  }

  const taskSitesAssignedToUser = _.chain(userAssignedSites)
    .pick(_.map(taskAssignedSites, 'id')).values().value();

  const taskAssignedSitesCount = _.size(taskAssignedSites);
  const userHasAccessToAssignedSites = (
    taskSitesAssignedToUser.length >= taskAssignedSitesCount
  ) || getCurrentUserRole().isOrgAdminOrAbove();

  if (userHasAccessToAssignedSites) {
    return formatMessage(
      { id: 'taskplanner.assigned_to_trails' },
      { siteCount: taskAssignedSitesCount });
  }

  return formatMessage(
    { id: 'taskplanner.assigned_to_your_trails' },
    {
      assignedToAllSitesCount: taskAssignedSitesCount,
      assignedToYourSitesCount: taskSitesAssignedToUser.length,
    });
};

TaskAssignedSitesDescription.propTypes = {
  taskAssignedSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  templateType: PropTypes.string.isRequired,
};

export default TaskAssignedSitesDescription;
