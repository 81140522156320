import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchInput from './SearchInput';
import ViewFiltersToggle from './ViewFiltersToggle';
import Filters from '../Filters';
import TaskReportsTitle from './TaskReportsTitle';
import FixedShortcutsMenu from './FixedShortcutsMenu';
import OffsetSticky from '../../common/OffsetSticky';
import getStickyOffset from '../../common/getStickyOffset';
import ExportModeHeader from './ExportModeHeader';

const HeaderBody = React.forwardRef(({
  disableSticky,
  showFilters,
  showShortcutMenu,
  width,
}, ref) => {
  const [headerIsStuck, toggleHeaderStuck] = useState(false);

  return (
    <OffsetSticky
      parentBorderWidth={ 1 }
      stickyClassName='showtime-task-reports__header-body--is-stuck'
      top={ getStickyOffset(width) }
      onFixedToggle={ toggleHeaderStuck }
      key={ `${headerIsStuck}_${showFilters}` }
      disabled={ disableSticky }
    >
      <div className='showtime-task-reports__header-body' ref={ ref }>
        <div className='showtime-task-reports__header-actions'>
          { !headerIsStuck && showShortcutMenu && <FixedShortcutsMenu /> }
          <SearchInput />
          <ViewFiltersToggle />
        </div>
        { showFilters && <Filters /> }
      </div>
    </OffsetSticky>
  );
});

HeaderBody.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  showShortcutMenu: PropTypes.bool.isRequired,
  disableSticky: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

HeaderBody.defaultProps = {
  width: 0,
};

const TaskReportsHeader = React.forwardRef(({
  showFilters,
  showShortcutMenu,
  bodyRef,
  exportHeaderRef,
  width,
  exportModeEnabled,
  showShareMenu,
}, ref) => (
  <div className='showtime-task-reports__header' ref={ ref } data-test='task-reports-header'>
    { !showShortcutMenu && !exportModeEnabled &&
      <TaskReportsTitle url={ window.location.href } showShareMenu={ showShareMenu } />}
    { exportModeEnabled && <ExportModeHeader exportModeRef={ exportHeaderRef } /> }
    <HeaderBody
      width={ width }
      showFilters={ showFilters }
      showShortcutMenu={ showShortcutMenu }
      ref={ bodyRef }
      disableSticky={ exportModeEnabled }
    />
  </div>
));

TaskReportsHeader.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  showShortcutMenu: PropTypes.bool.isRequired,
  bodyRef: PropTypes.object.isRequired,
  exportHeaderRef: PropTypes.object.isRequired,
  width: PropTypes.number,
  exportModeEnabled: PropTypes.bool.isRequired,
  showShareMenu: PropTypes.bool,
};

TaskReportsHeader.defaultProps = {
  width: 0,
  showShareMenu: true,
};

export default TaskReportsHeader;
