import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';

export const Tab = ({
  iconName, isActive, dataTest, onSelect, children, name, disabled,
}) => (
  <a
    className={
      classNames('showtime-tabs-underline-headers-item', {
        'is-active': isActive,
        'is-disabled': disabled,
      })
    }
    onClick={ () => onSelect(name) }
    data-test={ dataTest }
  >
    { iconName && (
      <SVGIcon
        classes='showtime-tabs-underline-headers-item-icon'
        iconName={ iconName }
      />
    )}
    { children }
  </a>
);

Tab.propTypes = {
  iconName: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

Tab.defaultProps = {
  iconName: null,
  dataTest: null,
  children: null,
  isActive: false,
  onSelect() {},
  disabled: false,
};

export const Tabs = ({
  containerClassName, className, onSelect, activeTab, children,
}) => {
  const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, {
    isActive: child.props.name === activeTab,
    onSelect,
  }));
  return (
    <div className={ classNames(containerClassName, 'showtime-tabs-underline') }>
      <div className={ classNames(className, 'showtime-tabs-underline-headers') }>
        { childrenWithProps }
      </div>
    </div>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node,
};
Tabs.defaultProps = {
  activeTab: '',
  onSelect() {},
  className: '',
  containerClassName: '',
  children: null,
};
