/* eslint-disable no-console */
import TimeoutMiddlewareConstants from './constants';

const timeoutMiddleware = (data) => {
  const { dispatch } = data;
  return next => (action) => {
    if (action.type === TimeoutMiddlewareConstants.TIMEOUT) {
      if (!action.delayedAction) {
        console.error('You must provide a delayedAction with a ' +
          '`TIMEOUT` action. Provided:', action);
      } else if (!action.delayPeriodMs) {
        console.error('You must provide a delayPeriodMs with a ' +
          '`TIMEOUT` action. Provided:', action);
      } else {
        setTimeout(() => {
          dispatch(action.delayedAction);
        }, action.delayPeriodMs);
      }
    }

    return next(action);
  };
};

export { TimeoutMiddlewareConstants };
export default timeoutMiddleware;
