import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Options from '../Options';
import Text from '../Text';
import FileInput from '../FileInput';
import TaskContentCommentThread from '../../../../../taskContentComments/TaskContentCommentThread';
import CommentButton from '../../../../../taskContentComments/CommentButton';
import { commentableTypes } from '../../../../../taskContentComments/constants';

import {
  FORMATTED_NUMBER,
  FREE_TEXT,
  OPTIONS,
  FILE,
  inputTypes,
} from '../../constants';

const typeToElement = {
  [FREE_TEXT]: Text,
  [FILE]: FileInput,
  [FORMATTED_NUMBER]: Text,
  [OPTIONS]: Options,
};

const shallowCompare = (obj1, obj2) => _.isEqualWith(
  obj1,
  obj2,
  (obj1Val, obj2Val, index) => (
    index === undefined ? undefined : Object.is(obj1Val, obj2Val)));

class InputComponent extends Component {
  shouldComponentUpdate({ values, labels, ...shallowNextProps }, nextState) {
    const { values: lastValues, labels: lastLabels, ...shallowLastProps } = this.props;
    const optionsChanged = !_.isEqual(labels, lastLabels) || !_.isEqual(values, lastValues);
    const primitiveValuePropsChanged = !shallowCompare(shallowNextProps, shallowLastProps);
    return optionsChanged || primitiveValuePropsChanged || !shallowCompare(nextState, this.state);
  }

  state = { showThread: false };

  toggleShowThread = () => this.setState(({ showThread }) => ({ showThread: !showThread }));

  render() {
    const {
      componentType,
      containerClassName,
      hideTaskContentComments,
      ...props
    } = this.props;
    const Element = typeToElement[componentType];

    if (!Element) return null;

    const {
      widgetId,
      taskId,
      rowId,
      fieldName,
      readOnly,
      isTaskCompleted,
    } = this.props;

    const commentThreadLinkingProps = {
      commentableId: widgetId,
      commentableType: commentableTypes.widget,
      timelineTaskId: taskId,
      widgetRowId: rowId,
      widgetFieldName: fieldName,
      readOnly,
    };

    const showCommentFeature = window.config.features.inlineComments && !hideTaskContentComments;

    return (
      <div
        className={ containerClassName }
        data-test={ `field-${props.fieldName}` }
        ref={ this.fieldRef }
      >
        <Element { ...{ ...this.props, readOnly: readOnly || isTaskCompleted } }>
          { showCommentFeature && !readOnly && (
            <CommentButton
              { ...commentThreadLinkingProps }
              onClick={ this.toggleShowThread }
            />
          ) }
        </Element>
        { showCommentFeature && (this.state.showThread || readOnly) && (
          <TaskContentCommentThread { ...commentThreadLinkingProps } />
        ) }
      </div>
    );
  }
}

InputComponent.propTypes = {
  componentType: PropTypes.oneOf(inputTypes).isRequired,
  hideTaskContentComments: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.array,
  widgetId: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired,
  rowId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  taskHeaderHeight: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  isTaskCompleted: PropTypes.bool,
};

InputComponent.defaultProps = {
  containerClassName: '',
  labels: undefined,
  values: undefined,
  readOnly: false,
  isTaskCompleted: false,
};

export default InputComponent;
