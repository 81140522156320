import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';

export const ToolTipWrapper = ({
  children, message, left, upsell, bottom, dataTest,
}) => (
  <div
    className={ classNames('hint--medium', { 'hint--upsell': upsell, 'hint--left': left && !bottom, 'hint--bottom': bottom }) }
    data-hint={ message }
    data-test={ dataTest }
  >
    {children}
  </div>
);

ToolTipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
  message: PropTypes.string.isRequired,
  upsell: PropTypes.bool,
  left: PropTypes.bool,
  bottom: PropTypes.bool,
};

ToolTipWrapper.defaultProps = {
  upsell: false,
  dataTest: undefined,
  left: true,
  bottom: false,
};

const ToolTip = (props) => {
  const { message, iconName, clickable } = { ...props };

  const hintSizeClasses = ['hint--small', 'hint--medium', 'hint--large'];
  const classes = props.classes.slice();
  if (_.intersection(hintSizeClasses, classes).length === 0) {
    classes.push('hint--medium');
  }
  const linkClasses = classes.join(' ');
  const iconClasses = ['showtime-icon'].concat(props.iconClasses).join(' ');

  const TipComponent = clickable ? 'a' : 'span';

  return (
    <TipComponent
      href='javascript:void(0);'
      className={ linkClasses }
      onClick={ clickable ? () => props.onInteraction('click') : null }
      onMouseLeave={ () => props.onInteraction('hover') }
      data-hint={ message }
      data-test={ props.dataTest }
    >
      <SVGIcon
        classes={ iconClasses }
        iconName={ iconName }
      />
    </TipComponent>
  );
};

ToolTip.propTypes = {
  onInteraction: PropTypes.func,
  classes: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconClasses: PropTypes.arrayOf(PropTypes.string),
  dataTest: PropTypes.string,
  clickable: PropTypes.bool,
};

ToolTip.defaultProps = {
  onInteraction: _.noop,
  classes: [],
  iconClasses: ['showtime-icon--size-14'],
  iconName: 'mini/ui/ui-16px-2_alert-circle-help',
  dataTest: '',
  clickable: true,
};

export default ToolTip;
