import { actionConstants } from '../constants';

export default (state, action) => {
  const initialState = [];

  const currState = state || initialState;

  switch (action.type) {
    case actionConstants.ADD_MESSAGE:
      return currState.some(({ id }) => id === action.message.id)
        ? currState.reduce((acc, message) => [
          ...acc,
          message.id === action.message.id
            ? action.message
            : message,
        ], [])
        : [
          action.message,
          ...currState,
        ];

    case actionConstants.REMOVE_MESSAGE_BY_INDEX:
      return currState.filter((message, index) => index !== action.index);

    case actionConstants.REMOVE_MESSAGE_BY_ID:
      return currState.filter(({ id }) => id !== action.id);

    case actionConstants.UPDATE_MESSAGE_BY_ID:
      return currState.reduce((acc, message) => {
        const updatedMessage = message.id === action.id
          ? {
            ...message,
            text: action.text,
          }
          : message;

        return [
          ...acc,
          updatedMessage,
        ];
      }, []);

    case actionConstants.CLEAR_MESSAGES:
      return [];

    default:
      return currState;
  }
};
