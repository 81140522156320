import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon from '../../../common/SVGIcon';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '../../../common/constants/validation';

const ValidationIcon = ({ status }) => (
  <SVGIcon
    classes='showtime-button__icon'
    iconName={
      status === VALIDATION_ERROR
        ? 'mini/ui/ui-16px-2_alert-circle-error'
        : 'mini/ui/ui-16px-3_alert'
    }
  />
);

ValidationIcon.propTypes = {
  status: PropTypes.oneOf([VALIDATION_ERROR, VALIDATION_WARNING]).isRequired,
};

export default ValidationIcon;
