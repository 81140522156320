import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SVGIcon from '../common/SVGIcon.jsx';

class ExpandableContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  expand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  renderExpandButton() {
    let label;
    let linkClass;

    if (this.state.isExpanded) {
      label = this.props.intl.formatMessage({ id: 'common.collapse' });
      linkClass = 'showtime-link--collapse';
    } else {
      label = this.props.intl.formatMessage({ id: 'common.expand' });
      linkClass = 'showtime-link--expand';
    }

    return (
      <a
        href='javascript:void(0);'
        onClick={ this.expand }
        className={ `showtime-link showtime-link--alternate ${linkClass}` }
      >
        {this.state.isExpanded ? null : <SVGIcon
          classes='showtime-link__icon showtime-link__icon--left'
          iconName='mini/arrows/arrows-16px-1_minimal-down'
        />}
        {this.state.isExpanded ? <SVGIcon
          classes='showtime-link__icon showtime-link__icon--left'
          iconName='mini/arrows/arrows-16px-1_minimal-up'
        /> : null} {label}
      </a>
    );
  }

  render() {
    const bodyClasses = {
      'showtime-expandable-container': true,
      'is-expanded': this.state.isExpanded,
    };

    return (
      <div className={ classNames(bodyClasses) }>
        {this.props.children}
        {this.renderExpandButton()}
      </div>
    );
  }
}

ExpandableContent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default injectIntl(ExpandableContent);
