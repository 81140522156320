import _ from 'lodash';
import { RequestConstants } from '../../request';
import { TaskPlannerConstants } from '../constants';
import { LocationConstants } from '../../sites/constants/locationConstants';

export const taskPlannerTableReducer = (function () {
  function selectTasks(currState, action) {
    const currentSelectedTasks = currState.selectedTasks;
    return currentSelectedTasks.indexOf(action.id) > -1
      ? _.without(currentSelectedTasks, action.id)
      : [].concat(currentSelectedTasks, [action.id]);
  }

  function selectAllTasks(currState, action) {
    const allTasks = action.tasks.filter(task => task.editable)
      .filter(({ status }) => status !== LocationConstants.STATUSES.archived).map(task => task.id);
    return _.extend({}, currState, {
      selectedTasks: allTasks,
    });
  }

  function toggleTaskExpanded(currState, action) {
    const taskIsExpanded = _.includes(
      currState.expandedTasks,
      action.id
    );
    const expandedTasks = taskIsExpanded
      ? _.without(currState.expandedTasks, action.id)
      : currState.expandedTasks.concat([action.id]);
    const allTaskIds = action.allTasks.map(task => task.id);
    const allTasksExpanded = _.isEqual(allTaskIds.sort(), expandedTasks.sort());

    return _.extend({}, currState, {
      expandedTasks,
      allTasksExpanded,
    });
  }

  function expandAllTasks(currState, action) {
    const allTaskIds = action.allTasks.map(task => task.id);

    return _.extend({}, currState, {
      expandedTasks: allTaskIds,
      allTasksExpanded: true,
    });
  }

  function collapseAllTasks(currState) {
    return _.extend({}, currState, {
      expandedTasks: [],
      allTasksExpanded: false,
    });
  }

  function updateSelectedTasks(currState, action) {
    const selectedTasks = selectTasks(currState, action);
    return _.extend({}, currState, { selectedTasks });
  }

  function reducer(state, action) {
    const initialState = {
      selectedTasks: [],
      showAssignToTrailSuccessNotice: false,
      showAddTagsToTasksSuccessNotice: false,
      showDeleteSuccessNotice: false,
      showRemoveTagsFromTasksSuccessNotice: false,
      expandedTasks: [],
      allTasksExpanded: false,
    };

    const currState = state || _.extend({}, initialState);

    switch (action.type) {
      case TaskPlannerConstants.RESET:
        return initialState;
      case TaskPlannerConstants.UPDATE_SELECTED_TASKS:
        return updateSelectedTasks(currState, action);
      case TaskPlannerConstants.SELECT_ALL_TASKS:
        return selectAllTasks(currState, action);
      case TaskPlannerConstants.CLEAR_ALL_TASKS:
        return _.extend({}, currState, {
          selectedTasks: [],
        });
      case TaskPlannerConstants.REMOVE_ASSIGNMENT_SUCCESS_NOTICE:
        return _.extend({}, currState, {
          showAssignToTrailSuccessNotice: false,
        });
      case TaskPlannerConstants.ADD_TAGS_TO_TASKS_SUCCESS_NOTICE:
        return _.extend({}, currState, {
          showAddTagsToTasksSuccessNotice: false,
        });
      case TaskPlannerConstants.REMOVE_UNASSIGNMENT_SUCCESS_NOTICE:
        return _.extend({}, currState, {
          showUnassignFromTrailSuccessNotice: false,
        });
      case TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS_SUCCESS_NOTICE:
        return _.extend({}, currState, {
          showRemoveTagsFromTasksSuccessNotice: false,
        });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.DELETE_TASK_KEY):
        return _.extend({}, currState, {
          selectedTasks: [],
          showDeleteSuccessNotice: true,
        });
      case TaskPlannerConstants.REMOVE_DELETE_SUCCESS_NOTICE:
        return _.extend({}, currState, {
          showDeleteSuccessNotice: false,
        });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.ARCHIVE_TASK_KEY):
        return _.extend({}, currState, { selectedTasks: [] });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.UNASSIGN_FROM_TRAIL):
        return _.extend({}, currState, {
          selectedTasks: [],
          showUnassignFromTrailSuccessNotice: true,
        });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.ASSIGN_TO_TRAIL):
        return _.extend({}, currState, {
          selectedTasks: [],
          showAssignToTrailSuccessNotice: true,
        });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.ADD_TAGS_TO_TASKS):
        return _.extend({}, currState, {
          selectedTasks: [],
          showAddTagsToTasksSuccessNotice: true,
        });
      case RequestConstants.getLoadedActionType(TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS):
        return _.extend({}, currState, {
          selectedTasks: [],
          showRemoveTagsFromTasksSuccessNotice: true,
        });
      case TaskPlannerConstants.TOGGLE_TASK_EXPANDED:
        return toggleTaskExpanded(currState, action);
      case TaskPlannerConstants.EXPAND_ALL_TASKS:
        return expandAllTasks(currState, action);
      case TaskPlannerConstants.COLLAPSE_ALL_TASKS:
        return collapseAllTasks(currState, action);
      case TaskPlannerConstants.HIGHLIGHT_TASK_TEMPLATE:
        return {
          ...currState,
          scrollToTaskTemplateId: action.taskTemplateId,
        };
      default:
        return currState;
    }
  }

  return reducer;
}());
