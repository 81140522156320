import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import sizes, { MEDIUM } from './sizes';
import positions, { TOP } from './positions';

const InteractionBlocker = ({
  blocked,
  children,
  inline,
  size,
  position,
  tooltipMessage,
  fullWidth,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const containerClasses = classnames(
    'showtime-interaction-blocker',
    'hint--blocked',
    sizes[size],
    positions[position],
    {
      'showtime-interaction-blocker--inline': inline,
      'showtime-interaction-blocker--block': fullWidth,
    }
  );

  return blocked
    ? (
      <div
        className={ containerClasses }
        data-hint={ tooltipMessage }
        onClickCapture={ handleClick }
        tabIndex='-1'
      >

        { children }
      </div>
    )
    : children;
};

InteractionBlocker.propTypes = {
  blocked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizes)),
  position: PropTypes.oneOf(Object.keys(positions)),
  tooltipMessage: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

InteractionBlocker.defaultProps = {
  inline: false,
  fullWidth: false,
  size: MEDIUM,
  position: TOP,
};

export default InteractionBlocker;
