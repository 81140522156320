import React from 'react';
import _ from 'lodash';
import { WarningSvg, ErrorSvg } from './svg';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '../../common/constants/validation';
import DateTimeZone from '../../common/DateTimeZone';

const LAST_UPDATED_FIELD = 'lastUpdated';

export const getErrorSvg = (errorType, colorFill) => {
  switch (errorType) {
    case VALIDATION_ERROR:
      return <ErrorSvg style={ { fill: colorFill, height: 10, marginTop: 2 } } />;
    case VALIDATION_WARNING:
      return <WarningSvg style={ { fill: colorFill, height: 10, marginTop: 2 } } />;
    default:
      return null;
  }
};

export const formatFieldValue = (value, column, fieldName, intl, timeZone) => {
  if (value === undefined) {
    return null;
  }

  if (fieldName === LAST_UPDATED_FIELD) {
    return (
      <DateTimeZone
        date={ value }
        format={ intl.formatMessage({ id: 'trail.widgets.data_capture.last_updated_date_format' }) }
        timeZone={ timeZone }
      />
    );
  }

  const prefix = column.cellSettings.prefixText || '';
  const suffix = column.cellSettings.suffixText || '';

  return `${prefix}${value}${suffix}`;
};

export const mapFieldNameToColumn = widget => _.fromPairs(
  [
    ...widget.public_settings.columns.map(column => [column.cellSettings.fieldName, column]),
    [LAST_UPDATED_FIELD, {}],
  ]
);

export const colorForFieldError = (errors, rowIndex, fieldName) => {
  const errorType = _.get(errors, `[${rowIndex}][${fieldName}][0].type`);
  switch (errorType) {
    case VALIDATION_ERROR:
      return '#FF513D';
    case VALIDATION_WARNING:
      return '#FF9100';
    default:
      return null;
  }
};
