const UserAgentQuery = function UserAgentQuery() {
  this.mobileRegex = /Mobi|Tablet|Android|iPad|iPhone/;
};

UserAgentQuery.prototype.userAgent = () => navigator.userAgent;

UserAgentQuery.prototype.isIPadDesktopMode = () => (
  navigator.platform === 'MacIntel' && (navigator.maxTouchPoints || 0) > 1
);

UserAgentQuery.prototype.isMobileDevice = function () {
  return this.isIPadDesktopMode() || this.mobileRegex.test(this.userAgent());
};

export default new UserAgentQuery();
