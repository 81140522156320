import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as _ from 'lodash';
import { RouterConstants } from '../router';

import LoadingSpinner from '../common/LoadingSpinner';
import { TrailConstants } from '../trail/constants';
import { TaskPlannerActions } from './actions';
import { UsersActions } from '../users/actions';
import { TagActions } from '../tags/actions';
import { getCurrentUserRole } from '../application/UserRole';
import TaskPlannerSidebar from './TaskPlannerSidebar';
import TaskPlannerActionBar from './TaskPlannerActionBar';
import TaskPlannerTable from './TaskPlannerTable';
import { TaskPlannerSelectors } from './selectors';
import Button from '../common/Button';
import TaskTemplatePlannerOptions from './TaskTemplatePlannerOptions';
import { fetchLocations } from '../sites/actions';

export class TaskPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileFilters: false,
    };
    this.showMobileFilterSidebar = this.showMobileFilterSidebar.bind(this);
    this.hideMobileFilterSidebar = this.hideMobileFilterSidebar.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps, this.props)) return true;
    if (!_.isEqual(nextState, this.state)) return true;

    return false;
  }

  showMobileFilterSidebar() {
    this.setState({
      showMobileFilters: true,
    });
  }

  hideMobileFilterSidebar() {
    this.setState({
      showMobileFilters: false,
    });
  }

  renderFilterButton() {
    let button = null;
    const buttonClasses = classNames({
      'showtime-button--lowlight-alt': !this.state.showMobileFilters,
      'showtime-button--highlight-alt': this.state.showMobileFilters,
      'showtime-button--small': true,
      'showtime-button--block': true,
    });

    if (this.state.showMobileFilters) {
      button = (
        <Button
          data-test='hideMobileFilter'
          onClick={ this.hideMobileFilterSidebar }
          buttonClasses={ buttonClasses }
          label='Hide filters'
        />
      );
    } else {
      button = (
        <Button
          data-test='showMobileFilter'
          onClick={ this.showMobileFilterSidebar }
          buttonClasses={ buttonClasses }
          label='Show filters'
        />
      );
    }

    return (
      <div className='showtime-layout-admin-management__filters-button-show-hide'>
        { button }
      </div>
    );
  }

  renderLoading() {
    return (<LoadingSpinner ref='taskPlanner.loading' />);
  }

  renderTaskPlannerTable() {
    const { taskPlanner: { isLoading, filteredTaskTemplates, scheduleCategories } } = this.props;
    if (isLoading) {
      return this.renderLoading();
    }

    return (
      <TaskPlannerTable
        ref='taskPlanner.table'
        taskTemplates={ filteredTaskTemplates }
        router={ this.props.router }
        location={ this.props.location }
        datePeriod={ this.props.datePeriod }
        tags={ this.props.taskPlanner.tags }
        scheduleCategories={ scheduleCategories }
        singleLocation={ this.props.singleLocation }
        singleLocationId={ this.props.singleLocationId }
        isLibraryPlanner={ this.props.isLibraryPlanner }
        organizationId={ this.props.organizationId }
        previewTaskId={ this.props.previewTaskId }
      />
    );
  }

  renderPlannerOptions = () => this.canShowPlannerOptions && (
    <TaskTemplatePlannerOptions
      libraries={ this.props.template_libraries }
      activeOrgId={ this.props.organizationId }
      onSelect={ tab => this.onPlannerOrganizationChanged(tab) }
    />
  );

  loadTaskPlanner = (organizationId) => {
    this.props.actions.taskPlanner.getTaskPlan(organizationId);

    if (!getCurrentUserRole().isOrgAdminOrAbove()) return;

    this.props.getOrganizationUsers(
      window.current_user.home_timeline.organization_id
    );
  };

  loadTags = (plannerOrganizationId) => {
    const organizationId = (
      plannerOrganizationId || window.current_user.home_timeline.organization_id
    );
    this.props.actions.tags.fetchOrganizationTags(organizationId, window.current_user.id);
  };

  onPlannerOrganizationChanged = (organizationId) => {
    this.props.actions.taskPlanner.reset();

    if (organizationId) {
      this.props.router.push(RouterConstants.buildTaskTemplateLibraryUrl(organizationId));
    } else {
      this.props.router.push(RouterConstants.BASE_URL);
    }
  };

  get canShowMobileFilterButton() {
    const { taskPlanner: { tags = [] } } = this.props;
    return tags.length || !this.props.isLibraryPlanner;
  }

  get canShowPlannerOptions() {
    return window.config.features.taskTemplateLibrary && !this.props.singleLocation;
  }

  handleCreateNewTask = () => this.props.router.push(RouterConstants.NEW_TASK);

  render() {
    const filterSidebarClasses = classNames({
      'showtime-layout-admin-management__filters-sidebar': true,
      'is-visible': this.state.showMobileFilters,
    });

    const containerClasses = classNames({
      'showtime-layout-admin-management__container': true,
      'has-active-sidebar': this.state.showMobileFilters,
    });

    let { locations, timelineTemplates } = this.props;
    let handleManageTags = () => this.props.router.push(RouterConstants.TAG_MANAGEMENT);

    if (this.props.isLibraryPlanner) {
      locations = [];
      timelineTemplates = [];
      handleManageTags = null;
    }

    return (
      <div className='showtime-layout-admin-management' data-test='taskPlanner'>
        <TaskPlannerActionBar
          locations={ this.props.locations }
          onCreateNewTask={ this.handleCreateNewTask }
          singleLocation={ this.props.singleLocation }
          libraryTabs={ this.renderPlannerOptions() }
        />
        <div className={ containerClasses }>
          { getCurrentUserRole().isOrgAdminOrAbove() && (
            <div className='showtime-layout-admin-management__filters' data-test='planner.filters'>
              { this.canShowMobileFilterButton && this.renderFilterButton() }
              <div className={ filterSidebarClasses }>
                <TaskPlannerSidebar
                  onManageTags={ handleManageTags }
                  locations={ locations }
                  timelineTemplates={ timelineTemplates }
                  tags={ this.props.taskPlanner.tags }
                  filters={ this.props.taskPlanner.filters }
                  singleLocation={ this.props.singleLocation }
                  taskPlanner={ this.props.taskPlanner }
                  isLibraryPlanner={ this.props.isLibraryPlanner }
                />
              </div>
            </div>
          ) }
          <div className='showtime-layout-admin-management__listing'>
            { this.renderTaskPlannerTable() }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.loadTaskPlanner(this.props.organizationId);
      this.loadTags(this.props.organizationId);
    }
  }

  componentDidMount() {
    if (this.props.singleLocation) {
      this.props.actions.taskPlanner.getSingleLocationTaskPlan(this.props.singleLocationId);
    } else {
      this.loadTaskPlanner(this.props.organizationId);
    }

    this.loadTags(this.props.organizationId);

    if (!getCurrentUserRole().isOrgAdminOrAbove()) this.props.fetchLocations();
  }
}

TaskPlanner.propTypes = {
  locations: PropTypes.array,
  timelineTemplates: PropTypes.array,
  taskPlanner: PropTypes.object.isRequired,
  singleLocation: PropTypes.bool,
  singleLocationId: PropTypes.number,
  organizationId: PropTypes.number,
  template_libraries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  datePeriod: PropTypes.string,
  isLibraryPlanner: PropTypes.bool,
  previewTaskId: PropTypes.number,
  actions: PropTypes.shape({
    tags: PropTypes.object,
    taskPlanner: PropTypes.object,
  }).isRequired,
  getOrganizationUsers: PropTypes.func,
  fetchLocations: PropTypes.func.isRequired,
};

TaskPlanner.defaultProps = {
  organizationId: null,
  isLibraryPlanner: false,
  singleLocation: false,
  singleLocationId: 0,
  datePeriod: TrailConstants.datePeriod.TODAY,
  timelineTemplates: [],
  locations: [],
  template_libraries: [],
  previewTaskId: null,
  getOrganizationUsers() {},
};

const mapStateToProps = state => ({
  taskPlanner: TaskPlannerSelectors.taskPlannerSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    taskPlanner: bindActionCreators(TaskPlannerActions, dispatch),
    tags: bindActionCreators(TagActions, dispatch),
  },
  getOrganizationUsers: bindActionCreators(UsersActions, dispatch).fetchOrganizationUsers,
  fetchLocations: () => dispatch(fetchLocations()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TaskPlanner));
