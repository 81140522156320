import _ from 'lodash';
import cellValidationRulesFactory from '../cellValidationRulesFactory';
import getErrors from './getErrors';
import isWithinRange from './adapter/isWithinRange';
import isRequired from './adapter/isRequired';
import hasExceptionalOptionSelected from './adapter/hasExceptionalOptionSelected';
import requiresAction from './adapter/requiresAction';
import unconditionalRules from './unconditionalRules.js';
import pipe from './adapter/pipe';
import calculateVisibleFieldsByLogic from '../../calculateVisibleFieldsByLogic';

export default (columns, rows, intl, adapters = [
  isRequired,
  requiresAction,
  hasExceptionalOptionSelected,
  isWithinRange,
]) => rows.map((row) => {
  const rowForLogic = _.mapValues(row, fieldObject => fieldObject.value);
  const visibleRowsByLogic = calculateVisibleFieldsByLogic(rowForLogic, columns);
  const adaptedRow = columns.reduce((acc, {
    cellSettings,
  }) => {
    let rules;
    const { fieldName } = cellSettings;

    if (visibleRowsByLogic.includes(fieldName)) {
      const conditionalRule = cellValidationRulesFactory.build(cellSettings, _.mapValues(row, 'value'));
      const ruleConfig = {
        ...cellSettings,
        ...conditionalRule,
      };
      ({ rules } = pipe(...adapters)(ruleConfig, intl));
      rules = [...(rules || []), ...unconditionalRules(intl)];
    }

    return ({
      ...acc,
      [fieldName]: {
        rules,
        value: _.get(row[fieldName], 'value'),
        hasAction: _.get(row[fieldName], 'hasAction'),
      },
    });
  }, {});

  return Object.keys(adaptedRow).reduce((acc, fieldName) => {
    const { value, hasAction, rules } = adaptedRow[fieldName];
    return {
      ...acc,
      [fieldName]: rules ? getErrors(rules, value, hasAction) : [],
    };
  }, {});
});
