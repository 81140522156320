export const TOP = 'top';
export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const BOTTOM = 'bottom';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_RIGHT = 'bottom-right';

export default {
  [TOP]: 'hint--top',
  [TOP_LEFT]: 'hint--top-left',
  [TOP_RIGHT]: 'hint--top-right',
  [BOTTOM]: 'hint--bottom',
  [BOTTOM_LEFT]: 'hint--bottom-left',
  [BOTTOM_RIGHT]: 'hint--bottom-right',
};
