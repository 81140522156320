import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SchemaErrors from './SchemaErrors';
import { SchemaValidator } from './SchemaValidator';

export default class CodeMirrorSchemaValidation extends PureComponent {
  static propTypes = {
    codeMirrorEditor: PropTypes.object.isRequired,
    validator: PropTypes.instanceOf(SchemaValidator).isRequired,
  };

  state = { errors: null }

  componentDidMount() {
    this.props.codeMirrorEditor.on('change', (e) => {
      this.setState({ errors: this.props.validator.validate(e.getValue()) });
    });
  }

  static getDerivedStateFromProps(props) {
    return { errors: props.validator.validate(props.codeMirrorEditor.getValue()) };
  }

  render() {
    if (!this.state.errors || !this.state.errors.length) return null;

    return <SchemaErrors errors={ this.state.errors } />;
  }
}
