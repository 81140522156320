import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import {
  filterQueryKeys, filterTypes, queryStatuses, flaggingTasksFeatureFlaggedStatuses,
} from '../constants';

const queryKey = filterQueryKeys[filterTypes.STATUSES];

export const StatusesFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });
  const { formatMessage } = useIntl();
  const items = queryStatuses
    .filter(status => (
      (window.config.features.flaggingTasks || window.config.features.flaggableTaskTemplates)
      || !flaggingTasksFeatureFlaggedStatuses.includes(status)
    ))
    .map(
      status => (
        { id: status, name: formatMessage({ id: `taskReports.statuses.${status}` }) }
      )
    );
  return (
    <Filter
      { ...props }
      { ...{ queryIds, setQueryIds, clearQueryIds } }
      items={ items }
      hasLoaded
      showSearch={ false }
      filterType={ filterTypes.STATUSES }
    />
  );
};

StatusesFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default StatusesFilter;
