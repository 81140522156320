import { ErrorConstants } from '../constants';
import { TimeoutMiddlewareConstants } from '../../middleware/timeout';

export const ErrorActions = {
  clear(delayInMs) {
    if (delayInMs && delayInMs > 0) {
      return {
        type: TimeoutMiddlewareConstants.TIMEOUT,
        delayedAction: {
          type: ErrorConstants.CLEAR,
        },
        delayPeriodMs: delayInMs,
      };
    }

    return {
      type: ErrorConstants.CLEAR,
    };
  },
};
