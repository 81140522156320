import { UserRole, getCurrentUserRole } from '../../../../application/UserRole';

export default columnRestricted => (
  !columnRestricted || (
    getCurrentUserRole().isManagerOrAbove() &&
    !getCurrentUserRole().isPermissionDisabled(
      UserRole.permissionTypes.COMPLETE_RESTRICTED_WIDGET_FIELDS
    )
  )
);
