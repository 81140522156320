import { noticesReducer as notices } from './noticesReducer';
import { errorsReducer as errors } from './errorsReducer';

export { notices, errors };
export const initialState = {
  notices: {
    content: {
      value: window.prefetch.flash.notice || '',
    },
  },
  errors: {
    content: {
      value: window.prefetch.flash.alert || '',
    },
  },
};

export default { notices, errors };
