import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { compose, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import metricsPublisher, {
  TrailMetricsDirectory, MetricsPublisherConstants,
} from '../../../metrics';
import scrollToComponent from '../../../common/scrollToComponent';
import createWidgetComponent from '../../HoC';
import widgetDataListenerHoC from '../../HoC/widgetDataListener';
import { WidgetDataStore } from '../../../trail/store';
import { WidgetDataActions, TrailActions } from '../../../trail/actions';
import { DataCaptureActions } from '../../actions';
import { DataCaptureSelectors } from '../../selectors';
import { TrailConstants } from '../../../trail/constants';
import { calculateSkippedFieldsForColumnIndex } from '../../calculateVisibleFieldsByLogic';
import { widgetModes, VALIDATION_DELAY_MS, WhiteListedCellTypes } from './constants';
import DataCaptureTable from './DataCaptureTable';
import DataCaptureList from './DataCaptureList';
import WidgetModeToggle from './WidgetModeToggle';
import getVisibleRows from './getVisibleRows';
import RecordLogScore from './RecordLogScore';

export class DataCaptureWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRowId: 0,
      isNewListIndex: false,
      hasErrored: false,
    };

    this.setActiveRowId = this.setActiveRowId.bind(this);
    this.onCellEdit = this.onCellEdit.bind(this);
    this.onAddEmpty = this.onAddEmpty.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.widgetContainerRef = createRef();
    this.recordContainerRef = createRef();

    this.debouncedValidation = _.debounce(
      this.performColumnValidation,
      VALIDATION_DELAY_MS
    );
  }

  get activeRow() {
    const activeRowFromState = _.find(this.getVisibleRows(), { rowId: this.state.activeRowId });
    if (activeRowFromState) return activeRowFromState;

    const allRows = this.getDataCaptureRows().map((row, rowId) => ({ ...row, rowId }));
    const withoutDeleted = rows => rows.filter(row => !row.rowDeleted);

    const priorVisibleRows = withoutDeleted(allRows.slice(0, this.state.activeRowId));
    const priorVisibleRow = priorVisibleRows[priorVisibleRows.length - 1];
    if (priorVisibleRow) return priorVisibleRow;

    const nextVisibleRows = withoutDeleted(allRows.slice(this.state.activeRowId, allRows.length));
    const nextVisibleRow = nextVisibleRows[0] || {};
    return nextVisibleRow;
  }

  get activeRowId() {
    return this.activeRow.rowId;
  }

  performColumnValidation = (newData) => {
    const { rowId, fieldName } = newData;
    const { id: widgetId } = this.props;

    this.props.showFieldErrors({ rowId, fieldName, widgetId });
  };

  componentDidUpdate(prevProps, prevState) {
    const isNewActiveIndex = this.activeRowId !== prevState.activeRowId;

    if (prevState.isNewListIndex && !isNewActiveIndex) {
      this.setState({
        isNewListIndex: false,
      });
    }
  }

  componentDidCatch() {
    this.setState({ hasErrored: true });
  }

  render() {
    const containerClasses = classNames(
      this.props.widget.widgetFormClasses(''),
      'showtime-widget-v2__container'
    );

    if (this.state.hasErrored) {
      return this.props.widget.renderEmptyWidget(
        `widget-${this.props.id}`,
        this.getWidgetTitle()
      );
    }

    return (
      <div
        className={ containerClasses }
        name='widgetForm'
        ref={ this.widgetContainerRef }
      >
        { !this.props.hideHeader && (
          <div className='showtime-widget-v2__header'>
            <h3 className='showtime-widget-v2__title'>{ this.getWidgetTitle() }</h3>
            { this.displayNavElements()
              && (
              <WidgetModeToggle
                title='VIEW'
                id={ this.props.id }
                onChange={ this.setMode }
                value={ this.props.mode }
              />
              )}
          </div>
        ) }
        { !this.taskStatusIsComplete() && this.renderWidgetSettingsLabel() }
        <div ref={ this.recordContainerRef }>
          { this.viewForMode() }
        </div>
        <RecordLogScore
          columns={ this.getColumns() }
          rows={ this.getVisibleRows() }
          paddingTop={ this.props.mode === widgetModes.TABLE }
        />
      </div>
    );
  }

  renderWidgetSettingsLabel = () => {
    const {
      minimumRows,
      restrictRows,
      singleRecord,
    } = this.getSettings();

    if (singleRecord) return null;

    const { intl } = this.props;

    const settingsLabelText = restrictRows
      ? intl.formatMessage({ id: 'trail.widgets.data_capture.restrict_rows' })
      : intl.formatMessage({ id: 'trail.widgets.data_capture.minimum_rows' }, { minimumRows });

    return (minimumRows || restrictRows)
      ? (
        <div className='showtime-widget-v2__settings'>
          <p className='showtime-label showtime-label--block'>
            <span className='showtime-label__text'>{ settingsLabelText }</span>
          </p>
        </div>
      )
      : null;
  };

  taskStatusIsComplete() {
    return this.props.taskStatus === TrailConstants.taskStatus.DONE;
  }

  isSingleRecord() {
    const { singleRecord } = this.getSettings();
    return singleRecord;
  }

  displayNavElements() {
    return !this.isSingleRecord() && !this.taskStatusIsComplete();
  }

  getVisibleRows = () => getVisibleRows(this.getDataCaptureRows());

  viewForMode() {
    const {
      id, taskName, taskId, actions, readOnly, preview,
    } = this.props;
    const { restrictRows } = this.getSettings();

    const columns = this.getColumns();
    const minimumRows = this.getMinimumRowsOrDefault();
    const visibleRows = this.getVisibleRows();

    const listView = (
      <DataCaptureList
        activeRowId={ this.activeRowId }
        addEmptyRow={ this.onAddEmpty }
        columns={ columns }
        displayNavElements={ this.displayNavElements() }
        errors={ this.props.errors }
        isNewListIndex={ this.state.isNewListIndex }
        minimumRows={ minimumRows }
        onDeleteRow={ this.onDeleteRow }
        onEdit={ this.onCellEdit }
        restrictRows={ restrictRows }
        rows={ visibleRows }
        setActiveRowId={ this.setActiveRowId }
        setTableMode={ this.handleSetTableMode }
        widgetId={ id }
        readOnly={ readOnly }
        isTaskCompleted={ this.taskStatusIsComplete() }
        taskHeaderHeight={ this.props.taskHeaderHeight }
        hideTaskContentComments={ this.props.hideTaskContentComments }
        router={ this.props.router }
        routePrefix={ this.props.routePrefix }
        { ...{ taskName, taskId, preview } }
        widgetName={ this.getWidgetTitle() }
        actions={ actions }
        onTimerChange={ this.onTimerChange }
        timerSettings={ this.getPrototypeTimerSettings() }
        timers={ this.props.widget.custom('prototypeTimers') }
      />
    );

    const tableView = (
      <DataCaptureTable
        widgetId={ id }
        timelineTaskId={ taskId }
        columns={ columns }
        errors={ this.props.errors }
        rows={ visibleRows }
        setListMode={ this.setListMode }
        isTaskCompleted={ this.taskStatusIsComplete() }
        hideTaskContentComments={ this.props.hideTaskContentComments }
        router={ this.props.router }
        routePrefix={ this.props.routePrefix }
        timeZone={ this.props.timeZone }
        actions={ actions }
      />
    );

    return this.props.mode === widgetModes.LIST
      ? listView
      : tableView;
  }

  handleSetTableMode = () => this.setMode(widgetModes.TABLE);

  setListMode = (index) => {
    if (this.taskStatusIsComplete()) {
      return;
    }

    this.setState({
      activeRowId: index,
    });
    this.props.onModeChange({ mode: widgetModes.LIST, widgetId: this.props.id });

    this.scrollToWidgetContainer();
  };

  setMode = (mode) => {
    this.props.onModeChange({ mode, widgetId: this.props.id });
  };

  setActiveRowId(activeRowId) {
    const isNewListIndex = activeRowId !== this.activeRowId;

    this.props.showRowErrors({
      widgetId: this.props.id,
      rowId: this.activeRowId,
    });

    this.setState({
      activeRowId,
      isNewListIndex,
    });

    this.scrollToRecordContainer();
  }

  scrollToWidgetContainer() {
    if (this.widgetContainerRef) {
      scrollToComponent(this.widgetContainerRef.current);
    }
  }

  scrollToRecordContainer() {
    if (this.recordContainerRef) {
      scrollToComponent(this.recordContainerRef.current);
    }
  }

  getWidgetTitle() {
    return this.getSettings().widgetTitle;
  }

  getSettings() {
    return this.props.widget.widget().public_settings || {};
  }

  getPrototypeTimerSettings() {
    return this.getSettings().prototypeTimerSettings || {};
  }

  getColumns = () => {
    const columns = this.getSettings().columns || [];
    return columns.filter(c => WhiteListedCellTypes.includes(c.cellType));
  };

  getDataCaptureRows = () => {
    if (this.props.preview) {
      return new Array(this.getMinimumRowsOrDefault()).fill({});
    }

    const dataCaptureRows = this.props.widget.custom('dataCaptureRows');

    return dataCaptureRows && dataCaptureRows.length > 0 ? dataCaptureRows : [{}];
  };

  getRowWithNewColumnData = (newData) => {
    const dataCaptureRows = this.getDataCaptureRows();
    const dataCaptureRow = dataCaptureRows[newData.rowId] || {};
    const newDataKeyValue = {};
    newDataKeyValue[newData.fieldName] = newData.fieldValue;

    return _.extend({}, dataCaptureRow, newDataKeyValue);
  };

  onAddEmpty() {
    const customRows = this.props.widget.custom('dataCaptureRows') || [];
    const customRowsCount = customRows.length;

    WidgetDataActions.setCustomFieldRow(this.props.id, 'dataCaptureRows', customRowsCount, {});

    if (customRowsCount === 0) {
      WidgetDataActions.setCustomFieldRow(this.props.id, 'dataCaptureRows', 1, {});
    }

    this.setActiveRowId(customRows.length - 1);

    WidgetDataActions.queueAutosave(this.props.id);
  }

  getMinimumRowsOrDefault() {
    const { minimumRows } = this.getSettings();
    return (minimumRows && minimumRows > 0) ? minimumRows : 1;
  }

  onDeleteRow(rowId) {
    const actionTimelineTaskIds = _.chain(this.props.actions)
      .filter({ widget_row_index: rowId })
      .map('action_timeline_task_id').value();
    this.props.deleteActions({
      actionTimelineTaskIds,
      linkedTimelineTaskId: this.props.taskId,
    });
    WidgetDataActions.deleteCustomFieldRow(this.props.id, 'dataCaptureRows', rowId);
    WidgetDataActions.queueAutosave(this.props.id);
  }

  onTimerChange = (rowId, data) => {
    WidgetDataActions.setTimer(this.props.id, rowId, data);
    WidgetDataActions.queueAutosave(this.props.id);
  };

  editCell = (newData) => {
    const { rowId } = newData;
    WidgetDataActions.setCustomFieldRow(
      this.props.id, 'dataCaptureRows', rowId, this.getRowWithNewColumnData(newData)
    );
    WidgetDataActions.queueAutosave(this.props.id);

    this.debouncedValidation(newData);

    this.setState({ isNewListIndex: false });
  };

  handleSkippingFields = (fieldName, fieldValue) => {
    const columns = this.getColumns();
    const { intl: { formatMessage } } = this.props;
    const columnIndex = _.findIndex(columns, { cellSettings: { fieldName } });
    const activeRowAfterUpdate = { ...this.activeRow, [fieldName]: fieldValue };

    const fieldsSkippedBeforeEdit = calculateSkippedFieldsForColumnIndex(
      columns,
      columnIndex,
      this.activeRow
    );
    const fieldsSkippedByEdit = calculateSkippedFieldsForColumnIndex(
      columns,
      columnIndex,
      activeRowAfterUpdate
    );
    const editWillSkipMoreFields = _.difference(
      fieldsSkippedByEdit, fieldsSkippedBeforeEdit).length;

    if (!editWillSkipMoreFields) return true;

    const noDataToLose = _.chain(activeRowAfterUpdate)
      .pick(fieldsSkippedByEdit).values().every(_.isEmpty)
      .value();
    if (noDataToLose) return true;

    const lastSkippedFieldIndex = _.findIndex(
      columns, { cellSettings: { fieldName: _.last(fieldsSkippedByEdit) } });
    const jumpToField = _.get(columns, lastSkippedFieldIndex + 1);
    const jumpToTitle = jumpToField
      ? jumpToField.columnTitle
      : formatMessage({ id: 'editWidget.builder.optionsColumn.logic.then.jumpToEnd' });

    const editPrompt = formatMessage(
      { id: 'trail.widgets.data_capture.skipFieldsPrompt' }, { fieldName: jumpToTitle });
    // eslint-disable-next-line no-alert
    if (!window.confirm(editPrompt)) return false;

    this.props.clearSkippedFields(this.props.id, this.activeRowId, fieldsSkippedByEdit);
    this.props.clearSkippedFieldComments({
      taskId: this.props.taskId,
      widgetId: this.props.id,
      rowId: this.activeRowId,
      skippedFields: fieldsSkippedByEdit,
    });
    this.deleteSkippedActions(fieldsSkippedByEdit);

    return true;
  };

  deleteSkippedActions = (fieldsSkippedByEdit) => {
    const actionTimelineTaskIds = _.chain(this.props.actions)
      .filter(actionTask => (
        actionTask.widget_row_index === this.activeRowId &&
          fieldsSkippedByEdit.includes(actionTask.widget_field_name)
      )).map('action_timeline_task_id').value();

    this.props.deleteActions({
      actionTimelineTaskIds,
      linkedTimelineTaskId: this.props.taskId,
    });
  };

  onCellEdit = (newData) => {
    const { rowId, fieldName, fieldValue } = newData;

    if (!this.handleSkippingFields(fieldName, fieldValue)) return;

    const action = _.find(this.props.actions, {
      widget_row_index: rowId,
      widget_field_name: fieldName,
    });

    if (action) {
      const deletePrompt = this.props.intl.formatMessage({
        id: 'trail.widgets.data_capture.delete_action_for_single_widget',
      });

      // eslint-disable-next-line no-alert
      const deleteConfirmed = window.confirm(deletePrompt);

      metricsPublisher.recordMetric(
        TrailMetricsDirectory.page.Trail.ACTION_REMOVAL_CONFIRMATION,
        {
          actionRemoved: deleteConfirmed,
          removalType: MetricsPublisherConstants.actionRemovalType.FIELD_CHANGED,
        }
      );

      if (!deleteConfirmed) return;

      this.props.deleteAction({
        actionTimelineTaskId: action.action_timeline_task_id,
        linkedTimelineTaskId: this.props.taskId,
      });
    }
    this.editCell(newData);
  };
}

DataCaptureWidget.propTypes = {
  id: PropTypes.number,
  hideHeader: PropTypes.bool,
  hideTaskContentComments: PropTypes.bool,
  showAllErrors: PropTypes.bool,
  widget: PropTypes.object,
  preview: PropTypes.bool,
  taskStatus: PropTypes.oneOf(
    Object.values(TrailConstants.taskStatus)
  ).isRequired,
  taskName: PropTypes.string,
  taskId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  showFieldErrors: PropTypes.func,
  updateErrors: PropTypes.func,
  errors: PropTypes.array,
  onRowDeleted: PropTypes.func,
  showRowErrors: PropTypes.func,
  onModeChange: PropTypes.func,
  mode: PropTypes.oneOf(Object.values(widgetModes)).isRequired,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
  taskHeaderHeight: PropTypes.number,
  actions: PropTypes.array,
  deleteAction: PropTypes.func.isRequired,
  deleteActions: PropTypes.func.isRequired,
  clearSkippedFields: PropTypes.func.isRequired,
  clearSkippedFieldComments: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  timeZone: PropTypes.string,
};

DataCaptureWidget.defaultProps = {
  id: null,
  hideHeader: false,
  hideTaskContentComments: false,
  showAllErrors: false,
  widget: null,
  preview: false,
  showFieldErrors() {},
  updateErrors() {},
  errors: [],
  onRowDeleted() {},
  showRowErrors() {},
  onModeChange() {},
  routePrefix: '',
  taskHeaderHeight: 0,
  taskName: null,
  taskId: null,
  actions: [],
  readOnly: false,
  timeZone: undefined,
};

const createWidgetHoC = createWidgetComponent(WidgetDataStore);

const mapStoreToProps = (state, ownProps) => ({
  errors: DataCaptureSelectors.visibleErrorsSelector(
    state, { ...ownProps, widgetId: ownProps.id }, ownProps.id
  ),
  mode: DataCaptureSelectors.viewModeSelector(
    state, { ...ownProps, widgetId: ownProps.id }
  ),
});

const passIntlToActionCreators = (actionCreators, intl) => _.mapValues(
  actionCreators,
  callback => data => callback({ intl, ...data })
);

const mapDispatchToProps = (dispatch, { intl }) => {
  const {
    showFieldErrors, showRowErrors, setViewMode, clearSkippedFieldComments,
  } = passIntlToActionCreators(
    bindActionCreators(DataCaptureActions, dispatch),
    intl
  );

  return ({
    showFieldErrors,
    showRowErrors,
    clearSkippedFieldComments,
    onModeChange: setViewMode,
    deleteAction: TrailActions.deleteAction,
    deleteActions: TrailActions.deleteActions,
    clearSkippedFields: WidgetDataActions.clearRowFields,
  });
};

const hocMountOrder = [
  widgetDataListenerHoC,
  createWidgetHoC,
  injectIntl,
  connect(
    mapStoreToProps,
    mapDispatchToProps
  ),
];

export default compose(...hocMountOrder)(DataCaptureWidget);
