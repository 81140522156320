import { TimeSlotConfigurationStore } from '../store.js';

export const timeslotStoreShim = (timeslotFormElementSelector, changesMade, onSubmit) => {
  if (!timeslotFormElementSelector) {
    return;
  }

  const form = document.querySelector(timeslotFormElementSelector);

  const injectHiddenField = () => {
    const hiddenFieldName = 'TimeSlotConfigurationStore';
    let hiddenField = form.querySelector(`[name="${hiddenFieldName}"]`);
    if (!hiddenField) {
      hiddenField = document.createElement('input');
      Object.assign(hiddenField, {
        type: 'hidden',
        name: hiddenFieldName,
      });
      form.appendChild(hiddenField);
    }
    hiddenField.setAttribute('value', JSON.stringify(TimeSlotConfigurationStore.getSaveableTimeSlots()));
  };

  let submitting = false;
  form.addEventListener('submit', () => {
    onSubmit();
    injectHiddenField();
    submitting = true;
  });

  window.addEventListener('beforeunload', (event = window.event) => {
    if (!submitting && changesMade()) {
      event.preventDefault();
      const message = form.getAttribute('data-shim-unsaved-message');
      event.returnValue = message;
      return message;
    }
    return undefined;
  });
};
