import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

export class Table extends Component {
  buildHeaderField(header, index) {
    const { headerClass, columnClasses } = this.props;
    const classes = `showtime-table__header-cell ${headerClass} ${columnClasses[index] || ''}`;

    return (
      <th key={ index } className={ classes }>
        <span>{header}</span>
      </th>
    );
  }

  buildRowField(field, index) {
    const classes = `showtime-table__cell ${this.props.columnClasses[index] || ''}`;

    return (
      <td key={ index } className={ classes }>
        <span>{field}</span>
      </td>
    );
  }

  buildRow(row, index) {
    const className = 'showtime-table__row';

    return (
      <tr key={ index } className={ className }>
        {row.map((field, rowIndex) => this.buildRowField(field, rowIndex))}
      </tr>
    );
  }

  render() {
    const tableClassName = classNames(
      'showtime-table showtime-table--full-width',
      { 'showtime-table--sticky': this.props.stickyHeader }
    );
    return (
      <table className={ tableClassName }>
        <thead className='showtime-table__header'>
          <tr className='showtime-table__header-row'>
            {this.props.header.map((header, i) => this.buildHeaderField(header, i))}
          </tr>
        </thead>
        <tbody className='showtime-table__body'>
          {this.props.rows.map((row, i) => this.buildRow(row, i))}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  header: PropTypes.array,
  headerClass: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.array),
  columnClasses: PropTypes.array,
  stickyHeader: PropTypes.bool,
};

Table.defaultProps = {
  header: [],
  rows: [[]],
  columnClasses: [],
  stickyHeader: false,
  headerClass: '',
};

export default injectIntl(Table);
