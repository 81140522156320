import { RequestConstants, RequestActions } from '../../request';
import { getCurrentUserRole } from '../../application/UserRole';

import {
  SETUP_GUIDE_PROGRESS_URL,
  GET_SETUP_GUIDE_PROGRESS,
  SKIP_SETUP_GUIDE_PROGRESS,
} from '../constants';

export default {
  getSetupGuideProgress: () => {
    if (getCurrentUserRole().isOrgAdminOrAbove()) {
      return RequestActions.request({
        url: SETUP_GUIDE_PROGRESS_URL,
        key: GET_SETUP_GUIDE_PROGRESS,
        method: RequestConstants.GET,
      });
    }
    return {
      type: SKIP_SETUP_GUIDE_PROGRESS,
    };
  },
};
