import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxWrapper from './CheckBoxWrapper.jsx';
import SVGIcon from '../SVGIcon.jsx';

const CheckBox = props => (
  <CheckBoxWrapper
    { ...props }
  >
    <SVGIcon
      classes='showtime-checkbox__tick-icon'
      iconName='mini/ui/ui-16px-1_check-CUSTOM'
    />
  </CheckBoxWrapper>
);

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
