import React from 'react';

import { columnPropTypes } from '../constants';

const Colgroups = ({ columns }) => (
  <colgroup>
    <col span='1' className='col-xs' />
    {
      columns.map(({
        columnStyle,
        columnTitle,
      }) => <col key={ columnTitle } span='1' className={ columnStyle } />)
    }
    <col span='1' className='col-xs' />
  </colgroup>
);

Colgroups.propTypes = {
  ...columnPropTypes,
};

export default Colgroups;
