import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon from '../../common/SVGIcon';

export const FileDeleteButton = ({ onClick }) => (
  <a
    className='showtime-file-preview__button'
    data-test='delete.preview'
    onClick={ onClick }
  >
    <SVGIcon
      iconName='mini/ui/ui-16px-1_trash-simple'
      classes='showtime-file-preview__button-icon'
    />
  </a>
);

FileDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FileDeleteButton;
