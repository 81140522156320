import { AttachmentConstants } from './constants';

export const formatAttachmentFromFilestackResponse = ({
  filename, url, type, size, key,
}) => {
  const attachment = {
    name: filename,
    previousName: filename,
    fileName: filename,
    url,
    filestackHandle: url.split('/').pop(),
    mimeType: type,
    fileSize: size,
    s3Key: key,
    type: AttachmentConstants.FILE_TYPE,
  };

  return attachment;
};

export const formatAttachmentFromFileObject = file => ({
  name: file.name,
  fileName: file.name,
  url: null,
  filestackHandle: null,
  mimeType: file.type,
  fileSize: file.size,
  s3Key: null,
  type: AttachmentConstants.FILE_TYPE,
});
