import _ from 'lodash';
import { RequestConstants } from '../../request';

import {
  FETCH_TASK_REPORTS_KEY,
  FETCH_TASK_REPORT_CONTENTS_KEY,
  FETCH_TASK_SUBTASKS_KEY,
  FETCH_TASK_TEMPLATES_KEY,
  CLEAR_TASKS,
  TOGGLE_VIEW_FILTERS,
  FETCH_TASK_REPORTS_REQUEST_BATCH_COMPLETE,
  FETCH_COUNT_TASK_REPORTS_KEY,
  SET_EXPORT_MODE,
  SET_SELECTED_TASK_IDS,
} from '../constants';

import { requestStatuses } from '../../common/requestStatuses';

const INITIAL_STATE = {
  timelineTasks: {
    entities: [],
    requestStatus: requestStatuses.NOT_REQUESTED,
    pageAfterCursor: null,
  },
  timelineTaskSubtasks: {
    entities: {},
    requestStatus: requestStatuses.NOT_REQUESTED,
  },
  taskTemplates: {
    entities: {},
    requestStatus: requestStatuses.NOT_REQUESTED,
  },
  timelineTaskCount: {
    value: null,
    requestStatus: requestStatuses.NOT_REQUESTED,
  },
  viewFilters: false,
  exportMode: false,
  selectedTaskIds: [],
};

const timelineTasksLoaded = RequestConstants.getLoadedActionType(FETCH_TASK_REPORTS_KEY);
const loadTimelineTasks = (state, action) => {
  const existingTaskIds = _.map(state.timelineTasks.entities, 'id');
  return ({
    ...state,
    timelineTasks: {
      requestStatus: requestStatuses.LOADED,
      pageAfterCursor: action.content.page.after || null,
      windowingDate: action.content.page.windowing_date || null,
      entities: [
        ...state.timelineTasks.entities,
        ..._.filter(action.content.data, ({ id }) => !existingTaskIds.includes(id)),
      ],
    },
  });
};

const setTimelineTasksRequestStatusLoaded = state => ({
  ...state,
  timelineTasks: {
    ...state.timelineTasks,
    requestStatus: requestStatuses.LOADED,
  },
});

const timelineTaskContentLoaded = RequestConstants.getLoadedActionType(
  FETCH_TASK_REPORT_CONTENTS_KEY
);
const loadTimelineTaskContent = (state, action) => ({
  ...state,
  timelineTasks: {
    ...state.timelineTasks,
    entities: state.timelineTasks.entities.map(task => ({
      ...task,
      comments: _.get(
        action,
        ['content', 'data', task.id, 'comments'],
        task.comments || []
      ),
    })),
  },
});

const timelineTasksLoading = RequestConstants.getLoadingActionType(FETCH_TASK_REPORTS_KEY);
const setTimelineTasksLoading = state => ({
  ...state,
  timelineTasks: {
    ...state.timelineTasks,
    requestStatus: requestStatuses.LOADING,
  },
});

const timelineTasksError = RequestConstants.getErrorActionType(FETCH_TASK_REPORTS_KEY);
const setTimelineTasksError = state => ({
  ...state,
  timelineTasks: {
    ...state.timelineTasks,
    requestStatus: requestStatuses.ERROR,
  },
});

const taskSubtasksLoaded = RequestConstants.getLoadedActionType(FETCH_TASK_SUBTASKS_KEY);
const loadTimelineTaskSubtasks = (state, action) => ({
  ...state,
  timelineTaskSubtasks: {
    requestStatus: requestStatuses.LOADED,
    entities: {
      ...state.timelineTaskSubtasks.entities,
      ..._.groupBy(action.content.data, 'timeline_task_id'),
    },
  },
});

const taskSubtasksError = RequestConstants.getErrorActionType(FETCH_TASK_SUBTASKS_KEY);
const setTimelineTaskSubtasksError = state => ({
  ...state,
  timelineTaskSubtasks: {
    ...state.timelineTaskSubtasks,
    requestStatus: requestStatuses.ERROR,
  },
});

const taskSubtasksLoading = RequestConstants.getLoadingActionType(FETCH_TASK_SUBTASKS_KEY);
const setTimelineTaskSubtasksLoading = state => ({
  ...state,
  timelineTaskSubtasks: {
    ...state.timelineTaskSubtasks,
    requestStatus: requestStatuses.LOADING,
  },
});

const taskTemplatesLoading = RequestConstants.getLoadingActionType(FETCH_TASK_TEMPLATES_KEY);
const setTaskTemplatesLoading = state => ({
  ...state,
  taskTemplates: {
    ...state.taskTemplates,
    requestStatus: requestStatuses.LOADING,
  },
});

const taskTemplatesError = RequestConstants.getErrorActionType(FETCH_TASK_TEMPLATES_KEY);
const setTaskTemplatesError = state => ({
  ...state,
  taskTemplates: {
    ...state.taskTemplates,
    requestStatus: requestStatuses.ERROR,
  },
});

const taskTemplatesLoaded = RequestConstants.getLoadedActionType(FETCH_TASK_TEMPLATES_KEY);
const setTaskTemplatesLoaded = (state, action) => ({
  ...state,
  taskTemplates: {
    entities: action.content,
    requestStatus: requestStatuses.LOADED,
  },
});

const clearTimelineTasks = state => ({
  ...state,
  timelineTasks: INITIAL_STATE.timelineTasks,
});

const toggleViewFilters = state => ({
  ...state,
  viewFilters: !state.viewFilters,
});

const timelineTasksCountLoading =
  RequestConstants.getLoadingActionType(FETCH_COUNT_TASK_REPORTS_KEY);
const setTimelineTaskCountLoading = (state, action) => ({
  ...state,
  timelineTaskCount: {
    value: null,
    maxTaskCount: null,
    requestStatus: requestStatuses.LOADING,
    requestId: action.content.requestId,
  },
});

const timelineTasksCountLoaded = RequestConstants.getLoadedActionType(FETCH_COUNT_TASK_REPORTS_KEY);
const setTimelineTaskCountLoaded = (state, action) => {
  if (action.content.requestId !== state.timelineTaskCount.requestId) return state;

  return ({
    ...state,
    timelineTaskCount: {
      value: action.content.data.task_count,
      maxTaskCount: action.content.data.max_task_count,
      requestStatus: requestStatuses.LOADED,
    },
  });
};

const timelineTasksCountError = RequestConstants.getErrorActionType(FETCH_COUNT_TASK_REPORTS_KEY);
const setTimelineTaskCountError = state => ({
  ...state,
  timelineTaskCount: {
    value: null,
    requestStatus: requestStatuses.ERROR,
  },
});

const setExportMode = (state, action) => ({
  ...state,
  exportMode: action.value,
});

const setSelectedTaskIds = (state, action) => ({
  ...state,
  selectedTaskIds: action.value,
});

const actionCases = {
  [timelineTasksCountLoading]: setTimelineTaskCountLoading,
  [timelineTasksCountLoaded]: setTimelineTaskCountLoaded,
  [timelineTasksCountError]: setTimelineTaskCountError,
  [timelineTaskContentLoaded]: loadTimelineTaskContent,
  [timelineTasksLoaded]: loadTimelineTasks,
  [timelineTasksLoading]: setTimelineTasksLoading,
  [timelineTasksError]: setTimelineTasksError,
  [CLEAR_TASKS]: clearTimelineTasks,
  [SET_EXPORT_MODE]: setExportMode,
  [SET_SELECTED_TASK_IDS]: setSelectedTaskIds,
  [taskSubtasksLoaded]: loadTimelineTaskSubtasks,
  [taskSubtasksError]: setTimelineTaskSubtasksError,
  [taskSubtasksLoading]: setTimelineTaskSubtasksLoading,
  [taskTemplatesLoading]: setTaskTemplatesLoading,
  [taskTemplatesLoaded]: setTaskTemplatesLoaded,
  [taskTemplatesError]: setTaskTemplatesError,
  [TOGGLE_VIEW_FILTERS]: toggleViewFilters,
  [FETCH_TASK_REPORTS_REQUEST_BATCH_COMPLETE]: setTimelineTasksRequestStatusLoaded,
};

export const taskReports = (state, action) => {
  state = state || INITIAL_STATE;
  if (actionCases[action.type]) return actionCases[action.type](state, action);

  return state;
};
