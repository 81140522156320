import { ALL } from '../constants';

export const removeInvalidLocationsFromQueryIds = (queryIds, locations) => {
  if (queryIds.includes(ALL)) return queryIds;
  const locationIds = locations.map(({ id }) => id);
  return queryIds.filter(queryId => locationIds.includes(queryId));
};

export const removeInvalidLocationsFromAreas = (areas, locations) => {
  const locationIds = locations.map(({ id }) => id);
  return areas.map((area) => {
    if (area.locations) {
      return {
        ...area,
        locations: area.locations
          .filter(areaLocationId => locationIds.includes(areaLocationId)),
      };
    }
    return area;
  });
};
