import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import autosize from 'autosize';

import TextArea from './TextArea';
import Label from './Label';

import { analyzeErrors } from '../constants/validation';
import ValidationMessages from './ValidationMessages';
import {
  CopyValueToNewLabel,
  LockedLabel,
} from './AnnotationLabels';

class TextAreaField extends Component {
  componentDidMount() {
    if (this.props.autoExpand) {
      autosize(this.textarea);
    }
  }

  componentWillUnmount() {
    if (this.props.autoExpand) {
      autosize.destroy(this.textarea);
    }
  }

  render() {
    const {
      containerClass,
      disabled,
      readOnly,
      errors,
      id,
      label,
      locked,
      maxLength,
      name,
      onPaste,
      onChange,
      onBlur,
      required,
      value,
      refFn,
      placeholder,
      repeating,
      rows,
      dataTest,
      children,
    } = this.props;
    const isEmpty = !value;
    const {
      isValid,
      anyValidationErrors,
      allValidationWarnings,
    } = analyzeErrors(errors);
    const containerClasses = classNames(
      containerClass, {
        'is-empty': disabled && isEmpty,
        'is-invalid': anyValidationErrors,
        'is-warning': allValidationWarnings,
        'is-restricted': disabled && !isEmpty,
        'is-disabled': readOnly,
      }
    );
    const textAreaFieldRefFn = (textarea) => {
      this.textarea = textarea;
      if (typeof refFn === 'function') {
        refFn(textarea);
      } else if (refFn) {
        refFn.current = textarea;
      }
    };

    return (
      <div className={ containerClasses }>
        {label ? <Label htmlFor={ id } required={ required }>{label}</Label> : null}
        <div className='showtime-textarea'>
          <TextArea
            rows={ rows }
            disabled={ disabled || readOnly }
            id={ id }
            name={ name }
            maxLength={ maxLength }
            onPaste={ onPaste }
            onChange={ onChange }
            onBlur={ onBlur }
            required={ required }
            value={ value }
            placeholder={ placeholder }
            refFn={ textAreaFieldRefFn }
            dataTest={ dataTest }
          />
          { children }
        </div>
        {!isValid && (
          <ValidationMessages
            fieldId={ id }
            errors={ errors }
            someErrors={ anyValidationErrors }
          />
        )}
        {locked &&
          <LockedLabel />}
        {repeating && isValid &&
          <CopyValueToNewLabel />}
      </div>
    );
  }
}

TextAreaField.propTypes = {
  autoExpand: PropTypes.bool,
  rows: PropTypes.string,
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.string
  ),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  locked: PropTypes.bool,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  onPaste: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  refFn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  repeating: PropTypes.bool,
  placeholder: PropTypes.string,
  dataTest: PropTypes.string,
  children: PropTypes.node,
};

TextAreaField.defaultProps = {
  autoExpand: false,
  rows: '',
  containerClass: '',
  disabled: false,
  readOnly: false,
  errors: [],
  label: null,
  locked: false,
  name: undefined,
  maxLength: null,
  required: false,
  repeating: false,
  value: undefined,
  refFn: null,
  placeholder: '',
  onPaste: null,
  onBlur: () => {},
  dataTest: undefined,
  children: null,
};

export default TextAreaField;
