import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import CheckBox from '../common/forms/CheckBox';
import SVGIcon from '../common/SVGIcon.jsx';
import Button from '../common/Button.jsx';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import OfflineInteractionBlocker from '../offline-interaction-blocker';

export class SubtaskInput extends PureComponent {
  state = {
    isFocused: false,
    isRecurringChecked: false,
    inputValue: '',
  };
  input = createRef();
  node = createRef();
  saveButton = createRef();


  handleKeyInput = (e) => {
    if (e.key !== 'Enter') return;
    if (!this.state.inputValue) return;
    e.preventDefault();
    this.handleInputSave();
  }

  componentDidMount() {
    const { isFocused } = this.props;
    if (isFocused) { this.onFocus(); }
  }

  handleClickOutside() {
    this.onBlur();
  }

  onBlur = () => {
    this.setState({ isFocused: false });
  }

  onFocus = () => {
    this.setState({ isFocused: true }, () => {
      this.input.current.focus();
      metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.SUBTASK_INPUT_SELECTED);
    });
  }

  handleSetRecurring = () => {
    this.setState(prevState => ({
      isRecurringChecked: !prevState.isRecurringChecked,
    }));
  }

  handleAddItemChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputClear = () => this.setState({ inputValue: '' });

  handleInputSave = () => {
    const { onSave, taskId } = this.props;
    const { inputValue } = this.state;
    if (!inputValue) return;

    onSave(taskId, inputValue, this.state.isRecurringChecked);
    this.onFocus();
    this.handleInputClear();
  };

  render() {
    const { inputValue } = this.state;

    const addButtonClasses = classNames({
      'showtime-button': true,
      'showtime-button--highlight-alt': true,
      'is-disabled': !inputValue,
    });

    const rootClasses = classNames({
      'showtime-task__subtasks-add': true,
      'is-focused': this.state.isFocused,
    });

    return (
      <div className={ rootClasses }>
        <div className='showtime-task__subtasks-add-input'>
          <div
            className='showtime-input showtime-input--block showtime-input--add'
          >
            <OfflineInteractionBlocker
              tooltipMessage={ this.props.intl.formatMessage({ id: 'offline.notAvailable' }) }
            >
              <input
                type='text'
                name='task-add-item'
                placeholder={ this.props.intl.formatMessage({ id: 'trail.subtasks.input.addItem' }) }
                data-test='subtask.input'
                value={ inputValue }
                onChange={ this.handleAddItemChange }
                ref={ this.input }
                onKeyDown={ this.handleKeyInput }
                onFocus={ this.onFocus }
              />
            </OfflineInteractionBlocker>
            {
              inputValue ?
                <a
                  onClick={ this.handleInputClear }
                  data-test='subtask.input.clear-search'
                  className='showtime-link showtime-link--alternate'
                >
                  <SVGIcon
                    classes='showtime-link__icon showtime-link__icon--left'
                    iconName='mini/ui/ui-16px-1_simple-remove'
                  />
                </a>
                : null
            }
          </div>
        </div>

        <div
          className='showtime-task__subtasks-add-save'
          data-test='subtask.input.button'
        >
          <Button
            onClick={ this.handleInputSave }
            buttonClasses={ addButtonClasses }
            label={ this.props.intl.formatMessage({ id: 'trail.subtasks.input.save' }) }
          />
        </div>
        { this.props.canSetRecurring &&
          <div
            className='showtime-task__subtasks-add-repeat'
          >
            <CheckBox
              checkboxClasses='showtime-checkbox--small'
              label='Repeat every time'
              checked={ this.state.isRecurringChecked }
              onChange={ this.handleSetRecurring }
              dataTest='subtask.recurring.checkbox'
            />
          </div>
        }
      </div>
    );
  }
}

SubtaskInput.propTypes = {
  taskId: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  canSetRecurring: PropTypes.bool,
  isFocused: PropTypes.bool,
};

SubtaskInput.defaultProps = {
  canSetRecurring: false,
  isFocused: false,
  onSave() {},
};

export default injectIntl(onClickOutside(SubtaskInput));
