import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import PDFTask from './PDFTask';

const PDFTasks = ({
  tasks,
  widgetsByTaskId,
  widgetErrorsByTaskId,
  taskContentCommentsByTaskId,
  subtasksByTaskId,
  displayComments,
  displaySubtasks,
  displayWidgets,
  intl,
}) => (
  <View>
    { tasks.map(task => (
      <PDFTask
        {
          ...{
            task,
            key: task.id,
            intl,
            widget: displayWidgets && widgetsByTaskId[task.id],
            widgetErrors: displayWidgets && widgetErrorsByTaskId[task.id],
            subtasks: displaySubtasks && subtasksByTaskId[task.id],
            comments: displayComments && task.comments,
            taskContentComments: displayComments && (taskContentCommentsByTaskId[task.id] || []),
          }
        }
      />
    )
    ) }
  </View>
);

PDFTasks.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  widgetsByTaskId: PropTypes.object.isRequired,
  widgetErrorsByTaskId: PropTypes.object.isRequired,
  taskContentCommentsByTaskId: PropTypes.object.isRequired,
  subtasksByTaskId: PropTypes.object.isRequired,
  displayComments: PropTypes.bool.isRequired,
  displaySubtasks: PropTypes.bool.isRequired,
  displayWidgets: PropTypes.bool.isRequired,
};

export default PDFTasks;
