import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Shortcut = ({
  children, onClick, isSelected,
}) => {
  const className = classNames('showtime-shortcuts__link', { 'is-selected': isSelected });
  return (
    <a onClick={ onClick } className={ className }>
      <span className='showtime-shortcuts__link-text'>{children}</span>
    </a>
  );
};

Shortcut.propTypes = {
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Shortcut.defaultProps = {
  isSelected: false,
  children: null,
  onClick() {},
};

export default Shortcut;
