import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WindowHelper from '../../utils/window';

export const FileTextAreaWrapper = ({
  children, isFocused, hasError, dataTest, stacked,
}) => {
  const {
    capture: captureSupported,
  } = WindowHelper.browserSupport();

  const inlineCommentClasses = classNames(
    'showtime-conversation__comment-box-inline',
    {
      'showtime-conversation__comment-box-inline--file-only': !captureSupported,
      'showtime-conversation__comment-box-inline--stacked': stacked,
    }
  );

  const inlineCommentInnerClasses = classNames(
    'showtime-conversation__comment-box-inline-inner',
    {
      'is-focused': isFocused,
      'has-error': hasError,
    }
  );

  return (
    <div className={ inlineCommentClasses } data-test={ dataTest }>
      <div className={ inlineCommentInnerClasses }>
        { children }
      </div>
    </div>
  );
};

FileTextAreaWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool,
  hasError: PropTypes.bool,
  dataTest: PropTypes.string,
  stacked: PropTypes.bool,
};

FileTextAreaWrapper.defaultProps = {
  isFocused: false,
  hasError: false,
  dataTest: undefined,
  stacked: false,
};

export default FileTextAreaWrapper;
