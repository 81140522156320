export const TaskCheckListConstants = {
  CHANGE: 'task_check_list.change',
  ADD_ITEM: 'task_check_list.add_item',
  REMOVE_ITEM: 'task_check_list.remove_item',
  SORT_ITEMS: 'task_check_list.sort_items',
  CHANGE_ITEM_NAME: 'task_check_list.change_item_name',
  PASTE_INTO_ITEM_NAME: 'task_check_list.paste_into_item_name',
  EXPAND_ITEM: 'task_check_list.expand_item',
  UNEXPAND_ITEM: 'task_check_list.unexpand_item',
  CONVERT_ITEM: 'task_check_list.convert_item',
  CHANGE_IS_MANDATORY: 'task_check_list.change_is_mandatory',
  EXPAND: 'task_check_list.expand',
  TYPE: {
    ITEM: 'item',
    GROUP: 'group',
  },
  ITEM_PREFIXES: {
    NEW: 'new-',
    GROUP: 'group-',
  },
};
