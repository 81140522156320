import _ from 'lodash';
import EventEmitter from 'wolfy87-eventemitter';
import AppDispatcher from '../../application/dispatcher';
import ApplicationConstant from '../../application/constants';
import { AddTaskToTrailConstants } from '../constants';

const AddTaskToTrailStore = function AddTaskToTrailStore() {
  if (AddTaskToTrailStore.instance) {
    return AddTaskToTrailStore.instance;
  }

  this._taskTemplates = [];
  this._selectedTaskTemplateId = null;
  this._isLoadingTaskTemplates = true;

  AppDispatcher.register(this._onActionDispatched.bind(this));

  AddTaskToTrailStore.instance = this;
};

_.extend(AddTaskToTrailStore.prototype, EventEmitter.prototype, {
  _taskTemplates: [],
  _selectedTaskTemplateId: null,
  _isLoadingTaskTemplates: true,

  _onActionDispatched(payload) {
    const action = payload.actionType;

    switch (action) {
      case AddTaskToTrailConstants.TASK_TEMPLATES_FETCHED:
        this._taskTemplates = payload.taskTemplates || [];
        this._isLoadingTaskTemplates = false;
        this.emitChange();
        break;

      case AddTaskToTrailConstants.ADD_TASK_STARTED:
        this._selectedTaskTemplateId = payload.taskTemplateId;
        this.emitChange();
        break;

      case AddTaskToTrailConstants.ADD_TASK_FAILED:
        this._selectedTaskTemplateId = null;
        this.emitChange();
        break;
      default:
        break;
    }
  },

  getTaskTemplates() {
    return this._taskTemplates;
  },

  getSelectedTaskTemplateId() {
    return this._selectedTaskTemplateId;
  },

  isLoadingTaskTemplates() {
    return this._isLoadingTaskTemplates;
  },

  addChangeListener(callback) {
    this.on(ApplicationConstant.CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(ApplicationConstant.CHANGE_EVENT, callback);
  },

  emitChange() {
    this.emit(ApplicationConstant.CHANGE_EVENT);
  },
});

export { AddTaskToTrailStore };
