import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AddLogicJumpButton from './AddLogicJumpButton';
import LogicJumps from './LogicJumps';

const OptionsLogicSelection = ({ columnIndex }) => {
  if (!_.get(window.config, 'features.logicJumps', false)) {
    return null;
  }

  return (
    <div data-test='logic'>
      <AddLogicJumpButton columnIndex={ columnIndex } />
      <LogicJumps columnIndex={ columnIndex } />
    </div>
  );
};
OptionsLogicSelection.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default OptionsLogicSelection;
