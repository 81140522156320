import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import PDFSubtask from './PDFSubtask';
import { isValidSubtaskGroupName } from '../../common/subtaskValidator';

const Subtasks = ({ subtasks, comments, intl }) => (
  <React.Fragment>
    {
      _.chain(subtasks).orderBy('key').map(subtask => (
        <PDFSubtask
          key={ `subtask-${subtask.timelineTaskId}-${subtask.key}` }
          subtask={ subtask }
          comments={ _.filter(comments, { commentable_id: subtask.id }) }
          intl={ intl }
          timeZone={ subtask.locationTimeZone }
        />
      )).value()
    }
  </React.Fragment>
);

Subtasks.propTypes = {
  subtasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const withoutAddedSubtasks = ({ addedByUserId }) => !addedByUserId;

const SubtaskGroup = ({
  subtasks, groupName, comments, intl,
}) => (
  <React.Fragment>
    { isValidSubtaskGroupName(groupName) && <Text is='span'>{ groupName }</Text> }
    <Subtasks { ...{ subtasks, comments, intl } } />
  </React.Fragment>
);

SubtaskGroup.propTypes = {
  subtasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupName: PropTypes.string.isRequired,
};

const SubtaskSpacer = ({ intl }) => (
  <View
    style={ {
      flexDirection: 'row',
      borderBottomColor: '#f6f6f6',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      padding: '5 0',
    } }
  >
    <Text is='span' style={ { width: '33.33%' } }>{intl.formatMessage({ id: 'taskReports.taskSubtasks.spacer' })}</Text>
    <Text is='span' style={ { width: '33.33%' } }>{intl.formatMessage({ id: 'taskReports.taskSubtasks.spacer' })}</Text>
    <Text is='span' style={ { width: '33.33%' } }>{intl.formatMessage({ id: 'taskReports.taskSubtasks.spacer' })}</Text>
  </View>
);

const PDFTaskSubtasks = ({ intl, subtasks, comments }) => {
  const groups = _.chain(subtasks).filter(withoutAddedSubtasks).groupBy('groupName').value();
  const added = _.filter(subtasks, 'addedByUserId');

  return (
    <View
      style={ {
        fontSize: 10,
      } }
      is='div'
    >
      <Text
        is='span'
        style={ {
          color: '#92929b',
          borderBottomColor: '#f6f6f6',
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          marginTop: 15,
          paddingBottom: 5,
          fontWeight: 600,
        } }
      >
        {intl.formatMessage({ id: 'taskReports.taskSubtasks.nameHeader' })}
      </Text>
      { _.map(groups, (groupSubtasks, groupName) => (
        <SubtaskGroup subtasks={ groupSubtasks } { ...{ groupName, comments, intl } } />
      )) }
      {added.length > 0 && <SubtaskSpacer intl={ intl } />}
      <Subtasks comments={ comments } subtasks={ added } intl={ intl } />
    </View>
  );
};

PDFTaskSubtasks.propTypes = {
  subtasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PDFTaskSubtasks;
