import _ from 'lodash';
import { createSelector } from 'reselect';
import { removeInvalidLocationsFromAreas } from '../../taskReports/Filters/updatedQueryIdsForSites';
import { LocationConstants } from '../constants';

export const locationsByIdSelector = state => state.sites.data.locations;

export const locationsAndGroupsSelector = state => state.sites.data;

const locationsFromIdsSelector = (state, { locationIds }) => {
  const locations = locationsByIdSelector(state);
  return _.chain(locations).pick(locationIds).values().value();
};

export const locationFromIdSelector = (state, locationId) => (
  locationsFromIdsSelector(state, { locationIds: [locationId] })[0]
);

const groupsSelectorWithoutBlankGroup = state => _.values(state.sites.data.groups) || [];

export const groupsSelector = (state) => {
  const groups = groupsSelectorWithoutBlankGroup(state);
  const blankArea = { name: '' };
  return [blankArea, ...groups];
};

const locationIdsSelector = (state) => {
  const locationsById = locationsByIdSelector(state);
  return _.keys(locationsById).map(id => parseInt(id, 10));
};

const locationIdsInGroupsSelector = (state) => {
  const groups = groupsSelector(state);
  return _.chain(groups)
    .map('locations')
    .flatten()
    .value();
};

const orphanLocationIdsSelector = (state) => {
  const locationIds = locationIdsSelector(state);
  const locationIdsInGroups = locationIdsInGroupsSelector(state);

  return _.difference(locationIds, locationIdsInGroups);
};

const isArchivedLocation = ({ status }) => status === LocationConstants.STATUSES.archived;

const sortLocationsByName = locations => _.sortBy(locations, ({ name }) => name.toLowerCase());

export const locationsWithArchivedSeparatedSelector = (state) => {
  const locationsById = locationsByIdSelector(state);
  const locations = Object.values(locationsById);
  const {
    true: archivedLocations = [],
    false: notArchivedLocations = [],
  } = _.groupBy(locations, isArchivedLocation);

  return _.mapValues(
    { archivedLocations, notArchivedLocations },
    sortLocationsByName
  );
};

export const locationsWithoutDemoSelector = createSelector(
  locationsWithArchivedSeparatedSelector,
  ({
    archivedLocations, notArchivedLocations,
  }) => [...notArchivedLocations, ...archivedLocations]
    .filter(({ status }) => status !== LocationConstants.STATUSES.demo)
);

const getGroupNameForLocation = (location, groups) => {
  const foundGroup = groups
    .filter(group => group.locations)
    .find(group => group.locations.includes(location.id));
  if (foundGroup) return foundGroup.name;
  return '';
};

export const locationsWithoutDemoWithGroupSelector = createSelector(
  locationsWithoutDemoSelector,
  groupsSelectorWithoutBlankGroup,
  (locations, areas) => {
    const areasWithoutInvalidLocations = removeInvalidLocationsFromAreas(areas, locations);

    const locationWithAreaNames = locations
      .map(location => ({
        ...location,
        group: getGroupNameForLocation(location, areasWithoutInvalidLocations),
      }));
    return { areas: areasWithoutInvalidLocations, locations: locationWithAreaNames };
  }
);

export const locationsByGroupSelector = (state) => {
  const locationIds = locationIdsSelector(state);

  if (!locationIds.length) {
    return [];
  }

  const groups = groupsSelector(state);

  const orphanLocationIds = orphanLocationIdsSelector(state);

  const orphanLocations = locationsFromIdsSelector(state, { locationIds: orphanLocationIds });

  const locationsWithoutAGroup = {
    id: null,
    name: '',
    locations: orphanLocations.map(location => ({ ...location, groupName: '' })),
    allowFilterBy: false,
  };

  const locationsByGroup = groups.map((group) => {
    const groupLocations = locationsFromIdsSelector(state, { locationIds: group.locations });

    return {
      id: group.id,
      name: group.name,
      locations: groupLocations.map(location => ({ ...location, groupName: group.name })),
      allowFilterBy: group.name !== '',
    };
  });

  return [locationsWithoutAGroup, ...locationsByGroup];
};

export const locationsHaveLoadedSelector = state => state.sites.loaded;

export const setLiveSites = (search) => {
  let setLive;
  search === LocationConstants.SET_LIVE_SITE ? setLive = true : setLive = false;

  return setLive;
};

export const getLocationIdsFromTimelineTemplateIds = (
  { locations, groups }, timelineTemplateIds
) => _.chain(timelineTemplateIds)
  .reduce((accu, timelineTemplateId) => {
    const group = groups[timelineTemplateId];
    if (group) return [...accu, ...group.locations];

    const locationId = _.chain(locations)
      .values()
      .find({ timelineTemplateId })
      .get('id')
      .value();

    if (!locationId) return accu;
    return [...accu, locationId];
  }, [])
  .uniq().value();
