import React, { Component } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnchoredMenu from './AnchoredMenu';
import Button from '../Button';
import metricsPublisher from '../../metrics';

const ConfirmationContent = ({
  title,
  description,
  children,
  onAbort,
  abort,
  confirm,
  onConfirm,
  confirmMetric,
  abortMetric,
  isDestructive,
  isPositive,
  confirmIsDisabled,
}) => {
  const baseButtonClasses = classNames({
    'showtime-button--small': true,
    'showtime-button--adjacent': true,
  });

  const confirmButtonClasses = classNames(
    baseButtonClasses, {
      'showtime-button--lowlight-delete': isDestructive,
      'showtime-button--lowlight-confirm': isPositive,
      'showtime-button--highlight': !isDestructive && !isPositive,
      'is-disabled': confirmIsDisabled,
    }
  );

  const abortButtonClasses = classNames(
    baseButtonClasses, {
      'showtime-button--highlight-alt': abort && isDestructive,
      'showtime-button--lowlight-alt': abort && !isDestructive,
    }
  );

  const handleConfirm = (...args) => {
    confirmMetric && metricsPublisher.recordMetric(confirmMetric);
    onConfirm(...args);
  };

  const handleAbort = (...args) => {
    abortMetric && metricsPublisher.recordMetric(abortMetric);
    onAbort(...args);
  };

  return (
    <div className='showtime-confirmation-modal'>
      <p className='showtime-confirmation-modal__title'>{title}</p>
      <p className='showtime-confirmation-modal__description'>{description || children}</p>
      <div className='showtime-confirmation-modal__actions'>
        { confirm && (
          <Button
            onClick={ handleConfirm }
            dataTest='confirmationMenu.confirm'
            label={ confirm }
            buttonClasses={ confirmButtonClasses }
          />
        )}
        { abort && (
          <Button
            onClick={ handleAbort }
            dataTest='confirmationMenu.abort'
            label={ abort }
            buttonClasses={ abortButtonClasses }
          />
        )}
      </div>
    </div>
  );
};

ConfirmationContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  abort: PropTypes.string,
  confirm: PropTypes.string,
  onAbort: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmMetric: PropTypes.object,
  abortMetric: PropTypes.object,
  isDestructive: PropTypes.bool,
  isPositive: PropTypes.bool,
  confirmIsDisabled: PropTypes.bool,
};

ConfirmationContent.defaultProps = {
  title: '',
  description: '',
  children: null,
  abort: '',
  confirm: '',
  onAbort() {},
  confirmMetric: null,
  abortMetric: null,
  onConfirm() {},
  isDestructive: true,
  isPositive: false,
  confirmIsDisabled: false,
};

class ConfirmationMenu extends Component { // eslint-disable-line
  render() {
    return (
      <AnchoredMenu
        { ... {
          ...this.props,
          content: <ConfirmationContent
            { ..._.pick(this.props, _.keys(ConfirmationContent.propTypes)) }
          />,
        } }
      />
    );
  }
}

export { ConfirmationMenu, ConfirmationContent };
