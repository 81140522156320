const documentHelperInit = (documentObject) => {
  function querySelector(document, query) {
    return document.querySelector(query);
  }

  return {
    querySelector: query => querySelector(documentObject, query),
  };
};
const DocumentHelper = documentHelperInit(document);

export { documentHelperInit, DocumentHelper };
