export const CREATE_MODE_SAVE = 'save';
export const CREATE_MODE_INVITE = 'invite';

export const CURRENT_USER_LOCATIONS_URL = '/api/v1/user/locations';
export const CURRENT_USER_LOCATIONS_FETCH_KEY = '@user fetch_current_user_locations';

export const ASSIGN_ORG_TO_USER = '@user assign_to_org';
export const UNASSIGN_ORG_FROM_USER = '@user unassign_from_org';
export const ASSIGN_GROUP_TO_USER = '@user assign_to_group';
export const UNASSIGN_GROUP_FROM_USER = '@user unassign_from_group';
export const ASSIGN_LOCATION_TO_USER = '@user assign_to_location';
export const UNASSIGN_LOCATION_FROM_USER = '@user unassign_from_location';
export const SET_INITIAL_USER_ASSIGNMENTS = '@user set_initial_assignments';
export const SET_USER_ROLE = '@user set_role';
export const SET_INITIAL_USER_ROLE = '@user set_initial_role';
export const SET_USER_SITE_SEARCH = '@user set_site_search';
