import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';

import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';
import { TaskWidgetSelectors } from '../../../task-wizard/selectors';

import FormSectionItem from '../../../common/forms/FormSectionItem';
import RadioButton from '../../../common/RadioButton';
import { optionsDisplay } from '../../constants';

const { BUTTONS, DROPDOWN } = optionsDisplay;

const ConfigRowTitleLight = ({ children }) => (
  <div className='showtime-record-log-builder-field__config-row-header'>
    <span
      className={
        classNames(
          'showtime-record-log-builder-field__config-row-title',
          'showtime-record-log-builder-field__config-row-title--light'
        )
      }
    >
      { children }
    </span>
  </div>
);

ConfigRowTitleLight.propTypes = {
  children: PropTypes.node.isRequired,
};

export const OptionsDisplaySelection = ({
  intl,
  isButtonDisplay,
  isDropdownDisplay,
  onButtonDisplaySelect,
  onDropdownDisplaySelect,
}) => {
  const format = createIntlMessageFormatter(intl, 'editWidget.builder.optionsColumn.display');
  return (
    <div className='showtime-record-log-builder-field__config-row'>
      <ConfigRowTitleLight>{ format('title') }</ConfigRowTitleLight>
      <FormSectionItem paddingTop paddingBottom>
        <RadioButton
          checked={ isButtonDisplay }
          onChange={ onButtonDisplaySelect }
        >
          { format('buttons') }
        </RadioButton>
        <RadioButton
          checked={ isDropdownDisplay }
          onChange={ onDropdownDisplaySelect }
        >
          { format('dropdown') }
        </RadioButton>
      </FormSectionItem>
    </div>
  );
};

OptionsDisplaySelection.propTypes = {
  isButtonDisplay: PropTypes.bool.isRequired,
  isDropdownDisplay: PropTypes.bool.isRequired,
  onButtonDisplaySelect: PropTypes.func.isRequired,
  onDropdownDisplaySelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const column = TaskWidgetSelectors.taskWidgetColumnSelector(state, ownProps);
  const { cellSettings: { display = BUTTONS } = {} } = column;
  return {
    isButtonDisplay: display === BUTTONS,
    isDropdownDisplay: display === DROPDOWN,
  };
};

const mapDispatchToProps = (dispatch, { columnIndex }) => {
  const { setWidgetPublicSettings } = bindActionCreators(EditWidgetActions, dispatch);
  const displayPath = `columns[${columnIndex}].cellSettings.display`;
  return {
    onButtonDisplaySelect: () => setWidgetPublicSettings(displayPath, BUTTONS),
    onDropdownDisplaySelect: () => setWidgetPublicSettings(displayPath, DROPDOWN),
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(OptionsDisplaySelection);
