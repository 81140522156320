import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import flatten from 'flat';

import store from './store';

const messages = flatten(window.messages);

const Main = ({ children }) => (
  <IntlProvider locale={ window.locales } formats={ window.time_format } messages={ messages }>
    <Provider store={ store }>
      {children}
    </Provider>
  </IntlProvider>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
