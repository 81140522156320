import _ from 'lodash';
import WidgetConstants from '../constants';
import { RequestActions, RequestConstants } from '../../request';
import { TaskWidgetSelectors } from '../../task-wizard/selectors';
import generateFieldName from './generateFieldName';
import { touched, cleared } from '../../unsaved-changes/actions';

const actionWithTouch = action => (dispatch) => {
  dispatch(touched());

  return dispatch(action);
};

const actionWithSettings = actionArgs => (dispatch, getState) => dispatch(actionWithTouch({
  settings: TaskWidgetSelectors.editedTaskWidgetConfig(getState()).settings,
  ...actionArgs,
}));

export const EditWidgetActions = {
  fetchWidgetConfiguration(organizationId, widgetConfigurationId) {
    return RequestActions.request({
      key: WidgetConstants.WIDGET_CONFIGURATION_LOADED,
      method: RequestConstants.GET,
      url: WidgetConstants.getWidgetConfigurationUrl(organizationId, widgetConfigurationId),
    });
  },
  clearColumnScores: columnIndex => actionWithSettings({
    type: WidgetConstants.CLEAR_COLUMN_SCORES, columnIndex,
  }),
  clearWidgetConfiguration: () => ({ type: WidgetConstants.CLEAR_WIDGET_STATE }),
  setWidgetPublicSettings: (path, value) => actionWithSettings({
    type: WidgetConstants.SET_PUBLIC_SETTINGS, path, value,
  }),
  setWidgetAsNew: () => actionWithSettings({
    type: WidgetConstants.SET_WIDGET_AS_NEW,
  }),
  setWidgetSettings: value => actionWithTouch({ type: WidgetConstants.SET_SETTINGS, value }),
  setColumnSettings: (columnIndex, value) => actionWithSettings({
    type: WidgetConstants.SET_COLUMN_SETTINGS, columnIndex, value,
  }),
  addColumn: (cellType, position) => actionWithSettings({
    type: WidgetConstants.ADD_COLUMN,
    cellType,
    position,
    fieldName: generateFieldName(),
  }),
  expandColumn: fieldName => ({
    type: WidgetConstants.EXPAND_COLUMN, fieldName,
  }),
  setColumnTitle: (columnId, value, columnIndex) => actionWithSettings({
    type: WidgetConstants.SET_COLUMN_TITLE,
    columnId,
    columnIndex,
    value,
  }),
  setDefaultColumnScores: columnIndex => actionWithSettings({
    type: WidgetConstants.SET_DEFAULT_COLUMN_SCORES, columnIndex,
  }),
  validateColumnTitle: (columnId, value) => ({
    type: WidgetConstants.VALIDATE_COLUMN_TITLE,
    columnId,
    value,
  }),
  validateColumnDropdownOptionLabel: (columnId, value, index) => ({
    type: WidgetConstants.VALIDATE_COLUMN_DROPDOWN_OPTION_LABEL,
    columnId,
    value,
    index,
  }),
  sortColumns: fieldNameToNewPosition => actionWithSettings({
    type: WidgetConstants.SORT_COLUMNS,
    fieldNameToNewPosition,
  }),
  addColumnDropdownOption: columnIndex => actionWithSettings({
    type: WidgetConstants.ADD_COLUMN_DROPDOWN_OPTION,
    columnIndex,
  }),
  removeColumnDropdownOption: (columnIndex, optionIndex) => actionWithSettings({
    type: WidgetConstants.REMOVE_COLUMN_DROPDOWN_OPTION,
    columnIndex,
    optionIndex,
  }),
  removeColumnLogicItem: (columnIndex, logicIndex) => actionWithSettings({
    type: WidgetConstants.REMOVE_COLUMN_LOGIC_ITEM,
    columnIndex,
    logicIndex,
  }),
  changeColumnDropdownOptionLabel: (columnIndex, optionIndex, value) => actionWithSettings({
    type: WidgetConstants.CHANGE_COLUMN_DROPDOWN_OPTION_LABEL,
    columnIndex,
    optionIndex,
    value,
  }),
  changeColumnAcceptableRange: (columnIndex, value, key) => actionWithSettings({
    type: WidgetConstants.CHANGE_ACCEPTABLE_RANGE,
    columnIndex,
    value,
    key,
  }),
  clearRulesFromColumn: columnIndex => actionWithSettings({
    type: WidgetConstants.CLEAR_RULES_FROM_COLUMN,
    columnIndex,
  }),
  addRuleToColumn: columnIndex => actionWithSettings({
    type: WidgetConstants.ADD_RULE_TO_COLUMN,
    columnIndex,
  }),
  setDependentRuleForColumn: (columnIndex, ruleColumn) => actionWithSettings({
    type: WidgetConstants.SET_DEPENDENT_RULE_FOR_COLUMN,
    columnIndex,
    ruleColumn,
  }),
  clearAllValidationFromColumn: columnIndex => actionWithSettings({
    type: WidgetConstants.CLEAR_ALL_VALIDATION_FROM_COLUMN,
    columnIndex,
  }),
  removeColumnPrefixAndSuffix: columnIndex => actionWithSettings({
    type: WidgetConstants.REMOVE_COLUMN_PREFIX_AND_SUFFIX,
    columnIndex,
  }),
  removeColumn: (columnIndex, columnId) => actionWithSettings({
    type: WidgetConstants.REMOVE_COLUMN,
    columnIndex,
    columnId,
  }),
  duplicateColumn: (columnIndex, columnId) => actionWithSettings({
    type: WidgetConstants.DUPLICATE_COLUMN,
    columnIndex,
    columnId,
    fieldName: generateFieldName(),
  }),
  setWidgetRequest: (path, value) => actionWithTouch(
    { type: WidgetConstants.SET_WIDGET_REQUEST, path, value }
  ),
  requestWidgetChanges: ({ editRequest }) => async (dispatch) => {
    const { success } = await dispatch(RequestActions.request({
      method: RequestConstants.POST,
      key: WidgetConstants.WIDGET_EDIT_REQUEST,
      url: _.get(window, 'config.zapierEditWidgetConfigWebhook'),
      errorMessage: 'editWidget.errorFlash',
      authenticated: false,
      options: { body: JSON.stringify(editRequest) },
    }));

    if (success) dispatch(cleared());
  },
  updateWidgetConfig: (organizationId, widgetId, settings) => RequestActions.request({
    url: WidgetConstants.updateWidgetConfigurationUrl(organizationId, widgetId),
    key: WidgetConstants.UPDATE_WIDGET_KEY,
    method: RequestConstants.PUT,
    errorMessage: 'editWidget.updateError',
    options: {
      body: JSON.stringify({ settings }),
    },
  }),
  createWidgetConfig: (organizationId, settings) => RequestActions.request({
    url: WidgetConstants.createWidgetConfigurationUrl(organizationId),
    key: WidgetConstants.CREATE_WIDGET_KEY,
    method: RequestConstants.POST,
    errorMessage: 'editWidget.createError',
    options: {
      body: JSON.stringify({ settings }),
    },
  }),
};
