import { isWidgetUrl } from './widgetUrlParser';

export default (request, queue) => {
  const deserializedRequest = JSON.parse(request);
  if (isWidgetUrl(deserializedRequest.url)) {
    return queue.filter((queuedRequest) => {
      const queuedRequestJson = JSON.parse(queuedRequest);
      return queuedRequestJson.url !== deserializedRequest.url;
    });
  }
  return queue;
};
