import React, { PureComponent } from 'react';
import _ from 'lodash';

// Import the module as itself
// To make getViewportWidthUtility mock-able without having it in a separate file
// https://stackoverflow.com/questions/39755439/how-to-mock-imported-named-function-in-jest-when-module-is-unmocked
// eslint-disable-next-line import/no-self-import
import * as withViewportWidthModule from './withViewportWidth';

export const getViewportWidth = () => Math.max(
  _.get(document, 'body.scrollWidth', 0),
  _.get(document, 'documentElement.scrollWidth', 0),
  _.get(document, 'body.offsetWidth', 0),
  _.get(document, 'documentElement.offsetWidth', 0),
  _.get(document, 'documentElement.clientWidth', 0)
);

const withViewportWidth = mapWidthToProps => (Element) => {
  class WithViewportWidth extends PureComponent {
    calcStateFromWidth = () => mapWidthToProps(
      withViewportWidthModule.getViewportWidth(), this.props
    ) || {};

    state = this.calcStateFromWidth();

    onResize = () => this.setState(this.calcStateFromWidth());

    componentDidMount() {
      window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    render() {
      return <Element { ...this.props } { ...this.state } />;
    }
  }

  const elementName = Element.displayName || Element.name || 'Component';
  WithViewportWidth.displayName = `withViewportWidth(${elementName})`;
  return WithViewportWidth;
};

export default withViewportWidth;
