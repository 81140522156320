import moment from 'moment-timezone';
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, FormattedDate } from 'react-intl';
import SVGIcon from '../common/SVGIcon';
import TimeSlotHelpMessage from './TimeSlotHelpMessage';
import { TimeSlotConfigurationStore } from './store.js';
import { TimeSlotConfigurationConstants } from './constants';
import { TimeSlotConfigurationActions } from './actions';
import TabPanel from './TabPanel';
import Tab from './Tab';
import TabHeader from './TabHeader';
import TabContent from './TabContent';
import EditableTimeSlotList from './EditableTimeSlotList';
import { getCurrentUserRole } from '../application/UserRole';
import LoadingSpinner from '../common/LoadingSpinner.jsx';

export class OrganizationLocationTimeSlotConfiguration extends Component {
  componentDidMount() {
    TimeSlotConfigurationActions.resetStore();
    TimeSlotConfigurationStore.addChangeListener(this.refreshState);
    TimeSlotConfigurationActions.fetchInitialTimeslots(
      this.props.organizationId,
      this.props.locationId
    );
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  componentWillUnmount() {
    TimeSlotConfigurationStore.removeChangeListener(this.refreshState);
    TimeSlotConfigurationActions.resetStore();
  }

  refreshState = () => {
    this.forceUpdate();
  };

  getDaysOfTheWeek() {
    return Object.keys(TimeSlotConfigurationConstants.DAYS_OF_THE_WEEK);
  }

  renderTabIcon() {
    return (
      <SVGIcon classes='showtime-tabs-underline-headers-item-icon' iconName='mini/ui/ui-16px-1_pencil' />
    );
  }

  renderResetButton(dayOfWeek) {
    const resetDayOfWeek = (e) => {
      e.preventDefault();
      TimeSlotConfigurationActions.resetLocationDayOfWeek(this.props.locationId, dayOfWeek);
    };
    let resetButton = null;

    if (!this.props.readOnly && !this.props.disabled) {
      resetButton = (
        <div>
          <a
            className='showtime-button showtime-button--small showtime-button--default
            showtime-button--margin-bottom'
            { ...{ 'data-test': 'reset-timeslots' } }
            onClick={ resetDayOfWeek }
          >
            {this.props.intl.formatMessage({ id: 'organizationLocationTimeSlotConfiguration.resetButtonLabel' })}
          </a>
        </div>
      );
    }

    return resetButton;
  }

  renderDayOfWeek(dayOfWeek) {
    const dayOfWeekFullName = TimeSlotConfigurationConstants.DAYS_OF_THE_WEEK[dayOfWeek];
    const timeSlots = TimeSlotConfigurationStore.getLocationTimeSlotsForDayOfWeek(
      this.props.locationId, dayOfWeekFullName);
    let overrides = TimeSlotConfigurationStore.isOrganizationDayOfWeekOverridden(
      this.props.locationId, dayOfWeekFullName);

    const locationCount = Object.keys(this.props.locations).length;
    if (locationCount === 1) { overrides = false; }

    return (
      <Tab key={ dayOfWeek }>
        <TabHeader { ...{ 'data-test': `tab-${dayOfWeek.toLowerCase()}` } }>
          {overrides ? this.renderTabIcon('pencil') : null}
          <FormattedDate value={ moment().day(dayOfWeek).toDate() } weekday='short' />
        </TabHeader>
        <TabContent>
          <EditableTimeSlotList
            readOnly={ this.props.readOnly || this.props.disabled }
            messages={ this.props.messages }
            level='location'
            timeSlotIds={ _.map(timeSlots, 'id') }
            isCloseable={ getCurrentUserRole().isOrgAdminOrAbove() }
          />
          { !_.size(timeSlots) && <LoadingSpinner /> }
          {overrides ? this.renderResetButton(dayOfWeekFullName) : null}
        </TabContent>
      </Tab>
    );
  }

  render() {
    return (
      <div className='organization-location-timeslot-configuration'>
        <TimeSlotHelpMessage />
        <TabPanel>
          { this.getDaysOfTheWeek().map(weekDay => this.renderDayOfWeek(weekDay)) }
        </TabPanel>
      </div>
    );
  }
}

OrganizationLocationTimeSlotConfiguration.propTypes = {
  locationId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  messages: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  locations: PropTypes.array,
};

OrganizationLocationTimeSlotConfiguration.defaultProps = {
  messages: '',
  disabled: false,
  locations: [],
};

export default injectIntl(OrganizationLocationTimeSlotConfiguration);
