import React from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';

import { DATE_QUERY_SEPARATOR, DATE_FILTER_FORMAT } from '../constants';
import applyFiltersToQuery, { getDateRangeFromQueryValue, getValueFromQuery } from '../applyFiltersToQuery';

const withDatesQuery = queryKey => Element => withRouter((props) => {
  const { location: { search: queryString }, history } = props;
  const queryValue = getValueFromQuery(queryString, queryKey);
  const queryDates = getDateRangeFromQueryValue(queryValue);
  const setQueryDates = (startDate, endDate) => applyFiltersToQuery(
    props.filterMetric,
    history,
    {
      [queryKey]: `${startDate.format(DATE_FILTER_FORMAT)}${DATE_QUERY_SEPARATOR}${endDate.format(DATE_FILTER_FORMAT)}`,
    }
  );
  const clearQueryDates = () => applyFiltersToQuery(props.filterMetric, history, { [queryKey]: '' });
  return (
    <Element
      { ...{
        ...props,
        queryDates,
        setQueryDates,
        clearQueryDates,
        startDate: queryDates[0] || moment().startOf('day'),
        endDate: queryDates[queryDates.length - 1] || moment().startOf('day'),
      } }
    />
  );
});

export default withDatesQuery;
