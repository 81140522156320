import { TaskHelpTextConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskHelpTextActions = {
  change: content => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskHelpTextConstants.CHANGE,
      content,
    });
  },
  expand() {
    return {
      type: TaskHelpTextConstants.EXPAND,
    };
  },
};
