import DataCaptureWidgetData from './models/DataCaptureWidgetData';
import MysteryDinerWidgetData from './models/MysteryDinerWidgetData';
import widgetSelectorHelper from './widgetSelectorHelper';

const WidgetModelRegistry = {
  modelByType: {
    'DataCapture.*WidgetData': DataCaptureWidgetData,
    'MysteryDiner.*WidgetData': MysteryDinerWidgetData,
  },
};

WidgetModelRegistry.getWidgetModelForType = type => widgetSelectorHelper(
  WidgetModelRegistry.modelByType, type
);

export { WidgetModelRegistry };
