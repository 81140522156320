import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RenderPropSticky } from 'react-sticky-el';
import classNames from 'classnames';

const OffsetSticky = ({
  top,
  disabled,
  stickyClassName,
  className,
  children,
  parentBorderWidth,
  ...props
}) => (
  <RenderPropSticky
    topOffset={ -top }
    bottomOffset={ top }
    key={ disabled }
    disabled={ disabled }
    { ...props }
  >
    {({
      isFixed,
      wrapperStyles,
      wrapperRef,
      holderStyles,
      holderRef,
    }) => (
      <div ref={ holderRef } style={ holderStyles }>
        <div
          className={ classNames(className, { [stickyClassName]: isFixed }) }
          style={ wrapperStyles }
          ref={ wrapperRef }
        >
          <div
            style={
              isFixed
                ? {
                  top,
                  position: 'relative',
                  marginLeft: -parentBorderWidth,
                  marginRight: -parentBorderWidth,
                }
                : null
            }
          >
            { children }
          </div>
        </div>
      </div>
    )}
  </RenderPropSticky>
);

OffsetSticky.propTypes = {
  stickyClassName: PropTypes.string,
  children: PropTypes.node,
  top: PropTypes.number,
  disabled: PropTypes.bool,
  parentBorderWidth: PropTypes.number,
  className: PropTypes.string,
};

OffsetSticky.defaultProps = {
  stickyClassName: '',
  children: null,
  top: 0,
  disabled: false,
  parentBorderWidth: 0,
  className: '',
};

export default memo(OffsetSticky);
