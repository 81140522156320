import React from 'react';
import PropTypes from 'prop-types';
import { parseTimeSlotTime, hourLabel } from './timeSlotTimeHelper';

const EditableTimeSlotSection = ({ label, time }) => {
  const parsedTime = parseTimeSlotTime(time);

  return (
    <div className='editable-time-slot-section grid-block' data-test={ label }>
      <div className='editable-time-slot-section__name'>
        { label }
      </div>
      <div className='editable-time-slot-section__times'>
        {hourLabel(parsedTime.h)}:{parsedTime.m}
      </div>
    </div>
  );
};

EditableTimeSlotSection.propTypes = {
  label: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default EditableTimeSlotSection;
