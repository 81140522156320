import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from '../common/SVGIcon.jsx';

export const CloseMenuButton = ({ onClick, intl: { formatMessage } }) => (
  <div className='showtime-menu-close'>
    <a
      className={
        classNames([
          'showtime-button',
          'showtime-button--menu-close',
          'showtime-button--block',
        ])
      }
      data-test='mobileMenuClose'
      onClick={ onClick }
    >
      <SVGIcon
        classes='showtime-button__icon'
        iconName='mini/ui/ui-16px-1_simple-remove'
      />
      <span>
        { formatMessage({ id: 'navigation.mobileMenu.closeButton' })}
      </span>
    </a>
  </div>
);

CloseMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(CloseMenuButton);
