import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import OnlineContext from '../online-context';

const MessageInfo = ({
  intl,
  userName,
  messageId,
  timestamp,
}) => (
  <div className='showtime-conversation__message-id'>
    <span data-test='userName' className='showtime-conversation__message-username'>
      { userName }
    </span>
    <span>
      {intl.formatMessage({ id: 'conversation.comment.usernameTimeSeparator' })}
    </span>
    <span data-test='timeDifference' className='showtime-conversation__message-timestamp'>
      <OnlineContext.Consumer>
        {
          (isOnline) => {
            const isSaving = isOnline && !messageId;
            return isSaving
              ? intl.formatMessage({ id: 'conversation.comment.saving' })
              : moment(timestamp).fromNow();
          }
        }
      </OnlineContext.Consumer>
    </span>
  </div>
);

MessageInfo.propTypes = {
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

MessageInfo.defaultProps = {
  messageId: undefined,
};

export default injectIntl(MessageInfo);
