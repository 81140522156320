import { RequestActions, RequestConstants } from '../../request';
import {
  FETCH_TASK_REPORTS_KEY,
  FETCH_TASK_REPORT_CONTENTS_KEY,
  FETCH_TASK_SUBTASKS_KEY,
  TASK_REPORTS_INDEX_URL,
  fetchTaskReportContentsUrl,
  fetchTaskSubtasksUrl,
} from '../constants';
import { TaskReportsSelectors } from '../selectors';
import { WidgetActions } from '../../widget/actions';

export default (requestId, location) => async (dispatch, getState) => {
  const cursor = TaskReportsSelectors.timelineTasksCursorSelector(getState());
  const windowingDate = TaskReportsSelectors.windowingDateSelector(getState());

  const taskReportsBody = TaskReportsSelectors.taskReportsRequestBodySelector(
    getState(), { location });

  const taskReportsResponse = await dispatch(RequestActions.request({
    url: TASK_REPORTS_INDEX_URL,
    key: FETCH_TASK_REPORTS_KEY,
    method: RequestConstants.POST,
    errorMessage: 'taskReports.timelineTasksFail',
    options: {
      body: JSON.stringify({
        ...cursor && { after: cursor },
        ...windowingDate && { windowing_date: windowingDate },
        ...taskReportsBody,
      }),
    },
  }));

  if (taskReportsResponse.success) {
    const { success: { body: { data: tasks } } } = taskReportsResponse;
    const taskIdsWithComments = TaskReportsSelectors.taskIdsWithComments(tasks);
    if (taskIdsWithComments.length > 0) {
      dispatch(RequestActions.request({
        url: fetchTaskReportContentsUrl(taskIdsWithComments),
        key: FETCH_TASK_REPORT_CONTENTS_KEY,
        method: RequestConstants.GET,
        errorMessage: 'taskReports.timelineTasksFail',
      }));
    }

    const taskIdsWithSubtasks = TaskReportsSelectors.taskIdsWithSubtasks(tasks);

    if (taskIdsWithSubtasks.length > 0) {
      dispatch(RequestActions.request({
        url: fetchTaskSubtasksUrl(taskIdsWithSubtasks),
        key: FETCH_TASK_SUBTASKS_KEY,
        method: RequestConstants.GET,
        errorMessage: 'taskReports.timelineTasksFail',
      }));
    }

    const widgetIds = TaskReportsSelectors.widgetIdsFromTasks(tasks);
    if (widgetIds.length > 0) {
      dispatch(WidgetActions.fetchWidgets(widgetIds));
    }
  }
  const response = { ...taskReportsResponse, requestId };
  return response;
};
