import FlashConstants from '../constants';

export const notice = message => ({
  type: FlashConstants.NOTICE,
  noticeMessage: message,
});

export const alert = message => ({
  type: FlashConstants.ALERT,
  errorMessage: message,
});
