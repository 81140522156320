import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const CouponPromoCodeLink = ({
  onClick,
  promoCodeExists,
  intl,
}) => (
  <a
    className='showtime-billing__edit-promo'
    data-test='edit-promo'
    onClick={ onClick }
  >
    {
      promoCodeExists
        ? intl.formatMessage({ id: 'billing.updatePromo' })
        : intl.formatMessage({ id: 'billing.applyNewPromo' })
    }
  </a>
);

CouponPromoCodeLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  promoCodeExists: PropTypes.bool,
};

CouponPromoCodeLink.defaultProps = {
  promoCodeExists: null,
};

export default injectIntl(CouponPromoCodeLink);
