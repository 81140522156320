import React from 'react';
import PropTypes from 'prop-types';

const TaskMetadata = ({ taskName, widgetName }) => (
  <div className='showtime-task-action__metadata'>
    <div className='showtime-task-action__title'>
      { taskName }
    </div>
    <div className='showtime-task-action__subtitle'>
      { widgetName }
    </div>
  </div>
);
TaskMetadata.propTypes = {
  taskName: PropTypes.string.isRequired,
  widgetName: PropTypes.string.isRequired,
};

export default TaskMetadata;
