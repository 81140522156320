import fp from 'lodash/fp';
import { combineReducers } from 'redux';

import { DATA_CAPTURE_ACTION_TYPES } from '../constants';

const {
  SHOW_FIELD_ERRORS,
  SHOW_ROW_ERRORS,
  SET_VIEW_MODE,
  SHOW_ALL_ERRORS,
} = DATA_CAPTURE_ACTION_TYPES;

const errorActionCases = {
  [SHOW_FIELD_ERRORS]: (state, { rowId, fieldName, widgetId }) => (
    fp.set([widgetId, 'visibleFields', rowId, fieldName], true, state)
  ),
  [SHOW_ROW_ERRORS]: (state, { rowId, widgetId }) => (
    fp.set([widgetId, 'visibleRows', rowId], true, state)
  ),
  [SHOW_ALL_ERRORS]: (state, { widgetId }) => fp.set([widgetId, 'allVisible'], true, state),
};

export const errorReducer = (state, action) => {
  state = state || {};
  if (errorActionCases[action.type]) {
    return errorActionCases[action.type](state, action);
  }
  return state;
};

export const viewModeReducer = (state, { type, widgetId, mode }) => {
  state = state || {};
  if (type === SET_VIEW_MODE) {
    return { ...state, [widgetId]: mode };
  }
  return state;
};

export default combineReducers({
  errors: errorReducer,
  viewMode: viewModeReducer,
});
