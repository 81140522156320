import React from 'react';
import PropTypes from 'prop-types';

const TaskTemplateCounts = ({
  intl, filteredTaskTemplates,
  taskTemplates,
}) => {
  const filteredCount = filteredTaskTemplates.length;
  const fullListCount = taskTemplates.length;

  return (
    <div className='showtime-layout-admin-management_page-title' data-test='taskTemplate.count'>
      <span className='showtime-layout-admin-management_selected-count'>
        { intl.formatMessage({ id: 'taskplanner.filteredCount' }, { filteredCount }) }
      </span>
      {Boolean(fullListCount) &&
        <span className='showtime-layout-admin-management_total-count'>
          { intl.formatMessage({ id: 'taskplanner.fullListCount' }, { fullListCount }) }
        </span>
      }
    </div>
  );
};

TaskTemplateCounts.propTypes = {
  intl: PropTypes.any.isRequired,
  filteredTaskTemplates: PropTypes.array,
  taskTemplates: PropTypes.array,
};

TaskTemplateCounts.defaultProps = {
  filteredTaskTemplates: [],
  taskTemplates: [],
};

export default TaskTemplateCounts;
