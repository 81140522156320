import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TaskTemplateConstants } from '../task/constants';
import SVGIcon from '../common/SVGIcon';
import ScheduleText from '../schedule/ScheduleText';

export class ScheduleStatus extends Component {
  renderSchedule(taskTemplate) {
    const schedules = taskTemplate.schedules.map((schedule) => {
      const { duration, recurrence, start_date: startDate } = schedule;
      return {
        start: { ...duration[0].start, date: startDate },
        end: duration[duration.length - 1].end,
        totalDays: duration.length,
        recurrence,
      };
    });

    const scheduleText = (<ScheduleText
      schedules={ schedules }
      timeslots={ this.props.scheduleCategories }
      isAllDay={ this.isAllDay() }
    />);

    return (
      <div className='showtime-task-planner-task__schedule' data-test={ this.props.dataTest }>
        <SVGIcon
          classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
          iconName='mini/ui/ui-16px-1_calendar-60'
        />
        { scheduleText }
      </div>
    );
  }

  isAllDay() {
    const openingTimeslot = this.props.scheduleCategories[0].id;
    const closingTimeslot =
      this.props.scheduleCategories[this.props.scheduleCategories.length - 1].id;
    const { duration } = this.props.taskTemplate.schedules[0];
    const selectedOpeningTimeslot = duration[0].start.value.id;
    const selectedClosingTimeslot = duration[duration.length - 1].end.value.id;
    if (selectedOpeningTimeslot === openingTimeslot
        && selectedClosingTimeslot === closingTimeslot) {
      return true;
    }
    return false;
  }

  hasNoSchedules(taskTemplate) {
    return !(taskTemplate.schedules && taskTemplate.schedules[0]);
  }

  render() {
    const unscheduledTemplateTypes = [
      TaskTemplateConstants.templateType.ON_DEMAND,
      TaskTemplateConstants.templateType.AUTOMATED,
      TaskTemplateConstants.templateType.ACTION_TEMPLATE,
    ];
    if (unscheduledTemplateTypes.includes(this.props.taskTemplate.template_type) ||
        this.hasNoSchedules(this.props.taskTemplate)) {
      return null;
    }

    return this.renderSchedule(this.props.taskTemplate);
  }
}

ScheduleStatus.propTypes = {
  dataTest: PropTypes.string,
  taskTemplate: PropTypes.object.isRequired,
  scheduleCategories: PropTypes.array.isRequired,
};

ScheduleStatus.defaultProps = {
  dataTest: '',
};

export default injectIntl(ScheduleStatus);
