import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormattedMoney from './FormattedMoney';

const getDurationType = ({ duration, durationInMonths }) => {
  if (duration) return 'DURATION';
  if (durationInMonths) return 'DURATION_IN_MONTHS';
  return 'NONE';
};

const CouponSummary = ({
  percentRatio,
  amountOff,
  currency,
  durationInMonths,
  duration,
}) => (
  <FormattedMessage
    id='billing.promoSummary'
    values={ {
      amountOff: <FormattedMoney { ...{ currency, amount: amountOff } } />,
      discountType: percentRatio ? 'PERCENT' : 'AMOUNT',
      percentRatio,
      duration,
      durationType: getDurationType({ duration, durationInMonths }),
      durationInMonths,
    } }
  />
);

CouponSummary.propTypes = {
  percentRatio: PropTypes.number,
  amountOff: PropTypes.number,
  currency: PropTypes.string,
  durationInMonths: PropTypes.number,
  duration: PropTypes.string,
};

CouponSummary.defaultProps = {
  percentRatio: 0,
  amountOff: 0,
  currency: undefined,
  durationInMonths: 0,
  duration: undefined,
};

export default CouponSummary;
