import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import FieldDropdown from './FieldDropdown';
import BlockTitle from './BlockTitle';

const OtherwiseNextField = ({ columnIndex }) => {
  const nextColumn = useSelector(TaskWidgetSelectors.taskWidgetColumnsSelector)[columnIndex + 1];

  if (!nextColumn) return null;

  const { formatMessage } = useIntl();

  return (
    <div className='showtime-record-log-builder-field--block'>
      <BlockTitle>
        { formatMessage({ id: 'editWidget.builder.optionsColumn.logic.otherwise' }) }
      </BlockTitle>
      <FieldDropdown
        disabled
        value={ _.get(nextColumn, 'cellSettings.fieldName') }
        selectedColumn={ nextColumn }
        dataTest='logic.then.fieldDefault'
      />
    </div>
  );
};

OtherwiseNextField.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default OtherwiseNextField;
