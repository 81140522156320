import React from 'react';
import PropTypes from 'prop-types';
import { usePDF } from '@react-pdf/renderer';
import LoadingSpinner from '../../common/LoadingSpinner';

const TaskReportPDFViewer = ({ children, loadingLabel }) => {
  const [instance] = usePDF({ document: children });

  if (!instance.url || instance.loading) {
    return <LoadingSpinner label={ loadingLabel } />;
  }

  return (
    <iframe
      src={ `${instance.url}#toolbar=1&navpanes=0&scrollbar=0&statusbar=0&messages=0` }
      title='export'
      height='100%'
      width='100%'
    />
  );
};

TaskReportPDFViewer.propTypes = {
  children: PropTypes.any.isRequired,
  loadingLabel: PropTypes.string.isRequired,
};

export default TaskReportPDFViewer;
