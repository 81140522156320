import _ from 'lodash';
import { TaskTagConstants, TaskWizardConstants } from '../constants';
import { TaskConstants, TaskTemplateConstants } from '../../task/constants';
import TagConstants from '../../tags/constants';
import { RequestConstants } from '../../request';
import { tagValidator } from '../../tags';
import { GET_ACTION_TEMPLATE_KEY, GET_EDITING_ACTION_KEY } from '../../taskActions/constants';

export const taskTagReducer = (function () {
  function removeTagFromTask(state, action) {
    const tags = state.content.tags.filter(tag => tag.label !== action.tag.label);
    const orgTags = _.assign({}, state.content.organizationTags);
    orgTags[action.tag.label] = {
      selected: false,
    };

    const empty = (tags.length < 1);
    return _.assign({}, state, {
      content: _.assign({}, state.content, {
        tags,
        isEmpty: empty,
        organizationTags: orgTags,
      }),
    });
  }

  function addTagToTask(state, action) {
    const tags = state.content.tags.slice();
    const previouslyAddedTags = state.content.organizationTags[action.tag.label];
    if (previouslyAddedTags) {
      tags.push({ label: action.tag.label, id: previouslyAddedTags.id });
    } else {
      tags.push(action.tag);
    }

    const orgTags = _.assign({}, state.content.organizationTags);
    orgTags[action.tag.label] = {
      selected: true,
    };

    const empty = (tags.length < 1);
    return _.assign({}, state, {
      content: _.assign({}, state.content, {
        showTagInput: true,
        tags,
        isEmpty: empty,
        organizationTags: orgTags,
        input: '',
        inputIsValid: false,
      }),
    });
  }

  function addOrganizationTags(state, action) {
    const defaultLoadedState = {
      organizationTags: {},
      tags: [],
    };

    action.content.data.reduce((loadedState, tag) => {
      const selected = state.loadedTags.indexOf(tag.id) !== -1;
      loadedState.organizationTags[tag.attributes.label] = {
        selected,
        id: tag.id,
      };
      if (selected) {
        loadedState.tags.push({
          label: tag.attributes.label,
          id: tag.id,
        });
      }
      return loadedState;
    }, defaultLoadedState);

    const returnedState = _.assign({}, state, {
      content: {
        tags: defaultLoadedState.tags,
        isEmpty: state.content.isEmpty,
        organizationTags: defaultLoadedState.organizationTags,
        organizationTagsLoaded: true,
      },
      originalContent: {
        tags: defaultLoadedState.tags,
        isEmpty: state.originalContent.isEmpty,
        organizationTags: defaultLoadedState.organizationTags,
        organizationTagsLoaded: true,
      },
    });
    return returnedState;
  }

  function organizationTagsDisabled(state) {
    const loadedState = {
      organizationTags: {},
      tags: [],
    };

    const returnedState = _.assign({}, state, {
      content: {
        tags: loadedState.tags,
        isEmpty: state.content.isEmpty,
        organizationTags: loadedState.organizationTags,
        organizationTagsLoaded: true,
      },
      originalContent: {
        tags: loadedState.tags,
        isEmpty: state.originalContent.isEmpty,
        organizationTags: loadedState.organizationTags,
        organizationTagsLoaded: true,
      },
    });
    return returnedState;
  }

  function assignIdToTag(state, action) {
    const createdTag = action.content.data;
    const tagsWithIdInserted = state.content.tags.map((tag) => {
      if (tag.label === createdTag.attributes.label) {
        return _.assign({}, tag, { id: createdTag.id });
      }
      return tag;
    });

    const contentOrgTags = _.assign({}, state.content.organizationTags);
    contentOrgTags[createdTag.attributes.label] = {
      selected: true,
      id: createdTag.id,
    };

    const originalContentOrgTags = _.assign({}, state.content.organizationTags);
    originalContentOrgTags[createdTag.attributes.label] = {
      selected: false,
      id: createdTag.id,
    };

    return _.assign({}, state, {
      content: _.assign({}, state.content, {
        tags: tagsWithIdInserted,
        organizationTags: contentOrgTags,
      }),
      originalContent: _.assign({}, state.originalContent, {
        organizationTags: originalContentOrgTags,
      }),
    });
  }

  function removeUnsavedTag(state, action) {
    const hasErrors = _.has(action, 'content.body.errors[0].meta.tag');
    if (!hasErrors) {
      return state;
    }
    const unsavedTag = action.content.body.errors[0].meta.tag;
    const filteredTags = state.content.tags.filter(tag => tag.label !== unsavedTag);
    const clonedOrgTags = _.assign({}, state.content.organizationTags);
    delete clonedOrgTags[unsavedTag];

    return _.assign({}, state, {
      content: _.assign({}, state.content, {
        tags: filteredTags,
        organizationTags: clonedOrgTags,
      }),
    });
  }

  function validateInput(state, action) {
    const valid = tagValidator.isLabelValid(action.input);
    return _.merge({}, state, {
      content: {
        input: action.input,
        inputIsValid: valid,
      },
    });
  }

  function showInputField(state) {
    return _.merge({}, state, {
      content: {
        showTagInput: true,
      },
    });
  }

  function reset(state, action, initialState) {
    const tagLabels = Object.keys(state.content.organizationTags);
    const organizationTags = tagLabels.reduce((orgTags, label) => {
      orgTags[label] = {
        id: state.content.organizationTags[label].id,
        selected: false,
      };
      return orgTags;
    }, {});
    return _.merge({}, initialState, {
      content: {
        organizationTags,
      },
    });
  }

  function loadTaskTags(state, tags) {
    if (!tags || !tags.length) {
      return state;
    }
    const orgKeys = Object.keys(state.content.organizationTags);

    const defaultLoadedState = {
      tags: [],
      organizationTags: {},
    };

    orgKeys.reduce((loadedState, label) => {
      const tag = state.content.organizationTags[label];

      if (tags.indexOf(tag.id) === -1) {
        return loadedState;
      }
      loadedState.organizationTags[label] = _.merge({}, tag, {
        selected: true,
      });
      loadedState.tags.push({
        id: tag.id,
        label,
      });
      return loadedState;
    }, defaultLoadedState);

    return _.merge({}, state, {
      loadedTags: tags,
      content: {
        isEmpty: false,
        organizationTags: defaultLoadedState.organizationTags,
        tags: defaultLoadedState.tags,
      },
      originalContent: {
        isEmpty: false,
        organizationTags: defaultLoadedState.organizationTags,
        tags: defaultLoadedState.tags,
      },
    });
  }

  function reduce(state, action) {
    const initialState = {
      isExpanded: true,
      content: {
        tags: [],
        isEmpty: true,
        organizationTags: {},
        organizationTagsLoaded: false,
        inputIsValid: false,
        input: '',
        showTagInput: false,
      },
      originalContent: {
        tags: [],
        isEmpty: true,
        organizationTags: {},
        organizationTagsLoaded: false,
        inputIsValid: false,
        input: '',
        showTagInput: false,
      },
      loadedTags: [],
    };

    const currState = state || _.extend({}, initialState);

    switch (action.type) {
      case TaskTagConstants.EXPAND:
        return _.extend({}, currState, {
          isExpanded: true,
        });
      case TaskConstants.RESET:
        return reset(currState, action, initialState);
      case TaskTagConstants.ADD:
        return addTagToTask(currState, action);
      case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
        return loadTaskTags(currState, action.content.tags);
      case RequestConstants.getLoadedActionType(GET_ACTION_TEMPLATE_KEY):
        if (action.content.status === TaskTemplateConstants.status.ARCHIVED) return state;

        return loadTaskTags(currState, action.content.tags);
      case RequestConstants.getLoadedActionType(GET_EDITING_ACTION_KEY):
        return loadTaskTags(currState, _.map(action.content.timeline_task.tags, 'id'));
      case TaskTagConstants.DELETE:
        return removeTagFromTask(currState, action);
      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_LOADED):
        return addOrganizationTags(currState, action);
      case TagConstants.ORGANIZATION_TAGS_DISABLED:
        return organizationTagsDisabled(currState);
      case RequestConstants.getLoadedActionType(TagConstants.ORGANIZATION_TAGS_CREATED):
        return assignIdToTag(currState, action);
      case RequestConstants.getErrorActionType(TagConstants.ORGANIZATION_TAGS_CREATED):
        return removeUnsavedTag(currState, action);
      case TaskTagConstants.INPUT_CHANGE:
        return validateInput(currState, action);
      case TaskTagConstants.SHOW_INPUT_FIELD:
        return showInputField(currState, action);
      default:
        return currState;
    }
  }
  return reduce;
}());
