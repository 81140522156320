import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '../common/Button';
import { AnchoredMenu, ConfirmationMenu } from '../common/menu';
import ListSelection, { Group, Item } from '../common/ListSelection';

const ALL_VALUES_SELECTED = 'ALL_VALUES';

class AdminActionBarButton extends Component {
  state = {
    showValueList: this.props.showValueList,
    selectedValues: [],
  };

  toggleMenu = () => {
    this.setState({
      showValueList: !this.state.showValueList,
      selectedValues: [],
    });
  }

  onAllValuesSelectedChange = ({ isSelected }) => {
    this.setState({
      selectedValues: isSelected ? this.props.values.map(value => value.id) : [],
    });
  }

  onSelectedValuesChange = ({ value: valueId, isSelected }) => {
    this.setState({
      selectedValues: isSelected ? [
        ...this.state.selectedValues,
        valueId,
      ] : _.without(this.state.selectedValues, valueId),
    });
  }

  areAllValuesSelected = () => (
    this.state.selectedValues.length === this.props.values.length
  )

  isValueSelected = valueId => (
    _.includes(this.state.selectedValues, valueId)
  )

  selectedValues = () => (
    this.state.selectedValues
  )

  showConfirmationMenu = () => (
    Boolean(this.state.selectedValues.length)
  )

  onConfirm = () => {
    this.props.onConfirm(this.selectedValues());
  }

  onCancel = () => {
    this.toggleMenu();
    this.props.onCancel();
  }

  onCloseMenu = () => {
    this.toggleMenu();
    this.props.onCloseMenu();
  }

  selectedValueNameOrCount = () => {
    const { valueType } = this.props;
    const valueCount = this.selectedValues().length;

    if (valueCount === 1) {
      const { name } = this.props.values.find(({ id }) => id === this.selectedValues()[0]);

      return name;
    }

    return this.props.intl.formatMessage({ id: `taskplanner.${valueType}_count` }, { valueCount });
  }

  renderConfirmationMenu = () => {
    const {
      valueType,
      intl,
      taskCount,
    } = this.props;

    const valueNameOrCount = this.selectedValueNameOrCount();

    return (
      <ConfirmationMenu
        align='left'
        title={ intl.formatMessage({ id: `taskplanner.${valueType}_selected_tasks` }) }
        description={
          intl.formatMessage({ id: `taskplanner.${valueType}_description` },
            { taskCount, valueNameOrCount })
        }
        abort={ intl.formatMessage({ id: 'taskplanner.abort_button' }) }
        confirm={ intl.formatMessage({ id: `taskplanner.confirm_${valueType}` }) }
        onAbort={ this.onCancel }
        onConfirm={ this.onConfirm }
        showMenu={ this.showConfirmationMenu() }
        isDestructive={ false }
        isPositive={ false }
        confirmIsDisabled={ this.props.saving }
      />
    );
  }

  renderValueListSelection = () => {
    const { intl, values } = this.props;

    return (
      <ListSelection
        dataTest={ `${this.props.valueType}-values` }
        searchInputClassName='showtime-input--margin-bottom'
      >
        <Group onSelectChange={ this.onAllValuesSelectedChange } selectAll id='all'>
          <Item
            value={ ALL_VALUES_SELECTED }
            checked={ this.areAllValuesSelected() }
          >
            { intl.formatMessage({ id: 'taskplanner.select_all_values' }) }
          </Item>
        </Group>
        <Group onSelectChange={ this.onSelectedValuesChange } id='values'>
          { values.map(value => (
            <Item
              key={ value.id }
              value={ value.id }
              checked={ this.isValueSelected(value.id) }
            >
              { value.name }
            </Item>
          )) }
        </Group>
      </ListSelection>
    );
  }

  renderValueListMenu = () => (
    <AnchoredMenu
      align='right'
      onClickOutside={ this.onCloseMenu }
    >
      <ul className='showtime-menu__list'>
        { this.renderValueListSelection() }
        { this.renderConfirmationMenu() }
      </ul>
    </AnchoredMenu>
  )

  render() {
    const {
      intl,
      valueType,
      componentName,
      iconName,
    } = this.props;

    return (
      <div className='showtime-layout-admin-management__button'>
        <Button
          { ... {
            buttonClasses: classNames(
              'showtime-button--default',
              'showtime-button--small',
              'showtime-button--collapse-icon'
            ),
            label: intl.formatMessage({ id: `taskplanner.${valueType}_button` }),
            iconName,
            iconPosition: 'left',
            onClick: this.toggleMenu,
            dataTest: componentName,
          } }
        />
        { this.state.showValueList && this.renderValueListMenu() }
      </div>
    );
  }
}

AdminActionBarButton.propTypes = {
  values: PropTypes.array.isRequired,
  valueType: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  taskCount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  showValueList: PropTypes.bool.isRequired,
  onCloseMenu: PropTypes.func,
};

AdminActionBarButton.defaultProps = { iconName: null, onCloseMenu() {} };
export default injectIntl(AdminActionBarButton);
