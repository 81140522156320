import {
  OFFLINE_STATE_SUBTASKS,
  OFFLINE_STATE_TASKS,
  OFFLINE_STATE_WIDGETS,
} from '../../../service-workers/constants';
import {
  SubTaskStore,
  TrailStore,
  WidgetDataStore,
} from '../../../trail/store';

export default (storeKey) => {
  const storesByKey = {
    [OFFLINE_STATE_TASKS]: () => new TrailStore().getTasks(),
    [OFFLINE_STATE_SUBTASKS]: () => SubTaskStore.getAllSubTasks(),
    [OFFLINE_STATE_WIDGETS]: () => WidgetDataStore.getAllWidgetData(),
  };

  return storesByKey[storeKey];
};
