import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import moment from 'moment-timezone';

const PDFFooter = ({
  intl, bulkStartIndex, bulkEndIndex, taskCount,
}) => (
  <View
    is='div'
    fixed
    style={ {
      position: 'absolute', bottom: 30, left: 15, right: 15,
    } }
  >
    <Text
      is='span'
      style={ {
        fontSize: 10,
        position: 'absolute',
        textAlign: 'left',
        left: 0,
      } }
    >
      {
        intl.formatMessage(
          { id: 'taskReports.export.PDF.footer.createdAt' },
          { date: moment().format(intl.formatMessage({ id: 'taskReports.export.PDF.footer.dateFormat' })) }
        )
      }
    </Text>

    <Text
      is='span'
      style={ {
        fontSize: 10,
        position: 'absolute',
        textAlign: 'center',
        left: 0,
        width: '100%',
      } }
      render={ ({ pageNumber, totalPages }) => (
        intl.formatMessage(
          { id: 'taskReports.export.PDF.footer.pageNumber' },
          { pageNumber, totalPages }
        )
      ) }
    />
    {bulkEndIndex ? (
      <Text
        is='span'
        style={ {
          fontSize: 10,
          position: 'absolute',
          textAlign: 'right',
          right: 0,
        } }
      >
        {intl.formatMessage(
          { id: 'taskReports.export.PDF.footer.bulkGroup' },
          { startTask: bulkStartIndex + 1, endTask: bulkEndIndex + 1, taskCount }
        )}
      </Text>
    )
      : null}
  </View>
);

PDFFooter.propTypes = {
  bulkStartIndex: PropTypes.number,
  bulkEndIndex: PropTypes.number,
  taskCount: PropTypes.number,
};

PDFFooter.defaultProps = {
  bulkStartIndex: null,
  bulkEndIndex: null,
  taskCount: null,
};

export default PDFFooter;
