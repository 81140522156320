import React from 'react';
import PropTypes from 'prop-types';
import { Link, View, Image } from '@react-pdf/renderer';

const PDFHeader = ({ intl, reportUrl }) => (
  <View
    is='div'
    fixed
    style={ {
      top: 10,
      marginBottom: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    } }
  >
    <Image
      is='img'
      style={ {
        width: 40,
        height: 'auto',
      } }
      src={
        `${window.config.assetPath}nucleo/logo/trail-logo-2019.png`
      }
    />
    <Link
      is='a'
      style={ { fontSize: 10 } }
      src={ reportUrl }
    >
      {intl.formatMessage({ id: 'taskReports.export.PDF.viewReport' })}
    </Link>
  </View>
);

PDFHeader.propTypes = {
  reportUrl: PropTypes.string.isRequired,
};

export default PDFHeader;
