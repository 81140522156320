import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withDatesQuery from './withDatesQuery';
import DateSelectFilter from '../../common/Filters/DateSelectFilter';
import { filterQueryKeys, filterTypes } from '../constants';
import SVGIcon from '../../common/SVGIcon';
import DateFilterMenu from './DateFilterMenu';
import { dateFilterKeys, determineDateFilterKey } from '../dateFilterKeyHelper';

export const DateFilterButtonText = injectIntl(({
  startDate, endDate, intl: { formatMessage }, labelDateFormat,
}) => {
  const dateFilterKey = determineDateFilterKey(startDate, endDate);
  switch (dateFilterKey) {
    case dateFilterKeys.LAST_90_DAYS:
      return formatMessage({ id: 'taskReports.dateFilterLabel.lastNinetyDays' });
    case dateFilterKeys.LAST_30_DAYS:
      return formatMessage({ id: 'taskReports.dateFilterLabel.lastThirtydays' });
    case dateFilterKeys.YESTERDAY:
      return formatMessage({ id: 'taskReports.dateFilterLabel.yesterday' });
    case dateFilterKeys.TODAY:
      return formatMessage({ id: 'taskReports.dateFilterLabel.today' });
    case dateFilterKeys.SINGLE_DATE:
      return formatMessage({ id: 'taskReports.dateFilterLabel.singleDate' }, {
        date: moment(startDate).format(labelDateFormat),
      });
    default:
      return formatMessage(
        { id: 'taskReports.dateFilterLabel.dateRange' },
        {
          startDate: moment(startDate).format(labelDateFormat),
          endDate: moment(endDate).format(labelDateFormat),
        }
      );
  }
});

const DateFilterButton = injectIntl(({
  startDate, endDate, intl: { formatMessage }, onClear,
}) => {
  const labelDateFormat = formatMessage({ id: 'taskReports.dateFilterLabel.dateFormat' });
  return (
    <React.Fragment>
      <DateFilterButtonText { ...{ startDate, endDate, labelDateFormat } } />
      <div
        data-hint={ formatMessage({ id: 'dropdownFilter.remove' }) }
        data-test='filterSummary.removeFilter'
        className='hint--medium hint--bottom-right showtime_filter__remove-tooltip'
        onClick={ onClear }
      >
        <SVGIcon
          classes='showtime-filter__remove-icon'
          iconName='mini/ui/ui-16px-1_simple-remove'
        />
      </div>
    </React.Fragment>
  );
});

export const DatesFilter = ({
  intl,
  setQueryDates,
  clearQueryDates,
  onFilterClear,
  startDate,
  endDate,
  initiallyExpanded,
}) => {
  const onClear = () => {
    clearQueryDates();
    onFilterClear();
  };

  useEffect(() => {
    setQueryDates(startDate, endDate);
  }, []);

  return (
    <DateSelectFilter
      noPadding
      intl={ intl }
      startDate={ startDate }
      endDate={ endDate }
      onStartDateChange={ value => setQueryDates(value, endDate) }
      onEndDateChange={ value => setQueryDates(startDate, value) }
      buttonLabel={ <DateFilterButton { ...{ onClear, startDate, endDate } } /> }
      iconName='mini/ui/ui-16px-1_calendar-60'
      iconPosition='left'
      className='showtime-filter showtime-filter__dates'
      dataTest='filterDropdown.dates'
      dropdownClassName={ classNames(
        'showtime-scores-controls__filters-item-menu--no-padding',
        'showtime-filter__dates-menu'
      ) }
      minDate={ moment({ year: 2020, month: 7, day: 20 }) }
      maxDate={ moment().startOf('day') }
      initiallyExpanded={ initiallyExpanded }
      dropdownContent={ <DateFilterMenu { ...{ setQueryDates, startDate, endDate } } /> }
    />
  );
};

DatesFilter.propTypes = {
  setQueryDates: PropTypes.func.isRequired,
  clearQueryDates: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment().constructor).isRequired,
  endDate: PropTypes.instanceOf(moment().constructor).isRequired,
  initiallyExpanded: PropTypes.bool.isRequired,
};

export default withDatesQuery(filterQueryKeys[filterTypes.DATES])(DatesFilter);
