export default (element, { offset, behavior = 'smooth' } = {}) => {
  if (offset) {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    return window.scrollTo({
      top: offsetPosition,
      behavior,
    });
  }
  return element.scrollIntoView({ behavior });
};
