import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import ColumnIcon from './ColumnIcon';
import ConfirmableButton from '../../../common/ConfirmableButton';
import { TaskWidgetSelectors } from '../../../task-wizard/selectors';
import Button from '../../../common/Button';
import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

const { removeColumn, duplicateColumn } = EditWidgetActions;
const { columnIsReferencedByLogicSelector } = TaskWidgetSelectors;

const EditableColumnHeader = ({
  cellType, children, columnIndex, fieldName,
}) => {
  const formatBuilderMessage = createIntlMessageFormatter(useIntl(), 'editWidget.builder');

  const dispatch = useDispatch();
  const onRemove = () => dispatch(removeColumn(columnIndex, fieldName));
  const onDuplicate = () => dispatch(duplicateColumn(columnIndex, fieldName));
  const columnIsReferencedByLogic = window.config.features.logicJumps
    && useSelector(state => columnIsReferencedByLogicSelector(state, { columnIndex }));

  return (
    <div className='showtime-record-log-builder-field__header'>
      <ColumnIcon cellType={ cellType } />
      <h4 className='showtime-record-log-builder-field__header-title'>
        { children }
      </h4>
      <div className='showtime-record-log-builder-field__header-actions'>
        <div className='showtime-record-log-builder-field__header-action'>
          <Button
            onClick={ onDuplicate }
            className='showtime-button showtime-button--lowlight-subtle showtime-button--small hint--top-left hint--info'
            dataHint={ formatBuilderMessage('duplicateColumnHint') }
            iconName='custom/copy-task-24px'
            dataTest='widget.columns.duplicate'
          />
          <ConfirmableButton
            onConfirm={ onRemove }
            buttonClasses='showtime-button showtime-button--lowlight-subtle showtime-button--small hint--top-left hint--error'
            iconClasses='showtime-button__icon'
            dataTest='widget.columns.delete'
            dataHint={ formatBuilderMessage('deleteColumnHint') }
            buttonIcon='mini/ui/ui-16px-1_trash-simple'
            title={ formatBuilderMessage('deleteColumnConfirmation.title') }
            description={ (
              <FormattedMessage
                id='editWidget.builder.deleteColumnConfirmation.description'
                values={ { br: <br />, columnIsReferencedByLogic } }
              />
            ) }
            confirm={ formatBuilderMessage('deleteColumnConfirmation.confirm') }
            abort={ formatBuilderMessage('deleteColumnConfirmation.abort') }
            align='left'
          />
        </div>
      </div>
    </div>
  );
};

EditableColumnHeader.propTypes = {
  cellType: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  columnIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditableColumnHeader;
