import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ConfirmableButton from '../common/ConfirmableButton';

const BulkDeleteButton = ({
  label, title, description, abort, confirm, onConfirm, onAbort, classes,
}) => (
  <ConfirmableButton
    { ...{
      buttonClasses: classNames({
        'showtime-link': true,
        'showtime-link--menu-item': true,
        'showtime-link--default': true,
        'showtime-link--menu-item-auto-width': true,
      }),
      isDestructive: true,
      dataTest: 'taskplanner.delete',
      classes,
      buttonLabel: label,
      title,
      description,
      abort,
      confirm,
      onConfirm,
      onAbort,
    } }
  />
);

BulkDeleteButton.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  abort: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  classes: PropTypes.string.isRequired,
};

export default BulkDeleteButton;
