import _ from 'lodash';
import fp from 'lodash/fp';
import { TaskWidgetConstants, TaskWizardConstants } from '../constants';
import { TaskConstants } from '../../task/constants';
import { RequestConstants } from '../../request';
import { WhiteListedCellTypes } from '../../widget/data-capture/v2/constants';
import { DATA_TYPE_REGEX } from '../../widget/constants';

const removeUnsupportedColumns = widgets => widgets.map((widget) => {
  if (!DATA_TYPE_REGEX.dataCapture.test(widget.widget_data_type)) return widget;

  const columns = _.chain(widget)
    .get('settings.public.columns', [])
    .filter(({ cellType }) => WhiteListedCellTypes.includes(cellType)).value();

  return fp.set('settings.public.columns', columns, widget);
});

export const taskWidgetReducer = function (state, action) {
  const initialState = {
    content: {
      widget: 0,
      availableIntegrations: [],
      availableIntegrationsLoaded: false,
      isEmpty: true,
    },
    originalContent: {
      widget: 0,
    },
    isExpanded: false,
  };
  const currState = state || _.extend({}, initialState);

  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return _.merge({}, currState, {
        content: {
          widget: Number(action.content.widget_config_id),
          isEmpty: !action.content.widget_config_id,
        },
        isExpanded: Boolean(action.content.integration),
        originalContent: {
          widget: Number(action.content.widget_config_id),
          isEmpty: !action.content.widget_config_id,
        },
      });
    case RequestConstants.getLoadedActionType(
      TaskWidgetConstants.FETCH_AVAILABLE_INTEGRATIONS):
      return {
        ...currState,
        content: {
          ...currState.content,
          availableIntegrations: removeUnsupportedColumns(action.content.availableIntegrations),
          availableIntegrationsLoaded: true,
        },
      };
    case TaskWidgetConstants.CHANGE:
      return _.merge({}, currState, {
        content: {
          widget: action.content ? (Number(action.content) || action.content) : action.content,
          isEmpty: !action.content,
        },
        isExpanded: action.content === '' ? false : currState.isExpanded,
      });
    case TaskWidgetConstants.EXPAND:
      return _.extend({}, currState, {
        isExpanded: true,
      });
    case TaskConstants.RESET:
      return {
        ...initialState,
        content: {
          ...initialState.content,
          availableIntegrations: currState.content.availableIntegrations,
          availableIntegrationsLoaded: currState.content.availableIntegrationsLoaded,
        },
      };
    default:
      return currState;
  }
};
