import _ from 'lodash';
import { TimeslotConstants } from '../constants';
import { RequestActions, RequestConstants } from '../../request';

export const TimeslotActions = new _.extend({}, {
  fetchDefaultTimeslots(organizationId) {
    return RequestActions.request({
      key: TimeslotConstants.TIMESLOTS_LOADED,
      method: RequestConstants.GET,
      url: TimeslotConstants.ORGANIZATION_TIMESLOTS_URL(organizationId),
    });
  },
});
