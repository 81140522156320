import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route } from 'react-router';
import classnames from 'classnames';

import scrollToComponent from '../common/scrollToComponent';
import OverlayFileViewer from './OverlayFileViewer';
import { TaskReportsSelectors } from './selectors';
import TaskReportActions from './TaskReportActions';
import TaskReportsHeader from './TaskReportsHeader';
import TaskReportsSidebar from './TaskReportsSidebar';
import Tasks from './Tasks';
import withViewportWidth from '../common/withViewportWidth';

import { FILE_PATH, PREVIEW_TASKS_PDF, FORCE_VIEW_FILTERS_WIDTH } from './constants';

import getRefHeight from '../common/getRefHeight';
import getStickyOffset from '../common/getStickyOffset';
import { UserRole, getCurrentUserRole } from '../application/UserRole';

export const TaskReports = ({
  searchTerm, hasContent, showFilters, showSidebar, width, exportModeEnabled,
  hideShareMenu, showShortcutMenu,
}) => {
  const [headerBodyHeight, setHeaderBodyHeight] = useState(0);
  const [exportHeaderHeight, setExportHeaderHeight] = useState(0);

  const listRef = useRef();
  const headerRef = useRef();
  const headerBodyRef = useRef();
  const exportHeaderRef = useRef();

  const [PDFOverlay, setPDFOverlayComponent] = useState(null);

  useEffect(() => {
    if (listRef.current) {
      const offset = getRefHeight(headerRef);
      scrollToComponent(listRef.current, { offset });
    }
  }, [searchTerm]);

  useEffect(() => {
    setHeaderBodyHeight(getRefHeight(headerBodyRef));
    setExportHeaderHeight(getRefHeight(exportHeaderRef));
  });

  if (!hideShareMenu && !PDFOverlay) {
    import('./Export/PDFOverlay')
      .then((moduleWithReactPDFDependencyThatBreaksSomeDevicesUsingTrail) => {
        setPDFOverlayComponent(
          moduleWithReactPDFDependencyThatBreaksSomeDevicesUsingTrail.default
        );
      });
  }

  const listClasses = classnames(
    'showtime-task-reports__list', {
      'has-content': hasContent,
    }
  );

  const taskHeaderStickyOffset = (exportHeaderHeight || headerBodyHeight) + getStickyOffset(width);

  return (
    <div className='showtime-task-reports'>
      { showSidebar && <TaskReportsSidebar /> }
      <div className='showtime-task-reports__main'>
        <TaskReportsHeader
          width={ width }
          bodyRef={ headerBodyRef }
          exportHeaderRef={ exportHeaderRef }
          exportHeaderHeight={ exportHeaderHeight }
          ref={ headerRef }
          showShareMenu={
            !hideShareMenu &&
              !getCurrentUserRole()
                .isPermissionDisabled(UserRole.permissionTypes.DOWNLOAD_TASK_REPORTS)
          }
          showFilters={ showFilters }
          showShortcutMenu={ showShortcutMenu }
          exportModeEnabled={ exportModeEnabled }
        />
        <div className={ listClasses } ref={ listRef }>
          { showFilters && <TaskReportActions /> }
          <Tasks stickyOffset={ taskHeaderStickyOffset } />
        </div>
      </div>
      <Route path={ `/:ignoredBaseRoute*${FILE_PATH}` } component={ OverlayFileViewer } />
      { PDFOverlay && <Route path={ PREVIEW_TASKS_PDF } component={ () => PDFOverlay } /> }
    </div>
  );
};

TaskReports.propTypes = {
  hasContent: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  showFilters: PropTypes.bool.isRequired,
  showSidebar: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  exportModeEnabled: PropTypes.bool.isRequired,
  hideShareMenu: PropTypes.bool,
  showShortcutMenu: PropTypes.bool.isRequired,
};

TaskReports.defaultProps = {
  hideShareMenu: false,
};

const mapStateToProps = (state, ownProps) => ({
  searchTerm: TaskReportsSelectors.searchTermSelector(state, ownProps),
  hasContent: TaskReportsSelectors.listHasVisibleContentSelector(state, ownProps),
  showFilters: TaskReportsSelectors.showFiltersSelector(state, ownProps),
  exportModeEnabled: TaskReportsSelectors.isExportModeSelector(state),
});

const mapViewportWidthToProps = (width, { hideSidebar }) => {
  const wideEnoughForSidebar = width >= FORCE_VIEW_FILTERS_WIDTH;
  return ({
    width,
    forceDisplayFilters: width >= FORCE_VIEW_FILTERS_WIDTH,
    showSidebar: wideEnoughForSidebar && !hideSidebar,
    showShortcutMenu: !wideEnoughForSidebar && !hideSidebar,
  });
};

export default compose(
  withViewportWidth(mapViewportWidthToProps),
  connect(mapStateToProps)
)(TaskReports);
