import clientMessageSub from './clientMessageSub';
import handleOffline from './handleOffline';
import handleOnline from './handleOnline';
import handleVisibilityChange from './handleVisibilityChange';
import checkOnlineInterval from './checkOnlineInterval';
import checkCache from './checkCache';
import doRegistration from './doRegistration';

export default async (name, client = window) => {
  if ('serviceWorker' in navigator) {
    await doRegistration(name);

    navigator.serviceWorker.addEventListener('message', clientMessageSub);

    if (!navigator.onLine) {
      handleOffline(0);
    }

    client.addEventListener('offline', () => {
      handleOffline();
    });

    client.addEventListener('online', () => {
      handleOnline();
    });

    client.addEventListener('visibilitychange', () => {
      handleVisibilityChange();
    });

    checkOnlineInterval();
    checkCache();
  }
};
