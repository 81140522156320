import { TaskWizardConstants } from '../constants';
import { RequestConstants } from '../../request';

const initialState = '';

export const taskStatusReducer = function (state, action) {
  state = state || initialState;
  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return action.content.status;
    default:
      return state || initialState;
  }
};
