import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import Nav from './Nav';
import Record from './Record';
import ListActions from './ListActions';
import { classNamePrefix, SECTION_TITLE } from '../constants';

class DataCaptureList extends Component {
  get activeVisibleIndex() {
    const { activeRowId, rows } = this.props;
    return _.findIndex(rows, { rowId: activeRowId });
  }

  goToNextRecord = () => {
    const { setActiveRowId, rows } = this.props;
    setActiveRowId(
      rows[this.activeVisibleIndex + 1].rowId
    );
  };

  goToPreviousRecord = () => {
    const { setActiveRowId, rows } = this.props;
    setActiveRowId(
      rows[this.activeVisibleIndex - 1].rowId
    );
  };

  render() {
    const {
      activeRowId,
      addEmptyRow,
      columns,
      minimumRows,
      displayNavElements,
      hideTaskContentComments,
      errors,
      isNewListIndex,
      onDeleteRow,
      onEdit,
      onTimerChange,
      restrictRows,
      rows,
      setActiveRowId,
      setTableMode,
      widgetId,
      readOnly,
      router,
      routePrefix,
      taskHeaderHeight,
      taskName,
      taskId,
      widgetName,
      actions,
      timers,
      timerSettings,
      preview,
      isTaskCompleted,
    } = this.props;
    const listId = `data-capture-list-${widgetId}`;
    const activeRow = _.find(rows, { rowId: activeRowId });
    const firstColumn = _.find(columns, obj => obj.cellType !== SECTION_TITLE) || {};
    const firstColumnCellSettings = firstColumn.cellSettings || {};
    const firstFieldKey = firstColumnCellSettings.fieldName;
    const firstRowName = firstColumnCellSettings.rowName;

    const recordClasses = classNames(
      `${classNamePrefix}-content`,
      {
        [`${classNamePrefix}-content--single-record`]: !displayNavElements,
      }
    );

    const errorsForActiveRow = errors[activeRow.rowId] || {};

    return (
      <div className={ classNamePrefix }>
        {displayNavElements && (
          <Nav
            activeRowId={ activeRowId }
            addEmptyRow={ addEmptyRow }
            classNamePrefix={ classNamePrefix }
            errors={ errors }
            firstFieldKey={ firstFieldKey }
            firstRowName={ firstRowName }
            listId={ listId }
            restrictRows={ restrictRows }
            rows={ rows }
            setActiveRowId={ setActiveRowId }
            columns={ columns }
          />
        )}

        <div
          className={ recordClasses }
          id={ listId }
        >
          <Record
            visibleActiveRowPosition={ this.activeVisibleIndex + 1 }
            activeRow={ activeRow }
            columns={ columns }
            errors={ errorsForActiveRow }
            isNewListIndex={ isNewListIndex }
            minimumRows={ minimumRows }
            displayNavElements={ displayNavElements }
            onDelete={ onDeleteRow }
            onEdit={ onEdit }
            restrictRows={ restrictRows }
            rowCount={ rows.length }
            widgetId={ widgetId }
            readOnly={ readOnly }
            router={ router }
            taskHeaderHeight={ taskHeaderHeight }
            routePrefix={ routePrefix }
            taskName={ taskName }
            isTaskCompleted={ isTaskCompleted }
            taskId={ taskId }
            widgetName={ widgetName }
            actions={ actions }
            onTimerChange={ onTimerChange }
            timers={ timers }
            timerSettings={ timerSettings }
            hideTaskContentComments={ hideTaskContentComments }
            preview={ preview }
          />

          {displayNavElements && (
            <ListActions
              isFirstRecord={ this.activeVisibleIndex === 0 }
              isLastRecord={ this.activeVisibleIndex === (rows.length - 1) }
              onNextRecordClick={ this.goToNextRecord }
              onPreviousRecordClick={ this.goToPreviousRecord }
              recordsLength={ rows.length }
              setTableMode={ setTableMode }
              restrictRows={ restrictRows }
              addEmptyRow={ addEmptyRow }
            />
          )}
        </div>
      </div>
    );
  }
}

DataCaptureList.propTypes = {
  activeRowId: PropTypes.number.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  addEmptyRow: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  hideTaskContentComments: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  isNewListIndex: PropTypes.bool.isRequired,
  minimumRows: PropTypes.number.isRequired,
  displayNavElements: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  restrictRows: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  setActiveRowId: PropTypes.func.isRequired,
  setTableMode: PropTypes.func.isRequired,
  widgetId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  routePrefix: PropTypes.string,
  taskHeaderHeight: PropTypes.number,
  actions: PropTypes.array,
  onTimerChange: PropTypes.func.isRequired,
  taskName: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  widgetName: PropTypes.string.isRequired,
  timers: PropTypes.array.isRequired,
  timerSettings: PropTypes.object.isRequired,
  preview: PropTypes.bool.isRequired,
  isTaskCompleted: PropTypes.bool.isRequired,
};

DataCaptureList.defaultProps = {
  restrictRows: false,
  routePrefix: '',
  taskHeaderHeight: 0,
  actions: [],
};

export default DataCaptureList;
