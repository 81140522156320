import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { widgetModes } from '../../constants';
import SVGIcon from '../../../common/SVGIcon';
import withPureComponent from '../../../common/withPureComponent';

const WidgetModeToggle = ({
  id,
  title,
  value,
  onChange,
  intl,
}) => {
  const namePrefix = `widget-mode-toggle-${id}`;
  return (
    <div className='showtime-widget-v2__mode-toggle' role='group'>
      <h3 className='showtime-widget-v2__mode-toggle-title'>{title}</h3>
      {
        Object.keys(widgetModes).map((mode) => {
          const modeValue = widgetModes[mode];
          const isActive = value === modeValue;
          const labelClassNames = classNames(
            'showtime-widget-v2__mode-toggle-label',
            'hint--info',
            'hint--capitalize',
            'hint--small',
            'hint--bottom-left',
            {
              'showtime-widget-v2__mode-toggle-label--active': isActive,
            }
          );
          const key = `mode-toggle--${mode}`;
          return (
            <div key={ key }>
              <label
                className={ labelClassNames }
                htmlFor={ `${namePrefix}-${modeValue}` }
                data-hint={ `${modeValue} ${intl.formatMessage({ id: 'trail.widgets.data_capture.view' })}` }
              >
                <SVGIcon
                  classes='showtime-widget-v2__mode-toggle-label-icon'
                  iconName={ `custom/widget-mode-${modeValue}` }
                />
                <span className='showtime-widget-v2__mode-toggle-label-text'>
                  {modeValue}
                </span>
              </label>
              <input
                checked={ isActive }
                id={ `${namePrefix}-${modeValue}` }
                name={ namePrefix }
                onChange={ () => onChange(modeValue) }
                type='radio'
                value={ modeValue }
              />
            </div>
          );
        })
      }
    </div>
  );
};
WidgetModeToggle.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(widgetModes)).isRequired,
  intl: PropTypes.object.isRequired,
};
export default compose(
  injectIntl,
  withPureComponent
)(WidgetModeToggle);
