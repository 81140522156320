import React from 'react';
import PropTypes from 'prop-types';
import ReactInlineSVG from 'react-inlinesvg';
import { injectIntl } from 'react-intl';
import { IconStore } from '../common/legacy/store';
import SVGIcon from '../common/SVGIcon';

const SaveStatus = ({ saving, offline, intl }) => {
  const showOffline = offline;
  const showSaving = !offline && saving;
  const showSaved = !offline && !saving;
  const buttonClasses = 'button text disabled no-padding no-margin';
  const hide = 'hide';

  return (
    <div className='save-status shrink grid-block'>
      <button data-test='status.offline' disabled className={ showOffline ? buttonClasses : hide }>
        <SVGIcon
          classes='showtime-icon showtime-icon--size-16'
          iconName='mini/ui/ui-16px-2_alert-circle-error'
        />
        &nbsp;{ intl.formatMessage({ id: 'trail.widgets.header.offline' }) }
      </button>
      <button data-test='status.saving' disabled className={ showSaving ? buttonClasses : hide }>
        <ReactInlineSVG
          className='icon-gray-light'
          src={ IconStore.getLoadingIcon() }
        />
        { intl.formatMessage({ id: 'trail.widgets.header.saving' }) }
      </button>
      <button data-test='status.saved' disabled className={ showSaved ? buttonClasses : hide }>
        <SVGIcon
          classes='showtime-icon showtime-icon--size-16'
          iconName='mini/ui/ui-16px-1_check-circle-08'
        />
        &nbsp;{ intl.formatMessage({ id: 'trail.widgets.header.saved' }) }
      </button>
    </div>
  );
};

SaveStatus.propTypes = {
  saving: PropTypes.bool.isRequired,
  offline: PropTypes.bool,
};

SaveStatus.defaultProps = {
  offline: false,
};

export default injectIntl(SaveStatus);
