import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { TaskReportsActions } from '../actions';
import Button from '../../common/Button';

const CancelExportButton = ({ intl: { formatMessage }, onClick }) => (
  <Button
    className='showtime-button showtime-button--default showtime-button--small showtime-button--adjacent'
    onClick={ onClick }
    dataTest='taskReports.cancelExport'
  >
    { formatMessage({ id: 'taskReports.share.cancel' })}
  </Button>
);

CancelExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick: () => {
    dispatch(TaskReportsActions.setSelectedTaskIds([]));
    dispatch(TaskReportsActions.setExportMode(false));
  },
});

export default compose(
  injectIntl,
  connect(null, mapDispatchToProps)
)(CancelExportButton);
