const widgetSelectorHelper = (object, type) => {
  const matchingModelTypes =
    Object.keys(object)
      .filter(keyRegExp => new RegExp(keyRegExp).test(type));

  if (matchingModelTypes.length === 1) {
    return object[matchingModelTypes[0]];
  }

  return null;
};

export default widgetSelectorHelper;
