import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddFilterMenu from './AddFilterMenu';

const insertAt = (arr, index, item) => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
];

const Filters = ({
  availableFilters, setFilters,
  className, dataTest,
  filterComponents, filterLabelPaths, filterIconPaths, filterMetric,
}) => {
  const [openFilter, setOpenFilter] = useState(null);
  const [openFilterIndex, setOpenFilterIndex] = useState(null);

  const handleClearOpenFilter = () => {
    setOpenFilter(null);
    setOpenFilterIndex(null);
  };

  const handleSetOpenFilter = (filter, index) => {
    setOpenFilter(filter);
    setOpenFilterIndex(index);
  };

  const isOpen = filter => filter === openFilter;

  const closedFilters = setFilters.filter(filter => !isOpen(filter));
  const setAndOpenFilters = openFilter
    ? insertAt(closedFilters, openFilterIndex, openFilter)
    : setFilters;

  const filterOptions = _.difference(availableFilters, setAndOpenFilters);

  return (
    <div className={ className } data-test={ dataTest }>
      {setAndOpenFilters.map((filter) => {
        const FilterDropdown = filterComponents[filter];
        return (
          <FilterDropdown
            key={ filter }
            onFilterClear={ handleClearOpenFilter }
            onClickOutside={ handleClearOpenFilter }
            onExpand={ () => handleSetOpenFilter(filter, setFilters.indexOf(filter)) }
            initiallyExpanded={ isOpen(filter) }
            filterMetric={ filterMetric }
          />
        );
      })}
      <AddFilterMenu
        filterOptions={ filterOptions }
        onAdd={ filter => handleSetOpenFilter(filter, setFilters.length) }
        filterLabelPaths={ filterLabelPaths }
        filterIconPaths={ filterIconPaths }
        dataTest={ dataTest }
      />
    </div>
  );
};

Filters.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  setFilters: PropTypes.array.isRequired,
  filterComponents: PropTypes.object.isRequired,
  filterLabelPaths: PropTypes.object.isRequired,
  filterIconPaths: PropTypes.object.isRequired,
  filterMetric: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTest: PropTypes.string,
};

Filters.defaultProps = {
  className: '',
  dataTest: null,
};

export default Filters;
