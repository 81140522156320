import queryBuilder from '../../common/api/queryBuilder';
import { WidgetDataConstants } from '../../trail/constants';

export default {
  CURRENCY_PREFIX: '£',
  WIDGET_CONFIGURATION_LOADED: 'widget_configuration.loaded',
  FETCH_WIDGETS_KEY: '@WIDGETS fetch_widgets_key',
  fetchWidgetsUrl: widgetIds => queryBuilder(
    WidgetDataConstants.BATCH_ROUTE, { 'ids[]': widgetIds }),
  getWidgetConfigurationUrl(organizationId, widgetConfigurationId) {
    return `/api/organizations/${organizationId}/widget_configurations/${widgetConfigurationId}`;
  },
  updateWidgetConfigurationUrl: (organizationId, widgetConfigurationId) => (
    `/api/organizations/${organizationId}/widget_configurations/${widgetConfigurationId}`
  ),
  createWidgetConfigurationUrl: organizationId => (
    `/api/organizations/${organizationId}/widget_configurations`
  ),
  CLEAR_COLUMN_OPTION_EXCEPTIONS: '@EDIT_WIDGET clear_column_option_exceptions',
  CLEAR_COLUMN_SCORES: '@EDIT_WIDGET clear_column_scores',
  CREATE_WIDGET_KEY: '@EDIT_WIDGET post_widget_settings',
  UPDATE_WIDGET_KEY: '@EDIT_WIDGET put_widget_settings',
  SET_PUBLIC_SETTINGS: '@EDIT_WIDGET set_public_settings',
  SET_SETTINGS: '@EDIT_WIDGET set_settings',
  SET_COLUMN_SETTINGS: '@EDIT_WIDGET set_column_settings',
  ADD_COLUMN: '@EDIT_WIDGET add_column',
  REMOVE_COLUMN: '@EDIT_WIDGET remove_column',
  REMOVE_COLUMN_LOGIC_ITEM: '@EDIT_WIDGET remove_column_logic_item',
  DUPLICATE_COLUMN: '@EDIT_WIDGET duplicate_column',
  SET_COLUMN_TITLE: '@EDIT_WIDGET set_column_title',
  SET_DEFAULT_COLUMN_SCORES: '@EDIT_WIDGET set_default_column_scores',
  VALIDATE_COLUMN_TITLE: '@EDIT_WIDGET validate_column_title',
  VALIDATE_COLUMN_DROPDOWN_OPTION_LABEL: '@EDIT_WIDGET validate_column_dropdown_option_label',
  SET_WIDGET_REQUEST: '@EDIT_WIDGET set_request',
  EXPAND_COLUMN: '@EDIT_WIDGET expand_column',
  SORT_COLUMNS: '@EDIT_WIDGET sort_columns',
  CHANGE_ACCEPTABLE_RANGE: '@EDIT_WIDGET change_acceptable_range',
  ADD_COLUMN_DROPDOWN_OPTION: '@EDIT_WIDGET add_column_dropdown_option',
  REMOVE_COLUMN_DROPDOWN_OPTION: '@EDIT_WIDGET remove_column_dropdown_option',
  CHANGE_COLUMN_DROPDOWN_OPTION_LABEL: '@EDIT_WIDGET change_column_dropdown_option_label',
  CLEAR_RULES_FROM_COLUMN: '@EDIT_WIDGET clear_rules_from_column',
  ADD_RULE_TO_COLUMN: '@EDIT_WIDGET add_rule_to_column',
  SET_DEPENDENT_RULE_FOR_COLUMN: '@EDIT_WIDGET set_dependent_rule_for_column',
  CLEAR_ALL_VALIDATION_FROM_COLUMN: '@EDIT_WIDGET clear_all_validation_from_column',
  REMOVE_COLUMN_PREFIX_AND_SUFFIX: '@EDIT_WIDGET remove_column_prefix_and_suffix',
  CLEAR_WIDGET_STATE: '@EDIT_WIDGET clear_widget_state',
  SET_WIDGET_AS_NEW: '@EDIT_WIDGET set_widget_as_new',
  WIDGET_EDIT_REQUEST: 'widget_configuration.edit_requested',
  DATA_CAPTURE_CONFIG_OPTIONS_URL: 'http://answers.trailapp.com/trail-administration/' +
    'managing-tasks/logging-records-with-forms-and-widgets',
  TYPEFORM_CONFIG_OPTIONS_URL: 'https://www.typeform.com/help/question-types/',
};

export const success = 'success';
export const error = 'error';
export const loading = 'loading';

export const DATA_TYPE_REGEX = {
  dataCapture: /DataCapture.*WidgetData/,
  form: /Form.*WidgetData/,
  mysteryDiner: /MysteryDiner.*WidgetData/,
};

export const EMPTY_SCORE = 'N/A';
export const DEFAULT_SCORE = 'N/A';

export const widgetModes = {
  LIST: 'list',
  TABLE: 'table',
};

export const optionsDisplay = {
  BUTTONS: 'buttons',
  DROPDOWN: 'dropdown',
};

export const logicConditions = {
  selected: 'selected',
  notSelected: 'notSelected',
};
export const logicThenTypes = {
  jumpToField: 'jumpToField',
};
export const JUMP_TO_END_OF_RECORD_LOG = 'jumpToEnd';

export const DATA_CAPTURE_ACTION_TYPES = {
  CLEAR_SKIPPED_FIELD_COMMENTS: '@DATA_CAPTURE clear_skipped_field_comments',
  SHOW_FIELD_ERRORS: '@DATA_CAPTURE show_field_errors',
  SHOW_ROW_ERRORS: '@DATA_CAPTURE show_row_errors',
  SET_VIEW_MODE: '@DATA_CAPTURE set_view_mode',
  SHOW_ALL_ERRORS: '@DATA_CAPTURE show_all_errors',
};
