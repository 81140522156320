import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Virtuoso } from 'react-virtuoso';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { TaskReportsActions } from '../actions';
import * as requestStatus from '../../common/requestStatuses';
import { TaskReportsSelectors } from '../selectors';
import { PAGINATION_LIMIT, getDefaultFilter } from '../constants';
import Task from './Task';
import EmptyState from '../../common/EmptyState';
import TasksFooter from './TasksFooter';

const PRERENDERED_PX = 1200;
const isTest = process.env.NODE_ENV === 'test';
const INITIAL_ITEM_COUNT = isTest ? PAGINATION_LIMIT : 0;

const NoSearchResults = ({ formatMessage }) => (
  <EmptyState
    dataTest='tasks.noSearchResults'
    messageTitle={ formatMessage({ id: 'taskReports.noSearchResults.title' }) }
    messageDescription={ formatMessage({ id: 'taskReports.noSearchResults.description' }) }
    iconName='empty-states/task_reports_no_results'
  />
);

NoSearchResults.propTypes = {
  formatMessage: PropTypes.func.isRequired,
};

const NoTasks = ({ formatMessage }) => (
  <EmptyState
    dataTest='tasks.noTasks'
    messageTitle={ formatMessage({ id: 'taskReports.noTasks.title' }) }
    messageDescription={ formatMessage({ id: 'taskReports.noTasks.description' }) }
    iconName='empty-states/task_reports_no_tasks'
  />
);

NoTasks.propTypes = {
  formatMessage: PropTypes.func.isRequired,
};

const TasksLoader = ({
  tasksLength,
  isLoading,
  noTasksLoaded,
  clearSelectedTasks,
  onClearTasks,
  onLoadTasks,
  hasMore,
  hasErrored,
  location,
  history,
  filters,
  stickyOffset,
  intl: { formatMessage },
  readyToQueryTimelineTasks,
}) => {
  const mountCompletedRef = useRef();
  const isMounting = () => !mountCompletedRef.current;
  const handleLoadTasks = (includeCount = false) => {
    let { search } = location;
    if (isMounting() && !location.search) {
      search = `?${getDefaultFilter()}`;
    }
    onLoadTasks({ ...location, search }, includeCount);
  };
  const loadTasksWithoutCount = () => handleLoadTasks(false);
  const loadTasksWithCount = () => handleLoadTasks(true);

  const lastFiltersRef = useRef();
  useEffect(() => {
    const hasPriorFilters = (lastFiltersRef.current || []).some(_.size);
    if (isMounting() || hasPriorFilters) onClearTasks();

    if (isMounting() && !location.search) {
      history.replace({ search: getDefaultFilter() });
    }
    clearSelectedTasks();

    if (!readyToQueryTimelineTasks) return;
    loadTasksWithCount();

    lastFiltersRef.current = filters;
    mountCompletedRef.current = true;
  }, [...filters, readyToQueryTimelineTasks]);

  const loadMoreItems = (hasErrored || isLoading || !hasMore) ? () => {} : loadTasksWithoutCount;

  if (noTasksLoaded) {
    if (filters.some(_.size)) return <NoSearchResults formatMessage={ formatMessage } />;
    return <NoTasks formatMessage={ formatMessage } />;
  }

  return (
    <Virtuoso
      useWindowScroll
      className='showtime-task-reports__list-items'
      data={ _.range(0, tasksLength) }
      initialItemCount={ INITIAL_ITEM_COUNT }
      endReached={ loadMoreItems }
      itemContent={ index => <Task index={ index } stickyOffset={ stickyOffset } /> }
      overscan={ PRERENDERED_PX }
      components={ { Footer: () => <TasksFooter onRetry={ handleLoadTasks } /> } }
    />
  );
};

TasksLoader.propTypes = {
  tasksLength: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  clearSelectedTasks: PropTypes.func.isRequired,
  readyToQueryTimelineTasks: PropTypes.bool.isRequired,
  noTasksLoaded: PropTypes.bool.isRequired,
  onClearTasks: PropTypes.func.isRequired,
  onLoadTasks: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  stickyOffset: PropTypes.number,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

TasksLoader.defaultProps = {
  stickyOffset: 0,
};

const mapStateToProps = (state, ownProps) => ({
  tasksLength: TaskReportsSelectors.visibleTasksLengthSelector(state, ownProps),
  isLoading: requestStatus.isLoading(
    TaskReportsSelectors.timelineTasksRequestStatusSelector(state, ownProps)
  ),
  noTasksLoaded: TaskReportsSelectors.noTasksLoadedSelector(state, ownProps),
  hasMore: TaskReportsSelectors.shouldLoadMoreTasksSelector(state, ownProps),
  hasErrored: requestStatus.hasError(
    TaskReportsSelectors.timelineTasksRequestStatusSelector(state, ownProps)
  ),
  filters: TaskReportsSelectors.filterQueryValuesSelector(state, ownProps),
  readyToQueryTimelineTasks: TaskReportsSelectors.readyToQueryTimelineTasksSelector(
    state, ownProps),
});

const mapDispatchToProps = (dispatch) => {
  const {
    fetchTaskReports, clearTasks, setSelectedTaskIds,
  } = bindActionCreators(TaskReportsActions, dispatch);
  return {
    onLoadTasks: fetchTaskReports,
    onClearTasks: clearTasks,
    clearSelectedTasks: () => setSelectedTaskIds([]),
  };
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TasksLoader);
