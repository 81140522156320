export const OrganizationSettingsConstants = {
  GET_SETTINGS_KEY: 'organization_settings.get_settings_key',
  UPDATE_SETTINGS_KEY: 'organization_settings.update_settings_key',
  messages: {
    GET_SETTINGS_FAILED: 'settings.errors.getSettingsFailed',
    UPDATE_SETTINGS_FAILED: 'settings.errors.updateSettingsFailed',
  },
  getOrUpdateSettingsRoute(organizationId) {
    return `/api/v1/organizations/${organizationId}/settings`;
  },
  formatNotificationSettingsForApi(updateObject) {
    return { notifications: updateObject };
  },
};
