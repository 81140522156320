import _ from 'lodash';
import * as filestack from 'filestack-js';
import {
  FILE_TYPE,
  COMMENT_IMAGE_OPTION,
  GRID_IMAGE_OPTION,
  FILEPICKER_OPTIONS,
} from './constants';

const transformations = {
  [COMMENT_IMAGE_OPTION]: '/resize=width:500',
  [GRID_IMAGE_OPTION]: '/resize=width:150,height:150,fit:crop',
};

export const getFileCdnUrl = ({
  handle, policy, signature, renderImageOption,
}) => {
  const transformation = transformations[renderImageOption] || '';
  const security = `security=policy:${policy},signature:${signature}`;
  const apiKey = _.get(window, 'config.filestackApiKey', '');

  return `https://cdn.filestackcontent.com/${apiKey}${transformation}/${security}/${handle}`;
};

export const formatAttachmentFromFilestackResponse = ({
  filename,
  url,
  mimetype,
  size,
  key,
}) => {
  const attachment = {
    filename,
    mimetype,
    size,
    key,
    name: filename,
    previousName: filename,
    fileName: filename,
    url,
    filestackUrl: url,
    filestackHandle: url.split('/').pop(),
    mimeType: mimetype,
    fileSize: size,
    s3Key: key,
    type: FILE_TYPE,
  };

  return attachment;
};

export const filestackClient = ({
  security,
  onFileUploadFailed,
  onCancel = () => {},
  onUploadDone,
  onProgress = () => {},
}) => {
  const client = filestack.init(
    window.config.filestackApiKey,
    { security }
  );

  const onUpload = ({ filesUploaded, filesFailed }) => {
    if (!Array.isArray(filesUploaded)) {
      filesUploaded = [filesUploaded];
    }
    if (!Array.isArray(filesFailed)) {
      filesFailed = [filesFailed];
    }
    onUploadDone({ filesUploaded: filesUploaded.map(formatAttachmentFromFilestackResponse) });
  };

  const open = (multiple) => {
    client.picker({
      ...FILEPICKER_OPTIONS,
      maxFiles: multiple ? window.config.filestackMaxFiles : 1,
      onUploadDone: onUpload,
      onFileUploadFailed,
      onCancel,
      onFileUploadProgress: onProgress,
    }).open();
  };

  const capture = async (file) => {
    try {
      const filesUploaded = await client.upload(
        file, { onProgress }
      );
      onUpload({ filesUploaded: [filesUploaded], filesFailed: [] });
    } catch (error) {
      onFileUploadFailed({ error, filesFailed: [file] });
    }
  };

  return { capture, open };
};
