import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import DataCaptureScoreSelectors from '../../selectors/dataCaptureScoreSelectors';

const RecordScore = ({
  score, scoreOutOf, longForm, intl: { formatMessage }, defaultValue,
}) => scoreOutOf
  ? (
    <div
      className='showtime-record-log-score showtime-record-log-score--record'
      data-test='score.row'
    >
      { longForm && formatMessage({ id: 'trail.widgets.data_capture.recordScore' }) }{ score }/{scoreOutOf}
    </div>
  )
  : defaultValue;

RecordScore.propTypes = {
  score: PropTypes.number.isRequired,
  scoreOutOf: PropTypes.number.isRequired,
  longForm: PropTypes.bool,
  defaultValue: PropTypes.node,
};

RecordScore.defaultProps = {
  longForm: false,
  defaultValue: null,
};

export default compose(
  injectIntl,
  connect(DataCaptureScoreSelectors.rowScoreSelector)
)(RecordScore);
