import _ from 'lodash';
import { TaskTrailsSelectorConstants, TaskWizardConstants } from '../constants';
import { TaskConstants } from '../../task/constants';
import { RequestConstants } from '../../request';

export const taskTrailsSelectorReducer = (function () {
  function defaultState() {
    return {
      isExpanded: true,
      content: {
        isEmpty: true,
        value: null,
      },
      originalContent: {
        isEmpty: true,
        value: null,
      },
      allLocations: [],
    };
  }

  function loadLocations(state, action) {
    const value = action.content.locations;
    const isEmpty = false;
    return _.extend({}, state, {
      content: { value, isEmpty },
      originalContent: { value, isEmpty },
    });
  }

  function reset(state) {
    return _.extend(defaultState(), {
      allLocations: state.allLocations,
    });
  }

  function selectOrUnselectTrail(state, trail) {
    const ids = _.map(state.content.value, 'id');
    const selectedIds = _.xor(ids, [trail.id]);
    const selectedLocations = selectedIds.map(id => id === trail.id ? trail
      : _.find(state.content.value, location => location.id === id));
    return selectedLocations;
  }

  function setAllLocations(state, action) {
    const multiSiteDisabled = !window.config.features.multiSite;
    if (multiSiteDisabled && action.allLocations.length > 0) {
      const selected = selectOrUnselectTrail(state, action.allLocations[0]);
      return _.extend({}, state, {
        allLocations: action.allLocations,
        content: {
          value: selected,
          isEmpty: false,
        },
      });
    }

    return _.extend({}, state, {
      allLocations: action.allLocations,
    });
  }

  function updateSelectedLocations(state, action) {
    const oldSelected = state.content.value;
    let newSelected;
    if (action.content.value) {
      newSelected = selectOrUnselectTrail(state, action.content.value);
    } else {
      newSelected = oldSelected;
    }
    return _.extend({}, state, {
      content: {
        value: newSelected,
        isEmpty: false,
      },
    });
  }

  function expand(state, action) {
    let selectedLocations = action.content.value;
    let { originalContent } = state;
    if (selectedLocations == null) {
      selectedLocations = state.allLocations;
    } else {
      originalContent = {
        value: selectedLocations,
        isEmpty: false,
      };
    }
    return _.extend({}, state, {
      isExpanded: true,
      content: {
        value: selectedLocations,
        isEmpty: false,
      },
      originalContent,
    });
  }

  function deselectAllTrails(state) {
    const currState = state;
    return _.extend({}, currState, {
      content: {
        isEmpty: false,
        value: [],
      },
    });
  }

  function selectAllTrails(state) {
    const currState = state;
    return _.extend({}, currState, {
      content: {
        isEmpty: false,
        value: state.allLocations,
      },
    });
  }

  return function reducer(state, action) {
    const currState = state || defaultState();
    switch (action.type) {
      case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
        return loadLocations(currState, action);
      case TaskConstants.RESET:
        return reset(currState, action);
      case TaskTrailsSelectorConstants.SET_ALL_LOCATIONS:
        return setAllLocations(currState, action);
      case TaskTrailsSelectorConstants.EXPAND:
        return expand(currState, action);
      case TaskTrailsSelectorConstants.SELECT_ALL_TRAILS:
        return selectAllTrails(currState, action);
      case TaskTrailsSelectorConstants.DESELECT_ALL_TRAILS:
        return deselectAllTrails(currState, action);
      case TaskTrailsSelectorConstants.CHANGE:
        return updateSelectedLocations(currState, action);
      default:
        return currState;
    }
  };
}());
