import { IS_REQUIRED } from '../rules';

export default (ruleConfig, intl) => {
  if (!ruleConfig.mandatory) {
    return ruleConfig;
  }

  const alreadyHasRule = ruleConfig.rules &&
    ruleConfig.rules.find(({ key }) => key === IS_REQUIRED);

  if (alreadyHasRule) {
    return ruleConfig;
  }

  const { mandatoryWarningText } = ruleConfig;

  return {
    ...ruleConfig,
    rules: [
      ...ruleConfig.rules || [],
      {
        key: IS_REQUIRED,
        message: mandatoryWarningText || intl.formatMessage({ id: 'trail.widgets.data_capture.errors.blank' }),
      },
    ],
  };
};
