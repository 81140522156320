import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { EditWidgetActions } from '../../actions';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';
import { FREE_TEXT, FORMATTED_NUMBER, OPTIONS, FILE, SECTION_TITLE } from '../../data-capture/v2/constants';

import scrollToComponent from '../../../common/scrollToComponent';
import SVGIcon from '../../../common/SVGIcon';
import AnchoredMenu from '../../../common/menu/AnchoredMenu';
import Button from '../../../common/Button';

const cellTypeOptions = [{
  cellType: FREE_TEXT,
  label: 'textLabel',
  description: 'textDescription',
  iconPath: 'mini/business-finance/business-16px_cheque',
}, {
  cellType: FORMATTED_NUMBER,
  label: 'numberLabel',
  description: 'numberDescription',
  iconPath: 'mini/ui/ui-16px-1_number-CUSTOM',
}, {
  cellType: OPTIONS,
  label: 'optionsLabel',
  description: 'optionsDescription',
  iconPath: 'mini/arrows/arrows-16px-1_circle-down-40',
}, {
  cellType: FILE,
  label: 'fileLabel',
  description: 'fileDescription',
  iconPath: 'mini/ui/ui-16px-1_attach-87',
}, {
  cellType: SECTION_TITLE,
  label: 'sectionTitleLabel',
  description: 'sectionTitleDescription',
  iconPath: 'glyph/24px/text-editing/text-24px-glyph_capitalize',
}];

class AddColumnButton extends Component {
  state = { showColumnOptions: false };
  scrollRef = createRef();

  handleAddFieldClick = async () => {
    await this.setState({ showColumnOptions: true });
    scrollToComponent(this.scrollRef.current);
  };

  handleMenuClose = () => this.setState({ showColumnOptions: false });

  render() {
    const { onAdd, dataTest, intl } = this.props;
    const buttonClasses = classNames('showtime-button--block', 'showtime-button--add-field');
    const addFieldClasses = classNames({
      'showtime-record-log-builder-field-add': !this.props.divider,
      'showtime-record-log-builder-field-divider': this.props.divider,
    });
    const onColumnAddClick = (cellType) => {
      onAdd(cellType);
      this.handleMenuClose();
    };

    const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.addColumnButton');

    return (
      <div className={ addFieldClasses }>
        <a
          className='showtime-link'
          onClick={ this.handleAddFieldClick }
          ref={ this.scrollRef }
          { ...dataTest && { 'data-test': dataTest } }
        >
          <SVGIcon
            classes='showtime-link__icon'
            iconName='mini/ui/ui-16px-1_simple-add'
          />
          { this.props.divider ?
            null
            :
            <span classes='showtime-link__icon-text'>{ formatMessage('addColumn') }</span>
          }
        </a>
        { this.state.showColumnOptions && (
          <AnchoredMenu
            align='right'
            onClickOutside={ this.handleMenuClose }
          >
            {cellTypeOptions.map(({
              cellType, label, description, iconPath,
            }) => (
              <Button
                buttonClasses={ buttonClasses }
                onClick={ () => onColumnAddClick(cellType) }
                label={ formatMessage(label) }
                secondaryLabel={ formatMessage(description) }
                dataTest={ `widget.columns.add${cellType}` }
                iconName={ iconPath }
              />
            ))}
          </AnchoredMenu>
        )}
      </div>
    );
  }
}

AddColumnButton.propTypes = {
  divider: PropTypes.bool,
  dataTest: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
};

AddColumnButton.defaultProps = {
  divider: false,
  dataTest: undefined,
};

const mapDispatchToProps = (dispatch, { position }) => {
  const { addColumn } = bindActionCreators(EditWidgetActions, dispatch);
  return { onAdd: cellType => addColumn(cellType, position + 1) };
};

export default compose(
  connect(null, mapDispatchToProps),
  injectIntl
)(AddColumnButton);
