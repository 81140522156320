import PropTypes from 'prop-types';
import React from 'react';
import ValidationMessage, { VALIDATION_MESSAGE_WARNING, VALIDATION_MESSAGE_DEFAULT_ERROR }
  from './ValidationMessage';
import { VALIDATION_ERROR } from '../constants/validation';

const ValidationMessages = ({
  fieldId,
  errors,
  someErrors,
}) => {
  const validationMessage = error => (
    error.type === VALIDATION_ERROR ? VALIDATION_MESSAGE_DEFAULT_ERROR : VALIDATION_MESSAGE_WARNING
  );

  if (!errors || !errors.length) return null;

  const validationErrors = someErrors
    ? errors.filter(e => e.type === VALIDATION_ERROR)
    : errors;

  return (
    <div data-test='validation.messages'>
      {
        validationErrors.map(error => (
          <ValidationMessage
            key={ `${fieldId}-error-${error.message}` }
            type={ validationMessage(error) }
          >
            {error.message}
          </ValidationMessage>))
      }
    </div>
  );
};

ValidationMessages.propTypes = {
  fieldId: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  someErrors: PropTypes.bool.isRequired,
};

export default ValidationMessages;
