import _ from 'lodash';
import authenticatedFetch, { asyncAuthenticatedFetch } from './authenticated';

const fetchHelper = {
  _token() {
    const el = document.querySelector('meta[name="csrf-token"]');
    return el ? el.getAttribute('content') : '';
  },

  getDefaultHeaders() {
    const csrf = this._token();
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
      'X-Requested-With': 'XMLHttpRequest',
    };
  },

  responseAsJson(response) {
    return response.text().then((text) => {
      const body = text ? JSON.parse(text) : {};
      return { status: response.status, body };
    });
  },

  async asyncResponseAsJson(response) {
    const text = await response.text();
    try {
      const body = text ? JSON.parse(text) : {};
      return { status: response.status, body };
    } catch (parsingError) {
      return { status: response.status, body: text };
    }
  },

  validateResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  },

  asyncValidateResponse(response) {
    if (response.status >= 200 && response.status < 300) {
      throw new Error(response);
    }
  },

  _standardFetch(route, success, failure, opts, authenticated, method) {
    success = success || function () {};
    failure = failure || function () {};
    opts = opts || {};

    return authenticatedFetch(route, _.merge({
      method,
      ...authenticated && {
        credentials: 'include',
        headers: this.getDefaultHeaders(),
      },
    }, opts))
      .then(this.responseAsJson)
      .then(this.validateResponse)
      .then(success, failure);
  },

  async _asyncFetch(route, opts, authenticated, method) {
    opts = opts || {};

    const res = await asyncAuthenticatedFetch(route, _.merge({
      method,
      ...authenticated && {
        credentials: 'include',
        headers: this.getDefaultHeaders(),
      },
    }, opts));
    const data = await this.asyncResponseAsJson(res);
    if (!res.ok) {
      const error = new Error('Fetch error');
      error.data = data;
      throw error;
    }
    return data;
  },

  put(route, success, failure, opts, authenticated = true) {
    return this._standardFetch(route, success, failure, opts, authenticated, 'put');
  },

  post(route, success, failure, opts, authenticated = true) {
    return this._standardFetch(route, success, failure, opts, authenticated, 'post');
  },

  get(route, success, failure, opts, authenticated = true) {
    return this._standardFetch(route, success, failure, opts, authenticated, 'get');
  },

  delete(route, success, failure, opts, authenticated = true) {
    return this._standardFetch(route, success, failure, opts, authenticated, 'delete');
  },

  async asyncGet(route, opts, authenticated = true) {
    return this._asyncFetch(route, opts, authenticated, 'get');
  },

  async asyncPost(route, opts, authenticated = true) {
    return this._asyncFetch(route, opts, authenticated, 'post');
  },

  async asyncPut(route, opts, authenticated = true) {
    return this._asyncFetch(route, opts, authenticated, 'put');
  },

  async asyncDelete(route, opts, authenticated = true) {
    return this._asyncFetch(route, opts, authenticated, 'delete');
  },
};

export { authenticatedFetch };
export default fetchHelper;
