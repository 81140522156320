import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';

import TaskStatus from './TaskStatus';
import { TaskReportsSelectors } from '../selectors';
import SVGIcon from '../../common/SVGIcon';
import { ATTRIBUTE_TYPES, FLAGGED_STATUS, FLAGGED_STATUS_UNFLAGGED } from '../constants';
import TaskComments from './TaskComments';
import TaskTimestamp from './TaskTimestamp';
import LoadingSpinner from '../../common/LoadingSpinner';
import DateTimeZone from '../../common/DateTimeZone';
import TaskSubtasks from './TaskSubtasks';
import TaskWidget from './TaskWidget';
import OffsetSticky from '../../common/OffsetSticky';
import TaskCheckbox from './TaskCheckbox';
import { TaskTemplateConstants } from '../../task/constants';
import RequiredPinIcon from '../../pinCode/RequiredPinIcon';

const { ACTION, ACTION_TEMPLATE } = TaskTemplateConstants.templateType;

const actionIconName = 'mini/arrows/arrows-16px-2_corner-right-round';

const TaskTitle = ({
  title, href, status, isAction, requiresPin, isTaskDone,
}) => (
  <div className='showtime-task-planner-task__primary' data-test='taskReports.taskPrimary'>
    { isAction && (
      <SVGIcon
        classes='showtime-task-reports__header-icon'
        iconName={ actionIconName }
      />
    ) }
    <a
      className='showtime-task-planner-task__primary-title showtime-task-planner-task__primary-title--link'
      href={ href }
      target='_blank'
      rel='noopener noreferrer'
      data-test='taskReports.taskTitle'
    >
      { title }
      <RequiredPinIcon requiresPin={ requiresPin } isTaskDone={ isTaskDone } />
    </a>
    <TaskStatus status={ status } />
  </div>
);

TaskTitle.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isAction: PropTypes.bool.isRequired,
  requiresPin: PropTypes.bool.isRequired,
  isTaskDone: PropTypes.bool.isRequired,
};

const itemTypeToIcon = {
  [ATTRIBUTE_TYPES.LOCATION]: 'mini/shopping/shopping-16px_shop',
  [ATTRIBUTE_TYPES.FLAGS]: 'mini/maps-location/location-16px-e_flag-07',
  [ATTRIBUTE_TYPES.CHECKS_ADDED]: 'mini/design-development/design-16px_todo',
  [ATTRIBUTE_TYPES.COMMENTS]: 'mini/ui/ui-16px-2_chat-round-content',
  [ATTRIBUTE_TYPES.EXCEPTIONS]: 'mini/ui/ui-16px-3_alert',
  [ATTRIBUTE_TYPES.ACTIONS]: actionIconName,
  [ATTRIBUTE_TYPES.LINKED_TIMELINE_TASK]: 'mini/ui/ui-16px-link',
};

const TaskReportItem = ({ type, label }) => (
  <div className='showtime-task-planner-task__report-item'>
    <SVGIcon
      classes='showtime-icon showtime-icon--size-14 showtime-icon--margin-right'
      iconName={ itemTypeToIcon[type] }
    />
    <span className='showtime-task-planner-task__report-item-text'>{label}</span>
  </div>
);

TaskReportItem.propTypes = {
  type: PropTypes.oneOf(Object.values(ATTRIBUTE_TYPES)).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      options: PropTypes.object,
    }),
  ]).isRequired,
};

const TaskAttributes = ({ attributes }) => (
  <div className='showtime-task-planner-task__secondary-assignment-group'>
    {
      Object.keys(attributes).map(key => (
        <TaskReportItem
          key={ key }
          type={ key }
          label={ attributes[key] }
        />
      ))
    }
  </div>
);

TaskAttributes.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const Task = ({
  id,
  dueBy,
  dueFrom,
  hasContent,
  name,
  status,
  flaggedStatus,
  flaggedChecklistCount,
  addedChecklistCount,
  commentsCount,
  locationName,
  locationTimeZone,
  completedAt,
  completedBy,
  comments,
  viewComments,
  titleHref,
  stickyOffset,
  isSelected,
  isSelectable,
  templateType,
  actionCount,
  exceptionCount,
  requiresPin,
  linkedTimelineTaskName,
  linkedTimelineTaskCompletedAt,
  intl: { formatMessage },
}) => {
  const attributes = _.reduce({
    [ATTRIBUTE_TYPES.FLAGS]: flaggedChecklistCount,
    [ATTRIBUTE_TYPES.CHECKS_ADDED]: addedChecklistCount,
    [ATTRIBUTE_TYPES.ACTIONS]: actionCount,
    [ATTRIBUTE_TYPES.EXCEPTIONS]: exceptionCount,
    [ATTRIBUTE_TYPES.COMMENTS]: commentsCount,
  }, (accu, value, key) => {
    if (!value) return accu;
    return {
      ...accu,
      [key]: formatMessage({ id: `taskReports.task.${key}` }, { value }),
    };
  }, {});
  if (linkedTimelineTaskName) {
    if (linkedTimelineTaskCompletedAt) {
      const locationCompletionDateTime = (
        <DateTimeZone
          date={ linkedTimelineTaskCompletedAt }
          timeZone={ locationTimeZone }
          format={ formatMessage({ id: 'taskReports.task.datetimeFormat' }) }
        />
      );
      attributes[ATTRIBUTE_TYPES.LINKED_TIMELINE_TASK] = formatMessage(
        { id: `taskReports.task.${ATTRIBUTE_TYPES.LINKED_TIMELINE_TASK}` },
        { taskName: linkedTimelineTaskName, locationCompletionDateTime }
      );
    } else {
      attributes[ATTRIBUTE_TYPES.LINKED_TIMELINE_TASK] = formatMessage(
        { id: `taskReports.task.${ATTRIBUTE_TYPES.LINKED_TIMELINE_TASK}Uncompleted` },
        { taskName: linkedTimelineTaskName }
      );
    }
  }

  const hasComments = viewComments && comments.length > 0;
  const commentsLoading = viewComments && commentsCount > 0 && comments.length === 0;
  const className = classnames(
    'showtime-task-planner-task is-editable', {
      'has-content': hasContent,
      'showtime-task-planner-task--selected': isSelected,
    }
  );

  return (
    <div className={ className } data-test='taskReports.task'>
      { isSelectable && <TaskCheckbox id={ id } /> }
      <div className='showtime-task-planner-task__inner'>
        <OffsetSticky
          stickyClassName='showtime-task-planner-task__inner--is-stuck'
          boundaryElement='.showtime-task-planner-task__inner'
          disabled={ !hasContent }
          top={ stickyOffset }
        >
          <TaskTitle
            title={ name }
            href={ titleHref }
            status={ status }
            isAction={ templateType === ACTION || templateType === ACTION_TEMPLATE }
            requiresPin={ requiresPin }
            isTaskDone={ Boolean(completedAt) }
          />
        </OffsetSticky>
        <div className='showtime-task-planner-task__secondary'>
          <div className='showtime-task-planner-task__secondary-assignment' data-test='taskReports.taskAttributes'>
            <TaskAttributes
              attributes={ { [ATTRIBUTE_TYPES.LOCATION]: locationName, ...attributes } }
            />
          </div>
          <div className='showtime-task-planner-task__secondary-meta'>
            <TaskTimestamp
              dueBy={ dueBy }
              dueFrom={ dueFrom }
              completedAt={ completedAt }
              completedBy={ completedBy }
              status={ status }
              timeZone={ locationTimeZone }
            />
            {
              status === FLAGGED_STATUS && (
                <span
                  data-test='task-flagged-status'
                  className='showtime-task-planner-task__flagged-status'
                >
                  { formatMessage({
                    id: `taskReports.task.flaggedStatus.${flaggedStatus}`,
                  }) }
                </span>
              )
            }
          </div>
        </div>
        <TaskSubtasks id={ id } />
        <TaskWidget id={ id } />
        { commentsLoading && <LoadingSpinner classes='showtime-loader--padding-reduced' label={ formatMessage({ id: 'taskReports.task.fetchingComments' }) } /> }
        {hasComments && <TaskComments comments={ comments } timeZone={ locationTimeZone } />}
      </div>
    </div>
  );
};

Task.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  hasContent: PropTypes.bool.isRequired,
  status: PropTypes.string,
  flaggedChecklistCount: PropTypes.number,
  addedChecklistCount: PropTypes.number,
  commentsCount: PropTypes.number,
  locationName: PropTypes.string,
  completedAt: PropTypes.string,
  completedBy: PropTypes.string,
  comments: PropTypes.array,
  viewComments: PropTypes.bool,
  titleHref: PropTypes.string,
  dueBy: PropTypes.string.isRequired,
  dueFrom: PropTypes.string.isRequired,
  stickyOffset: PropTypes.number,
  flaggedStatus: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  requiresPin: PropTypes.bool.isRequired,
  templateType: PropTypes.string.isRequired,
  locationTimeZone: PropTypes.string.isRequired,
  actionCount: PropTypes.number.isRequired,
  exceptionCount: PropTypes.number.isRequired,
  linkedTimelineTaskName: PropTypes.string,
  linkedTimelineTaskCompletedAt: PropTypes.string,
};

Task.defaultProps = {
  status: undefined,
  completedAt: undefined,
  completedBy: undefined,
  flaggedChecklistCount: undefined,
  addedChecklistCount: undefined,
  commentsCount: undefined,
  locationName: undefined,
  comments: [],
  viewComments: false,
  titleHref: undefined,
  stickyOffset: 0,
  flaggedStatus: FLAGGED_STATUS_UNFLAGGED,
  linkedTimelineTaskName: undefined,
  linkedTimelineTaskCompletedAt: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const { id, ...task } = TaskReportsSelectors.taskSelector(state, ownProps);
  const viewComments = TaskReportsSelectors.viewCommentsSelector(state, ownProps);
  const hasContent = TaskReportsSelectors.taskHasVisibleContentSelector(state, ownProps);
  const isSelected = TaskReportsSelectors.taskIsSelectedSelector(state, { id });
  const isSelectable = TaskReportsSelectors.isExportModeSelector(state);

  return {
    viewComments,
    hasContent,
    isSelected,
    isSelectable,
    id,
    ...task,
  };
};
export default compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps
  )
)(Task);
