import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TITLE_MAXIMUM_LENGTH } from './constants/index.js';
import AnchoredMenu from '../common/menu/AnchoredMenu';
import withViewportWidth from '../common/withViewportWidth';
import Overlay from '../overlay/Overlay';
import { TaskActions } from '../task-wizard/actions';
import { confirmMessage } from '../unsaved-changes/middleware';
import { unsavedChangesSelector } from '../unsaved-changes/selectors';
import ActionModalContent from './ActionModalContent';
import { setDefaultActionValues, getEditingTaskAction, getActionTemplate } from './actions';
import CloseButton from './CloseButton';
import TaskMetadata from './TaskMetadata';

const MODAL_OVERLAY_BREAKPOINT = 767;

const ActionModal = ({
  onClose,
  rowId,
  fieldName,
  taskName,
  taskId,
  widgetName,
  defaultTitle,
  showAsOverlay,
  modalTaskTemplateId,
  modalTimelineTaskId,
  actionTemplateId,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const truncatedTitle = defaultTitle.slice(0, TITLE_MAXIMUM_LENGTH);

    if (modalTimelineTaskId) dispatch(getEditingTaskAction(modalTimelineTaskId));
    else if (actionTemplateId) dispatch(getActionTemplate(actionTemplateId, truncatedTitle));
    else {
      dispatch(setDefaultActionValues({ title: truncatedTitle }));
    }
  }, [modalTimelineTaskId, actionTemplateId]);

  const hasUnsavedChanges = useSelector(unsavedChangesSelector);

  const closeAndReset = () => {
    onClose();
    dispatch(TaskActions.reset());
  };

  const handleClose = () => {
    const pickerIsOpen = document.getElementById('__filestack-picker') !== null;
    if (pickerIsOpen) return;

    // eslint-disable-next-line no-alert
    if (hasUnsavedChanges && !window.confirm(confirmMessage)) {
      return;
    }
    closeAndReset();
  };

  const contentProps = {
    taskId,
    rowId,
    fieldName,
    modalTaskTemplateId,
    modalTimelineTaskId,
    actionTemplateId,
    onSubmitSuccess: closeAndReset,
  };

  if (showAsOverlay) {
    return (
      <Overlay
        title={ <TaskMetadata { ...{ taskName, widgetName } } /> }
        onHidden={ handleClose }
        skipRouting
        fullHeight
        overlayContainerClasses='showtime-task-action'
      >
        <ActionModalContent
          { ...contentProps }
          showAsOverlay
        />
      </Overlay>
    );
  }

  return (
    <AnchoredMenu
      parentClasses='showtime-task-action-modal'
      onClickOutside={ handleClose }
      align='left'
      dataTest='action.modal'
    >
      <div className='showtime-stacked-modal-padded showtime-task-action'>
        <div className='showtime-stacked-modal__actions'>
          <div className='showtime-task-action__header'>
            <CloseButton onClick={ handleClose } />
            <TaskMetadata { ...{ taskName, widgetName } } />
          </div>
        </div>
        <ActionModalContent
          { ...contentProps }
          showAsOverlay={ false }
        />
      </div>
    </AnchoredMenu>
  );
};

ActionModal.propTypes = {
  ...TaskMetadata.propTypes,
  ...ActionModalContent.propTypes,
  defaultTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withViewportWidth(
  width => ({ showAsOverlay: width <= MODAL_OVERLAY_BREAKPOINT })
)(ActionModal);
