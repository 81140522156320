import _ from 'lodash';

export const AttachmentConstants = {
  FILE_TYPE: 'file',
  LINK_TYPE: 'link',
  MIME_TYPE_IMAGE: 'image',
  MIME_TYPE_PDF: 'pdf',
  ADD: 'attachment.add',
  DELETE: 'attachment.delete',
  SELECT: 'attachment.select',
  CHANGE_NAME: 'attachment.change_name',
  START_UPLOAD: 'attachment.start_upload',
  CANCEL_UPLOAD: 'attachment.cancel_upload',
  COMPLETE_UPLOAD: 'attachment.complete_upload',
  ADD_LINK: 'attachment.add_link',
  State: {
    PENDING_UPLOAD: 'pending_upload',
    UPLOADING: 'uploading',
    SAVED: 'saved',
    FAILED: 'upload_failed',
  },
};

export default AttachmentConstants;

export const isImage = mimeType => _.startsWith(mimeType, AttachmentConstants.MIME_TYPE_IMAGE);
export const isPdf = mimeType => _.endsWith(mimeType, AttachmentConstants.MIME_TYPE_PDF);

export const isLink = type => type === AttachmentConstants.LINK_TYPE;
export const isFile = type => type === AttachmentConstants.FILE_TYPE;
