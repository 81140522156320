import _ from 'lodash';

const DataCaptureWidgetData = function (rawWidgetData) {
  _.extend(this, rawWidgetData);

  const customData = this.custom_fields.dataCaptureRows;
  const preprocessedData = this.preprocessed_data &&
    this.preprocessed_data.dataCaptureRows;

  if (!customData && preprocessedData) {
    this.custom_fields.dataCaptureRows = preprocessedData;
  }

  this.custom_fields.dataCaptureRows = this.custom_fields.dataCaptureRows || [{}];

  const visibleRows = this.custom_fields.dataCaptureRows;

  if (
    this.public_settings &&
    this.public_settings.minimumRows &&
    visibleRows.length < this.public_settings.minimumRows
  ) {
    const rowsToAddCount = this.public_settings.minimumRows - visibleRows.length;

    for (let i = 0; i < rowsToAddCount; i += 1) {
      this.custom_fields.dataCaptureRows.push({});
    }
  }
};

export default DataCaptureWidgetData;
