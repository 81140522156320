import { OFFLINE_QUEUE_PROCESSING } from '../../../service-workers/constants';
import checkOnlineQueue from './checkOnlineQueue';
import checkMultipleClients from './checkMultipleClients';

export default (client = window) => {
  const checkTimeout = () => {
    checkOnlineQueue(client[OFFLINE_QUEUE_PROCESSING]);
    checkMultipleClients();

    setTimeout(checkTimeout, 2000);
  };

  checkTimeout();
};
