import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import PDFTaskAttribute from './PDFTaskAttribute';
import { ATTRIBUTE_TYPES } from '../constants';

const PDFTaskAttributes = ({ intl, task }) => {
  const taskAttributes = {
    [ATTRIBUTE_TYPES.LOCATION]: task.location_name,
  };
  if (task.flagged_checklist_count > 0) taskAttributes[ATTRIBUTE_TYPES.FLAGS] = intl.formatMessage({ id: `taskReports.task.${ATTRIBUTE_TYPES.FLAGS}` }, { value: task.flagged_checklist_count });
  if (task.added_checklist_count > 0) taskAttributes[ATTRIBUTE_TYPES.CHECKS_ADDED] = intl.formatMessage({ id: `taskReports.task.${ATTRIBUTE_TYPES.CHECKS_ADDED}` }, { value: task.added_checklist_count });
  if (task.comments_count > 0) taskAttributes[ATTRIBUTE_TYPES.COMMENTS] = intl.formatMessage({ id: `taskReports.task.${ATTRIBUTE_TYPES.COMMENTS}` }, { value: task.comments_count });
  return (
    <View
      is='div'
      style={ {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flex: 3,
      } }
    >
      {
      Object.entries(taskAttributes).map(
        ([key, value]) => (
          <PDFTaskAttribute
            key={ `attrib-${task.id}-${key}` }
            attributeKey={ key }
            attributeValue={ value }
          />)
          )
        }
    </View>
  );
};

PDFTaskAttributes.propTypes = {
  task: PropTypes.object.isRequired,
};

export default PDFTaskAttributes;
