import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      fill={ (props.style && props.style.fill) || '#444444' }
      d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1 S8.6,12,8,12z M9,9H7V4h2V9z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
