import moment from 'moment-timezone';
import metricsPublisher, { TrailMetricsDirectory, MetricsPublisherConstants } from '.';

export const ConnectionMetrics = {
  firstConnectionOccured: false,

  queuedMetrics: [],

  sendMetrics(metric) {
    if (this.isFirstConnection(metric)) {
      return;
    }

    const metricData = {
      triggeredTime: moment().format(),
    };

    if (this.shouldSendImmediately(metric)) {
      this.recordMetrics(metric, metricData);
    } else {
      this.queueMetricForSending(metric, metricData);
    }
  },

  triggerQueuedMetrics() {
    const metricsToSend = this.queuedMetrics;
    this.queuedMetrics = [];

    metricsToSend.forEach(function (metricToSend) {
      this.recordMetrics(metricToSend.metric, metricToSend.data);
    }, this);
  },

  queueMetricForSending(metric, data) {
    this.queuedMetrics.push({
      metric,
      data,
    });
  },

  recordMetrics(metric, data) {
    metricsPublisher.recordMetric(metric, data, [MetricsPublisherConstants.SEGMENT]);
  },

  isFirstConnection(eventType) {
    if (eventType === TrailMetricsDirectory.application.connection.ONLINE &&
        !this.firstConnectionOccured) {
      this.firstConnectionOccured = true;
      return true;
    }

    return false;
  },

  shouldSendImmediately(metric) {
    return metric === TrailMetricsDirectory.application.connection.ONLINE ||
      metric === TrailMetricsDirectory.page.Trail.TRAIL_REFRESHED;
  },
};
