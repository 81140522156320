import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { organizationUsersLoadedSelector } from '../../users/selectors';
import { TaskReportsActions } from '../actions';
import { filterQueryKeys, filterTypes } from '../constants';
import { TaskReportsSelectors } from '../selectors';
import Filter from './Filter';
import useIdsQuery from './useIdsQuery';

const queryKey = filterQueryKeys[filterTypes.USERS];

const UsersFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });

  const dispatch = useDispatch();
  const usersLoaded = useSelector(organizationUsersLoadedSelector);
  useEffect(() => {
    if (usersLoaded) return;

    dispatch(TaskReportsActions.fetchUserFilterInformation());
  }, []);

  const users = useSelector(TaskReportsSelectors.usersListSelector);
  const { formatMessage } = useIntl();

  const userItems = users.map(user => ({
    ...user,
    parentId: user.role,
    secondaryLabel: _.get(user, 'custom_role.name', formatMessage({ id: `common.userRoles.${user.role}` })),
    dataTest: `user-${user.id}`,
  }));

  const groups = [
    { id: filterTypes.USERS, items: userItems },
  ];

  return (
    <Filter
      { ...props }
      { ...{
        queryIds, clearQueryIds, setQueryIds, groups, filterMetric,
      } }
      filterType={ filterTypes.USERS }
      hasLoaded={ usersLoaded }
      selectFromGroupId={ filterTypes.USERS }
      additionalCheckboxClasses='showtime-checkbox--inline'
      exclusionWhenAllSelected
      multiSelect={ false }
    />
  );
};

UsersFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default UsersFilter;
