import { Font } from '@react-pdf/renderer';

export const FONT_NAME = 'Nunito';

(function disableWordHyphenation() {
  Font.registerHyphenationCallback(word => [word]);
}());

Font.register({
  family: FONT_NAME,
  fonts: [
    { src: `${window.config.assetPath}Nunito-Regular.ttf` },
    { src: `${window.config.assetPath}Nunito-SemiBold.ttf`, fontWeight: 600 },
  ],
});

// Attribution as required under CC-BY 4.0: Twemoji provided by Twitter, Inc.
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});
