const TaskHistoryConstants = {
  GET_LAST_INSTANCES_URL: '/api/v1/task_templates/:templateId/timeline_tasks?location_id=:locationId',
  GET_LAST_INSTANCES_KEY: 'task_history.get_last_instances',
  RESET: 'task_history.reset',
};

TaskHistoryConstants.buildGetLastInstancesUrl =
  (templateId, locationId) => TaskHistoryConstants.GET_LAST_INSTANCES_URL
    .replace(':templateId', templateId)
    .replace(':locationId', locationId);

export { TaskHistoryConstants };
