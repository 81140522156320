import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';
import * as MandatoryCommentSelectors from './mandatoryCommentSelectors';
// eslint-disable-next-line import/no-cycle
import * as TaskWizardSelectors from './taskWizardSelectors';
import * as TaskWidgetSelectors from './taskWidgetSelectors';
import * as ReportSelectors from './reportSelectors';
import * as TaskScheduleSelectors from './taskScheduleSelectors';
import { getCurrentUserRole, UserRole } from '../../application/UserRole';
import {
  usersForTaskAssignmentSelector,
  allManagersAssignedSelector,
  taskPermissionLevelSelector,
  taskHasAssignmentsSelector,
  assignedUserIdsToPersistSelector,
  permissionLevelToPersistSelector,
  invalidUserAssignmentsSelector,
  taskAssignedCustomRoleIdsSelector,
  isActionTemplateTypeSelector,
  taskRequiresPinSelector,
} from './taskAssignmentSelectors';
import { TaskPermissionConstants } from '../constants';

const { editPermissions } = TaskPermissionConstants;
const { MANAGE_PERMITTED_TASKS } = UserRole.permissionTypes;

const descriptionSelector = ({ newTask }) => newTask.helpText.content.value;
const titleSelector = ({ newTask }) => newTask.title.content.value;
const tagsSelector = ({ newTask }) => newTask.tags.content.tags;
const editPermissionTypesSelector = state => get(state,
  'newTask.permissions.editPermissionTypes',
  []);
const createdByCustomRoleSelector = state => get(state,
  'newTask.permissions.createdByCustomRole',
  null);
const taskIsNewSelector = state => get(state, 'taskWizard.createdAt') === null;
const isCustomRoleWithTaskManagementPermissionSelector = () => {
  const customRole = getCurrentUserRole();
  return customRole.hasCustomRole() && customRole.isPermissionEnabled(MANAGE_PERMITTED_TASKS);
};

const customRoleIsManagingAdminTaskSelector = createSelector(
  createdByCustomRoleSelector,
  taskIsNewSelector,
  isCustomRoleWithTaskManagementPermissionSelector,
  (createdByCustomRole, taskIsNew, customRoleCanManageTasks) => (
    !createdByCustomRole && !taskIsNew && customRoleCanManageTasks)
);

const permittedToEditAllSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => editPermissionTypes.includes(editPermissions.ALL)
);
const permittedToEditNoneSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => isEmpty(editPermissionTypes)
);
const permittedToEditScheduleSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => (
    intersection(editPermissionTypes, [editPermissions.SCHEDULE, editPermissions.ALL]).length > 0
  )
);
const hasAllPermissionsSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => isEqual(editPermissionTypes, [editPermissions.ALL])
);
const hasNoPermissionsSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => isEmpty(editPermissionTypes)
);
const hasScheduleAndAssignSitesPermissionsSelector = createSelector(
  editPermissionTypesSelector,
  editPermissionTypes => isEqual(
    editPermissionTypes, [editPermissions.SCHEDULE, editPermissions.ASSIGN_SITES]
  )
);

export const isCustomRoleNotPermittedToContentAndTagsSelector = createSelector(
  taskIsNewSelector,
  isCustomRoleWithTaskManagementPermissionSelector,
  editPermissionTypesSelector,
  createdByCustomRoleSelector,
  (taskIsNew, isCustomRoleWithTaskManagement, editPermissionTypes, createdByCustomRole) => {
    if (!isCustomRoleWithTaskManagement) return false;
    const notCopying = !createdByCustomRole;
    if (notCopying && taskIsNew) return false;
    if (!editPermissionTypes.length) return false;
    return !editPermissionTypes.includes(editPermissions.ALL);
  }
);

export {
  descriptionSelector,
  titleSelector,
  tagsSelector,
  usersForTaskAssignmentSelector,
  taskHasAssignmentsSelector,
  allManagersAssignedSelector,
  taskPermissionLevelSelector,
  invalidUserAssignmentsSelector,
  assignedUserIdsToPersistSelector,
  permissionLevelToPersistSelector,
  taskAssignedCustomRoleIdsSelector,
  taskRequiresPinSelector,
  MandatoryCommentSelectors,
  TaskWizardSelectors,
  TaskWidgetSelectors,
  ReportSelectors,
  TaskScheduleSelectors,
  isActionTemplateTypeSelector,
  editPermissionTypesSelector,
  customRoleIsManagingAdminTaskSelector,
  permittedToEditAllSelector,
  permittedToEditNoneSelector,
  permittedToEditScheduleSelector,
  hasAllPermissionsSelector,
  hasNoPermissionsSelector,
  hasScheduleAndAssignSitesPermissionsSelector,
};
