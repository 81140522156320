import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { TaskReportsSelectors } from '../selectors';
import { TaskReportsActions } from '../actions';
import { FORCE_VIEW_FILTERS_WIDTH } from '../constants';
import withViewportWidth from '../../common/withViewportWidth';

const ViewFiltersToggle = ({
  isHighlighted,
  onToggle,
  shouldDisplayToggle,
}) => shouldDisplayToggle ? (
  <Button
    onClick={ onToggle }
    className={ classNames('showtime-button showtime-button--small', {
      'showtime-button--highlight': isHighlighted,
      'showtime-button--default': !isHighlighted,
    }) }
    iconName='mini/ui/ui-16px-2_filter'
    dataTest='taskReports.toggleFilters'
  />
) : null;

ViewFiltersToggle.propTypes = {
  isHighlighted: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  shouldDisplayToggle: PropTypes.bool.isRequired,
};


const mapStateToProps = (state, ownProps) => ({
  isHighlighted: TaskReportsSelectors.selectedFilterTypesSelector(state, ownProps).length > 0,
});

const mapDispatchToProps = dispatch => ({
  onToggle: () => dispatch(TaskReportsActions.toggleViewFilters()),
});

export default compose(
  withRouter,
  withViewportWidth(width => ({ shouldDisplayToggle: width < FORCE_VIEW_FILTERS_WIDTH })),
  connect(mapStateToProps, mapDispatchToProps)
)(ViewFiltersToggle);
