export const SIGN_UP_PATH = '/';
export const SITE_NAMES_PATH = '/site-names';
export const BILLING_SUBSCRIPTIONS_PATH = '/api/billing/subscriptions/sign_up';
export const UPDATE_SIGN_UP_PATH = '/update_sign_up';
export const SIGN_IN_PATH = '/u/sign_in';
export const TRAIL_HOMEPAGE = 'https://trailapp.com/';

export const FIELD_CHANGE_ACTION = '@SIGN_UP form-change';
export const SITE_NAME_CHANGE_ACTION = '@SIGN_UP site-name-change';
export const REMOVE_SITE_NAME_ACTION = '@SIGN_UP remove-site-name';
export const FORM_SUBMITTED_ACTION = '@SIGN_UP form-submitted';
export const ADD_SITE_NAME_ACTION = '@SIGN_UP add-site-name';
export const SUBMIT_ERROR_ACTION = '@SIGN_UP submit-error';
export const SIGN_UP_KEY = '@SIGN_UP sign-up';

export const TRUSTED_CUSTOMER = [
  'honest-burgers',
  'bao',
  'pizza-pilgrims',
  'tonkotsu',
  'grind',
];
export const SUBMIT_ERROR = 'submit';

export const FREE_PLAN_AD_HOC_LIMIT = 3;

export const TEAM_ID = '2';
export const TEAM_NAME = 'team';
export const CAPITALISE_TEAM_NAME = 'Team';
export const STANDARD_ID = '3';
export const STANDARD_NAME = 'standard';
export const CAPITALISE_STANDARD_NAME = 'Standard';
export const FREE_ID = '4';
export const FREE_NAME = 'free';
export const CAPITALISE_FREE_NAME = 'Free';

export const PLAN_IDS = [TEAM_ID, STANDARD_ID, FREE_ID];
export const PLAN_NAMES = {
  [TEAM_ID]: TEAM_NAME,
  [STANDARD_ID]: STANDARD_NAME,
  [FREE_ID]: FREE_NAME,
};
export const capitalizePlanNames = {
  [FREE_ID]: CAPITALISE_FREE_NAME,
  [TEAM_ID]: CAPITALISE_TEAM_NAME,
  [STANDARD_ID]: CAPITALISE_STANDARD_NAME,
};

export const DEFAULT_FEATURE_INDEXES = [1, 2, 3];

export const FEATURE_INDEXES = {
  [FREE_NAME]: [1, 2],
};
export const COMPANY = 'company';
export const TOTAL_SITES = 'totalSites';
export const PHONE = 'phone';
export const NAME = 'name';
export const EMAIL = 'email';
export const fieldNames = {
  COMPANY,
  TOTAL_SITES,
  PHONE,
  NAME,
  EMAIL,
};
export const MANDATORY_FIELDS = [NAME, EMAIL, TOTAL_SITES, COMPANY];
export const OPTIONAL_FIELDS = [PHONE];

export const SITE_NAME_PREFIX = 'site-name-id-';
export const MAX_SITES = 20;

export const COOKIE_KEY = 'SIGN_UP';

export const DEFAULT_COUNTRY_CODE = 'GB';
export const CHECKOUT_METHOD = 'Cardless';

export const ACCOUNT_DETAILS_STEP = 'accountDetails';
export const SITE_NAMES_STEP = 'siteNames';
export const COOKIE_CHECKOUT_ID_IDENTIFIER = 'trail_checkout_id';

export const METRIC_ERROR_STATUS = 'error';
export const METRIC_ERROR_MESSAGE = 'Internal Server Error';

export const products = {
  team: { amount: 3800, productId: 'prod_I0F8LcwqiuCFWW' },
  standard: { amount: 7500, productId: 'prod_H6iF6zlBLLmXKa' },
};

export const cookieConsent = {
  THEME: 'classic',
  TYPE: 'opt-in',
  OP_IN: '<div class="cc-compliance cc-highlight showtime-sign-up__cookie-consent-buttons">{{deny}}{{allow}}</div>',
  COOKIE_NAME: 'trail_cookieconsent_status',
  ALLOW_STATUS: 'allow',
};

export const TRAIL_DOMAIN = 'trailapp.com';
