import _ from 'lodash';

import {
  OFFLINE_STATE_TASKS,
  OFFLINE_STATE_SUBTASKS,
  OFFLINE_STATE_WIDGETS,
} from '../../../service-workers/constants';
import AppDispatcher from '../../../application/dispatcher';
import { TrailConstants } from '../../../trail/constants';

export default (trailKey, jsonData, statesStore, options = {}) => {
  const trailStore = (statesStore && statesStore[trailKey]) || {};

  if (!Object.keys(trailStore).length) {
    return;
  }

  const storeKeys = [
    OFFLINE_STATE_TASKS,
    OFFLINE_STATE_SUBTASKS,
    OFFLINE_STATE_WIDGETS,
  ];

  const replacedState = storeKeys.reduce((acc, storeKey) => {
    const storeForKey = trailStore[storeKey] && JSON.parse(trailStore[storeKey]);

    return storeForKey
      ? {
        ...acc,
        [storeKey]: storeKey === OFFLINE_STATE_WIDGETS
          ? _.toArray(storeForKey)
          : storeForKey,
      }
      : acc;
  }, jsonData);

  AppDispatcher.dispatch({
    actionType: TrailConstants.UPDATE_TRAIL,
    jsonData: replacedState,
    ...options,
  });
};
