import React from 'react';
import PropTypes from 'prop-types';

import Button from '../common/Button';

const CloseButton = ({ onClick }) => (
  <Button
    onClick={ onClick }
    dataTest='action.close'
    small
    lowlightAlt
    className='showtime-task-action__close'
    iconName='mini/ui/ui-16px-1_simple-remove'
  />
);
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
