import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  disabled,
  id,
  name,
  maxLength,
  onChange,
  onBlur,
  onPaste,
  refFn,
  required,
  value,
  rows,
  placeholder,
  dataTest,
}) => {
  const dataTestProps = dataTest && { 'data-test': dataTest };
  return (<textarea
    rows={ rows }
    disabled={ disabled }
    id={ id }
    name={ name }
    maxLength={ maxLength }
    onChange={ ({ target: { value: newValue } }) => onChange(newValue) }
    onBlur={ ({ target: { value: newValue } }) => onBlur(newValue) }
    onPaste={
      (event) => {
        if (onPaste) {
          const pastedText = (event.clipboardData || window.clipboardData).getData('text');
          onPaste(pastedText, event.target.selectionStart, event.target.selectionEnd);
          event.preventDefault();
        }
      }
    }
    ref={ refFn }
    required={ required }
    value={ value }
    placeholder={ placeholder }
    { ...dataTestProps }
  />);
};

TextArea.propTypes = {
  rows: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
  refFn: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  dataTest: PropTypes.string,
};

TextArea.defaultProps = {
  rows: '',
  disabled: false,
  name: undefined,
  maxLength: null,
  onBlur: () => { },
  onPaste: null,
  refFn: null,
  required: false,
  value: undefined,
  placeholder: '',
  dataTest: undefined,
};

export default TextArea;
