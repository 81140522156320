import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import CustomDateInput from './CustomDateInput';

class DateSelect extends Component {
  render() {
    return (
      <DatePicker
        customInput={
          this.props.customDateInput || <CustomDateInput { ...this.props.input } />
        }
        minDate={ this.props.dates.min }
        maxDate={ this.props.dates.max }
        selected={ this.props.dates.selected }
        startDate={ this.props.dates.start }
        endDate={ this.props.dates.end }
        className={ this.props.classNames }
        dateFormat={ this.props.dateFormat }
        placeholderText={ this.props.placeholderText }
        utcOffset={ this.props.utcOffset }
        todayButton={ this.props.todayButtonText }
        onChange={ this.props.onChange }
        ref={ this.props.ref }
      />
    );
  }
}

DateSelect.propTypes = {
  customDateInput: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  input: PropTypes.shape({
    label: PropTypes.string,
    classNames: PropTypes.string,
    iconName: PropTypes.string,
    dataLabel: PropTypes.string,
  }),
  dates: PropTypes.shape({
    min: PropTypes.instanceOf(moment().constructor),
    max: PropTypes.instanceOf(moment().constructor),
    selected: PropTypes.instanceOf(moment().constructor),
    start: PropTypes.instanceOf(moment().constructor),
    end: PropTypes.instanceOf(moment().constructor),
  }),
  classNames: PropTypes.string,
  dateFormat: PropTypes.string,
  utcOffset: PropTypes.number,
  placeholderText: PropTypes.string,
  todayButtonText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  ref: PropTypes.func,
};

DateSelect.defaultProps = {
  customDateInput: null,
  input: {},
  dates: {},
  classNames: '',
  dateFormat: null,
  utcOffset: null,
  placeholderText: null,
  todayButtonText: null,
  ref: null,
};

export default DateSelect;
