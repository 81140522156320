import RouterConstants from './constants';

export const getBaseRoute = (pathname) => {
  const [baseMatcher] = RouterConstants.PANEL_BASE_MATCHES
    .filter(matcher => matcher.test(pathname));

  if (!baseMatcher) return RouterConstants.BASE_URL;

  const [baseRoute] = baseMatcher.exec(pathname);
  return baseRoute;
};
