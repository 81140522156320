import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from '@react-pdf/renderer';
import PDFHeader from './PDFHeader';
import PDFTasks from './PDFTasks';
import PDFFooter from './PDFFooter';
import { FONT_NAME } from './fonts';
import TaskReportPDFViewer from './TaskReportPDFViewer';

const PDF = ({
  selectedTasks = [], widgetsByTaskId = {}, widgetErrorsByTaskId = {}, subtasksByTaskId = {},
  displayComments = false, displaySubtasks = false, displayWidgets = false,
  taskContentCommentsByTaskId,
  bulkStartIndex, bulkEndIndex, taskCount, reportUrl, intl,
}) => (
  <TaskReportPDFViewer
    loadingLabel={ intl.formatMessage({ id: 'taskReports.export.PDF.generating' }) }
  >
    <Document is='div'>
      <Page
        is='div'
        style={ {
          padding: 15,
          paddingBottom: 40
        } }
      >
        <PDFHeader { ...{ intl, reportUrl } } />
        <PDFTasks
          {
            ...{
              tasks: selectedTasks,
              widgetsByTaskId,
              widgetErrorsByTaskId,
              taskContentCommentsByTaskId,
              subtasksByTaskId,
              displayComments,
              displayWidgets,
              displaySubtasks,
              intl,
            }
          }
        />
        <PDFFooter
          { ...{
            bulkStartIndex, bulkEndIndex, taskCount, intl,
          } }
        />
      </Page>
    </Document>
  </TaskReportPDFViewer>
);

PDF.propTypes = {
  bulkEndIndex: PropTypes.any,
  bulkStartIndex: PropTypes.any,
  displayComments: PropTypes.bool,
  displaySubtasks: PropTypes.bool,
  displayWidgets: PropTypes.bool,
  intl: PropTypes.any.isRequired,
  reportUrl: PropTypes.any,
  selectedTasks: PropTypes.array,
  subtasksByTaskId: PropTypes.object,
  taskCount: PropTypes.any,
  widgetErrorsByTaskId: PropTypes.object,
  widgetsByTaskId: PropTypes.object,
  taskContentCommentsByTaskId: PropTypes.object,
};

PDF.defaultProps = {
  selectedTasks: [],
  widgetsByTaskId: {},
  widgetErrorsByTaskId: {},
  taskContentCommentsByTaskId: {},
  subtasksByTaskId: {},
  displayComments: false,
  displaySubtasks: false,
  displayWidgets: false,
  bulkStartIndex: null,
  bulkEndIndex: null,
  taskCount: null,
  reportUrl: '',
};

export default PDF;
