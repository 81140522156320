import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import PDFTaskAttributes from './PDFTaskAttributes';
import { FLAGGED_STATUS } from '../constants';
import { TrailConstants } from '../../trail/constants/index';
import { DateDifference } from '../../common/DateDifference';
import { CompletedAtAndBy, DueBy } from '../Tasks/TaskTimestamp';

const { COMPLETED, COMPLETED_EARLY, COMPLETED_LATE } = TrailConstants.taskStatus;

const PDFTaskSubheader = ({ intl, task }) => {
  const completedEarlyLateOrOnTime = [
    COMPLETED, COMPLETED_EARLY, COMPLETED_LATE,
  ].includes(task.status) && task.completed_at;

  let completedEarlyLateOrOnTimeMessage = '';
  if (completedEarlyLateOrOnTime) {
    const notCompleted = task.status !== COMPLETED;
    const dueTime = task.status === COMPLETED_EARLY
      ? task.due_from
      : task.due_by;

    completedEarlyLateOrOnTimeMessage = (
      <React.Fragment>
        <CompletedAtAndBy
          completedAt={ task.completed_at }
          completedBy={ task.completed_by }
          intl={ intl }
          timeZone={ task.location_time_zone }
        /> {notCompleted && (
          <DateDifference toDate={ task.completed_at } fromDate={ dueTime } intl={ intl } />
        )}
      </React.Fragment>
    );
  }
  return (
    <View
      is='div'
      style={ {
        flexDirection: 'row',
        justifyContent: 'space-between',
      } }
    >
      <PDFTaskAttributes { ...{ intl, task } } />
      <View
        is='div'
        style={ {
          fontSize: 10,
          color: '#646470',
          flex: 2,
          textAlign: 'right',
        } }
      >
        { completedEarlyLateOrOnTime
          ? <Text is='span'>{completedEarlyLateOrOnTimeMessage}</Text>
          : (
            <Text is='span'>
              <DueBy
                { ...{
                  status: task.status, dueBy: task.due_by, intl, timeZone: task.location_time_zone,
                } }
              />
            </Text>
          )
        }
        {
          task.status === FLAGGED_STATUS && (
            <Text is='span'>
              { intl.formatMessage({ id: `taskReports.task.flaggedStatus.${task.flagged_status}` }) }
            </Text>
          )
        }
      </View>
    </View>
  );
};

PDFTaskSubheader.propTypes = {
  task: PropTypes.object.isRequired,
};

export default PDFTaskSubheader;

