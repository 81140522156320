import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import useIdsQuery from '../../taskReports/Filters/useIdsQuery';
import Filter from '../../taskReports/Filters/Filter';
import {
  locationsHaveLoadedSelector,
  locationsWithoutDemoWithGroupSelector,
} from '../../sites/selectors';
import { fetchLocations } from '../../sites/actions';
import { filterQueryKeys, filterTypes, ALL } from '../../taskReports/constants';
import { removeInvalidLocationsFromQueryIds } from '../../taskReports/Filters/updatedQueryIdsForSites';

const queryKey = filterQueryKeys[filterTypes.SITES];

const AREA = 'area';
const LOCATION = 'location';

export const SitesFilter = ({
  filterMetric, ...props
}) => {
  const { formatMessage } = useIntl();
  const siteQuery = useIdsQuery(queryKey, { sendMetrics: true, filterMetric });
  const { setQueryIds, clearQueryIds } = siteQuery;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const hasLoaded = useSelector(locationsHaveLoadedSelector);
  const { areas, locations } = useSelector(locationsWithoutDemoWithGroupSelector);

  const queryIds = removeInvalidLocationsFromQueryIds(siteQuery.queryIds, locations);

  const locationItems = locations.map(location => ({
    ...location,
    secondaryLabel: location.group,
    parentId: location.timelineTemplateParentId,
    dataTest: `location-${location.id}`,
  }));

  const areaItemsWithLocations = areas.filter(area => area.locations.length !== 0);
  const areaItems = areaItemsWithLocations.map((area) => {
    const totalAreaLocations = locationItems.filter(
      locationItem => locationItem.group === area.name).length;
    return {
      ...area,
      totalAreaLocations,
      secondaryLabel: formatMessage({ id: 'dropdownFilter.summary.areaSecondaryLabel' }, { totalAreaLocations }),
      dataTest: `area-${area.id}`,
    };
  });

  const groups = [
    { items: areaItems, id: AREA, parentId: ALL },
    { items: locationItems, id: LOCATION, parentId: AREA },
  ];

  const numberOfSelectedLocations = queryIds[0] === ALL ? locations.length : queryIds.length;
  const totalLocationsNumber = locations.length;

  return (
    <Filter
      { ...props }
      groups={ groups }
      queryIds={ queryIds }
      { ...{ setQueryIds, clearQueryIds, filterMetric } }
      hasLoaded={ hasLoaded }
      filterType={ filterTypes.SITES }
      selectAll
      selectAllSecondaryLabel={ formatMessage({ id: 'dropdownFilter.summary.selectAllSecondaryLabel' }, { numberOfSelectedLocations, totalLocationsNumber }) }
      additionalCheckboxClasses='showtime-checkbox--inline'
      selectFromGroupId={ LOCATION }
    />
  );
};

SitesFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default SitesFilter;
