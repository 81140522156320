import moment from 'moment-timezone';
import TaskScheduleConstants from './constants';

const RECURRENCE_UNIT_TO_MOMENT_UNIT = {
  [TaskScheduleConstants.RECUR_PERIODS.Day]: 'days',
  [TaskScheduleConstants.RECUR_PERIODS.Week]: 'weeks',
  [TaskScheduleConstants.RECUR_PERIODS.Month]: 'months',
};

export const getMaxDate = (date, unit, by) => {
  if (!by) return null;
  const momentUnit = RECURRENCE_UNIT_TO_MOMENT_UNIT[unit];
  const nextInstance = moment(date).add(by, momentUnit);
  const dayBeforeNextInstance = nextInstance.subtract(1, 'days');
  return dayBeforeNextInstance;
};
