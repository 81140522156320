import _ from 'lodash';
import { createCachedSelector } from 're-reselect';

import { IS_REQUIRED } from '../data-capture/validator/rules';
import { widgetModes } from '../constants';
import { WhiteListedCellTypes } from '../data-capture/v2/constants';
import { TrailConstants } from '../../trail/constants';
import { WidgetDataStore } from '../../trail/store';
import validator from '../data-capture/validator';

const viewModeSelector = (state, { widgetId, taskStatus, widget }) => {
  const { singleRecord: isSingleRecord } = widget.widget().public_settings || {};
  if (isSingleRecord) {
    return widgetModes.LIST;
  }
  const taskStatusIsComplete = taskStatus === TrailConstants.taskStatus.DONE;
  if (taskStatusIsComplete) {
    return widgetModes.TABLE;
  }
  return _.get(state, `dataCapture.viewMode.${widgetId}`, widgetModes.LIST);
};

const getWidgetCacheKey = (state, ownProps, widgetIdKey) => widgetIdKey || 'no_widget';

const widgetIdSelector = (state, { widgetId }) => widgetId;

const visibleFieldsSelector = createCachedSelector(
  state => _.get(state, 'dataCapture.errors', {}),
  widgetIdSelector,
  (state, { showAllErrors = false }) => showAllErrors,
  (visibleWidgetFields, widgetId, showAllErrors) => {
    const visible = visibleWidgetFields[widgetId] || {};
    visible.allVisible = showAllErrors || visible.allVisible;
    return visible;
  }
)(getWidgetCacheKey);

const widgetDataSelector = (state, ownProps) => (
  ownProps.widgetData || WidgetDataStore.getWidget(widgetIdSelector(state, ownProps))
);

const dataCaptureRowsForValidationSelector = (state, ownProps) => {
  const widget = widgetDataSelector(state, ownProps);
  const { actions } = ownProps;
  return _.chain(widget)
    .get('custom_fields.dataCaptureRows', [])
    .map((row, rowIndex) => _.chain(row)
      .toPairs()
      .map(([fieldName, value]) => {
        const hasAction = Boolean(_.find(actions, {
          widget_row_index: rowIndex,
          widget_field_name: fieldName,
        }));
        return [fieldName, { value, hasAction }];
      })
      .fromPairs()
      .value()
    ).value();
};

const allowedColumnsSelector = (state, ownProps) => {
  const widget = widgetDataSelector(state, ownProps);
  return _.chain(widget)
    .get('public_settings.columns', [])
    .filter(c => WhiteListedCellTypes.includes(c.cellType))
    .value();
};

const _cacheOnActionLengthToBreakEqualityCheck = (state, ownProps) => _.size(ownProps.actions);

const widgetErrorsSelector = createCachedSelector(
  allowedColumnsSelector,
  visibleFieldsSelector,
  dataCaptureRowsForValidationSelector,
  (state, ownProps) => ownProps.intl,
  _cacheOnActionLengthToBreakEqualityCheck,
  (allowedColumns, visible, dataCaptureRowsForValidation, intl) => {
    const errors = validator(allowedColumns, dataCaptureRowsForValidation, intl);

    const all = dataCaptureRowsForValidation.reduce((accu, row, rowId) => {
      if (row.rowDeleted) return accu;

      return { ...accu, [rowId]: errors[rowId] };
    }, {});

    return { visible, all };
  }
)(getWidgetCacheKey);

const shouldInitiallyDisplayError = ({ key } = {}) => key !== IS_REQUIRED;

const getVisibleErrorsForRow = ({
  all: allErrors, visible: fieldsFlaggedVisibleForErrors, rowId,
}) => {
  const {
    visibleFields = {},
    visibleRows = [],
    allVisible = false,
  } = fieldsFlaggedVisibleForErrors;

  const filterVisibleFieldErrors = (fieldErrors, fieldName) => {
    const isFieldFlaggedAsVisible = _.get(visibleFields, [rowId, fieldName], false);

    const shouldDisplayField = allVisible || visibleRows[rowId] || isFieldFlaggedAsVisible;

    return shouldDisplayField
      ? fieldErrors
      : fieldErrors.filter(shouldInitiallyDisplayError);
  };

  const rowErrors = allErrors[rowId];
  return _.mapValues(rowErrors, filterVisibleFieldErrors);
};

const visibleErrorsSelector = createCachedSelector(
  widgetErrorsSelector,
  ({ all, visible }) => {
    const errors = [];
    Object.keys(all).forEach((rowId) => {
      errors[rowId] = getVisibleErrorsForRow({ rowId, visible, all });
    });
    return errors;
  }
)(getWidgetCacheKey);

export default {
  widgetErrorsSelector,
  visibleErrorsSelector,
  viewModeSelector,
};
