import $ from 'jquery';

export const userSettings = (show) => {
  if (!show) {
    return;
  }

  $(document).ready(() => {
    const action = $('form').attr('action');
    if (action === undefined) {
      return;
    }
    function roleChangeSelected(userRole) {
      $('#champion_section').hide();
      let showTaskCompleted = true;

      if (userRole === 'enduser') {
        showTaskCompleted = false;
      } else if (userRole === 'organization_admin') {
        $('#champion_section').show();
      }
      $('#task-completed-section').toggle(showTaskCompleted);
    }

    if (action.split('/').length === 4) {
      $('#user_role').val('enduser');
    }

    const roleRadios = Array.from(document.getElementsByName('user[role]'));
    roleRadios.forEach((radio) => {
      const onChange = () => roleChangeSelected(radio.value);
      if (radio.checked) {
        onChange();
      }
      radio.onchange = onChange;
    });

    const championInput = $('#is_champion_id');
    championInput.change(() => {
      $('#user_is_champion').val(championInput.is(':checked'));
    });

    const PASSWORD_INPUT = 'input#user_password';
    const CURRENT_PASSWORD_LABEL = 'label[for="user_current_password"]';
    const CURRENT_PASSWORD_INPUT = 'input#user_current_password';
    const CURRENT_PASSWORD_WRAPPER = 'div.user_current_password';

    const requiredSelectors = [
      CURRENT_PASSWORD_LABEL,
      CURRENT_PASSWORD_WRAPPER,
      CURRENT_PASSWORD_INPUT,
    ];

    const switchClassNames = ({ old: oldClassName, new: newClassName }) => (selector) => {
      const element = document.querySelector(selector);
      element.classList.add(newClassName);
      element.classList.remove(oldClassName);
    };

    const currentPasswordInput = document.querySelector(CURRENT_PASSWORD_INPUT);

    if (currentPasswordInput) {
      document.querySelector(PASSWORD_INPUT).onchange = ({ target: { value } }) => {
        if (value && !currentPasswordInput.required) {
          currentPasswordInput.required = true;
          requiredSelectors.forEach(switchClassNames({ old: 'optional', new: 'required' }));

          const ASTERISK_HTML = '<abbr title="required">*</abbr> ';
          document.querySelector(CURRENT_PASSWORD_LABEL).insertAdjacentHTML('afterbegin', ASTERISK_HTML);
        }
        if (!value && currentPasswordInput.required) {
          currentPasswordInput.required = false;
          requiredSelectors.forEach(switchClassNames({ old: 'required', new: 'optional' }));

          const ASTERISK_SELECTOR = `${CURRENT_PASSWORD_LABEL} abbr[title="required"]`;
          document.querySelector(ASTERISK_SELECTOR).remove();
        }
      };
    }
  });
};

global.jQuery = $;
require('jquery-ujs');
