import { TrailConstants } from '../constants';

export const filters = (state = {}, action = {}) => {
  if (action.type === TrailConstants.SET_FILTER) {
    return {
      ...state,
      [action.filterType]: action.filterValue,
    };
  }
  return state;
};
