import _ from 'lodash';
import { createSelector } from 'reselect';
import { getWidgetEditedSettings, isNewWidgetSelector } from '../../widget/selectors';
import {
  DATA_TYPE_REGEX, JUMP_TO_END_OF_RECORD_LOG,
  logicConditions, logicThenTypes,
} from '../../widget/constants';
import { getLogicErrorsForColumn } from '../../widget/reducers/builderValidation/logicErrors';

export const selectedIntegrationSelector = (state) => {
  const { widget, availableIntegrations = [] } = _.get(state, 'newTask.widget.content', {});

  return _.find(availableIntegrations, { id: widget });
};

export const availableIntegrationsSelector = (state) => {
  const availableIntegrations = _.get(state, 'newTask.widget.content.availableIntegrations', []);
  const originalId = _.get(state, 'newTask.widget.originalContent.widget');

  return availableIntegrations
    .filter((integration) => {
      const isOrgIntegration = integration.organization_id === window.navigation.organizationId;
      const isAssignedIntegration = integration.id === originalId;
      return isOrgIntegration || isAssignedIntegration;
    });
};

export const taskHasNoExistingWidgetSelector = state => !_.get(state, 'newTask.widget.content.widget', false);

const getNewDataCapture = (orgId, settings) => ({
  widget_data_type: 'DataCaptureNEW_TASK_PLACEHOLDERWidgetData',
  organization_id: orgId,
  settings,
});

export const editedTaskWidgetConfig = (state) => {
  const isNew = isNewWidgetSelector(state);

  const { replace, settings } = getWidgetEditedSettings(state);

  if (isNew) {
    return getNewDataCapture(
      window.current_user.organization_id, settings
    );
  }

  const originalConfig = selectedIntegrationSelector(state);

  if (!originalConfig) return null;

  if (!settings) return originalConfig;

  if (replace) return { ...originalConfig, settings };

  return _.merge({},
    originalConfig,
    { settings }
  );
};

export const editedTaskWidgetConfigPathSelector = path => state => (
  _.get(editedTaskWidgetConfig(state), `settings.public.${path}`)
);

const getOptionsSettingsArray = (state, { key, columnIndex }) => {
  const { settings } = editedTaskWidgetConfig(state);
  const cellSettings = _.get(settings, `public.columns[${columnIndex}].cellSettings`, {});
  const { values = [] } = cellSettings;
  const numberOfValues = values.length;

  const data = cellSettings[key];
  const dataIsSmallerThanValues = data && data.length < numberOfValues;
  if (dataIsSmallerThanValues) {
    return [...data, ...new Array(numberOfValues - data.length).fill(false)];
  }
  return data || new Array(numberOfValues).fill(false);
};

export const optionsExceptionsSelector = (state, { columnIndex }) => getOptionsSettingsArray(state, { key: 'optionsExceptions', columnIndex });

export const optionsExceptionsWarningTextsSelector = (state, { columnIndex }) => getOptionsSettingsArray(state, { key: 'optionsExceptionsWarningTexts', columnIndex });

export const taskWidgetColumnsSelector = (state) => {
  const {
    columns = [],
  } = _.get(editedTaskWidgetConfig(state), 'settings.public', {});
  return columns;
};

export const columnsLengthSelector = createSelector(
  taskWidgetColumnsSelector,
  _.size);

const columnIndexSelector = (state, { columnIndex }) => columnIndex;

export const taskWidgetColumnSelector = createSelector(
  taskWidgetColumnsSelector,
  columnIndexSelector,
  (columns, columnIndex) => columns[columnIndex] || {});

export const columnIsReferencedByLogicSelector = createSelector(
  taskWidgetColumnsSelector,
  taskWidgetColumnSelector,
  (columns, { cellSettings: { fieldName } = {} } = {}) => _.some(columns,
    ({ cellSettings: { logic } }) => _.some(logic,
      ({ then }) => _.some(then,
        ({ fieldName: jumpToFieldName }) => jumpToFieldName === fieldName))));

export const nextFieldNameSelector = createSelector(
  taskWidgetColumnsSelector,
  columnIndexSelector,
  (columns, columnIndex) => (
    _.get(columns, `[${columnIndex + 1}].cellSettings.fieldName`, null)));

const fieldNameAfterNextSelector = createSelector(
  taskWidgetColumnsSelector,
  columnIndexSelector,
  (columns, columnIndex) => (
    _.get(columns, `[${columnIndex + 2}].cellSettings.fieldName`, null)));

export const columnSettingsSelector = createSelector(
  taskWidgetColumnSelector,
  column => _.get(column, 'cellSettings', {}));

const columnLogicSelector = createSelector(
  columnSettingsSelector,
  ({ logic }) => logic || []);

export const columnLogicByIndexSelector = createSelector(
  columnLogicSelector,
  (state, { logicIndex }) => logicIndex,
  (logic, logicIndex) => logic[logicIndex] || {});

export const logicIfValueOptionsSelector = createSelector(
  columnSettingsSelector,
  columnLogicSelector,
  columnLogicByIndexSelector,
  ({ values = [], labels = [] }, logic, { ifCase = {} } = {}) => {
    const valuesReferencedInLogic = _.flatMap(logic, 'ifCase.value');
    return values.reduce((options, value, valueIndex) => {
      if (valuesReferencedInLogic.includes(value) && value !== ifCase.value) return options;
      return [...options, { value, label: labels[valueIndex] }];
    }, []);
  });

export const defaultJumpLogicForColumnSelector = createSelector(
  fieldNameAfterNextSelector,
  logicIfValueOptionsSelector,
  (fieldNameAfterNext, ifValueOptions) => ({
    ifCase: {
      value: _.get(ifValueOptions, '[0].value', null),
      condition: logicConditions.selected,
    },
    then: [
      {
        type: logicThenTypes.jumpToField,
        fieldName: fieldNameAfterNext || JUMP_TO_END_OF_RECORD_LOG,
      },
    ],
  }));

export const columnLogicPathSelector = (state, { columnIndex, logicIndex }) => (
  `columns[${columnIndex}].cellSettings.logic[${logicIndex}]`);

export const columnLogicLengthSelector = createSelector(columnLogicSelector, _.size);

export const editedTaskWidgetTitleSelector = state => _.get(
  editedTaskWidgetConfig(state), 'settings.public.widgetTitle');

export const isDataCaptureWidgetSelector = (state) => {
  const { widget_data_type: widgetDataType } = editedTaskWidgetConfig(state) || {};
  return DATA_TYPE_REGEX.dataCapture.test(widgetDataType);
};

const widgetBuilderLogicErrorsSelector = (state) => {
  const columns = taskWidgetColumnsSelector(state);
  return columns.reduce((accu, column) => ({
    ...accu,
    [column.cellSettings.fieldName]: getLogicErrorsForColumn(column, columns),
  }), {});
};

export const widgetBuilderColumnLogicErrorsSelector = createSelector(
  taskWidgetColumnSelector,
  widgetBuilderLogicErrorsSelector,
  (column, errors) => errors[column.cellSettings.fieldName] || []);

export const widgetBuilderErrorsSelector = createSelector(
  getWidgetEditedSettings,
  widgetBuilderLogicErrorsSelector,
  ({ errors }, logicErrors) => _.mergeWith(
    { ...errors },
    logicErrors,
    (a = [], b = []) => ([...a, ...b]))
);

const jumpToFieldIndexSelector = createSelector(
  taskWidgetColumnsSelector,
  columnLogicByIndexSelector,
  (state, { thenIndex }) => thenIndex,
  (columns, logic, thenIndex) => {
    const selectedFieldName = _.get(logic, `then[${thenIndex}].fieldName`);
    if (selectedFieldName === JUMP_TO_END_OF_RECORD_LOG) return columns.length;
    return _.findIndex(columns, { cellSettings: { fieldName: selectedFieldName } });
  }
);

export const logicWillSkipMandatoryFieldSelector = createSelector(
  taskWidgetColumnsSelector,
  columnIndexSelector,
  jumpToFieldIndexSelector,
  (columns, columnIndex, jumpToFieldIndex) => {
    const endIndex = jumpToFieldIndex !== -1 ? jumpToFieldIndex : null;
    const skippedColumns = columns.slice(columnIndex + 1, endIndex);
    return _.some(skippedColumns, 'cellSettings.mandatory');
  }
);

export const widgetBuilderStatus = (state) => {
  const {
    expandedColumn = null,
  } = getWidgetEditedSettings(state);

  return {
    errors: widgetBuilderErrorsSelector(state),
    expandedColumn,
  };
};

export const missingWidgetTitleSelector = (state) => {
  const { settings: hasStagedWidgetConfigChanges } = getWidgetEditedSettings(state) || {};
  if (!hasStagedWidgetConfigChanges) return false;

  const isDataCaptureWidget = isDataCaptureWidgetSelector(state);
  if (!isDataCaptureWidget) return false;

  const missingWidgetTitle = !editedTaskWidgetTitleSelector(state);
  if (missingWidgetTitle) return 'newTask.errors.missingWidgetTitle';

  return false;
};

export const editedTaskWidgetConfigForPreview = (state) => {
  const editedWidgetConfig = editedTaskWidgetConfig(state);

  if (!editedWidgetConfig) return null;

  const { settings, ...widgetConfig } = editedTaskWidgetConfig(state);

  const { public: publicSettings } = settings || {};

  return { ...widgetConfig, public_settings: publicSettings, name: widgetConfig.title };
};
