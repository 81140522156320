import PropTypes from 'prop-types';

export const dateFormat = 'HH:mm [-] DD/MM/YYYY';
export const classNamePrefix = 'showtime-widget-v2__list';

export const STATUS_SAVED = 'saved';
export const STATUS_SAVING = 'saving';
export const STATUS_OFFLINE = 'offline';
export const TIMESTAMP_FIELDNAME = 'TimeStamp';

export const TIMER = 'TIMER';
export const FORMATTED_NUMBER = 'FORMATTED_NUMBER';
export const FREE_TEXT = 'FREE_TEXT';
export const OPTIONS = 'OPTIONS';
export const FILE = 'FILE';
export const ROW_COUNT = 'ROW_COUNT';
export const UPDATED_AT = 'UPDATED_AT';
export const SECTION_TITLE = 'SECTION_TITLE';

export const TEXT_INPUT_NUMBER = 'number';
export const TEXT_INPUT_TEXT = 'text';
export const WhiteListedCellTypes = [
  FORMATTED_NUMBER,
  FREE_TEXT,
  OPTIONS,
  FILE,
  SECTION_TITLE,
];
export const inputTypes = [
  ...WhiteListedCellTypes,
  ROW_COUNT,
  UPDATED_AT,
];

export const VALIDATION_DELAY_MS = 600;

export const columnPropTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      columnStyle: PropTypes.string,
      columnTitle: PropTypes.string.isRequired,
      cellType: PropTypes.oneOf(inputTypes).isRequired,
      cellSettings: PropTypes.shape({
        cellColumnHeading: PropTypes.string,
        columnRestricted: PropTypes.bool,
        copyValueToNew: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        prefixText: PropTypes.string,
        suffixText: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
};

export const widgetModes = {
  LIST: 'list',
  TABLE: 'table',
};

export const heightTomorrowTaskHeader = 79;
