import _ from 'lodash';
import { MandatoryCommentConstants } from '../constants';

export const mandatoryTaskCommentSettingsSelector = (state) => {
  const { file, text, cameraOnly } = _.get(
    state,
    ['newTask', 'mandatoryComments', MandatoryCommentConstants.MANDATORY_COMMENT_LEVEL.task],
    {}
  );
  return {
    file: Boolean(file),
    text: Boolean(text),
    cameraOnly: Boolean(cameraOnly),
  };
};
