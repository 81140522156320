import _ from 'lodash';
import { TimeSlotConfigurationConstants } from '../constants';
import AppDispatcher from '../../application/dispatcher';
import FetchHelper from '../../utils/fetch';
import {
  mapLegacyWeekDayTimeslots,
  mapLegacyDefaultTimeslots,
} from '../../timeslots';
import { TimeslotConstants } from '../../timeslots/constants/timeslotConstants';
import FlashActions from '../../flash/actions';

const {
  ORGANIZATION_TIMESLOTS_URL: organizationTimeslotsUrl,
  locationTimeslotsUrl,
} = TimeslotConstants;

const TimeSlotConfigurationActions = _.extend({}, {
  setStartTime(level, id, time) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_START_TIME,
      level,
      id,
      time,
    });
  },
  setEndTime(level, id, time) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_END_TIME,
      level,
      id,
      time,
    });
  },
  setClosed(level, id, closed) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_CLOSED,
      level,
      id,
      closed,
    });
  },
  setBatchClosed(level, ids, closed) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_BATCH_CLOSED,
      level,
      ids,
      closed,
    });
  },
  resetLocationDayOfWeek(locationId, dayOfWeek) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.RESET_LOCATION_DAY_OF_WEEK,
      dayOfWeek,
      locationId,
    });
  },
  resetDayOfWeek(dayOfWeek) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.RESET_DAY_OF_WEEK,
      dayOfWeek,
    });
  },
  destroy(level, id) {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.DESTROY,
      level,
      id,
    });
  },
  resetStore() {
    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.RESET_STORE,
    });
  },
  _fetchLocationTimeslotsSuccess: ({ body: { locationTimeslots = [] } }) => {
    const legacyLocationTimeslots = mapLegacyWeekDayTimeslots(
      locationTimeslots, 'location'
    );

    AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_TIMESLOTS,
      type: 'location',
      timeslots: legacyLocationTimeslots,
    });
  },
  _fetchOrganizationTimeslotsSuccess: ({
    body: { data = [], organizationTimeslots = [] },
  }) => {
    const legacyOrgTimeslots = mapLegacyWeekDayTimeslots(
      organizationTimeslots, 'organization'
    );

    const legacyDefaultTimeslots = mapLegacyDefaultTimeslots(
      _.groupBy(data, 'type').defaultTimeslots
    );

    [
      { type: 'dayDefault', timeslots: legacyDefaultTimeslots },
      { type: 'organization', timeslots: legacyOrgTimeslots },
    ].forEach(({ type, timeslots }) => AppDispatcher.dispatch({
      actionType: TimeSlotConfigurationConstants.SET_TIMESLOTS,
      type,
      timeslots,
    })
    );
  },
  _fetchTimeslotsFailure: message => FlashActions.alert(message),
  _fetchOrganizationTimeslots(organizationId) {
    const url = organizationTimeslotsUrl(organizationId);
    FetchHelper.get(
      url,
      this._fetchOrganizationTimeslotsSuccess,
      () => this._fetchTimeslotsFailure('web.timeslots.organization.fetchError')
    );
  },
  _fetchLocationTimeslots(locationId) {
    const url = locationTimeslotsUrl(locationId);

    FetchHelper.get(
      url,
      this._fetchLocationTimeslotsSuccess,
      () => this._fetchTimeslotsFailure('web.timeslots.location.fetchError')
    );
  },
  fetchInitialTimeslots(organizationId, locationId) {
    locationId && this._fetchLocationTimeslots(locationId);

    this._fetchOrganizationTimeslots(organizationId);
  },
});

if (process.env.TEST === true) {
  window.TimeSlotConfigurationActions = TimeSlotConfigurationActions;
}

export { TimeSlotConfigurationActions };
