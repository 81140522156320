import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import {
  mapFieldNameToColumn, formatFieldValue, colorForFieldError,
} from './utils.jsx';
import PDFWidgetFieldCell from './PDFWidgetFieldCell';

const PDFWidgetTableRows = ({
  intl, widget, errors, timeZone, comments,
}) => {
  const fieldNameToColumn = mapFieldNameToColumn(widget);
  const fieldNames = Object.keys(fieldNameToColumn);
  const finalIndex = fieldNames.length - 1;
  const defaultRows = comments.length ? [{}] : [];
  const rows = _.get(widget, 'custom_fields.dataCaptureRows', defaultRows);
  return (
    <View>
      {
        rows.map(
          (row, rowIndex) => (
            !row.rowDeleted && (
            <View
              is='div'
              style={
                {
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 10,
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#f6f6f6',
                }
              }
            >
              { fieldNames.map((field, fieldIndex) => (
                <PDFWidgetFieldCell
                  intl={ intl }
                  errorType={ _.get(errors, `[${rowIndex}][${field}][0].type`) }
                  fieldColor={ colorForFieldError(errors, rowIndex, field) }
                  isFinalField={ fieldIndex === finalIndex }
                  comments={ _.filter(
                    comments,
                    { linking_metadata: { widgetRowId: rowIndex, widgetFieldName: field } }) }
                  key={ field }
                >
                  { formatFieldValue(row[field], fieldNameToColumn[field], field, intl, timeZone) }
                </PDFWidgetFieldCell>
              )) }
            </View>
            )
          )
        )
      }
    </View>
  );
};

PDFWidgetTableRows.propTypes = {
  widget: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object),
  timeZone: PropTypes.string.isRequired,
};

PDFWidgetTableRows.defaultProps = {
  comments: [],
};

export default PDFWidgetTableRows;
