export const supportedMimeTypes = [ // https://www.filestack.com/docs/concepts/transform/#document-viewer
  /pdf/, // .pdf
  /powerpoint/, // .ppt
  // /excel/, // .xls (wraps poorly)
  // /opendocument.spreadsheet/, // .ods & .xlsx (wraps poorly)
  // /spreadsheetml.sheet/ .xlsx made by google docs (wraps poorly)
  /msword/, // .doc
  /opendocument.text/, // .odt
  /opendocument.presentation/, // .odp
  /wordprocessingml.document/, // .docx from google docs
  /text\/rtf/, // .rtf
  /gif/, // .gif not animated in preview
  /tiff/, // .tiff
  /jpeg/, // .jpg
  /png/, // .png
  /text\/plain/, // .txt
];

export const getReadPolicyUrl = (handles = []) => (
  `/api/v1/filestack_security/read?handles[]=${handles.join('&handles[]=')}`
);

export const pickPolicyUrl = '/api/v1/filestack_security/pick';

export const requestKey = {
  pick: 'filestack_security.pick',
  read: 'filestack_security.read',
};

export const SPREADSHEET_MIME_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const PERMITTED_MIME_TYPES = [
  'image/*',
  'text/plain',
  'video/quicktime',
  'video/mp4',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/pdf',
  ...SPREADSHEET_MIME_TYPES,
];

export const MAX_IMAGE_WIDTH = 2000;
export const MAX_IMAGE_HEIGHT = 2000;
export const FILE_TYPE = 'file';

export const FILEPICKER_OPTIONS = {
  fromSources: ['local_file_system'],
  accept: PERMITTED_MIME_TYPES,
  imageMax: [
    MAX_IMAGE_WIDTH,
    MAX_IMAGE_HEIGHT,
  ],
  disableTransformer: true,
};

export const COMMENT_IMAGE_OPTION = 'comment';
export const GRID_IMAGE_OPTION = 'grid';
