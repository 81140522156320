import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { TaskReportsSelectors } from '../selectors';
import ClearableSearchInput from '../../common/ClearableSearchInput';
import applyFiltersToQuery from '../applyFiltersToQuery';
import { PageNameConstants } from '../../metrics/constants';

const MS_WAIT_TO_FETCH_TASKS = 1000;

const SearchInput = ({
  intl: { formatMessage }, debouncedSearchTerm, history,
}) => {
  const [searchTerm, setSearchTerm] = useState(debouncedSearchTerm);

  const debouncedOnSearch = useCallback(_.debounce((value) => {
    applyFiltersToQuery(PageNameConstants.TASK_REPORTS, history, { search: value });
  }, MS_WAIT_TO_FETCH_TASKS), []);

  useEffect(() => debouncedOnSearch.cancel, []);

  useEffect(() => {
    if (!debouncedSearchTerm) setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleSearchChange = (value) => {
    value = value.trim();
    value = encodeURIComponent(value);
    setSearchTerm(value);
    const searchIsNotOneChar = value.length !== 1;
    searchIsNotOneChar && debouncedOnSearch(value);
  };

  return (
    <ClearableSearchInput
      className='showtime-task-reports__header-search showtime-input--small'
      value={ searchTerm }
      onChange={ handleSearchChange }
      dataTest='taskReports.search'
      placeholder={ formatMessage({ id: 'taskReports.taskActions.search' }) }
    />
  );
};

SearchInput.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  debouncedSearchTerm: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  debouncedSearchTerm: TaskReportsSelectors.searchTermSelector(state, ownProps),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(SearchInput);
