import _ from 'lodash';
import { RequestConstants } from '../request';
import { FETCH_CUSTOM_ROLES_KEY } from './constants';
import { requestStatuses } from '../common/requestStatuses';

const customRolesFetched = RequestConstants.getLoadedActionType(FETCH_CUSTOM_ROLES_KEY);
const customRolesLoading = RequestConstants.getLoadingActionType(FETCH_CUSTOM_ROLES_KEY);
const customRolesErrored = RequestConstants.getErrorActionType(FETCH_CUSTOM_ROLES_KEY);

const actionCases = {
  [customRolesFetched]: (state, action) => ({
    status: requestStatuses.LOADED,
    entities: _.keyBy(action.content, 'id'),
  }),
  [customRolesLoading]: state => ({ ...state, status: requestStatuses.LOADING }),
  [customRolesErrored]: state => ({ ...state, status: requestStatuses.ERROR }),
};

export const INITIAL_STATE = {
  status: requestStatuses.NOT_REQUESTED,
  entities: {},
};

export const customRoles = (state = INITIAL_STATE, action = {}) => {
  if (actionCases[action.type]) return actionCases[action.type](state, action);
  return state;
};
