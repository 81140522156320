import { RequestActions, RequestConstants } from '../../request';
import { RouterConstants } from '../../router';
import { fetchCustomRoles } from '../../customRoles/actions';
import { routeTo } from '../../router/actionCreators';
import {
  CUSTOM_ROLE_LIST_URL,
  updateRoleUrl,
} from '../../customRoles/constants';
import {
  CREATE_CUSTOM_ROLE_KEY,
  UPDATE_CUSTOM_ROLE_KEY,
  CLEAR_ROLE_PANEL,
  SET_EDITING_ROLE,
  SET_ROLE_NAME,
  SET_ROLE_DESCRIPTION,
  TOGGLE_ROLE_PERMISSION,
} from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

const createRole = (role, history) => async (dispatch) => {
  const { success } = await dispatch(
    RequestActions.request({
      key: CREATE_CUSTOM_ROLE_KEY,
      method: RequestConstants.POST,
      url: CUSTOM_ROLE_LIST_URL,
      errorMessage: 'roles.errors.createRoleFailed',
      successMessage: 'roles.success.createRoleSuccess',
      options: {
        body: JSON.stringify(role),
      },
    })
  );

  if (success) {
    dispatch(unsavedChangesActions.cleared());
    dispatch(fetchCustomRoles());
    dispatch(routeTo(RouterConstants.ROLES, { history }));
  }
};

const updateRole = (role, roleId, history) => async (dispatch) => {
  const { success } = await dispatch(
    RequestActions.request({
      key: UPDATE_CUSTOM_ROLE_KEY,
      method: RequestConstants.PUT,
      url: updateRoleUrl(roleId),
      errorMessage: 'roles.errors.updateRoleFailed',
      successMessage: 'roles.success.updateRoleSuccess',
      options: {
        body: JSON.stringify(role),
      },
    })
  );

  if (success) {
    dispatch(unsavedChangesActions.cleared());
    dispatch(fetchCustomRoles());
    dispatch(routeTo(RouterConstants.ROLES, { history }));
  }
};

const clearRolePanel = () => ({ type: CLEAR_ROLE_PANEL });

const setEditingRole = role => ({ type: SET_EDITING_ROLE, role });

const setName = name => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  dispatch({ type: SET_ROLE_NAME, name });
};
const setDescription = description => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  dispatch({ type: SET_ROLE_DESCRIPTION, description });
};
const togglePermission = permissionName => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  dispatch({ type: TOGGLE_ROLE_PERMISSION, permissionName });
};

export const RoleActions = {
  createRole,
  updateRole,
  clearRolePanel,
  setEditingRole,
  setName,
  setDescription,
  togglePermission,
};
