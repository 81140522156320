import _ from 'lodash';
import { RequestConstants, RequestActions } from '../request';
import { taskContentCommentsUrl, sendTaskContentCommentKey } from './constants';
import metricsPublisher, { TrailMetricsDirectory } from '../metrics';

export const postTaskContentComment = ({
  attachments,
  content,
  linkingMetadata,
  commentableId,
  commentableType,
  timelineTaskId,
}) => {
  const body = {
    timeline_task_id: timelineTaskId,
    commentable_id: commentableId,
    commentable_type: commentableType,
    linking_metadata: linkingMetadata,
    content,
    commented_at: new Date(Date.now()).toISOString(),
    attachments,
  };

  metricsPublisher.recordMetric(
    TrailMetricsDirectory.page.Trail.TASK_CONTENT_COMMENT_SENT,
    {
      commentableType,
      locationId: window.navigation && window.navigation.locationId,
    }
  );

  return RequestActions.request({
    url: taskContentCommentsUrl,
    key: sendTaskContentCommentKey,
    method: RequestConstants.POST,
    errorMessage: 'conversation.commentSendingError',
    content: {
      ...body,
      temporaryId: _.uniqueId(),
      commented_by: window.current_user.id,
      commented_by_user_name: window.current_user.name,
    },
    options: {
      body: JSON.stringify(body),
    },
  });
};
