import { actionTypes } from '../constants';

export const routeTo =
  (route, {
    js = true, allowSoftRerouteWithUnsavedChanges = false, history, search,
  } = {}) => ({
    type: actionTypes.route,
    route,
    js,
    allowSoftRerouteWithUnsavedChanges,
    history,
    search,
  });
