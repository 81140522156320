import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import NumberField from '../../../common/forms/NumberField';
import TextAreaField from '../../../common/forms/TextAreaField';
import SVGIcon from '../../../common/SVGIcon';
import CheckBox from '../../../common/forms/CheckBox';
import ActionTemplateSelection from './ActionTemplateSelection';

const RequestActionCheckbox = ({ checked, onChange, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <CheckBox
      dataTest='raise-action-checkbox'
      checkboxClasses='showtime-record-log-builder-field__input'
      dataHint={ formatMessage({ id: 'editWidget.builder.numberColumn.range.requireActionHint' }) }
      hintTop
      hintInfo
      checked={ checked }
      disabled={ disabled }
      label={ formatMessage({ id: 'editWidget.builder.numberColumn.range.requireActionCheckbox' }) }
      onChange={ () => onChange(!checked) }
      small
    />
  );
};
RequestActionCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const RequireMandatoryActionCheckBox = ({ checked, onChange }) => {
  const { formatMessage } = useIntl();
  return (
    <CheckBox
      dataTest='raise-mandatory-action-checkbox'
      checkboxClasses='showtime-record-log-builder-field__input'
      dataHint={ formatMessage({ id: 'editWidget.builder.numberColumn.range.requireMandatoryActionHint' }) }
      hintTop
      hintInfo
      checked={ checked }
      label={ formatMessage({ id: 'editWidget.builder.numberColumn.range.requireMandatoryActionCheckbox' }) }
      onChange={ () => onChange(!checked) }
      small
    />
  );
};
RequireMandatoryActionCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const MinMaxWarningRange = ({
  minFieldLabel,
  minFieldPlaceholder,
  minFieldValue,
  minFieldErrors,
  onMinFieldChange,
  maxFieldLabel,
  maxFieldPlaceholder,
  maxFieldValue,
  maxFieldErrors,
  onMaxFieldChange,
  requestValidationAction,
  requireMandatoryValidationAction,
  onRequestValidationActionChange,
  warningTextFieldLabel,
  warningTextFieldPlaceholder,
  warningTextFieldValue,
  warningTextFieldErrors,
  onWarningTextFieldChange,
  onRequireMandatoryValidationActionChange,
  onActionTemplateIdChange,
  actionTemplateId,
}) => (
  <React.Fragment>
    <div className='showtime-record-log-builder-field__config-split-input'>
      <NumberField
        label={ minFieldLabel }
        placeholder={ minFieldPlaceholder }
        defaultValue={ minFieldValue }
        onChange={ onMinFieldChange }
        errors={ minFieldErrors }
        dataTest='widget.columns.rangeMin'
        containerClass='showtime-record-log-builder-field__input'
        allowDecimal
      />
      <div className='showtime-record-log-builder-field__config-split-input-divider'>
        <SVGIcon
          classes='showtime-icon showtime-icon--size-14'
          iconName='/mini/arrows/arrows-16px-1_enlarge-horizontal'
        />
      </div>
      <NumberField
        label={ maxFieldLabel }
        placeholder={ maxFieldPlaceholder }
        defaultValue={ maxFieldValue }
        onChange={ onMaxFieldChange }
        errors={ maxFieldErrors }
        dataTest='widget.columns.rangeMax'
        containerClass='showtime-record-log-builder-field__input'
        allowDecimal
      />
    </div>
    <TextAreaField
      autoExpand
      label={ warningTextFieldLabel }
      placeholder={ warningTextFieldPlaceholder }
      value={ warningTextFieldValue }
      onChange={ onWarningTextFieldChange }
      errors={ warningTextFieldErrors }
      containerClass='showtime-record-log-builder-field__input'
      dataTest='widget.columns.rangeMessage'
    />
    { window.config.features.correctiveActions && (
      <RequestActionCheckbox
        checked={ requestValidationAction }
        onChange={ onRequestValidationActionChange }
        disabled={ requireMandatoryValidationAction }
      />
    ) }
    { window.config.features.correctiveActions && (
      <RequireMandatoryActionCheckBox
        checked={ requireMandatoryValidationAction }
        onChange={ onRequireMandatoryValidationActionChange }
      />
    ) }
    { requestValidationAction && (
      <ActionTemplateSelection
        onChange={ onActionTemplateIdChange }
        value={ actionTemplateId }
      />
    ) }
  </React.Fragment>
);

MinMaxWarningRange.propTypes = {
  minFieldLabel: PropTypes.string.isRequired,
  minFieldPlaceholder: PropTypes.string.isRequired,
  minFieldValue: PropTypes.string,
  minFieldErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onMinFieldChange: PropTypes.func.isRequired,
  maxFieldLabel: PropTypes.string.isRequired,
  maxFieldPlaceholder: PropTypes.string.isRequired,
  maxFieldValue: PropTypes.string,
  maxFieldErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onMaxFieldChange: PropTypes.func.isRequired,
  warningTextFieldLabel: PropTypes.string.isRequired,
  warningTextFieldPlaceholder: PropTypes.string.isRequired,
  warningTextFieldValue: PropTypes.string,
  warningTextFieldErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onWarningTextFieldChange: PropTypes.func.isRequired,
  requestValidationAction: PropTypes.bool.isRequired,
  requireMandatoryValidationAction: PropTypes.bool.isRequired,
  onRequestValidationActionChange: PropTypes.func.isRequired,
  onRequireMandatoryValidationActionChange: PropTypes.func.isRequired,
  onActionTemplateIdChange: PropTypes.func.isRequired,
  actionTemplateId: PropTypes.number,
};

MinMaxWarningRange.defaultProps = {
  minFieldValue: '',
  maxFieldValue: '',
  warningTextFieldValue: '',
  actionTemplateId: undefined,
};

export default MinMaxWarningRange;
