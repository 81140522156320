import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { ClearableSearchInput } from '../ClearableSearchInput';
import LoadingSpinner from '../LoadingSpinner';
import { ScrollableList } from './ScrollableList';

const includedInSearch = (text, search) => {
  if (!text) return true;

  const optionText = `${text}`.toLowerCase();
  const searchText = search.toLowerCase();
  return optionText.includes(searchText);
};

const shouldRenderItem = (item, search, checkedFilter = null) => {
  if (!item) return false;
  const { props: { children: text, checked: itemChecked } } = item;
  const matchesCheckedFilter = checkedFilter === null || itemChecked === checkedFilter;

  return includedInSearch(text, search) && matchesCheckedFilter;
};

class ListSelection extends Component {
  state = { currentSearch: '' };

  handleSearchChange = currentSearch => this.setState({ currentSearch });

  renderGroupChecklists = () => React.Children.map(
    this.props.children, group => this.renderGroupChecklist(group)
  );

  renderGroupChecklist = (group) => {
    if (!this.props.separateSelected) {
      if (group === null) return null;
      const items = this.cloneGroupItems(group);
      if (items.length === 0) return null;
      return (
        <ScrollableList
          dataTest={ group.props.id }
          groupBorderBottom={ this.props.groupBorderBottom }
        >
          { items }
        </ScrollableList>
      );
    }

    const selectedItems = this.cloneGroupItems(group, true);
    const deselectedItems = this.cloneGroupItems(group, false);
    return (
      <React.Fragment>
        { Boolean(_.size(selectedItems))
          && <ScrollableList dataTest={ `${group.props.id}-selected` } groupBorderBottom={ this.props.groupBorderBottom }>{ selectedItems }</ScrollableList> }
        { Boolean(_.size(deselectedItems))
        && <ScrollableList dataTest={ `${group.props.id}-deselected` } groupBorderBottom={ this.props.groupBorderBottom }>{ deselectedItems }</ScrollableList> }
      </React.Fragment>
    );
  };

  cloneGroupItems = (group, checkedFilter) => group
    ? React.Children.map(group.props.children, (item) => {
      if (!shouldRenderItem(item, this.state.currentSearch, checkedFilter)) return null;
      return React.cloneElement(item, { onChange: group.props.onSelectChange });
    })
    : [];

  render() {
    const {
      intl, isLoading, dataTest, searchInputClassName, showSearch,
    } = this.props;
    const dataProps = dataTest ? { 'data-test': dataTest } : {};

    return (
      <div className='showtime-form-section' { ...dataProps }>
        { showSearch && (
        <ClearableSearchInput
          className={ searchInputClassName }
          onChange={ this.handleSearchChange }
          placeholder={ intl.formatMessage({ id: 'listSelection.placeholders.search' }) }
        />
        )}
        { isLoading && <LoadingSpinner /> }
        { !isLoading && this.renderGroupChecklists() }
      </div>
    );
  }
}

ListSelection.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
  showSearch: PropTypes.bool,
  searchInputClassName: PropTypes.string,
  separateSelected: PropTypes.bool,
  groupBorderBottom: PropTypes.bool,
};

ListSelection.defaultProps = {
  isLoading: false,
  children: null,
  dataTest: null,
  showSearch: true,
  separateSelected: true,
  searchInputClassName: '',
  groupBorderBottom: false,
};

export default injectIntl(ListSelection);
