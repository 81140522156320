import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WindowHelper from '../../utils/window';

const readFileAsDataURL = file => new Promise((resolve) => {
  const filereader = new FileReader();
  filereader.onload = function () {
    resolve(filereader.result);
  };
  filereader.readAsDataURL(file);
});

export class CapturePreview extends Component {
  state = { imageSrc: null };

  componentDidMount() {
    if (this.props.file) {
      this.readAsDataURL(this.props.file);
    }
  }

  componentDidUpdate(prevProps) {
    if (Boolean(prevProps.file) !== Boolean(this.props.file)) {
      this.readAsDataURL(this.props.file);
    }
  }

  readAsDataURL = (image) => {
    const { filereader: fileReaderSupported } = WindowHelper.browserSupport();
    if (!fileReaderSupported) return;

    if (!image) {
      this.setState({ imageSrc: null });
      return;
    }

    this.readFileAsDataURLPromise = readFileAsDataURL(image);
    this.readFileAsDataURLPromise.then(imageSrc => this.setState({ imageSrc }));
  };

  render() {
    const className = classNames(
      'showtime-file-preview__image',
      { 'showtime-file-preview__image--blur': this.props.blur }
    );
    return this.state.imageSrc && (
      <img
        src={ this.state.imageSrc }
        alt='Upload Preview'
        className={ className }
      />
    );
  }
}

CapturePreview.propTypes = {
  file: PropTypes.object.isRequired,
  blur: PropTypes.bool.isRequired,
};

export default CapturePreview;
