import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { TaskReportsSelectors } from '../selectors';

const SelectedTasksCount = ({
  intl: { formatMessage }, selectedTaskCount, startBulkIndex, endBulkIndex,
}) => (
  <span className='text-bold'>
    { formatMessage({ id: 'taskReports.selection.tasks' }, {
      selectedTaskCount,
      bulkStartCount: _.isNumber(startBulkIndex) ? startBulkIndex + 1 : false,
      bulkEndCount: _.isNumber(startBulkIndex) ? endBulkIndex + 1 : false,
    }) }
  </span>
);

SelectedTasksCount.propTypes = {
  selectedTaskCount: PropTypes.number.isRequired,
  startBulkIndex: PropTypes.number.isRequired,
  endBulkIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const taskGroups = TaskReportsSelectors.bulkSelectionTaskGroupsSelector(state, ownProps);
  const startBulkIndex = TaskReportsSelectors.selectedTaskGroupSelector(state, ownProps);
  const { endIndex: endBulkIndex } = _.find(taskGroups, { startIndex: startBulkIndex }) || {};
  return ({
    selectedTaskCount: TaskReportsSelectors.selectedTasksLengthSelector(state),
    startBulkIndex,
    endBulkIndex,
  });
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(SelectedTasksCount);
