import { TaskTagConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskTagActions = {
  add: tag => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTagConstants.ADD,
      tag: { label: tag },
    });
  },

  delete: tag => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTagConstants.DELETE,
      tag: { label: tag },
    });
  },

  expand() {
    return {
      type: TaskTagConstants.EXPAND,
    };
  },

  inputChange(input) {
    return {
      type: TaskTagConstants.INPUT_CHANGE,
      input,
    };
  },

  showInputField() {
    return {
      type: TaskTagConstants.SHOW_INPUT_FIELD,
    };
  },
};
