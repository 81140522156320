import CodeMirror from 'codemirror';
import React from 'react';
import ReactDOM from 'react-dom';

import 'codemirror/mode/javascript/javascript.js';

import CodeMirrorSchemaValidation from './validation/CodeMirrorSchemaValidation';
import createSchemaValidator from './validation/SchemaValidator';

export const emptyJsonSchema = {
  $id: 'https://trailapp.com/empty.schema.json',
  $schema: 'http://json-schema.org/draft-04/schema#',
};

const applyValidation = (id, codeMirrorInstance) => {
  const warningElement = document.getElementById(`${id}_warning`);
  if (!(warningElement)) return;

  let schema;
  try {
    schema = JSON.parse(document.getElementById(`${id}_schema`).value);
  } catch (error) {
    schema = emptyJsonSchema;
  }

  ReactDOM.render(
    React.createElement(
      CodeMirrorSchemaValidation,
      {
        validator: createSchemaValidator(schema),
        codeMirrorEditor: codeMirrorInstance,
      }),
    warningElement);
};

const prettyPrintJson = jsonString => JSON.stringify(JSON.parse(jsonString), null, '\t');

const setupTextarea = (textarea) => {
  textarea.value = prettyPrintJson(textarea.value);

  const cm = CodeMirror.fromTextArea(textarea, {
    lineNumbers: true,
    matchBrackets: true,
    lineWrapping: true,
    viewportMargin: 3,
    gutter: true,
    styleActiveLine: true,
    mode: 'application/json',
    tabSize: 2,
  });

  applyValidation(textarea.id, cm);
};

export const CodeMirrorConfig = {
  init() {
    const codeMirrors = document.querySelectorAll('textarea[data-codemirror]');
    const codeMirrorsLength = codeMirrors.length;
    for (let i = 0; i < codeMirrorsLength; i += 1) {
      setupTextarea(codeMirrors[i]);
    }
  },
};
