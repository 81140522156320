import emojiRegex from 'emoji-regex';

function TagValidationHelper(label) {
  const MIN_LENGTH = 1;
  const PERMITTED_CHARS_REGEX_PATTERN = `([0-9a-zA-Z]|${emojiRegex().source})`;
  const SEPARATOR_CHARS_REGEX_PATTERN = '[ _\-]';
  const VALIDITY_REGEX_PATTERN = `^${PERMITTED_CHARS_REGEX_PATTERN}+(${SEPARATOR_CHARS_REGEX_PATTERN}${PERMITTED_CHARS_REGEX_PATTERN}+)*$`;

  this.isLabelLongEnough = function () {
    return label.length >= MIN_LENGTH;
  };
  this.allCharactersValid = function () {
    return new RegExp(VALIDITY_REGEX_PATTERN).test(label);
  };
}

export const tagValidator = {
  isLabelValid(label) {
    const validator = new TagValidationHelper(label);
    return validator.isLabelLongEnough() && validator.allCharactersValid();
  },
};
