const windowHelperInit = (windowObject) => {
  function navigateInNewTabTo(window, location) {
    window.open(location, '_blank');
  }

  function navigateTo(window, location) {
    window.location.href = location;

    if (window.navigator && window.navigator.standalone) {
      navigateInNewTabTo(window, location);
    }
  }

  function navigateToHashRoute(window, hash) {
    window.location.hash = hash;
  }

  function refreshPage(window) {
    window.location.reload(true);
  }

  function onResize(window, callback) {
    window.addEventListener('resize', callback);
  }

  function browserSupport(window) {
    return window.Modernizr;
  }

  return {
    navigateTo: (location) => {
      navigateTo(windowObject, location);
    },
    navigateInNewTabTo: (location) => {
      navigateInNewTabTo(windowObject, location);
    },
    navigateToHashRoute: (hash) => {
      navigateToHashRoute(windowObject, hash);
    },
    refreshPage: () => {
      refreshPage(windowObject);
    },
    onResize: (callback) => {
      onResize(windowObject, callback);
    },
    browserSupport: () => browserSupport(windowObject),
  };
};
const WindowHelper = windowHelperInit(window);

export { windowHelperInit, WindowHelper };
