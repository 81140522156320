import React, { Component } from 'react';
import { FormattedDate, FormattedTime, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';

import createWidgetComponent, { autosaveWidgetListener } from '../HoC';
import widgetDataListenerHoC from '../HoC/widgetDataListener';

import { WidgetDataActions } from '../../trail/actions';
import { WidgetDataStore } from '../../trail/store';
import { IconStore } from '../../common/legacy/store';
import MysteryDinerQuestionGroup from './MysteryDinerQuestionGroup';
import Header from '../Header';

class MysteryDinerWidget extends Component {
  getPredef = () => {
    return this.props.widget.getWidgetState(this.props.id).widget.predef || {};
  }

  handleQuestionChange = (groupId, questionId, question) => {
    var questionField = _.cloneDeep(this.props.widget.custom('questions'));
    questionField[groupId] = questionField[groupId] || [];
    questionField[groupId][questionId] = question;

    WidgetDataActions.setCustomField(this.props.id, 'questions', questionField);
    WidgetDataActions.queueAutosave(this.props.id);
  }

  getHeaderContent() {
    var element = null;
    var reportUrl = this.getPredef().reportUrl;
    if (reportUrl) {
      element = (
        <div>
          <a href={reportUrl} className="button secondary">
            {this.props.intl.formatMessage({ id: 'mysteryDiner.viewReport' })}
          </a>
        </div>
      );
    }

    return element;
  }

  getHeaderTitle() {
    return (
      <div>
        <div>
          <span>
            {this.props.intl.formatMessage({ id: 'mysteryDiner.heading' })}&nbsp;
            <FormattedDate
              value={new Date(this.getPredef().visitDate)}
              weekday="short"
              day="numeric"
              month="short"
              year="numeric" />
            &nbsp;{this.props.intl.formatMessage({ id: 'mysteryDiner.at' })}&nbsp;
            <FormattedTime
              value={new Date(this.getPredef().visitDate)}
              hour12={true}
              hour='numeric'
              minute='numeric' />
          </span>
        </div>
      </div>
    );
  }

  getQuestions = () => {
    var groups = this.getPredef().groups;

    return (
      groups ?
      <div className='widget-body vertical grid-block'>
        {groups.map(function (group, index) {
          var questionField = this.props.widget.custom('questions') || [];
          return (
            <MysteryDinerQuestionGroup
              key={'group' + index}
              id={this.props.id}
              questionChange={ this.handleQuestionChange }
              groupName={group.groupName}
              questions={questionField[index]}
              groupId={index} />
          );
        }, this)}
      </div> :
      null
    );
  }

  render() {
    if (!this.props.widget.getWidgetState(this.props.id).widget.predef) {
      return this.props.widget.renderEmptyWidget(
        'mystery-diner',
        this.props.intl.formatMessage({ id: 'mysteryDiner.emptyTitle' }),
        null,
        [this.props.intl.formatMessage({ id: 'mysteryDiner.emptyExplanation' })],
      );
    }
    const widgetData = this.props.widget.widget();
    return (
      <div
        className={this.props.widget.widgetFormClasses('mystery-diner')}
        name='widgetForm'>
        <Header
          saving={ this.props.saving }
          logoUrl={IconStore.getLogo(widgetData.public_settings &&
                                     widgetData.public_settings.logo)}
          message={this.getHeaderTitle()}
          additionalContent={this.getHeaderContent()}
        />

        {this.getQuestions()}
      </div>
    );
  }
}

MysteryDinerWidget.propTypes = {
  id: PropTypes.number,
};

const createWidgetHoC = createWidgetComponent(WidgetDataStore);
const withAutosaveStatus = autosaveWidgetListener(({ id }) => id);
export default compose(
  createWidgetHoC,
  widgetDataListenerHoC,
  withAutosaveStatus,
  injectIntl
)(MysteryDinerWidget);
