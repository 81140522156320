import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ValidationMessage, { VALIDATION_MESSAGE_DEFAULT_ERROR, VALIDATION_MESSAGE_ALT_ERROR }
  from '../../common/forms/ValidationMessage';

import {
  taskCommentBoxHasContentSelector,
  showUnsavedTaskCommentErrorsSelector,
} from '../selectors';

export const MandatoryPrompt = ({
  intl, textCommentRequired, fileCommentRequired, displayAsError,
}) => {
  if (!textCommentRequired && !fileCommentRequired) return null;

  let message = '';
  if (textCommentRequired && fileCommentRequired) {
    message = intl.formatMessage({ id: 'conversation.commentBox.requiredComment.both' });
  } else if (fileCommentRequired) {
    message = intl.formatMessage({ id: 'conversation.commentBox.requiredComment.file' });
  } else if (textCommentRequired) {
    message = intl.formatMessage({ id: 'conversation.commentBox.requiredComment.text' });
  }

  return (
    <ValidationMessage
      type={ displayAsError ? VALIDATION_MESSAGE_DEFAULT_ERROR : VALIDATION_MESSAGE_ALT_ERROR }
    >
      { message }
    </ValidationMessage>
  );
};
MandatoryPrompt.propTypes = {
  displayAsError: PropTypes.bool,
  textCommentRequired: PropTypes.bool,
  fileCommentRequired: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};
MandatoryPrompt.defaultProps = {
  displayAsError: false,
  textCommentRequired: false,
  fileCommentRequired: false,
};

export const UnsavedCommentPrompt = ({ intl }) => (
  <ValidationMessage type={ VALIDATION_MESSAGE_DEFAULT_ERROR }>
    { intl.formatMessage({ id: 'conversation.commentBox.unsavedComment' })}
  </ValidationMessage>
);

export const CommentBoxPrompt = ({
  textCommentRequired, fileCommentRequired, doneClicked,
  showUnsavedCommentError, hasContent, intl,
}) => {
  if (textCommentRequired || fileCommentRequired) {
    return (
      <MandatoryPrompt
        { ...{
          textCommentRequired, fileCommentRequired, displayAsError: doneClicked, intl,
        } }
      />
    );
  }
  if (showUnsavedCommentError && hasContent) return <UnsavedCommentPrompt intl={ intl } />;
  return null;
};

CommentBoxPrompt.propTypes = {
  textCommentRequired: PropTypes.bool,
  fileCommentRequired: PropTypes.bool,
  doneClicked: PropTypes.bool,
  hasContent: PropTypes.bool,
  showUnsavedCommentError: PropTypes.bool,
};

CommentBoxPrompt.defaultProps = {
  textCommentRequired: false,
  fileCommentRequired: false,
  doneClicked: false,
  hasContent: false,
  showUnsavedCommentError: false,
};

const mapStateToProps = (state, ownProps) => ({
  hasContent: taskCommentBoxHasContentSelector(state, ownProps),
  showUnsavedCommentError: showUnsavedTaskCommentErrorsSelector(state, ownProps),
});

export default compose(
  injectIntl,
  connect(mapStateToProps)
)(CommentBoxPrompt);
