import React, { PureComponent } from 'react';

import { WidgetDataStore } from '../../trail/store';
import { WidgetDataConstants } from '../../trail/constants';

const { WIDGET_AUTOSAVE_UPDATED } = WidgetDataConstants;

const autosaveWidgetListener = widgetIdSelector => Element => (
  class extends PureComponent {
    displayName = `WithWidgetDataListener(${Element.displayName || Element.name})`;

    state = {
      saving: WidgetDataStore.getWidget(this.widgetId).saving || false,
    };


    componentDidMount() {
      WidgetDataStore.on(WIDGET_AUTOSAVE_UPDATED, this.onChange);
    }

    componentWillUnmount() {
      WidgetDataStore.removeListener(WIDGET_AUTOSAVE_UPDATED, this.onChange);
    }

    onChange = (id, saving) => {
      if (id !== this.widgetId) return;

      this.setState({ saving });
    };

    get widgetId() {
      return widgetIdSelector(this.props);
    }

    render() {
      return (
        <Element { ...this.props } saving={ this.state.saving } />
      );
    }
  }
);

export default autosaveWidgetListener;
