export class TimelineTemplateIds {
  timelineTemplateIdsInput = null;

  constructor() {
    this.timelineTemplateIdsInput = document.getElementById('user_timeline_template_ids');
  }

  get value() {
    const { value } = this.timelineTemplateIdsInput;
    return value.split(' ')
      .reduce((accu, id) => {
        const idNum = parseInt(id, 10);
        if (!idNum) {
          return accu;
        }
        return [...accu, idNum];
      }, []);
  }

  set value(assignmentsArray) {
    const value = assignmentsArray.join(' ');
    this.timelineTemplateIdsInput.value = value;
    return value;
  }
}
