import _ from 'lodash';
import { MetricsHitTypeConstants, MetricsPublisherConstants } from './constants';

export default function SegmentTransport(config) {
  this.config = config;
  this.options = _.get(this.config, 'options', {});

  this._defaultMetricEvent = {
    hitType: MetricsHitTypeConstants.EVENT,
    category: '',
    action: '',
    label: '',
  };

  this._defaultPageViewEvent = {
    hitType: MetricsHitTypeConstants.PAGE_VIEW,
  };

  if (this.config && this.config.customDimensions) {
    Object.keys(this.config.customDimensions)
      .forEach(function (dimension) {
        this._defaultMetricEvent[dimension] = this.config.customDimensions[dimension];
        this._defaultPageViewEvent[dimension] = this.config.customDimensions[dimension];
      }, this);
  }
}

SegmentTransport.prototype = _.extend({}, {
  MATCH_LEADING_SLASH: /^\//,
  MATCH_UNTIL_SLASH: /.+?(?=\/)/,

  init() {
    if (!window.analytics) {
      // eslint-disable-next-line no-console
      console.warn('analytics (Segment) is not defined, invalid transport');
    }
  },

  getKey() {
    return MetricsPublisherConstants.SEGMENT;
  },

  identify(props) {
    const identifyObject = window.identify_object || {};
    const updated = {
      ...identifyObject,
      ...props,
    };
    window.analytics.identify(updated);
  },

  recordMetric(name, data) {
    const properties = _.extend({}, this._defaultMetricEvent, data);
    if (!window.analytics) {
      return Promise.resolve();
    }
    return new Promise(res => window.analytics.track(
      name,
      properties,
      this.options,
      res
    ));
  },

  recordPageView(path, title) {
    const matches = path.replace(this.MATCH_LEADING_SLASH, '').match(this.MATCH_UNTIL_SLASH);
    const name = (matches && matches.length) ? matches[0] : path;
    const search = path && path.indexOf('#') > 0 ? path.substring(path.indexOf('#')) : null;
    const properties = _.extend({}, this._defaultPageViewEvent, {
      page: path,
      title,
      search,
    });

    window.analytics.page(name, properties, this.options);
  },
});
