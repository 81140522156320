import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../common/SVGIcon';

const WidgetPreviewFallback = ({ widgetName }) => (
  <div className='showtime-widget' data-test='widgetPreviewFallback'>
    <div className='showtime-widget__header'>
      <div className='showtime-widget__title-and-icon'>
        <div className='showtime-widget__icon'>
          <SVGIcon
            classes='showtime-icon showtime-icon--size-16'
            iconName='mini/business-finance/business-16px_notes'
          />
        </div>
        <div className='showtime-widget__title'>
          { widgetName }
        </div>
      </div>
    </div>
  </div>
);

WidgetPreviewFallback.propTypes = {
  widgetName: PropTypes.string.isRequired,
};

export default WidgetPreviewFallback;
