import React from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { getCurrentUserRole } from '../../../../application/UserRole';
import HeaderCell from './HeaderCell';

import {
  inputTypes,
  columnPropTypes,
  SECTION_TITLE,
} from '../constants';

const Head = ({ columns, intl }) => (
  <thead>
    <tr
      className='showtime-table__row'
    >
      <th className='showtime-table__header-cell'>&nbsp;</th>
      {columns.map((
        {
          columnTitle,
          cellType,
          cellSettings: {
            columnRestricted,
            copyValueToNew,
          },
        },
        index
      ) => {
        const toolTipAlignmentClass = index === columns.length - 1
          ? 'hint--bottom-left'
          : 'hint--bottom-right';
        const disabled = !getCurrentUserRole().isManagerOrAbove() && columnRestricted;

        return inputTypes.includes(cellType) && (
          <HeaderCell
            key={ columnTitle }
            showTooltip={ disabled || copyValueToNew }
            tooltipClassName={ toolTipAlignmentClass }
            disabled={ disabled }
            emphasis={ cellType === SECTION_TITLE }
          >
            {columnTitle}
          </HeaderCell>
        );
      })}
      <th className='showtime-table__header-cell'>
        { intl.formatMessage({ id: 'trail.widgets.data_capture.last_updated' }) }
      </th>
      { columns.some(({ cellSettings: { scores } = {} }) => _.size(scores)) && (
        <th className='showtime-table__header-cell'>
          { intl.formatMessage({ id: 'trail.widgets.data_capture.tableHeader.score' })}
        </th>
      )}
    </tr>
  </thead>
);

Head.propTypes = {
  ...columnPropTypes,
};

export default injectIntl(Head);
