import _ from 'lodash';
import FlashConstants, { NoticeConstants } from '../constants';

export const noticesReducer = (state, action) => {
  const initialState = {
    content: {
      value: '',
    },
  };
  const currState = state || _.extend({}, initialState);
  const { noticeMessage } = action;

  switch (action.type) {
    case NoticeConstants.NOTICE:
    case FlashConstants.NOTICE:
      return _.extend({}, currState, {
        content: {
          value: noticeMessage,
        },
      });
    case NoticeConstants.CLEAR:
      return _.extend({}, currState, {
        content: {
          value: '',
        },
      });
    default:
      return currState;
  }
};
