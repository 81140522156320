import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabContent = (props) => {
  const classes = classNames({
    'showtime-tabs-underline-contents-item': true,
    'is-inactive': !props.selected,
    'is-active': props.selected,
  });
  return (
    <div className={ classes } data-tabindex={ props.index }>{props.children}</div>
  );
};

TabContent.propTypes = {
  index: PropTypes.number,
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

TabContent.defaultProps = {
  index: 0,
  selected: false,
  children: [],
};

export default TabContent;
