import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { injectIntl } from 'react-intl';

import TaskScheduleConstants from '../../schedule/constants';
import { getMaxDate } from '../../schedule/nextInstances';
import Button from '../../common/Button';
import SVGIcon from '../../common/SVGIcon';
import RadioButton from '../../common/RadioButton';

const Arrow = () => (
  <div className='showtime-scheduling__arrow'>
    <SVGIcon iconName='mini/arrows/arrows-16px-1_tail-triangle-right' />
  </div>
);

class DateRangeSelection extends Component {
  renderSpecificDatesToggle = () => {
    const {
      content: { isBetweenDates, recurrence: { unit } },
      intl: { formatMessage },
      readOnly,
    } = this.props;
    const selectionType = unit === TaskScheduleConstants.RECUR_PERIODS.Week
      ? 'weekday'
      : 'date';
    const handleSingleDateSelect = () => {
      this.props.actions.setIsBetweenDates(false);
      this.props.onIsBetweenDatesChange();
    };
    const handleBetweenDatesSelect = () => {
      this.props.actions.setIsBetweenDates(true);
      this.props.onIsBetweenDatesChange();
    };
    return (
      <div className='showtime-scheduling__input-row' data-test='scheduling.isBetweenDatesSelection'>
        <RadioButton
          onChange={ handleSingleDateSelect }
          checked={ !isBetweenDates }
          disabled={ readOnly }
        >
          { formatMessage({ id: `taskWizard.schedule.onSpecific.${selectionType}` })}
        </RadioButton>
        <RadioButton
          onChange={ handleBetweenDatesSelect }
          checked={ isBetweenDates }
          disabled={ readOnly }
        >
          { formatMessage({ id: `taskWizard.schedule.inBetween.${selectionType}` }) }
        </RadioButton>
      </div>
    );
  };

  renderDatePickerButton(type, date, error) {
    const { recurrence } = this.props.content;
    const isToday = (moment(date).isSame(moment(), 'days'));
    const isMonthly = recurrence.unit === TaskScheduleConstants.RECUR_PERIODS.Month;
    const buttonClasses = classNames({
      'showtime-button--block': true,
      'showtime-button--default': true,
      'showtime-button--block-left': true,
      'has-error': error,
      'is-disabled': this.props.readOnly,
    });

    let label;
    if (isToday) {
      label = this.props.intl.formatMessage({ id: 'taskWizard.schedule.today' });
    } else if (isMonthly) {
      label = moment(date).format('Do, MMM');
    } else {
      label = moment(date).format('ddd, Do MMM');
    }

    return (
      <div data-datepicker-button={ type }>
        <Button
          onClick={ e => e.preventDefault() }
          label={ label }
          buttonClasses={ buttonClasses }
          iconName='mini/ui/ui-16px-1_calendar-60'
          disabled={ this.props.readOnly }
        />
      </div>
    );
  }

  renderSingleDatePicker() {
    const hasError = this.props.content.summary.errorMessage === TaskScheduleConstants.ERRORS.DATE;
    return (
      <div data-single-datepicker className='showtime-scheduling__input-row'>
        <DatePicker
          customInput={
            this.renderDatePickerButton(
              TaskScheduleConstants.SET_SINGLE_DATE, this.props.content.start.date, hasError)
          }
          dateFormat='DD/MM/YYYY'
          minDate={ moment(this.props.minDate) }
          selected={ moment(this.props.content.start.date) }
          onChange={ this.props.actions.setSingleDate }
          disabled={ this.props.readOnly }
          className={ classNames({ 'is-disabled': this.props.readOnly }) }
        />
      </div>
    );
  }

  renderStartDatePicker() {
    const hasError = this.props.content.summary.errorMessage === TaskScheduleConstants.ERRORS.DATE;
    return (
      <div data-start-datepicker className='showtime-scheduling__input-range--input'>
        <DatePicker
          customInput={
            this.renderDatePickerButton(
              TaskScheduleConstants.SET_START_DATE, this.props.content.start.date, hasError)
          }
          dateFormat='DD/MM/YYYY'
          minDate={ moment(this.props.minDate) }
          selected={ moment(this.props.content.start.date) }
          onChange={ this.props.actions.setStartDate }
          disabled={ this.props.readOnly }
          className={ classNames({ 'is-disabled': this.props.readOnly }) }
        />
      </div>
    );
  }

  conditionalLeftTooltips = () => this.props.forceLeftTooltips
    ? { popperPlacement: 'left-end' }
    : {};

  renderEndDatePicker() {
    const {
      content: {
        recurrence, summary, start, end,
      },
    } = this.props;
    const hasError = summary.errorMessage === TaskScheduleConstants.ERRORS.DATE;

    return (
      <div data-end-datepicker className='showtime-scheduling__input-range--input'>
        <DatePicker
          customInput={
            this.renderDatePickerButton(
              TaskScheduleConstants.SET_END_DATE, end.date, hasError)
          }
          dateFormat='DD/MM/YYYY'
          minDate={ moment(start.date) }
          maxDate={ getMaxDate(start.date, recurrence.unit, recurrence.by) }
          selected={ moment(end.date) }
          onChange={ this.props.actions.setEndDate }
          disabled={ this.props.readOnly }
          className={ classNames({ 'is-disabled': this.props.readOnly }) }
          { ...this.conditionalLeftTooltips() }
        />
      </div>
    );
  }

  renderBetweenTwoDatesSelector = () => (
    <div className='showtime-scheduling__input-range'>
      { this.renderStartDatePicker() }
      <Arrow />
      { this.renderEndDatePicker() }
    </div>
  );

  renderWeekdaySelector = () => (
    <div
      data-schedule-weekdays
      className='showtime-scheduling__input-row'
    >
      { TaskScheduleConstants.weekDaysNewSchedule.map((day) => {
        const normalizedDay = day.toLowerCase();
        const checked = this.props.content.recurrence.on[day];
        return (
          <Button
            key={ day }
            dataTest='weekday'
            onClick={ () => this.props.actions.toggleDay(day) }
            disabled={ this.props.readOnly }
            className={ classNames(
              'showtime-button showtime-button--adjacent showtime-button--scheduling',
              {
                'showtime-button--highlight-alt': checked,
                'showtime-button--default': !checked,
                'is-disabled': this.props.readOnly,
              }
            ) }
            label={ this.props.intl.formatMessage({ id: `taskWizard.schedule.weekdays.${normalizedDay}` }).slice(0, 2) }
          />
        );
      }) }
    </div>
  );

  render() {
    const {
      content: { recurrence, isBetweenDates },
    } = this.props;
    const isWeekly = recurrence.unit === TaskScheduleConstants.RECUR_PERIODS.Week;
    const singleDatePicker = isWeekly
      ? this.renderWeekdaySelector()
      : this.renderSingleDatePicker();
    return (
      <React.Fragment>
        { this.renderSpecificDatesToggle() }
        { isBetweenDates
          ? this.renderBetweenTwoDatesSelector()
          : singleDatePicker}
      </React.Fragment>
    );
  }
}

DateRangeSelection.propTypes = {
  onIsBetweenDatesChange: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  forceLeftTooltips: PropTypes.bool,
  readOnly: PropTypes.bool,
  content: PropTypes.shape({
    isBetweenDates: PropTypes.bool,
    start: PropTypes.shape({
      date: PropTypes.instanceOf(Date),
    }),
    end: PropTypes.shape({
      date: PropTypes.instanceOf(Date),
    }),
    recurrence: PropTypes.shape({
      unit: PropTypes.string,
      by: PropTypes.number,
      on: PropTypes.object,
    }),
    summary: PropTypes.shape({
      nextInstances: PropTypes.array,
      errorMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]),
    }),
  }).isRequired,
  actions: PropTypes.shape({
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    toggleDay: PropTypes.func,
    setSingleDate: PropTypes.func,
    setIsBetweenDates: PropTypes.func,
  }).isRequired,
};

DateRangeSelection.defaultProps = {
  onIsBetweenDatesChange() {},
  forceLeftTooltips: false,
  readOnly: false,
};

export default injectIntl(DateRangeSelection);
