export { MetricsHitTypeConstants } from './metricsHitTypeConstants';
export { ConnectionMetricsConstants } from './connectionMetricsConstants';

export const MetricsPublisherConstants = {
  GA: 'ga',
  KEEN: 'keen',
  SEGMENT: 'segment',
  actionRemovalType: {
    FIELD_CHANGED: 'field_changed',
    ROW_DELETED: 'row_deleted',
  },
};

export const PageNameConstants = {
  TASK_REPORTS: 'TaskReports',
  SCORES: 'Scores',
};

export const WootricConstants = {
  SHOW_ON_ROUTE: [
    /\/taskplanner\/?$/,
    /\/reports\/?$/,
    /\/sites\/?$/,
    /\/organizations\/\d+\/timeline_templates\/?$/,
    /\/organizations\/\d+\/users\/?$/,
  ],
  EXCLUDED_EMAIL_DOMAINS: ['theaccessgroup.com', 'trailapp.com'],
  TEXT: 'Learn how we handle your feedback',
  LINK: 'https://www.theaccessgroup.com/en-gb/privacy-notice/',
  LINK_WORD: 'here',
  PRODUCT_REPORTING_CODE: 417,
  DEVICE_OS: 'Web',
  PRODUCT: 'Access Trail',
  MODAL_FOOTPRINT: 'compact',
};
