import _ from 'lodash';

import AppDispatcher from '../../../application/dispatcher';
import { WidgetDataConstants } from '../../../trail/constants';

export default (widgetData) => {
  AppDispatcher.dispatch({
    actionType: WidgetDataConstants.SET_STORE,
    widget_data: _.toArray(JSON.parse(widgetData)),
  });
};
