import React from 'react';
import PropTypes from 'prop-types';
import Message from '../common/Message';

const FlashMessageContent = ({
  type,
  message,
  iconPath,
  buttonLabel,
  action,
}) => {
  const messageClasses = `showtime-message--toast ${type}`;
  const messageButton = {
    buttonClasses: 'showtime-button--small showtime-button--inverse',
    onClick: action,
    label: buttonLabel,
  };

  return (
    <Message
      key={ type }
      messageClasses={ messageClasses }
      messages={ [message] }
      iconName={ iconPath }
      button={ messageButton }
    />
  );
};

FlashMessageContent.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default FlashMessageContent;
