import AttachmentConstants from '../attachments/constants';

const formatFileType = attachment => ({
  name: attachment.name,
  previousName: attachment.name,
  fileName: attachment.attachment_file_name,
  url: attachment.url,
  filestackHandle: attachment.filestack_handle,
  mimeType: attachment.attachment_content_type,
  fileSize: attachment.attachment_file_size,
  s3Key: attachment.s3_object_key,
  state: AttachmentConstants.State.SAVED,
  type: attachment.type,
});

const formatLinkType = attachment => ({
  url: attachment.url,
  name: attachment.name || attachment.url,
  previousName: attachment.name,
  state: AttachmentConstants.State.SAVED,
  type: attachment.type,
});

export const loadAttachments = (action) => {
  if (!action.content) return [];
  const { contents = [] } = action.content;
  return contents.map((attachment) => {
    if (attachment.type === AttachmentConstants.FILE_TYPE) return formatFileType(attachment);
    if (attachment.type === AttachmentConstants.LINK_TYPE) return formatLinkType(attachment);
    return {};
  });
};
