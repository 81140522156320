import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import FieldDropdown from './FieldDropdown';
import OtherwiseNextField from './OtherwiseNextField';
import BlockTitle from './BlockTitle';
import { EditWidgetActions } from '../../../actions';
import {
  jumpToHasFieldOrderError,
  missingJumpToFieldError,
  isLastColumnError,
} from '../../../reducers/builderValidation/constants';
import { VALIDATION_ERROR, VALIDATION_WARNING } from '../../../../common/constants/validation';
import { createIntlMessageFormatter } from '../../../../common/intlMessageFormatter';

const {
  columnLogicByIndexSelector, columnLogicPathSelector, widgetBuilderColumnLogicErrorsSelector,
  taskWidgetColumnsSelector, logicWillSkipMandatoryFieldSelector,
} = TaskWidgetSelectors;
const { setWidgetPublicSettings } = EditWidgetActions;

const errorMessages = {
  [jumpToHasFieldOrderError]: 'jumpToHasFieldOrderError',
  [missingJumpToFieldError]: 'missingField',
  [isLastColumnError]: 'isLastColumnError',
};

const LogicThen = ({ columnIndex, logicIndex, thenIndex }) => {
  const logic = useSelector(state => (
    columnLogicByIndexSelector(state, { columnIndex, logicIndex })
  ));
  const thenFieldNamePath = `then[${thenIndex}].fieldName`;

  const logicUpdatePath = useSelector(
    state => columnLogicPathSelector(state, { logicIndex, columnIndex }));

  const dispatch = useDispatch();
  const handleLogicJumpFieldNameChange = fieldName => dispatch(
    setWidgetPublicSettings(`${logicUpdatePath}.${thenFieldNamePath}`, fieldName));

  const formatLogicMessage = createIntlMessageFormatter(useIntl(), 'editWidget.builder.optionsColumn.logic');

  const errors = useSelector(
    state => widgetBuilderColumnLogicErrorsSelector(state, { columnIndex }));

  const validationMessages = _.reduce(errorMessages, (messages, errorIntlPath, errorKey) => {
    if (!errors.includes(`${errorKey}[${logicIndex}]`)) return messages;
    return [...messages, {
      type: VALIDATION_ERROR,
      message: formatLogicMessage(`errors.${errorIntlPath}`),
    }];
  }, []);

  const columns = useSelector(taskWidgetColumnsSelector);
  const selectedFieldName = _.get(logic, thenFieldNamePath);
  const selectedColumn = _.find(columns, { cellSettings: { fieldName: selectedFieldName } });
  const logicWillSkipMandatoryField = useSelector(
    state => logicWillSkipMandatoryFieldSelector(state, { columnIndex, logicIndex, thenIndex }));

  if (logicWillSkipMandatoryField) {
    validationMessages.push({
      type: VALIDATION_WARNING,
      message: formatLogicMessage('errors.willSkipMandatory'),
    });
  }

  return (
    <div>
      <div className='showtime-record-log-builder-field--block'>
        <BlockTitle>
          { formatLogicMessage('then.jumpTo') }
        </BlockTitle>
        <FieldDropdown
          onChange={ handleLogicJumpFieldNameChange }
          selectedColumn={ selectedColumn }
          value={ selectedFieldName }
          columnOptions={ columns.slice(columnIndex + 1) }
          dataTest='logic.then.fieldSelect'
          errors={ validationMessages }
          includeJumpToEnd={ (columnIndex + 1) !== columns.length }
        />
      </div>
      <OtherwiseNextField columnIndex={ columnIndex } />
    </div>
  );
};

LogicThen.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  logicIndex: PropTypes.number.isRequired,
  thenIndex: PropTypes.number.isRequired,
};

export default LogicThen;
