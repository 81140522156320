import _ from 'lodash';
import EventEmitter from 'wolfy87-eventemitter';

import AppDispatcher from './dispatcher';
import ApplicationEventsConstants from './constants';

const applicationEventsStoreStoreFactory = function () {
  const ApplicationEventsStore = function () {
    // see http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
    this.SCROLL_THROTTLE = 1000 / 60;
    window.onscroll = this.getOnScrollHandler();
  };

  _.extend(ApplicationEventsStore.prototype, EventEmitter.prototype, {
    _isOnline: true,

    isOnline() {
      return this._isOnline;
    },

    setOnline() {
      this._isOnline = true;
    },

    setOffline() {
      this._isOnline = false;
    },

    addConnectionStatusListener(callback) {
      this.on(ApplicationEventsConstants.CONNECTION_STATUS, callback);
    },

    removeConnectionStatusListener(callback) {
      this.removeListener(ApplicationEventsConstants.CONNECTION_STATUS, callback);
    },

    emitConnectionStatusChange() {
      this.emit(ApplicationEventsConstants.CONNECTION_STATUS, this.isOnline());
      this.emitChange();
    },

    addChangeListener(callback) {
      this.on(ApplicationEventsConstants.CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
      this.removeListener(ApplicationEventsConstants.CHANGE_EVENT, callback);
    },

    emitChange() {
      this.emit(ApplicationEventsConstants.CHANGE_EVENT);
    },

    addScrollListener(callback) {
      this.on(ApplicationEventsConstants.SCROLL, callback);
    },

    removeScrollListener(callback) {
      this.removeListener(ApplicationEventsConstants.SCROLL, callback);
    },

    getOnScrollHandler() {
      return _.throttle(() => {
        this.emit(ApplicationEventsConstants.SCROLL);
      }, this.SCROLL_THROTTLE);
    },

    scrollToCoordinates(payload) {
      this.scrollTimer.delayEventName(payload.coordinates);
    },
  });

  return new ApplicationEventsStore();
};

const ApplicationEventsStore = applicationEventsStoreStoreFactory();

AppDispatcher.register((payload) => {
  const action = payload.actionType;

  switch (action) {
    case ApplicationEventsConstants.ONLINE:
      ApplicationEventsStore.setOnline();
      ApplicationEventsStore.emitConnectionStatusChange();
      break;
    case ApplicationEventsConstants.OFFLINE:
      ApplicationEventsStore.setOffline();
      ApplicationEventsStore.emitConnectionStatusChange();
      break;
    default:
      break;
  }
});

if (process.env.TEST === true) {
  window.ApplicationEventsStore = ApplicationEventsStore;
}

export default ApplicationEventsStore;
