export const WARNING = 'warning';
export const INFO = 'info';
export const SUCCESS = 'success';
export const ONLINE = 'online';
export const ERROR = 'error';

export const types = [
  ERROR,
  INFO,
  ONLINE,
  SUCCESS,
  WARNING,
];

export const actionConstants = {
  ADD_MESSAGE: 'floatification.add',
  REMOVE_MESSAGE_BY_INDEX: 'floatification.removeByIndex',
  REMOVE_MESSAGE_BY_ID: 'floatification.removeById',
  CLEAR_MESSAGES: 'floatification.clearMessages',
};

export const FLOATIFICATION_PERSIST_DURATION = 5000;
