import React from 'react';
import PropTypes from 'prop-types';

import { getFileCdnUrl } from '../file/filestackApi';
import { canMakeValidCdnUrl } from '../file/securityValidation';
import { GRID_IMAGE_OPTION } from '../file/constants';
import LoadingSpinner from '../common/LoadingSpinner';
import { readPolicyHOC } from '../file/hoc';

export const AttachmentImage = ({
  policy, signature, handle, renderImageOption, ...imgProps
}) => {
  if (canMakeValidCdnUrl({ policy, signature, handle })) {
    const src = getFileCdnUrl({
      handle, policy, signature, renderImageOption,
    });
    return <img alt='Comment Attachment' src={ src } { ...imgProps } />;
  }

  const showLabel = renderImageOption !== GRID_IMAGE_OPTION;

  return (
    <div data-test={ `img-attachment-loader-${handle}` }>
      <LoadingSpinner classes={ ['showtime-loader--no-padding'] } showLabel={ showLabel } />
    </div>
  );
};

AttachmentImage.propTypes = {
  policy: PropTypes.string,
  handle: PropTypes.string.isRequired,
  signature: PropTypes.string,
  renderImageOption: PropTypes.string,
};

AttachmentImage.defaultProps = {
  policy: '',
  signature: '',
  renderImageOption: null,
};

export default readPolicyHOC(AttachmentImage);
