import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../common/Button';
import OfflineInteractionBlocker from '../../offline-interaction-blocker';
import { TOP } from '../../interaction-blocker/positions';
import { SMALL } from '../../interaction-blocker/sizes';

export const FilePicker = ({ onPickerOpen, disabled, intl }) => (
  <div className='showtime-conversation__comment-box-inline-file-picker'>
    <OfflineInteractionBlocker
      tooltipMessage={ intl.formatMessage({ id: 'conversation.commentBox.upload.offline.notAvailable' }) }
      size={ SMALL }
      position={ TOP }
    >
      <Button
        iconName='glyph/24px/ui/ui-24px-glyph-1_attach-87'
        buttonClasses={
          classNames(
            'showtime-button',
            'showtime-button--input-file',
            { 'is-disabled': disabled }
          )
        }
        onClick={ onPickerOpen }
        dataTest='file.picker'
      />
    </OfflineInteractionBlocker>
  </div>
);

FilePicker.propTypes = {
  onPickerOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

FilePicker.defaultProps = {
  onPickerOpen() {},
  disabled: false,
};

export default FilePicker;
