import { MESSAGE_CHECK_ONLINE_QUEUE } from '../../../service-workers/constants';
import safePostMessage from '../helpers/safePostMessage';

export default async (offlineQueueProcessing) => {
  if (navigator.onLine && !offlineQueueProcessing) {
    safePostMessage({
      type: MESSAGE_CHECK_ONLINE_QUEUE,
    });
  }
};
