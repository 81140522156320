import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment-timezone';

import { LocationConstants } from '../sites/constants';
import calculateDaysUntilLaunch from '../sites/calculateDaysUntilLaunch';

const classByStatus = {
  [LocationConstants.STATUSES.demo]: 'showtime-callout--inactive',
  [LocationConstants.STATUSES.inactive]: 'showtime-callout--inactive',
  [LocationConstants.STATUSES.setup]: 'showtime-callout--info',
  [LocationConstants.STATUSES.active]: 'showtime-callout--active',
  [LocationConstants.STATUSES.archived]: 'showtime-callout--warning',
};

export class LocationStatusLabel extends Component {
  render() {
    const { status, activeDate, intl } = this.props;

    switch (status) {
      case LocationConstants.STATUSES.demo:
      case LocationConstants.STATUSES.archived:
      case LocationConstants.STATUSES.active:
      case LocationConstants.STATUSES.inactive:
        return this.renderBasicStatus(status, intl);
      default:
        return this.renderSetupStatus(status, activeDate, intl);
    }
  }

  renderBasicStatus = (status, intl) => (
    this.renderStatusLabel(
      classByStatus[status],
      intl.formatMessage({ id: `sites.status.${status}` })
    )
  )

  renderSetupStatus = (status, activeDate, intl) => {
    const renderFallback = () => this.renderBasicStatus(status, intl);

    if (!activeDate) return renderFallback();

    const days = calculateDaysUntilLaunch(activeDate);
    if (!_.isFinite(days) || days < 0) return renderFallback();

    const launchmessage = this.props.intl.formatMessage({ id: 'sites.status.goLiveIn' }, { days });
    return this.renderStatusLabel(classByStatus[status], launchmessage);
  }

  renderStatusLabel = (statusClass, message) => (
    <div className={ `showtime-callout ${statusClass}` } data-test='site.status'>
      { message }
    </div>
  )
}

LocationStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  activeDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
  ]),
  intl: PropTypes.object.isRequired,
};

LocationStatusLabel.defaultProps = {
  activeDate: null,
};

export default injectIntl(LocationStatusLabel);
