import { ReportConstants } from '../constants';
import { ReportSelectors } from '../selectors';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const ReportActions = {
  clearTaskCompletionSubscriptions: () => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: ReportConstants.CLEAR_ALL_TASK_COMPLETION_SUBSCRIPTIONS,
    });
  },

  setNotifyAllUsers: ({ isSelected }) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: ReportConstants.SET_NOTIFY_ALL_USERS,
      value: isSelected,
    });
  },

  selectTaskCompletionSubscription: () => ({
    type: ReportConstants.SET_SELECT_MODE,
    value: ReportConstants.selectMode.COMPLETED,
  }),

  selectTaskCompletionWithExceptionsSubscription: () => ({
    type: ReportConstants.SET_SELECT_MODE,
    value: ReportConstants.selectMode.COMPLETED_WITH_EXCEPTIONS,
  }),

  setUserSubscription: ({ value: userId, isSelected }) => (dispatch, getState) => {
    dispatch(unsavedChangesActions.touched());

    const users = ReportSelectors.getUsersForTaskCompletionSubscriptions(getState());

    dispatch({
      type: ReportConstants.SET_TASK_COMPLETION_USER_SUBSCRIPTION,
      userId,
      isSelected,
      users,
    });
  },
};
