import { get } from 'lodash';
import {
  capitalizePlanNames,
  CAPITALISE_FREE_NAME,
  CAPITALISE_TEAM_NAME,
  PLAN_IDS,
  PLAN_NAMES,
  FREE_ID,
  TEAM_NAME,
  products,
} from './constants';

const userPlan = () => get(window, 'current_user.plan', false);

export const currentPlan = () => {
  const { plan } = window;
  return PLAN_NAMES[plan] || userPlan() || TEAM_NAME;
};

export const currentPlanCapitalCase = () => {
  const { plan } = window;
  return capitalizePlanNames[plan] || userPlan() || CAPITALISE_TEAM_NAME;
};

export const hasKnownPlan = () => {
  const { plan } = window;
  return PLAN_IDS.includes(plan) || Boolean(userPlan());
};

export const isFreePlan = () => {
  const { plan } = window;
  return plan === FREE_ID || userPlan() === CAPITALISE_FREE_NAME;
};

export const currentPrice = () => {
  const plan = currentPlan();
  const { amount } = products[plan] || { amount: 0 };
  return amount / 100;
};

export const totalPrice = totalSites => currentPrice() * Number(totalSites);

export const currentProductId = () => {
  const plan = currentPlan();
  const { productId } = products[plan] || { productId: null };
  return productId;
};
