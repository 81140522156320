import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getSortOrderFromItems = items => items.map(({ id }, position) => ({ id, position }));

class SortableList extends Component {
  reorderItems = (fromPosition, toPosition) => {
    const fromItem = this.props.items[fromPosition];

    const sortedItems = [...this.props.items];
    sortedItems.splice(fromPosition, 1);
    sortedItems.splice(toPosition, 0, fromItem);

    return getSortOrderFromItems(sortedItems);
  };

  handleDragEnd = (result) => {
    if (!result.destination) return;

    const sortOrder = this.reorderItems(result.source.index, result.destination.index);
    this.props.onSort(sortOrder);
  };

  render() {
    const { items, children: renderChild, id, draggingClass } = this.props;

    return (
      <DragDropContext onDragEnd={ this.handleDragEnd }>
        <Droppable
          droppableId={ id }
          renderClone={
            (dragProvided, stateSnapshot, draggableRubric) => (
              <div
                ref={ dragProvided.innerRef }
                { ...dragProvided.draggableProps }
              >
                {
                  renderChild(
                    {
                      item: items[draggableRubric.source.index],
                      dragProvided,
                      index: draggableRubric.source.index,
                      isDragging: stateSnapshot.isDragging,
                    }
                  )
                }
              </div>
            )
          }
        >
          {(dropProvided, stateSnapshot) => (
            <div
              { ...dropProvided.droppableProps }
              className={ stateSnapshot.isDraggingOver && draggingClass }
              ref={ dropProvided.innerRef }
            >
              {
                items.map((item, index) => (
                  <Draggable
                    draggableId={ `draggable-${item.id}` }
                    key={ item.id }
                    isDragDisabled={ item.sortDisabled }
                    index={ index }
                  >
                    {(dragProvided, draggableState) => (
                      <div
                        ref={ dragProvided.innerRef }
                        { ...dragProvided.draggableProps }
                      >
                        {
                          renderChild(
                            { item, dragProvided, index, isDragging: draggableState.isDragging }
                          )
                        }
                      </div>
                    )}
                  </Draggable>
                ))
              }
              { dropProvided.placeholder }
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

SortableList.propTypes = {
  onSort: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, sortDisabled: PropTypes.bool })
  ),
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  draggingClass: PropTypes.string,
};

SortableList.defaultProps = {
  onSort() {},
  items: [],
  draggingClass: null,
};

export default SortableList;
