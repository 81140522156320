import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import CommentBoxPrompt from './CommentBoxPrompt';
import SendCommentButton from './SendCommentButton';
import FileTextArea from '../../file/FileTextArea';

import { TaskSelectors } from '../../trail/selectors';
import { CommentBoxActions, TaskCommentBoxActions } from '../actions';
import { taskCommentBoxMessageSelector, taskCommentBoxAttachmentSelector } from '../selectors';

export const CommentBoxV2 = (props) => {
  const dispatch = useDispatch();
  const handleTextChange = value => dispatch(
    CommentBoxActions.change(value, props.taskId)
  );
  const handleAttachmentChange = value => dispatch(
    TaskCommentBoxActions.setAttachment({ attachment: value, taskId: props.taskId })
  );

  const showErrors = useSelector(state => TaskSelectors.showCommentErrorsSelector(state, props));
  const textValue = useSelector(state => taskCommentBoxMessageSelector(state, props));
  const attachment = useSelector(state => taskCommentBoxAttachmentSelector(state, props));
  const { cameraOnlyCommentRequired } = props.completionErrors;

  const {
    doneClicked, taskId, commentBoxRef, isTaskDone, conversationId,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <FileTextArea
        textareaRef={ commentBoxRef }
        textValue={ textValue }
        hasError={ showErrors }
        onTextChange={ handleTextChange }
        onAttachmentChange={ handleAttachmentChange }
        attachment={ attachment }
        showCameraOnly={ cameraOnlyCommentRequired }
        dataTest={ props.dataTest }
        key='what is happening'
        stacked={ props.stacked }
        placeholder={ formatMessage({ id: 'conversation.commentBox.placeholder' }) }
      >
        { props.showSaveButton && (
          <SendCommentButton taskId={ taskId } conversationId={ conversationId } />
        ) }
      </FileTextArea>
      {!isTaskDone && (
        <CommentBoxPrompt
          { ...{ ...props.completionErrors, doneClicked, taskId } }
        />
      )}
    </React.Fragment>
  );
};

CommentBoxV2.propTypes = {
  commentBoxRef: PropTypes.shape({ current: PropTypes.any }),
  taskId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  conversationId: PropTypes.string,
  completionErrors: PropTypes.shape({
    textCommentRequired: PropTypes.bool,
    fileCommentRequired: PropTypes.bool,
    cameraOnlyCommentRequired: PropTypes.bool,
  }),
  doneClicked: PropTypes.bool,
  isTaskDone: PropTypes.bool,
  showSaveButton: PropTypes.bool,
  dataTest: PropTypes.string,
  stacked: PropTypes.bool,
};

CommentBoxV2.defaultProps = {
  commentBoxRef: null,
  completionErrors: {
    textCommentRequired: false,
    fileCommentRequired: false,
    cameraOnlyCommentRequired: false,
  },
  doneClicked: false,
  isTaskDone: false,
  conversationId: null,
  showSaveButton: true,
  dataTest: undefined,
  stacked: undefined,
};

export default CommentBoxV2;
