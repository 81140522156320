import { TaskPermissionConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

const changePermissionLevel = permission => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: TaskPermissionConstants.CHANGE_PERMISSION_LEVEL,
    permission,
  });
};

const updateAssignedUser = userId => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: TaskPermissionConstants.UPDATE_ASSIGNED_USER,
    userId,
  });
};

const updateCustomRolePermissions = permissionTypes => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: TaskPermissionConstants.UPDATE_CUSTOM_ROLE_PERMISSIONS,
    permissionTypes,
  });
};

const updateAssignedCustomRole = customRoleId => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  dispatch({
    type: TaskPermissionConstants.UPDATE_ASSIGNED_CUSTOM_ROLE,
    customRoleId,
  });
};

const updateTaskFlagging = (newFlaggableValue) => {
  const flaggingValue = newFlaggableValue ? TaskPermissionConstants.FLAG_VALUES.WITHOUT_COMMENT :
    TaskPermissionConstants.FLAG_VALUES.NOT_FLAGGABLE;

  return {
    type: TaskPermissionConstants.CHANGE_TASK_FLAGGING,
    flagging: flaggingValue,
  };
};

const updateTaskPriority = priority => ({
  type: TaskPermissionConstants.CHANGE_TASK_PRIORITY,
  priority,
});

const clearTaskAssignments = () => (dispatch) => {
  dispatch(unsavedChangesActions.touched());
  dispatch({
    type: TaskPermissionConstants.CLEAR_ASSIGNMENTS,
  });
};

export const TaskPermissionActions = {
  changePermissionLevel,
  updateAssignedUser,
  clearTaskAssignments,
  updateAssignedCustomRole,
  updateCustomRolePermissions,
  updateTaskFlagging,
  updateTaskPriority,
};
