import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Select from './Select';
import Label from './Label';
import ValidationMessages from './ValidationMessages';
import { analyzeErrors } from '../constants/validation';

import {
  CopyValueToNewLabel,
  LockedLabel,
} from './AnnotationLabels';

export const SelectField = ({
  collapsed,
  containerClass,
  disabled,
  readOnly,
  locked,
  errors,
  id,
  includeEmptyOption,
  label,
  maxLength,
  name,
  onChange,
  options,
  refFn,
  repeating,
  required,
  value,
  dataTest,
  children,
  inputChildren,
  onFocus,
  onBlur,
}) => {
  const isEmpty = value === '';
  const {
    isValid,
    anyValidationErrors,
    allValidationWarnings,
  } = analyzeErrors(errors);

  const containerClasses = classNames(
    containerClass, {
      'is-empty': disabled && isEmpty,
      'is-invalid': anyValidationErrors,
      'is-warning': allValidationWarnings,
      'is-restricted': disabled && !isEmpty,
    }
  );

  const dropdownClasses = classNames(
    'showtime-input--options',
    {
      'showtime-input--options--collapsed': disabled || collapsed,
    }
  );

  const dataTestProps = dataTest && { 'data-test': dataTest };

  return (
    <div className={ containerClasses } { ...dataTestProps }>
      <Label htmlFor={ id } required={ required }>{ label }</Label>
      <div className={ dropdownClasses }>
        <Select
          disabled={ disabled || readOnly }
          id={ id }
          maxLength={ maxLength }
          name={ name }
          onChange={ onChange }
          options={ options }
          refFn={ refFn }
          onFocus={ onFocus }
          onBlur={ onBlur }
          required={ required }
          value={ value }
          includeEmptyOption={ includeEmptyOption }
        />
        { inputChildren }
      </div>
      {!isValid
        && (
        <ValidationMessages
          fieldId={ id }
          errors={ errors }
          someErrors={ anyValidationErrors }
        />
        )}
      {locked
        && <LockedLabel />}
      {repeating && isValid
        && <CopyValueToNewLabel />}
      { children }
    </div>
  );
};

SelectField.propTypes = {
  collapsed: PropTypes.bool,
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  locked: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  id: PropTypes.string.isRequired,
  includeEmptyOption: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ).isRequired,
  refFn: PropTypes.func,
  repeating: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  dataTest: PropTypes.string,
  children: PropTypes.node,
  inputChildren: PropTypes.node,
  onFocus: PropTypes.bool,
  onBlur: PropTypes.bool,
};

SelectField.defaultProps = {
  collapsed: false,
  containerClass: '',
  disabled: false,
  readOnly: false,
  locked: false,
  includeEmptyOption: false,
  maxLength: null,
  name: null,
  refFn: () => {},
  repeating: false,
  required: false,
  value: undefined,
  dataTest: undefined,
  children: null,
  inputChildren: null,
  onFocus: undefined,
  onBlur: undefined,
};

export default SelectField;
