import { DATA_CAPTURE_ACTION_TYPES } from '../constants';

const {
  CLEAR_SKIPPED_FIELD_COMMENTS,
  SHOW_FIELD_ERRORS,
  SHOW_ROW_ERRORS,
  SET_VIEW_MODE,
  SHOW_ALL_ERRORS,
} = DATA_CAPTURE_ACTION_TYPES;

export const DataCaptureActions = {
  clearSkippedFieldComments: ({
    taskId, widgetId, rowId, skippedFields,
  }) => ({
    type: CLEAR_SKIPPED_FIELD_COMMENTS, taskId, widgetId, rowId, skippedFields,
  }),
  showFieldErrors: ({ widgetId, rowId, fieldName }) => ({
    type: SHOW_FIELD_ERRORS, widgetId, rowId, fieldName,
  }),
  showRowErrors: ({ widgetId, rowId }) => ({
    type: SHOW_ROW_ERRORS, widgetId, rowId,
  }),
  setViewMode: ({ widgetId, mode }) => ({
    type: SET_VIEW_MODE, widgetId, mode,
  }),
  showAllErrors: ({ widgetId }) => ({
    type: SHOW_ALL_ERRORS, widgetId,
  }),
};
