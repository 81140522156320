export const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
export const API_CACHE = 'trail-cache-api';
export const RUNTIME_CACHE = 'trail-cache-runtime';
export const OFFLINE_CACHE = 'trail-cache-offline';
export const LAST_CACHED_URL = 'trail/last-cached-page';
export const QUEUE_STORE = 'queued-requests';
export const SERVER_TIME_DIFF_STORE = 'server-time-diff';
export const TIMESLOTS_STORE = 'timeslots';
export const CURRENT_TIMESLOT_STORE = 'current-timeslot';
export const BUSINESS_DAY_END_STORE = 'business-end';
export const OFFLINE_INTERVAL_DURATION = 10000;
export const OFFLINE_STATES_STORE = 'offline-states';
export const OFFLINE_STATE_TASKS = 'tasks';
export const OFFLINE_STATE_SUBTASKS = 'subtasks';
export const OFFLINE_STATE_WIDGETS = 'widget_data';
export const OFFLINE_QUEUE_PROCESSING = 'offline-queue-processing';
export const OFFLINE_CHECKED_MULTIPLE_CLIENTS = 'offline-checked-multiple-clients';
export const SCRIPT_PREFIX = 'sw-';
export const LAST_CACHE_CLEAR = 'last-cache-clear';
export const RETRY_TIMEOUT_MS = 5000;
export const MESSAGES_URL = '/api/v1/conversations/messages';

export const FLOATIFICATION_QUEUE_GETTING_TRAIL_UPDATES = 'sw-getting-trail-updates';
export const FLOATIFICATION_QUEUE_UPDATED_ID = 'sw-queue-updated-id';
export const FLOATIFICATION_QUEUE_PROCESSING_ID = 'sw-queue-processing-id';
export const FLOATIFICATION_QUEUE_PROCESSED_SUCCESS_ID = 'sw-queue-processed-success-id';
export const FLOATIFICATION_QUEUE_PROCESSED_FAILED_ID = 'sw-queue-processed-failed-id';
export const FLOATIFICATION_OFFLINE_ID = 'sw-offline-id';
export const FLOATIFICATION_ONLINE_ID = 'sw-online-id';
export const FLOATIFICATION_TIMESLOT_EXPIRED_ID = 'sw-timeslot-expired';
export const FLOATIFICATION_REFRESH_ID = 'sw-refresh';

// MESSAGES
export const MESSAGE_ONLINE = 'sw-message-online';
export const MESSAGE_OFFLINE = 'sw-message-offline';
export const MESSAGE_QUEUE_PROCESSED = 'sw-message-queue-processed';
export const MESSAGE_QUEUE_PROCESSING = 'sw-message-queue-processing';
export const MESSAGE_QUEUE_PROCESSING_INITIALISED = 'sw-message-queue-processing-initialised';
export const MESSAGE_QUEUE_BEFORE_UPDATED = 'sw-message-queue-before-updated';
export const MESSAGE_QUEUE_UPDATED = 'sw-message-queue-updated';
export const MESSAGE_SET_CURRENT_TIMESLOT = 'sw-message-set-current-timeslot';
export const MESSAGE_CHECK_CURRENT_TIMESLOT = 'sw-message-check-current-timeslot';
export const MESSAGE_TIMESLOT_EXPIRED = 'sw-message-timeslot-expired';
export const MESSAGE_SET_BUSINESS_DAY_END = 'sw-message-set-business-day-end';
export const MESSAGE_CHECK_BUSINESS_DAY_END = 'sw-message-check-business-day-end';
export const MESSAGE_AFTER_BUSINESS_DAY_END = 'sw-message-after-business-day-end';
export const MESSAGE_TRIGGER_REFRESH = 'sw-message-trigger-refresh';
export const MESSAGE_CHECK_CACHE = 'sw-message-check-cache';
export const MESSAGE_CHECK_ONLINE = 'sw-message-check-online';
export const MESSAGE_CHECK_ONLINE_QUEUE = 'sw-message-check-online-queue';
export const MESSAGE_HANDLE_ONLINE = 'sw-message-handle-online';
export const MESSAGE_OFFLINE_FLOATIFICATION = 'sw-message-offline-floatification';
export const MESSAGE_INITIATE_UPDATE_TRAIL_WITH_OFFLINE_STATE = 'sw-message-initiate-update-trail-with-offline-state';
export const MESSAGE_UPDATE_TRAIL_WITH_OFFLINE_STATE = 'sw-message-update-trail-with-offline-state';
export const MESSAGE_SET_OFFLINE_STATE = 'sw-message-set-offline-state';
export const MESSAGE_INITIATE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE = 'sw-message-initiate-update-widget-store-with-offline-state';
export const MESSAGE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE = 'sw-message-update-widget-store-with-offline-state';
export const MESSAGE_POST_METRIC = 'sw-message-post-metric';
export const MESSAGE_CHECK_MULTIPLE_CLIENTS = 'sw-message-check-multiple-clients';
export const MESSAGE_SET_CHECKED_MULTIPLE_CLIENTS = 'sw-message-set-checked-multiple-clients';
export const MESSAGE_DEVICE_BECAME_VISIBLE = 'sw-message-device-became-visible';
export const MESSAGE_FETCH_ALL_WIDGETS = 'sw-message-fetch-all-widgets';

export const CACHE_BLACKLIST = [
  'mini-profiler-resources',
  'sockjs-node',
  'fast.fonts.net',
  'fonts.googleapis.com',
  'fonts.gstatic.com',
];
