import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';

const positive = number => number >= 0 ? number : -number;

export const DateDifference = ({
  toDate, fromDate, intl: { formatMessage },
}) => {
  const isLessThanADayDifferent = !moment(fromDate).diff(toDate, 'days');
  const hoursDifference = moment(fromDate).diff(toDate, 'hours');
  const isAnHourToADayDifferent = hoursDifference && isLessThanADayDifferent;
  const minutesDifference = moment(fromDate).diff(toDate, 'minutes') - (hoursDifference * 60);

  if (moment(fromDate).isSame(toDate, 'minute')) return null;

  const isEarly = hoursDifference > 0 || minutesDifference > 0;
  if (isAnHourToADayDifferent) {
    return formatMessage(
      { id: 'common.dateDifference.relativeHoursAndMinutes' },
      { hours: positive(hoursDifference), minutes: positive(minutesDifference), isEarly });
  }

  const relativeTime = moment(fromDate).to(moment(toDate), true);
  return formatMessage(
    { id: 'common.dateDifference.relativeTime' },
    { relativeTime, isEarly, over: !isLessThanADayDifferent });
};

DateDifference.propTypes = {
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
};

export default injectIntl(DateDifference);
