import _ from 'lodash';
import { TaskTrailsSelectorConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskTrailsSelectorActions = {
  setAllLocations(locations) {
    return {
      type: TaskTrailsSelectorConstants.SET_ALL_LOCATIONS,
      allLocations: locations.map(location => _.pick(location, ['id', 'name'])),
    };
  },

  expand(selectedLocations) {
    return {
      type: TaskTrailsSelectorConstants.EXPAND,
      content: {
        value: selectedLocations,
        isEmpty: !selectedLocations || !selectedLocations.length,
      },
    };
  },

  selectAllTrails: () => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTrailsSelectorConstants.SELECT_ALL_TRAILS,
    });
  },

  deselectAllTrails: () => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTrailsSelectorConstants.DESELECT_ALL_TRAILS,
    });
  },

  change: (selectedLocation, areLocationsSelected) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskTrailsSelectorConstants.CHANGE,
      content: {
        value: selectedLocation,
        areLocationsSelected,
      },
    });
  },
};
