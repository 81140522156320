import _ from 'lodash';
import moment from 'moment-timezone';

import { CommentBoxConstants } from '../constants';
import { TrailActions } from '../../trail/actions';
import { RequestConstants, RequestActions } from '../../request';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

const recordCommentSentMetric = (taskId, { attachment } = {}) => metricsPublisher.recordMetric(
  TrailMetricsDirectory.page.Conversation.COMMENT_SENT,
  { taskId, ...attachment && { attachmentType: attachment.mimeType } }
);

const dispatchCommentToFlux = (
  content, userName, userInitials, taskId, temporaryId, attachment,
  { messageId = null, timestamp = moment.utc().toString() } = {}
) => TrailActions.messageReceived({
  userId: window.current_user.id,
  content,
  userName,
  userInitials,
  taskId,
  attachment,
  temporaryId,
  messageId,
  timestamp,
});

const send = (
  content, userName, userInitials, taskId, conversationId, attachment,
  { temporaryId } = {}
) => {
  recordCommentSentMetric(taskId, { attachment });

  return RequestActions.request({
    url: '/api/v1/conversations/messages',
    key: CommentBoxConstants.SEND,
    method: RequestConstants.POST,
    errorMessage: 'conversation.commentSendingError',
    content: {
      temporaryId,
      content,
      userName,
      userId: window.current_user.id,
      userInitials,
      taskId,
      attachment,
    },
    options: {
      body: JSON.stringify({
        content,
        temporaryId,
        task_id: taskId,
        conversation_id: conversationId,
        attachment,
      }),
    },
  });
};

const dispatchRemoveCommentToFlux = (taskId, messageId) => TrailActions.messageDeleted({
  taskId,
  messageId,
});

const deleteMessage = messageId => RequestActions.request({
  url: `/api/v1/conversations/messages/${messageId}`,
  key: CommentBoxConstants.DELETE,
  method: RequestConstants.DELETE,
  errorMessage: 'conversation.commentDeletingError',
  content: { messageId },
});

const requestDeleteMessage = ({ taskId, messageId }) => async (dispatch) => {
  const deleteRequest = await dispatch(deleteMessage(messageId));

  if (deleteRequest.success) {
    return dispatchRemoveCommentToFlux(
      taskId, messageId
    );
  }
  return Promise.resolve();
};

const sendCommentWithAttachment = (
  message, userName, userInitials, taskId, conversationId, attachment, temporaryId
) => async (dispatch) => {
  const sendRequest = await dispatch(send(
    message, userName, userInitials, taskId, conversationId, attachment, { temporaryId }
  ));

  if (sendRequest.success) {
    const { body: { data: { id: messageId, attributes: { timestamp } } } } = sendRequest.success;

    dispatchCommentToFlux(
      message, userName, userInitials, taskId, temporaryId, attachment, { messageId, timestamp }
    );
  }
  return Promise.resolve(sendRequest);
};

const requestSend = ({
  userName, userInitials,
  taskId, conversationId,
  uploadedAttachment, message,
}) => (dispatch) => {
  const temporaryId = _.uniqueId();

  dispatchCommentToFlux(
    message,
    userName,
    userInitials,
    taskId,
    temporaryId,
    uploadedAttachment,
    { messageId: temporaryId }
  );

  return dispatch(sendCommentWithAttachment(
    message, userName, userInitials, taskId, conversationId, uploadedAttachment, temporaryId
  ));
};

export const CommentBoxActions = {
  change(content, taskId) {
    return {
      type: CommentBoxConstants.CHANGE,
      content,
      taskId,
    };
  },
  send,
  deleteMessage,
  requestDeleteMessage,
  requestSend,
};
