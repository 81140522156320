import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVGIcon from './SVGIcon';

class Label extends Component {
  _getLabelText() {
    let text = null;

    if (this.props.text) {
      text = (
        <span key='text' className='showtime-label__text'>
          {this.props.text}
        </span>
      );
    }

    return text;
  }

  _getSecondaryText() {
    let secondaryText = null;

    if (this.props.secondaryText) {
      secondaryText = (
        <span key='secondaryText' className='showtime-label__secondary-text'> {this.props.secondaryText}</span>
      );
    }

    return secondaryText;
  }

  render() {
    const labelClasses = `showtime-label ${this.props.labelClasses}`;
    const { iconName } = this.props;

    const innerElements = [
      iconName && <SVGIcon classes='showtime-label__icon' iconName={ iconName } key='icon' />,
      this._getLabelText(),
      this._getSecondaryText(),
    ];

    let element = null;

    if (this.props.htmlFor) {
      element = (
        <label htmlFor={ this.props.htmlFor } className={ labelClasses }>
          {innerElements}
        </label>
      );
    } else {
      element = (
        <p className={ labelClasses }>
          {innerElements}
        </p>
      );
    }

    return element;
  }
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  labelClasses: PropTypes.string,
  text: PropTypes.string,
  secondaryText: PropTypes.string,
  iconName: PropTypes.string,
};

Label.defaultProps = {
  labelClasses: '',
  htmlFor: undefined,
  text: undefined,
  secondaryText: undefined,
  iconName: undefined,
};

export default Label;

