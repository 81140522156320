import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { WidgetRegistry } from '../widget/widgetRegistry';
import SubTaskList from './SubTaskList';
import WidgetPreviewFallback from '../widget/WidgetPreviewFallback';
import { getCurrentUserRole } from '../application/UserRole';
import Attachment from '../attachments/Attachment';
import Comment from '../conversation/Comment';
import OfflineInteractionBlocker from '../offline-interaction-blocker';
import CommentBoxV2 from '../conversation/CommentBoxV2';
import { TrailConstants } from './constants';

class TaskBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompletedSubTaskItems: this.props.showCompletedSubTaskItems || false,
    };
  }

  renderNotesSection() {
    let notesSection = null;

    if (this.props.task.notes) {
      notesSection = (
        <div className='showtime-task__body-item showtime-task__body-item--description'>
          <div data-test='trail.task.body.description' className='showtime-task__help-text'>
            <ReactMarkdown
              ref='description'
              source={ this.props.task.notes }
              softBreak='br'
              className='showtime-markdown-task'
              skipHtml
            />
          </div>
        </div>
      );
    }

    return notesSection;
  }

  toggleCompletedSubTaskItems = () => {
    this.setState(({ showCompletedSubTaskItems }) => ({
      showCompletedSubTaskItems: !showCompletedSubTaskItems,
    }));
  };

  renderSubtasksSection() {
    let subtasksSection = null;
    const hasSubtasks = this.props.subtasks.length > 0;
    const siteLevelSubtasksEnabled = _.get(window.config, 'features.siteLevelSubtasks', false);
    const subtaskValuesEnabled = _.get(window.config, 'features.subtaskValues', false);

    if (hasSubtasks) {
      subtasksSection = (
        <div className='showtime-task__body-item showtime-task__body-item--checklist'>
          <SubTaskList
            data-test='trail.task.body.subtask.list'
            task_id={ this.props.task.id }
            status={ this.props.task.status }
            subtasks={ this.props.subtasks }
            currentUserIsTeamRole={ getCurrentUserRole().isEnduser() }
            taskIsLocked={ this.props.task.isLocked }
            canShowFlags={ subtaskValuesEnabled }
            showAddSubtaskInput={ siteLevelSubtasksEnabled }
            showCompletedItems={ this.props.readOnly || this.state.showCompletedSubTaskItems }
            toggleCompletedItemsHandler={ this.toggleCompletedSubTaskItems }
            taskHeaderHeight={ this.props.taskHeaderHeight }
            readOnlyComments={ this.props.readOnly }
          />
        </div>
      );
    }

    return subtasksSection;
  }

  renderAttachmentsSection() {
    const { router, task: { contents } } = this.props;

    return (contents && contents.length > 0) && (
      <OfflineInteractionBlocker
        tooltipMessage={ this.props.intl.formatMessage({ id: 'offline.notAvailable' }) }
      >
        <div
          className='showtime-task__body-item showtime-task__body-item--attachments'
          data-test='trail.task.body.attachments'
        >
          <div className='showtime-task__attachments'>
            {contents.map((attachmentContent) => {
              const {
                filestack_handle: handle,
                id,
                type,
                name: title,
                attachment_content_type: mimeType,
                url,
              } = attachmentContent;
              return (
                <div className='showtime-task__attachment' key={ id + title }>
                  <Attachment
                    data-test={ `trail.task.body.attachment.${id}` }
                    subtitle={ this.getAttachmentSubtitle(attachmentContent) }
                    isClickable={ !this.props.preview }
                    { ...{
                      type,
                      mimeType,
                      title,
                      handle,
                      router,
                      url,
                      id,
                    } }
                    openInNewTab={ this.shouldOpenInNewTab(attachmentContent) }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </OfflineInteractionBlocker>
    );
  }

  getAttachmentSubtitle(attachmentContent) {
    let subtitle = null;

    if (attachmentContent.type === 'link' &&
      attachmentContent.link_type !== 'youtube') {
      subtitle = attachmentContent.url;
    }

    return subtitle;
  }

  shouldOpenInNewTab(attachmentContent) {
    return attachmentContent.type === 'link' &&
      attachmentContent.link_type !== 'youtube';
  }

  renderWidgetPreviewFallback() {
    return (<WidgetPreviewFallback widgetName={ this.props.widgetData.name } />);
  }

  renderWidget(WidgetComponent) {
    return (
      <WidgetComponent
        actions={ this.props.task.actions }
        id={ this.props.task.widget_data_id }
        taskId={ this.props.task.id }
        readOnly={ this.props.readOnly }
        taskName={ this.props.task.name }
        location_name={ this.props.task.location_name }
        widgetData={ this.props.widgetData }
        preview={ this.props.preview }
        taskStatus={ this.props.task.status }
        router={ this.props.router }
        taskHeaderHeight={ this.props.taskHeaderHeight }
      />
    );
  }

  renderWidgetSection() {
    const widgetType = this.props.task.integration;
    const widgetComponent = WidgetRegistry.getComponentForType(widgetType);
    let widget = null;
    const widgetContainerClasses = classNames(
      'showtime-task__body-item',
      'showtime-task__body-item--widget',
      'showtime-task__body-item--widget-v2'
    );

    if (!widgetComponent) {
      return null;
    }

    if (this.props.preview) {
      if (WidgetRegistry.widgetReadyForPreview(widgetType)) {
        widget = this.renderWidget(widgetComponent);
      } else {
        widget = this.renderWidgetPreviewFallback();
      }
    } else if (this.props.task.widget_data_id) {
      widget = this.renderWidget(widgetComponent);
    }

    return widget ? (
      <div className={ widgetContainerClasses }>
        <div data-test='widgetContainer' className='showtime-task__widget'>
          { widget }
        </div>
      </div>
    ) : null;
  }

  renderConversationTitle() {
    if (!this.props.task.messages) {
      return null;
    }

    const { messageCount } = this.props.task;
    const classesTitle = classNames({
      'showtime-task__conversation-inline-title': true,
    });

    return (
      <h4 data-test='conversationTitle' className={ classesTitle }>
        {
          messageCount === 1 ?
            this.props.intl.formatMessage({ id: 'trail.task.commentListOne' }) :
            this.props.intl.formatMessage({ id: 'trail.task.commentListMultiple' }, { messageCount })
        }
      </h4>
    );
  }

  renderConversationMessages() {
    if (!this.props.task.messages) {
      return null;
    }

    const commentsInAscendingOrder = [...this.props.task.messages]
      .sort((a, b) => (moment(a.timestamp).diff(b.timestamp)));

    const isDone = this.props.task.status === TrailConstants.taskStatus.DONE;

    return (
      <div data-test='commentList'>
        {
          commentsInAscendingOrder.map((comment, i) => (
            <Comment
              data-test={ `comment.${i}` }
              key={ comment.timestamp }
              { ...comment }
              disableOptions={ isDone }
              taskId={ this.props.task.id }
              router={ this.props.router }
              canDelete
            />
          ))
        }
      </div>
    );
  }

  renderConversation() {
    const {
      userName,
      userInitials,
      task: { id: taskId, messages, conversation_id: conversationId },
      completionErrors,
      commentBoxRef,
      doneClicked,
    } = this.props;

    const taskHasConversation = messages && messages.length > 0;

    return (
      <div className='showtime-task__body-item showtime-task__body-item--conversation'>
        <div className='showtime-task__conversation'>
          { taskHasConversation && this.renderConversationTitle() }
          { taskHasConversation && this.renderConversationMessages() }
          { this.props.allowComments && (
          <CommentBoxV2
            { ...{
              userInitials,
              userName,
              taskId,
              conversationId,
              commentBoxRef,
              doneClicked,
              completionErrors,
              dataTest: 'commentbox.task',
              isTaskDone: this.props.task.status === TrailConstants.taskStatus.DONE,
            } }
          />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className='showtime-task__body' id={ `task-body-${this.props.task.id}` }>
        {this.renderNotesSection()}
        {this.renderSubtasksSection()}
        {this.renderAttachmentsSection()}
        {this.renderWidgetSection()}
        {this.renderConversation()}
      </div>
    );
  }
}

TaskBody.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    contents: PropTypes.array,
    integration: PropTypes.string,
    location_name: PropTypes.string,
    widget_data_id: PropTypes.number,
    notes: PropTypes.string,
    status: PropTypes.string,
    isLocked: PropTypes.bool,
    messages: PropTypes.array,
    messageCount: PropTypes.number,
    conversation_id: PropTypes.string,
    actions: PropTypes.array,
  }).isRequired,
  readOnly: PropTypes.bool,
  preview: PropTypes.bool,
  subtasks: PropTypes.array,
  widgetData: PropTypes.object,
  showCompletedSubTaskItems: PropTypes.bool,
  onCommentsClick: PropTypes.func,
  date: PropTypes.string,
  router: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userInitials: PropTypes.string.isRequired,
  commentBoxRef: PropTypes.shape({ current: PropTypes.any }),
  completionErrors: PropTypes.object,
  doneClicked: PropTypes.bool,
  allowComments: PropTypes.bool,
  taskHeaderHeight: PropTypes.number,
};

TaskBody.defaultProps = {
  readOnly: false,
  preview: false,
  widgetData: null,
  date: undefined,
  showCompletedSubTaskItems: false,
  onCommentsClick: _.noop,
  subtasks: [],
  commentBoxRef: null,
  completionErrors: {},
  doneClicked: false,
  allowComments: true,
  taskHeaderHeight: 0,
};

export default injectIntl(TaskBody);
