import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import { isLoaded } from '../../common/requestStatuses';
import { TaskReportsSelectors } from '../selectors';
import { filterQueryKeys, filterTypes } from '../constants';

const queryKey = filterQueryKeys[filterTypes.TASKS];

const TASKS = 'tasks';
const ALL = 'all';
const HIGH_PRIORITY = 'high';
const LOW_PRIORITY = 'low';
const PRIORITY = 'priority';
const TasksFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });
  const items = useSelector(TaskReportsSelectors.taskTemplatesForDropdownSelector);
  const hasLoaded = isLoaded(
    useSelector(TaskReportsSelectors.taskTemplatesRequestStatusSelector));

  const { formatMessage } = useIntl();

  const highPriorityItem = { id: HIGH_PRIORITY, name: formatMessage({ id: 'scores.filters.taskFilter.highPriority' }) };
  const lowPriorityItem = { id: LOW_PRIORITY, name: formatMessage({ id: 'scores.filters.taskFilter.lowPriority' }) };
  const priorityItems = [];
  const hasHighPriorityItems = items.some(item => item.priority === HIGH_PRIORITY);
  if (hasHighPriorityItems) priorityItems.push(highPriorityItem);
  const hasLowPriorityItems = items.some(item => item.priority === LOW_PRIORITY);
  if (hasLowPriorityItems) priorityItems.push(lowPriorityItem);

  const taskGroups = [];
  if ((hasHighPriorityItems || hasLowPriorityItems) &&
  (window.config.features.taskPriority && priorityItems.length > 0)) {
    taskGroups.push({
      items: priorityItems,
      id: PRIORITY,
      parentId: ALL,
    });
  }

  taskGroups.push(
    {
      items: items.map(task => ({
        ...task,
        parentId: task.priority,
      })),
      id: TASKS,
      parentId: PRIORITY,
    });

  return (
    <Filter
      { ...props }
      { ...{ queryIds, setQueryIds, clearQueryIds } }
      hasLoaded={ hasLoaded }
      filterType={ filterTypes.TASKS }
      selectAll
      groups={ taskGroups }
      selectFromGroupId={ TASKS }
    />
  );
};

TasksFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default TasksFilter;
