import React from 'react';
import PropTypes from 'prop-types';

import withPureComponent from '../withPureComponent';

export const NUMBER = 'number';
export const TEL = 'tel';
export const TEXT = 'text';
export const EMAIL = 'email';

export const Input = ({
  dataTest,
  disabled,
  readOnly,
  id,
  inputMode,
  max,
  minLength,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onClick,
  onKeyDown,
  onCopy,
  placeholder,
  refFn,
  required,
  type,
  value,
  autoComplete,
  pattern,
}) => (
  <input
    readOnly={ readOnly }
    data-test={ dataTest }
    disabled={ disabled }
    id={ id }
    max={ max }
    minLength={ minLength }
    maxLength={ maxLength }
    min={ min }
    name={ name }
    onBlur={ ({ target: { value: newValue } }) => onBlur(newValue) }
    onChange={ ({ target: { value: newValue, validity } }) => onChange(newValue, validity) }
    onClick={ onClick }
    onKeyDown={ onKeyDown }
    placeholder={ placeholder }
    ref={ refFn }
    required={ required }
    type={ type }
    value={ value }
    autoComplete={ autoComplete }
    onCopy={ onCopy }
    inputMode={ inputMode }
    pattern={ pattern }
  />
);

Input.propTypes = {
  dataTest: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string.isRequired,
  max: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onCopy: PropTypes.func,
  placeholder: PropTypes.string,
  refFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  required: PropTypes.bool,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  type: PropTypes.oneOf([
    NUMBER,
    TEL,
    TEXT,
    EMAIL,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Input.defaultProps = {
  dataTest: null,
  autoComplete: null,
  disabled: false,
  readOnly: false,
  max: null,
  minLength: null,
  maxLength: null,
  min: null,
  name: null,
  onBlur: () => { },
  onChange: () => { },
  onClick: () => { },
  onCopy: null,
  onKeyDown: null,
  placeholder: null,
  refFn: null,
  required: false,
  inputMode: null,
  pattern: null,
  type: TEXT,
  value: undefined,
};

export default withPureComponent(Input);
