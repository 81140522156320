import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import classNames from 'classnames';

import withPureComponent from '../../../../common/withPureComponent';
import ToolTip from '../../../../common/ToolTip';

const HeaderCell = ({
  showTooltip, disabled, emphasis, tooltipClassName, children, intl,
}) => (
  <th
    className={
      classNames('showtime-table__header-cell', { 'showtime-table__header-cell--emphasis': emphasis })
    }
  >
    { children }
    { showTooltip &&
      (<ToolTip
        classes={ ['showtime-widget-v2__tooltip', 'hint--medium', tooltipClassName] }
        message={ disabled
          ? intl.formatMessage({ id: 'trail.widgets.data_capture.locked_field' })
          : intl.formatMessage({ id: 'trail.widgets.data_capture.changeable_field' })
        }
        iconName={ disabled ? 'mini/ui/ui-16px-2_ban' : 'mini/arrows/arrows-16px-e_refresh-20' }
        iconClasses={ ['showtime-icon--size-14'] }
        key='colSettings'
      />)
    }
  </th>
);

HeaderCell.propTypes = {
  showTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
  emphasis: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  children: PropTypes.node,
};

HeaderCell.defaultProps = {
  showTooltip: false,
  disabled: false,
  emphasis: false,
  tooltipClassName: '',
  children: null,
};

export default compose(
  injectIntl,
  withPureComponent
)(HeaderCell);
