export const rangeValidator = (min, max, warningText) => {
  const isEmpty = val => val === undefined || val === null || val.length === 0;
  const isEmptyOrInvalid = val => isEmpty(val) || Number.isNaN(val);
  const isMaxLessThanMin = () => max <= min;

  const allFieldsEmpty = [min, max, warningText].every(value => isEmpty(value));
  const validates = (val, validationFn) => allFieldsEmpty || !validationFn(val);

  return [
    [min, isEmptyOrInvalid, 'minValueWarningLimit'],
    [max, isEmptyOrInvalid, 'maxValueWarningLimit'],
    [null, isMaxLessThanMin, 'isMaxLessThanMin'],
    [warningText, isEmpty, 'outOfRangeWarningText'],
  ].reduce((acc, valAndFn) => {
    if (!validates(valAndFn[0], valAndFn[1])) acc.push(valAndFn[2]);
    return acc;
  }, []);
};
