import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getFilestackPickSignature } from './actions';
import { getFilePickerPolicy } from './selectors';
import { isExpired } from './securityValidation';
import { filestackClient } from './filestackApi';

const useFileUpload = ({
  multiUpload,
  onUploadDone,
  onFileUploadFailed,
  onCancel,
  onProgress,
}) => {
  const { config: { filestackApiKey } } = window;
  const { expiry, signature, policy } = useSelector(getFilePickerPolicy);
  const dispatch = useDispatch();
  const pickPolicyRequest = useMemo(() => {
    const shouldFetch = filestackApiKey && isExpired(expiry);
    if (shouldFetch) {
      return dispatch(getFilestackPickSignature());
    }
    return Promise.resolve();
  }, [expiry]);

  const requestClient = async () => {
    await pickPolicyRequest;

    return Promise.resolve(filestackClient({
      security: {
        signature,
        policy,
        expiry,
      },
      onUploadDone,
      onFileUploadFailed,
      onCancel,
      onProgress,
    }));
  };

  const onPickerOpen = async () => {
    const client = await requestClient();
    return client.open(multiUpload);
  };

  const onCapture = async (file) => {
    const client = await requestClient();
    client.capture(file);
  };

  return { onPickerOpen, onCapture, isFilestackAvailable: Boolean(signature) };
};

export default useFileUpload;
