import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WidgetDataConstants } from '../../trail/constants';
import { WidgetDataStore } from '../../trail/store';

export const widgetDataListener = widgetIdFromProps => WidgetComponent =>
  class WidgetDataListenerWrapper extends Component {
    constructor(props) {
      super(props);
      this.wrapped = null;
      WidgetComponent.displayName = `WithWidgetDataListener(${WidgetComponent.displayName || WidgetComponent.name})`;
    }

    static propTypes = {
      id: PropTypes.number,
    };

    static defaultProps = {
      id: null,
    };

    state = {
      widgetReceivedUpdate: Symbol(''),
    };

    componentDidMount() {
      WidgetDataStore.on(WidgetDataConstants.WIDGET_UPDATED, this.onChange);
    }

    componentWillUnmount() {
      WidgetDataStore.removeListener(WidgetDataConstants.WIDGET_UPDATED, this.onChange);
    }

    onChange = (changedWidgetId) => {
      const id = widgetIdFromProps ? widgetIdFromProps(this.props) : this.props.id;
      if (changedWidgetId === id) {
        this.setState({ widgetReceivedUpdate: Symbol('') });
      }
    }

    render() {
      return (
        <WidgetComponent
          { ...this.props }
          forceUpdateFromWidgetDataListener={ this.state.widgetReceivedUpdate }
        />
      );
    }
  };

const dataListenerHoC = widgetDataListener();
export default dataListenerHoC;
