import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { EditWidgetActions } from '../../../actions';
import { TaskWidgetSelectors } from '../../../../task-wizard/selectors';
import ToolTip from '../../../../common/ToolTip';

const {
  columnsLengthSelector,
  columnLogicLengthSelector,
  defaultJumpLogicForColumnSelector,
} = TaskWidgetSelectors;

const AddLogicJumpButton = ({ columnIndex }) => {
  const { formatMessage } = useIntl();

  const logicLength = useSelector(state => (
    columnLogicLengthSelector(state, { columnIndex })));
  const defaultJumpLogic = useSelector(state => (
    defaultJumpLogicForColumnSelector(state, { columnIndex })));
  const columnLength = useSelector(columnsLengthSelector);
  const disabled = columnLength === (columnIndex + 1);

  if (logicLength) return null;

  const dispatch = useDispatch();
  const logicPath = `columns[${columnIndex}].cellSettings.logic`;
  const addLogicJump = () => dispatch(
    EditWidgetActions.setWidgetPublicSettings(logicPath, [defaultJumpLogic]));

  return (
    <div className='showtime-record-log-builder-field__config-links'>
      <div
        className={
          classNames(
            'showtime-record-log-builder-field__config-link',
            'showtime-record-log-builder-field__config-link--border-bottom',
            { 'showtime-record-log-builder-field__config-link--disabled': disabled }
          )
        }
      >
        <a
          className={
            classNames('showtime-link showtime-link--small',
              {
                'showtime-link--highlight': !disabled,
                'showtime-link--disabled': disabled,
              })
          }
          onClick={ addLogicJump }
          data-test='logic.add'
        >
          { formatMessage({ id: 'editWidget.builder.optionsColumn.addLogicJump' }) }
        </a>
        { disabled && (
          <ToolTip
            message={ formatMessage({ id: 'editWidget.builder.optionsColumn.addLogicJumpDisabled' }) }
            classes={ ['hint--info', 'hint--bottom-right'] }
          />
        )}
      </div>
    </div>
  );
};

AddLogicJumpButton.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default AddLogicJumpButton;
