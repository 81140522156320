import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { UserRole, getCurrentUserRole } from '../../../../application/UserRole';
import Button from '../../../../common/Button';
import { VALIDATION_ERROR } from '../../../../common/constants/validation';
import { columnPropTypes } from '../constants';
import { formatCellData } from '../formatters/cellDataFormatter';
import NavItem from './NavItem';

const Nav = ({
  activeRowId,
  addEmptyRow,
  classNamePrefix,
  errors,
  firstFieldKey,
  firstRowName,
  listId,
  restrictRows,
  rows,
  columns,
  setActiveRowId,
  intl,
}) => {
  const addButtonClasses = classNames(
    'showtime-button--small',
    'showtime-button--lowlight-alt',
    'showtime-button--collapse-text',
    'showtime-button--block-left',
  );
  const CurrentUserRole = getCurrentUserRole();
  const isPermittedOrUnrestricted = (
    CurrentUserRole.isManagerOrAbove() &&
    !CurrentUserRole.isPermissionDisabled(UserRole.permissionTypes.MANAGE_RESTRICTED_WIDGET_ROWS)
  ) || !restrictRows;
  const rowHasErrors = errorsForRow => Object.values(errorsForRow)
    .some(fieldErrors => fieldErrors.some(fieldError => fieldError.type === VALIDATION_ERROR));

  const firstColumn = columns[0] || {};
  const { cellSettings: firstCellSettings = {}, cellType: firstCellType } = firstColumn;

  return (
    <nav
      aria-controls={ listId }
      className={ `${classNamePrefix}-nav` }
    >
      <ul className={ `${classNamePrefix}-nav-items` }>
        {rows.map((row, index) => {
          const errorsForRow = errors[rows[index].rowId] || {};
          const isInvalid = rowHasErrors(errorsForRow);
          const allFieldsHaveValue = Object.keys(errorsForRow).every(errorKey =>
            row[errorKey] !== undefined);

          const firstCellData = row[firstFieldKey] &&
            formatCellData(row[firstFieldKey], firstCellType, firstCellSettings);

          const itemLabel = firstCellData ||
            firstRowName ||
            intl.formatMessage({ id: 'trail.widgets.data_capture.default_blank_label' });

          return (
            <NavItem
              key={ `nav-item--${row.rowId}` }
              classNamePrefix={ classNamePrefix }
              id={ row.rowId }
              onClick={ setActiveRowId }
              isActive={ row.rowId === activeRowId }
              isValid={ !isInvalid && allFieldsHaveValue }
              isInvalid={ isInvalid }
              counts={ `${index + 1}` }
            >
              { itemLabel }
            </NavItem>
          );
        }) }
      </ul>

      {isPermittedOrUnrestricted &&
        <Button
          onClick={ addEmptyRow }
          buttonClasses={ addButtonClasses }
          iconName='mini/ui/ui-16px-1_simple-add'
          label={ intl.formatMessage({ id: 'trail.widgets.data_capture.add_record' }) }
          dataTest='add.record'
        />
      }
    </nav>
  );
};

Nav.propTypes = {
  ...columnPropTypes,
  activeRowId: PropTypes.number.isRequired,
  addEmptyRow: PropTypes.func.isRequired,
  classNamePrefix: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  firstFieldKey: PropTypes.string.isRequired,
  firstRowName: PropTypes.string,
  listId: PropTypes.string.isRequired,
  restrictRows: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  setActiveRowId: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

Nav.defaultProps = {
  firstRowName: '',
  restrictRows: false,
};

export default injectIntl(Nav);
