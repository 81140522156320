import EventEmitter from 'wolfy87-eventemitter';
import moment from 'moment-timezone';
import _ from 'lodash';

import random from '../../common/random';
import { TrailActions } from '../actions';
import { TrailConstants } from '../constants';
import { TaskTemplateConstants } from '../../task/constants';
import AppDispatcher from '../../application/dispatcher';
import DataReloadWatchdog from './dataReloadWatchdog';
import TaskScheduleConstants from '../../schedule/constants';
import WindowHelper from '../../utils/window';
import hasServiceWorker from '../../utils/service-workers/helpers/hasServiceWorker';
import { isFlagged } from '../taskStatusUtilities';
import {
  MESSAGE_SET_CURRENT_TIMESLOT,
  MESSAGE_SET_BUSINESS_DAY_END,
} from '../../service-workers/constants';
import safePostMessage from '../../utils/service-workers/helpers/safePostMessage';

const {
  taskStatus: {
    MISSED,
    CURRENT,
    OVERDUE,
    DONE,
    LATER,
    ONDEMAND,
    FLAGGED,
  },
} = TrailConstants;

const taskStatusToTrailSection = {
  [MISSED]: TrailConstants.section.NOW,
  [OVERDUE]: TrailConstants.section.NOW,
  [ONDEMAND]: TrailConstants.section.NOW,
  [LATER]: TrailConstants.section.LATER_TODAY,
  [DONE]: TrailConstants.section.DONE,
};

const TrailStore = function TrailStore() {
  if (TrailStore.instance) {
    return TrailStore.instance;
  }

  const ONE_SECOND = 1000;
  const ONE_HOUR = 1000 * 60 * 60;
  const FIVE_MINUTES = 5 * 60 * 1000;
  const ACCEPTABLE_SLEEP_DURATION_MS = 4 * ONE_HOUR;
  const ACCEPTABLE_APP_AGE_MS = 12 * ONE_HOUR;

  this.maxTimeout = window.prefetch.trailRefreshSpreadInMilliseconds || FIVE_MINUTES;
  this._tasks = [];
  this._timeslots = [];
  this._currentTimeslotId = null;
  this._locationId = window.prefetch.location_id;
  this._locationActiveDate = [];
  this._locationArchivedAt = null;
  this._locationStatus = [];
  this._locationClosed = null;
  this._locationName = null;
  this._timelineHasTasks = null;
  this._date = moment(window.prefetch.date);
  this._serverDifference = 0;
  this._dataReloadWatchdog =
  new DataReloadWatchdog(ACCEPTABLE_SLEEP_DURATION_MS, ACCEPTABLE_APP_AGE_MS);
  this._isFetchingTrail = false;
  this._tags = [];
  this._businessDayEnd = null;

  TrailStore.refreshToken = setInterval(this.onIntervalTick.bind(this), ONE_SECOND);

  TrailStore.dispatchToken =
    AppDispatcher.register(this._onActionDispatched.bind(this));
  TrailStore.instance = this;
};

_.extend(TrailStore.prototype, EventEmitter.prototype, {
  _tasks: [],
  _timeslots: [],
  _locationActiveDate: [],
  _locationStatus: [],
  _currentTimeslotId: null,
  _date: moment(window.prefetch.date),
  _serverDifference: 0,
  _dataReloadWatchdog: null,
  _isFetchingTrail: false,
  _businessDayEnd: null,

  _onActionDispatched(payload) {
    switch (payload.actionType) {
      case TrailConstants.TRAIL_LOADING:
        this.emitTrailLoading();
        break;
      case TrailConstants.UPDATE_TRAIL:
        if (!this._isFetchingTrail) {
          this.setServerTime(payload.jsonData.serverTime);
          this.setDate(payload.date, payload.datePeriod);
        }
        this.setLocationActiveDate(payload.jsonData.location.active_date);
        this.setLocationArchivedAt(payload.jsonData.location.archived_at);
        this.setLocationClosed(payload.jsonData.locationClosed);
        this.setTimelineHasTasks(payload.jsonData.timelineHasTasks);
        this.setLocationName(payload.jsonData.location.name);
        this.setLocationStatus(payload.jsonData.location.status);
        this.setTasks(payload.jsonData.tasks);
        this.setTimeslots(payload.jsonData.timeslots);
        this.emitTrailUpdated();
        this._isFetchingTrail = false;
        break;
      case TrailConstants.TRAIL_UPDATE_FAILED:
        this._isFetchingTrail = false;
        break;
      case TrailConstants.UPDATE_TASKS:
        this.updateTasks(payload.tasks);
        this.emitTrailUpdated();
        break;
      case TrailConstants.MARK_COMPLETED:
      case TrailConstants.MARK_UNCOMPLETED:
        this.updateTasks([payload.task]);
        this.actionTaskCompletedAtUpdated(payload.task);
        this.emitTrailUpdated();
        break;
      case TrailConstants.MOVE_TASK_TO_COMPLETED:
        this.emitCompletionStarted(payload.task.id);
        break;
      case TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED:
        this.emitCompletionFailed(payload.task.id);
        break;
      case TrailConstants.DELETE_ACTION_TASK:
        this.deleteAction(payload);
        break;
      case TrailConstants.DELETE_ACTION_TASKS:
        this.deleteActions(payload);
        break;
      case TrailConstants.TASK_DELETED:
        this.deleteTask(payload.task);
        this.emitTrailUpdated();
        break;
      case TrailConstants.MOVE_TASK_FROM_COMPLETED:
        this.emitTaskRemovalStarted(payload.task);
        break;
      case TrailConstants.MOVE_TASK_FROM_COMPLETED_FAILED:
        this.emitTaskRemovalFailed(payload.task);
        break;
      case TrailConstants.MESSAGE_RECEIVED:
        this.updateTaskWithMessage(payload);
        break;
      case TrailConstants.MESSAGE_DELETED:
        this.deleteTaskMessage(payload);
        break;
      case TrailConstants.tags.FILTER:
        this.filterTrailByTagId(payload);
        break;
      case TrailConstants.tags.FILTER_TASKS_WITH_NO_TAGS:
        this.filterTrailByTasksWithoutTags();
        break;
      case TrailConstants.SET_TAGS:
        this.setTags(payload.tags);
        break;
      case TrailConstants.TASK_SNOOZED:
        this.updateTasks([payload.task]);
        this.emitTrailUpdated();
        break;
      case TrailConstants.ADD_CREATED_TASK_ACTION:
        this.addTaskAction(payload);
        this.emitTrailUpdated();
        break;
      case TrailConstants.UPDATE_TASK_ACTION:
        this.updateTaskAction(payload);
        this.emitTrailUpdated();
        break;
      case TrailConstants.ADD_CREATED_TIMELINE_TASK:
        this.updateTasks([payload.timeline_task]);
        this.emitTrailUpdated();
        break;
      case TrailConstants.TASK_FLAGGED:
      case TrailConstants.TASK_UNFLAGGED:
        this.updateTasks([payload.task]);
        this.emitTrailUpdated();
        break;
      default:
        break;
    }
  },

  setTags(tags) {
    this._tags = tags;
  },

  filterTrailByTagId(payload) {
    const tasks = this._tasks.filter((task) => {
      if (task.templateType === TrailConstants.taskStatus.ONDEMAND) {
        return true;
      }
      if (task.tags.length === 0 && payload.includeTasksWithoutTags) {
        return true;
      }
      const taskTagIDs = task.tags.map(tag => tag.id);
      const taskIsSelectedWithOtherTag = payload.selectedTags.some(tag => taskTagIDs.includes(tag));
      return (taskIsSelectedWithOtherTag);
    });
    this.setTasks(tasks);
    this.emitTrailUpdated();
  },

  filterTrailByTasksWithoutTags() {
    const tasks = this._tasks.filter(task => task.tags.length === 0);
    this.setTasks(tasks);
    this.emitTrailUpdated();
  },

  deleteTaskMessage({ taskId, messageId }) {
    const tasks = this._tasks
      .map((task) => {
        if (task.id === taskId) {
          task.messages = task.messages.filter(({ messageId: id }) => id !== messageId);
          task.messageCount = task.messages.length;
        }
        return task;
      });
    this.setTasks(tasks);
    this.emitTrailUpdated();
  },

  updateTaskWithMessage(payload) {
    const payloadFromPusher = !payload.temporaryId;
    const unsynced = m => !m.messageId && payload.content === m.body;
    const unsyncedMessageWithTheSameComment = m => unsynced(m) && payload.content === m.body;

    const messageSyncedWithServer = messages => messages
      && messages.some(m => payload.messageId && m.messageId === payload.messageId);

    const pusherWillUpdateTemporaryMessage = messages => payloadFromPusher &&
      messages &&
      messages.some(m => unsyncedMessageWithTheSameComment(m));

    const tasks = this._tasks.map((task) => {
      if (task.id !== payload.taskId) return task;

      const taskMessages = task.messages || [];
      if (messageSyncedWithServer(taskMessages)) return task;
      if (pusherWillUpdateTemporaryMessage(taskMessages)) return task;

      const newMessage = {
        temporaryId: payload.temporaryId,
        messageId: payload.messageId,
        body: payload.content,
        taskId: payload.taskId,
        timestamp: payload.timestamp,
        userName: payload.userName,
        userId: payload.userId,
        userInitials: payload.userInitials,
        attachment: payload.attachment,
        converted_task_content_comment: payload.convertedTaskContentComment,
      };

      const messages = taskMessages
        .filter(m => payloadFromPusher || m.temporaryId !== payload.temporaryId);

      messages.push(newMessage);

      const messageCount = messages.length;

      const mappedTask = _.assign({}, task, { messageCount, messages });
      return mappedTask;
    });
    this.setTasks(tasks);
    this.emitTrailUpdated();
  },

  setTasks(tasks) {
    this._tasks = tasks;
  },

  setLocationActiveDate(locationActiveDate) {
    this._locationActiveDate = locationActiveDate;
  },

  setLocationArchivedAt(locationArchivedAt) {
    this._locationArchivedAt = locationArchivedAt;
  },

  setLocationClosed(locationClosed) {
    this._locationClosed = locationClosed;
  },

  setTimelineHasTasks(timelineHasTasks) {
    this._timelineHasTasks = timelineHasTasks;
  },

  setLocationName(locationName) {
    this._locationName = locationName;
  },

  setLocationStatus(locationStatus) {
    this._locationStatus = locationStatus;
  },

  setTimeslots(timeslots) {
    this._timeslots = timeslots;
  },

  setDate(date, datePeriod) {
    this._date = moment(date);

    if (datePeriod) {
      this._datePeriod = datePeriod;
    }
  },

  getTags() {
    return this._tags;
  },

  getDate() {
    return moment(this._date);
  },

  isToday() {
    return this._datePeriod === TrailConstants.datePeriod.TODAY;
  },

  isFuture() {
    return TrailConstants.datePeriod.isFuture(this._datePeriod);
  },

  isPast() {
    return this._datePeriod === TrailConstants.datePeriod.PAST;
  },

  setServerTime(date) {
    this._serverDifference = moment().diff(moment(date), 'seconds');
  },

  getServerTime() {
    return moment().subtract(this._serverDifference, 'seconds');
  },

  setBusinessDayEnd(time) {
    this._businessDayEnd = time;

    if (hasServiceWorker()) {
      safePostMessage({
        type: MESSAGE_SET_BUSINESS_DAY_END,
        payload: {
          timestamp: time.toString(),
        },
      });
    }
  },

  getBusinessDayEnd() {
    return this._businessDayEnd;
  },

  updateTasks(newOrUpdatedTasks) {
    if (!(newOrUpdatedTasks instanceof Array)) {
      return;
    }
    const tasks = this._mergeTaskArrays(this._tasks, newOrUpdatedTasks);
    const sortedTasks = this._sortTasks(tasks);

    this.setTasks(sortedTasks);
  },

  taskStartsAfterToday(task) {
    const endOfDay = moment(this.getBusinessDayEnd());
    return moment(task.due_from_datetime).isAfter(endOfDay);
  },

  actionTaskCompletedAtUpdated(payload) {
    const {
      id: actionTimelineTaskId,
      completed_at: actionCompletedAt,
    } = payload;

    const tasks = this.getTasks();
    const { actions } = _.find(
      tasks,
      { actions: [{ action_timeline_task_id: actionTimelineTaskId }] }
    ) || {};
    const action = _.find(actions, { action_timeline_task_id: actionTimelineTaskId });
    if (!action) return;

    action.action_completed_at = actionCompletedAt;
  },

  updateTaskAction(payload) {
    const {
      action_timeline_task: actionTask,
      deleted_action_timeline_task_id: deletedActionId,
      action_relation: { action_timeline_task_id: newActionId },
      linkedTimelineTaskId,
    } = payload;

    const oldTimelineTaskAction = this.getTask(deletedActionId);
    if (oldTimelineTaskAction) this.deleteTask({ task: oldTimelineTaskAction });

    const { actions: linkedTaskActions } = this.getTask(linkedTimelineTaskId);
    const linkedTaskUpdate = {
      id: linkedTimelineTaskId,
      actions: linkedTaskActions.map(action => (
        action.action_timeline_task_id === deletedActionId
          ? { ...action, action_timeline_task_id: newActionId }
          : action)),
    };

    const taskUpdates = this.taskStartsAfterToday(actionTask)
      ? [linkedTaskUpdate]
      : [linkedTaskUpdate, actionTask];

    this.updateTasks(taskUpdates);
  },

  addTaskAction(payload) {
    const {
      action_timeline_task: actionTask,
      action_relation: actionRelation,
    } = payload;

    const { actions: linkedTaskActions = [] } = this.getTask(payload.linkedTimelineTaskId);
    const linkedTaskUpdate = {
      id: payload.linkedTimelineTaskId,
      actions: [...linkedTaskActions, actionRelation],
    };

    const tasksToSet = this.taskStartsAfterToday(actionTask)
      ? [linkedTaskUpdate] : [actionTask, linkedTaskUpdate];

    this.updateTasks(tasksToSet);
  },

  _mergeTaskArrays(first, second) {
    const firstTasks = _.clone(first);
    let secondTasks = _.clone(second);
    const secondTasksById = _.keyBy(secondTasks, 'id');

    let mergedTasks = firstTasks.map((task) => {
      if (secondTasksById[task.id]) {
        _.merge(task, secondTasksById[task.id]);

        secondTasks = _.reject(secondTasks, newTask => newTask.id === task.id);
      }

      return task;
    });
    mergedTasks = mergedTasks.concat(secondTasks);

    return mergedTasks;
  },

  getTasks() {
    return this._tasks;
  },

  getTaskByWidgetId(widgetId) {
    return this._tasks.find(task => task.widget_data_id === widgetId);
  },

  getTasksViewableOnTrail() {
    return this._tasks.filter(
      task => TrailConstants.STATUSES_TO_DISPLAY_ON_TRAIL.includes(task.status),
      this
    );
  },

  getTask(id) {
    return _.find(this._tasks, { id });
  },

  getCurrentTimeslotId() {
    return this._currentTimeslotId;
  },

  deleteTask(task) {
    const index = _.findIndex(this._tasks, { id: task.task.id });
    this._tasks.splice(index, 1);
  },

  deleteAction({ actionTimelineTaskId, linkedTimelineTaskId }) {
    const { actions: linkedTaskActions = [] } = this.getTask(linkedTimelineTaskId) || {};

    _.remove(linkedTaskActions, { action_timeline_task_id: actionTimelineTaskId });

    if (this.getTask(actionTimelineTaskId)) this.deleteTask({ task: { id: actionTimelineTaskId } });

    this.emitTrailUpdated();
  },

  deleteActions({ actionTimelineTaskIds, linkedTimelineTaskId }) {
    const { actions: linkedTaskActions = [] } = this.getTask(linkedTimelineTaskId) || {};
    _.remove(linkedTaskActions, ({ action_timeline_task_id: actionTimelineTaskId }) => (
      actionTimelineTaskIds.includes(actionTimelineTaskId)
    ));

    _.remove(this._tasks, ({ id }) => actionTimelineTaskIds.includes(id));

    this.emitTrailUpdated();
  },

  getTasksForTrail() {
    let parsedTasks = {};

    if (this.isToday()) {
      parsedTasks = this._tasksBySectionForToday();
    } else if (this.isPast()) {
      parsedTasks = this._tasksBySectionForPast();
    } else if (this.isFuture()) {
      parsedTasks = this._tasksBySectionForFuture();
    }

    return parsedTasks;
  },

  _createTimeslotEndTimeObject(timeslot) {
    if (Object.prototype.hasOwnProperty.call(timeslot, 'timeSlotId')) {
      const time = timeslot.endTime.split(':');
      return {
        dayOffset: 0,
        hour: parseInt(time[0], 10),
        minute: parseInt(time[1], 10),
        id: timeslot.timeSlotId,
      };
    }
    return _.assign({}, timeslot.endTime, {
      id: timeslot.id,
    });
  },

  _timeFromTimeslot(timeslot) {
    return this.getDate()
      .startOf('day')
      .add(timeslot.dayOffset, 'days')
      .hours(timeslot.hour)
      .minutes(timeslot.minute);
  },

  _tasksBySectionForToday() {
    const tasksBySection = {
      [TrailConstants.section.DONE]: [],
      [TrailConstants.section.NOW]: [],
      [TrailConstants.section.TODAY]: [],
      [TrailConstants.section.LATER_TODAY]: [],
      [TrailConstants.section.AFTER_TODAY]: [],
    };

    if (this._timeslots.length === 0) return tasksBySection;

    const timeslots = this._timeslots;
    const lastTimeslot = this._createTimeslotEndTimeObject(timeslots[timeslots.length - 1]);
    const businessDayEnd = this._timeFromTimeslot(lastTimeslot);
    const currentTimeslot = this._calculateCurrentTimeslot();

    this.setBusinessDayEnd(businessDayEnd);

    this.getTasksViewableOnTrail().forEach((task) => {
      if (this._taskHasBeenCompletedOnPreviousDay(task) && this._taskIsOverSeveralDays(task)) {
        return;
      }
      const taskTrailSection = this._getTrailSectionForTask({
        task, lastTimeslot, businessDayEnd, currentTimeslot,
      });
      if (taskTrailSection) {
        const updatedTask = { ...task, status: this._getStatusForTask(task) };
        tasksBySection[taskTrailSection].push(updatedTask);
      }
    });

    return tasksBySection;
  },

  _taskIsOverSeveralDays(task) {
    if (!task.schedule || !task.ongoing) {
      return false;
    }
    return task.schedule.duration.length > 1;
  },

  _tasksBySectionForPast() {
    const parsedTasks = {};
    parsedTasks[TrailConstants.section.DONE] = [];
    parsedTasks[TrailConstants.section.MISSED] = [];
    this.getTasksViewableOnTrail().forEach((task) => {
      if (task.status === TrailConstants.taskStatus.MISSED ||
          task.status === TrailConstants.taskStatus.ONDEMAND) {
        parsedTasks[TrailConstants.section.MISSED].push(task);
      } else {
        parsedTasks[TrailConstants.section.DONE].push(task);
      }
    });

    return parsedTasks;
  },

  _taskHasBeenCompletedOnPreviousDay(task) {
    if (!task.completed_at) {
      return false;
    }
    const startOfComplete = moment(task.completed_at).startOf('day');
    if (startOfComplete.isBefore(this._date.startOf('day'))) {
      return true;
    }
    return false;
  },

  _tasksBySectionForFuture() {
    let section;
    const parsedTasks = {};
    const timeslots = this._timeslots;

    parsedTasks[TrailConstants.section.DONE] = [];
    parsedTasks[TrailConstants.section.LATER] = [];
    parsedTasks[TrailConstants.section.ALL_DAY] = [];
    parsedTasks[TrailConstants.section.AFTER_TOMORROW] = [];

    const lastTimeslot = this._createTimeslotEndTimeObject(timeslots[timeslots.length - 1]);
    const futureBusinessDayEnd = this._timeFromTimeslot(lastTimeslot);

    this.getTasksViewableOnTrail().forEach((task) => {
      const dueDate = moment(task.due_by_datetime);
      section = TrailConstants.section.LATER;

      if (this._taskHasBeenCompletedOnPreviousDay(task) && this._taskIsOverSeveralDays(task)) {
        return;
      }

      if (futureBusinessDayEnd.isSame(dueDate)) {
        section = TrailConstants.section.ALL_DAY;
      }

      if (dueDate.isAfter(futureBusinessDayEnd)) {
        section = TrailConstants.section.AFTER_TOMORROW;
      }

      if ([DONE, FLAGGED].includes(task.status)) {
        section = TrailConstants.taskStatus.DONE;
      }

      if (parsedTasks[section]) {
        parsedTasks[section].push(task);
      }
    });

    return parsedTasks;
  },

  getTimeSlot(id) {
    return this._timeslots.filter(timeslot => timeslot.id === id).pop();
  },

  getTimeslotsForTrail() {
    const parsedTimeslots = {};
    const currentTimeslot = this._calculateCurrentTimeslot();
    this._currentTimeslotId = currentTimeslot.id;

    if (hasServiceWorker()) {
      safePostMessage({
        type: MESSAGE_SET_CURRENT_TIMESLOT,
        payload: {
          id: this._currentTimeslotId,
        },
      });
    }

    parsedTimeslots[TrailConstants.section.DONE] =
      this._findTimeslotsForSection(TrailConstants.section.DONE);
    parsedTimeslots[TrailConstants.section.NOW] = [currentTimeslot];
    parsedTimeslots[TrailConstants.section.TODAY] =
      this._findTimeslotsForSection(TrailConstants.section.TODAY);
    parsedTimeslots[TrailConstants.section.LATER] =
      this._findTimeslotsForSection(TrailConstants.section.LATER);
    parsedTimeslots[TrailConstants.section.LATER_TODAY] =
      this._findTimeslotsForSection(TrailConstants.section.LATER_TODAY);
    parsedTimeslots[TrailConstants.section.AFTER_TODAY] =
      this._findTimeslotsForSection(TrailConstants.section.AFTER_TODAY);

    return parsedTimeslots;
  },

  _findTimeslotsForSection(section) {
    const sectionTimeslots = [];
    const tasksForSection = this.getTasksForTrail()[section] || [];
    const timeslotsById = _.keyBy(this._timeslots, 'id');

    tasksForSection.forEach((task) => {
      if (timeslotsById[task.timeslot_id]) {
        sectionTimeslots.push(timeslotsById[task.timeslot_id]);
      }
    });

    return _.sortBy(_.uniq(sectionTimeslots), 'position');
  },

  _sortTasks(tasks) {
    const completedTasks = _.chain(tasks)
      .filter(task => Boolean(task.completed_at))
      .sortBy('completed_at')
      .value() || [];
    const uncompletedTasks = _.chain(tasks)
      .filter(task => !task.completed_at)
      .sortBy('order')
      .value() || [];

    return completedTasks.concat(uncompletedTasks);
  },

  _calculateCurrentTimeslot() {
    let currentTimeslot;

    if (this.isFuture()) {
      currentTimeslot = _.first(this._timeslots);
    } else if (this.isPast()) {
      currentTimeslot = _.last(this._timeslots);
    } else {
      currentTimeslot = this._timeslotForCurrentTime(this._timeslots);
    }

    return currentTimeslot || {};
  },

  _timeslotForCurrentTime() {
    const timeNow = this.getServerTime();
    let startTime;
    let endTime;

    const currentTimeslot = _.find(this._timeslots, (timeslot) => {
      startTime = moment(window.prefetch.date).set({
        hour: timeslot.startTime.hour,
        minute: timeslot.startTime.minute,
      }).add(timeslot.startTime.dayOffset, 'days');

      endTime = moment(window.prefetch.date).set({
        hour: timeslot.endTime.hour,
        minute: timeslot.endTime.minute,
      }).add(timeslot.endTime.dayOffset, 'days');

      return timeNow.isSame(startTime, 'minute') ||
        timeNow.isBetween(startTime, endTime, 'minute') ||
        timeNow.clone().subtract(1, 'days').isBetween(startTime, endTime, 'minute') ||
        timeNow.clone().add(1, 'days').isBetween(startTime, endTime, 'minute');
    });
    return currentTimeslot || _.last(this._timeslots);
  },

  _doneTaskIsDueToBeLocked(task, timeNow) {
    const canBeLocked = this.isToday() ? task.canBeLockedToday : true;
    return Boolean(task.isCompleted && !task.isLocked && canBeLocked &&
      task.gracePeriodEnd && moment(task.gracePeriodEnd).isBefore(timeNow));
  },

  _currentTaskShouldBeOverdue(task, timeNow) {
    return task.status === TrailConstants.taskStatus.CURRENT &&
        moment(task.due_by_datetime).isBefore(timeNow);
  },

  _laterTaskShouldBeCurrent(task, timeNow) {
    const taskStatusIsLater = task.status === TrailConstants.taskStatus.LATER;
    if (!taskStatusIsLater) {
      return false;
    }

    if (task.ongoing) {
      const taskStart = task.schedule.duration[0].start;

      if (taskStart.type === TaskScheduleConstants.TIME) {
        const startTime = moment(window.prefetch.date).set({
          hour: taskStart.value.split(':')[0],
          minute: taskStart.value.split(':')[1],
        });

        return startTime.isBefore(timeNow);
      }
    }

    return task.timeslot_id === this._currentTimeslotId;
  },

  _getStatusForTask(task) {
    if (task.completed_at) {
      return DONE;
    }

    if (task.status === MISSED) {
      return OVERDUE;
    }
    return task.status;
  },

  _getTrailSectionForTask({
    task, businessDayEnd, lastTimeslot, currentTimeslot,
  }) {
    if (task.completed_at || isFlagged(task)) {
      return TrailConstants.section.DONE;
    }
    if (task.templateType === TaskTemplateConstants.templateType.AUTOMATED) {
      return TrailConstants.section.NOW;
    }

    const taskStatus = this._getStatusForTask(task);

    if (taskStatus === CURRENT) {
      if (task.due_by_datetime) {
        const taskDueDate = moment(task.due_by_datetime);
        if (taskDueDate.isAfter(businessDayEnd)) {
          return TrailConstants.section.AFTER_TODAY;
        }
        if (taskDueDate.isSame(businessDayEnd) && lastTimeslot.id !== currentTimeslot.id) {
          return TrailConstants.section.TODAY;
        }

        const endOfCurrentTimeslot = this._createTimeslotEndTimeObject(currentTimeslot);
        const currentTimeslotEndTime = this._timeFromTimeslot(endOfCurrentTimeslot);
        if (taskDueDate.isAfter(currentTimeslotEndTime)) {
          return TrailConstants.section.TODAY;
        }
      }

      return TrailConstants.section.NOW;
    }

    return taskStatusToTrailSection[taskStatus];
  },

  _trailIsStale() {
    return !this._dataReloadWatchdog.recentActivityRecorded();
  },

  onIntervalTick() {
    const offlineServiceWorker = hasServiceWorker() && !navigator.onLine;

    if (this._trailIsStale() && !offlineServiceWorker) {
      WindowHelper.navigateTo(TrailConstants.trailPageRoute());
    } else if (this.isToday() && !this._isFetchingTrail) {
      return this.updateTaskStatus();
    }
    return undefined;
  },

  _getTrailArgs(optionsOverrides = {}) {
    const locationId = this.getLocationId();
    const date = this.getDate().format('YYYY-MM-DD');
    let options = _.isEmpty(this.getTags()) ? {} : { tagOptions: this.getTags() };
    options = { ...options, ...optionsOverrides };
    return [locationId, date, options];
  },

  updateTaskStatus() {
    return new Promise(((resolve) => {
      const timeNow = this.getServerTime();
      this._currentTimeslotId = this._calculateCurrentTimeslot().id;
      const doesTaskNeedUpdating = task => (
        this._doneTaskIsDueToBeLocked(task, timeNow) ||
        this._currentTaskShouldBeOverdue(task, timeNow) ||
        this._laterTaskShouldBeCurrent(task, timeNow)
      );
      const thereAreTasksToUpdate = this._tasks.some(doesTaskNeedUpdating);
      if (!thereAreTasksToUpdate) {
        resolve();
      } else {
        const refreshedTasks = this.getTasks().map(this._recalculateTask, this);
        this.setTasks(refreshedTasks);
        this.emitTrailUpdated();

        this._isFetchingTrail = true;

        const timeout = Math.floor((random() * this.maxTimeout));

        setTimeout(() => {
          const args = this._getTrailArgs({ loadType: TrailConstants.trailApiLoadType.TIMESLOT });
          TrailActions.getTrail(...args);
          resolve(timeout);
        }, timeout);
      }
    }));
  },

  _recalculateTask(task) {
    let { status } = task;

    const timeNow = this.getServerTime();
    const taskShouldBeOverdue = this._currentTaskShouldBeOverdue(task, timeNow);
    if (taskShouldBeOverdue) {
      status = OVERDUE;
    }
    const taskShouldBeCurrent = this._laterTaskShouldBeCurrent(task, timeNow);
    if (taskShouldBeCurrent) {
      status = CURRENT;
    }

    const isLocked = this._doneTaskIsDueToBeLocked(task, timeNow);
    return { ...task, status, isLocked };
  },

  forceFetch() {
    this._currentTimeslotId = this._calculateCurrentTimeslot().id;
    this._isFetchingTrail = true;

    const args = this._getTrailArgs({ loadType: TrailConstants.trailApiLoadType.ONLINE });
    return TrailActions.getTrail(...args);
  },

  getLocationId() {
    return this._locationId;
  },

  getLocationClosed() {
    return this._locationClosed;
  },

  emitTrailUpdated() {
    this.emit(
      TrailConstants.TRAIL_UPDATED,
      this.getTasksForTrail(),
      this.getTimeslotsForTrail(),
      this._locationActiveDate,
      this._locationStatus,
      this._locationClosed,
      this._locationName,
      this._timelineHasTasks,
      this._locationArchivedAt
    );
  },

  emitTrailLoading() {
    this.emit(TrailConstants.TRAIL_LOADING);
  },

  emitCommentUpdate(taskId) {
    this.emit(TrailConstants.COMMENT_SAVED, taskId);
  },

  emitCompletionStarted(taskId) {
    this.emit(TrailConstants.MOVE_TASK_TO_COMPLETED, taskId);
  },

  emitCompletionFailed(taskId) {
    this.emit(TrailConstants.MOVE_TASK_TO_COMPLETED_FAILED, taskId);
  },

  emitTaskRemovalStarted(taskId) {
    this.emit(TrailConstants.MOVE_TASK_FROM_COMPLETED, taskId);
  },

  emitTaskRemovalFailed(taskId) {
    this.emit(TrailConstants.MOVE_TASK_FROM_COMPLETED_FAILED, taskId);
  },
});

TrailStore.reset = function () {
  if (TrailStore.instance) {
    AppDispatcher.unregister(TrailStore.dispatchToken);
    clearInterval(TrailStore.refreshToken);
    TrailStore.instance = null;
  }
};

if (process.env.TEST === true) {
  window.TrailStore = TrailStore;
}

export { TrailStore };
