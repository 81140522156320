const IconPathLookup = {
  getIconForMimeType: (mimeType) => {
    let iconPathExtension;

    switch (mimeType) {
      case 'application/x-troff-msvideo':
      case 'video/avi':
      case 'video/msvideo':
      case 'video/x-msvideo':
        iconPathExtension = 'file-avi';
        break;
      case 'image/bmp':
      case 'image/x-windows-bmp':
        iconPathExtension = 'file-bmp';
        break;
      case 'application/csv':
      case 'text/csv':
        iconPathExtension = 'file-csv';
        break;
      case 'application/msword':
        iconPathExtension = 'file-doc';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        iconPathExtension = 'file-docx';
        break;
      case 'application/epub+zip':
        iconPathExtension = 'file-epub';
        break;
      case 'video/x-flv':
        iconPathExtension = 'file-flv';
        break;
      case 'image/gif':
        iconPathExtension = 'file-gif';
        break;
      case 'image/jpeg':
      case 'image/pjpeg':
      case 'image/x-jps':
        iconPathExtension = 'file-jpeg';
        break;
      case 'video/quicktime':
        iconPathExtension = 'file-mov';
        break;
      case 'audio/mpeg3':
      case 'audio/x-mpeg-3':
        iconPathExtension = 'file-mp3';
        break;
      case 'video/mp4':
        iconPathExtension = 'file-mp4';
        break;
      case 'audio/mpeg':
      case 'video/mpeg':
        iconPathExtension = 'file-mpg';
        break;
      case 'application/pdf':
        iconPathExtension = 'file-pdf';
        break;
      case 'image/png':
        iconPathExtension = 'file-png';
        break;
      case 'application/vnd.ms-powerpoint':
      case 'application/mspowerpoint':
      case 'application/powerpoint':
      case 'application/x-mspowerpoint':
        iconPathExtension = 'file-ppt';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        iconPathExtension = 'file-pptx';
        break;
      case 'application/rtf':
      case 'application/x-rtf':
      case 'text/rtf':
      case 'text/richtext':
        iconPathExtension = 'file-rtf';
        break;
      case 'image/svg+xml':
        iconPathExtension = 'file-svg';
        break;
      case 'text/plain':
        iconPathExtension = 'file-txt';
        break;
      case 'application/excel':
      case 'application/vnd.ms-excel':
      case 'application/x-excel':
      case 'application/x-msexcel':
        iconPathExtension = 'file-xls';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        iconPathExtension = 'file-xlsx';
        break;
      case 'application/zip':
      case 'application/octet-stream':
        iconPathExtension = 'file-zip';
        break;
      default:
        iconPathExtension = 'file';
    }

    const iconPath = `/custom/attachment-icons/${iconPathExtension}`;

    return iconPath;
  },
};

export default IconPathLookup;
