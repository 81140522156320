import _ from 'lodash';
import { createSelector } from 'reselect';
import { UserRole } from '../../application/UserRole';
import {
  locationsAndGroupsSelector,
  getLocationIdsFromTimelineTemplateIds,
} from '../../sites/selectors/locationSelectors';
import { ALL_SITES } from '../constants';

const isNotAdmin = ({ role }) => !(new UserRole(role)).isOrgAdminOrAbove();

export const organizationUsersSelector = state => state.users.entities;
export const organizationUsersLoadedSelector = state => state.users.loaded;

export const nonAdminUsersSelector = createSelector(
  organizationUsersSelector,
  users => _.chain(users).values().filter(isNotAdmin).value());

const getUsersWithLocationIds = (users, locationsAndGroups) => users.map(user => ({
  ...user,
  locationIds: getLocationIdsFromTimelineTemplateIds(
    locationsAndGroups,
    user.timelineTemplateIds
  ),
}));

export const filteredUsersByLocationSelector = createSelector(
  [
    (state, users) => users,
    (state, users, selectedLocationIds) => selectedLocationIds,
    locationsAndGroupsSelector,
  ],
  (users, selectedLocationIds, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    return usersWithLocationIds.filter((user) => {
      if (user.locations === ALL_SITES) return true;
      if (!selectedLocationIds.length) return true;
      return user.locationIds.some(locationId => selectedLocationIds.includes(locationId));
    });
  }
);

const addUserToLocationGroup = (usersByLocation, locationId, user) => {
  if (!usersByLocation[locationId]) {
    usersByLocation[locationId] = [];
  }
  usersByLocation[locationId].push(user);
};

export const getUsersByLocationSelector = createSelector(
  [
    (state, users) => users,
    locationsAndGroupsSelector,
  ],
  (users, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);
    return usersWithLocationIds.reduce((usersByLocation, user) => {
      if (user.locations === ALL_SITES) {
        Object.values(locationsAndGroups.locations).forEach((location) => {
          addUserToLocationGroup(usersByLocation, location.id, user);
        });
      } else {
        user.locationIds.forEach((locationId) => {
          addUserToLocationGroup(usersByLocation, locationId, user);
        });
      }
      return usersByLocation;
    }, {});
  }
);
