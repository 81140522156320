import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import Button from '../../common/Button';
import { PREVIEW_TASKS_PDF } from '../constants';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

const PreviewPDFButton = ({ selectedTasksLength, bulkGroupSelected, intl: { formatMessage } }) => {
  const { push } = useHistory();
  const { search } = useLocation();

  const previewPdf = () => {
    const route = `${PREVIEW_TASKS_PDF}${search}`;

    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskReports.PDF_PREVIEWED,
      { numberOfTasks: selectedTasksLength, bulkGroupSelected }
    );

    push(route);
  };

  return (
    <Button
      iconName='mini/arrows/arrows-16px-4_launch-11'
      className='showtime-button showtime-button--small showtime-button--attention showtime-button--adjacent '
      onClick={ previewPdf }
      dataTest='taskReports.previewPDF'
      label={ formatMessage({ id: 'taskReports.share.previewPDFExport' }) }
    />
  );
};

PreviewPDFButton.propTypes = {
  selectedTasksLength: PropTypes.number.isRequired,
  bulkGroupSelected: PropTypes.bool.isRequired,
};

export default injectIntl(PreviewPDFButton);
