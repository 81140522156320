import _ from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import ListSelection, { Group, Item } from '../ListSelection';
import AnchoredMenu from '../menu/AnchoredMenu';
import FilterSummary from './FilterSummary';
import random from '../random';
import { ALL } from './constants';

const MAX_SITE_SUMMARY_COUNT = 4;

const FilterMenu = injectIntl(({
  intl: { formatMessage },
  isLoading,
  showSearch,
  onItemsSelect,
  groups,
  onSelectAll,
  allSelected,
  allItemsSelected,
  selectedItemIds,
  selectAllSecondaryLabel,
  additionalCheckboxClasses,
  multiSelect,
}) => {
  const multipleGroups = groups.length > 1;
  const groupBorderBottom = multipleGroups;
  const separateSelected = !multipleGroups;

  groups = groups.filter(group => _.get(group, 'items', []).length > 0);
  return (
    <ul className='showtime-menu__list'>
      <ListSelection { ...{
        showSearch, isLoading, groupBorderBottom, separateSelected,
      } }
      >
        {onSelectAll && (
        <Group onSelectChange={ onSelectAll } id='all'>
          <Item
            checked={ allSelected || allItemsSelected }
            value={ ALL }
            additionalClasses={ additionalCheckboxClasses }
            secondaryLabel={ selectAllSecondaryLabel }
          >
            { formatMessage({ id: 'listSelectionWithAll.allLabel' }) }
          </Item>
        </Group>
        )}
        {groups.map(({ items, id: groupId, parentId: groupParentId }, groupIndex) => (
          <Group
            onSelectChange={ ({ value, isSelected, parentId }) => onItemsSelect({
              groupIndex, value, id: value, isSelected, groupId, parentId, groupParentId,
            }) }
            id={ groupId }
            key={ groupId }
          >
            { items.map(({
              id, name, secondaryLabel = null, isSelected = null, dataTest = null, parentId,
            }) => {
              let checked = allSelected || selectedItemIds.includes(id);
              if (isSelected !== null) checked = isSelected;
              return (
                <Item
                  key={ id }
                  value={ id }
                  secondaryLabel={ secondaryLabel }
                  checked={ checked }
                  dataTest={ dataTest }
                  additionalClasses={ additionalCheckboxClasses }
                  parentId={ parentId }
                  radio={ !multiSelect }
                >
                  { name }
                </Item>
              );
            })}
          </Group>
        ))}
      </ListSelection>
    </ul>
  );
});

const DropdownFilter = ({
  intl: { formatMessage },
  dataTest,
  showSearch,
  isLoading,
  allSelected,
  onSelectAll,
  groups,
  items,
  selectedItemIds,
  onItemsSelect,
  label,
  onClearAll,
  iconName,
  selectedItemsLabel,
  initiallyExpanded,
  onClickOutside,
  onExpand,
  selectAllSecondaryLabel,
  additionalCheckboxClasses,
  exclusionWhenAllSelected,
  selectFromGroupId,
  multiSelect,
}) => {
  groups = groups || [{ items }];
  if (groups.length === 0) groups = [{ items }];

  const [menuExpanded, expandMenu] = useState(initiallyExpanded);

  const selectableItems = selectFromGroupId
    ? _.chain(groups).find({ id: selectFromGroupId }).get('items').value()
    : _.chain(groups).flatMap('items').value();
  const allItemsSelected = selectableItems.every(({ id }) => selectedItemIds.includes(id));

  if (!selectedItemsLabel) {
    const allItemsSelectedWithoutExclusion = (allItemsSelected && !exclusionWhenAllSelected);
    if (allSelected || allItemsSelectedWithoutExclusion || _.isEmpty(selectedItemIds)) {
      selectedItemsLabel = formatMessage({ id: 'dropdownFilter.summary.all' });
    } else {
      const groupToList = selectFromGroupId ? _.find(groups, { id: selectFromGroupId }) : groups[0];
      const list = _.chain(groupToList).get('items').reduce((accu, item) => (
        selectedItemIds.includes(item.id) ? [...accu, item.name] : accu
      ), [])
        .slice(0, MAX_SITE_SUMMARY_COUNT)
        .join(', ')
        .value();
      const hasMoreSelectedSites = selectedItemIds.length > MAX_SITE_SUMMARY_COUNT;
      const remainingCount = hasMoreSelectedSites
        ? selectedItemIds.length - MAX_SITE_SUMMARY_COUNT : 0;
      selectedItemsLabel = formatMessage(
        { id: 'dropdownFilter.summary.selected' }, { list, remainingCount });
    }
  }

  const handleExpandMenu = (value) => {
    expandMenu(value);
    if (value) onExpand();
  };

  const handleClickOutsideMenu = () => {
    expandMenu(false);
    onClickOutside();
  };

  const uniqueClickOutsideClass = `outside--click-ignore-${random() * 10000}`;

  return (
    <div className='showtime-filter' data-test={ dataTest }>
      <FilterSummary
        label={ label }
        secondaryLabel={ selectedItemsLabel }
        onClear={ onClearAll }
        className={ uniqueClickOutsideClass }
        iconName={ iconName }
        onClick={ () => handleExpandMenu(!menuExpanded) }
      />
      { menuExpanded && (
        <AnchoredMenu
          align='right'
          className='showtime-menu'
          onClickOutside={ handleClickOutsideMenu }
          outsideClickIgnoreClass={ uniqueClickOutsideClass }
        >
          <FilterMenu
            { ...{
              additionalCheckboxClasses,
              isLoading,
              showSearch,
              onItemsSelect,
              onSelectAll,
              allSelected,
              allItemsSelected,
              selectedItemIds,
              selectAllSecondaryLabel,
              multiSelect,
            } }
            groups={ groups }
          />
        </AnchoredMenu>
      )}
    </div>
  );
};

DropdownFilter.propTypes = {
  showSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
  allSelected: PropTypes.bool,
  onSelectAll: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.node,
  })),
  groups: PropTypes.array,
  selectedItemIds: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])),
  onItemsSelect: PropTypes.func.isRequired,
  label: PropTypes.node,
  selectedItemsLabel: PropTypes.node,
  onClearAll: PropTypes.func,
  iconName: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onExpand: PropTypes.func,
  selectAllSecondaryLabel: PropTypes.string,
  additionalCheckboxClasses: PropTypes.string,
  exclusionWhenAllSelected: PropTypes.bool,
  selectFromGroupId: PropTypes.string,
  multiSelect: PropTypes.bool,
};

DropdownFilter.defaultProps = {
  showSearch: true,
  isLoading: false,
  dataTest: null,
  allSelected: false,
  onSelectAll: null,
  items: [],
  selectedItemIds: [],
  label: null,
  onClearAll: null,
  iconName: null,
  selectedItemsLabel: null,
  initiallyExpanded: false,
  onClickOutside() {},
  onExpand() {},
  groups: undefined,
  selectAllSecondaryLabel: undefined,
  additionalCheckboxClasses: undefined,
  exclusionWhenAllSelected: false,
  selectFromGroupId: undefined,
  multiSelect: true,
};

export default injectIntl(DropdownFilter);
