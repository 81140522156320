import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import CameraCapture from '../CameraCapture';
import useFileUpload from '../useFileUpload';
import TextArea from './TextArea';
import AttachmentPreview from './AttachmentPreview';
import FilePicker from './FilePicker';
import { AttachmentConstants } from '../../attachments/constants';
import {
  formatAttachmentFromFilestackResponse, formatAttachmentFromFileObject,
} from '../../attachments/formatAttachment';
import FileTextAreaWrapper from './FileTextAreaWrapper';
import WindowHelper from '../../utils/window';

const {
  State: {
    UPLOADING, FAILED, SAVED,
  },
} = AttachmentConstants;

const FileTextArea = ({
  textareaRef,
  hasError,
  onTextChange,
  textValue,
  children,
  onAttachmentChange,
  attachment,
  showCameraOnly,
  dataTest,
  placeholder,
  stacked,
  disabled,
}) => {
  const intl = useIntl();
  const [isFocused, setIsFocused] = useState(false);
  const [progress, setProgress] = useState(0);

  const attachmentRef = useRef();
  useEffect(() => {
    attachmentRef.current = attachment;
  }, [attachment]);

  const handleUploadDone = ({ filesUploaded: [file] }) => {
    if (!attachmentRef.current) return;

    const metadata = formatAttachmentFromFilestackResponse({
      ...file,
      type: file.mimetype,
    });
    onAttachmentChange({ ...attachmentRef.current, metadata, status: SAVED });
    setProgress(100);
  };

  const handleUploadFailed = () => {
    if (!attachmentRef.current) return;

    onAttachmentChange({ ...attachmentRef.current, status: FAILED });
    setProgress(0);
  };

  const handleUploadCanceled = () => {
    onAttachmentChange(null);
    setProgress(0);
  };

  const handleUploadProgress = ({ totalPercent }) => {
    if (!attachmentRef.current) return;

    setProgress(totalPercent);
  };

  const { onPickerOpen, onCapture, isFilestackAvailable } = useFileUpload({
    multiUpload: false,
    onUploadDone: handleUploadDone,
    onFileUploadFailed: handleUploadFailed,
    onCancel: handleUploadCanceled,
    onProgress: handleUploadProgress,
  });
  const disableFileInputs = Boolean(disabled || attachment || !isFilestackAvailable);

  const handleImageRetry = (file) => {
    onAttachmentChange({ ...attachment, status: UPLOADING });
    onCapture(file);
  };
  const handleImageCapture = ({ target }) => {
    const { files: [file] } = target;
    onAttachmentChange({ file, metadata: formatAttachmentFromFileObject(file), status: UPLOADING });
    onCapture(file);
  };
  const handlePickerOpen = () => {
    onAttachmentChange({ status: UPLOADING });
    onPickerOpen();
  };
  const handleFileDelete = () => onAttachmentChange(null);

  const { capture } = WindowHelper.browserSupport();

  const cameraOnlyCondition = showCameraOnly && capture;

  const containerClasses = classNames({
    'showtime-conversation__comment-box-inline-input-wrapper': true,
    'is-disabled': disabled,
  });

  return (
    <FileTextAreaWrapper
      { ...{
        isFocused, hasError, dataTest, stacked,
      } }
    >
      <div className={ containerClasses }>
        <div className='showtime-conversation__comment-box-inline-camera'>
          <CameraCapture
            onCapture={ handleImageCapture }
            disabled={ disableFileInputs }
          />
        </div>

        <div className='showtime-conversation__comment-box-inline-input'>
          <AttachmentPreview
            { ...{ attachment, progress } }
            onUploadRetry={ handleImageRetry }
            onDelete={ handleFileDelete }
          />
          <TextArea
            onBlur={ () => setIsFocused(false) }
            onFocus={ () => setIsFocused(true) }
            commentBoxRef={ textareaRef }
            onChange={ ({ target: { value } }) => onTextChange(value) }
            intl={ intl }
            value={ textValue }
            placeholder={ placeholder }
          />
        </div>
        { !cameraOnlyCondition && (
        <FilePicker {
          ...{ onPickerOpen: handlePickerOpen, disabled: disableFileInputs, intl }
        }
        />
        )}
      </div>
      { children }
    </FileTextAreaWrapper>
  );
};

FileTextArea.propTypes = {
  textareaRef: PropTypes.object,
  hasError: PropTypes.bool,
  onTextChange: PropTypes.func.isRequired,
  textValue: PropTypes.string.isRequired,
  children: PropTypes.node,
  onAttachmentChange: PropTypes.func.isRequired,
  attachment: PropTypes.object,
  showCameraOnly: PropTypes.bool,
  dataTest: PropTypes.string,
  placeholder: PropTypes.string,
  stacked: PropTypes.bool,
  disabled: PropTypes.bool,
};

FileTextArea.defaultProps = {
  textareaRef: null,
  hasError: false,
  children: null,
  attachment: null,
  showCameraOnly: false,
  dataTest: undefined,
  placeholder: undefined,
  stacked: undefined,
  disabled: false,
};

export default FileTextArea;
