import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon from '../common/SVGIcon';
import IconPathLookup from './iconPathLookup';
import { isLink } from './constants';


const YoutubeThumbnail = ({ youtubeCode }) => (
  <img
    className='showtime-attachment__indicator-preview'
    alt='Video Thumbnail'
    src={ `https://img.youtube.com/vi/${youtubeCode}/0.jpg` }
  />
);

YoutubeThumbnail.propTypes = {
  youtubeCode: PropTypes.string.isRequired,
};

const AttachmentIcon = ({ attachmentType, mimeType, youtubeCode }) => {
  if (youtubeCode) {
    return (
      <YoutubeThumbnail youtubeCode={ youtubeCode } />
    );
  }

  const iconName = isLink(attachmentType)
    ? '/custom/attachment-icons/link'
    : IconPathLookup.getIconForMimeType(mimeType);

  return (
    <SVGIcon
      classes='showtime-attachment__indicator-icon'
      iconName={ iconName }
    />
  );
};

AttachmentIcon.propTypes = {
  mimeType: PropTypes.string,
  attachmentType: PropTypes.string,
  youtubeCode: PropTypes.string,
};

AttachmentIcon.defaultProps = {
  mimeType: '',
  attachmentType: '',
  youtubeCode: null,
};

export default AttachmentIcon;

