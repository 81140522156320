import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import PDFComment from './PDFComment';

const PDFTaskContentComments = ({
  comments,
  intl,
  timeZone,
  showSignature,
}) => _.map(comments,
  ({
    content, id, commented_at: commentedAt, commented_by_user_name: commentedBy,
  }) => (
    <PDFComment
      key={ id }
      { ...{
        showSignature, timeZone, intl, content, commentedAt, commentedBy,
      } }
    />
  ));

PDFTaskContentComments.propTypes = {
  comments: PropTypes.array,
  showSignature: PropTypes.bool,
};

PDFTaskContentComments.defaultProps = {
  comments: [],
  showSignature: true,
};

export default PDFTaskContentComments;
