import { SCRIPT_PREFIX } from '../../../service-workers/constants';

export default async (name) => {
  if ('serviceWorker' in navigator) {
    const registeredWorkers = await navigator.serviceWorker.getRegistrations();
    registeredWorkers.map((worker) => {
      const splitScriptURL = worker.active
        ? worker.active.scriptURL.split('/')
        : [];
      const scriptName = splitScriptURL[splitScriptURL.length - 1];

      if (scriptName === `${SCRIPT_PREFIX}${name}.js`) {
        return worker.unregister();
      }

      return null;
    });
  }
};
