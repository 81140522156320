import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M1,16c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v14C2,15.6,1.6,16,1,16z'
    />
    <Path
      is='path'
      fill={ (props.style && props.style.fill) || '#444' }
      d='M15,11H5c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h10c0.7,0,1.2,0.8,0.9,1.4L14.1,6l1.8,3.6 C16.2,10.2,15.7,11,15,11z M6,9h7.4l-1.3-2.6c-0.1-0.3-0.1-0.6,0-0.9L13.4,3H6V9z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
