import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { getFixedShortcuts } from '../constants';
import Select, { NO_SELECTION } from '../../common/forms/Select';
import { createIntlMessageFormatter } from '../../common/intlMessageFormatter';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';

const FixedShortcutsMenu = ({
  intl, location: { search: currentSearch }, history,
}) => {
  const fixedShortcuts = getFixedShortcuts();
  const currentQueryString = currentSearch.slice(1);
  const currentQueryStringMatchesShortcut = !!_.find(
    fixedShortcuts, { queryString: currentQueryString });

  const shortcutLabel = createIntlMessageFormatter(intl, 'taskReports.shortcuts');
  const noneSelectedOption = { value: NO_SELECTION, label: shortcutLabel('fixedTitle') };
  const shortcutOptions = fixedShortcuts.map(shortcut => ({
    label: shortcutLabel(shortcut.labelPath),
    value: shortcut.queryString,
  }));
  const findShortcutLabelByValue = value => _.find(shortcutOptions, { value }).label;
  const handleChange = (queryString) => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskReports.SHORTCUT_CLICKED,
      { shortcut: findShortcutLabelByValue(queryString) }
    );
    history.push({ search: queryString });
  };
  const options = [
    ...currentQueryStringMatchesShortcut ? [] : [noneSelectedOption],
    ...shortcutOptions,
  ];

  return (
    <Select
      options={ options }
      onChange={ handleChange }
      dataTest='taskReports.fixedShortcutsMenu'
      value={ currentQueryStringMatchesShortcut ? currentQueryString : NO_SELECTION }
      className='showtime-task-reports__header-shortcuts'
    />
  );
};

FixedShortcutsMenu.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default compose(
  injectIntl,
  withRouter,
)(FixedShortcutsMenu);
