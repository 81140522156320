import constants from './constants';
import { RequestActions, RequestConstants } from '../request';

const actions = {
  loadIncompleteNotifications: () => RequestActions.request({
    url: '/api/v1/user/notifications/incomplete',
    key: constants.INCOMPLETE_KEY,
    method: RequestConstants.GET,
  }),

  dismissNotification: notification => RequestActions.request({
    url: `/api/v1/user/notifications/${notification.id}/dismiss`,
    key: constants.DISMISS_KEY,
    method: RequestConstants.PUT,
  }),

  updateNotification: notification => ({
    type: constants.UPDATE,
    notification,
  }),
};

export default actions;
