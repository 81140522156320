import { RequestConstants, RequestActions } from '../../request';
import { LocationConstants } from '../constants';
import { locationsHaveLoadedSelector } from '../selectors';
import { routeTo } from '../../router/actionCreators';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { TimeSlotConfigurationStore } from '../../organization/store';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';
import setupGuideActions from '../../setup-guide/actions/setupGuideActions';

const SiteMetrics = TrailMetricsDirectory.page.Sites;

export const fetchLocations = () => (dispatch, getState) => {
  if (locationsHaveLoadedSelector(getState())) {
    return Promise.resolve();
  }
  return dispatch(RequestActions.request({
    url: LocationConstants.GET_LOCATIONS_ROUTE,
    key: LocationConstants.GET_LOCATIONS_KEY,
    method: RequestConstants.GET,
  }));
};

export const getNewLocation = () => RequestActions.request({
  url: LocationConstants.GET_NEW_LOCATION_ROUTE,
  key: LocationConstants.GET_NEW_LOCATION_KEY,
  method: RequestConstants.GET,
});

export const getLocation = locationId => RequestActions.request({
  url: LocationConstants.getOrUpdateLocationRoute(locationId),
  key: LocationConstants.GET_LOCATION_KEY,
  method: RequestConstants.GET,
});

const updateSetupGuideProgress = async (dispatch) => {
  await dispatch(setupGuideActions.getSetupGuideProgress());
};

export const updateLocation = (locationId, location, metricsToPublish) => async (dispatch) => {
  const request = RequestActions.request({
    url: LocationConstants.getOrUpdateLocationRoute(locationId),
    key: LocationConstants.UPDATE_LOCATION_KEY,
    method: RequestConstants.PUT,
    errorMessage: 'sites.edit.errors.updated',
    successMessage: 'sites.edit.success.updated',
    options: {
      body: JSON.stringify({
        ...location,
        TimeSlotConfigurationStore:
          JSON.stringify(TimeSlotConfigurationStore.getSaveableTimeSlots()),
      }),
    },
  });
  const { success } = await dispatch(request);

  if (success) {
    (metricsToPublish || []).forEach((metric) => {
      metricsPublisher.recordMetric(metric.type, metric.data);
    });

    await updateSetupGuideProgress(dispatch);
    dispatch(unsavedChangesActions.cleared());

    dispatch(routeTo('/'));
  }

  return Promise.resolve();
};

export const createLocation = location => async (dispatch) => {
  const request = RequestActions.request({
    url: LocationConstants.CREATE_LOCATION_ROUTE,
    key: LocationConstants.CREATE_LOCATION_KEY,
    method: RequestConstants.POST,
    errorMessage: 'sites.edit.errors.created',
    successMessage: 'sites.edit.success.created',
    options: {
      body: JSON.stringify(location),
    },
  });
  const { success } = await dispatch(request);

  if (success) {
    const { body: createdLocation } = success;

    metricsPublisher.recordMetric(SiteMetrics.SITE_CREATED, {
      createdLocationId: createdLocation.id,
      areaId: createdLocation.timelineTemplateParentId,
    });

    await updateSetupGuideProgress(dispatch);
    dispatch(unsavedChangesActions.cleared());

    dispatch(routeTo('/'));
  }

  return Promise.resolve();
};

export const setLocationActiveDate = (locationId, location, activeDate) => {
  const locationParams = {
    ...location,
    ...{
      status: LocationConstants.STATUSES.setup,
      activeDate,
    },
  };
  const unarchivingLocation = location.status === LocationConstants.STATUSES.archived;
  const metricsToPublish = [
    {
      type: SiteMetrics.SITE_LAUNCH_DATE_CHANGED,
      data: {
        locationId,
        locationName: location.name,
        locationLaunchDate: activeDate,
      },
    },
  ];

  if (unarchivingLocation) {
    metricsToPublish.push({
      type: SiteMetrics.SITE_UNARCHIVED,
      data: {
        locationId,
        locationName: location.name,
      },
    });
  }

  return locationId
    ? updateLocation(locationId, locationParams, metricsToPublish)
    : createLocation(locationParams);
};

export const deactivateLocation = (locationId, location) => (
  updateLocation(locationId, {
    ...location,
    ...{
      status: LocationConstants.STATUSES.inactive,
      activeDate: null,
    },
  }, [{
    type: SiteMetrics.SITE_DEACTIVATED,
    data: {
      locationId,
      locationName: location.name,
    },
  }])
);

export const archiveLocation = (locationId, location) => (
  updateLocation(locationId, {
    ...location,
    ...{
      status: LocationConstants.STATUSES.archived,
      activeDate: null,
    },
  }, [{
    type: SiteMetrics.SITE_ARCHIVED,
    data: {
      locationId,
      locationName: location.name,
    },
  }])
);

export const deleteLocation = (locationId, location) => async (dispatch) => {
  const request = RequestActions.request({
    url: LocationConstants.getOrUpdateLocationRoute(locationId),
    key: LocationConstants.DELETE_LOCATION_KEY,
    method: RequestConstants.DELETE,
    errorMessage: 'sites.edit.errors.deleted',
    successMessage: 'sites.edit.success.deleted',
    content: {
      deletedLocationId: locationId,
    },
  });
  const { success } = await dispatch(request);

  if (success) {
    metricsPublisher.recordMetric(SiteMetrics.SITE_DELETED, {
      locationId,
      locationName: location.name,
    });

    await updateSetupGuideProgress(dispatch);
    dispatch(routeTo('/'));
  }
  return Promise.resolve();
};

export const reset = () => (dispatch) => {
  dispatch(unsavedChangesActions.cleared());

  dispatch({
    type: LocationConstants.RESET,
  });
};

export const locationChanged = content => (dispatch) => {
  dispatch(unsavedChangesActions.touched());

  dispatch({
    type: LocationConstants.LOCATION_CHANGED,
    content,
  });
};
