import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

class AnchoredMenu extends Component {
  alignClass() {
    switch (this.props.align) {
      case 'right':
        return 'showtime-menu--grow-right';
      case 'left':
        return 'showtime-menu--grow-left';
      default:
        return '';
    }
  }

  renderMenu() {
    const { classes } = this.props;
    return (
      <div
        className={ `showtime-menu ${this.alignClass()} ${classes}` }
        style={ this.props.styles }
      >
        {this.props.content}
        {this.props.children}
      </div>
    );
  }

  handleClickOutside(e) {
    this.props.onClickOutside(e);
  }

  render() {
    const { parentClasses, dataTest } = this.props;
    const parentStyles = _.assign({}, this.props.parentStyles, {
      position: 'relative',
    });

    return (
      <div className={ parentClasses } style={ parentStyles } data-test={ dataTest }>
        {this.props.showMenu && this.renderMenu() }
      </div>
    );
  }
}

AnchoredMenu.propTypes = {
  showMenu: PropTypes.bool,
  content: PropTypes.node,
  parentClasses: PropTypes.string,
  classes: PropTypes.string,
  align: PropTypes.string,
  parentStyles: PropTypes.object,
  styles: PropTypes.object,
  onClickOutside: PropTypes.func,
  children: PropTypes.node,
  dataTest: PropTypes.string,
};

AnchoredMenu.defaultProps = {
  align: '',
  classes: '',
  parentClasses: '',
  parentStyles: {},
  styles: {},
  onClickOutside() {},
  children: null,
  content: null,
  showMenu: true,
  dataTest: undefined,
};

export const { defaultProps, propTypes } = AnchoredMenu;

export default onClickOutside(AnchoredMenu);
