import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import withPureComponent from '../withPureComponent';

const Label = ({
  children,
  htmlFor,
  required,
  emphasis,
}) => (
  <label
    className={
      classNames('showtime-label showtime-label--block',
        {
          'showtime-label--emphasis': emphasis,
        })
    }
    htmlFor={ htmlFor }
  >
    <span className='showtime-label__text'>
      {children}
      {required &&
        <span className='showtime-label__text--required'> *</span>}
    </span>
  </label>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  emphasis: PropTypes.bool,
};

Label.defaultProps = {
  required: false,
  emphasis: false,
};

export default withPureComponent(Label);
