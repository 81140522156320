import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import SVGIcon from '../common/SVGIcon';
import { PromoIcons } from './constants';
import CouponSummary from './CouponSummary';

const hasExpired = redeemBy => redeemBy !== null && redeemBy * 1000 < new Date().getTime();

const Coupon = ({
  redeemBy,
  durationInMonths,
  amountOff,
  currency,
  duration,
  percentOff,
  name,
  intl,
}) => {
  const expired = hasExpired(redeemBy);

  const iconClassName = classnames(
    'showtime-icon showtime-icon--size-16', {
      'showtime-icon--error': expired,
    }
  );

  return (
    <div className='showtime-billing__promo'>
      <SVGIcon
        classes={ iconClassName }
        iconName={ expired ? PromoIcons.ERROR : PromoIcons.SUCCESS }
      />
      <div>
        <p className='showtime-billing__promo-name' data-test='promo-name'>{name}</p>
        <p className='showtime-billing__promo-message'>
          <CouponSummary
            { ...{
              durationInMonths,
              duration,
              percentRatio: percentOff / 100,
              amountOff: amountOff / 100,
              currency,
            } }
          />
        </p>
        { expired && (
          <p className='showtime-billing__promo-error'>
            {intl.formatMessage({ id: 'billing.promoError' })}
          </p>
        ) }
      </div>
    </div>
  );
};


Coupon.propTypes = {
  intl: PropTypes.object.isRequired,
  redeemBy: PropTypes.number,
  durationInMonths: PropTypes.number,
  duration: PropTypes.string,
  percentOff: PropTypes.number,
  name: PropTypes.string.isRequired,
  amountOff: PropTypes.number,
  currency: PropTypes.string,
};

Coupon.defaultProps = {
  redeemBy: null,
  duration: null,
  durationInMonths: null,
  percentOff: 0,
  amountOff: 0,
  currency: null,
};

export default injectIntl(Coupon);
