import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SVGIcon from './SVGIcon.jsx';

export const ACTIVE_CLASS = 'showtime-callout--active';
export const MANDATORY_CLASS = 'showtime-callout--mandatory';
export const INFO_CLASS = 'showtime-callout--info';
export const INACTIVE_CLASS = 'showtime-callout--inactive';
export const WARNING_CLASS = 'showtime-callout--warning';
export const UPSELL_CLASS = 'showtime-callout--upsell';

const calloutPropTypes = {
  children: PropTypes.node.isRequired,
  modifierClassName: PropTypes.string,
  hasDot: PropTypes.bool,
  active: PropTypes.bool,
  mandatory: PropTypes.bool,
  info: PropTypes.bool,
  inactive: PropTypes.bool,
  warning: PropTypes.bool,
  iconName: PropTypes.string,
  upsell: PropTypes.bool,
  dataTest: PropTypes.string,
};

const calloutDefaultProps = {
  iconName: undefined,
  modifierClassName: '',
  hasDot: true,
  active: false,
  mandatory: false,
  info: false,
  inactive: false,
  warning: false,
  upsell: false,
  dataTest: undefined,
};

const Callout = ({
  children,
  modifierClassName,
  hasDot,
  active,
  mandatory,
  info,
  inactive,
  warning,
  iconName,
  upsell,
  dataTest,
}) => (
  <span
    { ...dataTest ? { 'data-test': dataTest } : {} }
    className={
      classnames(
        'showtime-callout',
        modifierClassName,
        {
          [ACTIVE_CLASS]: active,
          [MANDATORY_CLASS]: mandatory,
          [INFO_CLASS]: info,
          [INACTIVE_CLASS]: inactive,
          [WARNING_CLASS]: warning,
          [UPSELL_CLASS]: upsell,
          'showtime-callout--no-dot': !hasDot,
        }
      )
    }
  >
    { iconName && (
      <SVGIcon
        classes='showtime-callout__icon'
        iconName={ iconName }
      />
    )}
    {children}
  </span>
);

Callout.propTypes = calloutPropTypes;
Callout.defaultProps = calloutDefaultProps;

export default Callout;
