import { FETCH_SCHEDULE_CATEGORIES_KEY } from './constants';
import { RequestConstants } from '../request';
import { requestStatuses } from '../common/requestStatuses';

const INITIAL_STATE = {
  entities: {},
  requestStatus: requestStatuses.NOT_REQUESTED,
};

const actionCases = {
  [RequestConstants.getLoadedActionType(FETCH_SCHEDULE_CATEGORIES_KEY)]: (state, action) => ({
    entities: action.content,
    requestStatus: requestStatuses.LOADED,
  }),
  [RequestConstants.getLoadingActionType(FETCH_SCHEDULE_CATEGORIES_KEY)]: state => ({
    ...state,
    requestStatus: requestStatuses.LOADING,
  }),
  [RequestConstants.getErrorActionType(FETCH_SCHEDULE_CATEGORIES_KEY)]: state => ({
    ...state,
    requestStatus: requestStatuses.ERROR,
  }),
};

export const scheduleCategories = (state = INITIAL_STATE, action = {}) => {
  if (actionCases[action.type]) return actionCases[action.type](state, action);

  return state;
};
