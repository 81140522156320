import React from 'react';
import PropTypes from 'prop-types';
import CouponPromoCodeLink from './CouponPromoCodeLink';
import CouponPromoCodeInput from './CouponPromoCodeInput';
import Coupon from './Coupon';

const CouponContainer = ({
  coupon,
  promo,
  showPromoCodeInput,
  promoError,
  onPromoCodeLinkClick,
  onPromoCodeSubmit,
  readOnly,
  loading,
}) => {
  const promoCodeLink = (
    <CouponPromoCodeLink
      onClick={ onPromoCodeLinkClick }
      promoCodeExists={ Boolean(promo) }
    />
  );
  const promoCodeInput = (
    <CouponPromoCodeInput
      loading={ loading }
      onClick={ onPromoCodeSubmit }
      promoError={ promoError }
    />
  );

  return (
    <div className='showtime-billing__promos'>
      { coupon && <Coupon key={ coupon.name } { ...coupon } /> }
      { !readOnly && (showPromoCodeInput ? promoCodeInput : promoCodeLink) }
    </div>
  );
};

CouponContainer.propTypes = {
  coupon: PropTypes.object,
  promo: PropTypes.object,
  promoError: PropTypes.string,
  showPromoCodeInput: PropTypes.bool,
  onPromoCodeLinkClick: PropTypes.func.isRequired,
  onPromoCodeSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

CouponContainer.defaultProps = {
  coupon: null,
  promo: null,
  promoError: null,
  showPromoCodeInput: false,
  readOnly: false,
  loading: false,
};

export default CouponContainer;
