import React from 'react';
import PropTypes from 'prop-types';

import hasServiceWorker from '../utils/service-workers/helpers/hasServiceWorker';
import InteractionBlocker from '../interaction-blocker';
import sizes, { MEDIUM } from '../interaction-blocker/sizes';
import positions, { TOP } from '../interaction-blocker/positions';
import OnlineContext from '../online-context';

const OfflineInteractionBlocker = ({
  children,
  size,
  position,
  tooltipMessage,
  fullWidth,
}) => (
  <OnlineContext.Consumer>
    {isOnline => (
      <InteractionBlocker
        blocked={ hasServiceWorker() && !isOnline }
        position={ position }
        size={ size }
        fullWidth={ fullWidth }
        tooltipMessage={ tooltipMessage }
      >
        { children }
      </InteractionBlocker>
    )}
  </OnlineContext.Consumer>
);

OfflineInteractionBlocker.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(sizes)),
  position: PropTypes.oneOf(Object.keys(positions)),
  tooltipMessage: PropTypes.string.isRequired,
};

OfflineInteractionBlocker.defaultProps = {
  size: MEDIUM,
  position: TOP,
};

export default OfflineInteractionBlocker;
