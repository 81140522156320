import React from 'react';

import FixedShortcuts from './FixedShortcuts';

const TaskReportsSidebar = () => (
  <div className='showtime-task-reports__sidebar' data-test='taskReports.sidebar'>
    <FixedShortcuts />
  </div>
);

export default TaskReportsSidebar;
