export const NONE = 'none';
export const SINGLE = 'single';
export const MULTI = 'multi';
export const RESUDE = 'reuse';

export const ICONS = {
  none: 'glyph/24px/ui/ui-24px-glyph-2_layers',
  single: 'mini/business-finance/business-16px_notes',
  multi: 'custom/widget-mode-list',
  reuse: 'mini/ui/ui-16px-1_zoom',
};

export const TITLE = {
  none: 'taskWizard.widget.title',
  single: 'taskWizard.widget.type.single.title',
  multi: 'taskWizard.widget.type.multi.title',
  reuse: 'taskWizard.widget.type.reuse.title',
};

export const INTRODUCTION = {
  none: 'taskWizard.widget.type.none.introduction',
  single: 'taskWizard.widget.type.single.introduction',
  multi: 'taskWizard.widget.type.multi.introduction',
  reuse: 'taskWizard.widget.type.reuse.introduction',
};

export const TASK_LIBRARY_URL = '/taskplanner#/library/1265';
