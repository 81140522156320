import _ from 'lodash';
import { DATA_TYPE_REGEX } from '../constants';

export const getWidgetApiSettings = state => _.get(state, 'editWidget.widgetConfiguration.settings.public', {});
export const getWidgetEditedSettings = state => _.get(state, 'editWidget.stagedSettingsChanges');

export const isNewWidgetSelector = state => getWidgetEditedSettings(state).isNew;

export const getWidgetRequest = state => _.get(state, 'editWidget.requestedChanges');

export const getWidgetDataType = state => _.get(state, 'editWidget.widgetConfiguration.widget_data_type');
export const getEditRequestStatus = state => _.get(state, 'editWidget.requestStatus');

export const getEditRequestPayload = (state, ownProps) => {
  const edits = getWidgetRequest(state);
  if (!edits) return null;

  const dataType = getWidgetDataType(state);
  if (!dataType) return null;
  const isForm = DATA_TYPE_REGEX.form.test(dataType);
  const isDataCapture = DATA_TYPE_REGEX.dataCapture.test(dataType);
  if (!isForm && !isDataCapture) return null;

  const { organizationId: orgId, widgetConfigurationId: configId } = ownProps;
  const widgetConfigLink = `https://web.trailapp.com/organizations/${orgId}/widget_configurations/${configId}/edit`;

  const requestData = {
    ...edits,
    ...isForm && { formUrl: getWidgetApiSettings(state).form_url },
    ...isDataCapture && { widgetConfigLink },
    email: window.current_user.email,
  };

  return requestData;
};
