import _ from 'lodash';

import { WidgetDataConstants } from '../constants';
import { WidgetDataStore, TrailStore } from '../store';
import FlashActions from '../../flash/actions';
import AppDispatcher from '../../application/dispatcher';
import hasServiceWorker from '../../utils/service-workers/helpers/hasServiceWorker';
import { MESSAGE_INITIATE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE } from '../../service-workers/constants';
import safePostMessage from '../../utils/service-workers/helpers/safePostMessage';
import { DATA_TYPE_REGEX } from '../../widget/constants';
import reduxStore from '../../store';
import { RequestActions, RequestConstants } from '../../request';

const doubleDispatch = (action) => {
  reduxStore.dispatch({ ...action, type: action.actionType });
  AppDispatcher.dispatch(action);
};

const WidgetDataActions = _.extend({}, {
  SAVE_DELAY: 1000,
  _saveQueue: [],
  _saveInterval: null,
  _savingPaused: false,

  pauseSaving() {
    this._savingPaused = true;

    if (this._saveInterval) {
      clearInterval(this._saveInterval);
      this._saveInterval = null;
    }
  },

  resumeSaving() {
    this._savingPaused = false;
  },

  performQueuedSaves() {
    if (this._saveQueue.length === 0) {
      return Promise.resolve();
    }

    const widgetSavePromises = this._saveQueue.map((id) => {
      doubleDispatch({
        actionType: WidgetDataConstants.QUEUE_AUTOSAVE,
        id,
      });

      return this.saveWidget(id);
    });

    this._saveQueue = [];
    return Promise.all(widgetSavePromises);
  },

  saveSucceeded(id, jsonResponse) {
    doubleDispatch({
      actionType: WidgetDataConstants.SAVE_SUCCESS,
      id,
      jsonResponse,
    });
  },

  startAutosaveLoop() {
    this._saveInterval = setInterval(_.bind(this.autosave, this), this.SAVE_DELAY);
  },

  removeSaveQueueDuplicates() {
    this._saveQueue = _.uniq(this._saveQueue);
  },

  autosave() {
    this.saveWidget(this._saveQueue.pop());
    if (this._saveQueue.length === 0) {
      clearInterval(this._saveInterval);
      this._saveInterval = null;
    }
  },

  queueAutosave(id) {
    this._saveQueue.push(id);
    this.removeSaveQueueDuplicates();

    if (!this._savingPaused) {
      if (this._saveInterval) {
        clearInterval(this._saveInterval);
      }

      this.startAutosaveLoop();

      doubleDispatch({
        actionType: WidgetDataConstants.QUEUE_AUTOSAVE,
        id,
      });
    }
  },

  saveWidget: id => reduxStore.dispatch(
    RequestActions.request({
      url: `/api/widget_data/${id}.json`,
      options: { body: WidgetDataStore.getJSONBody(id) },
      key: WidgetDataConstants.SAVE_KEY,
      method: RequestConstants.PUT,
    })
  ).then(({ success, error }) => success
    ? WidgetDataActions.saveSucceeded(success.body.id, success.body)
    : WidgetDataActions.handleFailedRequest(error, 'widgetDataActions.errors.saveWidget')
  ),

  getWidgetDataBatch(dataIds) {
    const ids = dataIds || [];
    if (!(ids instanceof Array)) {
      return Promise.resolve();
    }

    const query = ids.filter(id => typeof id === 'number' || typeof id === 'string')
      .map(id => `ids[]=${encodeURI(id)}`)
      .join('&');

    if (!query) {
      return Promise.resolve();
    }

    const url = `${WidgetDataConstants.BATCH_ROUTE}?${query}`;
    const onSuccess = function (json) {
      WidgetDataActions.setStore(json.body);

      if (hasServiceWorker() && !navigator.onLine) {
        const trailStore = new TrailStore();

        safePostMessage({
          type: MESSAGE_INITIATE_UPDATE_WIDGET_STORE_WITH_OFFLINE_STATE,
          payload: {
            trailKey: `${trailStore.getLocationId()}-${trailStore.getDate()}`,
          },
        });
      }
    };

    return reduxStore.dispatch(
      RequestActions.request({
        url,
        method: RequestConstants.GET,
        key: WidgetDataConstants.FETCH_BATCH_KEY,
      })
    ).then(({ success, error }) => success
      ? onSuccess(success)
      : FlashActions.alert(error.body.message)
    );
  },

  fetchUnloadedWidgets() {
    const widgetData = WidgetDataStore.getAllWidgetData();
    const trailStore = new TrailStore();
    const tasks = trailStore.getTasks();
    const loadedWidgetIds = _.chain(widgetData).values().map('id').value();
    const allWidgetIds = _.chain(tasks).map('widget_data_id').compact().value();
    const notYetLoadedWidgetIds = _.difference(allWidgetIds, loadedWidgetIds);

    WidgetDataActions.getWidgetDataBatch(notYetLoadedWidgetIds);
  },

  fetchAllWidgets() {
    const tasks = new TrailStore().getTasks();
    const widgetIds = _.chain(tasks).map('widget_data_id').compact().value();

    WidgetDataActions.getWidgetDataBatch(widgetIds);
  },

  setStore(json) {
    doubleDispatch({
      actionType: WidgetDataConstants.SET_STORE,
      widget_data: json,
    });
  },

  storeRebuilt(id) {
    doubleDispatch({
      actionType: WidgetDataConstants.STORE_REBUILT,
      id,
    });
  },

  handleFailedRequest(response, messageKey) {
    const baseResponse = response && response.body && response.body.base[0];
    FlashActions.alert(baseResponse || messageKey);
    return Promise.reject(response);
  },

  submit(id) {
    const onSuccess = function (response) {
      WidgetDataActions.saveSucceeded(response.body.id, response.body);
      return Promise.resolve(response);
    };

    const failure = function (response) {
      WidgetDataStore.batchUpdate([{ submitted_at: null, id }]);
      WidgetDataActions.storeRebuilt();
      return WidgetDataActions.handleFailedRequest(response, 'widgetDataActions.errors.submit');
    };

    if (_.isEmpty(WidgetDataStore.getWidget(id))) {
      FlashActions.alert('widgetDataActions.errors.submit');
      return Promise.reject();
    }

    clearInterval(this._saveInterval);
    this._saveInterval = null;

    return WidgetDataActions.performQueuedSaves().then(() => {
      doubleDispatch({
        actionType: WidgetDataConstants.SUBMITTED,
        id,
      });

      const { type: widgetDataType } = WidgetDataStore.getWidget(id);
      const withScores = DATA_TYPE_REGEX.dataCapture.test(widgetDataType);

      return reduxStore.dispatch(
        RequestActions.request({
          url: `/api/widget_data/form_submission/${id}.json`,
          method: RequestConstants.PUT,
          key: WidgetDataConstants.SUBMIT_KEY,
          options: { body: WidgetDataStore.getJSONBody(id, withScores) },
        })
      ).then(({ success, error }) => success ? onSuccess(success) : failure(error));
    });
  },

  unsubmit(id) {
    const responseReceived = function (response) {
      if (response.submitted_at !== null) {
        FlashActions.alert('widgetDataActions.errors.unsubmit');
        return false;
      }

      doubleDispatch({
        actionType: WidgetDataConstants.UNSUBMITTED,
        id,
      });

      return true;
    };

    if (id === null || id === undefined) {
      return Promise.resolve(true);
    }

    return reduxStore.dispatch(
      RequestActions.request({
        url: `/api/widget_data/form_unsubmission/${id}.json`,
        method: RequestConstants.PUT,
        key: WidgetDataConstants.SUBMIT_KEY,
        options: { body: WidgetDataStore.getJSONBody(id) },
      })
    ).then(({ success, error }) => responseReceived(success.body || error.body));
  },

  reset(id) {
    reduxStore.dispatch(RequestActions.request({
      url: `/api/widget_data/${id}.json`,
      method: RequestConstants.GET,
      key: WidgetDataConstants.RESET_KEY,
    })).then(({ success }) => {
      WidgetDataActions.setStore([success.body]);
    });
  },

  widgetDataUpdatedByPusher: async (id) => {
    const { success } = await reduxStore.dispatch(RequestActions.request({
      url: `${WidgetDataConstants.BATCH_ROUTE}?ids[]=${id}`,
      method: RequestConstants.GET,
      content: { updateFromPusher: true },
      key: WidgetDataConstants.FETCH_WIDGET_KEY,
    })).catch(() => Promise.resolve());

    if (!success) return Promise.resolve();
    const [{ custom_fields: customFields } = {}] = success.body;
    AppDispatcher.dispatch({
      actionType: WidgetDataConstants.WIDGET_UPDATED_BY_PUSHER,
      id,
      customFields,
    });
    return Promise.resolve();
  },

  addActionWidgetToTrail(actionWidget) {
    if (_.isEmpty(actionWidget)) return;

    doubleDispatch({
      actionType: WidgetDataConstants.ACTION_WIDGET_ADDED,
      actionWidget,
    });
  },

  setCustomField(id, key, value) {
    doubleDispatch({
      actionType: WidgetDataConstants.CUSTOM_FIELD_CHANGED,
      id,
      key,
      value,
    });
  },

  clearRowFields(id, rowIndex, fieldNames) {
    doubleDispatch({
      actionType: WidgetDataConstants.ROW_FIELDS_CLEARED,
      id,
      rowIndex,
      fieldNames,
    });
  },

  setTimer(id, rowId, data) {
    doubleDispatch({
      actionType: WidgetDataConstants.TIMER_CHANGED,
      id,
      rowId,
      data,
    });
  },

  setCustomFieldRow(id, rowKey, rowIndex, row) {
    doubleDispatch({
      actionType: WidgetDataConstants.CUSTOM_FIELD_ROW_CHANGED,
      id,
      rowKey,
      rowIndex,
      row,
    });
  },

  deleteCustomFieldRow(id, rowKey, rowIndex) {
    doubleDispatch({
      actionType: WidgetDataConstants.REQUEST_CUSTOM_ROW_DELETE,
      id,
      rowKey,
      rowIndex,
    });
  },
});

export { WidgetDataActions };
