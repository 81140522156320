import _ from 'lodash';

import {
  missingJumpToFieldError, jumpToHasFieldOrderError, isLastColumnError, noLogicIfCaseValueError,
} from './constants';
import { JUMP_TO_END_OF_RECORD_LOG } from '../../constants';

export const getLogicErrorsForColumn = ({ cellSettings: { logic = [], fieldName } }, columns) => {
  const columnIndex = _.findIndex(columns, { cellSettings: { fieldName } });
  const allFieldNames = _.map(columns, 'cellSettings.fieldName');

  if (!logic.length) return [];

  const isLastColumn = columns.length === (columnIndex + 1);

  return logic.reduce((errors, { then, ifCase }, logicIndex) => {
    if (!ifCase.value) errors.push(`${noLogicIfCaseValueError}[${logicIndex}]`);

    const [{ fieldName: jumpTofieldName }] = then;
    if (jumpTofieldName === JUMP_TO_END_OF_RECORD_LOG && !isLastColumn) return errors;

    if (isLastColumn) return [...errors, `${isLastColumnError}[${logicIndex}]`];
    const jumpToFieldIndex = _.findIndex(columns, { cellSettings: { fieldName: jumpTofieldName } });
    if (!allFieldNames.includes(jumpTofieldName)) {
      errors.push(`${missingJumpToFieldError}[${logicIndex}]`);
    } else if (jumpToFieldIndex <= columnIndex) {
      errors.push(`${jumpToHasFieldOrderError}[${logicIndex}]`);
    }
    return errors;
  }, []);
};
