import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import classNames from 'classnames';
import Button from '../../../common/Button';
import InputField from '../../../common/forms/InputField';

const SettingsMessageField = ({
  value,
  onChange,
  onDelete,
  label,
  dataTest,
  inline,
  placeholder,
}) => {
  const inputRef = createRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const { formatMessage } = useIntl();

  return (
    <div className={
      classNames(
        'showtime-record-log-builder-field__settings-message', {
          'showtime-record-log-builder-field__settings-message--inline': inline,
        }
      )
    }
    >
      <div className='showtime-record-log-builder-field__config-row-header'>
        <h5 className='showtime-record-log-builder-field__config-row-title'>
          { label }
        </h5>
        <Button
          buttonClasses='showtime-button--lowlight-subtle showtime-button--small hint--top-left hint--info'
          onClick={ onDelete }
          dataTest='remove.setting'
          dataHint={ formatMessage({ id: 'editWidget.builder.removeMessageHint' }) }
          iconName='/mini/ui/ui-16px-1_simple-remove'
        />
      </div>
      <InputField
        errors={ [] }
        value={ value }
        onChange={ onChange }
        refFn={ inputRef }
        dataTest={ dataTest }
        placeholder={ placeholder || 'This field is required' }
        containerClass='showtime-record-log-builder-field__settings-message-input'
      />
    </div>
  );
};

SettingsMessageField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.node,
  dataTest: PropTypes.string,
  inline: PropTypes.bool,
  placeholder: PropTypes.string,
};

SettingsMessageField.defaultProps = {
  value: '',
  onChange() {},
  onDelete() {},
  label: null,
  dataTest: null,
  inline: false,
  placeholder: undefined,
};

export default SettingsMessageField;
