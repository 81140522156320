import {
  GET_PLANS_KEY, GET_ORGANIZATION_IDS, SET_EMAIL,
} from './constants';
import { RequestConstants } from '../request';

export const admin = (state, action) => {
  const initialState = {
    plans: [],
    organizationIds: {},
    email: '',
    isLoaded: false,
  };

  const currState = { ...state } || { ...initialState };

  switch (action.type) {
    case RequestConstants.getLoadedActionType(GET_PLANS_KEY): {
      return {
        plans: action.content.plans,
        isLoaded: true,
      };
    }
    case RequestConstants.getLoadedActionType(GET_ORGANIZATION_IDS): {
      return {
        ...currState,
        organizationIds: action.content,
      };
    }
    case SET_EMAIL:
      if (action.payload === '') {
        return {
          ...currState,
          email: action.payload,
          organizationIds: {},
        };
      }
      return {
        ...currState,
        email: action.payload,
      };

    default:
      return currState;
  }
};

export default admin;
