import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { TrailConstants } from '../../trail/constants/index';
import DateDifference from '../../common/DateDifference';
import DateTimeZone from '../../common/DateTimeZone';
import ToolTip from '../../common/ToolTip';
import { createIntlMessageFormatter } from '../../common/intlMessageFormatter';
import withViewportWidth from '../../common/withViewportWidth';

const { COMPLETED, COMPLETED_EARLY, COMPLETED_LATE } = TrailConstants.taskStatus;
const windowWidthBreakpoint = 649;

const calculateWhetherToAlignTooltipToRight = viewportWidth => ({
  alignTooltipToRight: viewportWidth <= windowWidthBreakpoint,
});

export const CompletedAtAndBy = ({
  completedAt, completedBy, timeZone, intl,
}) => (
  <React.Fragment>
    <DateTimeZone
      date={ completedAt }
      timeZone={ timeZone }
      format={ intl.formatMessage({ id: 'taskReports.task.datetimeFormat' }) }
    />
    { completedBy && intl.formatMessage({ id: 'taskReports.task.completedBy' }, { completedBy }) }
  </React.Fragment>
);
CompletedAtAndBy.propTypes = {
  completedAt: PropTypes.string,
  completedBy: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
};

CompletedAtAndBy.defaultProps = {
  completedAt: undefined,
  completedBy: undefined,
};

export const DueBy = ({
  status, dueBy, intl, timeZone,
}) => {
  const statusText = intl.formatMessage({ id: 'taskReports.task.dueBy' }, { status });
  const format = intl.formatMessage({ id: 'taskReports.task.datetimeFormat' });
  return (
    <React.Fragment>
      { statusText } <DateTimeZone date={ dueBy } timeZone={ timeZone } format={ format } />
    </React.Fragment>
  );
};

DueBy.propTypes = {
  status: PropTypes.oneOf(Object.values(TrailConstants.taskStatus)).isRequired,
  dueBy: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export const TaskTimestamp = ({
  completedAt, completedBy, dueBy, dueFrom, status, intl, alignTooltipToRight, timeZone,
}) => {
  if (!dueFrom) return null;

  const taskMessage = createIntlMessageFormatter(intl, 'taskReports.task');
  const completedEarlyLateOrOnTime = [
    COMPLETED, COMPLETED_EARLY, COMPLETED_LATE,
  ].includes(status) && completedAt;

  const durationLateOrEarlySnippet = (
    <span className='showtime-task-planner-task__early-or-late-duration'>
      <DateDifference
        toDate={ completedAt }
        fromDate={ status === COMPLETED_EARLY ? dueFrom : dueBy }
      />
      { status === COMPLETED_EARLY && <ToolTip
        dataTest='taskReports.completedEarlyToolTip'
        message={ taskMessage('completedEarly') }
        classes={ [
          'showtime-task__task-report-help',
          alignTooltipToRight ? 'hint--right' : 'hint--left',
          'hint--info',
        ] }
      /> }
    </span>
  );

  return (
    <span className='showtime-task-planner-task__timestamp'>
      { completedEarlyLateOrOnTime
        ? (
          <React.Fragment>
            <CompletedAtAndBy
              { ...{
                completedBy, completedAt, intl, timeZone,
              } }
            />
            { status !== COMPLETED && durationLateOrEarlySnippet }
          </React.Fragment>
        )
        : (
          <DueBy
            { ...{
              status, dueBy, timeZone, intl,
            } }
          />
        ) }
    </span>
  );
};

TaskTimestamp.defaultProps = {
  completedAt: undefined,
  completedBy: undefined,
};

TaskTimestamp.propTypes = {
  alignTooltipToRight: PropTypes.bool.isRequired,
  completedAt: PropTypes.string,
  completedBy: PropTypes.string,
  dueBy: PropTypes.string.isRequired,
  dueFrom: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(TrailConstants.taskStatus)).isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default injectIntl(withViewportWidth(calculateWhetherToAlignTooltipToRight)(TaskTimestamp));
