import _ from 'lodash';
import moment from 'moment-timezone';

import TaskScheduleConstants from '../../schedule/constants';
import { RequestActions, RequestConstants } from '../../request';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const ScheduleActions = {
  setActiveSchedule(scheduleKey) {
    return {
      type: TaskScheduleConstants.SET_ACTIVE_SCHEDULE,
      content: { scheduleKey },
    };
  },
  fetchNextInstancesForARelatedSchedule(scheduleId, url) {
    return RequestActions.request({
      url,
      method: RequestConstants.GET,
      key: TaskScheduleConstants.FETCHING_SCHEDULE_NEXT_INSTANCES,
      content: { scheduleKey: scheduleId },
    });
  },
  setCompletionDays: days => (dispatch) => {
    dispatch(unsavedChangesActions.touched());
    dispatch({
      type: TaskScheduleConstants.SET_COMPLETION_DAYS,
      value: days,
    });
  },
  setStartDate: date => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_START_DATE,
      value: date,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setEndDate: date => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_END_DATE,
      value: date,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setSingleDate: date => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_SINGLE_DATE,
      value: date,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setStartTimeSlot: timeslot => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_START_TIMESLOT,
      value: timeslot,
    });
  },
  setEndTimeSlot: timeslot => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_END_TIMESLOT,
      value: timeslot,
    });
  },
  setStartTime: time => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_START_TIME,
      value: time,
    });
  },
  setEndTime: time => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_END_TIME,
      value: time,
    });
  },
  setRecurBy: by => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_RECUR_BY,
      value: by,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setRecurUnit: unit => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_RECUR_UNIT,
      value: unit,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setupOneOffView: (date, timeslots) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      date,
      timeslots,
      type: TaskScheduleConstants.SETUP_ONE_OFF_VIEW,
    });
  },
  setView: view => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({ type: TaskScheduleConstants.SET_VIEW, view });
  },
  toggleDay: day => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.TOGGLE_DAY,
      value: day,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  setIsBetweenDates: value => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SET_IS_BETWEEN_DATES,
      value,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  selectAllDayFromTimeslots: timeslots => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SELECT_ALL_DAY,
      timeslots,
    });
  },
  setupRecurringView: (date, timeslots) => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({
      type: TaskScheduleConstants.SETUP_RECURRING_VIEW,
      date,
      timeslots,
    });

    return dispatch(ScheduleActions.fetchNextInstances());
  },
  removeSchedule() {
    return {
      type: TaskScheduleConstants.REMOVE_SCHEDULE,
    };
  },
  onAddSchedule: () => ({
    type: TaskScheduleConstants.ADD_SCHEDULE,
  }),
  fetchNextInstances: () => (dispatch, getState) => {
    const state = getState();
    if (_.get(state, 'newTask.schedule.content.summary.errorMessage')) {
      return Promise.resolve();
    }
    const { content, activeScheduleKey: scheduleKey } = state.newTask.schedule;
    const activeSchedule = content[scheduleKey];

    const startDate = moment(activeSchedule.start.date).format(TaskScheduleConstants.DATE_FMT);
    const recurBy = activeSchedule.recurrence.by;
    const recurOn = Object.keys(activeSchedule.recurrence.on)
      .filter(key => activeSchedule.recurrence.on[key]);
    const recurUnit = activeSchedule.recurrence.unit;

    const url = TaskScheduleConstants.NEXT_INSTANCES_URL(startDate, recurBy, recurOn, recurUnit);

    return dispatch(RequestActions.request({
      key: TaskScheduleConstants.NEXT_INSTANCES_LOADED,
      method: RequestConstants.GET,
      url,
      content: { scheduleKey },
    }));
  },
};
