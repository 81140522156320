import _ from 'lodash';

import { RequestConstants } from '../../request';
import { CommentBoxConstants } from '../constants';
import { TrailConstants } from '../../trail/constants';
import AttachmentConstants from '../../attachments/constants';

const { State } = AttachmentConstants;

const getAttachmentFromFilestackMiddleware = action => ({
  name: action.fileName,
  previousName: action.fileName,
  fileName: action.fileName,
  url: action.filestackUrl,
  filestackHandle: action.filestackUrl.split('/').pop(),
  mimeType: action.mimeType,
  fileSize: action.fileSize,
  s3Key: action.s3Key,
  state: State.SAVED,
  type: AttachmentConstants.FILE_TYPE,
});

const updateTask = (state, taskId, data) => (
  _.merge({}, state, { [taskId]: data })
);

const updateMessage = (state, messageId, data) => (
  _.merge({}, state, { messages: { [messageId]: data } })
);

export const taskCommentBoxReducer = (state, action) => {
  state = state || {};
  switch (action.type) {
    case RequestConstants.getLoadedActionType(CommentBoxConstants.DELETE):
      return updateMessage(state, action.content.messageId, {
        deletingMessage: false,
      });
    case RequestConstants.getLoadingActionType(CommentBoxConstants.DELETE):
      return updateMessage(state, action.content.messageId, {
        deletingMessage: true,
      });
    case RequestConstants.getErrorActionType(CommentBoxConstants.DELETE):
      return updateMessage(state, action.content.messageId, {
        deletingMessage: false,
      });
    case RequestConstants.getLoadingActionType(CommentBoxConstants.SEND):
      return updateTask(state, action.content.taskId, {
        message: null,
        attachment: null,
        showUnsavedCommentError: false,
      });
    case TrailConstants.COMPLETION_ATTEMPTED:
      return updateTask(state, action.taskId, {
        showUnsavedCommentError: true,
      });
    case TrailConstants.MARK_UNCOMPLETED:
      return updateTask(state, action.taskId, {
        showUnsavedCommentError: false,
      });
    case CommentBoxConstants.SET_ATTACHMENT:
      return updateTask(state, action.taskId, {
        attachment: action.attachment,
      });
    case CommentBoxConstants.CHANGE:
      return updateTask(state, action.taskId, {
        message: action.content,
      });
    case AttachmentConstants.COMPLETE_UPLOAD:
      return updateTask(
        state,
        action.taskId,
        { attachment: { metadata: getAttachmentFromFilestackMiddleware(action) } }
      );
    case AttachmentConstants.DELETE:
      return updateTask(state, action.taskId, { attachment: null }
      );
    default:
      return state;
  }
};
