import _ from 'lodash';

import { TimeslotConstants } from './constants';

const { SAVEABLE_TIMESLOT_FIELDS } = TimeslotConstants;

export const getLegacyTimeString = ({ hour, minute, dayOffset }) => {
  const hourOffset = dayOffset * 24;
  hour += hourOffset;
  hour = `${hour}`.padStart(2, '0');
  minute = `${minute}`.padStart(2, '0');
  return `${hour}:${minute}`;
};

export const getSaveableTimeSlot = timeslot => _.pick(
  timeslot,
  SAVEABLE_TIMESLOT_FIELDS
);

const mapLegacyTimeslotsForDay = (timeslots, nextInitialTimeslot) => {
  const calcOrgTimeslot = (timeslot, index) => {
    const nextTimeslot = timeslots[index + 1] || {
      ...nextInitialTimeslot,
      dayOffset: nextInitialTimeslot.dayOffset + 1,
    };

    const startTime = getLegacyTimeString(timeslot);
    const endTime = getLegacyTimeString(nextTimeslot);
    const { dayOfWeek, baseTimeslotUuid, uuid } = timeslot;
    const key = uuid || `${dayOfWeek}-${baseTimeslotUuid}`;
    return {
      ...timeslot,
      id: key,
      timeSlotId: key,
      position: index,
      startTime,
      endTime,
    };
  };

  return timeslots.map(calcOrgTimeslot);
};

export const mapLegacyDefaultTimeslots = (timeslots) => {
  timeslots = timeslots.map(({ attributes, id }) => ({
    uuid: id, ...attributes, level: 'dayDefault',
  }));

  const [firstTimeslot] = timeslots;

  return mapLegacyTimeslotsForDay(timeslots, firstTimeslot);
};

export const mapLegacyWeekDayTimeslots = (timeslots, level) => {
  const timeslotsByDayOfWeekNum = _.chain(timeslots)
    .map(timeslot => ({ ...timeslot, level }))
    .groupBy('dayOfWeekNum')
    .value();

  return _.chain(timeslotsByDayOfWeekNum)
    .toPairs()
    .map(([dayOfWeekNum, dayTimeslots]) => {
      const { 0: firstTimeslotsOfWeek } = timeslotsByDayOfWeekNum;
      const nextWeekdayNum = parseInt(dayOfWeekNum, 10) + 1;
      const nextDayTimeslots = timeslotsByDayOfWeekNum[nextWeekdayNum] || firstTimeslotsOfWeek;
      const [nextDayFirstTimeslot] = nextDayTimeslots;

      return mapLegacyTimeslotsForDay(
        dayTimeslots, nextDayFirstTimeslot
      );
    })
    .flatten()
    .value();
};
