import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes } from 'lodash';

import SVGIcon from '../common/SVGIcon.jsx';
import CheckBoxWrapper from '../common/forms/CheckBoxWrapper.jsx';
import { SubTaskConstants } from './constants';

const SubtaskCheckBox = (props) => {
  const multiValueSubtaskClasses = classNames({
    'is-completed': props.value === SubTaskConstants.VALUES.DONE,
    'is-not-completed': props.value === SubTaskConstants.VALUES.NOT_DONE,
    'is-not-applicable': includes(
      [SubTaskConstants.VALUES.NOT_APPLICABLE, SubTaskConstants.VALUES.NEVER_APPLICABLE],
      props.value
    ),
  });

  return (
    <CheckBoxWrapper
      additionalClasses={ multiValueSubtaskClasses }
      { ...props }
    >
      <span>
        <SVGIcon
          classes='showtime-checkbox__tick-icon showtime-checkbox__tick-icon--not-completed'
          iconName='mini/ui/ui-16px-1_simple-remove'
        />
        <SVGIcon
          classes='showtime-checkbox__tick-icon showtime-checkbox__tick-icon--not-applicable'
          iconName='mini/ui/ui-16px-2_ban'
        />
        <SVGIcon
          classes='showtime-checkbox__tick-icon showtime-checkbox__tick-icon--completed'
          iconName='mini/ui/ui-16px-1_check-CUSTOM'
        />
      </span>
    </CheckBoxWrapper>
  );
};

SubtaskCheckBox.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SubtaskCheckBox;
