import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubTaskActions } from './actions';
import SubtaskInput from './SubtaskInput';

class EditSubtaskInput extends Component {
  handleBlur = () => {
    SubTaskActions.focusSubtaskForEdit(null, this.props.taskId);
  }

  render() {
    return (
      <SubtaskInput
        taskId={ this.props.taskId }
        onBlur={ this.handleBlur }
        isFocused
        defaultValue={ this.props.subtask.name }
        canSetRecurring
      />
    );
  }
}

EditSubtaskInput.propTypes = {
  taskId: PropTypes.number.isRequired,
  subtask: PropTypes.object.isRequired,
};

export default EditSubtaskInput;
