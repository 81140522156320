import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useIdsQuery from './useIdsQuery';
import Filter from './Filter';
import { isLoaded } from '../../common/requestStatuses';
import { TaskReportsSelectors } from '../selectors';
import { filterQueryKeys, filterTypes } from '../constants';

const queryKey = filterQueryKeys[filterTypes.TAGS];

const TagsFilter = ({ filterMetric, ...props }) => {
  const { queryIds, setQueryIds, clearQueryIds } = useIdsQuery(
    queryKey, { sendMetrics: true, filterMetric });
  const items = useSelector(TaskReportsSelectors.tagsForDropdownSelector);
  const hasLoaded = isLoaded(useSelector(TaskReportsSelectors.taskTemplatesRequestStatusSelector));
  return (
    <Filter
      { ...props }
      { ...{ queryIds, setQueryIds, clearQueryIds } }
      items={ items }
      hasLoaded={ hasLoaded }
      filterType={ filterTypes.TAGS }
      exclusionWhenAllSelected
    />
  );
};

TagsFilter.propTypes = {
  filterMetric: PropTypes.string.isRequired,
};

export default TagsFilter;
