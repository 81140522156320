import _ from 'lodash';

import { logicConditions, logicThenTypes, JUMP_TO_END_OF_RECORD_LOG } from './constants';

const isFieldEmpty = (activeRow, fieldName) => _.isEmpty(activeRow[fieldName]);

const calculateSkippedFields = (thenArray, fieldNames, colIndex) => (
  thenArray.reduce((acc, thenCase) => {
    if (thenCase.type !== logicThenTypes.jumpToField) return acc;

    const jumpToEndSelected = thenCase.fieldName === JUMP_TO_END_OF_RECORD_LOG;
    const jumpFieldIndex = jumpToEndSelected
      ? fieldNames.length
      : fieldNames.indexOf(thenCase.fieldName);
    return [...acc, ...fieldNames.slice(colIndex + 1, jumpFieldIndex)];
  }, [])
);

const skippedFieldsForSelected = (activeRow, fieldName, logic, fieldNames, value, colIndex) => {
  if (isFieldEmpty(activeRow, fieldName) || activeRow[fieldName] === value) {
    return calculateSkippedFields(logic.then, fieldNames, colIndex);
  }

  return [];
};

const skippedFieldsForNotSelected = (activeRow, fieldName, logic, fieldNames, value, colIndex) => {
  if (isFieldEmpty(activeRow, fieldName) || activeRow[fieldName] !== value) {
    return calculateSkippedFields(logic.then, fieldNames, colIndex);
  }

  return [];
};

const calculateSkippedFieldsForColumn = (column, colIndex, activeRow, fieldNames) => {
  if (!window.config.features.logicJumps) return [];
  const fieldName = _.get(column, 'cellSettings.fieldName');
  const logicArray = _.get(column, 'cellSettings.logic', []);

  return logicArray.reduce((logicAcc, logic) => {
    const { value, condition } = logic.ifCase;

    switch (condition) {
      case logicConditions.selected:
        return [
          ...logicAcc,
          ...skippedFieldsForSelected(
            activeRow,
            fieldName,
            logic,
            fieldNames,
            value,
            colIndex
          ),
        ];
      case logicConditions.notSelected:
        return [
          ...logicAcc,
          ...skippedFieldsForNotSelected(
            activeRow,
            fieldName,
            logic,
            fieldNames,
            value,
            colIndex
          ),
        ];
      default:
        return logicAcc;
    }
  }, []);
};

const calculateVisibleFieldsByLogic = (activeRow, columns) => {
  const fieldNames = _.map(columns, 'cellSettings.fieldName');

  const skippedFields = columns.reduce((columnAcc, column, colIndex) => {
    const fieldName = _.get(column, 'cellSettings.fieldName');
    if (columnAcc.includes(fieldName)) return columnAcc;

    return _.union(
      columnAcc,
      calculateSkippedFieldsForColumn(column, colIndex, activeRow, fieldNames)
    );
  }, []);

  return _.without(fieldNames, ...skippedFields);
};

export const calculateSkippedFieldsForColumnIndex = (columns, colIndex, activeRow) => {
  const fieldNames = _.map(columns, 'cellSettings.fieldName');

  return calculateSkippedFieldsForColumn(columns[colIndex], colIndex, activeRow, fieldNames);
};

export default calculateVisibleFieldsByLogic;
