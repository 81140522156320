import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Iconic from '../../common/legacy/Iconic';

class MysteryDinerQuestion extends Component {
  renderIcon() {
    var iconType;
    var iconClass;

    switch (this.props.question.resultType) {
      case 'boolean':
        iconType = this.props.question.result ? 'circle-check' : 'circle-x';
        iconClass = 'question-icon ' + (this.props.question.result ? 'green' : 'red');
        return (<Iconic classes={iconClass} icon={iconType} />);
      case 'rating':
        return (<span className='text-center'>{this.props.question.result + '/' + this.props.question.maxRating}</span>);
      case 'string':
        return '';
    }
  }

  renderAnswerBox() {
    var element = null;

    if (this.props.question.resultType === 'string') {
      element = (
        <div className='question-answer-box'>
          {this.props.question.result}
        </div>
      );
    }

    return element;
  }

  renderQuestionCommentBox() {
    var element = null;

    if (this.props.question.comment) {
      element = (
        <div className='question-comment-box'>
          {this.props.question.comment}
        </div>
      );
    }

    return element;
  }

  _onChange(event) {
    var question = _.merge({}, this.props.question);
    question[event.target.name] = event.target.value;
    this.props.onChange(this.props.groupId, this.props.questionId, question);
  }

  render() {
    return (
      <tr>
        <td className='cell-title'>{this.props.question.name}
          {this.renderAnswerBox()}
          {this.renderQuestionCommentBox()}
        </td>
        <td className='question-icon-container cell-sm'
            data-col-heading={this.props.intl.formatMessage({ id: 'mysteryDiner.results' })}>
          {this.renderIcon()}
        </td>
        <td className='cell-fluid'>
          <input
            name='notes'
            className='optional question-notes'
            defaultValue={this.props.question.notes}
            onChange={e => this._onChange(e)}
            placeholder={this.props.intl.formatMessage({ id: 'common.addNotes' })}
            type="text">
          </input>
        </td>
      </tr>
    );
  }
}

MysteryDinerQuestion.propTypes = {
  onChange: PropTypes.func,
  groupId: PropTypes.number,
  questionId: PropTypes.number,
  question: PropTypes.object,
};

export default injectIntl(MysteryDinerQuestion);
