import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript.js';

import createSchemaValidator, { SchemaValidator } from '../../organization/legacy/validation/SchemaValidator';
import SchemaErrors from '../../organization/legacy/validation/SchemaErrors';
import { EditWidgetActions } from '../actions';
import { TaskWidgetSelectors } from '../../task-wizard/selectors';
import { DATA_TYPE_REGEX } from '../constants';
import dataCaptureSchema from '../../json/widget_configuration_data_capture_schema.json';

const CODE_MIRROR_OPTIONS = {
  lineNumbers: true,
  matchBrackets: true,
  lineWrapping: true,
  viewportMargin: 3,
  gutter: true,
  styleActiveLine: true,
  mode: 'application/json',
  tabSize: 2,
};

export class WidgetSettingsCodeEditor extends Component {
  state = { value: null };
  setValidatorValue = value => this.setState({ value });

  handleSettingsChange = (editor, data, value) => {
    this.setValidatorValue(value);

    let json;
    try {
      json = JSON.parse(value);
    } catch (e) {
      return;
    }

    this.props.onChange(json);
  };

  render() {
    const { configSettings, validator } = this.props;
    const value = _.isString(this.state.value)
      ? this.state.value
      : JSON.stringify(configSettings, null, '\t');

    const errors = validator.validate(value);

    return (
      <div className='showtime-widget-code'>
        <SchemaErrors errors={ errors } />
        <CodeMirror
          value={ value }
          options={ CODE_MIRROR_OPTIONS }
          onBeforeChange={ this.handleSettingsChange }
        />
      </div>
    );
  }
}

WidgetSettingsCodeEditor.propTypes = {
  validator: PropTypes.instanceOf(SchemaValidator).isRequired,
  configSettings: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

WidgetSettingsCodeEditor.defaultProps = {
  onChange() {},
};

const mapDispatchToProps = (dispatch) => {
  const { setWidgetSettings } = bindActionCreators(EditWidgetActions, dispatch);
  return {
    onChange: value => setWidgetSettings(value),
  };
};

const mapStateToProps = (state) => {
  const config = TaskWidgetSelectors.editedTaskWidgetConfig(state);
  const configSettings = _.get(config, 'settings', {});

  const isDataCapture = DATA_TYPE_REGEX.dataCapture.test(config.widget_data_type);
  const schema = isDataCapture ? dataCaptureSchema : {};
  const validator = createSchemaValidator(schema);

  return ({
    validator,
    configSettings,
  });
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(WidgetSettingsCodeEditor);
