import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import SVGIcon from '../SVGIcon';

export const VALIDATION_MESSAGE_DEFAULT_ERROR = 'validation-default-error';
export const VALIDATION_MESSAGE_ALT_ERROR = 'validation-alt-error';
export const VALIDATION_MESSAGE_WARNING = 'validation-warning';
const ValidationMessage = ({
  children,
  type,
}) => {
  const containerClasses = classNames(
    'showtime-label',
    'showtime-label--block',
    'showtime-label--emphasis',
    'showtime-label--validation',
    { 'showtime-label--validation-error': type === VALIDATION_MESSAGE_DEFAULT_ERROR }
  );
  const messageIcon =
    (type === VALIDATION_MESSAGE_DEFAULT_ERROR
 || type === VALIDATION_MESSAGE_ALT_ERROR)
      ? 'mini/ui/ui-16px-2_alert-circle-error'
      : 'mini/ui/ui-16px-3_alert';
  return (
    <div className={ containerClasses } data-test={ `${type}` }>
      <SVGIcon
        classes='showtime-label__icon'
        iconName={ messageIcon }
      />
      <span className='showtime-label__text'>
        {children}
      </span>
    </div>
  );
};

ValidationMessage.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    VALIDATION_MESSAGE_DEFAULT_ERROR,
    VALIDATION_MESSAGE_ALT_ERROR,
    VALIDATION_MESSAGE_WARNING,
  ]),
};

ValidationMessage.defaultProps = {
  type: VALIDATION_MESSAGE_DEFAULT_ERROR,
};

export default ValidationMessage;
