import { connect } from 'react-redux';

import { getFilestackPickSignature } from '../actions/';
import { getFilePickerPolicy } from '../selectors';
import { isExpired } from '../securityValidation';

const dispatchActionsToProps = dispatch => ({
  getSignature: () => dispatch(getFilestackPickSignature()),
});

const mapDerivedProps = (stateProps, dispatchProps, ownProps) => {
  const { config: { filestackApiKey } = {} } = window;
  const onUploadRequested = () => {
    const shouldFetch = filestackApiKey && isExpired(stateProps.expiry);
    if (shouldFetch) {
      dispatchProps.getSignature();
    }
  };
  return { ...ownProps, onUploadRequested };
};

export const pickPolicyHOC = connect(
  getFilePickerPolicy,
  dispatchActionsToProps,
  mapDerivedProps,
);
