import React from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { HelpMessage, HelpMessageLink } from '../../common/HelpMessage';
import SVGIcon from '../../common/SVGIcon';
import { TASK_LIBRARY_URL } from './constants';

const WidgetSectionHelpMessage = ({ intl }) => {
  let helpMessageType;

  if (_.get(window.config, 'features.recordLogBuilder')) {
    helpMessageType = 'helpMessage';
  } else {
    helpMessageType = 'noBuilderHelpMessage';
  }

  return (
    <HelpMessage>
      <FormattedMessage
        id={ `taskWizard.widget.type.${helpMessageType}.content` }
        values={ {
          link: (
            <HelpMessageLink
              href={ TASK_LIBRARY_URL }
              metricName='Record log - task library'
            >
              <span>
                {intl.formatMessage({ id: `taskWizard.widget.type.${helpMessageType}.link` })}
              </span>
              <SVGIcon
                classes='showtime-link__icon showtime-icon--margin-left'
                iconName='mini/arrows/arrows-16px-4_launch-11'
              />
            </HelpMessageLink>
          ),
        } }
      />
    </HelpMessage>
  );
};

export default injectIntl(WidgetSectionHelpMessage);
