import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Path, Rect } from '@react-pdf/renderer';

const Icon = props => (
  <Svg is='svg' width='16px' height='16px' viewBox='0 0 16 16' { ...props }>
    <Rect
      is='rect'
      width='14'
      height='14'
      rx='2'
      fill={ (props.style && props.style.fill) || '#444' }
    />
    <Path
      is='path'
      fill='#fff'
      d='M11.1875 2.8125C10.9375 2.5625 10.5625 2.5625 10.3125 2.8125L7 6.125L3.6875 2.8125C3.4375 2.5625 3.0625 2.5625 2.8125 2.8125C2.5625 3.0625 2.5625 3.4375 2.8125 3.6875L6.125 7L2.8125 10.3125C2.5625 10.5625 2.5625 10.9375 2.8125 11.1875C2.9375 11.3125 3.0625 11.375 3.25 11.375C3.4375 11.375 3.5625 11.3125 3.6875 11.1875L7 7.875L10.3125 11.1875C10.4375 11.3125 10.625 11.375 10.75 11.375C10.875 11.375 11.0625 11.3125 11.1875 11.1875C11.4375 10.9375 11.4375 10.5625 11.1875 10.3125L7.875 7L11.1875 3.6875C11.4375 3.4375 11.4375 3.0625 11.1875 2.8125Z'
    />
  </Svg>
);

Icon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

Icon.defaultProps = {
  style: null,
};

export default Icon;
