import { TaskTemplateConstants } from '../../task/constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

export const TaskTemplateActions = {
  changeTemplateType(templateType) {
    return {
      type: TaskTemplateConstants.CHANGE_TEMPLATE_TYPE,
      templateType,
    };
  },
  toggleLockTask: () => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    dispatch({ type: TaskTemplateConstants.TOGGLE_LOCK_TASK });
  },
};
