export const MetricsHitTypeConstants = {
  PAGE_VIEW: 'pageview',
  SCREEN_VIEW: 'screenview',
  EVENT: 'event',
  TRANSACTION: 'transaction',
  ITEM: 'item',
  SOCIAL: 'social',
  EXCEPTION: 'exception',
  TIMING: 'timing',
};
