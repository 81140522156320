import _ from 'lodash';
import { TaskConstants, TaskTemplateConstants } from '../../task/constants';
import { TaskHelpTextConstants, TaskWizardConstants } from '../constants';
import { RequestConstants } from '../../request';
import { GET_ACTION_TEMPLATE_KEY, GET_EDITING_ACTION_KEY } from '../../taskActions/constants';

const setHelpText = (state, { notes }) => ({
  ...state,
  content: {
    value: notes,
    isEmpty: _.isEmpty(notes),
  },
  isExpanded: !_.isEmpty(notes),
  originalContent: {
    value: notes,
    isEmpty: _.isEmpty(notes),
  },
});

export const taskHelpTextReducer = function (state, action) {
  const initialState = {
    content: {
      value: '',
      isEmpty: true,
    },
    originalContent: {
      value: '',
      isEmpty: true,
    },
    isExpanded: false,
  };
  const currState = state || _.extend({}, initialState);

  switch (action.type) {
    case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
      return setHelpText(currState, action.content);
    case RequestConstants.getLoadedActionType(GET_ACTION_TEMPLATE_KEY):
      if (action.content.status === TaskTemplateConstants.status.ARCHIVED) return state;

      return setHelpText(currState, action.content);
    case RequestConstants.getLoadedActionType(GET_EDITING_ACTION_KEY):
      return setHelpText(currState, action.content.timeline_task);
    case TaskHelpTextConstants.CHANGE:
      return _.extend({}, currState, {
        content: {
          value: action.content,
          isEmpty: !action.content,
        },
      });
    case TaskHelpTextConstants.EXPAND:
      return _.extend({}, currState, {
        isExpanded: true,
      });
    case TaskConstants.RESET:
      return _.extend({}, initialState);
    default:
      return currState;
  }
};
