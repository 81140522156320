import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import PDFWidgetTableHeader from './PDFWidgetTableHeader';
import PDFWidgetTableRows from './PDFWidgetTableRows';

const PDFWidgetTable = ({
  intl, widget, errors, timeZone, comments,
}) => (
  <View>
    <PDFWidgetTableHeader { ...{ intl, widget } } />
    <PDFWidgetTableRows { ...{
      intl, widget, errors, timeZone, comments,
    } }
    />
  </View>
);

PDFWidgetTable.propTypes = {
  widget: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  timeZone: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
};

export default PDFWidgetTable;
