import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { parseTimeSlotTime, hourLabel, twoDigits } from './timeSlotTimeHelper';

class SelectTimePicker extends Component {
  getValue() {
    return `${this.getHours()}:${this.getMinutes()}`;
  }

  _twoDigitsGroup(group) {
    return group.map(twoDigits);
  }

  allHours() {
    return _.chain(_.range(this.props.minHour, this.props.maxHour, this.props.hourStep))
      .map(twoDigits)
      .groupBy(i => (i < 24 ? 'today' : 'tomorrow'))
      .value();
  }

  allMinutes() {
    return _.range(0, 60, this.props.minuteStep).map(twoDigits);
  }

  getHours() {
    return this.refs.hours.value;
  }

  getMinutes() {
    return this.refs.minutes.value;
  }

  parseValue(parts) {
    return parseTimeSlotTime((parts || this.props.value));
  }

  onChange(e) {
    this.props.onChange(this.getValue(), this.getHours(), this.getMinutes());
  }

  renderReadOnlyTime() {
    const value = this.parseValue();

    return (
      <div className='select-time-picker select-time-picker--read-only'>
        {`${hourLabel(value.h)}:${value.m}`}
      </div>
    );
  }

  renderEditableTime() {
    const value = this.parseValue();
    const hours = this.allHours();
    return (
      <div className='select-time-picker' >
        <select
          onChange={ e => this.onChange(e) }
          ref='hours'
          className='hours'
          data-test='select-hours'
          value={ value.h }
        >
          {
            hours.today ? (
              <optgroup label={ this.props.todayLabel() }>
                {hours.today.map(h => (<option key={ h } value={ h }>{hourLabel(h)}</option>))}
              </optgroup>
            ) : null
          }
          {
            hours.tomorrow ? (
              <optgroup label={ this.props.tomorrowLabel() }>
                {hours.tomorrow.map(h => (
                  <option key={ h } value={ h }>
                    {hourLabel(h)}
                  </option>
                ))}
              </optgroup>
            ) : null
          }
        </select>
        <span className='separator'>:</span>
        <select
          onChange={ e => this.onChange(e) }
          ref='minutes'
          className='minutes'
          value={ value.m }
        >
          {this.allMinutes().map(m => (<option key={ m } value={ m }>{m}</option>))}
        </select>
      </div>
    );
  }

  render() {
    return (
      <div>{ this.props.readOnly ? this.renderReadOnlyTime() : this.renderEditableTime() }</div>
    );
  }
}

SelectTimePicker.propTypes = {
  value: PropTypes.string,
  maxHour: PropTypes.number,
  minHour: PropTypes.number,
  hourStep: PropTypes.number,
  minuteStep: PropTypes.number,
  readOnly: PropTypes.bool.isRequired,
  todayLabel: PropTypes.func,
  tomorrowLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SelectTimePicker.defaultProps = {
  value: '00:00',
  minHour: 0,
  maxHour: 24,
  hourStep: 1,
  minuteStep: 1,
  todayLabel: () => 'Today',
  tomorrowLabel: () => 'Next Day',
};

export default SelectTimePicker;
