import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { injectIntl } from 'react-intl';

import SVGIcon from '../../../common/SVGIcon';
import { createIntlMessageFormatter } from '../../../common/intlMessageFormatter';

const CELL_TYPE_TO_FRIENDLY_NAME = {
  ERROR: 'errorHint',
  FREE_TEXT: 'textHint',
  FORMATTED_NUMBER: 'numberHint',
  OPTIONS: 'optionsHint',
  FILE: 'fileHint',
  SECTION_TITLE: 'sectionTitleHint',
};

const CELL_TYPE_TO_ICON = {
  ERROR: 'mini/ui/ui-16px-2_alert-circle-error',
  FREE_TEXT: 'mini/business-finance/business-16px_cheque',
  FORMATTED_NUMBER: 'mini/ui/ui-16px-1_number-CUSTOM',
  OPTIONS: 'mini/arrows/arrows-16px-1_circle-down-40',
  FILE: 'mini/ui/ui-16px-1_attach-87',
  SECTION_TITLE: 'glyph/24px/text-editing/text-24px-glyph_capitalize',
};

const ColumnIcon = ({ cellType, intl, hideTooltips }) => {
  const formatMessage = createIntlMessageFormatter(intl, 'editWidget.builder.icon');
  return (
    <div
      className={ classNames({
        'hint--small hint--top-right hint--info': !hideTooltips,
      }) }
      data-hint={ formatMessage(CELL_TYPE_TO_FRIENDLY_NAME[cellType]) }
    >
      <SVGIcon
        classes='showtime-record-log-builder-field__header-icon'
        iconName={ CELL_TYPE_TO_ICON[cellType] }
      />
    </div>
  );
};

ColumnIcon.propTypes = {
  cellType: PropTypes.string.isRequired,
  hideTooltips: PropTypes.bool,
};
ColumnIcon.defaultProps = {
  hideTooltips: false,
};

export default injectIntl(ColumnIcon);
