import React from 'react';
import PropTypes from 'prop-types';

import DeleteAttachmentButton from './DeleteAttachmentButton';
import Attachment from '../../attachments/Attachment';
import { isImage } from '../../attachments/constants';
import { GRID_IMAGE_OPTION } from '../../file/constants';

const AttachmentGrid = ({
  files,
  isClickable,
  onDelete,
  router,
  routePrefix,
}) => (
  <div className='showtime-table-files'>
    { (files || []).map(({
      fileName, filestackHandle, mimeType, url, type,
    }) => {
      const className = isImage(mimeType) ? 'showtime-table-files__image' :
        'showtime-table-files__file';

      return (
        <div className={ className } key={ filestackHandle }>
          <Attachment
            title={ fileName }
            handle={ filestackHandle }
            mimeType={ mimeType }
            url={ url }
            type={ type }
            isClickable={ isClickable }
            renderImageOption={ GRID_IMAGE_OPTION }
            routePrefix={ routePrefix }
            router={ router }
          />
          { onDelete && <DeleteAttachmentButton onClick={ () => onDelete(filestackHandle) } /> }
        </div>
      );
    }
    )}
  </div>
);

AttachmentGrid.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string,
    filestackHandle: PropTypes.string,
    mimeType: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
  })),
  isClickable: PropTypes.bool,
  onDelete: PropTypes.func,
  router: PropTypes.object,
  routePrefix: PropTypes.string,
};

AttachmentGrid.defaultProps = {
  files: [],
  isClickable: false,
  onDelete: undefined,
  router: undefined,
  routePrefix: '',
};

export default AttachmentGrid;
