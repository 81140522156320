import _ from 'lodash';
import { OrganizationSettingsConstants } from '../constants';
import { RequestConstants } from '../../request';

export const organizationSettingsReducer = function (state, action) {
  const initialState = {
    content: {
      notifications: {},
    },
    originalContent: {
      notifications: {},
    },
  };
  const currState = state || _.extend({}, initialState);
  const getKey = OrganizationSettingsConstants.GET_SETTINGS_KEY;
  const updateKey = OrganizationSettingsConstants.UPDATE_SETTINGS_KEY;

  switch (action.type) {
    case RequestConstants.getLoadedActionType(getKey):
      return _.assign({}, currState, {
        content: action.content,
      });
    case RequestConstants.getLoadingActionType(updateKey):
      return _.assign({}, currState, {
        content: _.merge({}, currState.content, action.content.updateObject),
        originalContent: currState.content,
      });
    case RequestConstants.getErrorActionType(updateKey):
      return _.assign({}, currState, {
        content: currState.originalContent,
      });
    default:
      return currState;
  }
};
