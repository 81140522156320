import _ from 'lodash';
import { SubTaskConstants } from '../constants';
import { WidgetDataStore } from '../store';
import reduxStore from '../../store';
import FetchHelper from '../../utils/fetch';
import AppDispatcher from '../../application/dispatcher';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';
import FlashActions from '../../flash/actions';

const SubTaskActions = _.extend({}, {

  dispatchSubtaskChangedValueAction(id, taskId, data) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.VALUE_CHANGED,
      id,
      taskId,
      completedAt: data.completed_at,
      completedBy: data.completed_by,
      completed: data.completed,
      value: data.value,
    });
  },

  getChangeValueUrl(oldValue, newValue, id) {
    const isValueRecurring = value => value === SubTaskConstants.VALUES.NEVER_APPLICABLE;

    if (isValueRecurring(newValue)) {
      return SubTaskConstants.markRecurringUrl(id);
    }
    if (isValueRecurring(oldValue)) {
      return SubTaskConstants.unmarkRecurringUrl(id);
    }
    if (newValue === SubTaskConstants.VALUES.UNCHECKED) {
      return SubTaskConstants.markUncompletedUrl(id);
    }
    return SubTaskConstants.markCompletedUrl(id);
  },

  changeValue(props, actionOriginatedFromPusher, data) {
    const { id, task_id: taskId } = props;
    SubTaskActions.dispatchSubtaskChangedValueAction(id, taskId, data);
    if (actionOriginatedFromPusher) {
      return Promise.resolve();
    }

    const url = SubTaskActions.getChangeValueUrl(props.value, data.value, id);

    const success = function (response) {
      SubTaskActions.dispatchSubtaskChangedValueAction(id, taskId, response.body);
      return Promise.resolve(response);
    };

    const failure = function (response) {
      SubTaskActions.dispatchSubtaskChangedValueAction(id, taskId, props);
      return SubTaskActions.handleFailedRequest(response, 'subtaskActions.errors.submit');
    };

    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.Trail.SUBTASK_VALUE_CHANGED,
      {
        value: data.value,
      }
    );
    return FetchHelper.put(
      url,
      success,
      failure,
      {
        body: JSON.stringify({
          custom_fields: WidgetDataStore.getCustomFields(id),
          value: data.value,
        }),
      }
    );
  },

  handleFailedRequest(response, messageKey) {
    const baseResponse = _.get(response, 'body.base[0]');
    FlashActions.alert(baseResponse || messageKey, reduxStore);
    return Promise.reject(response);
  },

  expandSubtask() {
    metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.FLAG_SUBTASK_ICON_CLICKED);
  },

  startCreatingSubtask(taskId, value, temporaryId, addedByUserId, subtaskTemplateId) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.CREATION_STARTED,
      taskId,
      value,
      temporaryId,
      addedByUserId,
      subtaskTemplateId,
    });
  },

  subtaskCreationFailed(taskId, value, temporaryId) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.CREATION_FAILED,
      taskId,
      value,
      temporaryId,
    });
  },

  subtaskCreationSucceeded(taskId, value, temporaryId, data) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.CREATION_SUCCEEDED,
      taskId,
      value,
      temporaryId,
      data,
    });
  },

  createSubtask(taskId, value, isRecurring) {
    const temporaryId = _.uniqueId('creating_subtask_');
    const temporaryAddedByUserId = _.uniqueId('creating_subtask_');
    const temporarySubtaskTemplateId = isRecurring ? _.uniqueId('creating_subtask_') : null;
    SubTaskActions.startCreatingSubtask(
      taskId,
      value,
      temporaryId,
      temporaryAddedByUserId,
      temporarySubtaskTemplateId
    );

    const body = JSON.stringify({
      timeline_task_id: taskId,
      name: value,
      recurring: isRecurring,
    });

    const success = function (response) {
      SubTaskActions.subtaskCreationSucceeded(taskId, value, temporaryId, response.body);
      return Promise.resolve(response);
    };

    const failure = function (response) {
      SubTaskActions.subtaskCreationFailed(taskId, value, temporaryId);
      return SubTaskActions.handleFailedRequest(response, 'subtaskActions.errors.create');
    };

    metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.SUBTASK_ADDED);
    return FetchHelper.post(SubTaskConstants.BASE_URL, success, failure, { body });
  },

  focusSubtaskForEdit(subtaskId, taskId) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.FOCUS_SUBTASK_INPUT,
      subtaskId,
      taskId,
    });
  },

  showSubtaskEditMenu() {
    metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.SUBTASK_MENU_SELECTED);
  },

  hideSubtaskEditMenu() {
    metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.SUBTASK_DELETED_CANCELED);
  },

  startDeletingSubtask(subtaskId) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.DELETION_STARTED,
      subtaskId,
    });
  },

  deletingSubtaskFailed(subtaskId) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.DELETION_FAILED,
      subtaskId,
    });
  },

  deletingSubtaskSucceeded(subtaskId, response) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.DELETION_SUCCEEDED,
      subtaskId,
      data: response.body,
    });
  },

  deleteSubtask(subtaskId) {
    SubTaskActions.startDeletingSubtask(subtaskId);

    const body = JSON.stringify({
      subtask_id: subtaskId,
    });

    const success = function (response) {
      SubTaskActions.deletingSubtaskSucceeded(subtaskId, response);
      return Promise.resolve(response);
    };

    const failure = function (response) {
      SubTaskActions.deletingSubtaskFailed(subtaskId);
      return SubTaskActions.handleFailedRequest(response, 'subtaskActions.errors.delete');
    };

    metricsPublisher.recordMetric(TrailMetricsDirectory.page.Trail.SUBTASK_DELETED_CONFIRMED);
    return FetchHelper.delete(SubTaskConstants.BASE_URL, success, failure, { body });
  },

  addActionSubtasksToTrail(actionSubtasks, actionTimelineTaskId) {
    if (_.isEmpty(actionSubtasks)) return;

    AppDispatcher.dispatch({
      actionType: SubTaskConstants.ACTION_SUBTASKS_ADDED,
      actionSubtasks,
      actionTimelineTaskId,
    });
  },

  subtaskAdded(existingSubtask, passedFromPusher, addedSubtaskData) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.SUBTASK_ADDED_FROM_PUSHER,
      existingSubtask,
      passedFromPusher,
      addedSubtaskData,
    });
  },

  subtaskRemoved(existingSubtask, passedFromPusher, removedSubtaskData) {
    AppDispatcher.dispatch({
      actionType: SubTaskConstants.SUBTASK_REMOVED_FROM_PUSHER,
      existingSubtask,
      passedFromPusher,
      removedSubtaskData,
    });
  },
});

export { SubTaskActions };
