import { LocationConstants } from './constants';

export const sortLocationsByStatusAndName = locations => [...locations].sort((a, b) => {
  if (a.status === b.status) return a.name.localeCompare(b.name);
  if (a.status === LocationConstants.STATUSES.demo) return -1;
  if (b.status === LocationConstants.STATUSES.demo) return 1;
  if (a.status === LocationConstants.STATUSES.archived) return 1;
  if (b.status === LocationConstants.STATUSES.archived) return -1;
  return a.name.localeCompare(b.name);
});
