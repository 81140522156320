import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';
import { ATTRIBUTE_TYPES } from '../constants';
import { ChecklistSvg, CommentSvg, FlagSvg, SiteSvg } from './svg';

const attributeKeyToSvg = {
  [ATTRIBUTE_TYPES.LOCATION]: SiteSvg,
  [ATTRIBUTE_TYPES.FLAGS]: FlagSvg,
  [ATTRIBUTE_TYPES.CHECKS_ADDED]: ChecklistSvg,
  [ATTRIBUTE_TYPES.COMMENTS]: CommentSvg,
};

const PDFTaskAttribute = ({ attributeKey, attributeValue }) => {
  const SVGElement = attributeKeyToSvg[attributeKey];

  return (
    <View
      is='div'
      style={ {
        flexDirection: 'row',
        marginRight: 5,
        alignItems: 'center',
      } }
    >
      { SVGElement && <SVGElement
        style={ {
          fill: '#646470',
          height: 10,
          marginRight: 2,
        } }
      />
      }
      <Text
        is='span'
        style={ {
          fontSize: 10,
          color: '#646470',
        } }
      >
        { attributeValue }
      </Text>
    </View>
  );
};

PDFTaskAttribute.propTypes = {
  attributeKey: PropTypes.string.isRequired,
  attributeValue: PropTypes.string.isRequired,
};

export default PDFTaskAttribute;
